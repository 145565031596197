import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { AlumnoBoletin } from 'src/app/interfaces/interface-bakend/cursos/alumno-boletin.interface';
import { MateriaBoletinDetalle } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin-detalle.interface';
import { BoletinesService } from 'src/app/services/cursos/boletines.service';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { APP_CONFIG_SHARED, IAppShared } from 'src/app/app.config/app.config-shared';
import { Router } from '@angular/router';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { ExamenEvaluacion } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';

@Component({
    selector: 'app-examenes-boletines-modal',
    templateUrl: './examenes-boletines-modal.component.html',
    styleUrls: ['./examenes-boletines-modal.component.scss']
})
export class ExamenesBoletinesModalComponent implements OnInit {
    displayedColumns: string[] = [
        'nombreBoletin',
        'acciones'
    ];
    dataSource: MatTableDataSource<any>;
    isLoading = true;
    modalidadCursadaId: string;
    modalidadExamenId: string;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        public dialogRef: MatDialogRef<ExamenesBoletinesModalComponent>,
        private boletinesService: BoletinesService,
        private catalogoService: CatalogoService,
        private router: Router,
        private examenServices: ExamenesService,
        @Inject(APP_CONFIG_SHARED)
        private iAppShared: IAppShared,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.data.boletines);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    // Confirma agregar nueva materia al boletin
    async agregarMateria(element: any) {
        this.getCatalogo();
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¿Confirma agregar el examen a este Boletin?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, agregar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const data: MateriaBoletinDetalle = {
                    materia: this.data.examen.materiaBoletin,
                    modalidadCursada: this.modalidadCursadaId,
                    modalidadExamen: this.modalidadExamenId,
                    fechaExamen: this.data.evaluacion.fechaEvaluacion,
                    fechaNota: this.data.evaluacion.fechaEvaluacion,
                    notaExamen: this.data.evaluacion.puntajeObtenido,
                    fechaMateriaEnviada: new Date(),
                    examenEvaluacionId: this.data.evaluacion._id
                };

                console.log("Agregando materia a boletin::", data)

                try {
                    await this.boletinesService.addMateriaToAlumnoBoletin(element.boletin._id, element.alumno._id, data);

                    const examenEvaluacionUpdateOne: any = {
                        materiaBoletinEnviada: true,
                        fechaMateriaEnviada: new Date()
                    }
                    /* UPDATE EXAMEN */
                    await this.examenServices.evaluacionBoletinUpdateOne(this.data.evaluacion._id, examenEvaluacionUpdateOne);

                    Swal.fire({
                        icon: 'success',
                        title: 'Materia asignada correctamente al Boletin',
                        showConfirmButton: false,
                        timer: 1400
                    });

                    // Espera a que se cierre el Swal de éxito
                    setTimeout(async () => {
                        const result = await Swal.fire({
                            title: 'Ver los boletines cargados?',
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Ver Boletines',
                            cancelButtonText: 'Cancelar'
                        });

                        if (result.isConfirmed) {
                            this.dialogRef.close();
                            this.router.navigate(['/alumnos-boletines-listado']);
                        } else {
                            this.dialogRef.close();
                        }
                    }, 1500);

                    // Dispara la actualización
                    this.examenServices.triggerRefresh();
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'No se pudo asignar la materia a este boletin.',
                    });
                }

            }
        });
    }

    async getCatalogo() {
        const modalidadCursadas: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.modalidadCursada, null);
        const modalidadExamenes: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.modalidadExamen, null);

        this.modalidadCursadaId = modalidadCursadas[0]?._id;
        this.modalidadExamenId = modalidadExamenes[0]?._id;
    }

}
