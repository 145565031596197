<div class="fondo">
    <div class="get-instant-courses-area">
        <div class="container">
            <div class="get-instant-courses-inner-area">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-12">
                        <div class="get-instant-courses-content">
                            <h2>{{ idioma | translate: "home.TIDIO-CHAT" | async}}</h2>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="get-instant-courses-image">
                            <img src="assets/img/logo-aadidess.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>