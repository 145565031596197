import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Curso, CursoActividad } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { Actividad } from 'src/app/interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { Pais } from 'src/app/interfaces/interface-bakend/shared/paises/pais.interfaces';
import { PaisesService } from 'src/app/services/shared/paises.service';
import { Subscription } from 'rxjs';
import { Translators } from 'src/app/services/shared/translators.service';
import { GuiToolsService } from 'src/app/services/shared/gui-tools.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { APP_CONFIG_SHARED, IAppShared } from 'src/app/app.config/app.config-shared';
import { UserDocumentoTipo } from 'src/app/interfaces/interface-bakend/seguridad/users/user-documento-tipos.interface';
import { UserDocumentoTiposService } from 'src/app/services/shared/user-documento-tipos.service';
import { MediaService } from 'src/app/services/shared/media.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import Swal from 'sweetalert2';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config/app.config';
import { ActividadPostularComponent } from '../actividad-postular.component';
import { SolicitudesService } from 'src/app/services/ofertas-laborales/solicitudes.service';
import { Route, Router } from '@angular/router';


@Component({
  selector: 'app-formulario-inscripcion',
  templateUrl: './formulario-inscripcion.component.html',
  styleUrls: ['./formulario-inscripcion.component.scss']
})
export class FormularioInscripcionComponent implements OnInit {

  selectedRows: Set<any> = new Set<any>();
  selectedItemsDelegaciones: any[] = [];
  displayedColumns: string[] = [
    'Curso',
    'Delegación',
    'Fecha',
    'Seleccionar'
  ];

  myForm: FormGroup;
  cursos: Curso[];
  preescripcionesPista: Actividad[];
  modulos: Curso[];
  modulosSeleccionados: Curso[];
  delegaciones: CursoActividad[] = [];
  tiposIdentificaciones: Catalogo[] = [];
  paises: Pais[] = [];
  usuario: User;
  fullname: string;
  email: string;
  identificacion: string;
  enfermedad: boolean;
  tituloSecundario: boolean;
  otraFormacion: string;
  hospitalizado: boolean;
  nacionalidad: string;
  tiposIdentificacion: string;
  selectedPais: Pais;
  selectedCurso: boolean = false;
  selectedModulo: boolean = false;
  selectedDelegaciones: Catalogo[];
  selectedCursoDelegacion: CursoActividad[] = [];
  selectedTipoIdentificacion: Catalogo;
  flagProcesandoPago = false;
  idioma: Subscription;

  documentosActivosUsuario: UserDocumentoTipo[] = [];
  tableDocuments: object[] = [];

  /* Carga de documentacion del usuario*/

  flagDisableButtonApprove: boolean = true;
  flagCargaCorrecta: boolean = false;
  selectedFiles: { [documentId: string]: File[] } = {};
  selectedFileNames: { [documentId: string]: string } = {};
  value: number = 0;
  activeDocumentId: string | null = null;
  fileSelected: boolean = false;
  subscriptionAvance: Subscription;

  constructor(
    private fb: FormBuilder,
    private cursosService: CursosService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private actividadesService: ActividadesService,
    private guiMsjService: GuiMsjService,
    private paisesService: PaisesService,
    private translatorService: Translators,
    private userDocumentoTiposService: UserDocumentoTiposService,
    private guiToolsService: GuiToolsService,
    private mediaService: MediaService,
    private catalogoService: CatalogoService, @Inject(APP_CONFIG_SHARED)
    private iAppShared: IAppShared,
    public dialog: MatDialog,
    public solicitudesService: SolicitudesService,
    public usarioService: UsuarioService,
    private router: Router,
    @Inject(APP_CONFIG) private iAppConfig: IAppConfig,
    public dialogRef: MatDialogRef<ActividadPostularComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.getUserLogueado();
    this.createForm();
    this.getPaises();
    this.getTipoIdentificaciones();
    this.getDocumentosActivos();
    // this.getPreescripciones();
    this.getCursos();
  

    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
      this.guiToolsService.refreshComponent('/inscripcion-pista');
    });

    this.subscribeAvanceUpload();
    this.mediaService.reiniciarContadorControlUpload();

    this.createListenersStateForm();

  }

  ngOnInit(): void {
    console.log('RECIBO', this.data);
  }

  createListenersStateForm() {
    this.myForm.valueChanges.subscribe(async status => {
      console.log(status);
      // Realiza aquí las acciones que necesites con el valor del formulario
    });
  }
  
  async getDocumentosActivos() {
    try {
      const resp: UserDocumentoTipo[] = await this.userDocumentoTiposService.getUserDocumentosTiposActivos();
      this.documentosActivosUsuario = resp;
      this.generateTableContent();
    } catch (error) {
      console.error('Error al obtener los documentos', error);
    }
  }

  generateTableContent() {
    this.tableDocuments = []; // Inicializa o actualiza el arreglo que contiene el contenido de la tabla
    if (this.documentosActivosUsuario.length > 0) {
      this.documentosActivosUsuario.forEach(doc => {
        if (!this.userDocumentoTiposService.documentosSolicitado(this.usuario.adjuntos, doc._id)) {
          console.log('pusheo', doc._id);
          this.tableDocuments.push({
            id: doc?._id,
            name: doc.nombre
          });
        } else  {
          console.log('documento ya cargado..');
        }     
      });
    } else {
      this.tableDocuments = [{ message: 'No se encontraron documentos activos' }];
    }
    console.log(this.tableDocuments);
  }

  async getDelegaciones() {
    try {
      const resp: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.delegaciones, null);
      this.selectedDelegaciones = resp;
    } catch (error) {
      console.error('Error al obtener las delegaciones', error);
    }
  }

  async getPreescripciones() {
    try {
      const preescripciones = await this.actividadesService.getActividades();
      this.preescripcionesPista = preescripciones.filter(preescripcion => preescripcion?.pista == true);
      if (this.preescripcionesPista.length === 0) {
        console.error('No se encontraron preescripciones');
      }
    } catch (error) {
      console.error('Error al obtener las preescripciones', error);
    }
  }

  async getCursos() {
    const cursos = await this.cursosService.getCursos();
    this.cursos = cursos.filter(curso => curso.pista == true);
    this.modulos = cursos.filter(curso => curso.modulo == true && curso.pista == true); // no se utiliza
  }

  async getUserLogueado() {

    this.usuario = await this.authService.getUserLogon();
    this.fullname = this.usuario?.fullName;
    this.email = this.usuario?.email;
    this.otraFormacion = this.usuario?.otraFormacion;
    this.hospitalizado = this.usuario?.hospitalizado;
    this.nacionalidad = this.usuario?.paisNacimiento?.name_es;
    this.tiposIdentificacion = this.usuario.tipoIdentificacion?.concepto;

    this.selectedPais = this.usuario?.paisNacimiento;

    this.myForm.patchValue({
      enfermedad: this.usuario?.enfermedad,
      numeroDocumento: this.usuario?.identificacion,
      tituloSecundario: this.usuario?.tituloSecundario,
      telefono: this.usuario?.telefono,
      otraFormacion: this.usuario?.otraFormacion,
    });

    if (this.usuario.birth !== undefined) {
      const formattedBirthDate = this.guiToolsService.datePipeTransform(this.usuario.birth, 'yyyy-MM-dd');
      const birthDate = formattedBirthDate ? new Date(formattedBirthDate) : new Date();
      const data = this.guiToolsService.datePipeTransform(birthDate.setDate(birthDate.getDate() + 2), 'yyyy-MM-dd');
      this.myForm.patchValue({
        birth: data
      });
    } 

  
    // myFormtear la fecha antes de asignarla al formulario
    const fechamyFormteada = this.datePipe.transform(this.usuario?.birth, 'yyyy-MM-dd', 'UTC');

    // Asignar la fecha myFormteada al formulario
    this.myForm.patchValue({
      fechaNacimiento: fechamyFormteada
    });
  }

  async getPaises() {
    await this.paisesService.getPaises().then((resp: Pais[]) => {
      this.paises = resp;
    });
  }

  onChangePais(event): void {
    this.selectedPais = this.paises.filter(x => x.name_es === event)[0];
    console.log('onChangePais',  this.selectedPais);
  }
  
  getTipoIdentificaciones() {
    this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.tiposIdentificaciones,
        this.translatorService.getIdioma()).
        then( (resp: Catalogo[]) => {
            return this.tiposIdentificaciones =  resp;
        });
  }

  onChangeTipoIdentificacion(event): void {
    this.selectedTipoIdentificacion = this.tiposIdentificaciones.filter(x => x.concepto === event)[0];
  }

  /*
  *
  *VALIDACIONES
  *
  */
  get paisNacimiento() {
    return this.myForm.get('paisNacimiento').invalid
  }

  get fechaNacimiento() {
    return this.myForm.get('fechaNacimiento').invalid
  }

  get telefono() {
    return this.myForm.get('telefono').invalid
  }

  /*
  *
  *FIN VALIDACIONES
  *
  */

  /*
  *
  *SECCION GRILLA
  *
  */

  // Mapea el nombre de las delegaciones por el concepto
  async mapConceptoDelegaciones(delegaciones: CursoActividad[]) {
    try {
      await this.getDelegaciones();
      this.delegaciones = delegaciones.map(delegacion => {
        const delegacionEncontrada = this.selectedDelegaciones.find(catalogo => catalogo._id === delegacion.nombre);
        return {
          ...delegacion,
          nombre: delegacionEncontrada ? delegacionEncontrada.concepto : ''
        };
      });
    } catch (error) {
      console.error('Error al mapear delegaciones', error);
    }
  }

  // Ordena this.delegaciones por nombreCurso
  ordenarDelegaciones(delegaciones: CursoActividad[]) {
    delegaciones.sort((a, b) => {
      const nombreCursoA = a.cursoNombre.toLowerCase();
      const nombreCursoB = b.cursoNombre.toLowerCase();

      if (nombreCursoA < nombreCursoB) {
        return -1;
      }
      if (nombreCursoA > nombreCursoB) {
        return 1;
      }
      return 0;
    });
  }

  /*
  *
  *FIN SECCION GRILLA
  *
  */

  createForm() {
    this.myForm = this.fb.group({
      nombreApellido: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.required],
      paisNacimiento: ['', Validators.required], // es   nacionalidad
      fechaNacimiento: ['', Validators.required],
      tipoIdentificacion: ['', Validators.required],
      numeroDocumento: ['', Validators.required],
      enfermedad: ['', Validators.required],
      tituloSecundario: ['', Validators.required],
      otraFormacion: ['', Validators.required],
      pista: false,
      aceptarTerminos: ['', Validators.requiredTrue],      
    });
  }

  encontrarIdRepetido(arreglo: any): string | null {
    const ids = new Set<string>();
    
    for (const registro of arreglo) {
      if (ids.has(registro._idModulo)) {
        return registro._idModulo;
      } else {
        ids.add(registro._idModulo);
      }
    }
  
    return null;
  }

  async usuarioUpdate(usuarioUpdated: User): Promise<boolean> {
    return new Promise( async resolve => {
      this.usarioService.updateUser(
        this.usuario._id,
        usuarioUpdated
      ).then((usuario: User) => {
        if (usuario) {
          resolve (true);
        } else {
          resolve (false);
        }
      });
    });
    
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  controlSolicitudCrear(flagUsuario, flagSolicitud, flagUpload ): boolean {
    if (flagUsuario && flagSolicitud && flagUpload) {
      return true;
    } else {
      return false;
    }
  }

  openConfirmationDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '80%', // Ajusta el ancho de la modal según sea necesario
      height: '80%', // Ajusta la altura de la modal según sea necesario
      data: { formData: this.myForm.value }, // Pasando los datos del formulario al modal
    });

    dialogRef.afterClosed().subscribe(async (result) => {

      // ACEPTA LA CARGA DE DATOS 
      if (result === 'confirmar') {   
        console.log('creo',this.data.solicitud );  
         this.flagProcesandoPago = true;
         const flagUsuario = await this.usuarioUpdate(await this.setDatamyForm()); // actualizo usuario
         const flagSolicitud =  await this.solicitudesService.create(this.data.solicitud);  // creo solicitud
         const flagUpload = await this.uploadFiles(); // subo archivos     
         const flagControl = this.controlSolicitudCrear(flagUsuario, flagSolicitud, flagUpload);
        if (flagControl) {
          this.guiMsjService.msjFormSubmit('formularioOfertaCargado');
          this.flagProcesandoPago = false;
          this.closeDialog();
          this.router.navigateByUrl('/usuario-solicitudes');
        } else {
          this.guiMsjService.msjFormSubmit('crearSolicitudError');
        }
      } else {
        this.closeDialog();
      }

    });
  }

  async setDatamyForm(): Promise<User>{

    this.selectedPais = this.paises.filter(x => x.name_es ===  this.myForm.value.paisNacimiento)[0];   

    const datamyForm: any = 
    {        
      aceptarTerminos: this.myForm.value.aceptarTerminos,
      enfermedad: this.myForm.value.enfermedad,
      birth: new Date(this.myForm.value.fechaNacimiento).toISOString(), 
      otraFormacion: this.myForm.value.otraFormacion,
      paisNacimiento: this.selectedPais,
      pista: false,
      telefono: this.myForm.value.telefono, 
      tituloSecundario: this.myForm.value.tituloSecundario, 
      fromApp: false,
      fromWeb: true,
      esPersona: true,
    };

    return datamyForm;

 }

  showAlert(moduloRepetido: string) {
    Swal.fire({
      icon: "error",
      title: "Selecciono más de una delegación",
      html: `El módulo con el nombre <strong>${moduloRepetido}</strong> tiene más de una delegación seleccionada`,
      footer: '<span style="color: blue;"><strong>Por favor, seleccione solo una delegación por módulo.</strong></span>',
      showCloseButton: true,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
      }
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
        this.dialog.closeAll();
      }
    });
  }

  encontrarModuloRepetido(grilla: any): string | null {
    const ids = new Set<string>();

    for (const modulo of grilla) {
      if (ids.has(modulo._idModulo)) {
        return modulo.cursoNombre;
      } else {
        ids.add(modulo._idModulo);
      }
    }

    return null;
  }

  resetForm() {
    this.selectedRows.clear();
    this.selectedItemsDelegaciones = [];
    this.delegaciones = [];
    this.selectedCurso = false;
    this.selectedModulo = false;
  }

  controlSelectores(): boolean {
    let flag = true;
    console.log('this.selectedPais', this.selectedPais);
    if (this.selectedPais == null) {
      alert('seleccione una opción PAIS correcta');
      this.myForm.controls['paisNacimiento'].setValue('');
      flag = false;
    }
    return flag;
  }

  /*
  *
  *CARGA DOCUMENTACIÓN 
  *
  */

  openFileInput(documentId: string) {
    this.activeDocumentId = documentId;
    const input = document.getElementById('i' + documentId) as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  clearSelectionFile() {
    this.selectedFiles = {};
    this.selectedFileNames = {};
    this.activeDocumentId = null;
  }

  onFilesSelected(event: Event, documentId: string) {
    const fileInput = event.target as HTMLInputElement;
    const files: FileList = fileInput.files;

    if (!files || files.length === 0) {
      // No se encontró archivo
      this.clearSelectionFile();
      this.fileSelected = false;
      this.activeDocumentId = null;
      return;
    }

    const fileList: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      if (this.mediaService.controlSize(event)) {
        fileList.push(file);
      } else {
        alert('Archivo demasiado grande, seleccione un archivo de menos de 4MB');
        this.clearSelectionFile();
        this.flagDisableButtonApprove = true;
        this.fileSelected = false;
        return;
      }
    }

    this.selectedFiles[documentId] = fileList;
    this.selectedFileNames[documentId] = fileList[0].name;
    this.flagDisableButtonApprove = false;
    this.fileSelected = true;
  }

  async uploadFiles(): Promise<boolean> {
    try {
        const promises = [];
        for (const documentId in this.selectedFiles) {
            if (this.selectedFiles.hasOwnProperty(documentId)) {
                const selectedFiles = this.selectedFiles[documentId];
                for (const selectedFile of selectedFiles) {
                    promises.push(this.uploadDocumentoAdjunto(selectedFile, documentId));
                }
            }
        }
        // Espera a que todas las promesas se resuelvan
        await Promise.all(promises);
        // Si todas las cargas de archivos son exitosas, retorna true
        return true;
    } catch (error) {
        console.error('Error al subir archivos:', error);
        // Si hay algún problema, retorna false
        return false;
    }
 }

  uploadDocumentoAdjunto(selectedFile: File, documentId: string) {
    this.mediaService.uploadUserFromWeb(
      selectedFile, this.usuario._id,
      documentId
    ).then(async resp => {
      if (resp) {
        if (this.value === 100) {
          this.flagCargaCorrecta = true;
          // this.guiMsjService.msjFormSubmit('uploadDocumentOK');
        }
      } else {
        this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
      }
    });
  }

  subscribeAvanceUpload() {
    this.subscriptionAvance = this.mediaService
      .getAvance()
      .subscribe((resp) => {
        if (resp) {
          this.value = resp.value;
        } else {
          this.value = 0;
        }
      });
  }

    /*
  *
  * FIN CARGA DOCUMENTACIÓN 
  *
  */

}