import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Translators } from 'src/app/services/shared/translators.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import Swal from 'sweetalert2';
import { HomeComponent } from '../home.component';

@Component({
  selector: 'app-terms-of-service-home',
  templateUrl: './terms-of-service-home.component.html',
  styleUrls: ['./terms-of-service-home.component.scss']
})
export class TermsOfServiceComponentHome implements OnInit {

  form : FormGroup;
  usuario: User;
  idioma: Subscription;

  constructor(private usuarioService:UsuarioService, 
    private router:Router, 
    private authService: AuthService,
    public dialogRef: MatDialogRef<HomeComponent>,
    private guiMsjService:GuiMsjService,
    private translatorService: Translators
    
    ) { 
    this.form = new FormGroup({
      aceptar : new FormControl(false, [Validators.requiredTrue])
    })

    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });
  }

  ngOnInit(): void {
  }

  async setDataForma(): Promise<User>{

    const userLogon = await this.authService.getUserLogon();

    const user: User = {
      _id : userLogon._id,
      aceptaTerminos : this.form.value.aceptar
    }
    return user;
  }

  // Update user, set aceptaTerminos : true
  async updateUser(){
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const flag = await this.usuarioService.updateOne(await this.setDataForma());
    if (flag) {
      this.dialogRef.close(true);
      Swal.close();
      this.router.navigate(['/home']);
    } 
  }

  async logout(){
    this.dialogRef.close(true);
    await this.authService.logout();
  }

}
