<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ idioma | translate: "oferta-laboral.ADJ-DOC" | async}}</h2>
        </div>
    </div>
</div>

<div class="andorra-document-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row  mx-auto">
            <div class="col-lg-9 col-md-12">
                <div class="document-content">
                    <h3>{{data.label}}</h3>
                    <hr>
                    <form>
                        <label [ngStyle]="{'background': this.flagLoading?'#28a745':'#ffffff'}" class="form-label"></label>
                        <input class="form-control-lg" type="file" accept={{this.data.accept}} (change)="onFileSelected($event); ">
                        <!-- <button *ngIf=!flagDisableButton (click)="aceptar()">{{data.labelButton}}<span></span></button>
                        <button *ngIf=flagDisableButton disabled>{{data.labelButton}}<span></span></button> -->
                        <button *ngIf=!flagDisableButton (click)="aceptar()">{{ idioma | translate: "solicitudes.CARGA-DOCUMENTOS" | async}}<span></span></button>
                        <button *ngIf=flagDisableButton disabled>{{ idioma | translate: "form.BTN-ACEPTAR" | async}}<span></span></button>
                        <mat-progress-bar *ngIf='flagLoading' mode="indeterminate"></mat-progress-bar>
                    </form>
                </div>
            </div>
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <button *ngIf=!flagDisableButton (click)="closeModal()" class="btn btn-primary" type="button">Cancelar</button>
            <button *ngIf=flagDisableButton disabled (click)="closeModal()" class="btn btn-primary" type="button">Cancelar</button>
        </div>
    </div>
</div>