<mat-form-field class="example-full-width" [style.width.%]="100">
  <mat-label>Seleccionar Mesa</mat-label>
  <input matInput [formControl]="mesaControl" placeholder="Buscar Mesa" [matAutocomplete]="auto">
  <mat-autocomplete #auto (optionSelected)="seleccionarMesa($event.option.value)">
    <mat-option *ngFor="let mesa of filteredMesas | async" [value]="mesa._id">
      <strong>{{mesa.titulo}} | {{mesa.fechaExamenDesde | date:"dd/MM/yy"}}</strong> | {{mesa.delegacion.concepto}} | {{mesa.disciplina.nombre}} | Nivel: {{mesa.nivel}}
    </mat-option>
    <mat-option *ngIf="(filteredMesas | async)?.length === 0" disabled>No se encontraron mesas disponibles.</mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-spinner *ngIf="!mesas"></mat-spinner>