import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

const URL_BACKEND = environment.urlBackend;


@Pipe({
  name: 'comprobanteTransferencia'
})
export class ComprobanteTransferenciaPipe implements PipeTransform {

 // Debe recibir el type: 1 (img), 2 (pdf) y el id del usuario
 transform(type: number, idUsuario: string ): unknown {
    console.log('comprobanteTransferencia', type, idUsuario);
    return `${ URL_BACKEND }/media/getUrlComprobanteTransferencia/${type}/${idUsuario}`;
  }

}
