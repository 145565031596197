import { TipoPregunta } from './../../../../interfaces/interface-bakend/cursos/examen.interface';
import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RespuestasAltaComponent } from './respuestas-alta/respuestas-alta.component';
import { Router } from '@angular/router';
import { Examen, Pregunta } from '../../../../interfaces/interface-bakend/cursos/examen.interface';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { MediaService } from 'src/app/services/shared/media.service';
import { Subscription } from 'rxjs';
import { invalid } from '@angular/compiler/src/render3/view/util';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { ExamenesService } from '../../../../services/cursos/examenes.service';

interface DataArrayMultimedia {
    posRespuesta?: number;
    posPregunta?: number;
    file: any;
}

@Component({
  selector: 'app-evaluacion-preguntas-config',
  templateUrl: './evaluacion-preguntas-config.component.html',
  styleUrls: ['./evaluacion-preguntas-config.component.scss'],
})

export class EvaluacionPreguntasConfigComponent implements OnInit {

  tiposPregunta: TipoPregunta[];

  @Output() guardado = new EventEmitter();
  @Input() modificar: boolean;
  forma: FormGroup;
  flagFormaValido = false;
  dataModal: any;
  selectedFileIMG: any;
  selectedFilePDF: any;
  selectedFileMP4: any;
  subscriptionAvance: Subscription;
  subscriptionExamenValues: Subscription;

  value = 0;
  flagCargandoArchivo = false;
  flagMultimediaUploadFull = false;
  flagUpdateExamen = false;
  procesando = false;
  flagFin = false;
  procesandoArchivos = false;
  isEditable = true;
  path: string;
  flagLoaded = false;
  flagContinuarRespuestas = false;
  selectedType: string;
  tituloExamen: string;

  nombreArchivoUpload: string;
  tipoArchivoUpload: string;

  examen: Examen;

  preguntasBuild: Pregunta[] = [];

  preguntasMultimedia: DataArrayMultimedia [] = [];

  @ViewChild('file') myInputVariable: ElementRef;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private cursosService: CursosService,
    private mediaService: MediaService,
    private guiMsjService: GuiMsjService,
    private examenesService: ExamenesService

  ) {
    this.subscriptionExamen();
    this.createForm();
    this.createListenersStateForm();
    this.subscribeAvanceUpload();
  }

  ngOnInit(): void {
    // this.cargarFormaTest(); // lo activa para cargar un examen de prueba
    this.cargarExamenModificar();
  }

  /*******************************MODIFICAR EXAMEN CONDICION *******************************/
  async cargarExamenModificar() {
    if (this.modificar) {
      await this.setValoresExamenModificar();
    }
  }

  async setValoresExamenModificar() {
    this.examen = await this.examenesService.getExamenSeleccionado();
    this.tituloExamen = this.examen.titulo;
    this.tiposPregunta = this.examenesService.getTiposPreguntas();
    this.flagLoaded = true;
  }


  async cargarArreglosExamenModificar(preguntas: Pregunta[]) {
    preguntas = this.examen.preguntas;
    return preguntas;

  }

  /** NO IMPLEMENTADO POR COMPLEGIDAD */
  async cargarPreguntasExamenesModificar(examen: Examen) {
      examen.preguntas.forEach( element => {
        this.tipos.push(new FormControl(element.tipo));
        this.puntaje.push(new FormControl(element.puntaje));
        this.order.push(new FormControl(element.orden));
        this.preguntas.push(new FormControl(element.enunciado));
        this.file.push(new FormControl(''));
        this.obligatoria.push(new FormControl(element.obligatoria));
      });
  }
 /*******************************FIN MODIFICAR EXAMEN CONDICION *******************************/


  async aceptarPreguntas() {
    this.setFlagView();
    this.disabledForma();

    // Next aviso a mis observables
    this.examen = await this.examenesService.getExamenFindOne(this.examen._id);
    this.examenesService.setExamen(this.examen);
    this.examenesService._setExamenView$(this.examen);

    // Emit a mis comoponentes (este se puede evaluar y quitar, no next bastaria)
    this.guardado.emit(true);
  }

  /* observable al examen */
  subscriptionExamen() {
    this.subscriptionExamenValues = this.examenesService.getExamen().subscribe(resp => {
        this.examen = resp.value;
        this.tituloExamen = resp.value.titulo;
        this.tiposPregunta = this.examenesService.getTiposPreguntas();
        this.flagLoaded = true;
    });
  }

  async cargarFormaTest() {
    this.examen = await this.cursosService.getExamenById('618c283ab08fd683da0679f6');
    this.examenesService.setExamenSeleccionado(  this.examen );
    this.cursosService.setConfigExamen(  this.examen );
    this.tituloExamen = this.examen.titulo;
    this.tiposPregunta = this.examenesService.getTiposPreguntas();
    this.flagLoaded = true;
}

  /* subo archivo con observable*/
  subscribeAvanceUpload(){
      this.subscriptionAvance = this.mediaService.getAvance().subscribe(resp => {
      if (resp) {
          this.value = resp.value;
      } else {
          this.value = 0;
          }
      });
  }

  async cargarPreguntas(){

    this.initFlagcontrol();

    // 1- Busco examen 
    this.examen = await this.cursosService.getExamenById((await this.cursosService.getConfigExamen())._id);

    // 2- Asocio preguntas y multimedia
    this.preguntasBuild = await this.asociarPreguntasConMultimedia(
                            this.forma.value.preguntas, this.forma.value.order,
                            this.preguntasMultimedia, this.forma.value.obligatoria,
                            this.forma.value.puntaje, this.forma.value.tipos,
                        );
    this.examen.preguntas =  this.preguntasBuild;

    // 3- Update examen con preguntas configuradas
    this.examen = await this.cursosService.updateExamen(this.examen);

    // 4-Upload de multimedia
    this.flagMultimediaUploadFull = await this.uploadMultimedia(this.examen, this.preguntasMultimedia, 'P') ; 

    // 5- Actualizo servicios
    this.cursosService.setConfigExamen(this.examen);

    this.procesando = false;

  }

  createListenersStateForm() {
    return this.forma.statusChanges.subscribe( async status => {
        if (this.forma.value.preguntas) {
            if (
               status === 'VALID' &&
               this.forma.value.preguntas.length > 0 &&
               !this.forma.value.preguntas.includes('') &&
               !this.forma.value.order.includes(null)
            ) {
                this.flagFormaValido = true;
            } else {
              this.flagFormaValido = false;
            }
        }
    });
  }

  createForm(){
    this.forma = new FormGroup({
      tipos: new FormArray([],  [ Validators.required ]),
      preguntas: new FormArray([],  [ Validators.required ]),
      file: new FormArray([],  [ Validators.required ]),
      puntaje: new FormArray([],  [ Validators.required, Validators.min(1), Validators.max(10) ]),
      order: new FormArray([],  [ Validators.required, Validators.min(1) ]),
      obligatoria: new FormArray([],  [ Validators.required ]),
    });
  }

  agregarPregunta(tipo: string){
    if (tipo) {
        this.tipos.push(new FormControl(tipo));
        this.puntaje.push(new FormControl(1));
        this.order.push(new FormControl(this.preguntas.length + 1));
        this.preguntas.push(new FormControl(''));
        this.file.push(new FormControl(''));
        this.obligatoria.push(new FormControl(false));
    } else {
        this.guiMsjService.msjFormSubmit('ingrseTipoPregunta');
    }

  }

  borrarPregunta(i: number){
    this.tipos.removeAt(i);
    this.preguntas.removeAt(i);
    this.file.removeAt(i);
    this.puntaje.removeAt(i);
    this.order.removeAt(i);
    this.borraraArchivoCargado(i);
  }

  borraraArchivoCargado(i: number){
    this.preguntasMultimedia.forEach( (item, index) => {
        if (item.posPregunta === i) {
            this.preguntasMultimedia.splice(index, 1);
         }
   });
  }

  onFileChange(event, i) {

      if (event.target.files && event.target.files.length > 0) {
        this.controlSize(event);
        const dataFile: DataArrayMultimedia = {
            posPregunta: i,
            file: event.target.files[0],
        };
        // this.preguntasMultimedia.push(dataFile);

        if ( i >=  this.preguntasMultimedia.length) {
            this.preguntasMultimedia.push(dataFile);
        } else {
            this.preguntasMultimedia[i] = dataFile;
        }
      }

  }

  async controlSize(event) {
    if (!await this.mediaService.controlSize50mb(event)) {
        this.forma.setErrors({ invalid: true });
        alert('File too Big, please select a file less than 4mb');
        return;
    }
  }

  async asociarPreguntasConMultimedia(
      arregloPreguntas: any[],
      arregloOrder: any[],
      arregloMultimedia: any[],
      arregloObligatoria: any[],
      arregloPuntaje: any[],
      arregloTipos: any[]
    ): Promise<Pregunta[]> {

        return new Promise<Pregunta[]>(async (resolve) => {

        let preguntas: Pregunta[] = [];
        let pregunta: Pregunta;
        let elementoMultimedia: any;
        let flagContenido = false;

        /*******************************CUANDO LLAMA MODIFICAR TRUE *******************************/
        if (this.modificar) {
          preguntas = await this.cargarArreglosExamenModificar(preguntas);
        }
        /*******************************FIN CUANDO LLAMA MODIFICAR TRUE *******************************/

        for (let i = 0; i < arregloPreguntas.length; i++) {
                flagContenido = false;

                // 1- Busco si hay contenido para la pregunta
                arregloMultimedia.forEach(element => {
                    if (element.posPregunta === i) {
                        elementoMultimedia = element.file;
                        flagContenido = true;
                    }
                });

                // 2- Armo pregunta con o sin contenido
                if (flagContenido) {
                    pregunta = {
                        enunciado: arregloPreguntas[i],
                        contenido: {
                            source: 'pendiente de upload...',
                            type: elementoMultimedia.type,
                            descripcion: elementoMultimedia.name,
                            nombreArchivo: elementoMultimedia.name,
                            contiene: true,
                        },
                        obligatoria: arregloObligatoria[i],
                        orden: arregloOrder[i],
                        puntaje: arregloPuntaje[i],
                        tipo: arregloTipos[i],
                    };
                } else {
                    pregunta = {
                        enunciado: arregloPreguntas[i],
                        contenido: {
                            contiene: false,
                        },
                        obligatoria: arregloObligatoria[i],
                        orden: arregloOrder[i],
                        puntaje: arregloPuntaje[i],
                        tipo: arregloTipos[i],
                    };
                }
                preguntas.push(pregunta);
        }

        resolve (preguntas);

    });
  }

  async uploadMultimedia(examen: Examen, arrayMultimedia: DataArrayMultimedia[], tipo: string): Promise<boolean> {
    return new Promise( async resolve => {
        if (arrayMultimedia.length > 0) {
            this.procesandoArchivos = true;
            await this.uploadElment(arrayMultimedia, examen, tipo);
            resolve(true);
        } else {
            this.flagFin = true;
            resolve(false);
        }
      });
  }

  async uploadElment(arrayMultimedia: DataArrayMultimedia[], examen: Examen, tipo: string) {
    let cont = 0;
    arrayMultimedia.forEach(async (element: DataArrayMultimedia) => {
                await this.uploadFile(examen._id, element.posPregunta.toString(), element.file, arrayMultimedia.length, tipo);
                cont ++;
                if (cont === arrayMultimedia.length) {
                    this.flagFin = true;
                } else {
                    this.flagFin = false;
                }
            });
  }

  initFlagcontrol() {
    this.procesando = true;
    this.isEditable = false;
    this.flagFormaValido = false;
  }

  async uploadFile(idExamen, posPregunta, file, length, tipo) {

    this.value = 0;
    this.nombreArchivoUpload = file.name;
    this.tipoArchivoUpload = file.type;

    await this.mediaService.uploadFileExamen(idExamen, posPregunta, file, tipo).then( (resp: any) => {
      if (resp) {
        if (this.value === 100) {
            this.flagCargandoArchivo = false;
            this.examen = resp.examen;
            this.cursosService.setConfigExamen(this.examen);
        }
      } else {
        this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
      }
    });

  }

  get tipos(){
    return this.forma.get('tipos') as FormArray;
  }
  get preguntas(){
    return this.forma.get('preguntas') as FormArray;
  }

  get file(){
    return this.forma.get('file') as FormArray;
  }

  get puntaje(){
    return this.forma.get('puntaje') as FormArray;
  }

  get order(){
    return this.forma.get('order') as FormArray;
  }

  get obligatoria(){
    return this.forma.get('obligatoria') as FormArray;
  }

  disabledForma(){
    this.forma.controls['tipos'].disable();
    this.forma.controls['preguntas'].disable();
    this.forma.controls['file'].disable();
    this.forma.controls['puntaje'].disable();
    this.forma.controls['order'].disable();
    this.forma.controls['obligatoria'].disable();
  }

  setFlagView(){
    this.flagCargandoArchivo = false;
    this.flagMultimediaUploadFull = false;
    this.flagUpdateExamen = false;
    this.procesando = false;
    this.flagFin = false;
    this.procesandoArchivos = false;
    this.isEditable = true;
    this.flagLoaded = true;
    this.flagContinuarRespuestas = true;
  }
}








