import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {

  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | UrlTree |
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
          if(!this.authService.validateToken()) {
            this.router.navigate(['/profile-authentication']);
            return false;
          } else {
            return true;
          }
        // return this.authService.validateToken();       
    }

    canLoad(
        route: Route, segments: UrlSegment[]): boolean |
        UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.authService.validateToken();
        // throw new Error('Method not implemented.');
    }
    
}
