import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Leccion } from '../../../../../interfaces/interface-bakend/cursos/leccion.interface';
import { Materia } from '../../../../../interfaces/interface-bakend/cursos/materia.interface';
import { ArchivoLeccion } from '../../../../../interfaces/interface-bakend/cursos/archivoLeccion.interface';

@Component({
  selector: 'app-item-leccion',
  templateUrl: './item-leccion.component.html',
  styleUrls: ['./item-leccion.component.scss']
})
export class ItemLeccionComponent implements OnInit {

  @Input() leccion: Leccion;
  @Input() materia: Materia;
  @Input() orden: number;
  @Output() leccionSeleccionada: EventEmitter<any>;

  // Defino la variable con una interfaz
  archivoLeccion: ArchivoLeccion;
  
  reproduciendo = false;
  activo = false;


  constructor() {
    this.leccionSeleccionada = new EventEmitter();
   }

  ngOnInit(): void {
  }

  reproducir() {
    this.reproduciendo = !this.reproduciendo;
  }

  enviarContenido(){

    if (this.leccion.contenidos.length > 0){

        this.archivoLeccion = {
            source: this.leccion.contenidos[0].source,
            type: this.leccion.contenidos[0].type,
            nombreLeccion: this.leccion.nombre
        };

        this.leccionSeleccionada.emit(this.archivoLeccion);

      // Cambia el estado activo cuando se hace clic en este elemento
      this.activo = true;
    }
  }

}

