<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/dashboard">Inicio</a></li>
        <li><a routerLink="/mesas-listado">Listado Mesas</a></li>
        <li>Gestos Cargar Puntajes</li>
      </ul>
      <h2>Cargar puntajes de gestos</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<div class="contenedor-spinner" *ngIf="!gestosCargados || !usuariosCargados">
  <mat-spinner *ngIf="!gestosCargados && !usuariosCargados"></mat-spinner>
  <mat-spinner *ngIf="usuariosCargados && !gestosCargados"></mat-spinner>
</div>
<div class="andorra-apply-content ptb-70 animate__animated animate__fadeIn">
  <div class="container">

    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <h4 *ngIf="!usuariosCargados && !gestosCargados">Cargando configuración de la mesa...</h4>
          <h4 *ngIf="usuariosCargados && !gestosCargados">Cargando usuarios de la mesa...</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="gestosCargados && usuariosCargados">
        <mat-card>
          <h4>Mesa: <strong>{{mesa?.titulo}}</strong></h4>
          <h6>Disciplina: <strong>{{mesa?.disciplina.nombre}}</strong></h6>
          <h6>Nivel: <strong>{{mesa?.nivel}}</strong></h6>
        </mat-card>
        <div [formGroup]="formulario">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Usuario</th>
                  <th *ngFor="let gesto of gestos; let columnIndex = index" class="mat-column-{{columnIndex}}"> {{
                    gesto.nombre }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let usuario of this.usuariosFaltantes; let rowIndex = index">
                  <td class="nombre-usuario">{{ usuario.usuario?.fullName }}</td>
                  <td *ngFor="let gesto of gestos; let columnIndex = index">
                    <mat-form-field appearance="fill" class="gesto-input">
                      <mat-label>Puntaje</mat-label>
                      <input matInput type="number" min="0" [formControlName]="'campo_' + rowIndex + '_' + columnIndex"
                        (input)="capturarPuntajeGesto($event, rowIndex, columnIndex)" placeholder="Ingrese el puntaje"
                        required>
                    </mat-form-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <mat-form-field color="accent" [style.width.%]=50>
            <mat-label>Fecha evaluación</mat-label>
            <input matInput formControlName="fechaEvaluacion" [matDatepicker]="picker3" autocomplete="off" disabled>
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3 disabled="false"></mat-datepicker>
            <div *ngIf="fechaEvaluacion?.touched && fechaEvaluacion?.value === null" class="mensaje-costado">Por favor, ingrese una fecha de evaluación</div>
          </mat-form-field>

          <div class="d-flex justify-content-end">
            <button mat-raised-button color="accent" type="button" (click)="cancelarCarga()"
              style="margin-right: 5px; background-color: #eeeeee;">Cancelar</button>
            <button mat-raised-button color="warn" (click)="confirmReset()" style="margin-right: 5px;">Borrar grilla</button>
            <button mat-raised-button (click)="post()" color="primary" class="right-button" [disabled]="formulario.get('fechaEvaluacion').value === null">Guardar</button>

          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>