<div class="single-courses-box">
    <div class="courses-image">
        <a *ngIf="!flagCursoAlumno" (click)="navCtrlDescripcionCurso(curso)" routerLink="/curso-descripcion-compra" class="d-block image">
            <img *ngIf="this.img" [src]=" 1 | mediaCursosPipe: curso.imagen  | safe" [alt]="curso.imagen">
            <img *ngIf="this.video" [src]=" 2 | mediaCursosPipe: curso.video  | safeResourceUrl" [alt]="curso.video">
            <a class="link-btn"></a>
        </a>
        <a *ngIf="flagCursoAlumno" (click)="navCtrlDescripcionCurso(curso)" routerLink="/curso-habilitado-alumno" class="d-block image">
            <img *ngIf="this.img" [src]=" 1 | mediaCursosPipe: curso.imagen  | safe" [alt]="curso.imagen">
            <img *ngIf="this.video" [src]=" 2 | mediaCursosPipe: curso.video  | safeResourceUrl" [alt]="curso.video">
            <a class="link-btn"></a>
        </a>
        <div *ngIf="this.price" class="price shadow">${{ curso.precio }}</div>
    </div>

    <div class="courses-content">
        <div class="course-author d-flex align-items-center">
            <img src="assets/img/user9.png" class="rounded-circle" alt="image">
            <span *ngFor="let item of profesoresCurso">{{ item.nombrePersona }} {{ item.apellidoPersona }}</span>
        </div>
        <h3>
            <a *ngIf="!flagCursoAlumno" (click)="navCtrlDescripcionCurso(curso)" routerLink='/curso-descripcion-compra' class="link-btn">{{curso.nombre}}</a>
            <a *ngIf="flagCursoAlumno" (click)="navCtrlDescripcionCurso(curso)" routerLink='/curso-habilitado-alumno' class="link-btn">{{curso.nombre}}</a>
        </h3>
        <p>{{curso.introduccion}}</p>
        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
            <li><i class='flaticon-time'></i> {{curso.cantidadHoras}} {{ idioma | translate: "curso.HORAS-TOTAL" | async}}</li>
            <li><i class='flaticon-agenda'></i> {{curso.cantLecciones}} {{ idioma | translate: "curso.LECCIONES" | async}}</li>
        </ul>
        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
            <li><i class='flaticon-people'></i> {{curso.inscriptos}} {{ idioma | translate: "curso.ESTUDIANTES" | async}}</li>
        </ul>

    </div>
</div>