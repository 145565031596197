import { PaisDisponible } from './../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Response } from '../../interfaces/interface-bakend/response';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { StorageService } from '../shared/storage.service';
import { GuiMsjService } from '../shared/gui-msj.service';
import { Actividad } from '../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { AuthService } from '../auth/auth.service';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../app.config/app.config-ofertas';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class OfertasLaboralesService {

  actividadSeleccion: Actividad;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private guiMsjService: GuiMsjService,
    private authService: AuthService,
    @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,

  ) { }

  getActividades(): Promise<Actividad[]>{
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/actividades/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
   }

  async getActividadesFindOne(id: string): Promise<Actividad> {

    const headers =  new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
    });

    return new Promise( resolve => {
      this.httpClient.get<Response>(`${ URL_BACKEND }/actividades/findOne/:${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getActividadesFindByPaisDisponible(pais: PaisDisponible): Promise<Actividad[]>{

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      const idPaisDisponible = pais._id;
      // tslint:disable-next-line:max-line-length
      this.httpClient.get<Response>(`${ URL_BACKEND }/actividades/findByPaisDisponible/${idPaisDisponible}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  getNombresActividadesAsync( localidadedes: Actividad[], page?: number, size?: number, timeout = 1000): Observable<Actividad[]> {
    return new Observable<Actividad[]>(observer => {
      observer.next(this.getActividadIndividual(localidadedes, page, size));
      observer.complete();
    }).pipe(delay(timeout));
  }

  getActividadIndividual(localidadedesFiltradas: Actividad[], page?: number, size?: number): Actividad[] {
    let actividades = [];
    localidadedesFiltradas.forEach(localidad => {
      actividades.push(localidad);
    });
    if (page && size) {
      actividades = actividades.slice((page - 1) * size, ((page - 1) * size) + size);
    }
    return actividades;
  }

  getNombreAdjunto(adjunto: string): Promise<string>{

    return new Promise( async resolve => {

      const posInicial = 0;
      const longitud = adjunto.indexOf('.');
      const idAdjunto = adjunto.substring(posInicial, longitud);

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/actividad-adjuntos/getNombre/${idAdjunto}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });

    });
  }

  getPathAdjunto(userid: string, file: string): Promise<string>{
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/media/pathFileUserOffer/${userid}/${file}`, { headers }).subscribe( resp => {
        if (resp['ok']) {
            resolve(resp['path']);
        } else {
            this.guiMsjService.msjFormSubmit('documentosEnviadosERROR');
        }
      });
    });
  }

  setActividadSeleccionada(actividad: Actividad) {
    this.actividadSeleccion = actividad;
    this.storageService.setActividad(actividad);
  }

  getActividadSeleccionada(): Actividad {
    return this.actividadSeleccion;
  }

  // Busca si el postulante ya se postulo y aprobo en otras solicitudes
  getPostulanteAprobadoDeActividadesAnteriores(id: string, state: number ): Promise<Actividad[]> {
    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient.get<Response>(`${ URL_BACKEND }/solicitudes/findByIdUserAndStatus/${id}/${state}`, { headers}).subscribe( resp => {
            resolve(resp.response);
        });
    });
  }

  getCostoAdministrativoPagado(id: string, state: number ): Promise<Actividad[]> {
    return new Promise( async resolve => {
        const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient.get<Response>(`${ URL_BACKEND }/solicitudes/findByIdUserAndStatus/${id}/${state}`, { headers }).subscribe( resp => {
            resolve(resp.response);
        });
    });
  }

  getEstado( numberState: number): string {
   let state: string;

   switch (numberState) {
     case  this.iAppOfertas.estado_1.number:
       state =  this.iAppOfertas.estado_1.name;
       break;
     case  this.iAppOfertas.estado_2.number:
      state =  this.iAppOfertas.estado_2.name;
       break;
     case  this.iAppOfertas.estado_3.number:
      state =  this.iAppOfertas.estado_3.name;
       break;
     case  this.iAppOfertas.estado_4.number:
      state =  this.iAppOfertas.estado_4.name;
       break;
     case  this.iAppOfertas.estado_5.number:
      state =  this.iAppOfertas.estado_5.name;
       break;
     case  this.iAppOfertas.estado_6.number:
      state =  this.iAppOfertas.estado_6.name;
       break;
    //  case  this.iAppOfertas.estado_7.number:
    //   state =  this.iAppOfertas.estado_7.name;
    //    break;       
    //  case  this.iAppOfertas.estado_8.number:
    //   state =  this.iAppOfertas.estado_8.name;
    //    break;
    //  case  this.iAppOfertas.estado_9.number:
    //   state =  this.iAppOfertas.estado_9.name;
    //    break;

   
     default:
       break;
   } 

   return state;

  }

}
