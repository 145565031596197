<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Materia</a></li>
                <li>Modificar Materia</li>
                <li>{{materia?.nombre}}</li>
            </ul>
            <h2>Modificar Materia Boletin</h2>
        </div>
    </div>
    <!--<div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="form" (ngSubmit)="put()">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-xs-12">

                                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                    <mat-label>Nombre</mat-label>
                                    <input formControlName="nombre" autofocus matInput placeholder="Nombre" autocomplete="off">
                                </mat-form-field>
                                <mat-slide-toggle formControlName="activa">Activa</mat-slide-toggle>

                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <button type="button" routerLink="/materias-boletin-listado"
                                    class="btn btn-warning">Cancelar</button>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <button [disabled]="!form.valid" type="submit">Guardar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>