import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { GuiMsjService } from '../../../../../services/shared/gui-msj.service';
import { CursosService } from '../../../../../services/cursos/cursos.service';
import { Examen } from '../../../../../interfaces/interface-bakend/cursos/examen.interface';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { MediaService } from 'src/app/services/shared/media.service';
import { ThemePalette } from '@angular/material/core';
import { MateriasBoletinService } from 'src/app/services/cursos/materias-boletin.service';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';

@Component({
  selector: 'app-evaluacion-config',
  templateUrl: './evaluacion-config.component.html',
  styleUrls: ['./evaluacion-config.component.scss']
})
export class EvaluacionConfigComponent implements OnInit {

  public disabled1 = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate1: Date;
  public maxDate: Date;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color1: ThemePalette = 'primary';
  public disableMinute = false;
  public hideTime = false;
  public dateControl = new FormControl(null);


  forma: FormGroup;
  flagFormaValido = false;
  flagProcesando = false;
  flagContinuarPreguntas = false;
  condition = false;
  flagRecuperatorio = false;
  selectedFileImg: any;
  cargandoArchivo = false;

  @Output() guardado = new EventEmitter();
  @Output() moveToTab = new EventEmitter<boolean>();


  /* ripple */
  centered = false;
  disabled = false;
  unbounded = false;

  /* img portada */
  fileAttr = 'Choose File';


  radius: number;
  color: string;


  progress: number;
  infoMessage: any;
  isUploading = false;
  file: File;

  imageUrl: string | ArrayBuffer =
  './assets/img/uploadImg.png';
  fileName = 'No file selected';

  estado: boolean = false;
  mostrarCorrectas: boolean = false;

  materiasBoletin: MateriaBoletin[];

  constructor(
    private router: Router,
    private cursosService: CursosService,
    private guiMsjService: GuiMsjService,
    private examenesService: ExamenesService,
    private mediaService: MediaService,
    private materiasBoletinService: MateriasBoletinService
  ) {

    this.createForm();
    this.createListenersStateForm();
    this.getMateriasBoletin();

  }

  // Funcion que desactiva dias anteriores a la fecha de hoy
  minDate = new Date();

  ngOnInit(): void {
  }

  moveToSelectedTab(flag: boolean){
    this.moveToTab.emit(true);
  }

  async getMateriasBoletin() {
    await this.materiasBoletinService.getMaterias().then((materias: MateriaBoletin[]) => {
      this.materiasBoletin = materias.filter(activas => activas.activa);
    });
  }

  createForm(){
    this.forma = new FormGroup({
      titulo: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required, Validators.max(250)]),
      fechaPublicacion: new FormControl('', [Validators.required]),
      fechaVencimiento: new FormControl('', [Validators.required]),
      horaPublicacion: new FormControl('00:00', [Validators.required]),
      horaVencimiento: new FormControl('00:00', [Validators.required]),
      porcentaje: new FormControl('', [Validators.required, Validators.min (50), Validators.max(100)]),
      intentos: new FormControl(1, [Validators.required, Validators.min(1)]),
      preguntasAleatorias: new FormControl(false),
      cantidadPreguntasAmostrar: new FormControl(0, [  Validators.required, Validators.min(0)] ),
      mostrarRespuestas: new FormControl(false),
      mostrarRespuestasCorrectas: new FormControl(false),
      activo: new FormControl(false),
      materiaBoletin: new FormControl(['', Validators.required]),

      /* recuperatorio */
      recuperatorio: new FormControl(false),
      cantidadRecuperable: new FormControl(0, [ Validators.required,  Validators.min(0), Validators.max(2) ] ),
      fechaPublicaR1: new FormControl(''),
      fechaVenceR1: new FormControl(''),
      fechaPublicaR2: new FormControl(''),
      fechaVenceR2: new FormControl(''),

      porcentajeR: new FormControl(0),
      notaMinimaR: new FormControl(0),
      preguntasAleatoriasR: new FormControl(false),
      repetirPreguntasR: new FormControl(false),
      preguntasDiferentesR: new FormControl(false),
      /* fin recuperatorio */

      tieneLimiteTiempo: new FormControl(false),
      limiteTiempo: new FormControl('02:00', Validators.required),
      imgPortada: new FormControl(''),

    });
  }

  createListenersStateForm() {
    return this.forma.statusChanges.subscribe( async status => {
    //   console.log(this.forma);
    });
  }

  /* MÉTODOS PARA VALIDACIÓN DE FORMULARIO */
  get tituloNoValido() {
    return this.forma.get('titulo').invalid && this.forma.get('titulo').touched;
  }

  get descripcionNoValida() {
    return this.forma.get('descripcion').invalid && this.forma.get('descripcion').touched;
  }

  get fechaPublicacionNoValida() {
    return this.forma.get('fechaPublicacion').invalid && this.forma.get('fechaPublicacion').touched;
  }

  get fechaVencimientoNoValida() {
    return this.forma.get('fechaVencimiento').invalid && this.forma.get('fechaVencimiento').touched;
  }

  get porcentajeNoValido() {
    return this.forma.get('porcentaje').invalid && this.forma.get('porcentaje').touched;
  }

  get intentosNoValido() {
    return this.forma.get('intentos').invalid && this.forma.get('intentos').touched;
  }

  get fechaPublicaR1Novalida() {
    return this.forma.get('fechaPublicaR1').invalid && this.forma.get('fechaPublicaR1').touched;
  }

  get fechaVenceR1NoValida() {
    return this.forma.get('fechaVenceR1').invalid && this.forma.get('fechaVenceR1').touched;
  }

  get fechaPublicaR2Novalida() {
    return this.forma.get('fechaPublicaR2').invalid && this.forma.get('fechaPublicaR2').touched;
  }

  get fechaVenceR2NoValida() {
    return this.forma.get('fechaVenceR2').invalid && this.forma.get('fechaVenceR2').touched;
  }

  get porcentajeRnoValido() {
    return this.forma.get('porcentajeR').invalid && this.forma.get('porcentajeR').touched;
  }


  /* COMPARAR FECHAS */
  compararFechas(event) {
    if (this.forma.value.fechaPublicacion > this.forma.value.fechaVencimiento){
        this.forma.controls.fechaVencimiento.setErrors({incorrect: true});
        return this.forma.get('fechaPublicacion').invalid && this.forma.get('fechaPublicacion').touched;
    } else {
        return;
    }
  }

  compararFechasRecuperatorio1(event) {
    if (
      this.forma.value.fechaPublicaR1 > this.forma.value.fechaVenceR1 ||
      this.forma.value.fechaVencimiento > this.forma.value.fechaPublicaR1
      ) {
        this.forma.controls.fechaVenceR1.setErrors({incorrect: true});
        return this.forma.get('fechaPublicaR1').invalid && this.forma.get('fechaPublicaR1').touched;
    } else {
        return;
    }
  }

  compararFechasRecuperatorio2(event) {
    if (this.forma.value.fechaPublicaR2 < this.forma.value.fechaVenceR1 ||
      this.forma.value.fechaVenceR2 < this.forma.value.fechaVenceR1 ||
      this.forma.value.fechaPublicaR1 > this.forma.value.fechaVenceR2
      ){
        this.forma.controls.fechaVenceR2.setErrors({incorrect: true});
        return this.forma.get('fechaPublicaR2').invalid && this.forma.get('fechaPublicaR2').touched;
    } else {
        return;
    }
  }

  disabledForma(){
      this.forma.controls['titulo'].disable();
      this.forma.controls['descripcion'].disable();
      this.forma.controls['fechaPublicacion'].disable();
      this.forma.controls['fechaVencimiento'].disable();
      this.forma.controls['porcentaje'].disable();
      this.forma.controls['intentos'].disable();
      this.forma.controls['cantidadPreguntasAmostrar'].disable();
      this.forma.controls['preguntasAleatorias'].disable();
      this.forma.controls['mostrarRespuestas'].disable();
      this.forma.controls['mostrarRespuestasCorrectas'].disable();
      this.forma.controls['recuperatorio'].disable();
      this.forma.controls['cantidadRecuperable'].disable();
      this.forma.controls['fechaPublicaR1'].disable();
      this.forma.controls['fechaVenceR1'].disable();
      this.forma.controls['fechaPublicaR2'].disable();
      this.forma.controls['fechaVenceR2'].disable();
      this.forma.controls['activo'].disable();
      this.forma.controls['tieneLimiteTiempo'].disable();
      this.forma.controls['limiteTiempo'].disable();
      this.forma.controls['porcentajeR'].disable();
      this.forma.controls['preguntasAleatoriasR'].disable();
      this.forma.controls['repetirPreguntasR'].disable();
      this.forma.controls['preguntasDiferentesR'].disable();
      this.forma.controls['horaPublicacion'].disable();
      this.forma.controls['horaVencimiento'].disable();
      this.forma.controls['imgPortada'].disable();
      this.forma.controls['notaMinimaR'].disable();
      this.forma.controls['materiaBoletin'].disable();
  }

  controlRecuperatorio(examen: Examen): boolean {
  let flag = true;
  if (this.forma.value.recuperatorio) {

    if (this.forma.value.porcentajeR >= 60 &&
      this.forma.value.notaMinimaR >= 0 &&
      this.forma.value.cantidadRecuperable >= 1
    ){
      flag = false;
      if (this.forma.value.cantidadRecuperable  === 1) {
        if (
          this.forma.value.fechaPublicaR1 !== '' && this.forma.value.fechaVenceR1 !== '' &&
          this.forma.value.fechaPublicaR1 !== null && this.forma.value.fechaVenceR1 !== null

        ) {
          return flag = true;
        } else {
          this.flagProcesando = false;
          this.guiMsjService.msjFormSubmit('recuperatorioFechas');
          return flag;
        }
      } else {
        if (this.forma.value.cantidadRecuperable  >= 2) {
          if (this.forma.value.fechaPublicaR1 !== '' && this.forma.value.fechaVenceR1 !== ''
            &&
            this.forma.value.fechaPublicaR2 !== '' && this.forma.value.fechaVenceR2 !== ''
            &&
            this.forma.value.fechaPublicaR1 !== null && this.forma.value.fechaVenceR1 !== null
            &&
            this.forma.value.fechaPublicaR2 !== null && this.forma.value.fechaVenceR2 !== null
            ) {
            return flag = true;
          } else  {
            this.flagProcesando = false;
            this.guiMsjService.msjFormSubmit('recuperatorioFechas');
            return flag;
          }
        } else {
          this.flagProcesando = false;
          this.guiMsjService.msjFormSubmit('recuperatorioCant');
        }
      }
    } else {
      this.flagProcesando = false;
      this.guiMsjService.msjFormSubmit('recuperatorioConfig');
    }
  } else {
        examen.cantidadRecuperable = 0;
        examen.fechaPublicaR1 = null;
        examen.fechaPublicaR2 = null;
        examen.fechaVenceR1 = null;
        examen.fechaVenceR2 = null;
        return flag;
      }
  }

  /* FIN MÉTODOS VALIDACION FORMULARIO */

  async setDataForma(): Promise<Examen>{

    const examen: Examen = {
      titulo: this.forma.value.titulo,
      descripcion: this.forma.value.descripcion,
      fechaPublicacion: this.forma.value.fechaPublicacion,
      fechaVencimiento: this.forma.value.fechaVencimiento,
      porcentajeAprobacionMinimo: this.forma.value.porcentaje,
      mostrarRespuesta: this.forma.value.mostrarRespuestas,
      mostrarRespuestaCorrectas: this.forma.value.mostrarRespuestasCorrectas,
      tieneLimiteTiempo: this.forma.value.tieneLimiteTiempo,
      intentos: this.forma.value.intentos,
      activo: this.forma.value.activo,
      recuperable: this.forma.value.recuperatorio,
      cantidadRecuperable: this.forma.value.cantidadRecuperable,
      fechaPublicaR1: this.forma.value.fechaPublicaR1,
      fechaVenceR1: this.forma.value.fechaVenceR1,
      fechaPublicaR2: this.forma.value.fechaPublicaR2,
      fechaVenceR2: this.forma.value.fechaVenceR2,
      preguntasAleatorias: this.forma.value.preguntasAleatorias,
      cantidadPreguntasAmostrar: this.forma.value.cantidadPreguntasAmostrar,
      porcentajeR: this.forma.value.porcentajeR,
      notaMinimaR: this.forma.value.notaMinimaR,
      preguntasAleatoriasR: this.forma.value.preguntasAleatoriasR,
      repetirPreguntasR: this.forma.value.repetirPreguntasR,
      preguntasDiferentesR: this.forma.value.preguntasDiferentesR,
      imgPortada: this.forma.value.imgPortada,
      limiteTiempo :  {
        hours: +(this.forma.value.limiteTiempo.substring(0, 2)),
        minutes: +(this.forma.value.limiteTiempo.substring(3, 5)),
      },
      horaPublicacion :  {
        hours: +(this.forma.value.horaPublicacion.substring(0, 2)),
        minutes: +(this.forma.value.horaPublicacion.substring(3, 5)),
      },
      horaVencimiento :  {
        hours: +(this.forma.value.horaVencimiento.substring(0, 2)),
        minutes: +(this.forma.value.horaVencimiento.substring(3, 5)),
      },
      materiaBoletin: this.forma.value.boletin
    };
    return examen;
  }

  /**
   * Guarada la config. ppal. del examen en la base, en el servicio, y en el storage
   */
  async guardarConfigGralExamen(){

    this.flagProcesando = true;
    const examen: Examen = await this.setDataForma();

    if (this.controlRecuperatorio(examen)) {
      this.disabledForma();
      this.forma.setErrors({ invalid: true });
      const exSave = await this.cursosService.postExamenNuevo(examen);
      if (exSave) {
        await this.uploadFileImg(this.selectedFileImg, exSave._id);
        await this.cursosService.setConfigExamen(exSave);
        this.guiMsjService.msjFormSubmit('configGuardadaOK');
        this.guardado.emit(true);
        this.examenesService.setExamen(exSave);
        this.flagProcesando = false;
        this.flagContinuarPreguntas = true;
      } else {
        this.guiMsjService.msjFormSubmit('configGuardadaError');
      }
    } else {
      return;
    }

  }

  onChangeFile(file: File, event) {
      if (file) {

        this.openFileImgSelected(event);
        this.fileName = file.name;
        this.file = file;

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = e => {
        this.imageUrl = reader.result;
        };
      }
  }

  onUpload() {
      this.infoMessage = null;
      this.progress = 0;
      this.isUploading = true;
  }


  openFileImgSelected(event) {
      // console.log('seleciono img');
      this.selectedFileImg = <File> event.target.files[0];
  }

  uploadFileImg(selectedFile, idCurso) {
      // console.log('upload');
      if (selectedFile) {
        this.mediaService.uploadImgPortadaExamen(selectedFile, idCurso).then( resp => {
            if (resp) {
             this.cargandoArchivo = false;
            } else {
            this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
            }
          });
      }
  }

  onClick(estado: boolean){
    if(estado === false){
      this.mostrarCorrectas = false;
    }
  }


}
