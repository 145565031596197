<div class="col-sm-12" *ngIf="flagLoaded">
    <section>
        <br>
        <ks-carousel 
            [id]="0" 
            [images]="imagesRect" 
            [carouselConfig]="{maxWidth: '766px', maxHeight: '400px', showArrows: true, objectFit: 'cover', keyboardEnable: true, modalGalleryEnable: true, legacyIE11Mode: false}" 
            [previewConfig]="{visible: true, number: 5, width: 'auto', maxHeight: '100px'}"
            >           
        </ks-carousel>
    </section>
</div>
