<div class="page-title-area">
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div>

                <app-loading *ngIf="!flagLoadedModal" class="m-5"></app-loading><br>

                <mat-card class="search-card">
                    <h3>Seleccione un Tipo de preescripción para filtrar</h3>
                    <div class="example-button-row">
                        <button mat-button mat-raised-button color="warn" [ngClass]="{'selected-button': !filtroPista}"
                            (click)="filtroActividades(false)" style="margin-right: 5px;">Online</button>
                        <button mat-button mat-raised-button color="basic" [ngClass]="{'selected-button': filtroPista}"
                            (click)="filtroActividades(true)" style="margin-right: 5px;">Presenciales</button>
                    </div>
                    <br>
                    <div *ngIf="filtroPista; else elseBlock">
                        <p>Filtrando por tipo Presenciales</p>
                    </div>

                    <ng-template #elseBlock>
                        <p>Filtrando por tipo Online</p>
                    </ng-template>

                    <div class="container mt-4">
                        <mat-form-field class="mr-2">
                            <mat-label>Desde<span style="color: red">*</span></mat-label>
                            <input matInput [matDatepicker]="pickerDesde" placeholder="Seleccione una fecha"
                                [(ngModel)]="fechaDesde">
                            <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDesde></mat-datepicker>
                        </mat-form-field>

                        <div style="width: 20px;"></div>

                        <mat-form-field class="mr-2">
                            <mat-label>Hasta<span style="color: red">*</span></mat-label>
                            <input matInput [matDatepicker]="pickerHasta" placeholder="Seleccione una fecha"
                                [(ngModel)]="fechaHasta">
                            <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
                            <mat-datepicker #pickerHasta></mat-datepicker>
                        </mat-form-field>

                        <div style="width: 20px;"></div>


                        <mat-form-field appearance="fill" class="custom-margin-bottom">
                            <mat-label>Selecciona un estado<span style="color: red">*</span></mat-label>
                            <mat-select [(ngModel)]="selectedEstados" [disabled]="!dataSource"
                                (selectionChange)="selectEstados($event.value)" name="estados" multiple>
                                <mat-option (click)="toggleSelectAll($event)" [value]="selectAllValue">
                                    {{ isAllSelected() ? 'Deseleccionar todo' : 'Seleccionar todo' }}
                                </mat-option>
                                <mat-option *ngFor="let estado of iAppOfertas.selectEstados" [value]="estado.number">
                                    {{estado.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div style="width: 20px;"></div>

                        <mat-form-field appearance="fill" class="custom-margin-bottom" *ngIf="filtroPista">
                            <mat-label>Selecciona una delegación<span style="color: red">*</span></mat-label>
                            <mat-select [(ngModel)]="delegationSelected" [disabled]="!dataSource" name="delegacion">
                                <mat-option *ngFor="let delegacion of selectorDelegaciones"
                                    [value]="delegacion.concepto">
                                    {{delegacion.concepto}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>


                        <div style="width: 20px;"></div>

                        <!-- Botones para manejo de nuevas solicitudes -->
                        <div class="buttons-container">
                            <div class="button-container">
                                <button mat-raised-button color="primary" (click)="aceptarSeleccion()">Buscar</button>
                                <div style="width: 20px;"></div>
                            </div>

                        </div>

                    </div>

                    <div class="container mt-4">
                        <label>Resultados encontrados en - TAB - <strong>{{nombrePreescripcion}}</strong>: <strong>{{
                                dataSourceLength }}</strong></label>
                    </div>

                </mat-card>

                <!-- Mostrar la cantidad de solicitudes encontradas -->
                <div *ngIf="flagLoaded">
                    <br>
                    <p>Cantidad de solicitudes encontradas: {{ cantidadSolicitudesTotal }}</p>
                    <p>Cantidad de solicitudes pendientes: {{ cantidadSolicitudesPendientes }}</p>
                    <p>Cantidad de solicitudes leídas: {{ cantidadSolicitudesLeidas }}</p>
                    <!-- Acordeones para Leídas y Pendientes -->
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>Leídas</mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngFor="let key of getKeys(solicitudCounts.leidas)">
                                {{ key }}: {{ solicitudCounts.leidas[key] }}
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>Pendientes</mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngFor="let key of getKeys(solicitudCounts.pendientes)">
                                {{ key }}: {{ solicitudCounts.pendientes[key] }}
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

                <hr style="margin-top: 20px; margin-bottom: 20px;">

                <mat-form-field>
                    <mat-label>Buscar preescripción aprox...</mat-label>
                    <input matInput [(ngModel)]="filtroBusqueda" (input)="filtrarPreescripcion()"
                        placeholder="Ingrese el nombre de la preescripcion">
                </mat-form-field>

                <mat-tab-group (selectedTabChange)="selectPreescripcion($event)">
                    <!-- <mat-tab *ngFor="let preescripcion of preescripcionesFiltradas" label="{{ preescripcion.nombre }}"> -->
                        <mat-tab *ngFor="let preescripcion of preescripcionesFiltradas" [label]="getTabLabel(preescripcion)">
                        <!-- Contenido de cada tab -->
                    </mat-tab>
                </mat-tab-group>

                <br>

                <mat-form-field>
                    <mat-label>Filtrar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Nro. Solicitud...">
                </mat-form-field>

                <div class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" matSort> <!-- Agregado matSort a la tabla -->

                        <!-- Columna: Nro. Solicitud -->
                        <ng-container matColumnDef="nro_solicitud">
                            <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> Nro. </th>
                            <td mat-cell *matCellDef="let element" class="column-cell"> {{element.nro_solicitud}} </td>
                        </ng-container>

                        <!-- Columna: Fecha Solicitud -->
                        <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> Sol. Fecha
                            </th>
                            <td mat-cell *matCellDef="let element" class="column-cell"> {{element.createdAt |
                                date:"dd/MM/yy"}} </td>
                        </ng-container>

                        <!-- Columna: Tipo -->
                        <!-- <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> Preescripción </th>
                        <td mat-cell *matCellDef="let element" class="column-cell"> {{element.tipo }} </td>
                    </ng-container>       -->

                        <!-- Columna: Actividad -->
                        <!-- <ng-container matColumnDef="actividad">
                            <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> Delegación
                            </th>
                            <td mat-cell *matCellDef="let element" class="column-cell"> {{element.actividad }} </td>
                        </ng-container> -->
              
                        <!-- Columna: Actividad -->
                        <ng-container matColumnDef="cursoNombre">
                            <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> Módulo </th>
                            <td mat-cell *matCellDef="let element" class="column-cell"> {{element.cursoNombre }} </td>
                        </ng-container>

                        <!-- Columna: Descripcion delegacion -->
                        <ng-container matColumnDef="descripcion">
                            <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> Descripción </th>
                            <td mat-cell *matCellDef="let element" class="column-cell"> {{element.descripcion }} </td>
                        </ng-container>


                        <!-- Columna: Fecha Actividad -->
                        <!-- <ng-container matColumnDef="fechaActividad">
                        <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> Act. Fecha  </th>
                        <td mat-cell *matCellDef="let element" class="column-cell"> {{element.fechaActividad | date:"dd/MM/yy"}} </td>
                    </ng-container>           -->

                        <!-- Columna: Nombre -->
                        <ng-container matColumnDef="usuario_nombrePersona">
                            <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> Nombre </th>
                            <td mat-cell *matCellDef="let element" class="column-cell"> {{element.usuario_nombrePersona
                                | nombreExiste}} </td>
                        </ng-container>

                        <!-- Columna: Identificación -->
                        <ng-container matColumnDef="usuario_identificacion">
                            <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> D.N.I </th>
                            <td mat-cell *matCellDef="let element" class="column-cell"> {{element.usuario_identificacion
                                | nombreExiste}} </td>
                        </ng-container>

                        <!-- Columna: Email -->
                        <ng-container matColumnDef="usuario_email">
                            <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> Email </th>
                            <td mat-cell *matCellDef="let element" class="column-cell"> {{element.usuario_email || 'SIN
                                DATOS'}} </td>
                        </ng-container>

                        <!-- Columna: Estado -->
                        <ng-container matColumnDef="lastState_numberState">
                            <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> Estado </th>
                            <td mat-cell *matCellDef="let element" class="column-cell"
                                [ngStyle]="{'color': getColor(element.lastState_numberState)}">
                                {{ getStatusText(element.lastState_numberState) }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="condicion">
                            <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> Condicion </th>
                            <td mat-cell *matCellDef="let element" class="column-cell custom-width">
                                <mat-select [(value)]="element.condicion" (selectionChange)="updateCondicion(element)">
                                    <mat-option value="Aprobado">Aprobado</mat-option>
                                    <mat-option value="Promociona">Promociona</mat-option>
                                    <mat-option value="No Aprobado">No Aprobado</mat-option>
                                    <mat-option value="No Asistió">No Asistió</mat-option>
                                    <mat-option value="Lista de Espera">Lista de Espera</mat-option>
                                    <mat-option value="Rinde Nuevamente">Rinde Nuevamente</mat-option>
                                </mat-select>
                            </td>
                        </ng-container>

                        <!-- Columna: Documentos Adjuntos Generales -->
                        <ng-container matColumnDef="documentosAdjuntosPersonales">
                            <th mat-header-cell *matHeaderCellDef class="column-header"> Doc. P. </th>
                            <td mat-cell *matCellDef="let element" class="column-cell">
                                <mat-form-field class="custom-width">
                                    <mat-label> Doc. Personales </mat-label>
                                    <mat-select [(ngModel)]="element.selectedDocumentsPersonal"
                                        placeholder="Seleccionar" multiple>
                                        <mat-option *ngFor="let documento of element.usuarioAdjuntos"
                                            [value]="documento.id" [disabled]="true">
                                            {{documento.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Columna: Documentos Adjuntos Solicitud -->
                        <ng-container matColumnDef="documentosAdjuntosSolicitud">
                            <th mat-header-cell *matHeaderCellDef class="column-header"> Doc. S. </th>
                            <td mat-cell *matCellDef="let element" class="column-cell">
                                <mat-form-field class="custom-width">
                                    <mat-label> Doc. Solicitud</mat-label>
                                    <mat-select
                                        [(ngModel)]="element.documentosSolicitudEstado.selectedDocumentsSolicitud"
                                        placeholder="Seleccionar" multiple>
                                        <ng-container
                                            *ngIf="element.documentosSolicitudEstado.documentosAdjuntosSolicitud && element.documentosSolicitudEstado.documentosAdjuntosSolicitud.length > 0; else noDocuments">
                                            <mat-option
                                                *ngFor="let documento of element.documentosSolicitudEstado.documentosAdjuntosSolicitud "
                                                [value]="documento._id" [disabled]="true">
                                                {{documento.documento.nombre}}
                                            </mat-option>
                                        </ng-container>
                                        <ng-template #noDocuments>
                                            <mat-option disabled>Sin documentos</mat-option>
                                        </ng-template>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="leido">
                            <th mat-header-cell *matHeaderCellDef> Visto </th>
                            <td mat-cell *matCellDef="let element">
                                <div style="display: inline-flex;">
                                    <button (click)="verSolicitud(element)" mat-raised-button class="btn btn-success"
                                        style="margin-right: 5px;">
                                        <i class="fas" [ngClass]="{'fa-eye': element.leido, 'fa-eye-slash': !element.leido}"
                                            [ngStyle]="{'color': !element.leido ? 'blue' : ''}"></i>
                                    </button>                                  
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="progreso">
                            <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> Progreso </th>
                            <td mat-cell *matCellDef="let element" class="column-cell" style="min-width: 80px;">
                                <div class="progress-container">
                                    <div class="progress">
                                        <div class="progress-bar-background"></div>
                                        <div class="progress-bar"
                                            [style.width]="calculatePercentage(element.lastState_numberState) + '%'"
                                            [style.backgroundColor]="getColor(element.lastState_numberState)">
                                            <span class="progress-text">{{
                                                calculatePercentage(element.lastState_numberState) }}%</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>


                        <!-- Columna: Fecha Libres -->
                        <ng-container matColumnDef="fechaLibreSolicitudes">
                            <th mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header> F. Libres Sol.
                            </th>
                            <td mat-cell *matCellDef="let element" class="column-cell">
                                <mat-slide-toggle [(ngModel)]="element.fechaLibreSolicitudes"
                                    (change)="onToggleChangeFechalibre(element)">
                                </mat-slide-toggle>
                            </td>
                        </ng-container>


                        <!-- Columna: Acciones -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Acciones </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-menu #accionesMenu="matMenu">
                                    <button mat-menu-item (click)="verSolicitud(element)">
                                        <i class="fas fa-eye"></i> Ver Solicitud
                                    </button>
                                    <button mat-menu-item (click)="cargarPersona(element)">
                                        <i class="fa fa-user"></i> Ver Persona
                                    </button>
                                    <button mat-menu-item (click)="verPuntajesCargados(element)">
                                        <i class="fa fa-hand-spock-o"></i> Ver Puntajes Cargados
                                    </button>
                                    <button mat-menu-item>
                                        <i class="fas fa-file"></i> Enviar Título al Alumno
                                    </button>
                                </mat-menu>
                                <button mat-icon-button [matMenuTriggerFor]="accionesMenu">
                                    <i class="fa fa-ellipsis-v"></i>
                                </button>
                            </td>
                        </ng-container>

                        <!-- Definición de filas -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <!-- Fila que se muestra cuando no hay datos coincidentes -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro</td>
                        </tr>

                    </table>

                    <!-- <app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br> -->

                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"
                        aria-label="Selecciona página de solicitudes"></mat-paginator>


                    <!-- Otros códigos HTML -->

                    <div class="export-button-container"
                        style="margin-top: 10px; margin-bottom: 10px; text-align: right;">
                        <button mat-raised-button color="primary" (click)="exportExcel()">Exportar a Excel</button>
                    </div>
                    <br>

                    <!-- Otros códigos HTML -->


                </div>

                <!-- loading.component.html -->
                <mat-progress-bar *ngIf="!flagLoaded" mode="indeterminate"></mat-progress-bar>
                <div *ngIf="!flagLoaded" class="loading-message">Procesando, por favor espere...</div>

            </div>
        </div>
    </div>
</div>

<!-- Loading Spinner -->
<div *ngIf="loading" class="loading-overlay">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>