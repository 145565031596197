import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Alumno } from 'src/app/interfaces/interface-bakend/cursos/alumno.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';

@Component({
  selector: 'app-cursos-alumnos-modificar',
  templateUrl: './cursos-alumnos-modificar.component.html',
  styleUrls: ['./cursos-alumnos-modificar.component.scss']
})
export class CursosAlumnosModificarComponent implements OnInit {

   /* Data Table*/
   @ViewChild(MatSort) sort: MatSort;
   @ViewChild(MatPaginator) paginator: MatPaginator;
   displayedColumns: string[] =
   [
     'apellidoPersona', 'nombrePersona', 'email', '_id'
   ];
   dataSource: any;

   /* Fin Data Table*/
   usuarioLoad = false;
   flagLoaded = false;

   constructor(
       private cursosService: CursosService,
       private perfilesService: PerfilesService,
       private router: Router,
   ) { }

   ngOnInit(): void {
       this.perfilesService.allowedByUserLogon(this.router.url);
   }

   async ngAfterViewInit() {
       await this.loadDataPage();
       this.dataSource.paginator = this.paginator;
       this.dataSource.sort = this.sort;
       this.flagLoaded = true;
   }

   async loadDataTable(){
       await this.cursosService.getAlumnos().then( (alumnos: Alumno[]) => {
        this.formatDataTable(alumnos).then( ( data: any[] ) => {  
          this.dataSource = new MatTableDataSource(data);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
       });
   }

   applyFilter(event: Event) {
       const filterValue = (event.target as HTMLInputElement).value;
       this.dataSource.filter = filterValue.trim().toLowerCase();
   }

   async loadDataPage() {
       await this.loadDataTable();
       this.flagLoaded = true;
       return;
   }

   formatDataTable( alumnos: any[]) {
    return new Promise( resolve => {
      const format: any[] = [];
      let elemento: {
        apellidoPersona: string,
        nombrePersona: string,
        email: string,        
        _id: ''
        // fechaInicia: Date,
        // fechaFinaliza: Date,
      };

       // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < alumnos.length; i++) {
        elemento = {
            apellidoPersona: alumnos[i].usuario.apellidoPersona,
            nombrePersona: alumnos[i].usuario.nombrePersona,
            email: alumnos[i].usuario.email,            
            _id: alumnos[i]._id               
      };
        format.push(elemento);
      }
      resolve(format);
    });
   }

   async verCursosAlumno(alumno) {
    await this.cursosService.setAlumnoSeleccionado(alumno);
    this.router.navigateByUrl('/alumnos-cursos-modificacion');
   }



}
