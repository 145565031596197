<mat-card class="example-card">
    <mat-card-header>
        <button class="close" mat-button [mat-dialog-close]="true">X</button>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>AADIDESS - I.S.E.S</mat-card-title>
        <mat-card-subtitle> {{ idioma | translate: "legales.LEGAL-CTF-TC" | async}}</mat-card-subtitle>
    </mat-card-header>
    <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
    <mat-card-content>
        <p>
            {{ idioma | translate: "form.DECLARACIÓN_AADIDESS_ISES_TEXT" | async}}
        </p>
    </mat-card-content>
</mat-card>
<mat-dialog-actions align="center">
    <button mat-raised-button mat-button [mat-dialog-close]="true">{{ idioma | translate: "form.BTN-CERRAR" | async}}</button>
</mat-dialog-actions>