import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';
import { MateriasBoletinService } from 'src/app/services/cursos/materias-boletin.service';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { APP_CONFIG_SHARED, IAppShared } from 'src/app/app.config/app.config-shared';
import { Boletin } from 'src/app/interfaces/interface-bakend/cursos/boletin.interface';
import { BoletinesService } from 'src/app/services/cursos/boletines.service';
import { MateriaBoletinDetalle } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin-detalle.interface';
import { AlumnoBoletin } from 'src/app/interfaces/interface-bakend/cursos/alumno-boletin.interface';
import { Alumno } from 'src/app/interfaces/interface-bakend/cursos/alumno.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { ExamenEvaluacion } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';

@Component({
  selector: 'app-boletin-modificacion',
  templateUrl: './boletin-modificacion.component.html',
  styleUrls: ['./boletin-modificacion.component.scss']
})
export class BoletinModificacionComponent implements OnInit {

  flagFormaValido = false;
  form: FormGroup;
  materiasBoletines: MateriaBoletin[];
  modalidadCursadas: Catalogo[];
  modalidadExamenes: Catalogo[];
  alumnoBoletinSeleccionado: AlumnoBoletin;
  boletinSeleccionado: string;
  boletines: Boletin[] = [];
  alumno: Alumno;
  materiasOriginales: MateriaBoletinDetalle[];

  constructor(
    private fb: FormBuilder,
    private guiMsjService: GuiMsjService,
    private materiasBoletinesService: MateriasBoletinService,
    private boletinService: BoletinesService,
    private catalogoService: CatalogoService,
    private examenEvaluacionService: ExamenesService,
    @Inject(APP_CONFIG_SHARED) private iAppShared: IAppShared,
    private cursosService: CursosService,
    private router: Router
  ) {
    this.getMateriasBoletines();
    this.getCatalogo();
    this.getBoletines();
  }

  ngOnInit(): void {
    this.createForm();
    this.getEstadoVariables();
  }

  async getBoletines() {
    await this.boletinService.getBoletines().then((boletines: Boletin[]) => {
      this.boletines = boletines;
    });
  }

  async getEstadoVariables() {
    if (this.getAlumnoBoletinSeleccionado() === undefined) {
      this.router.navigateByUrl('/alumnos-boletines-listado');
      return;
    } else {
      this.getAlumnoBoletinSeleccionado();
      this.getAlumno();
    }
  }

  async getCatalogo() {
    try {
      const modalidadCursadas: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.modalidadCursada, null);
      const modalidadExamenes: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.modalidadExamen, null);
      this.modalidadCursadas = modalidadCursadas;
      this.modalidadExamenes = modalidadExamenes;
    } catch (error) {
      console.error('Error al cargar modalidades', error);
    }
  }

  async getMateriasBoletines() {
    await this.materiasBoletinesService.getMaterias().then((materiasBoletines: MateriaBoletin[]) => {
      this.materiasBoletines = materiasBoletines;
    });
  }

  createForm() {
    this.form = this.fb.group({
      boletin: ['', Validators.required],
      materias: this.fb.array([])
      //materias: this.fb.array([], this.minMateriasValidator)
    });
  }

  get materias() {
    return this.form.get('materias') as FormArray;
  }

  addMateria() {
    const materiaForm = this.fb.group({
      materia: ['', Validators.required],
      modalidadCursada: ['', Validators.required],
      modalidadExamen: ['', Validators.required],
      fechaExamen: [''],
      fechaNota: [''],
      notaExamen: ['', [Validators.min(0), Validators.max(100)]]
    });

    this.materias.push(materiaForm);
  }

  // Validador para mínimo de materias
  minMateriasValidator(control: FormArray): { [key: string]: boolean } | null {
    if (control.length < 1) {
      return { 'minMaterias': true };
    }
    return null;
  }

  quitarMateria(index: number) {
    const confirmed = window.confirm('¿Estás seguro de que deseas eliminar esta materia?');
    if (confirmed) {
      this.materias.removeAt(index);
    }
  }

  async getAlumno() {
    this.alumno = await this.cursosService.getAlumnoSeleccionado();
    if (!this.alumno) {
      //this.router.navigate(['/usuarios-listado']);
    }
  }

  async getAlumnoBoletinSeleccionado() {
    const alumnoBoletin = this.boletinService.getAlumnoBoletinSeleccionado();
    if (alumnoBoletin) {
      this.boletinSeleccionado = alumnoBoletin.boletin;
      this.alumnoBoletinSeleccionado = alumnoBoletin;
      await this.loadBoletinData(alumnoBoletin);
      this.form.patchValue({
        boletin: alumnoBoletin.boletin
      });
      return alumnoBoletin;
    } else {
      this.router.navigateByUrl('/alumnos-boletines-listado');
    }
  }

  async loadBoletinData(alumnoBoletin: AlumnoBoletin) {
    if (alumnoBoletin) {
      // Limpia el FormArray de materias antes de agregar nuevas
      this.materias.clear();

      this.form.patchValue({
        alumno: alumnoBoletin.boletin,
        boletin: alumnoBoletin._id
      });

      // Guardo las materias originales antes de editar
      this.materiasOriginales = alumnoBoletin.materias;

      if (alumnoBoletin.materias && Array.isArray(alumnoBoletin.materias)) {
        alumnoBoletin.materias.forEach(materia => {
          const materiaForm = this.fb.group({
            materia: [materia.materia, Validators.required],
            modalidadCursada: [materia.modalidadCursada, Validators.required],
            modalidadExamen: [materia.modalidadExamen, Validators.required],
            fechaExamen: [materia.fechaExamen],
            fechaNota: [materia.fechaNota],
            notaExamen: [materia.notaExamen, [Validators.min(0), Validators.max(100)]]
          });

          this.materias.push(materiaForm);
        });
      }
    } else {
      console.error('alumnoBoletin es undefined');
    }
  }

  async setDataForm(): Promise<AlumnoBoletin> {
    const data: AlumnoBoletin = {
      alumno: this.alumnoBoletinSeleccionado.alumno,
      boletin: this.form.value.boletin,
      materias: this.form.value.materias.map((materia: MateriaBoletinDetalle) => ({
        materia: materia.materia,
        modalidadCursada: materia.modalidadCursada,
        modalidadExamen: materia.modalidadExamen,
        fechaExamen: materia.fechaExamen,
        fechaNota: materia.fechaNota,
        notaExamen: materia.notaExamen
      }))
    };
    return data;
  }

  async put() {
    await this.guiMsjService.msjFormSubmit('Guardando');

    // Array de materias que ya están en boletin antes de modificar
    const materiasCargadas = this.materiasOriginales || [];
    const nuevasMaterias = await (await this.setDataForm()).materias || [];

    // Filtrar materias eliminadas
    const materiasEliminadasBoletin = materiasCargadas.filter(existeMateria =>
      existeMateria.examenEvaluacionId != null && // Verificar que examenEvaluacionId no sea nulo para saber que viene desde un examen
      !nuevasMaterias.some(newMateria => newMateria.materia.toString() === existeMateria.materia.toString())
    );

    // Por cada materia eliminada actualizo examen evaluacion para poder volver a enviar a boletin
    for (const materia of materiasEliminadasBoletin) {
      const data: Partial<ExamenEvaluacion> = {
        materiaBoletinEnviada: false,
        fechaMateriaEnviada: null
      };
      // Actualizo el examen evaluacion, seteo en false el envio de boletin para poder volver a enviarlo
      await this.examenEvaluacionService.evaluacionBoletinUpdate(materia.examenEvaluacionId.toString(), data);
    }

    try {

      // Actualizo alumno boletines
      await this.boletinService.put(this.alumnoBoletinSeleccionado._id, await this.setDataForm());
    } catch (error) {
      console.error('Error al guardar el boletín', error);
    }
  }

  get nombreNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }
}
