<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading>
<div *ngIf="flagLoaded" class="profile-area pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <h3>Listado de Cursos</h3>
            <hr>
            <div>
                <mat-form-field>
                    <mat-label>Filtrar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
                </mat-form-field>

                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                    <!-- Checkbox Column  -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox [checked]="selection.hasValue() &&  isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" (change)="masterToggle()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
                            <mat-checkbox (change)="onChangeToggled(row)" [checked]="selection.isSelected(row)">

                            </mat-checkbox>
                        </td>
                    </ng-container>


                    <!-- Column -->
                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let elemento"> {{elemento.nombre}} </td>
                    </ng-container>


                    <!-- Column -->
                    <ng-container matColumnDef="introduccion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Introducción Corta </th>
                        <td mat-cell *matCellDef="let elemento"> {{elemento.introduccion }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="fechaInicio">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Inicio </th>
                        <td mat-cell *matCellDef="let elemento"> {{elemento.fechaInicio | date:"dd/MM/yy"}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="fechaFin">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Fin </th>
                        <td mat-cell *matCellDef="let elemento"> {{elemento.fechaFin | date:"dd/MM/yy"}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="disponible">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Disponible </th>
                        <td mat-cell *matCellDef="let elemento" style="text-align: center"> {{elemento.disponible }} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-elemento-row"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                <div *ngIf="flagAsignando">
                    <br>
                    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
                </div>
            </div>

            <div mat-dialog-actions align="end">
                <button *ngIf="!this.disabled" [disabled]="!this.flagSelected" (click)="asignarExamen()" mat-raised-button color="accent">Asignar</button>
            </div>
            <div *ngIf="this.disabled" class="alert alert-success" role="alert">
                Cursos asignados correctamente..!
            </div>
        </div>
    </div>
</div>