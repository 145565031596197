import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Mesa } from 'src/app/interfaces/interface-bakend/cursos/mesa.interface';
import { MesasService } from 'src/app/services/cursos/mesas.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { GestosEvaluacionesUsuariosListadoComponent } from '../gestos-evaluaciones-usuarios-listado/gestos-evaluaciones-usuarios-listado.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-modal-elegir-mesa-examen',
  templateUrl: './modal-elegir-mesa-examen.component.html',
  styleUrls: ['./modal-elegir-mesa-examen.component.scss']
})
export class ModalElegirMesaExamenComponent implements OnInit {
  mesas: Mesa[];
  filteredMesas: Observable<Mesa[]>;
  mesaControl = new FormControl(); // Variable para el control del filtro

  constructor(
    private mesasService: MesasService,
    private guiMsjService: GuiMsjService,
    private router: Router,
    private dialogRef: MatDialogRef<GestosEvaluacionesUsuariosListadoComponent>
  ) {
    this.getMesas();
  }

  ngOnInit(): void {
  }

  async getMesas() {
    await this.mesasService.getMesas().then((mesas: Mesa[]) => {
      this.mesas = mesas;
      this.filteredMesas = this.mesaControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterMesas(value))
        );
    });
  }

  filterMesas(value: string): Mesa[] {
    const filterValue = value.toLowerCase();
    return this.mesas.filter(mesa =>
      mesa.titulo.toLowerCase().includes(filterValue)
    );
  }

  async seleccionarMesa(mesa: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    await this.mesasService.getMesaFindOne(mesa).then(()=>{
      this.dialogRef.close();
      this.router.navigateByUrl('/gestos-configuracion-usuario');
    }).catch((error)=>{
      this.dialogRef.close();
    })
  }

}
