<section class="example-section" [formGroup]="form" *ngIf="examen">
    <h6>Seleccione respuestas:</h6>
    <form [formGroup]="form">

        <ng-container formArrayName="opciones" *ngFor="let opcion of ordersFormArray.controls; let i = index">
            <p>
                <mat-checkbox style="white-space: normal;" [formControlName]="i" (change)="seleccionarOpciones($event, i)">
                    <p style="white-space: normal !important;">{{respuestasArray[i].texto}}</p>
                </mat-checkbox>
            </p>

            <!-- CONTENIDO MULTIMEDIA RESPUESTAS -->
            <ng-container *ngIf="respuestasArray[i].contenido.contiene">

                <div *ngIf="respuestasArray[i].contenido.type == 'image/png' || respuestasArray[i].contenido.type == 'image/jpeg' || respuestasArray[i].contenido.type == 'image/jpg'" class="animate__animated animate__fadeIn">
                    <img [src]=" 1 | mediaCursosPipe:  respuestasArray[i].contenido.source  | safe" alt="image" style="width: 100%; height: auto;">
                </div>

                <div *ngIf="respuestasArray[i].contenido.type == 'application/pdf'" class="animate__animated animate__fadeIn">
                    <iframe [src]="( 3 | mediaCursosPipe:  respuestasArray[i].contenido.source | safeResourceUrl)" type="application/pdf" style="width: 75%; height: 50vh;"></iframe>
                </div>

                <div *ngIf="respuestasArray[i].contenido.type == 'video/mp4'" class="animate__animated animate__fadeIn">
                    <iframe [src]="( 2 | mediaCursosPipe:  respuestasArray[i].contenido.source | safeResourceUrl)" type="video/mp4" style="width: 100%; height: 50vh;"></iframe>
                </div>

            </ng-container>

        </ng-container>
        <div *ngIf="!form.valid">Seleccione al menos una respuesta</div>
        <br>
        <div>
            <button *ngIf="index != 0" mat-button matStepperPrevious>Anterior</button>
            <button mat-raised-button mat-button color="primary" *ngIf="longitud != index+1" [disabled]="!form.valid" matStepperNext>Siguiente</button>
        </div>
    </form>
</section>