<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div *ngIf="flagLoaded" class="profile-area pb-70">
  <div class="container">

    <div class="profile-courses-quizzes">
      <h4>Listado de puntajes cargados al usuario: <strong>{{usuario?.nombrePersona}}
          {{usuario?.apellidoPersona}}</strong></h4>

          <button (click)="elegirMesa()" mat-raised-button mat-button color="primary"
          style="margin-right: 5px;"><mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon> Cargar puntajes</button>

      <div *ngIf="flagLoaded">

        <mat-form-field>
          <mat-label>Filtrar</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Primer gesto..." autocomplete="off">
        </mat-form-field>

        <div class="alert alert-warning" role="alert" *ngIf="!resultados">
          No se encontraron resultados de gestos evaluados para el usuario.
        </div>

        <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

            <!-- Column -->
            <ng-container matColumnDef="mesa">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font" class="font"> Mesa
              </th>
              <td mat-cell *matCellDef="let element"> {{element.mesa.titulo}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="gesto">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Gesto </th>
              <td mat-cell *matCellDef="let element" class="td-nombre" mat-column="nombre">
                {{element.gesto.nombre}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="usuario">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Usuario </th>
              <td mat-cell *matCellDef="let element"> {{element.usuario.nombrePersona}}
                {{element.usuario.apellidoPersona}}</td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="aprobado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Estado </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.aprobado; else elseTemplate">
                  <mat-chip-listbox aria-label="Fish selection">
                    <mat-chip-option>Aprobado</mat-chip-option>
                  </mat-chip-listbox>
                </ng-container>
                <ng-template #elseTemplate>
                  <mat-chip-listbox aria-label="Fish selection">
                    <mat-chip-option color="warn">Desaprobado</mat-chip-option>
                  </mat-chip-listbox>
                </ng-template>
              </td>
            </ng-container>


            <!-- Column -->
            <ng-container matColumnDef="nota">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Nota </th>
              <td mat-cell *matCellDef="let element"> {{element.nota}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="fechaVencimiento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Fecha Vencimiento </th>
              <td mat-cell *matCellDef="let element"> {{element.fechaVencimiento | date}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Acciones </th>
              <td mat-cell *matCellDef="let element">
                <button (click)="editarEvaluacionGesto(element._id)" type="button" style="margin-right: 5%;"
                  title="Editar mesa" class="btn btn-warning"><i class="fas fa-edit"></i></button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>