<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li>{{ idioma | translate: "solicitudes.LINK-MIS-SOLICITUDES" | async}}</li>
            </ul>
            <h2>{{ idioma | translate: "solicitudes.LINK-MIS-SOLICITUDES" | async}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70">
    <div class="container">

        <div class="profile-courses-quizzes">
            <h3>{{ idioma | translate: "solicitudes.MIS-SOLICITUDES-SUBTITULO" | async}}</h3>
            <hr>

            <mat-form-field>
                <mat-label>{{ idioma | translate: "solicitudes.FILTRAR" | async}}</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
            </mat-form-field>

            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Column -->
                    <ng-container matColumnDef="pais">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ idioma | translate: "solicitudes.PAIS" | async}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.pais}} </td>
                    </ng-container>


                    <!-- Column -->
                    <ng-container matColumnDef="actividad">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ idioma | translate: "solicitudes.ACTIVIDAD" | async}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.actividad }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="_nro">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ idioma | translate: "solicitudes.NRO" | async}} </th>
                        <td mat-cell *matCellDef="let element"> {{element._nro}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="fecha">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ idioma | translate: "solicitudes.FECHA-SOL" | async}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.fecha | date:"dd/MM/yy"}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="progreso">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ idioma | translate: "solicitudes.PROGRESO" | async}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.progreso | estadosPipe}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="ver">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ idioma | translate: "solicitudes.ACCIONES" | async}} </th>
                        <td mat-cell *matCellDef="let element">
                            <!-- <button (click)="verSolicitud(element)" class="btn default-btn-style-two"><i class="fas fa-file"></i> {{ idioma | translate: "solicitudes.BTN-VER" | async}}</button> -->
                            <button (click)="verSolicitud(element)" mat-raised-button color="accent">{{ idioma | translate: "solicitudes.BTN-VER" | async}}</button>

                        </td>
                    </ng-container>

                    <!-- Column -->
                    <!-- <ng-container matColumnDef="descargar">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                        <td mat-cell *matCellDef="let element">
                            <button class="btn default-btn-style-two"><i class="fas fa-file-download"></i> Solicitud</button>
                        </td>
                    </ng-container> -->

                    <!-- Column -->
                    <!-- <ng-container matColumnDef="indentificacion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th> -->
                    <!-- <td mat-cell *matCellDef="let element">
                            <button class="btn default-btn-style-two" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                <i class="fas fa-qrcode"></i> Tarjeta QR
                            </button> -->

                    <!-- Modal -->
                    <!-- <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Identificación</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body text-center">
                                            <img src="assets/img/qr-falso.png" width="50%" alt="codigo-qr"><img class="pt-5" src="assets/img/logo-solapa.png" width="20%" alt="codigo-qr">
                                            <br><br>
                                            <h3><b>TARJETA VÁLIDA PARA LECTURA</b></h3>
                                            <div class="alert alert-secondary" role="alert">
                                                <p> Presenta esta tarjeta, de ser solicitada,<br>para validar el permiso</p>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                    <!-- </td> -->
                    <!-- </ng-container> -->

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                </table>
            </div>

            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
    </div>
</div>