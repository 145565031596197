import { IAppCursos } from './../../app.config/app.config-cursos';
import { Pipe, PipeTransform, Inject } from '@angular/core';
import { APP_CONFIG_CURSOS } from '../../app.config/app.config-cursos';

@Pipe({
  name: 'tipoPregunta'
})
export class TipoPreguntaPipe implements PipeTransform {

    constructor(
        @Inject(APP_CONFIG_CURSOS) private iAppCursos: IAppCursos,
    ){}
    transform( tipo: string ): string {
        switch (tipo) {
            case this.iAppCursos.tipo_examen_MC.tipo:
                return this.iAppCursos.tipo_examen_MC.descripcion;
                break;

            case this.iAppCursos.tipo_examen_VF.tipo:
                return this.iAppCursos.tipo_examen_VF.descripcion;

                break;

            case this.iAppCursos.tipo_examen_OC.tipo:
                return this.iAppCursos.tipo_examen_OC.descripcion;
                break;

            case this.iAppCursos.tipo_examen_CO.tipo:
                return this.iAppCursos.tipo_examen_CO.descripcion;
                break;

            default:
                return 'NOT DESCRIPTION';
                break;
        }
    }
}
