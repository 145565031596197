<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li>Examenes Evaluaciones Resultados</li>
            </ul>
            <h2>Examenes Evaluaciones Resultados</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area">
    <div class="container" style="max-width: 85%;">

        <div class="profile-courses-quizzes">
            <div class="header-with-button">
                <h3>Examenes Evaluaciones Resultados</h3>
                <a routerLink="/evaluacion-alta">
                    <button mat-raised-button mat-button color="primary">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>
                        Nuevo Examen
                    </button>
                </a>
            </div>
            <br>

            <mat-card class="search-card">
                <div class="container mt-4 search-container">
                    <mat-form-field class="mr-2">
                        <mat-label>Desde</mat-label>
                        <input matInput [matDatepicker]="pickerDesde" placeholder="Seleccione una fecha"
                            [(ngModel)]="fechaDesde">
                        <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDesde></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="mr-2">
                        <mat-label>Hasta</mat-label>
                        <input matInput [matDatepicker]="pickerHasta" placeholder="Seleccione una fecha"
                            [(ngModel)]="fechaHasta">
                        <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
                        <mat-datepicker #pickerHasta></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="mr-2">
                        <mat-label>Aprobación</mat-label>
                        <mat-select [(ngModel)]="aprobadoFiltro">
                            <mat-option [value]="'true'">Aprobado</mat-option>
                            <mat-option [value]="'false'">No Aprobado</mat-option>
                            <mat-option [value]="''">Todos</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div style="width: 20px;"></div>

                    <button mat-raised-button color="primary" class="ml-2" (click)="buscarPorFechas()">Buscar</button>
                </div>

                <div class="container mt-4" *ngIf="this.dataSourceLength > 0">
                    <label>Resultados encontrados: <strong>{{dataSourceLength}}</strong></label>
                </div>

                <br>

            </mat-card>

            <div *ngIf="flagSinEvaluaciones" class="alert alert-warning m-3" width="100%" role="alert">
                No hay evaluaciones de examenes
            </div>
            <mat-tab-group>
                <mat-tab [label]="getTabLabel()">
                    <mat-form-field>
                        <mat-label>Filtrar</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Alumno..." autocomplete="off">
                    </mat-form-field>
                    <mat-spinner *ngIf="!flagLoaded"></mat-spinner>
                    <br>
                    <div #todos>
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                            <!-- Column -->
                            <ng-container matColumnDef="nombre">
                                <th width="30%" mat-header-cell *matHeaderCellDef mat-sort-header> Examen </th>
                                <td width="30%" mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                            </ng-container>

                            <!-- Column -->
                            <ng-container matColumnDef="alumno">
                                <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header> Alumno </th>
                                <td width="20%" mat-cell *matCellDef="let element"> {{element.alumno}} </td>
                            </ng-container>

                            <!-- Column -->
                            <ng-container matColumnDef="disciplina">
                                <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header> Disciplina </th>
                                <td width="20%" mat-cell *matCellDef="let element"> {{element.disciplina}} </td>
                            </ng-container>

                            <!-- Column -->
                            <ng-container matColumnDef="estado">
                                <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                <td width="20%" mat-cell *matCellDef="let element">
                                    <p><strong> {{ element.estado ? 'Aprobado' : 'Desaprobado' }} </strong></p>
                                </td>
                            </ng-container>

                            <!-- Column -->
                            <ng-container matColumnDef="recuperatorio">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Es Recuperatorio</th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.recuperatorio === true">
                                        <section class="example-section">
                                            <p><strong>Si</strong></p>
                                        </section>
                                    </div>
                                    <div *ngIf="element.recuperatorio === false || element.recuperatorio == null">
                                        <section class="example-section">
                                            <p><strong>No</strong></p>
                                        </section>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Column -->
                            <ng-container matColumnDef="fecha">
                                <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Evaluación
                                </th>
                                <td width="20%" mat-cell *matCellDef="let element"> {{element.fechaEvaluacion |
                                    date:"short"}}</td>
                            </ng-container>

                            <!-- Column -->
                            <ng-container matColumnDef="puntaje">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Puntaje Obtenido </th>
                                <td mat-cell *matCellDef="let element">{{element.puntajeObtenido}}</td>
                            </ng-container>

                            <!-- Column -->
                            <ng-container matColumnDef="fechaMateriaEnviada">
                                <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Materia Enviada
                                </th>
                                <td width="20%" mat-cell *matCellDef="let element"> {{element.fechaMateriaEnviada |
                                    date:"short"}}</td>
                            </ng-container>

                            <ng-container matColumnDef="_id">
                                <th width="25%" mat-header-cell *matHeaderCellDef mat-sort-header
                                    style="text-align: center;">Acciones</th>
                                <td width="25%" mat-cell *matCellDef="let element"
                                    style="display: flex; align-items: center;">
                                    <button style="margin-right: 5px;" mat-raised-button mat-button type="button"
                                        title="Ver Examen Resultado" (click)="verExamen(element._id)">
                                        <mat-icon aria-hidden="false"
                                            aria-label="Example home icon">visibility</mat-icon>
                                    </button>
                                    <button style="background-color: #fbc02d; height: 36px;" mat-raised-button mat-button type="button"
                                    title="{{ element.materiaBoletinEnviada ? 'Nota ya enviada a Boletín' : 'Enviar a Boletín' }}" [disabled]="element.materiaBoletinEnviada || loading" (click)="enviarBoletin(element.examenId, element.usuarioId, element.nombre, element)">
                                    {{ element.materiaBoletinEnviada ? 'Nota ya enviada' : 'Enviar a Boletin' }}
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row">
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 25, 100, 500, 800, 1200, 2000]"
                            showFirstLastButtons></mat-paginator>

                        <div mat-dialog-actions align="end">
                            <button mat-raised-button color="primary" (click)="exportTOExcel()">Exportar a
                                Excel</button>
                        </div>
                        <br>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>