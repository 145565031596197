import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { APP_CONFIG_OFERTAS, IAppOfertas } from 'src/app/app.config/app.config-ofertas';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { ActividadDAdjunto } from 'src/app/interfaces/interface-bakend/ofertas-laborales/actividad-adjuntos.interface';
import { Actividad } from 'src/app/interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { PaisDisponible } from 'src/app/interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { MediaService } from 'src/app/services/shared/media.service';
import { PaisesService } from 'src/app/services/shared/paises.service';
import { ValidadoresService } from 'src/app/services/shared/validadores.service';
import Swal from 'sweetalert2';
import { DocumentosEstadosSolicitar } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { BoletinesService } from 'src/app/services/cursos/boletines.service';
import { Boletin } from 'src/app/interfaces/interface-bakend/cursos/boletin.interface';

@Component({
  selector: 'app-oferta-modificacion',
  templateUrl: './oferta-modificacion.component.html',
  styleUrls: ['./oferta-modificacion.component.scss']
})
export class OfertaModificacionComponent implements OnInit {

  currentDate = new Date();
  form: FormGroup;
  actividad: any;
  adjuntos: ActividadDAdjunto[];
  cursosModulos: Curso[];
  paisesDisponibles: PaisDisponible[];
  flagLoaded = false;
  flagLoadedAdjuntos = false;
  tipoPuestos: Catalogo[] = [];
  modalidades: Catalogo[] = [];
  areas: Catalogo[] = [];
  boletines: Boletin[] = [];
  // Upload
  flagSelectFile = false;
  selectedFile: any;
  value = 0;
  cargandoArchivo = false;
  subscriptionAvance: Subscription;

  condition = false;
  fileName = 'No file selected';
  file: File;

  /* ripple */
  centered = false;
  disabled = false;
  unbounded = false;

  /* img portada */
  fileAttr = 'Choose File';


  radius: number;
  color: string;


  // arreglo de documentos que solicita 
  documentosEstadosSolicitar: DocumentosEstadosSolicitar [] = [];

  imageUrl: string | ArrayBuffer =
  './assets/img/uploadImg.png';

  circuitos: any[] = [];

  flagProcesando = false;

  constructor(private fb: FormBuilder,
              private router: Router,
              private actividadesService: ActividadesService,
              private paisesService: PaisesService,
              private mediaService: MediaService,
              private guiMsjService: GuiMsjService,
              private catalogoService: CatalogoService,
              private validadores: ValidadoresService, 
              private cursosService: CursosService,
              private boletinesServive: BoletinesService,
              @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
              ) {

    this.getAdjuntosAll();
    this.getCursosModulo();
    this.getPaisesDisponibles();
    this.getTipoPuestos();
    this.getModalidades();
    this.getAreas();
    this.getCircuitos();
    this.getBoletines();
  }

  ngOnInit(): void {
    this.getEstadoVariables();
    this.createForm();
  }

  get nombreNoValido(){
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  get sueldoNoValido(){
    return this.form.get('sueldo').invalid && this.form.get('sueldo').touched;
  }

  get costoAdministrativoNoValido(){
    return this.form.get('costoAdministrativo').invalid && this.form.get('costoAdministrativo').touched;
  }

  get costoPostAprobacionNoValido(){
    return this.form.get('costoPostAprobacion').invalid && this.form.get('costoPostAprobacion').touched;
  }

  get importanteNoValido(){
    return this.form.get('importante').invalid && this.form.get('importante').touched;
  }

  get documentacionNoValida(){
    return this.form.get('documentacionRequerida').invalid && this.form.get('documentacionRequerida').touched;
  }

  get paisNoValido(){
    return this.form.get('nombre').invalid && this.form.get('pais').touched;
  }

  get adjuntoNoValido(){
    return this.form.get('adjuntos').invalid && this.form.get('adjuntos').touched;
  }
  get documentosEstadosSolicitarNoValido(){
    return this.form.get('documentosEstadosSolicitar').invalid && this.form.get('documentosEstadosSolicitar').touched;
  }

  get cursoModuloNoValido(){
    return this.form.get('cursoModulo').invalid && this.form.get('cursoModulo').touched;
  }

  get vigenciaNoValida(){
    return this.form.get('vigenciaDesde').invalid && this.form.get('vigenciaDesde').touched;
  }

  get vigenciaNoValidaHasta(){
    return this.form.get('vigenciaHasta').invalid && this.form.get('vigenciaHasta').touched;
  }

  get descripcionNoValida(){
    return this.form.get('descripcion').invalid && this.form.get('descripcion').touched;
  }

  get detalleNoValido(){
    return this.form.get('detalle').invalid && this.form.get('detalle').touched;
  }

  async getTipoPuestos() {
    this.tipoPuestos = await this.catalogoService.findDescendantsByKey('TIPO_PUESTO');
  }

  async getModalidades() {
    this.modalidades = await this.catalogoService.findDescendantsByKey('MODALIDAD');
  }

  async getAreas() {
    this.areas = await this.catalogoService.findDescendantsByKey('AREA');
  }

  async getCircuitos() {
    this.circuitos.push({circuito: "1", viewValue: 'Pago Inicial - Segundo Pago'});
    this.circuitos.push({circuito: "2", viewValue: 'Sin pago Inicial - Únicamente un Pago post Pre-Aprobacion'});
  }

  async getBoletines() {
    this.boletines = await this.boletinesServive.getBoletines();
  }

  createForm() {
    this.form = this.fb.group({
      nombre: new FormControl(this.actividad.nombre, [ Validators.required]),
      descripcion: new FormControl(this.actividad?.descripcion, [ Validators.required]),
      pais: new FormControl(this.actividad.paisDisponible._id, [ Validators.required]),
      // adjuntos: new FormControl(''),
      cursoModulo: new FormControl(this.actividad.cursos,),
      documentos: new FormControl(this.actividad?.documentos),
      vigenciaDesde: new FormControl(this.actividad.vigenciaDesde, [ Validators.required]),
      vigenciaHasta: new FormControl(this.actividad.vigenciaHasta, [ Validators.required]),
      vigente: new FormControl(this.actividad.vigente),      
      requisitos: new FormControl(this.actividad.requisitos, ),
      importante: new FormControl(this.actividad.importante, ),
      finalizando: new FormControl(this.actividad.finalizando, ),
      imagen: new FormControl('',),
      detalle: new FormControl(this.actividad.detalle, [ Validators.required]),
      documentacionRequerida: new FormControl(this.actividad.documentacionRequerida, ),
      tipoPuesto: new FormControl(''),
      area: new FormControl(''),
      resumenAvatar: new FormControl(this.actividad.resumenAvatar),
      modalidad: new FormControl(''),
      sueldo: new FormControl(''),
      costoAdministrativo: new FormControl(this.actividad.costoAdministrativo, ),
      costoPostAprobacion: new FormControl(this.actividad.costoPostAprobacion, ),
      circuito: new FormControl(2, [ Validators.required]),
      documentosEstadosSolicitar: new FormControl(this.actividad?.documentosEstadosSolicitar,),     
      pista: new FormControl(this.actividad?.pista),      
      solicitarDocumentos: new FormControl(this.actividad?.solicitarDocumentos),
      boletin: new FormControl(this.actividad?.boletin)
        
    }, {
      validators: this.validadores.fechaFinMenor('vigenciaDesde', 'vigenciaHasta')
    });
  }

  async setDataForma(): Promise<Actividad>{

    const data: Actividad = {

      i18n: 'es',
      nombre: this.form.value.nombre,
      descripcion: this.form.value.descripcion,
      paisDisponible: this.form.value.pais,
      // adjuntos: this.form.value.adjuntos,
      cursos: this.form.value.cursoModulo,
      vigenciaDesde: this.form.value.vigenciaDesde,
      vigenciaHasta: this.form.value.vigenciaHasta,
      vigente: this.form.value.vigente,
      detalle: this.form.value.detalle,
      requisitos: this.form.value.requisitos,
      importante: this.form.value.importante,
      finalizando: this.form.value.finalizando,
      documentacionRequerida: this.form.value.documentacionRequerida,
      // sueldo: this.form.value.sueldo,
      // tipoPuesto: this.form.value.tipoPuesto,
      // modalidad: this.form.value.modalidad,
      // area: this.form.value.area,
      resumenAvatar: this.form.value.resumenAvatar,
      costoAdministrativo: this.form.value.costoAdministrativo,
      costoPostAprobacion: this.form.value.costoPostAprobacion,
      circuito: +this.form.value.circuito,
      documentosEstadosSolicitar:  await this.asignarDocumentosEstadosSolicitar(this.form.value.documentosEstadosSolicitar),
      pista: this.form.value.pista,
      solicitarDocumentos: this.form.value.solicitarDocumentos,
      boletin: this.form.value.boletin != null ?  this.form.value.boletin: null
    };

    return data;

  }

  getActividad(){
    return this.actividad = this.actividadesService.getActividadSeleccionada();
  }

  async getEstadoVariables(){
    if (this.getActividad() === undefined) {
      this.router.navigateByUrl('/');
      return;
    } else {
      this.getActividad();
      this.flagLoaded = true;
    }
  }

  async getAdjuntosAll() {
    this.adjuntos = await this.actividadesService.getAdjuntos();
  }

  async getCursosModulo() {
    this.cursosModulos = await this.cursosService.getCursosModulos();
  }

  async getPaisesDisponibles() {
    this.paisesDisponibles = await this.paisesService.getPaisesDisponibles();
  }

  async put(){

    await this.guiMsjService.msjFormSubmit('Guardando');
    this.flagProcesando = true;
    this.actividadesService.put(this.actividad._id, await this.setDataForma()).then( async (actividad: Actividad) => {
        if (this.selectedFile !== undefined) {
            await this.uploadFile(this.selectedFile, actividad._id);
        }
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.flagProcesando = false;
        this.router.navigateByUrl('/pre-inscripciones-listado');
        return;
    });
  }

  msjError(){
    Swal.close();
    Swal.fire({
        icon: 'error',
        title: 'Error al guardar!',
        showConfirmButton: false,
        timer: 1100
    });
  }

  async asignarDocumentosEstadosSolicitar(documentosSolicitar: string[]): Promise<DocumentosEstadosSolicitar[]> {
      documentosSolicitar.forEach((documentoSolicitar: any) => {
        if (documentoSolicitar.hasOwnProperty('documento')) {
          const documento = this.adjuntos.find(element => element._id == documentoSolicitar.documento._id);
          this.documentosEstadosSolicitar.push({documento :documento, entregado: false, solicitar:true, obligatorio:false});
        } else {
          const documento = this.adjuntos.find(element => element._id == documentoSolicitar);
          this.documentosEstadosSolicitar.push({documento :documento, entregado: false, solicitar:true, obligatorio:false});
        }
        
      });
      return this.documentosEstadosSolicitar;
  }

  /* UPLOAD */

    /* subo archivo con observable*/
  subscribeAvanceUpload(){
    this.subscriptionAvance = this.mediaService.getAvance().subscribe(resp => {
    if (resp) {
        this.value = resp.value;
    } else {
        this.value = 0;
        }
    });
  }

  openFileSelected(event) {
    this.selectedFile = <File> event.target.files[0];
  }

  onChangeFile(file: File, event){
    if(file){
      this.openFileSelected(event);
      this.fileName = file.name;
      this.file = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = e => {
        this.imageUrl = reader.result;
      }
    }
  }

  async uploadFile(selectedFile, idactividad) {
    this.cargandoArchivo = true;
    await this.mediaService.uploadImgActividad2(selectedFile, idactividad).then( resp => {
      if (resp) {
        if (this.value === 100) {this.cargandoArchivo = false; }
      } else {
        this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
      }
    });
  }
   /* FIN UPLOAD */

}
