import { Injectable } from '@angular/core';
import { StorageService } from '../shared/storage.service';
const urlCookiebot = '//consent.cookiebot.com/uc.js';
const urlCookieDeclaration = '//consent.cookiebot.com/cb7c056e-db7f-4526-9dba-4c12ecb7a14a/cd.js';

@Injectable({
  providedIn: 'root'
})
export class CookiebotService {

  constructor(
    private storageService: StorageService,
  ) {
   }

  async  loadScriptCookiebot(idioma) {

    this.removeElementById('Cookiebot');
  
    if ( await this.storageService.getIdiomaCookiebot() === 'undefined') {
      this.storageService.setIdiomaCookiebot('es');
      this.cookiebotConfig('es');
    } else {
      await this.storageService.clearIdiomaCookiebot();
      this.storageService.setIdiomaCookiebot(idioma.value);
      this.cookiebotConfig(idioma.value);      
    }
  
  }

  cookiebotConfig(idioma) {

    // Remove
    this.removeElementById('Cookiebot');

    // Create
    let node = document.createElement('script');
    node.id = 'Cookiebot';
    node.src = urlCookiebot;
    node.type = 'text/javascript';
    node.async = true;
    node.setAttribute("data-cbid", "cb7c056e-db7f-4526-9dba-4c12ecb7a14a");
    node.setAttribute("data-blockingmode", "auto");

    if (idioma === 'es' || idioma === 'ca' || idioma === 'en') {
      node.setAttribute("data-culture", idioma.toUpperCase());
    } else  {
      node.setAttribute("data-culture", "ES");
    }
    document.getElementsByTagName('body')[0].appendChild(node);
  }


  removeElementById(id) {
    //removing element by ID
    var element = document.getElementById(id);
    if (element !== null) {
      element.parentNode.removeChild(element);
    } 
  }


  // No esta en uso este metodo, va por HTML
  public loadScriptCookieDeclaration(idioma) {

    let node = document.createElement('script');
    node.id = 'CookieDeclaration';
    node.src = urlCookieDeclaration;
    node.type = 'text/javascript';
    node.async = true;

    if (idioma.value === 'es' || idioma.value === 'ca' || idioma.value === 'en') {
      node.setAttribute("data-culture", idioma.value.toUpperCase());
    } else  {      
      node.setAttribute("data-culture", "ES");
    }

    document.getElementsByTagName('body')[0].appendChild(node);

  }

}
