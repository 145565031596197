import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';
import { MateriasBoletinService } from 'src/app/services/cursos/materias-boletin.service';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { APP_CONFIG_SHARED, IAppShared } from 'src/app/app.config/app.config-shared';
import { Boletin } from 'src/app/interfaces/interface-bakend/cursos/boletin.interface';
import { BoletinesService } from 'src/app/services/cursos/boletines.service';
import { MateriaBoletinDetalle } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin-detalle.interface';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { Alumno } from 'src/app/interfaces/interface-bakend/cursos/alumno.interface';
import { AlumnoBoletin } from 'src/app/interfaces/interface-bakend/cursos/alumno-boletin.interface';

@Component({
  selector: 'app-boletin-nombre-alta',
  templateUrl: './boletin-nombre-alta.component.html',
  styleUrls: ['./boletin-nombre-alta.component.scss']
})
export class BoletinNombreAltaComponent implements OnInit {

  flagFormaValido = false;
  form: FormGroup;
  boletines: Boletin[];
  materiasBoletines: MateriaBoletin[];
  modalidadCursadas: Catalogo[];
  modalidadExamenes: Catalogo[];

  constructor(
    private fb: FormBuilder,
    private guiMsjService: GuiMsjService,
    private boletinService: BoletinesService,
    private catalogoService: CatalogoService,
    private materiasBoletinesService: MateriasBoletinService,
    private router: Router,
    @Inject(APP_CONFIG_SHARED)
    private iAppShared: IAppShared) {

    this.createForm();
    this.createListenersStateForm();
    this.getMateriasBoletines();
    this.getCatalogo();
  }

  ngOnInit() {
  }

  async createForm() {
    this.form = this.fb.group({
      nombre: ['', Validators.required],
      materias: this.fb.array([], this.minMateriasValidator)
    });
  }

  async getMateriasBoletines() {
    await this.materiasBoletinesService.getMaterias().then((materiasBoletines: MateriaBoletin[]) => {
      this.materiasBoletines = materiasBoletines.filter(materia => materia.activa);
    });
  }

  async getCatalogo() {
    try {
      const modalidadCursadas: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.modalidadCursada, null);
      const modalidadExamenes: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.modalidadExamen, null);
      this.modalidadCursadas = modalidadCursadas;
      this.modalidadExamenes = modalidadExamenes;
    } catch (error) {
      console.error('Error al cargar modalidades', error);
    }
  }

  get materias() {
    return this.form.get('materias') as FormArray;
  }

  addMateria() {
    const materiaForm = this.fb.group({
      materia: ['', Validators.required],
      modalidadCursada: ['', Validators.required],
      modalidadExamen: ['', Validators.required],
      fechaExamen: [''],
      fechaNota: [''],
      notaExamen: ['', [Validators.min(0), Validators.max(100)]]
    });

    this.materias.push(materiaForm);
  }

  quitarMateria(index: number) {
    const confirmed = window.confirm('¿Estás seguro de que deseas eliminar esta materia?');
    if (confirmed) {
      this.materias.removeAt(index);
    }
  }

  minMateriasValidator(control: FormArray): { [key: string]: boolean } | null {
    if (control.length < 1) {
      return { 'minMaterias': true };
    }
    return null;
  }

  async setDataForm(): Promise<Boletin> {
    const data: Boletin = {
      nombre: this.form.value.nombre,
      materias: this.form.value.materias.map((materia: MateriaBoletinDetalle) => ({
        materia: materia.materia, // Objeto único
        modalidadCursada: materia.modalidadCursada,
        modalidadExamen: materia.modalidadExamen,
        fechaExamen: materia.fechaExamen,
        fechaNota: materia.fechaNota,
        notaExamen: materia.notaExamen
      }))
    };
    return data;
  }

  async post() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    await this.boletinService.postBoletin(await this.setDataForm()).then(async (boletin: Boletin) => {
      //await this.guiMsjService.msjFormSubmit('DatosGuardados');
      this.router.navigate(['/boletines-listado']);
      return;
    });
  }

  /* MÉTODOS PARA VALIDACIÓN DE FORMULARIO */
  get nombreNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  createListenersStateForm() {
    return this.form.statusChanges.subscribe(async status => {
      //  console.log(this.form);
    });
  }
}
