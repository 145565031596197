import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'precio'
})
export class PrecioPipe implements PipeTransform {
  transform(value: number): string {
    // Formatear el número como precio
    return '$' + value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}
