import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { Router } from '@angular/router';
import { MediaService } from '../../../../services/shared/media.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { Disciplina, Titulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';
import { MateriasBoletinService } from 'src/app/services/cursos/materias-boletin.service';

@Component({
  selector: 'app-materia-boletin-alta',
  templateUrl: './materia-boletin-alta.component.html',
  styleUrls: ['./materia-boletin-alta.component.scss']
})
export class MateriaBoletinAltaComponent implements OnInit {

  flagFormaValido = false;
  form: FormGroup;
  disciplinas: Disciplina[] = [];

  constructor(
      private fb: FormBuilder,
      private materiasBoletinService: MateriasBoletinService,
      private mediaService: MediaService,
      private guiMsjService: GuiMsjService,
      private router: Router){

      this.createForm();
      this.createListenersStateForm();
    }

    ngOnInit(): void {
    }

    async createForm(){
      this.form = this.fb.group({
        nombre: new FormControl('',  [ Validators.required]),
        activa: new FormControl(true)
      });
    }

    async setDataForma(): Promise<MateriaBoletin>{

      const data: MateriaBoletin = {
        nombre: this.form.value.nombre,
        activa: this.form.value.activa
      };
      return data;
    }

    async post(){
      await this.guiMsjService.msjFormSubmit('Guardando');
      await this.materiasBoletinService.post(await this.setDataForma()).then( async (titulo: Titulo) => {
          await this.guiMsjService.msjFormSubmit('DatosGuardados');
          this.router.navigate(['/materias-boletin-listado']);
          return;
      });
    }

    /* MÉTODOS PARA VALIDACIÓN DE FORMULARIO */
    get nombreNoValido() {
      return this.form.get('nombre').invalid && this.form.get('nombre').touched;
    }

  createListenersStateForm() {
      return this.form.statusChanges.subscribe( async status => {
        //  console.log(this.form);
      });
  }
}
