import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { Color, Label, MultiDataSet } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { EstadisticasService } from '../../../services/ofertas-laborales/estadisticas.service';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {

    @Input() cantFormularioCargado = 0;
    @Input() cantPreInscripcion = 0;
    @Input() cantDocumentosCargadosParciales = 0;
    @Input() cantDocumentosCargadosCompleto = 0;
    @Input() cantPagoSolicitado = 0;
    @Input() cantControlarPago = 0;
    @Input() cantAprobado = 0;
    @Input() cantRechazado = 0;
    @Input() cantAnulada = 0;
    @Input() cantEnEspera = 0;

    refresh: Subscription;


    public doughnutChartLabels: Label[] = [
        'Pre - Incripcion',
        'Formulario Cargado',
        'Documentos Parciales',
        'Documentos Cargados',
        'Pago Solicitado',
        'Controlar Pago',
        'Aprobados',
        'Rechazados',
        'Anulados',
        'En espera'
    ];

    public doughnutChartColors: Color[] = [{
        backgroundColor: ['#0d6efd', '#F9E79F', '#85929E', '#CD5C5C', '#85C1E9', '#DAF7A6', '#FFC300' , '#FFC300', '#FFA07A', '#2ecc71', '#e74c3c']
    }];

    public doughnutChartData: MultiDataSet = [];
    // = [
    //    [7, 3, 6, 2],
    // ];

    public doughnutChartType: ChartType = 'doughnut';


    constructor(
       private estadisticasService: EstadisticasService,
    ) {
        this.refresh = this.estadisticasService.refresh().subscribe(resp => {
            this.cargarDataSet();
        });
     }

    ngOnInit(): void {
        this.cargarDataSet();
    }

     // events
     public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {}

     public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {}

     public cargarDataSet() {
         this.doughnutChartData = [];
         this.doughnutChartData.push([
                                    this.cantFormularioCargado, this.cantPreInscripcion,
                                    this.cantDocumentosCargadosParciales, this.cantDocumentosCargadosCompleto,
                                    this.cantPagoSolicitado, this.cantControlarPago,
                                    this.cantAprobado, this.cantEnEspera,
                                    this.cantRechazado, this.cantAnulada,
                                ]);
     }

}
