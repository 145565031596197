import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { Actividad } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { PerfilesService } from '../../../../services/perfiles/perfiles.service';

@Component({
  selector: 'app-ofertas-laborales-listado',
  templateUrl: './ofertas-laborales-listado.component.html',
  styleUrls: ['./ofertas-laborales-listado.component.scss']
})
export class OfertasLaboralesListadoComponent implements OnInit {

    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] =
    [
      'nombre', 'pais', 'vigente' , '_id'
      // 'nombre', 'pais', 'vigente', 'puesto', 'modalidad', 'area' , '_id'
    ];
    dataSource: any;
    ofertas: Actividad [];
    /* Fin Data Table*/

    flagLoaded = false;


    constructor(
        private actividadesService: ActividadesService,
        private router: Router,
        private perfilesService: PerfilesService,
    ) {}

    ngOnInit(): void {
        this.perfilesService.allowedByUserLogon(this.router.url);
        this.loadDataPage();

    }

    async verActividad(event) {
      const oferta: Actividad = await this.actividadesService.getActividadesFindOne(event);
      this.router.navigateByUrl('/oferta-modificacion');
      await this.actividadesService.setActividadSeleccionada(oferta);
    }

    async loadDataTable(){

        await this.actividadesService.getActividades().then( (ofertas: Actividad[]) => {
            this.formatDataTable(ofertas).then( ( data: Actividad[] ) => {
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
            });
        });

    }

    formatDataTable( actividad: Actividad[]) {
        return new Promise( resolve => {
          const format: any[] = [];
          let elemento: {
            nombre: string,
            pais: string,
            vigente: boolean,
            // puesto: string,
            // modalidad: string,
            // area: string,
            _id: string,
          };
           // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < actividad.length; i++) {
            elemento = {
                nombre: actividad[i].nombre,
                pais: actividad[i].paisDisponible.pais.name_es,
                vigente: actividad[i].vigente,
                // puesto: actividad[i].tipoPuesto.concepto,
                // modalidad: actividad[i].modalidad.concepto,
                // area: actividad[i].area.concepto,
                _id: actividad[i]._id,
          };
            format.push(elemento);
          }
          resolve(format);
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    async loadDataPage() {
        await this.loadDataTable();
        this.flagLoaded = true;
        return;
    }

}
