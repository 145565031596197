import { Component, OnInit } from '@angular/core';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { Curso } from '../../../../interfaces/interface-bakend/cursos/curso.interface';
import { Subscription } from 'rxjs/internal/Subscription';
import { Translators } from 'src/app/services/shared/translators.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-cursos-listar',
  templateUrl: './cursos-listar.component.html',
  styleUrls: ['./cursos-listar.component.scss']
})
export class CursosListarComponent implements OnInit {

  cursosDisponibles: Curso[] = [];

  // MatPaginator Output
  pageEvent: any;

  // MatPaginator Inputs
  length: number = 0;
  pageSize: number = 3;
  pageSizeOptions: number[] = [3, 6, 9, 12, 24];

  lowValue: number = 0;
  highValue: number = 3; // default view

  idioma: Subscription;

  flagLoaded = false;

  constructor(
    private cursosService: CursosService,
    private translatorService: Translators,
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        this.idioma = resp;
    });
  }

  ngOnInit(): void {
      this.getCursosDisponibles();
  }

  getCursosDisponibles() {
    this.cursosService.getCursosDisponibles().then((cursos: Curso[]) => {
        this.cursosDisponibles = cursos;
        this.flagLoaded = true;
    });
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

}
