<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><a routerLink="/examenes-alumno">Mis Examenes</a></li>
                <li *ngIf="this.examen">{{examen.titulo}}</li>
            </ul>
            <h2 *ngIf="this.examen">{{ examen.titulo }}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>
<div class="features-area pb-70 mt-5 animate__animated animate__fadeIn">
    <div class="container" *ngIf="examen">
        <mat-card>
            <p>Nombre examen: <strong>{{ examen.titulo }}</strong></p>
            <p>Fecha vencimiento: <strong>{{ fechaVencimiento | date:'short' }} </strong></p>
            <div *ngIf="examen.tieneLimiteTiempo">
                <p>Tiempo restante: </p>
                <h4 style="color:red;"><strong>{{ hours | number:'2.0'}}:{{ minutes | number:'2.0'}}:{{seconds | number:'2.0'}} </strong></h4>
            </div>

            <ng-container *ngIf="cantPreguntas <= 8; else elseTemplate">

                <mat-horizontal-stepper [linear]="isLinear" #stepper>
                    <mat-step [aria-labelledby]="stepDisabled ? 'disabled_Resi' : null" *ngFor="let pregunta of preguntas; let i = index">
                        <ng-template matStepLabel>Pregunta Nro {{ i+1 }}</ng-template>
                        <h5>{{ pregunta.enunciado }}</h5>

                        <!-- CONTENIDO MULTIMEDIA PREGUNTAS -->
                        <ng-container *ngIf="pregunta.contenido.contiene">

                            <div *ngIf="pregunta.contenido.type == 'image/png' || pregunta.contenido.type == 'image/jpeg' || pregunta.contenido.type == 'image/jpg'" style="width: 100%; height: auto;" class="animate__animated animate__fadeIn">
                                <img [src]=" 1 | mediaCursosPipe:  pregunta.contenido.source  | safe" alt="image">
                            </div>

                            <div *ngIf="pregunta.contenido.type == 'application/pdf'" class="animate__animated animate__fadeIn">
                                <iframe [src]="( 3 | mediaCursosPipe:  pregunta.contenido.source | safeResourceUrl)" type="application/pdf" style="width: 75%; height: 50vh;"></iframe>
                            </div>

                            <div *ngIf="pregunta.contenido.type == 'video/mp4'" class="animate__animated animate__fadeIn">
                                <iframe [src]="( 2 | mediaCursosPipe:  pregunta.contenido.source | safeResourceUrl)" type="video/mp4" style="width: 100%; height: 50vh;"></iframe>
                            </div>
                            <br>
                        </ng-container>
                        <br>
                        <div *ngIf="pregunta.tipo == 'MC'">
                            <app-multiple-choice (getFormStatus)="getFormStatus = $event" [obligatoria]="pregunta.obligatoria === true ? 1 : 0" [examen]="examen" [longitud]="preguntas.length" [respuestas]="pregunta.respuestas" [tipoPregunta]="pregunta.tipoPregunta" [index]="i" (respuestasEmitter)="recibirRespuestas($event)"></app-multiple-choice>
                        </div>

                        <div *ngIf="pregunta.tipo == 'VF'">
                            <app-verdadero-falso (getFormStatus)="getFormStatus = $event" [obligatoria]="pregunta.obligatoria" [examen]="examen" [longitud]="preguntas.length" [respuestas]="pregunta.respuestas" [tipoPregunta]="pregunta.tipoPregunta" [index]="i" (respuestasEmitter)="recibirRespuestas($event)"></app-verdadero-falso>
                        </div>
                        <br>
                        <!-- SI EL FORMULARIO ES CORRECTO Y ESTOY POSICIONADO EN LA ÚLTIMA PREGUNTA, HABLITO EL BOTON PARA FINALIZAR EL EXAMEN -->
                        <ng-container *ngIf="this.finalizarExamenCompleto; else elseTemplate">
                            <section>
                                <div class="example-button-row" align="end">
                                    <button mat-raised-button mat-button color="warn" [disabled]="procesandoEvaluacion" (click)="finalizarExamen()">Finalizar examen</button>
                                </div>
                            </section>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <section>
                                <div class="example-button-row" align="end">
                                    <button mat-raised-button mat-button color="warn" disabled (click)="finalizarExamen()">Finalizar examen</button>
                                </div>
                            </section>
                        </ng-template>
                    </mat-step>
                </mat-horizontal-stepper>

            </ng-container>
            <ng-template #elseTemplate>

                <mat-vertical-stepper [linear]="isLinear" #stepper>
                    <mat-step [aria-labelledby]="stepDisabled ? 'disabled_Resi' : null" *ngFor="let pregunta of preguntas; let i = index">
                        <ng-template matStepLabel>Pregunta Nro {{ i+1 }}</ng-template>
                        <h5>{{ pregunta.enunciado }}</h5>

                        <!-- CONTENIDO MULTIMEDIA PREGUNTAS -->
                        <ng-container *ngIf="pregunta.contenido.contiene">

                            <div *ngIf="pregunta.contenido.type == 'image/png' || pregunta.contenido.type == 'image/jpeg' || pregunta.contenido.type == 'image/jpg'" style="width: 100%; height: auto;" class="animate__animated animate__fadeIn">
                                <img [src]=" 1 | mediaCursosPipe:  pregunta.contenido.source  | safe" alt="image">
                            </div>

                            <div *ngIf="pregunta.contenido.type == 'application/pdf'" class="animate__animated animate__fadeIn">
                                <iframe [src]="( 3 | mediaCursosPipe:  pregunta.contenido.source | safeResourceUrl)" type="application/pdf" style="width: 75%; height: 50vh;"></iframe>
                            </div>

                            <div *ngIf="pregunta.contenido.type == 'video/mp4'" class="animate__animated animate__fadeIn">
                                <iframe [src]="( 2 | mediaCursosPipe:  pregunta.contenido.source | safeResourceUrl)" type="video/mp4" style="width: 100%; height: 50vh;"></iframe>
                            </div>
                            <br>
                        </ng-container>
                        <br>
                        <div *ngIf="pregunta.tipo == 'MC'">
                            <app-multiple-choice (getFormStatus)="getFormStatus = $event" [obligatoria]="pregunta.obligatoria === true ? 1 : 0" [examen]="examen" [longitud]="preguntas.length" [respuestas]="pregunta.respuestas" [tipoPregunta]="pregunta.tipoPregunta" [index]="i" (respuestasEmitter)="recibirRespuestas($event)"></app-multiple-choice>
                        </div>

                        <div *ngIf="pregunta.tipo == 'VF'">
                            <app-verdadero-falso (getFormStatus)="getFormStatus = $event" [obligatoria]="pregunta.obligatoria" [examen]="examen" [longitud]="preguntas.length" [respuestas]="pregunta.respuestas" [tipoPregunta]="pregunta.tipoPregunta" [index]="i" (respuestasEmitter)="recibirRespuestas($event)"></app-verdadero-falso>
                        </div>
                        <br>
                        <!-- SI EL FORMULARIO ES CORRECTO Y ESTOY POSICIONADO EN LA ÚLTIMA PREGUNTA, HABLITO EL BOTON PARA FINALIZAR EL EXAMEN -->
                        <ng-container *ngIf="this.finalizarExamenCompleto; else elseTemplate">
                            <section>
                                <div class="example-button-row" align="end">
                                    <button mat-raised-button mat-button color="warn" (click)="finalizarExamen()">Finalizar examen</button>
                                </div>
                            </section>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <section>
                                <div class="example-button-row" align="end">
                                    <button mat-raised-button mat-button color="warn" disabled (click)="finalizarExamen()">Finalizar examen</button>
                                </div>
                            </section>
                        </ng-template>
                    </mat-step>
                </mat-vertical-stepper>

            </ng-template>



        </mat-card>
    </div>
</div>