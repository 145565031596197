import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Actividad } from '../../../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { Translators } from '../../../../../../services/shared/translators.service';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss']
})
export class DetalleComponent implements OnInit {

  @Input() actividad: Actividad;
  @Input() nombrePais: string;

  idioma: Subscription;

  constructor(
      private translatorService: Translators
  ) { 
    
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });
  }


  ngOnInit(): void {
  }

}
