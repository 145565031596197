<!-- LA CLASE ACTIVE PONE EN AZUL EL ITEM <a (click)="enviarContenido()" class="list-group-item active" aria-current="true" style="cursor:pointer;"> -->
<!-- <a (click)="enviarContenido()" class="list-group-item" aria-current="true" style="cursor:pointer;">
    <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">{{ orden }}. Lección: {{ leccion.nombre }}</h6>
        <span class="badge rounded-pill"><i class="fas fa-check-circle fa-2x"></i></span>
    </div>
    <div>
        <p>Descripcion: {{ leccion.descripcion }}</p>
    </div>
    <br>
    <button type="button" class="btn btn-success btn-small"><i class="fas fa-play"></i></button>
</a> -->


<div class="card m-2">
    <a (click)="enviarContenido()" class="list-group-item" [class.active]="activo" style="cursor:pointer; border: none; outline: none;">
      <div class="d-flex w-100 justify-content-between">
        <div>
          <h6 class="mb-1">{{ orden }}. Lección: {{ leccion.nombre }}</h6>
          <p>Descripcion: {{ leccion.descripcion }}</p>
        </div>
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-success btn-sm me-2">
            <i class="fas fa-play"></i>
          </button>
        </div>
      </div>
    </a>
  </div>
  
<!-- 
<div class="card m-2">
    <a (click)="enviarContenido()" class="list-group-item" [class.active]="activo" style="cursor:pointer; border: none; outline: none;">
      <div class="d-flex w-100 justify-content-between">
        <div>
          <h6 class="mb-1">{{ orden }}. Lección: {{ leccion.nombre }}</h6>
          <p>Descripcion: {{ leccion.descripcion }}</p>
        </div>
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-success btn-sm me-2" (click)="reproducir()">
            <i class="fas fa-play"></i>
          </button>
          <span class="badge rounded-pill" *ngIf="reproduciendo">Reprociendo</span>
          <span class="badge rounded-pill" *ngIf="!reproduciendo">...</span>
        </div>
      </div>
    </a>
  </div> -->

  