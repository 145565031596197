<!-- ACTIVAR PARA AADIDESS SI LO SOLICITA ESTA EN VARIAS PAG (PROFILE / GYM Y HOME)-->
<!-- <app-cookiebot></app-cookiebot> -->
<div class="page-title-area-profile-authentication">
    <div class="container">
        <div>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('en')"><img src="assets/img/flags/usa.png" alt="English" width="20"> EN</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('es')"><img src="assets/img/flags/espana.png" alt="Español" width="20"> ES</button>
            <!-- <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('ca')"><img src="assets/img/flags/catalunya.png" alt="Catala" width="20"> CA</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('it')"><img src="assets/img/flags/italia.png" alt="Italiano" width="20"> IT</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('ar')"><img src="assets/img/flags/arabia-saudita.png" alt="Arab" width="20"> AR</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('fr')"><img src="assets/img/flags/francia.png" alt="French" width="20"> FR</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('de')"><img src="assets/img/flags/alemania.png" alt="German" width="20"> DE</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('br')"><img src="assets/img/flags/brasil.png" alt="Portugués" width="20"> PT </button> -->
        </div>
        <div class="page-title-content">
            <h2>
                <a routerLink="/home"><img [src]="logoUrl" alt="campus" width="30%"></a><br>{{ idioma | translate: "login.TITULO-BIENVENIDA" | async}}</h2>
        </div>
    </div>

    <div class="profile-authentication-area ptb-70 mt-5 ">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="login-form animate_animated animate_fadeIn"><br>
                        <h2> {{ idioma | translate: "login.INICIAR-SESION" | async}}</h2>
                        <form [formGroup]="loginForm" (ngSubmit)="login()">

                            <div class="form-group">
                                <label>{{ idioma | translate: "form.EMAIL" | async}}</label>
                                <input formControlName="email" type="email" class="form-control text-lowercase" [placeholder]=" idioma | translate: 'form.placeholders.INGRESE-EMAIL' | async" [class.is-invalid]="emailAccess">
                                <small *ngIf="emailAccess" class="text-danger">{{ idioma | translate: "form.placeholders.INGRESE-EMAIL" | async}}</small>
                            </div>

                            <div class="form-group inner-addon right-addon">
                                <label>{{ idioma | translate: "login.CONTRASENA" | async}}</label>
                                <div class="input-group">
                                    <input formControlName="password" [type]="showPasswordLogin ? 'text' : 'password'" class="form-control" [placeholder]="idioma | translate:'login.placeholders.INGRESE-CONTRASENA' | async" [class.is-invalid]="passwordAccess">
                                    <i alt="show" class="far fa-eye eye-show" (click)="showPasswordLogin = !showPasswordLogin" [class.hide]="showPasswordLogin"></i>
                                    <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showPasswordLogin = !showPasswordLogin" [class.hide]="!showPasswordLogin"></i>
                                </div>
                                <small *ngIf="passwordAccess" class="text-danger">{{ idioma | translate: "login.placeholders.INGRESE-CONTRASENA" | async}}</small>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                    <p>
                                        <input type="checkbox" id="test2">
                                        <label for="test2">{{ idioma | translate: "login.RECORDARME" | async}}</label>
                                    </p>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                    <a routerLink="/resend-pin" class="lost-your-password" style="text-decoration: none;">{{ idioma | translate: "login.REENVIAR-PIN" | async}}</a> -
                                   <a routerLink="/reset-password" class="lost-your-password" style="text-decoration: none;">{{ idioma | translate: "login.OLVIDO-CONTRASENA" | async}}</a> 
                                   - <a routerLink="/activate-account" class="lost-your-password" style="text-decoration: none;">{{ idioma | translate: "login.ACTIVAR-CUENTA" | async}}</a>
                                </div>
                            </div>

                            <button type="submit" [disabled]="!loginForm.valid" disabled><i class="fas fa-sign-in-alt"></i> {{ idioma | translate: "login.INICIAR-SESION" | async}}</button>
                            <button type="submit" *ngIf="loadingForm" [disabled]="true" class="btn btn-primary submit-btn btn-block">
                            <i class="fas fa-sign-in-alt"></i>
                            {{ idioma | translate: "login.MSJ-INGRESANDO" | async}}
                            </button>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="register-form">
                        <h2>{{ idioma | translate: "login.REGISTRO" | async}}</h2>

                        <div class="alert">
                            <strong>¡Importante!</strong> Por favor, registre su cuenta, le solicitamos amablemente que verifique tanto su bandeja de entrada como su carpeta de correo no deseado. Esto se debe a que nuestro sistema enviará un PIN importante para validar la cuenta que ha sido creada.
                        </div>  
                        <div class="info">
                            <strong>¡Información!</strong> Al completar el registro recibirá 
                            un correo electrónico que contendrá un PIN de validación. Haga clic en el enlace del <strong>PIN</strong> proporcionado para activar y comenzar a utilizar su cuenta.
                        </div> 

                        <form [formGroup]="registerForm" (ngSubmit)="registerUser()">
                            <div class="form-group">
                                <label>{{ idioma | translate: "form.EMAIL" | async}}</label>
                                <input formControlName="email" type="email" class="form-control text-lowercase" [placeholder]=" idioma | translate: 'form.placeholders.INGRESE-EMAIL' | async" [class.is-invalid]="emailNoValido">
                                <small *ngIf="emailNoValido" class="text-danger">{{ idioma | translate: "form.EMAIL-INVALIDO" | async}}</small>
                            </div>

                            <div class="form-group">
                                <div class="row mb-4">
                                    <div class="col-lg-6 col-xs-12">
                                        <div class="form-outline">
                                            <label>{{ idioma | translate: 'form.NOMBRE' | async }}</label>
                                            <input type="text" formControlName="nombrePersona" [placeholder]=" idioma | translate: 'form.placeholders.NOMBRE' | async" [class.is-invalid]="getNombreNoValido" class="form-control" style="margin-right: 3px;">
                                            <small style="margin-right: 30px;" *ngIf="getNombreNoValido" class="text-danger">{{ idioma | translate: "form.placeholders.NOMBRE" | async}}</small>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-xs-12">
                                        <div class="form-outline">
                                            <label>{{ idioma | translate: 'form.APELLIDO' | async }}</label>
                                            <input type="text" formControlName="apellidoPersona" [placeholder]=" idioma | translate: 'form.placeholders.APELLIDO' | async" [class.is-invalid]="getApellidoNoValido" class="form-control">
                                            <small *ngIf="getApellidoNoValido" class="text-danger">{{ idioma | translate: "form.placeholders.APELLIDO" | async}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group inner-addon right-addon">
                                <label>{{ idioma | translate: 'form.NRO-ID' | async }} </label>
                                <div class="input-group">
                                    <input type="string" type="text" formControlName="identificacion" class="form-control" [placeholder]=" idioma | translate: 'form.placeholders.ID-NO' | async" [class.is-invalid]="getIdentificacionNoValida">
                                </div>
                                <small *ngIf="getIdentificacionNoValida" class="text-danger">{{ idioma | translate: "form.NRO-ID" | async}}</small>
                            </div>

                            <div class="form-group inner-addon right-addon">
                                <label>{{ idioma | translate: "login.CONTRASENA" | async}}</label>
                                <div class="input-group">
                                    <input formControlName="password" [type]="showPasswordRegister ? 'text' : 'password'" class="form-control" [placeholder]=" idioma | translate: 'login.placeholders.INGRESE-CONTRASENA' | async" [class.is-invalid]="passwordNoValido">
                                    <i alt="show" class="far fa-eye eye-show" (click)="showPasswordRegister = !showPasswordRegister" [class.hide]="showPasswordRegister"></i>
                                    <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showPasswordRegister = !showPasswordRegister" [class.hide]="!showPasswordRegister"></i><br>
                                </div>
                                <small *ngIf="passwordNoValido" class="text-danger">{{ idioma | translate: "login.MSJ-CONTRASENA" | async}}</small>
                            </div>

                            <div class="form-group inner-addon right-addon">
                                <label>{{ idioma | translate: "login.CONFIRMAR-CONTRASENA" | async}}</label>
                                <div class="input-group">
                                    <input formControlName="confirmPassword" [type]="showPasswordConfirm ? 'text' : 'password'" class="form-control" [placeholder]=" idioma | translate: 'login.CONFIRMAR-CONTRASENA' | async" [class.is-invalid]="confirmPasswordNoValido">
                                    <i alt="show" class="far fa-eye eye-show" (click)="showPasswordConfirm = !showPasswordConfirm" [class.hide]="showPasswordConfirm"></i>
                                    <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showPasswordConfirm = !showPasswordConfirm" [class.hide]="!showPasswordConfirm"></i><br>
                                </div>
                                <small *ngIf="confirmPasswordNoValido" class="text-danger">{{ idioma | translate: "login.CONTRASENA-INVALIDA" | async}}</small>
                            </div>

                            <p class="description">{{ idioma | translate: "login.MSJ-CONTRASENA-INVALIDA" | async}}</p>

                            <!-- CERTIFICACION -->

                            <!-- <div class="form-group">
                                <app-modal-legal [CFR]="true" [TC]="true"></app-modal-legal>
                            </div> -->

                            <div class="form-group">
                                <input type="checkbox" formControlName="proteccionDeDatos" style="margin-right: 5px !important;"> <em>{{ idioma | translate: "legales.LEGAL-CTF-3" | async}}
                                    - <a (click)="openBlank('CFR')" class="link" style="text-decoration: none;">{{ idioma | translate:"legales.LEGAL-CTF-1" | async}}</a></em>
                            </div>

                            <div class="form-group">
                                <input type="checkbox" formControlName="clausula" style="margin-right: 5px !important;"> <em>{{ idioma | translate: "legales.LEGAL-CTF-4" | async}}</em>
                            </div>

                            <div class="form-group">
                                <input type="checkbox" formControlName="aceptaTerminos" style="margin-right: 5px !important;"> <em>{{ idioma | translate: "legales.LEGAL-CTF-5" | async}} 
                                - <a (click)="openBlank('TC')" class="link" style="text-decoration: none;">{{ idioma | translate:"legales.LEGAL-CTF-TC" | async}}</a></em>
                            </div>

                            <div class="form-group">
                                <input type="checkbox" formControlName="consentimiento" style="margin-right: 5px !important;"> <em>{{ idioma | translate: "legales.LEGAL-CTF-6" | async}}</em>
                            </div>

                            <!-- FIN CERTIFICACION -->

                            <div class="form-group">
                                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                                </ngx-recaptcha2>
                            </div>

                            <!-- <button *ngIf="registerForm.valid" type="submit" ><i class="fas fa-sign-in-alt"></i> {{ idioma | translate: "login.BTN-REGISTRARME" | async}}</button> -->
                          
                            <button [disabled]="!registerForm.valid" [ngClass]="{'custom-disabled': !registerForm.valid}"> {{ idioma | translate: "login.BTN-REGISTRARME" | async}}</button>
                            <br>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>