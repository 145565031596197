import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { Curso } from '../../../../interfaces/interface-bakend/cursos/curso.interface';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Titulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';

@Component({
  selector: 'app-titulos-listado',
  templateUrl: './titulos-listado.component.html',
  styleUrls: ['./titulos-listado.component.scss']
})
export class TitulosListadoComponent implements OnInit, AfterViewInit {

  /* Data Table*/

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
  [
    //'nombre', 'introduccion', 'descripcion', 'fechaInicio', 'fechaFin', 'precio', 'disponible', '_id'
    'nombre', 'disciplina', 'observaciones', 'nivel', 'estado', 'mesesVigencia','id'
  ];
  dataSource: any;
 // cursos : Curso [];

  /* Fin Data Table*/

  flagLoaded = false;
  cursoDisponible: string;

  constructor(
    private cursosService : CursosService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    await this.verCursos();
  }

  async verCursos() {
    this.flagLoaded = false;
    this.loadDataTable();
  }

  async loadDataTable(){

    await this.cursosService.getTitulos().then( (titulos : Titulo[]) =>{
      this.formatDataTable(titulos).then( ( data: Curso[] ) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.flagLoaded = true;
      });
      
    });

  }

  formatDataTable( titulos: Titulo[]) {
    return new Promise( resolve => {
      const format: any[] = [];
      let elemento: {
        id: string,
        nombre: string,
        disciplina: string,
        observaciones: string,
        nivel: number,
        activo: string,
        mesesVigencia: number,
        titulo: Titulo
      };
       // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < titulos.length; i++) {
        elemento = {
          id: titulos[i]._id,
          nombre: titulos[i].nombre,
          disciplina: titulos[i].disciplina.nombre,
          nivel: titulos[i].nivel,
          observaciones: titulos[i].observaciones,
          activo: titulos[i].activo ? 'Activo': 'Inactivo',
          mesesVigencia: titulos[i].mesesVigencia ? titulos[i].mesesVigencia : null,
          titulo: titulos[i]
      };
        format.push(elemento);
      }
      resolve(format);
    });
  }

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

async editarTitulo(titulo: Titulo) {
  this.cursosService.setTituloSeleccionado(titulo);
  this.router.navigateByUrl('/titulo-modificacion');
}

}
