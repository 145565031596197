import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catedras-configuracion',
  templateUrl: './catedras-configuracion.component.html',
  styleUrls: ['./catedras-configuracion.component.scss']
})
export class CatedrasConfiguracionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
