
<div  class="container-fluid px-4">

    <!-- <div mat-dialog-actions align="end">
        <button (mouseover)="setDataFormarefreshComponents()" (click)="setDataFormarefreshComponents()" mat-mini-fab
            aria-label="">
            <mat-icon>refresh</mat-icon>
        </button>
    </div> -->

    <nav class="sb-topnav navbar navbar-expand navbar-light bg-light">
        <div class="container-fluid">
            <a class="navbar-brand">Estadísticas</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" routerLink="/">Home</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Opciones
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" routerLink="/">Inicio</a></li>
                            <!-- <li><a class="dropdown-item" routerLink="/ofertas-estadisticas">Ofertas Estadísticas</a></li> -->
                            <!-- <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li><a class="dropdown-item" href="#">Reportes</a></li> -->
                        </ul>
                    </li>
                </ul>
                <div class="d-flex align-items-center">
                    <div class="indicator d-flex align-items-center">
                        <mat-icon>perm_identity</mat-icon>
                        <span class="ms-2">{{ cantTotalUsuarios | number }}</span>
                    </div>
                    <div class="indicator d-flex align-items-center ms-3">
                        <mat-icon>fact_check</mat-icon>
                        <span class="ms-2">{{ cantTotalSolicitudes | number }}</span>
                    </div>
                </div>
            </div>
        </div>
    </nav>

    <br>

    <button class="btn btn-primary mb-4" (click)="toggleCards()">
        {{ showCards ? 'Ocultar' : 'Mostrar' }} Dashboard - Indicadores Totales (desde inicio del sistema..) 
    </button>

    <!-- Dashboard -->
    <div class="row" *ngIf="showCards">
        <div class="col-xl-3 col-md-6">
            <div class="card bg-primary-set text-white mb-4">
                <div class="card-body">
                    Formulario Cargados<span class="badge bg-secondary">{{this.cantFormularioCargado}}</span>
                </div>
                <div class="card-footer d-flex align-items-center justify-content-between">
                    <a (click)="navigateToSolicitudes(this.iAppOfertas.estado_1.number)"
                        class="small text-white stretched-link menu_links">Ver Detalle</a>
                    <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6">
            <div class="card bg-warning text-white mb-4">
                <div class="card-body">
                    Controlar Pagos<span class="badge bg-secondary">{{this.cantControlarPago}}</span>
                </div>
                <div class="card-footer d-flex align-items-center justify-content-between">
                    <a (click)="navigateToSolicitudes(this.iAppOfertas.estado_6.number)"
                        class="small text-white stretched-link menu_links">Ver Detalle</a>
                    <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6">
            <div class="card bg-success text-white mb-4">
                <div class="card-body">
                    Aprobadas<span class="badge bg-secondary">{{this.cantAprobado}}</span>
                </div>
                <div class="card-footer d-flex align-items-center justify-content-between">
                    <a (click)="navigateToSolicitudes(this.iAppOfertas.estado_7.number)"
                        class="small text-white stretched-link menu_links">Ver Detalle</a>
                    <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6">
            <div class="card bg-danger text-white mb-4">
                <div class="card-body">
                    Rechazadas<span class="badge bg-secondary">{{this.cantRechazado}}</span></div>
                <div class="card-footer d-flex align-items-center justify-content-between">
                    <a (click)="navigateToSolicitudes(this.iAppOfertas.estado_8.number)"
                        class="small text-white stretched-link menu_links">Ver Detalle</a>
                    <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                </div>
            </div>
        </div>
    </div>

    <br>


    <div class="row" *ngIf="showCards">
        <div class="col-xl-6">
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-chart-pie me-1"></i> Solicitudes por estado
                </div>
                <div class="card-body">
                        <app-doughnut-chart 
                            [cantDocumentosCargadosParciales]=cantDocumentosCargadosParciales
                            [cantFormularioCargado]=cantFormularioCargado 
                            [cantPreInscripcion]=cantPreInscripcion
                            [cantDocumentosCargadosCompleto]=cantDocumentosCargadosCompleto 
                            [cantPreaprobado]=cantPreaprobado
                            [cantPagoSolicitado]=cantPagoSolicitado
                            [cantAprobado]=cantAprobado
                            [cantControlarPago]=cantControlarPago
                            [cantRechazado]=cantRechazado
                            [cantAnulada]=cantAnulada
                            [cantEnEspera]=cantEnEspera>
                        </app-doughnut-chart>
                    </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-chart-bar me-1"></i> Solicitudes Progreso
                </div>
                <div class="card-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4">
                                <p class="card-text">Pre Inscripción</p>
                                <div class="progress mb-3">
                                    <div class="progress-bar" style="background-color:#0d6efd" role="progressbar"
                                        [style.width]="porPreInscripcion + '%'" aria-valuenow="porPreInscripcion"
                                        aria-valuemin="0" aria-valuemax="100">{{porPreInscripcion | number:'1.0-0' }}%</div>
                                </div>
                                <p class="card-text">Formulario Cargado</p>
                                <div class="progress mb-3">
                                    <div class="progress-bar" style="background-color:#F9E79F" role="progressbar"
                                        [style.width]="porcFormularioCargado + '%'" aria-valuenow="porcFormularioCargado"
                                        aria-valuemin="0" aria-valuemax="100">{{porcFormularioCargado | number:'1.0-0' }}%
                                    </div>
                                </div>
                                <p class="card-text">Documentos Parciales</p>
                                <div class="progress mb-3">
                                    <div class="progress-bar" style="background-color:#85929E" role="progressbar"
                                        [style.width]="porcDocumentosCargadosParciales + '%'"
                                        aria-valuenow="porcDocumentosCargadosParciales" aria-valuemin="0"
                                        aria-valuemax="100">
                                        {{porcDocumentosCargadosParciales | number:'1.0-0' }}%</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <p class="card-text">Documentos Cargados</p>
                                <div class="progress mb-3">
                                    <div class="progress-bar" style="background-color:#85929E" role="progressbar"
                                        [style.width]="porcDocumentosCargadosCompleto + '%'"
                                        aria-valuenow="porcDocumentosCargadosCompleto" aria-valuemin="0"
                                        aria-valuemax="100">
                                        {{porcDocumentosCargadosCompleto | number:'1.0-0' }}%</div>
                                </div>
                                <p class="card-text">Pre-Aprobado</p>
                                <div class="progress mb-3">
                                    <div class="progress-bar" style="background-color:#CD5C5C" role="progressbar"
                                        [style.width]="porcPreaprobado + '%'" aria-valuenow="porcPreaprobado"
                                        aria-valuemin="0" aria-valuemax="100">{{porcPreaprobado | number:'1.0-0' }}%</div>
                                </div>
                                <p class="card-text">Pago Solicitado</p>
                                <div class="progress mb-3">
                                    <div class="progress-bar" style="background-color:#85C1E9" role="progressbar"
                                        [style.width]="porcPagoSolicitado + '%'" aria-valuenow="porcPagoSolicitado"
                                        aria-valuemin="0" aria-valuemax="100">{{porcPagoSolicitado | number:'1.0-0' }}%
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <p class="card-text">Controlar Pago</p>
                                <div class="progress mb-3">
                                    <div class="progress-bar" style="background-color:#FFC300" role="progressbar"
                                        [style.width]="porcControlarPago + '%'" aria-valuenow="porcControlarPago"
                                        aria-valuemin="0" aria-valuemax="100">{{porcControlarPago | number:'1.0-0' }}%</div>
                                </div>
                                <p class="card-text">Aprobados</p>
                                <div class="progress mb-3">
                                    <div class="progress-bar" style="background-color:#FFC300" role="progressbar"
                                        [style.width]="porcAprobado + '%'" aria-valuenow="porcAprobado" aria-valuemin="0"
                                        aria-valuemax="100">{{porcAprobado | number:'1.0-0' }}%</div>
                                </div>
                                <p class="card-text">Rechazados</p>
                                <div class="progress mb-3">
                                    <div class="progress-bar" style="background-color:#FFA07A" role="progressbar"
                                        [style.width]="porcRechazado+ '%'" aria-valuenow="porcRechazado" aria-valuemin="0"
                                        aria-valuemax="100">{{porcRechazado| number:'1.0-0' }}%</div>
                                </div>
                                <p class="card-text">Anulados</p>
                                <div class="progress mb-3">
                                    <div class="progress-bar  bg-success" role="progressbar"
                                        [style.width]="porcAnulada + '%'" aria-valuenow="porcAnulada" aria-valuemin="0"
                                        aria-valuemax="100">{{porcAnulada |
                                        number:'1.0-0' }}%</div>
                                </div>
                                <p class="card-text">En espera</p>
                                <div class="progress mb-3">
                                    <div class="progress-bar  bg-danger" role="progressbar" [style.width]="porcEspera + '%'"
                                        aria-valuenow="porcEspera" aria-valuemin="0" aria-valuemax="100">{{porcEspera |
                                        number:'1.0-0' }}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="profile-courses-quizzes">
        <mat-card class="search-card">
            <mat-card-title>Buscar por rango</mat-card-title>
            <div class="container mt-4">
                <mat-form-field class="mr-2">
                    <mat-label>Desde</mat-label>
                    <input matInput [matDatepicker]="pickerDesde" placeholder="Seleccione una fecha"
                        [(ngModel)]="fechaDesde">
                    <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDesde></mat-datepicker>
                </mat-form-field>
    
                <!-- Espacio entre los campos de búsqueda -->
                <div style="width: 20px;"></div>
    
                <mat-form-field class="mr-2">
                    <mat-label>Hasta</mat-label>
                    <input matInput [matDatepicker]="pickerHasta" placeholder="Seleccione una fecha"
                        [(ngModel)]="fechaHasta">
                    <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
                    <mat-datepicker #pickerHasta></mat-datepicker>
                </mat-form-field>
    
                <!-- Botones para filtrar Online o Presenciales -->
                <div class="example-button-row mt-3">
                    <button mat-button mat-raised-button color="warn" [class.selected-button]="!filtroPresenciales"
                        (click)="filtroActividades(false)">Online</button>
                    <div style="width: 10px;"></div> <!-- Espacio entre los botones -->
                    <button mat-button mat-raised-button color="basic" [class.selected-button]="filtroPresenciales"
                        (click)="filtroActividades(true)">Presenciales</button>
                </div>
    
                <!-- Botón para buscar -->
                <button mat-raised-button color="primary" class="mt-3" (click)="buscarPorFechas()">Buscar</button>
            </div>
        </mat-card>
    
        <app-loading *ngIf="!flagCargado"></app-loading>
    </div>


    <!-- Solicitudes -->
    <div class="card mb-4"  *ngIf="this.flagCargado">
        <div class="card-header">
            <i class="fas fa-table me-1"></i> Ofertas...
        </div>
        <div class="card-body">
            <app-ofertas-table [solicitudes]=solicitudes></app-ofertas-table>
        </div>
    </div>
</div>