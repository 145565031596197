
  <div *ngIf="profesor; else noProfesorMessage" class="row align-items-center">
    <div class="col-lg-3 col-md-3">
        <div class="advisor-image">
            <img [src]="(profesor.avatar === 'av-1.png') ? rutaImagenDefault : profesor.avatar" [alt]="profesor.nombrePersona" style="max-width: 80px; height: auto;">
        </div>
    </div>      
    <div class="col-lg-9 col-md-9">
        <div class="advisor-content">
            <div class="card">
                <div class="card-body" style="padding: 0.5rem;">
                    <h6 class="card-title mb-1" style="margin-bottom: 0.25rem;">{{ convertirACamelCase(profesor?.nombrePersona) || 'Sin nombre en sistema'}} {{ convertirACamelCase(profesor?.apellidoPersona) || 'Sin apellido en sistema'}}</h6>
                    <p class="card-text mb-0" style="font-size: 0.8rem; margin-bottom: 0;">{{ profesor.email }}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #noProfesorMessage>
    <div class="alert alert-info" role="alert">
        Profesores no disponibles
    </div>
</ng-template>

  