<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li><a routerLink="/examenes-listado">Gestión de Examenes</a></li>
                <li>Modificar Examen</li>
            </ul>
            <h2>Modificar Examen</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<br>

<mat-tab-group #allTabs>
    <mat-tab label="Tab 1">
        <section *ngIf="tabPreguntasActivated">
            <mat-card class="material-card">
                <div mat-dialog-actions align="end">
                    <button (click)='moveToSelectedTab("description")' mat-mini-fab color="accent">
                 <mat-icon>arrow_right_alt</mat-icon>
                </button>
                </div>
            </mat-card>
        </section>

        <ng-template mat-tab-label>
            <mat-icon class="examen-tab-icon">settings</mat-icon>
        </ng-template>

        <app-evaluacion-config-modificar></app-evaluacion-config-modificar>
    </mat-tab>


    <mat-tab label="Tab 2">
        <section>
            <mat-card class="material-card">
                <div mat-dialog-actions align="end">
                    <button (click)='moveToSelectedTab("done")' mat-mini-fab color="accent">
                 <mat-icon>arrow_right_alt</mat-icon>
                </button>
                </div>
            </mat-card>
        </section>
        <ng-template mat-tab-label>
            <mat-icon class="examen-tab-icon">description</mat-icon>
            <!-- Agregar Preguntas -->
        </ng-template>
        <div class="examen-large-box mat-elevation-z4">
            <app-evaluacion-preguntas-config [modificar]=true></app-evaluacion-preguntas-config>
        </div>
    </mat-tab>

    <mat-tab label="Tab 3">
        <section>
            <mat-card class="material-card">
                <div mat-dialog-actions align="end">
                    <button (click)='moveToSelectedTab("grading")' mat-mini-fab color="accent">
                 <mat-icon>arrow_right_alt</mat-icon>
                </button>
                </div>
            </mat-card>
        </section>
        <ng-template mat-tab-label>
            <mat-icon class="examen-tab-icon">done</mat-icon>
            <!-- Agregar Respuestas -->
        </ng-template>

        <div class="examen-large-box mat-elevation-z4">
            <app-respuestas-alta [modificar]=true></app-respuestas-alta>
        </div>
    </mat-tab>

    <mat-tab label="Tab 4">
        <section>
            <mat-card class="material-card">
                <div mat-dialog-actions align="end">
                    <button (click)='moveToSelectedTab("grading")' mat-mini-fab color="accent">
                 <mat-icon>arrow_right_alt</mat-icon>
                  <!-- Editar Texto y valores -->
                </button>
                </div>
            </mat-card>
        </section>
        <ng-template mat-tab-label>
            <mat-icon class="examen-tab-icon">app_registration</mat-icon>
        </ng-template>

        <div class="examen-large-box mat-elevation-z4">
            <app-evaluacion-config-preguntas></app-evaluacion-config-preguntas>
        </div>
    </mat-tab>

    <mat-tab label="Tab 5">
        <section>
            <mat-card class="material-card">
                <div mat-dialog-actions align="end">
                    <button (click)='moveToSelectedTab("settings")' mat-mini-fab color="accent">
                 <mat-icon>arrow_left_alt</mat-icon>
                </button>
                </div>
            </mat-card>
        </section>
        <ng-template mat-tab-label>
            <mat-icon class="examen-tab-icon">grading</mat-icon>
        </ng-template>

        <div class="examen-large-box mat-elevation-z4">
            <app-evaluacion-asociar-modificar></app-evaluacion-asociar-modificar>
        </div>
    </mat-tab>
</mat-tab-group>