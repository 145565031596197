import { BankTransfer } from './../../../../interfaces/interface-bakend/invoices/bankTransfer.interfaces';
import { Component, Inject, OnInit } from '@angular/core';
import { BankTransferService } from '../../../../services/shared/bank-transfer.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Invoice } from 'src/app/interfaces/interface-bakend/invoices/invoices.interfaces';
import { InvoicesService } from 'src/app/services/shared/invoices.service';

@Component({
  selector: 'app-comprobante-transf-detalle',
  templateUrl: './comprobante-transf-detalle.component.html',
  styleUrls: ['./comprobante-transf-detalle.component.scss']
})
export class ComprobanteTransfDetalleComponent implements OnInit {

    activarCerrarModal = true;

    bankTransfers: any;
    flagLoaded = false;
    isPdf = false;
    invoice: Invoice;

    constructor(
        private bankTransferService: BankTransferService,
        private invoicesService: InvoicesService,

        /* Modal Ref. */
        public dialogRef: MatDialogRef<ComprobanteTransfDetalleComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        /* Fin Modal Ref. */
    ) { }

    async ngOnInit(): Promise<void> {
        this.getBankTransfer();
    }

    async getBankTransfer(): Promise<BankTransfer> {
        this.invoice = await this.invoicesService.getInvoiceSeleccionado();
        this.bankTransfers = await this.bankTransferService.getBankTransferSeleccionado();
      
        if (this.bankTransfers.path.search('pdf') !== -1) {
            this.isPdf = true;
        } else {
            this.isPdf = false;
        }
        this.flagLoaded = true;

        console.log('this.invoice', this.invoice);
        console.log('this.bankTransfers', this.bankTransfers);
        return this.bankTransfers;
    }

    // MODAL CLOSE
    closeModal(): void {
        this.dialogRef.close();
    }


}
