import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HolderId, CardData } from '../../../../../interfaces/interface-frontend/shared/cardData.interface';
import { Output, Input } from '@angular/core';
import { Translators } from '../../../../../services/shared/translators.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Output() cardData = new EventEmitter<CardData>();
  @Output() cardDataFlag = new EventEmitter<boolean>();
  @Input() activar: boolean;

  forma: FormGroup;
  regEmail = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  idioma: Subscription;

  constructor(
    private translatorService: Translators
  ) {

    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });

      this.createForm();
      this.createListenersStateForm();
  }

  ngOnInit(): void {
  }

  createForm() {
    this.forma = new FormGroup({
        card_holder_first_name: new FormControl('',  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
        card_holder_last_name: new FormControl('',  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
        card_number: new FormControl('', [Validators.required, Validators.minLength(16), Validators.maxLength(16)]),
        card_expiration_month: new FormControl('', [ Validators.required, Validators.min(1), Validators.max(12)]),
        card_expiration_year: new FormControl('',
                                        [ Validators.required, Validators.min((+(new Date().getFullYear().toString().substring(2, 4))))]),
        security_code: new FormControl('', [ Validators.required, Validators.minLength(3), Validators.maxLength(10) ]),
        tipoIdentificacion: new FormControl('', [ Validators.required ]),
        identificacion: new FormControl('', [ Validators.required,  Validators.minLength(1), Validators.maxLength(30) ]),
        email: new FormControl('', [ Validators.required, Validators.pattern(this.regEmail) ]),
        phoneAreaCode: new FormControl('', [ Validators.required, Validators.required, Validators.minLength(1), Validators.maxLength(20) ]),
        phoneNumber: new FormControl('', [ Validators.required, Validators.minLength(1), Validators.maxLength(20) ]),
    });
  }

  createListenersStateForm() {
    return this.forma.statusChanges.subscribe( async status => {
        if (status === 'VALID') {
            this.emitData(await this.setDatosCard());
        } else {
            this.emitDataFlag(false);
        }
    });
  }

  async setDatosCard(): Promise<CardData>{
    const holderId: HolderId = {type: this.forma.value.tipoIdentificacion, number: this.forma.value.identificacion};

    const cardDataUser: CardData = {
        card_number: this.forma.value.card_number,
        card_expiration_month: this.forma.value.card_expiration_month,
        card_expiration_year: this.forma.value.card_expiration_year,
        security_code: this.forma.value.security_code,
        card_holder_first_name: this.forma.value.card_holder_first_name,
        card_holder_last_name: this.forma.value.card_holder_first_last,
        email: this.forma.value.email,
        phoneAreaCode: this.forma.value.phoneAreaCode,
        phoneNumber: this.forma.value.phonenumber,
        card_holder_identification: holderId,
        date_processed_system: new Date(),
    };

    return cardDataUser;

  }

  emitData(data: CardData) {
    this.cardData.emit(data);
    this.cardDataFlag.emit(true);
  }

  emitDataFlag(flag: boolean) {
    this.cardDataFlag.emit(flag);
  }

  createListenersForm() {
    this.forma.valueChanges.subscribe( valoresForm => {
    // example change value
    });
    // example change value
  }

  /* validaciones form */

  get card_holder_first_name() {
    return this.forma.get('card_holder_first_name').invalid && this.forma.get('card_holder_first_name').touched;
  }

  get card_holder_last_name() {
    return this.forma.get('card_holder_last_name').invalid && this.forma.get('card_holder_last_name').touched;
  }

  get card_number() {
    return this.forma.get('card_number').invalid && this.forma.get('card_number').touched;
  }

  get card_expiration_month() {
    return this.forma.get('card_expiration_month').invalid && this.forma.get('card_expiration_month').touched;
  }

  get card_expiration_year() {
    return this.forma.get('card_expiration_year').invalid && this.forma.get('card_expiration_year').touched;
  }

  get security_code() {
    return this.forma.get('security_code').invalid && this.forma.get('security_code').touched;
  }

  get tipoIdentificacion() {
    return this.forma.get('tipoIdentificacion').invalid && this.forma.get('tipoIdentificacion').touched;
  }

  get identificacion() {
    return this.forma.get('identificacion').invalid && this.forma.get('identificacion').touched;
  }

  get email() {
    return this.forma.get('email').invalid && this.forma.get('email').touched;
  }

  get phoneAreaCode() {
    return this.forma.get('phoneAreaCode').invalid && this.forma.get('phoneAreaCode').touched;
  }

  get phoneNumber() {
    return this.forma.get('phoneNumber').invalid && this.forma.get('phoneNumber').touched;
  }
}
