import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Gesto } from 'src/app/interfaces/interface-bakend/cursos/gesto.interface';
import { GestoUsuario, GestoUsuarioCarga, GestoUsuarioCargaMasiva, UserGestos } from 'src/app/interfaces/interface-bakend/cursos/gestoUsuario.interface';
import { Mesa } from 'src/app/interfaces/interface-bakend/cursos/mesa.interface';

import { GestosService } from 'src/app/services/cursos/gestos.service';
import { MesasService } from 'src/app/services/cursos/mesas.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-gestos-configuracion',
  templateUrl: './gestos-configuracion.component.html',
  styleUrls: ['./gestos-configuracion.component.scss']
})
export class GestosConfiguracionComponent implements OnInit {

  formulario: FormGroup;
  resultadosMesa: any[] = [];
  mesa: Mesa;
  usuarios: UserGestos[] = [];
  gestos: Gesto[] = [];
  gestosCargados: boolean = false;
  usuariosCargados: boolean = false;
  puntajesCargados: GestoUsuario[] = [];
  idUsuariosMesa: string[] = [];
  usuariosFiltrado: any[];
  usuariosFaltantes: any[];

  puntajesCargadosMasivo: GestoUsuario[] = [];

  constructor(private formBuilder: FormBuilder, private gestoService: GestosService,
    private mesaService: MesasService, private router: Router, private guiMsjService: GuiMsjService,
    private gestosService: GestosService) {

    this.formulario = this.formBuilder.group({});
    // Agregar un nuevo FormControl al formulario
    this.formulario.addControl('fechaEvaluacion', new FormControl(new Date()));
  }

  ngOnInit() {
    this.cargarEstadoVariables();
  }

  generarFormulario() {
    this.formulario = this.formBuilder.group({});
    if (this.usuariosCargados && this.gestosCargados && this.usuariosFaltantes.length >= 1 && this.gestos.length >= 1) {
      for (let i = 0; i < this.usuarios.length; i++) {
        for (let j = 0; j < this.gestos.length; j++) {
          const controlName = `campo_${i}_${j}`;
          this.formulario.addControl(controlName, this.formBuilder.control(''));
        }
      }
    }
    this.formulario.addControl('fechaEvaluacion', new FormControl(new Date(), Validators.required));
  }

  get fechaEvaluacion() {
    return this.formulario.get('fechaEvaluacion');
  }
  

  getMesaSeleccionada(): Mesa | undefined {
    return this.mesa = this.mesaService.getMesaSeleccion();
  }

  confirmReset() {
    const confirmed = window.confirm('¿Estás seguro de que deseas borrar todos los puntajes?');
    if (confirmed) {
      this.resetForm();
    }
  }

  resetForm() {
    this.formulario.reset();
    // Establece un valor predeterminado para el campo fechaEvaluacion
    this.formulario.get('fechaEvaluacion').patchValue(new Date());
  }

  async cargarEstadoVariables() {
    if (this.getMesaSeleccionada() === undefined) {
      this.router.navigateByUrl('/mesas-listado');
      return;
    } else {
      this.getMesaSeleccionada();
      const mesa = this.getMesaSeleccionada()._id;
      this.usuarios = (await this.mesaService.getMesaFindOne(mesa)).usuarios;

      /* Cargo todos los puntajes cargados */
      this.puntajesCargadosMasivo = await this.gestosService.getGestosEvaluados();

      let puntajesFiltrado = this.puntajesCargadosMasivo.filter(element => element.mesa._id === mesa);
      let usuariosProcesados = {};

      this.usuariosFiltrado = puntajesFiltrado.filter(objeto => {
        const usuarioId = objeto.usuario._id;
        if (!usuariosProcesados[usuarioId]) {
          usuariosProcesados[usuarioId] = true;
          return true;
        }
        return false;
      });

      this.usuariosFaltantes = this.usuariosFaltantesMesa(this.usuarios, this.usuariosFiltrado);

      if (Object.keys(this.usuariosFaltantesMesa(this.usuarios, this.usuariosFiltrado)).length > 0) {
        this.usuariosCargados = true;
        this.getGestos();
        this.generarFormulario();
      } else {
        Swal.fire({
          title: 'Se han cargado todos los puntajes posibles para esta mesa',
          text: 'Si desea realizar modificaciones individuales, puede hacerlo ahora.',
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: 'Modificar puntaje de gestos',
          cancelButtonText: 'Cancelar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.router.navigateByUrl('/gestos-evaluaciones-listado');
          } else {
            this.router.navigateByUrl('/mesas-listado');
          }
        });
      }
    }
  }

  usuariosFaltantesMesa(primerObjeto: any[], segundoObjeto: any[]) {
    // Capturo los usuarios asociados a la mesa
    const usuariosPrimerObjeto = primerObjeto.map(item => item.usuario._id);

    // Filtrar los usuarios de la mesa que no están en la consulta general de puntajes
    const usuariosFaltantes = primerObjeto.filter(item => {
      return !segundoObjeto.find(obj => obj.usuario._id === item.usuario._id);
    });

    return usuariosFaltantes;
  }

  /**
   * Título: Cargo gestos a recorrer
   * Descripción: Filtro la carga de gestos para que coincidan con los de la mesa
   * Autor: Bruno
   * Fecha de modificación: 14 de julio de 2023
  */
  async getGestos(){
    try {
      const gestos = await this.gestoService.getGestos();
      const gestosFiltrados = gestos?.filter((gesto: Gesto) =>
        gesto.nivel === this.mesa?.nivel &&
        gesto.disciplina._id === this.mesa?.disciplina._id &&
        gesto.activo
      );
      if (gestosFiltrados.length >= 1) {
        this.gestos = gestosFiltrados;
        this.gestosCargados = true;
        this.generarFormulario();
      } else {
        Swal.fire({
          icon: 'info',
          title: 'No existen gestos para evaluar o bien se encuentran inactivos, cargue un nuevo gesto del mismo nivel y disciplina e intente nuevamente',
          showCancelButton: true,
          confirmButtonText: 'Ir a Gestos',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/gestos-listado']);
          }else{
            this.router.navigate(['/mesas-listado']);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Título: Captura valor input
   * Descripción: se captura cada valor de evaluación del input de gestos,
   * recibo como parámetro el valor del input, y las posiciones tanto en el
   * row como por cada columna
   * Autor: Bruno
  */
  capturarPuntajeGesto(event: any, rowIndex: number, columnIndex: number) {
    const valor = event.target.value;

    // Capturar el ID del gesto seleccionado
    const gestoId = this.gestos[columnIndex]._id;

    // Capturar el ID del usuario seleccionado
    const userId = this.usuariosFaltantes[rowIndex].usuario;

    // Generar el objeto y almacenarlo en resultadosMesa
    const objeto: GestoUsuarioCarga = {
      usuario: userId._id,
      gesto: gestoId,
      nota: valor
    };

    // Almacenar el objeto en resultadosMesa
    if (!this.resultadosMesa[rowIndex]) {
      this.resultadosMesa[rowIndex] = [];
    }
    this.resultadosMesa[rowIndex][columnIndex] = objeto;
  }

  async setData() {

    const gestosEvaluados = this.gestos.map(gesto => gesto._id)

    /***  CONCATENO LOS ARRAYS DE CADA USUARIO Y SUS GESTOS ASI DEJO UN SÓLO ARRAY resultadosMesa ***/
    const resultadosMesa = [].concat(...this.resultadosMesa);

    /*** FILTRO LOS QUE VENGAN EN NULL ***/
    const filtrarNulls = resultadosMesa.filter(Boolean);

    /***  AHORA FILTRO LOS INPUT QUE HAN SIDO SELECCIONADO PERO SI NOTA ***/
    const objetosFiltrados = filtrarNulls.filter(objeto => objeto.nota !== null && objeto.nota !== "");


    const data: GestoUsuarioCargaMasiva = {
      mesa: this.getMesaSeleccionada()?._id,
      fechaEvaluacion: this.formulario.value.fechaEvaluacion,
      gestosEvaluados: gestosEvaluados,
      resultadosMesa: objetosFiltrados
    }
    return data;
  }

  cancelarCarga(): void {
    this.router.navigateByUrl('/mesas-listado');
  }

  async post() {
    Swal.fire({
      title: 'Confirmación',
      text: '¿Confirma la carga masiva de gestos?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.guiMsjService.msjFormSubmit('Guardando');
        this.gestoService.post(await this.setData()).then(async (gesto: GestoUsuarioCargaMasiva) => {
        }).catch(error => {
          console.warn(error);
          Swal.close();
        });
      }
    });
  }

}
