import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Gesto } from 'src/app/interfaces/interface-bakend/cursos/gesto.interface';
import { GestoUsuario, GestoUsuarioCarga, GestoUsuarioCargaMasiva, UserGestos } from 'src/app/interfaces/interface-bakend/cursos/gestoUsuario.interface';
import { Mesa } from 'src/app/interfaces/interface-bakend/cursos/mesa.interface';
import { GestosService } from 'src/app/services/cursos/gestos.service';
import { MesasService } from 'src/app/services/cursos/mesas.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gestos-configuracion-usuario',
  templateUrl: './gestos-configuracion-usuario.component.html',
  styleUrls: ['./gestos-configuracion-usuario.component.scss']
})
export class GestosConfiguracionUsuarioComponent implements OnInit {

  formulario: FormGroup;
  resultadosMesa: any[] = [];
  mesa: Mesa;
  usuarios: UserGestos[] = [];
  usuarioSeleccionado: UserGestos[] = [];
  gestos: Gesto[] = [];
  gestosUsuario: any[] = [];
  gestosCargados: boolean = false;
  usuariosCargados: boolean = false;
  puntajesCargados: GestoUsuario[] = [];
  idUsuariosMesa: string[] = [];
  usuariosFiltrado: any[];
  usuariosFaltantes: any[];
  puntajesCargadosMasivo: GestoUsuario[] = [];

  constructor(private formBuilder: FormBuilder, private gestoService: GestosService,
    private mesaService: MesasService, private router: Router, private guiMsjService: GuiMsjService,
    private gestosService: GestosService, private usuarioService: UsuarioService) {

    this.formulario = this.formBuilder.group({});
    // Agregar un nuevo FormControl al formulario
    this.formulario.addControl('fechaEvaluacion', new FormControl(new Date()));
  }

  ngOnInit() {
    this.cargarEstadoVariables();
  }

  generarFormulario() {
    this.formulario = this.formBuilder.group({});
    if (this.usuarios && this.gestosCargados && this.usuarios.length >= 1 && this.gestos.length >= 1) {
      for (let i = 0; i < this.usuarios.length; i++) {
        for (let j = 0; j < this.gestos.length; j++) {
          const controlName = `campo_${i}_${j}`;
          this.formulario.addControl(controlName, this.formBuilder.control(''));
        }
      }
    }
    this.formulario.addControl('fechaEvaluacion', new FormControl(new Date(), Validators.required));
  }

  get fechaEvaluacion() {
    return this.formulario.get('fechaEvaluacion');
  }

  async cargarEstadoVariables() {
    if (this.getMesaSeleccionada() === undefined) {
      this.router.navigateByUrl('/mesas-listado');
      return;
    } else {
      this.mesa = this.getMesaSeleccionada();
      const usuario = this.usuarioService.getUsuarioSeleccionado();
      this.usuarios = (await this.mesaService.getMesaFindOne(this.mesa._id)).usuarios;
      /* Cargo todos los puntajes cargados */
      this.puntajesCargadosMasivo = await this.gestosService.getGestosEvaluados();
      this.usuarioSeleccionado = this.usuarios.filter(x => x.usuario._id === usuario._id);
      this.usuariosCargados = true;
      this.getGestos();
      this.generarFormulario();
    }
  }

  /**
   * Título: Cargar mesa seleccionada
   * Descripción: Cargo la mesa seleccionada y filtro si al usuario que quiero cargar 
   * más puntajes pertenece a la mesa, sino retorno a lista de mesas
   * Autor: Bruno
   * Fecha de creación: 16 de julio de 2023
  */
  getMesaSeleccionada(): Mesa | undefined {
    const mesa = this.mesaService.getMesaSeleccion();
    const usuario = this.usuarioService.getUsuarioSeleccionado();

    const usuarioMesa = mesa.usuarios.find((usuarios) => {
      return usuarios.usuario._id === usuario._id;
    });

    if (usuarioMesa == undefined) {
      Swal.fire({
        icon: 'info',
        title: 'El usuario no está asociado en esta mesa',
      })
      this.router.navigate(['/gestos-evaluaciones-usuarios-listado']);
    }
    return mesa;
  }

  /**
   * Título: Cargo gestos a recorrer
   * Descripción: Filtro la carga de gestos para que coincidan con los de la mesa
   * Autor: Bruno
   * Fecha de modificación: 14 de julio de 2023
  */
  async getGestos() {
    try {
      const usuario = this.usuarioService.getUsuarioSeleccionado();
      this.gestos = await this.gestoService.getGestos();
      this.gestosUsuario = await this.gestoService.getGestosEvaluadosByUser(usuario._id);

      const gestosFiltrados = this.gestos?.filter((gesto: Gesto) =>
        gesto.nivel === this.mesa?.nivel &&
        gesto.disciplina._id === this.mesa?.disciplina._id &&
        gesto.activo
      );

      const gestosFaltantes = this.gestosFaltantes(gestosFiltrados, this.gestosUsuario);
      if(gestosFaltantes.length === 0){
        Swal.fire({
          title: 'Se han cargado todos los puntajes posibles para este usuario',
          text: 'Quiere modificar los puntajes ya cargados?',
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: 'Modificar puntaje de gestos',
          cancelButtonText: 'Cancelar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.router.navigateByUrl('/gestos-evaluaciones-usuarios-listado');
          } else {
            this.router.navigateByUrl('/mesas-listado');
          }
        });
      }else{
        if (gestosFiltrados.length >= 1) {
          this.gestos = gestosFaltantes;
          this.gestosCargados = true;
          this.generarFormulario();
        } else {
          Swal.fire({
            icon: 'info',
            title: 'No existen gestos para evaluar o bien se encuentran inactivos, cargue un nuevo gesto del mismo nivel y disciplina e intente nuevamente',
            showCancelButton: true,
            confirmButtonText: 'Ir a Gestos',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/gestos-listado']);
            } else {
              this.router.navigate(['/mesas-listado']);
            }
          });
        }
      } 
    } catch (error) {
      console.error(error);
    }
  }

  gestosFaltantes(primerObjeto: any[], segundoObjeto: any[]) {
    const gestosFaltantes = primerObjeto.filter(item => {
      return !segundoObjeto.find(obj => obj.gesto._id === item._id);
    });
    return gestosFaltantes;
  }

  /**
   * Título: Captura valor input
   * Descripción: se captura cada valor de evaluación del input de gestos,
   * recibo como parámetro el valor del input, y las posiciones tanto en el
   * row como por cada columna
   * Autor: Bruno
  */
  capturarPuntajeGesto(event: any, rowIndex: number, columnIndex: number) {
    const valor = event.target.value;

    // Capturar el ID del gesto seleccionado
    const gestoId = this.gestos[columnIndex]._id;

    // Capturar el ID del usuario seleccionado
    const userId = this.usuarioSeleccionado[rowIndex].usuario;

    // Generar el objeto y almacenarlo en resultadosMesa
    const objeto: GestoUsuarioCarga = {
      usuario: userId._id,
      gesto: gestoId,
      nota: valor
    };

    // Almacenar el objeto en resultadosMesa
    if (!this.resultadosMesa[rowIndex]) {
      this.resultadosMesa[rowIndex] = [];
    }
    this.resultadosMesa[rowIndex][columnIndex] = objeto;
  }

  async setData() {
    const gestosEvaluados = this.gestos.map(gesto => gesto._id)

    /***  CONCATENO LOS ARRAYS DE CADA USUARIO Y SUS GESTOS ASI DEJO UN SÓLO ARRAY resultadosMesa ***/
    const resultadosMesa = [].concat(...this.resultadosMesa);

    /*** FILTRO LOS QUE VENGAN EN NULL ***/
    const filtrarNulls = resultadosMesa.filter(Boolean);

    /***  AHORA FILTRO LOS INPUT QUE HAN SIDO SELECCIONADO PERO SI NOTA ***/
    const objetosFiltrados = filtrarNulls.filter(objeto => objeto.nota !== null && objeto.nota !== "");


    const data: GestoUsuarioCargaMasiva = {
      mesa: this.getMesaSeleccionada()?._id,
      fechaEvaluacion: this.formulario.value.fechaEvaluacion,
      gestosEvaluados: gestosEvaluados,
      resultadosMesa: objetosFiltrados
    }
    return data;
  }

  async post() {
    Swal.fire({
      title: 'Confirmación',
      text: '¿Confirma la carga masiva de gestos?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.guiMsjService.msjFormSubmit('Guardando');
        this.gestoService.post(await this.setData()).then(async (gesto: GestoUsuarioCargaMasiva) => {
        }).catch(error => {
          console.warn(error);
          Swal.close();
        });
      }
    });
  }

  
  confirmReset() {
    const confirmed = window.confirm('¿Estás seguro de que deseas borrar todos los puntajes?');
    if (confirmed) {
      this.resetForm();
    }
  }
  resetForm() {
    this.formulario.reset();
    // Establece un valor predeterminado para el campo fechaEvaluacion
    this.formulario.get('fechaEvaluacion').patchValue(new Date());
  }
  cancelarCarga(): void {
    this.router.navigateByUrl('/mesas-listado');
  }

}
