import { IAppOfertas } from './../../../../app.config/app.config-ofertas';
import { User } from './../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { PaisDisponible } from './../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { SolicitudesService } from './../../../../services/ofertas-laborales/solicitudes.service';
import { ActividadesService } from './../../../../services/ofertas-laborales/actividades.service';
import { PaisesService } from './../../../../services/shared/paises.service';
import { GuiMsjService } from './../../../../services/shared/gui-msj.service';
import { Actividad } from './../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { Solicitud } from './../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { MediaService } from './../../../../services/shared/media.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { APP_CONFIG_OFERTAS } from '../../../../app.config/app.config-ofertas';
import { AuthService } from '../../../../services/auth/auth.service';
import { Translators } from '../../../../services/shared/translators.service';

@Component({
  selector: 'app-solicitud-postular-documentacion',
  templateUrl: './solicitud-postular-documentacion.component.html',
  styleUrls: ['./solicitud-postular-documentacion.component.scss']
})
export class SolicitudPostularDocumentacionComponent implements OnInit {

    value = 0;
    subscriptionAvance: Subscription;
    cargandoArchivo = false;
    selectedFile: any;

    nombrePais: string;
    nombreActividad: string;
    solicitud: Solicitud;
    actividad: Actividad;
    user: User;
    pais: PaisDisponible;
    flagLoaded = false;
    flagLoadingAllDocuments = false;
    flagEnviando = false;

    documentosAdjuntar: any[];
    selectedItem: number;
    uploadedDocument: number[] = [];

    idioma: Subscription;

    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        private router: Router,
        private mediaService: MediaService,
        private guiMsjService: GuiMsjService,
        private actividadesService: ActividadesService,
        private paisesService: PaisesService,
        private solicitudesService: SolicitudesService,
        private authService: AuthService,
        private translatorService: Translators
    ) {
        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
          });

        this.subscribeAvanceUpload();
     }

    ngOnInit(): void {
        this.getData();
    }

    /* subo archivo con observable*/
    subscribeAvanceUpload(){
        this.subscriptionAvance = this.mediaService.getAvance().subscribe(resp => {
        if (resp) {
            this.value = resp.value;
        } else {
            this.value = 0;
            }
        });
    }

    onFileSelected(event, index, adj) {

        if (this.mediaService.controlSize(event)) {
            this.selectedFile = <File> event.target.files[0];
            this.uploadDocumentoAdjunto(this.selectedFile, index, this.user._id, adj._id);
        } else {
            alert('File too Big, please select a file less than 4mb');
            return;
        }

    }

    uploadDocumentoAdjunto(selectedFile, index, idPersona, adjunto) {
        this.cargandoArchivo = true;
        this.mediaService.uploadAttachJobOfferFromWeb(selectedFile, idPersona, adjunto).then( resp => {
          if (resp) {
            if (this.value === 100) {this.cargandoArchivo = false; }
            this.uploadedDocument.push(index);
            this.controlCargaDocumentos();
            this.guiMsjService.msjFormSubmit('uploadDocumentOK');
          } else {
            this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
          }
        });
    }

    setDocumentosAdjuntar(actividad: Actividad) {
        this.documentosAdjuntar = actividad.adjuntos;
    }

    controlCargaDocumentos() {

        const uploadedDocumentLength = this.uploadedDocument.length;
        const documentosAdjuntarLength = this.documentosAdjuntar.length;

        if (
           uploadedDocumentLength >= documentosAdjuntarLength
        ) {
           this.flagLoadingAllDocuments = true;
        } else {
          this.flagLoadingAllDocuments = false;
        }
    }

    enviarDocumentacion() {
        this.flagEnviando = true;
        this.flagLoadingAllDocuments = false;
        this.solicitudesService.enviarDocumentacion(this.solicitud, this.user ).then( async (solicitud: Solicitud) => {
            const _id = this.solicitud._id;
            const numberState = this.iAppOfertas.estado_2.number;
            this.solicitudesService.updateSolicitudState(_id, numberState, false).then( async (solicitudUpdated: Solicitud) => {
                this.flagEnviando = false;
                await this.solicitudesService.setSolicitudSeleccionada(solicitudUpdated);
                this.guiMsjService.msjFormSubmit('documentosEnviadosOK');
                this.router.navigateByUrl('/solicitud');
            });
        });
    }

    /* variables en servicios */
    async getActividad(){
        this.nombreActividad = this.actividadesService.getActividadSeleccionada().nombre;
        this.setDocumentosAdjuntar( await this.actividadesService.getActividadSeleccionada());
        return this.actividad = await this.actividadesService.getActividadSeleccionada();
    }

    async getPais(){
        this.nombrePais = this.paisesService.getPaisSeleccionado().pais.name_es;
        return this.pais =  await this.paisesService.getPaisSeleccionado();
    }

    async getSolicitud() {
        return this.solicitud = await this.solicitudesService.getSolicitudSeleccionada();
    }

    async getData() {
        await this.getActividad();
        await this.getPais();
        await this.getSolicitud();
        await this.getUser();
        this.flagLoaded = true;
    }

    async getUser() {
      return this.user = await this.authService.getUserLogon();
    }

    cancelar() {
        this.router.navigateByUrl('/solicitud');
    }




}
