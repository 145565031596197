<div class="page-title-area">
    <div class="container">
        <div>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('es')"><img src="assets/img/flags/espana.png" alt="Español" width="20"> ES</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('en')"><img src="assets/img/flags/usa.png" alt="English" width="20"> EN</button>
        </div>
        <div class="page-title-content">
            <h2>
                <a routerLink="/"><img src="assets/img/logo-aadidess-white.png" alt="ski-andorra" width="30%"></a><br></h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div *ngIf="this.lenguaje === 'es'">
    <div class="row">
        <div class="col-lg-3 col-md-3"></div>
        <div class="col-lg-9 col-md-9">
            <div class="purchase-guide-content">
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'><br>&nbsp;</span></p>
                <table style="width:480.3pt;border-collapse:collapse;border:none;">
                    <tbody>
                        <tr>
                            <td style="width: 28.7pt;border: 1pt solid black;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:  3.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:3.0pt;margin-right:0cm;margin-left:0cm;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:#943841;">2.2</span></strong></p>
                            </td>
                            <td colspan="2" style="width: 451.6pt;border-top: 1pt solid black;border-right: 1pt solid black;border-bottom: 1pt solid black;border-image: initial;border-left: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:  3.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:3.0pt;margin-right:0cm;margin-left:0cm;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:#943841;">Formulario 2 &ndash; ESTAMOS PARA AYUDARTE</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="width: 480.3pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(148, 56, 65);padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:  3.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:3.0pt;margin-right:0cm;margin-left:0cm;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:white;">Protecci&oacute;n de Datos &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong>
                                    <span style="font-size:13px;line-height:115%;color:white;">Ver.</span><em><strong><span style='font-size:13px;line-height:115%;font-family:"Arial","sans-serif";color:#E7E6E6;'>&nbsp;21.3</span></strong></em></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 86.4pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Responsable</span></strong></p>
                            </td>
                            <td style="width: 393.9pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">AADIDESS LLC</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 86.4pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Finalidad</span></strong></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                            </td>
                            <td style="width: 393.9pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Recogida y tratamiento de sus datos para atender sus consultas.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 86.4pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Legitimaci&oacute;n</span></strong></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                            </td>
                            <td style="width: 393.9pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Inter&eacute;s leg&iacute;timo del tratamiento por parte del responsable para mantener la relaci&oacute;n/servicios con clientes y/o posibles clientes y postulantes.</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:13px;">Consentimiento inequ&iacute;voco y espec&iacute;fico otorgado por el propio interesado.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 86.4pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Destinatarios</span></strong></p>
                            </td>
                            <td style="width: 393.9pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:13px;">No se ceder&aacute;n datos a terceros, excepto por obligaci&oacute;n legal.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 86.4pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Derechos</span></strong></p>
                            </td>
                            <td style="width: 393.9pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Acceder, rectificar y suprimir los datos, as&iacute; como otros derechos como se explica en la&nbsp;</span><span style="font-size:13px;color:windowtext;">+info adicional&nbsp; </span></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 86.4pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Informaci&oacute;n adicional</span></strong></p>
                            </td>
                            <td style="width: 393.9pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Puede consultar la informaci&oacute;n adicional y detallada sobre protecci&oacute;n de datos en nuestra p&aacute;gina web:&nbsp;</span><span style="font-size:13px;color:blue;">www.aadidess.cipbyte.io</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="border:none;"><br></td>
                            <td style="border:none;"><br></td>
                            <td style="border:none;"><br></td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:13px;">&nbsp;</span></p>
            </div>
        </div>
    </div>
</div>
<div *ngIf="this.lenguaje === 'en'">
    <div class="row">
        <div class="col-lg-3 col-md-3"></div>
        <div class="col-lg-9 col-md-9">
            <div class="purchase-guide-content">
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'><br>&nbsp;</span></p>
                <table style="width:480.3pt;border-collapse:collapse;border:none;">
                    <tbody>
                        <tr>
                            <td style="width: 32.6pt;border: 1pt solid black;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:  3.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:3.0pt;margin-right:0cm;margin-left:0cm;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:#943841;">2.2</span></strong></p>
                            </td>
                            <td colspan="2" style="width: 447.7pt;border-top: 1pt solid black;border-right: 1pt solid black;border-bottom: 1pt solid black;border-image: initial;border-left: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:  3.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:3.0pt;margin-right:0cm;margin-left:0cm;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:#943841;">Form 2 &ndash; WE ARE HERE TO HELP YOU</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="width: 480.3pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(148, 56, 65);padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:  3.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:3.0pt;margin-right:0cm;margin-left:0cm;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:white;">Data Protection &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong>
                                    <span style="font-size:13px;line-height:115%;color:white;">Ver.</span><em><strong><span style='font-size:13px;line-height:115%;font-family:"Arial","sans-serif";color:#E7E6E6;'>&nbsp;21.3</span></strong></em></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 90.3pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Controller</span></strong></p>
                            </td>
                            <td style="width: 390pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">AADIDESS LLC</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 90.3pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Purpose</span></strong></p>
                            </td>
                            <td style="width: 390pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Collection and treatment of your data to answer your queries.</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 90.3pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Legitimation</span></strong><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                            </td>
                            <td style="width: 390pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Legitimate interest of the treatment by the person in charge to maintain the relationship/services with clients and/or potential clients and applicants.</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Unequivocal and specific consent granted by the interested party.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 90.3pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Recipients</span></strong></p>
                            </td>
                            <td style="width: 390pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:13px;">Data will not be transferred to third parties, except by legal obligation.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 90.3pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Rights</span></strong></p>
                            </td>
                            <td style="width: 390pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Access, rectify and delete the data, as well as other rights as explained in the&nbsp;</span><span style="font-size:13px;color:windowtext;">+info additional&nbsp;GDPR.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 90.3pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Additional Information</span></strong></p>
                            </td>
                            <td style="width: 390pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 4.5pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">You can consult additional and detailed information on data protection on our website:&nbsp;</span><span style="font-size:13px;color:blue;">www.aadidess.cipbyte.io</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="border:none;"><br></td>
                            <td style="border:none;"><br></td>
                            <td style="border:none;"><br></td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:13px;">&nbsp;</span></p>
            </div>
        </div>
    </div>
</div>