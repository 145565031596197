import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lecciones-configuracion',
  templateUrl: './lecciones-configuracion.component.html',
  styleUrls: ['./lecciones-configuracion.component.scss']
})
export class LeccionesConfiguracionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
