import { Response } from '../../interfaces/interface-bakend/response';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '../../interfaces/interface-bakend/seguridad/users/user.interface';
import { StorageService } from './storage.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { GuiMsjService } from './gui-msj.service';
import { AuthService } from '../auth/auth.service';
import { UserList } from '../../interfaces/interface-bakend/seguridad/users/personas.interface';
import { Perfil } from '../../interfaces/interface-bakend/seguridad/acl/perfil.interface';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  usuarioSeleccion: User;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private guiMsjService: GuiMsjService,
    private authService: AuthService,
  ) { }

  // tslint:disable-next-line: variable-name
  updateUser(_id: string, usuario: User): Promise<User> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.put<Response>(`${URL_BACKEND}/users/updateOne/${_id}`, usuario, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      }, error => {
        console.log('error', error);
        this.guiMsjService.msjFormSubmit('emailDuplicado');
      });
    });
  }

  createUser(usuario: User): Promise<User> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post<Response>(`${URL_BACKEND}/users/`, usuario, { headers }).subscribe((resp: Response) => {

        resolve(resp.response);
      }, error => {
        console.log('error', error);
        this.guiMsjService.msjFormSubmit('emailDuplicado');
      });
    });
  }

  async crearUsuarioEnFacturacion(usuario: User): Promise<User> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      
      this.httpClient.post<Response>(`${URL_BACKEND}/aadidess-adm/createPersonaMin`, usuario, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      }, error => {
        console.log('error', error);
        this.guiMsjService.msjFormSubmit('error al intentar crear usuario en facturación...');
      });
    });
  }

  getAllUsers(): Promise<User[]> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findAll`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      });
    });
  }

  getAllUsersGrilla(): Promise<User[]> {
    return new Promise(async (resolve, reject) => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${URL_BACKEND}/users/findAllGrillaUsuarios`, { headers }).subscribe(
        (data: Response) => {
          resolve(data.response); // Resolvemos la promesa con los datos exitosos
        },
        (error: HttpErrorResponse) => {
          if (error.status === 400) {
            reject('Error 400: Bad Request'); // Rechazamos la promesa con el mensaje de error específico para el error 400
          } else {
            reject('Algo salió mal. Por favor, inténtalo de nuevo más tarde.'); // Rechazamos la promesa con un mensaje de error genérico
          }
        }
      );
    });
  }

  getCountUsers(): Promise<number> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findAll`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response.length);
      });
    });
  }

  getFindOne(id: string): Promise<User> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findOne/${id}`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
        this.setUsuarioSeleccionado(resp.response);
      });
    });
  }

  setUsuarioSeleccionado(usuario: User) {
    this.usuarioSeleccion = usuario;
    this.storageService.setUsuario(usuario);
  }

  getUsuarioSeleccionado(): User {
    return this.usuarioSeleccion;
  }

  put(id: string, nombre: string, apellido: string, suspendido: boolean) {

    const data = {
      nombrePersona: nombre,
      apellidoPersona: apellido,
      suspendido,
    };

    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put(`${URL_BACKEND}/users/updateOne/${id}`, data, { headers }).subscribe(resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Usuario modificado satisfactoriamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/usuarios-listado']);
        resolve(true)
          ,

          // tslint:disable-next-line:no-unused-expression
          (error) => {
            Swal.close();
            Swal.fire('Error al modificar', '', 'error');
            resolve(false);
          };
      });
    });

  }

  updateOne(data: User) {

    console.log('envio', data);
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put<Response>(`${URL_BACKEND}/users/updateOne/${data._id}`, data, { headers }).subscribe(resp => {
        if (resp) {
          resolve(true);
        } else {
          resolve(false);
        }
        // tslint:disable-next-line:no-unused-expression
        (error) => {
          console.log('Error al modificar usuario');
          resolve(false);
        };
      });
    });

  }

  getFindOneByEmail(email: string): Promise<User> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findOneByEmail/${email}`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      });
    });
  }

  getExistsTipoAndIdentificacion(tipoIdent: string, ident: string): Promise<boolean> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/existsTipoAndIdentificacion/${tipoIdent}/${ident}`, { headers }).
        subscribe((resp: Response) => {
          resolve(resp.response);
        });
    });
  }

  newPassword(id: string, password: string) {

    const data = {
      password
    };

    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put(`${URL_BACKEND}/users/updateOne/${id}`, data, { headers }).subscribe(resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Contraseña modificada satisfactoriamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/usuarios-listado']);
        resolve(true)
          ,

          // tslint:disable-next-line:no-unused-expression
          (error) => {
            Swal.close();
            Swal.fire('Error al modificar', '', 'error');
            resolve(false);
          };

      });
    });
  }

  updatePresentacion(id: string, presentacion: string) {

    const data = {
      presentacion
    };

    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put(`${URL_BACKEND}/users/updateOne/${id}`, data, { headers }).subscribe(resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Datos modificados satisfactoriamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/cuenta']);
        resolve(true)
          ,

          // tslint:disable-next-line:no-unused-expression
          (error) => {
            Swal.close();
            Swal.fire('Error al modificar', '', 'error');
            resolve(false);
          };
      });
    });

  }

  updatePasswordUser(id: string, password: string) {

    const data = {
      id,
      password
    };

    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post(`${URL_BACKEND}/users/cambiarPassword/`, data, { headers }).subscribe(resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Contraseña modificada satisfactoriamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/usuarios-listado']);
        resolve(true)
          ,

          // tslint:disable-next-line:no-unused-expression
          (error) => {
            Swal.close();
            Swal.fire('Error al modificar', '', 'error');
            resolve(false);
          };

      });
    });
  }

  async getPerfiles(): Promise<Perfil[]> {

    const headers = new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
    });

    return new Promise(resolve => {
      this.httpClient.get<Response>(`${URL_BACKEND}/perfiles/findAllActivos/`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      });
    });

  }

  async getNombreAdjunto(adjunto: string): Promise<string> {
    const posInicial = 0;
    const longitud = adjunto.indexOf('.');
    const idAdjunto = adjunto.substring(posInicial, longitud);

    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/documento_tipos/getDocumentName/${idAdjunto}`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });
    });
  }

}
