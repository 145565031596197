<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">{{ idioma | translate: "navbar.INICIO" | async}}</a></li>
                <li><a *ngIf="this.loaded" routerLink="/curso-descripcion-compra">{{this.curso.nombre}}</a></li>

                <li>{{ idioma | translate: "curso.COMPRAR-CURSO" | async}}</li>
            </ul>
            <h2>{{ idioma | translate: "curso.COMPRAR-CURSO" | async}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!this.loaded" class="m-5"></app-loading>

<div class="checkout-area ptb-100 animate__animated animate__fadeIn" *ngIf="this.loaded">
    <div class="container">
        <form>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <div class="row">
                            <app-card (cardData)="(cardDataUser = $event)" (cardDataFlag)="(cardDataFlag = $event)"></app-card>
                        </div>
                    </div>
                </div>
                <div *ngIf="this.loaded" class="col-lg-6 col-md-12">
                    <div class="courses-details-info">
                        <app-cursos-detalle-items [curso]=curso (EcostoPagar)="(costoPagar = $event)"></app-cursos-detalle-items>
                        <div class="courses-details-info">
                            <div class="payment-box">
                                <!-- Boton Paypal -->
                                <app-pay-pal-button [valor]="curso.precio" [description]="curso.nombre" [currency_code]="this.currency_code"></app-pay-pal-button>
                                <br>
                                <br>

                                <div *ngIf="this.flagProcesandoPago">
                                    <mat-divider></mat-divider>
                                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                    <!-- <span class="d-block p-2 bg-primary text-white">{{ idioma | translate: "form.MSJ-PROCESANDO" | async}}</span> -->
                                    <div class="alert alert-success m-3" width="100%" role="alert">
                                        {{ idioma | translate: "form.MSJ-PROCESANDO" | async}}
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>

                                <!-- Boton transferencia  -->
                                <!-- <app-transferencia-button [componenteCall]="'PAGO_CURSO'"></app-transferencia-button> -->
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    </div>
</div>