<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li>Configuración Cursos</li>
            </ul>
            <h2>Configuración Cursos</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="features-area pb-70 animate__animated animate__fadeIn">
    <div class="container">
        <div class="row m-4">
            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-book-open"></i>
                    </div>
                    <h3><b>Materias (1)</b></h3>
                    <p>Acceda a la configuración de Materias</p>
                    <a routerLink="/materias-configuracion" class="btn btn-warning">
                        Ir a Materias
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-book-reader"></i>
                    </div>
                    <h3><b>Lecciones (2)</b></h3>
                    <p>Acceda a la configuración de Lecciones</p>
                    <a routerLink="/lecciones-configuracion" class="btn btn-warning">
                        Ir a Lecciones
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-plus-square"></i>
                    </div>
                    <h3>Nuevo Curso (3)</h3>
                    <p>Cree un nuevo Curso en el sistema</p>
                    <a routerLink="/curso-alta" class="btn btn-warning">Crear Curso</a>
                </div>
            </div>
        </div>

        <div class="row m-4">
            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-book-reader"></i>
                    </div>
                    <h3><b>Configurar Cátedras</b></h3>
                    <p>Acceda a la configuración de Cátedras</p>
                    <a routerLink="/catedra-alta" class="btn btn-warning">Ir a Configuración</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-user-circle"></i>
                    </div>
                    <h3>Configurar Examenes</h3>
                    <p>Gestión de Examenes</p>
                    <a routerLink="/examenes-listado" class="btn btn-warning">Ir a Examenes</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-list-ul"></i>
                    </div>
                    <h3>Listado de Cursos</h3>
                    <p>Acceda al listado de cursos del sistema</p>
                    <a routerLink="/cursos-listado" class="btn btn-warning">Ver Cursos</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-list-ul"></i>
                    </div>
                    <h3>Configurar Profesores</h3>
                    <p>Acceda a la configuración de Profesores</p>
                    <a routerLink="/profesores-configuracion" class="btn btn-warning">Ir a Profesores</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-user-circle"></i>
                    </div>
                    <h3>Examenes Evaluaciones Resultados</h3>
                    <p>Resultados evaluaciones de examenes</p>
                    <a routerLink="/examenes-resultados" class="btn btn-warning">Ir a Resultado de Evaluaciones</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-list-ul"></i>
                    </div>
                    <h3>Listado de Titulos</h3>
                    <p>Acceda al listado de titulos del sistema</p>
                    <a routerLink="/titulos-listado" class="btn btn-warning">Ver Titulos</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-user-circle"></i>
                    </div>
                    <h3>Listado Mesas de Examenes</h3>
                    <p>Gestión de Mesas y carga de puntajes de gestos</p>
                    <a routerLink="/mesas-listado" class="btn btn-warning">Ir a Mesas</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-user-circle"></i>
                    </div>
                    <h3>Listado de Gestos</h3>
                    <p>Gestión de Gestos</p>
                    <a routerLink="/gestos-listado" class="btn btn-warning">Ir a Gestos</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-user-circle"></i>
                    </div>
                    <h3>Listado de examenes gestos</h3>
                    <p>Gestión de Gestos Evaluados</p>
                    <a routerLink="/gestos-evaluaciones-listado" class="btn btn-warning">Ir a Gestos Evaluados</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-book-open"></i>
                    </div>
                    <h3>Listado de Boletines</h3>
                    <p>Gestión de Boletines</p>
                    <a routerLink="/boletines-listado" class="btn btn-warning">Ir a Boletines</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-book-open"></i>
                    </div>
                    <h3>Listado de Materias Boletines</h3>
                    <p>Gestión de Materias Boletines</p>
                    <a routerLink="/materias-boletin-listado" class="btn btn-warning">Ir a Materias Boletines</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-book-open"></i>
                    </div>
                    <h3>Listado de Boletines Cargados</h3>
                    <p>Gestión de Boletines</p>
                    <a routerLink="/alumnos-boletines-listado" class="btn btn-warning">Ir a Boletines Cargados</a>
                </div>
            </div>
        </div>
    </div>
</div>