<!-- ATENCION !!! NO ESTA EN USO ESTE COMPONENTE -->
<h2 mat-dialog-title>{{ idioma | translate: "terminos-sitio.TITULO" | async}}</h2>

<mat-dialog-content class="mat-typography">

    <p>{{ idioma | translate: "terminos-sitio.DESCRIPCION" | async}}</p>

    <strong>{{ idioma | translate: "terminos-sitio.TERMINO-TITULO-1" | async}}</strong>
    <p>{{ idioma | translate: "terminos-sitio.TERMINO-1" | async}}</p>

    <strong>{{ idioma | translate: "terminos-sitio.TERMINO-TITULO-2" | async}}</strong>
    <p>{{ idioma | translate: "terminos-sitio.TERMINO-2" | async}}</p>

    <strong>{{ idioma | translate: "terminos-sitio.TERMINO-TITULO-3" | async}}</strong>
    <p>{{ idioma | translate: "terminos-sitio.TERMINO-3" | async}}</p>

    <strong>{{ idioma | translate: "terminos-sitio.TERMINO-TITULO-4" | async}}</strong>
    <p>{{ idioma | translate: "terminos-sitio.TERMINO-4" | async}}</p>

    <strong>{{ idioma | translate: "terminos-sitio.TERMINO-TITULO-5" | async}}</strong>
    <p>{{ idioma | translate: "terminos-sitio.TERMINO-5" | async}}</p>

    <strong>{{ idioma | translate: "terminos-sitio.TERMINO-TITULO-6" | async}}</strong>
    <p>{{ idioma | translate: "terminos-sitio.TERMINO-6" | async}}</p>

    <strong>{{ idioma | translate: "terminos-sitio.TERMINO-TITULO-7" | async}}</strong>
    <p>{{ idioma | translate: "terminos-sitio.TERMINO-7" | async}}</p>
    <br>
    <p style="text-align: center !important;"><strong>{{ idioma | translate: "login.TITULO-BIENVENIDA" | async}}</strong></p>
    <p style="text-align: center !important;">{{ idioma | translate: "home.BIENVENIDA-PLATAFORMA-OFERTAS" | async}}</p>
    <p style="text-align: center !important;">{{ idioma | translate: "home.ENCONTRARAS-OFERTAS" | async}}</p>
    <p style="text-align: center !important;">{{ idioma | translate: "home.INVITACION-HERRAMIENTA" | async}}</p>


</mat-dialog-content>
<br><br>

<form [formGroup]="form" (ngSubmit)="updateUser()">
    <input type="checkbox" formControlName="aceptar"> {{ idioma | translate: "login.TERMINOS-CONDICIONES" | async}}

    <mat-dialog-actions align="center">
        <button mat-raised-button mat-button color="default" style="margin-right:5px;" (click)="logout()">{{ idioma | translate: "form.BTN-CANCELAR" | async }}</button>
        <button mat-raised-button mat-button color="primary" [disabled]="form.invalid" disabled type="submit" mat-button cdkFocusInitial>{{ idioma | translate: "form.CONTINUAR-BTN" | async}}</button>
    </mat-dialog-actions>

</form>