import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Actividad } from './../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Translators } from '../../../../services/shared/translators.service';

@Component({
  selector: 'app-ofertas-detalle-items',
  templateUrl: './ofertas-detalle-items.component.html',
  styleUrls: ['./ofertas-detalle-items.component.scss']
})
export class OfertasDetalleItemsComponent implements OnInit {

    @Input() pais: PaisDisponible;
    @Input() actividad: Actividad;
    @Output() EcostoPagar = new EventEmitter<number>();
    @Output() Edescription = new EventEmitter<string>();

    nombrePais: string;
    costoTotal: number;
    costoPagar: number;

    idioma: Subscription;
    
    constructor(
        private translatorService: Translators
    ) {
        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
          });
     }

    ngOnInit(): void {
       this.loadDataInput();
    }

    async loadDataInput(){
        await this.getCostos(this.actividad);
        await this.emitCotoPagar(this.actividad);
        await this.emitDescription(this.actividad);
        this.getNombrePais();
    }

    getCostos(actividad: Actividad) {
        this.costoTotal = actividad.costoAdministrativo + actividad.costoPostAprobacion;
    }

    emitCotoPagar(actividad: Actividad): void {
        return this.EcostoPagar.emit(actividad.costoAdministrativo);
    }

    emitDescription(actividad: Actividad): void {
        return this.Edescription.emit('ACTIVIDAD: ' + actividad.nombre + '_id: ' + actividad._id);
    }

    getNombrePais(){
        this.nombrePais = this.pais.pais.name_es;
    }

}
