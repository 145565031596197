import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MensajesBackendService {

  constructor(
    private router: Router,
  ) { }
  
  getErrorMessage(errorMessage: string): string {
    let customErrorMessage = '';
  
    switch (true) {
      case errorMessage.includes('E11000 duplicate key'):
        customErrorMessage = 'El elemento que quiere cargar ya existe.';
        break;
      // Agrega más casos según los diferentes tipos de errores que quieras manejar
      default:
        customErrorMessage = 'Ocurrió un error al procesar la solicitud.';
        break;
    }
  
    return customErrorMessage;
  }
  

  concatenarMensaje(mensajeBackend: string): string {
    const asteriskIndex = mensajeBackend.indexOf('*');

    const mensajesValidos = [
      'EMAIL_EXISTENTE',
      'CONFIRM_REGISTER_ERROR',
      'CONFIRM_REGISTER_OK',
      'CUENTA_SUSPENDIDA',
      'CUENTA_YA_VERIFICADA',
      'EMAIL_INEXISTENTE',
      'EMAIL_NO_VERIFICADO',
      'LOGIN_SUCCESSFULLY',
      'LOGOUT_SUCCESSFULLY',
      'PASSWORD_INCORRECTO',
      'PASSWORD_RECUPERADO_OK',
      'PASSWORD_UPDATED',
      'SEND_PIN_OK',
      'duplicate',
    ];

    if (asteriskIndex !== -1) {
      const mensajeSinPrefijo = mensajeBackend.substring(asteriskIndex + 1); // Obtiene parte después del asterisco
      if (mensajesValidos.includes(mensajeSinPrefijo)) {
        const formattedMessage = mensajeSinPrefijo
          .toLowerCase() // Convertir todo a minúsculas
          .replace(/_/g, ' ') // Reemplazar guiones bajos con espacios
          .replace(/\b\w/g, (char) => char.toUpperCase()); // Convertir primera letra a mayúscula
        return `${formattedMessage}`;
      } else {
        return 'Mensaje no válido';
      }
    } else {
      return 'Formato de mensaje incorrecto';
    }
  }

  showSuccessMessage(): void {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Inicio Exitoso!',
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      this.router.navigate(['/']);
    });
  }

  showErrorMessage(message: string): void {
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: message || 'Error General ' + message,
      showConfirmButton: true,
      // timer: 1500
    });
  }

  showRegistrationSuccess(): void {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Registro Exitoso!, se ha enviado un pin de confirmacion a la cuenta de email creada',
      showConfirmButton: true,
    });
  }

  showRegistrationPinError(message: string): void {
    Swal.fire({
      position: 'top-end',
      icon: 'warning',
      title: 'No se pudo activar su PIN!, su PIN se encuentra activo. Puede reiniciar su clave si precisa acceder.',
      showConfirmButton: true,
    });
  }
  
  showErrorMessageRegistration(): void {
    Swal.fire({
      position: 'top-end',
      icon: 'warning',
      title: 'Registro Error!, se produjo un error al querer registrar',
      showConfirmButton: true,
      timer: 1500
    });
  }
}
