<form class="row g-3">

    <mat-card class="example-card">
        <mat-card-header>
            <button class="close" mat-button [mat-dialog-close]="true">X</button>
            <mat-card-title>Solicitar Pago - CONFIRMAR</mat-card-title>
            <mat-card-subtitle>Solicitud: {{this.solicitud.actividad.nombre }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

            <div class="p-1 mb-2 bg-secondary text-white rounded-top">Al momento de aceptar esta operación, se enviara la solicitu de pago por mail al cliente</div>

            <div class="row">
                <div class="col-lg-4 col-md-44">
                    <div class="products-details-desc">
                        <div class="products-meta">
                            <span><a>Actividad: </a> <span> {{ this.solicitud.actividad.nombre }}</span></span>
                            <span><a>Nro Solicitud: </a> {{ this.solicitud._nro }}</span>
                            <span><a>Fecha Solicitud: </a> {{ this.solicitud.createdAt | date:"dd/MM/yy" }}</span>
                            <span><a>Pais: </a> {{ this.solicitud.pais.name_es }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-44">
                    <div class="products-details-desc">
                        <div class="products-meta">
                            <span><a>Nombre: </a> {{ this.solicitud.usuario.nombrePersona }}</span>
                            <span><a>Apellido: </a> {{ this.solicitud.usuario.apellidoPersona }}</span>
                            <span><a>Tipo Identificación: </a> {{ this.solicitud.usuario.tipoIdentificacion.concepto}}</span>
                            <span><a>Número identificación: </a> {{  this.solicitud.usuario.identificacion }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="p-1 mb-2 bg-secondary text-white rounded-top">Detalle como en {{this.data.amount.currency_code}}</div>
            <div class="products-details-desc">
                <div class="products-meta">
                    <div class="courses-details-info">
                        <ul class="info">
                            <div *ngFor="let item of this.solicitud.cursos">
                                <li class="price">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i></i>{{item.nombre}}  </span> ${{item.precio}}
                                    </div>
                                </li>
                            </div>                            
                            <li class="price">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><i></i>Descuento  </span> ${{this.data.data.descuento}}
                                </div>
                            </li>
                            <li class="price">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><i></i>Recargo  </span> ${{this.data.data.recargo}}
                                </div>
                            </li>
                            <li class="price">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><i></i>Observación  </span> {{this.data.data.observacion || 'sin Observación '}}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        <!-- Columna 2 -->
        <div class="col-md-6">
            <div class="p-3 mb-2 bg-primary text-white rounded-top">Total envío cobro</div>
            <div class="input-group">
                <span class="input-group-text">$</span>
                <input type="number" disabled class="form-control" [value]="this.data.amount.value">            
            </div>
        </div>
        
        <!-- Botones -->
        <div class="col-md-12">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button (click)="aceptar()" class="btn btn-primary me-md-2" type="button">Confirmar</button>
                <button (click)="cancelar()" class="btn btn-secondary" type="button">Cancelar</button>
            </div>
        </div>

    </mat-card-content>
    </mat-card>

</form>
