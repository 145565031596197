import { NgModule } from '@angular/core';
import { ActividadDetalleComponent } from '../components/ofertas-laborales/pages/actividad-detalle/actividad-detalle.component';
import { ActividadPostularComponent } from '../components/ofertas-laborales/pages/actividad-postular/actividad-postular.component';
import { ActividadesDisponiblesPaisComponent } from '../components/ofertas-laborales/pages/actividades-disponibles-pais/actividades-disponibles-pais.component';
import { OfertaBuscadorPaisesComponent } from '../components/ofertas-laborales/pages/oferta-buscador-paises/oferta-buscador-paises.component';
import { OfertaBuscadorPaisesBannerComponent } from '../components/ofertas-laborales/pages/oferta-buscador-paises/oferta-buscador-paises-banner/oferta-buscador-paises-banner.component';
import { OfertasAltaComponent } from '../components/ofertas-laborales/pages/ofertas-alta/ofertas-alta.component';
import { OfertasConfiguracionComponent } from '../components/ofertas-laborales/pages/ofertas-configuracion/ofertas-configuracion.component';
import { OfertaModificacionComponent } from '../components/ofertas-laborales/pages/oferta-modificacion/oferta-modificacion.component';
import { OfertasListarComponent } from '../components/ofertas-laborales/pages/ofertas-listar/ofertas-listar-listado/ofertas-listar.component';
import { OfertasDisponiblesComponent } from '../components/ofertas-laborales/pages/ofertas-disponibles/ofertas-disponibles.component';
import { SolicitudPostularComponent } from '../components/ofertas-laborales/pages/solicitud-postular/solicitud-postular.component';
import { SolicitudPostularDocumentacionComponent } from '../components/ofertas-laborales/pages/solicitud-postular-documentacion/solicitud-postular-documentacion.component';
import { UsuariosSolicitudesComponent, } from '../components/ofertas-laborales/pages/usuario-solicitudes/usuario-solicitudes.component';
import { CardComponent } from '../components/ofertas-laborales/pages/actividad-postular/card/card.component';
import { UploadFileSolicitudComponent } from '../components/ofertas-laborales/common/upload-file-solicitud/upload-file-solicitud.component';
import { CatalogoConfiguracionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/catalogo-configuracion.component';
import { AreasConfiguracionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/areas-configuracion/areas-configuracion.component';
import { AreasListadoComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/areas-configuracion/areas-listado/areas-listado.component';
import { AreaAltaComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/areas-configuracion/area-alta/area-alta.component';
import { ModalidadesConfiguracionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/modalidades-configuracion/modalidades-configuracion.component';
import { TipoPuestosConfiguracionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/tipo-puestos-configuracion/tipo-puestos-configuracion.component';
import { AdjuntosConfiguracionComponent } from '../components/ofertas-laborales/pages/adjuntos-configuracion/adjuntos-configuracion.component';
import { AdjuntosListadoComponent } from '../components/ofertas-laborales/pages/adjuntos-configuracion/adjuntos-listado/adjuntos-listado.component';
import { AdjuntoAltaComponent } from '../components/ofertas-laborales/pages/adjuntos-configuracion/adjunto-alta/adjunto-alta.component';
import { ModalidadesListadoComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/modalidades-configuracion/modalidades-listado/modalidades-listado.component';
import { TipoPuestosListadoComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/tipo-puestos-configuracion/tipo-puestos-listado/tipo-puestos-listado.component';
import { ModalidadAltaComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/modalidades-configuracion/modalidad-alta/modalidad-alta.component';
import { TipoPuestoAltaComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/tipo-puestos-configuracion/tipo-puesto-alta/tipo-puesto-alta.component';
import { AdjuntoModificacionComponent } from '../components/ofertas-laborales/pages/adjuntos-configuracion/adjunto-modificacion/adjunto-modificacion.component';
import { AreaModificacionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/areas-configuracion/area-modificacion/area-modificacion.component';
import { ModalidadModificacionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/modalidades-configuracion/modalidad-modificacion/modalidad-modificacion.component';
import { TipoPuestoModificacionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/tipo-puestos-configuracion/tipo-puesto-modificacion/tipo-puesto-modificacion.component';
import { DocumentosUsuariosConfiguracionComponent } from '../components/usuarios/pages/documentos-usuarios-configuracion/documentos-usuarios-configuracion.component';
import { DocumentoUsuarioAltaComponent } from '../components/usuarios/pages/documento-usuario-alta/documento-usuario-alta.component';
import { DocumentoUsuarioModificacionComponent } from '../components/usuarios/pages/documento-usuario-modificacion/documento-usuario-modificacion.component';
import { DocumentosUsuariosListadoComponent } from '../components/usuarios/pages/documentos-usuarios-listado/documentos-usuarios-listado.component';
import { DocumentosUsuariosFilesComponent } from '../components/usuarios/pages/documentos-usuarios-files/documentos-usuarios-files.component';
import { DocumentosUsuariosGalleryComponent } from '../components/usuarios/pages/documentos-usuarios-gallery/documentos-usuarios-gallery.component';

// Imports
import 'hammerjs';
import 'mousetrap';
import { GalleryComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-gallery/ofertas-gallery.component';
import { ChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from './material.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { AccordionModule } from 'ngx-accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { PipesModule } from './pipes.module';
import { OfertasAvatarComponent } from '../components/ofertas-laborales/common/ofertas-avatar/ofertas-avatar.component';
import { BtnHomeComponent } from '../components/common/btn-home/btn-home.component';
import { BtnOfertasListarComponent } from '../components/common/btn-ofertas-listar/btn-ofertas-listar.component';
import { OfertasDetalleItemsComponent } from '../components/ofertas-laborales/common/ofertas-detalle-items/ofertas-detalle-items.component';
import { SolicitudComponent } from '../components/ofertas-laborales/pages/solicitud/solicitud.component';
import { DetalleComponent } from '../components/ofertas-laborales/pages/actividad-detalle/detalle/detalle/detalle.component';
import { CommonComponentsModule } from './common.components.module';
import { OfertasAdministracionComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-administracion.component';
import { OfertasListadoComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-listado/ofertas-listado.component';
import { OfertasSolicitudComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-solicitud/ofertas-solicitud.component';
import { OfertasEstadoDetalleComponent } from '../components/ofertas-laborales/common/ofertas-estado-detalle/ofertas-estado-detalle.component';
import { OfertasFilesComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-files/ofertas-files.component';
import { OfertasFormularioComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-formulario/ofertas-formulario.component';
import { OfertasEstadisticasComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-estadisticas/ofertas-estadisticas.component';
import { DoughnutChartComponent } from '../components/chart-types/doughnut-chart/doughnut-chart.component';
import { PagarSaldoComponent } from '../components/ofertas-laborales/pages/pagar-saldo/pagar-saldo.component';
import { OfertasTableComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-estadisticas/ofertas-table/ofertas-table.component';
import { OfertasVerPdfComponent } from '../components/ofertas-laborales/common/ofertas-ver-pdf/ofertas-ver-pdf.component';
import { SpinnerColorsComponent } from '../components/common/spinner-colors/spinner-colors.component';
import { OfertasLaboralesListadoComponent } from '../components/ofertas-laborales/pages/ofertas-laborales-listado/ofertas-laborales-listado.component';
// tslint:disable-next-line:max-line-length
import { ModalConfirmarComponent } from '../components/ofertas-laborales/pages/solicitud-postular/modal-confirmar/modal-confirmar.component';
import { ModalSolicitarPagoComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-solicitud/modal-solicitar-pago/modal-solicitar-pago.component';
import { PayPalButtonComponent } from '../components/common/pay-pal-button/pay-pal-button.component';
import { ModalConfirmarSolicitarPagoComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-solicitud/modal-solicitar-pago/modal-confirmar-solicitar-pago/modal-confirmar-solicitar-pago.component';
import { DocumentosEnviadosComponent } from '../components/ofertas-laborales/pages/solicitud/documentos-enviados/documentos-enviados.component';
import { AgregarDocumentosExtraComponent } from '../components/ofertas-laborales/pages/solicitud/agregar-documentos-extra/agregar-documentos-extra.component';
import { StepperComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/stepper/stepper.component';
import { InvoicesListadoComponent } from '../components/ofertas-laborales/pages/invoices-listado/invoices-listado.component';
import { InvoiceDetalleComponent } from '../components/ofertas-laborales/pages/invoices-listado/invoice-detalle/invoice-detalle.component';
import { TransferenciaButtonComponent } from '../components/common/transferencia-button/transferencia-button.component';
import { TransferenciaModalComponent } from '../components/common/transferencia-modal/transferencia-modal.component';
import { UploadComprobanteSolicitudComponent } from '../components/ofertas-laborales/common/upload-comprobante-solicitud/upload-comprobante-solicitud.component';
import { ComprobantesTransfListadoComponent } from '../components/common/comprobantes-transf-listado/comprobantes-transf-listado.component';
import { ComprobanteTransfDetalleComponent } from '../components/common/comprobantes-transf-listado/comprobante-transf-detalle/comprobante-transf-detalle.component';
import { SolicitudPostularDocumentacionIncripcionComponent } from '../components/ofertas-laborales/pages/solicitud-postular-documentacion-incripcion/solicitud-postular-documentacion-incripcion.component';
import { ModificarModulosSolicitudComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/modificar-modulos-solicitud/modificar-modulos-solicitud.component';
import { OfertasListarTarjetasComponent } from '../components/ofertas-laborales/pages/ofertas-listar/ofertas-listar-tarjetas/ofertas-listar-tarjetas.component';
import { TarjetaComponent } from '../components/ofertas-laborales/pages/ofertas-listar/ofertas-listar-tarjetas/curso-tarjeta/tarjeta.component';
import { FormularioInscripcionComponent } from '../components/ofertas-laborales/pages/actividad-postular/formulario-inscripcion/formulario-inscripcion.component';
import { BotonTarjetaComponent } from '../components/pages/home/boton-tarjeta/curso-tarjeta/boton-tarjeta.component';
import { OfertasListadoCursosComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-listado-cursos/ofertas-listado-cursos.component';
import { ModificarActividadesComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/modificar-actividades/modificar-actividades.component';
import { DocumentosUsuariosFilesUploadComponent } from '../components/usuarios/pages/documentos-usuarios-files-upload/documentos-usuarios-files-upload.component';

@NgModule({
  declarations: [
    ActividadDetalleComponent,
    ActividadPostularComponent,
    FormularioInscripcionComponent,
    ActividadesDisponiblesPaisComponent,
    OfertaBuscadorPaisesComponent,
    OfertaBuscadorPaisesBannerComponent,
    OfertasAltaComponent,
    OfertasConfiguracionComponent,
    OfertaModificacionComponent,
    OfertasLaboralesListadoComponent,
    OfertasListarComponent,
    OfertasListarTarjetasComponent,
    TarjetaComponent,
    OfertasDisponiblesComponent,
    OfertasAvatarComponent,
    SolicitudPostularComponent,
    SolicitudPostularDocumentacionComponent,
    UsuariosSolicitudesComponent,
    CardComponent,
    BtnHomeComponent,
    BtnOfertasListarComponent,
    OfertasDetalleItemsComponent,
    SolicitudComponent,
    DetalleComponent,
    UploadFileSolicitudComponent,
    UploadComprobanteSolicitudComponent,
    OfertasAdministracionComponent,
    OfertasListadoComponent,
    OfertasListadoCursosComponent,
    OfertasSolicitudComponent,
    GalleryComponent,
    OfertasEstadoDetalleComponent,
    OfertasFilesComponent,
    OfertasFormularioComponent,
    OfertasEstadisticasComponent,
    DoughnutChartComponent,
    PagarSaldoComponent,
    OfertasTableComponent,
    OfertasVerPdfComponent,
    SpinnerColorsComponent,
    ModalConfirmarComponent,
    ModalSolicitarPagoComponent,
    PayPalButtonComponent,
    TransferenciaButtonComponent,
    TransferenciaModalComponent,
    CatalogoConfiguracionComponent,
    AreasConfiguracionComponent,
    ModalidadesConfiguracionComponent,
    TipoPuestosConfiguracionComponent,
    AreasListadoComponent,
    AreaAltaComponent,
    AdjuntosConfiguracionComponent,
    AdjuntosListadoComponent,
    AdjuntoAltaComponent,
    AdjuntoModificacionComponent,
    DocumentosUsuariosConfiguracionComponent,
    DocumentosUsuariosListadoComponent,
    DocumentoUsuarioAltaComponent,
    DocumentoUsuarioModificacionComponent,
    DocumentosUsuariosFilesComponent,
    DocumentosUsuariosFilesUploadComponent,
    DocumentosUsuariosGalleryComponent,
    ModalidadesListadoComponent,
    TipoPuestosListadoComponent,
    ModalidadAltaComponent,
    TipoPuestoAltaComponent,
    AreaModificacionComponent,
    ModalidadModificacionComponent,
    TipoPuestoModificacionComponent,
    ModalConfirmarSolicitarPagoComponent,
    DocumentosEnviadosComponent,
    AgregarDocumentosExtraComponent,
    StepperComponent,
    InvoicesListadoComponent,
    InvoiceDetalleComponent,
    ComprobantesTransfListadoComponent,
    ComprobanteTransfDetalleComponent,
    SolicitudPostularDocumentacionIncripcionComponent,
    ModificarModulosSolicitudComponent,
    ModificarActividadesComponent,
    BotonTarjetaComponent,
  ],

  imports: [
    PipesModule,
    ChartsModule,
    CommonModule,
    AppRoutingModule,
    MaterialModule,
    CarouselModule,
    BrowserAnimationsModule,
    CountUpModule,
    StickyNavModule,
    TabsModule,
    NgxScrollTopModule,
    LightboxModule,
    AccordionModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    // GalleryModule,
    GalleryModule.forRoot(),
    CommonComponentsModule,

  ],

  exports: [
    ActividadDetalleComponent,
    ActividadPostularComponent,
    FormularioInscripcionComponent,
    ActividadesDisponiblesPaisComponent,
    OfertaBuscadorPaisesComponent,
    OfertaBuscadorPaisesBannerComponent,
    OfertasAltaComponent,
    OfertasConfiguracionComponent,
    OfertaModificacionComponent,
    OfertasListarComponent,
    OfertasListarTarjetasComponent,
    TarjetaComponent,
    OfertasDisponiblesComponent,
    OfertasAvatarComponent,
    SolicitudPostularComponent,
    SolicitudPostularDocumentacionComponent,
    UsuariosSolicitudesComponent,
    CardComponent,
    BtnHomeComponent,
    BtnOfertasListarComponent,
    OfertasDetalleItemsComponent,
    SolicitudComponent,
    DetalleComponent,
    UploadFileSolicitudComponent,
    UploadComprobanteSolicitudComponent,
    OfertasAdministracionComponent,
    OfertasListadoComponent,
    OfertasListadoCursosComponent,
    OfertasSolicitudComponent,
    GalleryComponent,
    OfertasEstadoDetalleComponent,
    OfertasFilesComponent,
    OfertasFormularioComponent,
    OfertasEstadisticasComponent,
    DoughnutChartComponent,
    PagarSaldoComponent,
    OfertasTableComponent,
    OfertasVerPdfComponent,
    SpinnerColorsComponent,
    ModalConfirmarComponent,
    ModalSolicitarPagoComponent,
    PayPalButtonComponent,
    TransferenciaButtonComponent,
    TransferenciaModalComponent,
    CatalogoConfiguracionComponent,
    AreasConfiguracionComponent,
    ModalidadesConfiguracionComponent,
    TipoPuestosConfiguracionComponent,
    AreasListadoComponent,
    AreaAltaComponent,
    AdjuntosConfiguracionComponent,
    AdjuntosListadoComponent,
    AdjuntoAltaComponent,
    AdjuntoModificacionComponent,
    DocumentosUsuariosConfiguracionComponent,
    DocumentosUsuariosListadoComponent,
    DocumentoUsuarioAltaComponent,
    DocumentoUsuarioModificacionComponent,
    ModalidadesListadoComponent,
    TipoPuestosListadoComponent,
    ModalidadAltaComponent,
    TipoPuestoAltaComponent,
    AreaModificacionComponent,
    ModalidadModificacionComponent,
    TipoPuestoModificacionComponent,
    ModalConfirmarSolicitarPagoComponent,
    DocumentosEnviadosComponent,
    AgregarDocumentosExtraComponent,
    StepperComponent,
    InvoicesListadoComponent,
    InvoiceDetalleComponent,
    ComprobantesTransfListadoComponent,
    ComprobanteTransfDetalleComponent,
    SolicitudPostularDocumentacionIncripcionComponent,
    DocumentosUsuariosFilesComponent,
    DocumentosUsuariosFilesUploadComponent,
    DocumentosUsuariosGalleryComponent,
    BotonTarjetaComponent,
  ],


})

export class OfertasLaboralesModule { }
