import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { PerfilesService } from './../../../../../services/perfiles/perfiles.service';
import { UsuarioService } from './../../../../../services/shared/usuario.service';
import { User } from './../../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { Subscription } from 'rxjs';
import { Examen } from '../../../../../interfaces/interface-bakend/cursos/examen.interface';
import { ExamenesService } from '../../../../../services/cursos/examenes.service';
import { GuiMsjService } from '../../../../../services/shared/gui-msj.service';


@Component({
  selector: 'app-grilla-usuarios',
  templateUrl: './grilla-usuarios.component.html',
  styleUrls: ['./grilla-usuarios.component.scss']
})
export class GrillaUsuariosComponent implements OnInit, AfterViewInit {

  @Output() usuarioAsignado = new EventEmitter<boolean>();

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
  [
      'select', 'nombre', 'email', 'estado', 'created', 'identificacion'
  ];
  dataSource: any;
  usuarios: User [];
  selection = new SelectionModel<any>(true, []);
  /* Fin Data Table*/

  usuarioLoad = false;

  flagLoaded = false;
  flagAsignando = false;
  flagSelected = false;
  disabled = false;
  cursoDisponible: string;

  subscriptionExamenValues: Subscription;
  examen: Examen;


  constructor(
      private usuariosService: UsuarioService,
      private perfilesService: PerfilesService,
      private examenesService: ExamenesService,
      private guiMsjService: GuiMsjService,
      private cdRef: ChangeDetectorRef,
  ) {
      this.subscriptionExamen();
  }

  ngOnInit(): void {
  }

  /* observable al examen */
  subscriptionExamen() {
      this.subscriptionExamenValues = this.examenesService.getExamen().subscribe( async resp => {
      this.examen = resp.value;
      });
  }

  async ngAfterViewInit() {
      await this.loadDataPage();
  }

  async loadDataTable(){
      await this.usuariosService.getAllUsers().then( (usuarios: User[]) => {
          this.dataSource = new MatTableDataSource(usuarios);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.flagLoaded = true;
      });
  }

  async asignarExamen(tipo: string) {
    this.flagAsignando = true;
    this.flagSelected = false;
    this.disabled = true;
    await this.selection.selected.forEach( async element => {
            const usuarioSelect = element;
            await this.examenesService.asignacionExamen(this.examen._id, '', usuarioSelect._id, '' );
    });
    this.usuarioAsignado.emit(true);
    this.guiMsjService.msjFormSubmit('examenAsignado');
    this.flagAsignando = false;
  }

  applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async loadDataPage() {
      await this.loadDataTable();
      this.flagLoaded = true;
      return;
  }

 /*------------------------------------------
  --------------------------------------------
  Defined DATA TABLE
  --------------------------------------------
  --------------------------------------------*/
  toggleCheckbox(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  /** ngAfterViewChecked lo carga automaticamente , evita el error del check **/
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  async masterToggle() {
    if (this.isAllSelected()) {
      //  console.log('masterToggle=> isAllSelected=> this.selection.clear()');
      this.selection.clear();
      this.flagSelected = false;
      return;
    }
    if (this.dataSource.data.length > 0) {
      // console.log('masterToggle=> this.dataSource.data.length=> this.selection.clear()', this.dataSource.data.length);
      this.flagSelected = true;
      this.dataSource.data.forEach( element => {
        this.selection.toggle(element);
      });      
     
    } 

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /** The element selected */
  async onChangeToggled(elemento) {
    this.selection.toggle(elemento);
    this.flagSelected = true;
    // console.log('onChangeComprobanteToggled',elemento);
   
    // Comienzo proceso de asignacion
    if (this.selection.selected.length > 0) {
      // console.log('this.selection.selected.length ',this.selection.selected.length );
    } 
  }
  /** FIN DATA TABLE **/

}
