<div class="premium-access-area bg-f9f9f9 ptb-100 animate__animated animate__fadeIn">
    <div class="container">
        <div class="premium-access-content-style-two">
            <span class="sub-title">Examen</span>
            <h2>Alta Respuestas</h2>
            <p>Aquí podras dar de alta las respuestas a las preguntas previamente cargadas.</p>

            <!-- <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div> -->
            <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>


            <div *ngIf="this.isEditable">
                <span class="sub-title">Titulo Examen: {{ this.data.examen.titulo }}</span>
                <span class="sub-title" *ngIf="this.data.pregunta.contenido.contiene">Multimedia en pregunta: SI
                </span>
                <span class="sub-title" *ngIf="!this.data.pregunta.contenido.contiene">Multimedia en pregunta: NO
               </span>
            </div>


            <app-loading *ngIf="this.procesando"></app-loading>
            <mat-label *ngIf="this.procesando">procesando...</mat-label>

            <di *ngIf="this.isEditable">

                <pan class="sub-title"> Tipo pregunta: {{ this.data.pregunta.tipo | tipoPregunta }}</pan>

                <div class="text-nowrap bd-highlight" style="width: 8rem;">
                    Enunciado: {{ this.data.pregunta.enunciado }}</div>
                <br>

                <mat-accordion *ngIf="this.data.pregunta.contenido.contiene">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Ver Multimedia de Pregunta
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <!-- ****** DEFINO TIPO DE ARCHIVO A MOSTRAR ****** -->
                        <div *ngIf="this.getTipoArchivo(this.data.pregunta.contenido.type) == 'pdf'" class="animate__animated animate__fadeIn">
                            <iframe [src]="( 3 | mediaCursosPipe: this.data.pregunta.contenido.source | safeResourceUrl)" type="application/pdf" style="width: 75%; height: 50vh;"></iframe>
                        </div>

                        <div *ngIf="this.getTipoArchivo(this.data.pregunta.contenido.type) == 'mp4'" class="animate__animated animate__fadeIn">
                            <iframe [src]="( 2 | mediaCursosPipe: this.data.pregunta.contenido.source | safeResourceUrl)" type="video/mp4" style="width: 75%; height: 50vh;"></iframe>
                        </div>

                        <div *ngIf="this.getTipoArchivo(this.data.pregunta.contenido.type) == 'img'" class="animate__animated animate__fadeIn">
                            <img [src]="( 1 | mediaCursosPipe: this.data.pregunta.contenido.source | safe)" [alt]=" this.data.pregunta.contenido.nombreArchivo" style="width: 75%; width: 50%;">
                        </div>
                        <!-- ****** FIN DEFINO TIPO DE ARCHIVO A MOSTRAR ****** -->


                    </mat-expansion-panel>
                </mat-accordion>

                <mat-divider></mat-divider>
                <br>
                <form [formGroup]="forma">

                    <button (click)="agregarRespuesta(this.data.pregunta.tipo)" mat-raised-button color="accent">
                                <mat-icon>plus_one</mat-icon> Agregar Respuesta</button>


                    <!-- VERDADERO FALSO -->
                    <cdk-virtual-scroll-viewport *ngIf="flagVerdaderoFalso" appendOnly itemSize="70" class="scroll-viewport animate__animated animate__fadeInUp">
                        <br>
                        <br>
                        <table>
                            <thead>
                                <th>N°</th>
                                <th>Es Verdadera?</th>
                                <th>Borrar Pregunta</th>
                            </thead>
                            <tbody formArrayName="respuestas">

                                <tr *ngFor="let control of respuestas.controls; let i=index">
                                    <td width="10%">{{ i+1 }}</td>
                                    <td width="35%">
                                        <mat-slide-toggle [formControl]="esCorrecta.controls[i]"></mat-slide-toggle>
                                    </td>
                                    <td>
                                        <button class="btn-tabla" (click)="borrarRespuesta(i)"><i class="far fa-trash-alt"></i></button>
                                    </td>
                                </tr>
                            </tbody>

                        </table>

                    </cdk-virtual-scroll-viewport>

                    <!-- TABLA DE PREGUNTAS MULTIPLE DE CHOISE -->
                    <cdk-virtual-scroll-viewport *ngIf="respuestas.controls.length > 0 && !this.flagOrdenarConceptos && !this.flagVerdaderoFalso" appendOnly itemSize="70" class="scroll-viewport animate__animated animate__fadeInUp">

                        <table>
                            <thead>
                                <th>N°</th>
                                <th>Respuesta</th>
                                <th>Es correcta?</th>
                                <th>JPG/PNG-PDF-MP4 <i class="fas fa-photo-video"></i></th>
                                <th>Borrar Archivo</th>
                                <th>Borrar Pregunta</th>
                            </thead>
                            <tbody formArrayName="respuestas">

                                <tr *ngFor="let control of respuestas.controls; let i=index">
                                    <td>{{ i+1 }}</td>
                                    <td width="70%">
                                        <mat-form-field class="material-full-width" appearance="fill">
                                            <mat-label>Escriba su respuesta aquí</mat-label>
                                            <textarea [formControl]="respuestas.controls[i]" matInput></textarea>
                                        </mat-form-field>
                                    </td>
                                    <td width="35%">
                                        <mat-slide-toggle [formControl]="esCorrecta.controls[i]"></mat-slide-toggle>
                                    </td>
                                    <td>
                                        <input (change)="onFileChange($event,i)" [formControl]="file.controls[i]" type="file" accept="image/jpeg, image/jpg, image/png, application/pdf, video/mp4">
                                    </td>
                                    <td>
                                        <button class="btn-tabla" (click)="borraraArchivoCargado(i)"><i class="far fa-trash-alt"></i></button>
                                    </td>
                                    <td>
                                        <button class="btn-tabla" (click)="borrarRespuesta(i)"><i class="far fa-trash-alt"></i></button>
                                    </td>
                                </tr>
                            </tbody>

                        </table>

                    </cdk-virtual-scroll-viewport>


                    <!-- ORDENAR CONCEPTOS -->
                    <div *ngIf="this.flagOrdenarConceptos">
                        <app-ordenar-conceptos (conceptosOrdenados)="(conceptosOrdenados = $event)" (flagFormaValido)="(flagFormaValido = $event)"></app-ordenar-conceptos>
                    </div>

                    <!-- CUADRICULA DE OPCIONES -->
                    <div *ngIf="this.flagCuadriculaOpciones">
                        <app-cuadricula-opciones (flagFormaValido)="(flagFormaValido = $event)" (matrix)="(matrix = $event)"></app-cuadricula-opciones>
                    </div>

                </form>

            </di>

            <mat-card-actions>

                <button *ngIf="flagFormaValido" (click)="cargarRespuestas(this.data.pregunta.tipo)" class="default-btn-style-two animate__animated animate__fadeIn margin-left" type="submit"><i class="fas fa-file"></i>Guardar respuestas</button>
                <button *ngIf="isEditable" (click)="closeModal()" class="default-btn-style-two animate__animated animate__fadeIn margin-left"><i class="fas fa-file"></i>Cancelar y Salir</button>

                <div *ngIf="this.flagFin">
                    <blockquote class="blockquote">
                        Respuestas procesadas...
                    </blockquote>

                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-sm-6 col-md-6">
                            <div class="single-features-box">
                                <div class="icon">
                                    <i class="flaticon-shield-1"></i>
                                </div>
                                <h3>PROCESADO</h3>
                                <p>Sus respuestas se procesaron correctamente.</p>
                                <a class="link-btn">puede continuar..</a>
                            </div>
                        </div>
                    </div>

                </div>

                <div *ngIf="this.procesandoArchivos">
                    <blockquote class="blockquote">
                        <div> Procesando archivos...</div>
                        <div class="progress" style="height: 20px;">
                            <div class="progress-bar bg-info" role="progressbar" [style.width]="this.value + '%'" aria-valuenow="this.value" aria-valuemin="0" aria-valuemax="100">{{this.value}}%</div>
                        </div>
                    </blockquote>
                </div>

                <br>
            </mat-card-actions>

            <button *ngIf="this.flagFin" (click)="closeModalRespuetasCargadas()" class="default-btn-style-two animate__animated animate__fadeIn margin-left" type="button"><i class="fas fa-file"></i>Continuar con el resto de las respuestas</button>

        </div>
    </div>
</div>