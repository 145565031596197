import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Catalogo } from '../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '../../interfaces/interface-bakend/response';
import Swal from 'sweetalert2';
import { StorageService } from './storage.service';
import { Subscription } from 'rxjs';
import { Translators } from './translators.service';
import { AuthService } from '../auth/auth.service';
const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class CatalogoService {

  conceptoSeleccionado: Catalogo;

  constructor(
      private httpClient: HttpClient,
      private storageService: StorageService,
      private translatorService: Translators,
      private authService: AuthService,
  ) {}

  findDescendantsByKeyByLenguage(key, idioma: string): Promise<Catalogo[]> {
    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        if (idioma !== null) { 
          this.httpClient.get<Response>
            (`${ URL_BACKEND }/catalogos/findDescendantsByKey/${key + idioma.toUpperCase()}`, { headers }).subscribe( (resp: Response)  => {
            resolve(resp.response);
          });        
        }
        else { // especificación AADIDESS
          this.httpClient.get<Response>
            (`${ URL_BACKEND }/catalogos/findDescendantsByKey/${ key }`, { headers }).subscribe( (resp: Response)  => {
            resolve(resp.response);
          });
        }

        
    });
  }

  findDescendantsByKey(key): Promise<Catalogo[]> {
    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient.get<Response>
            (`${ URL_BACKEND }/catalogos/findDescendantsByKey/${key}`, { headers }).subscribe( (resp: Response)  => {
            resolve(resp.response);
      });
    });
  }

  getFindByKey(key): Promise<Catalogo> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/catalogos/findByKey/${key}`, { headers }).subscribe(( resp: Response ) => {
        resolve(resp.response);
      });
    });
  }

  getFindOne(id): Promise<Catalogo> {
    return new Promise( async resolve => {

      if (id != '') {
        const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
        });
  
        this.httpClient.get<Response>(`${ URL_BACKEND }/catalogos/findOne/${id}`, { headers }).subscribe(( resp: Response ) => {
          resolve(resp.response);
        });
      } else {
        resolve({ concepto: 'sin concepto'});
      }
      
    });
  }

  getConceptoSeleccionado(): Catalogo {
    return this.conceptoSeleccionado;
  }

  setConcepto(concepto: Catalogo) {
    this.conceptoSeleccionado = concepto;
    this.storageService.setConcepto(concepto);
  }

  post(data: Catalogo){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post<Response>(`${ URL_BACKEND }/catalogos`, data, { headers }).subscribe( resp => {
        
        resolve(resp.response);
        
        // tslint:disable-next-line:no-unused-expression
        (error) => {
          Swal.close();
          Swal.fire('Error al guardar', '', 'error');
          console.log(error);
          resolve(false);
        };
      });
    });

  }

  put(id: string, data: Catalogo){ // Controlar este servicio BRUNO

    return new Promise( async resolve => {

    const headers =  new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
    });

    this.httpClient.put<Response>(`${ URL_BACKEND }/catalogos/updateOne/${id}`, data,  { headers }).subscribe( resp => {

          resolve(resp.response);

          // tslint:disable-next-line:no-unused-expression
          (error) => {
            Swal.close();
            Swal.fire('Error al guardar', '', 'error');
            console.log(error);
            resolve(false);
          };
        });
      });

  }


}
