import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ExamenesService } from '../services/cursos/examenes.service';

export interface OnExit {
  onExit: () => Observable<any | UrlTree> | Promise<any | UrlTree> | any | UrlTree
}

@Injectable({
  providedIn: 'root'
})
export class ExitGuard implements CanDeactivate<unknown> {

  exit: boolean = false;
  constructor(private examenService: ExamenesService){

  }

  canDeactivate(
    component: OnExit,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
    return component.onExit().then((exit: boolean)=>{
      if(exit === true){
        this.exit = exit;
        this.examenService.setExamenEvaluacionRespuesta(null);
        sessionStorage.removeItem('EXAMEN');
        return this.exit;
      }else{
        this.exit = exit;
      }
      return this.exit;   
    })
  }

}
