<app-loading *ngIf="!this.flagLoaded"></app-loading>
<div *ngIf="this.flagLoaded" class="courses-area ptb-100 animate__animated animate__fadeIn">
    <div class="container">
        <div class="section-title">
            <h2>{{ idioma | translate: "oferta-laboral.LISTADO-OFERTAS-TITULO" | async}}{{this.nombrePais}} </h2>
            <p>{{ idioma | translate: "oferta-laboral.LISTADO-OFERTAS-SUBTITULO" | async}}</p>
        </div>

        <!-- PAGINATOR  -->
        <mat-paginator [length]="actividadesDisponibles.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getPaginatorData($event)" aria-label="Select page" showFirstLastButtons="false">
        </mat-paginator>

        <div class="row">
            <div class="col-lg-4 col-md-12 animate__animated animate__fadeIn" *ngFor="let actividad of (actividadesDisponibles |
            slice: lowValue : highValue)" [newspaper]="actividad">
                <app-ofertas-avatar *ngIf="actividad.vigente" [actividad]="actividad"></app-ofertas-avatar>
            </div>
        </div>

    </div>
</div>
<!--



// img = 'instructor-actividad.png';
// pdf = 'v-snp-debin_19-05-09.pdf';
// mp4 = 'demo.mp4';

<div class="animate__animated animate__fadeIn">
    <iframe [src]="( 3 | mediaOfertaLaboral: this.pdf ) | safe" type="application/pdf" style="width: 75%; height: 50vh;"></iframe>
</div>


<div class="row">
    <div class="animate__animated animate__fadeIn">
        <iframe [src]="( 2 | mediaOfertaLaboral: this.mp4 )| safe " type="video/mp4 " style="width: 75%; height: 50vh; "></iframe>
    </div>
</div>

<div class="row ">
    <img [src]=" 1 | mediaOfertaLaboral: this.img " alt="image ">
</div> -->

<!-- <div class="row ">
                <img [src]=" 1 | mediaCursosPipe: this.img " alt="image ">
            </div> -->
<!-- FIN Ejemplos Tefi/Bruno -->