import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config/app.config';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Translators } from 'src/app/services/shared/translators.service';
import { ProfileAuthenticationComponent } from '../profile-authentication.component';


@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class TermsConditions implements OnInit {

  activarCerrarModal = true;
  idioma: Subscription;

  constructor(
      /* Modal Ref. */
      private translatorService: Translators,
      public dialogRef: MatDialogRef<ProfileAuthenticationComponent>,
      private authService : AuthService,
      @Inject(APP_CONFIG) private iAppConfig: IAppConfig,
      @Inject(MAT_DIALOG_DATA) public data: string // los datos los recibo por servicio
      /* Fin Modal Ref. */
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });
  }

  ngOnInit(): void {
  }

    // MODAL CLOSE
    closeModal(): void {
      this.dialogRef.close();
  }

  /* DESACTIVAR BOTONES */
  async desactivarBotonoes() {
    this.activarCerrarModal = false;
  }

  cancelar(){
    this.authService.setCheck(false);
  }

  aceptar(){
    this.authService.setCheck(true);
  }

}
