import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Translators } from '../../services/shared/translators.service';
import { AuthService } from '../../services/auth/auth.service';
import { NavigationService } from '../../services/shared/navigation.service';
import { StorageService } from 'src/app/services/shared/storage.service';
import { PerfilesService } from '../../services/perfiles/perfiles.service';
import { MenuesService } from 'src/app/services/menues/menues.service';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { Menu } from '../../interfaces/interface-bakend/seguridad/acl/menu.interface';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  idioma: Subscription;
  classApplied = false;

  menuDescendats: [] = [];
  usuario: User;
  menues: Menu[] = [];
  buildMenu: any [] = [];

  flagMenuLoaded = false;

  logoUrl: string;
  
  tipoSolicitud = 0;

  constructor(
    private authservice: AuthService,
    private translatorService: Translators,
    private router: Router,
    private navigation: NavigationService,
    private menuesService: MenuesService,
    private configBeginService: ConfigBeginService,
    ) {
      this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        this.idioma = resp;
      });
    }

  ngOnInit(): void {
      this.cargarMenuPerfil();
      this.cargarLogo();
  }

  async getConfiguracionGral() {
    this.tipoSolicitud = +(await this.configBeginService.getConfiguracionGral()).tipoSolicitud.numero;
  }

  async cargarLogo() {
    this.logoUrl = (await this.configBeginService.getConfiguracionGral()).urlLogo;
  }

  logout(){
    this.authservice.logout();
  }

  toggleClass() {
      this.classApplied = !this.classApplied;
  }

  useLanguage(idioma: string){
    this.translatorService.setIdiomaSeleccionado(idioma);
  }

  back(): void {
    this.navigation.back();
  }

  async cargarMenuPerfil() {
    this.usuario = await this.authservice.getUserLogon();
    this.menues = await this.usuario.perfil.menuWeb;
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < this.menues.length; index++) {
        const menu: any = this.menues[index];
        const descendants = await this.menuesService.getFindInmediateDescendants(menu._id);
        const data = {
            menu,
            descendants,
        };
        this.buildMenu.push(data);
    }
    this.flagMenuLoaded = true;
  }

}
