<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/home">{{ idioma | translate: "navbar.UL-INICIO" | async}}</a></li>
                <li>{{ idioma | translate: "form.MI-CUENTA" | async}}</li>
            </ul>
            <h2>{{ idioma | translate: "form.MI-CUENTA" | async}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70 mt-5">
    <div class="container">
        <div class="profile-box animate__animated animate__fadeIn" *ngIf="flagLoaded">
            <div class="row align-items-right">
                <div class="col-lg-6 col-md-6 col-xs-12">
                    <div class="image">
                        <div matRipple [matRippleCentered]="centered" [matRippleDisabled]="disabled"
                            [matRippleUnbounded]="unbounded" [matRippleRadius]="radius" [matRippleColor]="color">

                            <ng-container *ngIf="this.usuario.avatar == 'av-1.png' else elseTemplate">
                                <img [src]="imageUrl" class="rounded float-start" width="300px" />
                            </ng-container>
                            <ng-template #elseTemplate>
                                <img [src]=" this.usuario._id | mediaUsuariosPipe: this.usuario.avatar | safe"
                                    [alt]="this.usuario.avatar" class="rounded float-start" width="300px">
                            </ng-template>

                        </div>
                    </div>
                    <br>
                    <div>
                        <form [formGroup]="formImg" (ngSubmit)="guardarImg()">
                            <div class="form-group">
                                <input [disabled]="condition" class="file-input" type="file" formControlName="imagen"
                                    #fileInput accept="image/jpeg, image/jpg, image/png"
                                    (change)="onChangeFile(fileInput.files[0], $event)" />
                            </div>
                            <!--<div class="material-ripple-container mat-elevation-z4 mt-5" matRipple [matRippleCentered]="centered" [matRippleDisabled]="disabled" [matRippleUnbounded]="unbounded" [matRippleRadius]="radius" [matRippleColor]="color">
                                <img [src]="imageUrl" />
                            </div>-->
                            <div class="form-group">
                                <button [disabled]="!formImg.valid || this.cargandoArchivo" type="submit"
                                    class="button button-xs" disabled>{{ idioma | translate: "form.GUARDAR-FOTO" |
                                    async}}</button>
                            </div>

                        </form>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-xs-12">

                    <div class="content animate__animated animate__fadeIn" *ngIf="flagLoaded">
                        <h4>{{ idioma | translate: "form.DATOS-PERSONALES" | async}}</h4>

                        <h3 *ngIf="this.usuario.nombrePersona">{{ this.usuario.nombrePersona }} {{
                            this.usuario.apellidoPersona }}</h3>
                        <p *ngIf="this.usuario.presentacion" style="text-align: justify;">{{ this.usuario.presentacion
                            }}</p>
                        <ul class="info">
                            <li *ngIf="this.usuario.telefono"><span>{{ idioma | translate: "form.NUMERO-TELEFONO" |
                                    async}}: </span>{{ this.usuario.paisCodeArea }} - {{ this.usuario.telefono }}</li>
                            <li *ngIf="this.usuario.email"><span>{{ idioma | translate: "form.EMAIL" | async}}: </span>
                                {{ this.usuario.email }}</li>
                            <li *ngIf="this.usuario.birth"><span>{{ idioma | translate: "form.FECHA-NACIMIENTO" |
                                    async}}: </span> {{ this.usuario.birth | date: 'dd-MM-yyyy'}}</li>
                        </ul>

                        <!-- Button trigger modal -->
                        <div class="mt-5">
                            <button type="button" style="margin-right: 2%;" class="button btn-xs"
                                (click)="this.openDialogModificarClave()">
                                {{ idioma | translate: "form.MODIFICAR-CLAVE" | async}}
                            </button>
                            <!-- <button type="button" class="button" data-bs-toggle="modal" data-bs-target="#modalPresentacion">
                                Modificar presentación
                            </button> -->
                        </div>

                    </div>
                </div>

            </div>
            <br>
            <mat-divider></mat-divider>
            <br>

            <div class="col-lg-12" *ngIf="this.usuario && this.usuario?._id">
                <!-- <div class="card-header" style="background-color: #fe4a55; color: white;"> -->
                <h4 class="text-center">Documentos</h4>
                <!-- </div> -->

                <br>


                <!-- DOCUMENTOS PENDIENTE -->
                <div class="row">
                    <div class="col-lg-18 col-md-18">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header style="background-color: #fe4a55; color: white;">
                                    <mat-panel-title>
                                        Ver documentación pendiente
                                    </mat-panel-title>
                                    <mat-panel-description>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <app-documentos-usuarios-files-upload
                                    [usuario]="this.usuario"></app-documentos-usuarios-files-upload>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>

                <br>

                <!-- IMAGENES -->
                <div class="row" *ngIf='this.imgs.length > 0'>
                    <div class="col-lg-18 col-md-18">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header class="fotos"
                                    style="background-color: #98fb98; color: white;">
                                    <mat-panel-title>
                                        Documentación - Fotos
                                    </mat-panel-title>
                                    <mat-panel-description>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <app-documentos-usuarios-gallery [imagenes]="this.imgs"
                                    [id_usuario]="this.usuario._id"></app-documentos-usuarios-gallery>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>

                <div class="row" *ngIf='!this.imgs.length > 0'>
                    <div class="col-lg-18 col-md-18">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        SIN Documentación - Fotos
                                    </mat-panel-title>
                                    <mat-panel-description>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div class="form-group">
                                    <div class="col-sm-12">
                                        <mat-list>
                                            <mat-list-item>
                                                <i class="fa fa-file-archive-o fa-2x" aria-hidden="true"></i>
                                            </mat-list-item>
                                        </mat-list>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>

                <br>

                <!-- ARCHIVOS -->
                <div class="row" *ngIf='this.files.length > 0'>
                    <div class="col-lg-18 col-md-18">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header class="archivos"
                                    style="background-color: #919bbb; color: white;">
                                    <mat-panel-title>
                                        Documentación - Archivos
                                    </mat-panel-title>
                                    <mat-panel-description>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <app-documentos-usuarios-files [files]="this.files"
                                    [usuario]="this.usuario"></app-documentos-usuarios-files>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>

                <br>

                <div class="row" *ngIf='!this.files.length > 0'>
                    <div class="col-lg-18 col-md-18">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        SIN Documentación - Archivos
                                    </mat-panel-title>
                                    <mat-panel-description>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div class="form-group">
                                    <div class="col-sm-12">
                                        <mat-list>
                                            <mat-list-item>
                                                <i class="fa fa-file-archive-o fa-2x" aria-hidden="true"></i>
                                            </mat-list-item>
                                        </mat-list>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-lg-12">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Mis Títulos
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <br>
                                <!-- Tabla para mostrar los títulos -->
                                <table mat-table [dataSource]="this.titulos" class="mat-elevation-z8"
                                    style="width: 100%;">
                                    <ng-container matColumnDef="nombre">
                                        <th mat-header-cell *matHeaderCellDef> Nombre </th>
                                        <td mat-cell *matCellDef="let titulo"> {{ titulo.titulo.nombre }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="disciplina">
                                        <th mat-header-cell *matHeaderCellDef> Disciplina </th>
                                        <td mat-cell *matCellDef="let titulo"> {{ titulo.disciplina.nombre }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="fechaOtorgamiento">
                                        <th mat-header-cell *matHeaderCellDef> Fecha Otorgamiento </th>
                                        <td mat-cell *matCellDef="let titulo">
                                            {{ titulo.fechaOtorgamiento | date: 'dd MMMM yyyy' }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="fechaVencimiento">
                                        <th mat-header-cell *matHeaderCellDef> Fecha Vencimiento </th>
                                        <td mat-cell *matCellDef="let titulo">
                                            {{ titulo.fechaVencimiento | date: 'dd MMMM yyyy' }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="observaciones">
                                        <th mat-header-cell *matHeaderCellDef> Observaciones </th>
                                        <td mat-cell *matCellDef="let titulo"> {{ titulo.observaciones }} </td>
                                    </ng-container>

                                    <tr mat-header-row
                                        *matHeaderRowDef="['nombre', 'disciplina', 'fechaOtorgamiento', 'fechaVencimiento', 'observaciones']">
                                    </tr>
                                    <tr mat-row
                                        *matRowDef="let row; columns: ['nombre', 'disciplina', 'fechaOtorgamiento', 'fechaVencimiento', 'observaciones'];">
                                    </tr>
                                </table>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>

                <br>
                <!-- Mis Boletines -->
                <div class="row">
                    <div class="col-lg-12">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Mis Boletines
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div *ngFor="let boletin of this.boletines" class="boletin-item">
                                    <!-- Panel de expansión para las materias del boletín -->
                                    <mat-accordion>
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header
                                                style="background-color: #b9c4ce; color: white;">
                                                <mat-panel-title>
                                                    {{ boletin.boletin.nombre }}
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <br>
                                            <!-- Tabla para mostrar las materias -->
                                            <table mat-table [dataSource]="boletin.materias" class="mat-elevation-z8"
                                                style="width: 100%;">
                                                <ng-container matColumnDef="materia">
                                                    <th mat-header-cell *matHeaderCellDef> Materia </th>
                                                    <td mat-cell *matCellDef="let materia"> {{ materia.materia.nombre }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="modalidadCursada">
                                                    <th mat-header-cell *matHeaderCellDef> Modalidad Cursada </th>
                                                    <td mat-cell *matCellDef="let materia"> {{
                                                        materia.modalidadCursada.concepto }} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="modalidadExamen">
                                                    <th mat-header-cell *matHeaderCellDef> Modalidad Examen </th>
                                                    <td mat-cell *matCellDef="let materia"> {{
                                                        materia.modalidadExamen.concepto }} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="fechaExamen">
                                                    <th mat-header-cell *matHeaderCellDef> Fecha Examen </th>
                                                    <td mat-cell *matCellDef="let materia">
                                                        {{ materia.fechaExamen ? (materia.fechaExamen | date: 'dd MMMM yyyy') : 'No disponible' }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="fechaNota">
                                                    <th mat-header-cell *matHeaderCellDef> Fecha Nota </th>
                                                    <td mat-cell *matCellDef="let materia">
                                                        {{ materia.fechaNota ? (materia.fechaNota | date: 'dd MMMM yyyy') : 'No disponible' }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="notaExamen">
                                                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                                                        Nota Examen </th>
                                                    <td mat-cell *matCellDef="let materia" style="text-align: center;">
                                                        {{ materia.notaExamen || 'Pendiente de Nota' }} </td>
                                                </ng-container>

                                                <tr mat-header-row
                                                    *matHeaderRowDef="['materia', 'modalidadCursada', 'modalidadExamen', 'fechaExamen', 'fechaNota', 'notaExamen']">
                                                </tr>
                                                <tr mat-row
                                                    *matRowDef="let row; columns: ['materia', 'modalidadCursada', 'modalidadExamen', 'fechaExamen', 'fechaNota', 'notaExamen'];">
                                                </tr>
                                            </table>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                    <br>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalPresentacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modificar presentación</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formPresentacion" (ngSubmit)="guardarPresentacion()">

                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                            <mat-label>Presentación</mat-label>
                            <textarea formControlName="presentacion" rows="5" matInput
                                placeholder="Ingrese una presentación"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="modal-footer">
                        <button type="submit" class="button">Guardar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>