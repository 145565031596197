<app-loading *ngIf="!flagLoaded" class="m-5">
</app-loading><br>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/usuario-solicitudes">{{ idioma | translate: "solicitudes.SOLICITUDES" | async}}</a>
                </li>
                <li>{{ idioma | translate: "solicitudes.SOLICITUD" | async}}</li>
            </ul>
            <h2>{{ idioma | translate: "solicitudes.SOLICITUD-DETALLE" | async}}</h2>
        </div>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image" width="80%"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>

<div class="products-details-area ptb-100" *ngIf="flagLoaded">

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <mat-tab-group animationDuration="0ms">
                    <!-- TAB LABEL 1 -->
                    <mat-tab [label]="(idioma | translate: 'oferta-laboral.DETALLES_PASOS' | async)">
                        <br>
                        <div class="products-details-desc">
                            <h3>{{ idioma | translate: "solicitudes.SOLICITUD" | async}}</h3>
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="products-details-desc">
                                        <div class="products-meta">
                                            <span>{{ idioma | translate: "solicitudes.ACTIVIDAD" | async}}: <a>{{
                                                    actividad.nombre }}</a></span>
                                            <span>{{ idioma | translate: "solicitudes.NUMERO-SOLICITUD" | async}}: <a>{{
                                                    solicitud._nro }}</a></span>
                                            <span>{{ idioma | translate: "solicitudes.FECHA-SOL" | async}}: <a>{{
                                                    solicitud.createdAt | date:"dd/MM/yy" }}</a></span>
                                            <!-- <span>{{ idioma | translate: "solicitudes.PAIS" | async}}: <a>{{ nombrePais }}</a></span> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="products-details-desc">
                                        <div class="products-meta">
                                            <span>{{ idioma | translate: "solicitudes.FORMULARIO" | async}}: <a>{{
                                                    estadoFormulario }}</a></span>
                                            <span>{{ idioma | translate:
                                                "oferta-laboral.DETALLE-DOCUMENTACION-REQUERIDA" | async}}: <a>{{
                                                    estadoDocumentacion }}</a></span>
                                            <span>{{ idioma | translate: "solicitudes.STEPPER-PAGO" | async}}: <a>{{
                                                    estadoPagoTotal }}</a></span>
                                            <span>{{ idioma | translate: "solicitudes.DETALLE-PROGRESO" | async}}: <a>{{
                                                    solicitud.lastState.numberState | detalleEstadosPipe }}.</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="table-responsive">
                                                <table *ngIf="solicitud.cursos && solicitud.cursos.length > 0"
                                                    class="table">
                                                    <!-- <caption>Cursos</caption> -->
                                                    <thead>
                                                        <tr>
                                                            <th>Curso</th>
                                                            <th>Precio</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let curso of solicitud.cursos">
                                                            <td>{{ curso.nombre }}</td>
                                                            <td>${{ curso.precio }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="table-responsive">
                                                <table *ngIf="solicitud.actividades && solicitud.actividades.length > 0"
                                                    class="table">
                                                    <!-- <caption>Actividades</caption> -->
                                                    <thead>
                                                        <tr>
                                                            <th>Actividades</th>
                                                            <th>Fecha</th>
                                                            <th>Fecha V.</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let actividad of solicitud.actividades">
                                                            <td>{{ actividad.nombre || actividad.descripcion }}</td>
                                                            <td>{{ actividad.fecha | date: "dd/MM/yyyy" }}</td>
                                                            <td>{{ actividad.fechaVencimiento | date: "dd/MM/yyyy" }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- DRIVE DOC -->
                            <ng-container *ngIf="enlacesDrive.length > 0">
                                <h3>Documentos disponibles para descarga:</h3>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Documento</th>
                                            <th>Enlace</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let enlace of enlacesDrive">
                                            <td>{{ enlace.texto }}</td>
                                            <td>
                                                <a href="{{ enlace.enlace }}" target="_blank" rel="noopener noreferrer">
                                                    <i class="fa fa-download" aria-hidden="true"></i>Descargar
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                            <!-- FIN DRIVE DOC -->


                            <!-- Steps and Button -->
                            <div class="row">
                                <div class="col-lg-8 col-md-44">
                                    <div class="products-add-to-cart">

                                        <!-- estados administrativos-->
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span *ngIf="flagPreAprobacion" class="alert alert-primary" role="alert">{{
                                                idioma | translate: "solicitudes.ESPERA-PRE-APROBACION" | async}}</span>
                                            <span *ngIf="flagPreAprobado" class="alert alert-primary" role="alert">{{
                                                idioma | translate: "solicitudes.STEPPER-PRE-APROBADO-DETALLE" |
                                                async}}</span>
                                            <span *ngIf="flagAprobado" class="alert alert-success" role="alert">{{
                                                idioma | translate: "solicitudes.STEPPER-APROBADO" | async}} - {{ idioma
                                                | translate: "solicitudes.STEPPER-APROBADO-DETALLE" | async }}</span>
                                            <span *ngIf="flagRechazada" class="alert alert-warning" role="alert">{{
                                                idioma | translate: "solicitudes.STEPPER-RECHAZADA" | async }} - {{
                                                idioma | translate: "solicitudes.STEPPER-RECHAZADA-DETALLE" | async
                                                }}</span>
                                            <span *ngIf="flagAnulada" class="alert alert-warning" role="alert">{{ idioma
                                                | translate: "solicitudes.ANULADA" | async }} </span>
                                            <span *ngIf="flagEsperaDeComprobacionPago" class="alert alert-primary"
                                                role="alert">{{ idioma | translate:
                                                "solicitudes.STEPPER-ESPERA-COMPROBACION-PAGO" | async }} - {{ idioma |
                                                translate: "solicitudes.STEPPER-ESPERA-COMPROBACION-PAGO-DETALLE" |
                                                async }}</span>
                                            <span *ngIf="flagEsperaInicioCurso" class="alert alert-primary"
                                                role="alert">{{ idioma | translate: "solicitudes.ESPERA-CURSO" | async
                                                }}</span>
                                        </div>

                                        <div *ngIf="flagPreAprobado || flagAprobado" class="alert alert-secondary"
                                            role="alert">
                                            Puede cargar más documentos de ser necesario, en la opción "Cargar
                                            Documentación Extra".
                                        </div>
                                        <!-- pago solicitado -->
                                        <br>
                                        <br>
                                        <div class="col-lg-12 col-md-12" *ngIf="activarPagoFinal">
                                            <div class="alert alert-warning" *ngIf="this.getMacroPagos() && this.macroPagosForm && !this.tienePayment">
                                                <img src="https://pagos.macroclickpago.com.ar/images/MACROLogo.png" style="width: 20%;">
                                                <h4 class="alert-heading">Macro Pagos</h4>
                                                <p>Abonar mediante Macro Pagos, si abona por este medio no precisa enviar el comprobante. Su enlace de pago de Macro Pagos también ha sido enviado a su correo electrónico.</p>
                                                <button mat-button (click)="createPaymentMacro(solicitud._id)" *ngIf="!isPaymentProcessed">Procesar Pago</button>
                                                <mat-spinner *ngIf="loadingMacroPagos"></mat-spinner>
                                                <!-- Contenedor donde se embebará el formulario -->
                                                <div id="payment-container-macro" class="form-container"></div>
                                                <!-- <div style="margin-top:10px;color:#333;">
                                                    <p style="margin:0;">12 cuotas sin interés con Tarjeta de crédito Visa Signature</p>
                                                    <p style="margin:0;">y 3 cuotas sin interés con tarjeta de crédito VISA Macro</p>
                                                </div> -->
                                                <div style="border: 2px solid #008cdd; padding: 10px; margin-top: 20px; background-color: #f0f8ff; border-radius: 10px;">
                                                    <h3 style="color: #008cdd; text-align: center;">Promoción de Cuotas Sin Interés</h3>
                                                    <div style="margin-top:10px;color:#333; text-align: center;">
                                                        <p style="margin:0; font-size: 16px;"><strong>12 cuotas sin interés</strong> con Tarjeta de crédito <strong>Visa Signature Macro Selecta</strong></p>
                                                        <p style="margin:0; font-size: 16px;"><strong>3 cuotas sin interés</strong> con tarjeta de crédito <strong>Visa Macro</strong></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="alert alert-warning" role="alert" *ngIf="this.getMercadoPago() && !this.tienePayment">
                                                <h4 class="alert-heading">Mercado Pago</h4>
                                                <p>Abonar mediante Mercado Pago, si abona por este medio no precisa
                                                    enviar el comprobante. Su enlace de pago de Mercado Pago también ha
                                                    sido enviado a su correo electrónico.</p>
                                                <a [href]="mercadoPagoLink" target="_blank"
                                                    style="background-color: #008cdd; color: white; padding: 10px 20px; border: none; border-radius: 5px; text-decoration: none;">
                                                    <img src="https://administracion.aadidess.cipbyte.io/imgEmail/mercadopago.png"
                                                        alt="Mercado Pago"
                                                        style="vertical-align: middle; margin-right: 10px; height: 20px;">
                                                    <strong style="color: white;">Click aquí</strong>
                                                </a>
                                                <hr>
                                            </div>

                                            

                                            <mat-card class="example-card">
                                                <mat-card-header>
                                                    <div mat-card-avatar class="example-header-image"></div>
                                                    <mat-card-title>{{ idioma | translate: "pagos.PAGO-SOLICITUD" |
                                                        async}}</mat-card-title>
                                                </mat-card-header>
                                                <mat-card-content>
                                                    <div class="products-details-desc">
                                                        <div class="products-meta">
                                                            <div class="courses-details-info">
                                                                <ul class="info">
                                                                    <div *ngFor="let item of this.modulos">
                                                                        <li class="price">
                                                                            <div class="d-flex justify-content-between align-items-center">
                                                                                <span><i class="flaticon-lock"></i>{{item.nombre}}</span>
                                                                                {{ item.precio | currency:'ARS':'symbol':'1.2-2' }} <!-- Formato pesos argentinos -->
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                    <li class="price">
                                                                        <div class="d-flex justify-content-between align-items-center">
                                                                            <span>Subtotal</span>
                                                                            {{ this.totalModulosCosto | currency:'ARS':'symbol':'1.2-2' }} <!-- Formato pesos argentinos -->
                                                                        </div>
                                                                    </li>
                                                                    <li class="price">
                                                                        <div class="d-flex justify-content-between align-items-center">
                                                                            <span>Descuento</span>
                                                                            {{ this.invoice.importeDescuento ? (this.invoice.importeDescuento | currency:'ARS':'symbol':'1.2-2') : '0.00' }} <!-- Formato pesos argentinos -->
                                                                        </div>
                                                                    </li>
                                                                    <li class="price">
                                                                        <div class="d-flex justify-content-between align-items-center">
                                                                            <span>Recargo</span>
                                                                            {{ this.invoice.importeRecargo ? (this.invoice.importeRecargo | currency:'ARS':'symbol':'1.2-2') : '0.00' }} <!-- Formato pesos argentinos -->
                                                                        </div>
                                                                    </li>
                                                                    <li class="price" style="font-size: 1.5em;">
                                                                        <div class="d-flex justify-content-between align-items-center">
                                                                            <span>Total</span>
                                                                            {{ this.invoice.importeTotal | currency:'ARS':'symbol':'1.2-2' }} <!-- Formato pesos argentinos -->
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Boton Transferencia -->
                                                    <div class="payment-box">
                                                        <app-transferencia-button [valorCobrar]="totalModulosCosto"
                                                            [componenteCall]="'PAGO_TRANSFERENCIA'"></app-transferencia-button>
                                                    </div>
                                                </mat-card-content>
                                            </mat-card>

                                        </div>

                                        <!-- <button *ngIf="!activarPagoFinal" disabled>PAGAR SALDO<span></span></button> -->
                                        <!-- FIN pago solicitado -->


                                        <!-- cargar formulario -->
                                        <button routerLink="/solicitud-postular" *ngIf="activarCargarFormulario">{{
                                            idioma | translate: "solicitudes.CARGAR-FORM" |
                                            async}}<span></span></button>
                                        <!-- <button *ngIf="!activarCargarFormulario" disabled>{{ idioma | translate: "solicitudes.CARGAR-FORM" | async}}<span></span></button> -->

                                        <mat-divider></mat-divider>
                                        <br>

                                        <div class="alert alert-info" role="alert">
                                            <h4 class="alert-heading">Documentos</h4>
                                            <p>A continuación, se enumeran los documentos necesarios para la incripción.
                                                Deberás entregar la documentación solicitada completa antes del cierre
                                                de inscripción (las mismas son requisitos
                                                obligatorios), en caso de no presentarla se dará de baja la solicitud
                                                perdiendo el arancel abonado.</p>
                                            <hr>
                                        </div>

                                        <!-- carga documentos opcionales -->
                                        <!-- <app-solicitud-postular-documentacion-incripcion *ngIf="solicitud.actividad.solicitarDocumentos" [activarCargaDocumentosOpcionales]="activarCargaDocumentosOpcionales" [documentosEstadosSolicitar]="documentosEstadosSolicitar" [solicitud]="this.solicitud" [usuario]="this.solicitudesService.getSolicitudSeleccionada().usuario"></app-solicitud-postular-documentacion-incripcion> -->
                                        <app-solicitud-postular-documentacion-incripcion
                                            *ngIf="solicitud.actividad.solicitarDocumentos"
                                            [activarCargaDocumentosOpcionales]="true"
                                            [documentosEstadosSolicitar]="documentosEstadosSolicitar"
                                            [solicitud]="this.solicitud"
                                            [usuario]="this.solicitudesService.getSolicitudSeleccionada().usuario"></app-solicitud-postular-documentacion-incripcion>
                                        <!-- fin carga documentos opcionales -->


                                        <div *ngIf="!solicitud.actividad.solicitarDocumentos"
                                            class="alert alert-success" role="alert">
                                            ATENCIÓN: Esta solicitud no requiere documentación específica. Por favor,
                                            espere mientras su solicitud se encuentra en proceso de PRE-APROBACIÓN. Será
                                            notificado una vez que sea revisada por el área administrativa. </div>
                                        <br>

                                        <!--  DETALLE INFORMATIVO PASOS -->
                                        <!-- <div class="experience-area ptb-100 extra-padding">
                                            <div class="container">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="experience-content">
                                                            <span class="sub-title">Realiza tu trámite... Online!</span>
                                                            <h2 class="playfair-display-font">Completa los pasos...</h2>
                                                            <p>Sigue los pasos de la solicitud.</p>
                                                            <ul class="features-list">
                                                                <ul class="features-list">
                                                                    <li><i class="bx bx-check"></i> Carga tu formulario</li>
                                                                    <li><i class="bx bx-check"></i> Envía tu documentación</li>
                                                                    <li><i class="bx bx-check"></i> Espera tu pre-aprobación</li>
                                                                    <li><i class="bx bx-check"></i> Envía tu comprobante</li>
                                                                    <li><i class="bx bx-check"></i> Comienza!</li>
                                                                </ul>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="experience-image">
                                                            <img src="assets/img/icon.png" alt="image" width="20">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- DETALLE INFORMATIVO PASOS -->

                                        <!-- texto informacion -->
                                        <!-- <div class="features-area pt-100 pb-70 bg-fff8f8">
                                            <div class="container">
                                                <div class="section-title">
                                                    <span class="sub-title">{{ idioma | translate: "solicitudes.INFO-SOLICITUD" | async}}</span>
                                                    <h2>{{ idioma | translate: "solicitudes.INFO-SOLICITUD-SUBTITULO" | async}}​</h2>
                                                    <p>{{ idioma | translate: "solicitudes.INFO-SOLICITUD-DETALLE" | async}}</p>
                                                </div>
                                                <div class="row justify-content-center">

                                                    <div class="col-lg-4 col-sm-6 col-md-6">
                                                        <div class="features-box">
                                                            <div class="icon">
                                                                <i class="flaticon-shield-1"></i>
                                                            </div>
                                                            <h3>{{ idioma | translate: "solicitudes.INFO-SOLICITUD-NOTIFICACIONES" | async}}</h3>
                                                            <p>{{ idioma | translate: "solicitudes.INFO-SOLICITUD-NOTIFICACIONES-DETALLE" | async}}</p>
                                                            <a class="link-btn">{{ idioma | translate: "solicitudes.INFO-SOLICITUD-COMIENZA-AHORA" | async}}</a>
                                                            <div class="back-icon">
                                                                <i class="flaticon-shield-1"></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- FIN TEXTO DE INFORMACIÓN -->

                                        <app-detalle [actividad]=actividad></app-detalle>

                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-44">
                                    <!-- stepper -->
                                    <app-stepper *ngIf="!flagRechazada"
                                        [stepperSet]="this.solicitud.lastState.numberState"
                                        [vertical]="true"></app-stepper>
                                </div>
                            </div>
                        </div>


                    </mat-tab>

                    <!-- TAB LABEL 2 -->
                    <mat-tab [label]="(idioma | translate: 'oferta-laboral.DOCUMENTOS_ENVIADOS' | async)">
                        <br>
                        <app-documentos-enviados [solicitud]="this.solicitud"
                            [usuario]="this.solicitud.usuario"></app-documentos-enviados>
                    </mat-tab>

                    <!-- TAB LABEL 3 -->
                    <mat-tab [label]="(idioma | translate: 'solicitudes.CARGA-DOCUMENTACION_EXTRA' | async)">
                        <br>
                        <!-- cargar documentos extras -->
                        <br>
                        <mat-card class="example-card">
                            <mat-card-header>
                                <div mat-card-avatar class="example-header-image"></div>
                                <mat-card-title>{{ idioma | translate: "solicitudes.DOCUMENTOS" |
                                    async}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <p>
                                    {{ idioma | translate: "oferta-laboral.DOCUMENTOS-BTN-ADJUNTAR" | async}}
                                </p>
                            </mat-card-content>
                            <mat-card-actions>
                                <button
                                    (click)="openDialogDocExtra(labelDocExtra,this.idAdjuntoExtra.properties._idAdjunto)"
                                    *ngIf="activarCargaExtra">{{ idioma | translate:
                                    "solicitudes.CARGA-DOCUMENTACION_EXTRA" | async}}<span></span></button>
                                <button *ngIf="!activarCargaExtra" disabled>{{ idioma | translate:
                                    "solicitudes.CARGA-DOCUMENTACION_EXTRA" | async}}<span></span></button>
                            </mat-card-actions>
                        </mat-card>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <!-- <div class="courses-details-area pb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <app-detalle [actividad]=actividad></app-detalle>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>

</div>
