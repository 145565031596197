import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { PaisesService } from '../../../../services/shared/paises.service';
import { Actividad, DocumentosEstadosSolicitar } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { SolicitudesService } from '../../../../services/ofertas-laborales/solicitudes.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MediaOfertaLaboralFileUserPipe } from '../../../../pipes/ofertas-laborales/mediaOfertaLaboralFileUser.Pipe';
import { Payments } from '../../../../interfaces/interface-bakend/payments/payments.interfaces';
import { PaymentsService } from '../../../../services/shared/payments.service';
import { Solicitud } from '../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { IAppOfertas, APP_CONFIG_OFERTAS } from '../../../../app.config/app.config-ofertas';
import { CatalogoService } from '../../../../services/shared/catalogo.service';
import { Translators } from '../../../../services/shared/translators.service';
import { PaypalService } from '../../../../services/shared/paypal.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { UploadFileSolicitudComponent } from '../../common/upload-file-solicitud/upload-file-solicitud.component';
import { AgregarDocumentosExtraComponent } from './agregar-documentos-extra/agregar-documentos-extra.component';
import { BankTransferService } from 'src/app/services/shared/bank-transfer.service';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { Curso } from '../../../../interfaces/interface-bakend/cursos/curso.interface';
import { Invoice } from 'src/app/interfaces/interface-bakend/invoices/invoices.interfaces';

declare var require: any;
const FileSaver = require('file-saver');

@Component({
    selector: 'app-solicitud',
    templateUrl: './solicitud.component.html',
    styleUrls: ['./solicitud.component.scss']
})
export class SolicitudComponent implements OnInit, OnDestroy {

    actividad: Actividad;
    pais: PaisDisponible;
    nombrePais: string;
    solicitud: Solicitud;
    flagLoaded = false;
    documentosEstadosSolicitar: DocumentosEstadosSolicitar[];
    activarCargaDocumentosOpcionales; boolean;
    activarCargarFormulario: boolean;
    activarCargarDocumentos: boolean; // AADIDESS NO UTILIZA POR EL MOMENTO
    activarCargarDniFrente: boolean;
    activarCargarDniDorso: boolean;
    activarCargarFichaMedica: boolean;
    activarCargarTituloSecundario: boolean;
    activarDescargaContrato: boolean; // AADIDESS NO UTILIZA POR EL MOMENTO
    activarPagoFinal: boolean;
    activarCargaExtra: boolean;

    dataModal: any;

    /* ESTADOS */
    estadoFormulario: string;
    estadoDocumentacion: string;
    estadoPagoTotal: string;

    /* FLAGS */
    flagAprobado = false;
    flagDocumentosCompleto = false;
    flagPreAprobacion = false;
    flagPreAprobado = false;
    flagSolicitarPago = false;
    flagPreContrato = false;
    flagRechazada = false;
    flagAnulada = false;
    flagEsperaPreContrato = false;
    flagEviarPago = false;
    flagTituloSecundario = false;
    flagEsperaDeComprobacionPago = false;
    flagEsperaInicioCurso = false;

    idAdjuntoPasaje: any;
    idAdjuntoPcr: any;
    idAdjuntoExtra: any;

    idDniFrente: any;
    idDniDorso: any;
    idFichaMedica: any;
    idTituloSecundario: any;

    /* LABELS */
    labelPasajes = 'Cargar Pasajes';
    labelpcr = 'Cargar PCR';
    labelContrao = 'Descargar contrato';
    labelDocExtra = 'Cargar documentos extras';

    idioma: Subscription;
    newState: Subscription;

    modulos: Curso[];
    totalModulosCosto = 0;

    /* Paypal */
    valor: string;
    description: string;
    // tslint:disable-next-line:variable-name
    currency_code = 'USD';
    estadoPago: Subscription;
    flagProcesandoPago = false;

    enlacesDrive: { texto: string, enlace: string }[] = [];

    mercadoPagoLink: string;
    isPaymentProcessed: boolean = false;
    loadingMacroPagos: boolean = false;
    macroPagosForm: boolean = false;
    tienePayment: boolean = false;
    invoice: Invoice;

    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        private router: Router,
        private actividadesService: ActividadesService,
        private paisesService: PaisesService,
        private solicitudesService: SolicitudesService,
        public dialog: MatDialog,
        private catalogoService: CatalogoService,
        private guiMsjService: GuiMsjService,
        private translatorService: Translators,
        private paypalService: PaypalService,
        private paymentsService: PaymentsService,
        private bankTransferService: BankTransferService,
        private cursosService: CursosService,
    ) {
        // this.statusChangesEstadoPagoPaypal();

        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
        });

        this.newState = this.solicitudesService.getsetNewStater().subscribe(resp => {
            this.initPostChangeStatus();
            this.newState = resp;
        });
    }

    ngOnInit(): void {
        this.getEstadoVariables();
        this.cargarAdjuntos();
    }

    ngOnDestroy(): void {
        // this.estadoPago.unsubscribe();
        // this.newState.unsubscribe();
    }

    async initPostChangeStatus() {
        this.getEstadoVariables();
        this.cargarAdjuntos();
    }

    async cargarAdjuntos() {
        this.idAdjuntoExtra = await this.catalogoService.getFindByKey('ADJUNTO_EXTRA');
    }

    getActividad() {
        return this.actividad = this.actividadesService.getActividadSeleccionada();
    }


    getPais() {
        this.nombrePais = this.paisesService.getPaisSeleccionado().pais.name_es;
        return this.pais = this.paisesService.getPaisSeleccionado();
    }

    async getSoliciitud() {
        this.flagTituloSecundario = this.solicitudesService.getSolicitudSeleccionada().usuario.tituloSecundario;
        if (this.solicitud !== undefined) {
            this.invoice = this.solicitud.invoices[0];

            if (this.invoice) {
                this.getPaymentByInvoice(this.solicitud?.invoices[0]._id);
            }
            const estados = this.solicitud.states;

            // Verificar si existe un objeto con numberState igual a 5
            const existeEstado5 = estados.some(estado => estado.numberState === 5);

            if (existeEstado5) {
                await this.createPaymentMacro(this.solicitud?._id)
            }
            
        }
        return this.solicitud = this.solicitudesService.getSolicitudSeleccionada();
    }

    async getPaymentByInvoice(invoice: string) {
        const payment = await this.paymentsService.getPaymentBySolicitud(invoice);
        this.tienePayment = payment == null || payment == undefined ? false : true;
    }

    async getLinkMercadoPago() {
        this.mercadoPagoLink = this.solicitud?.mercadoPagoPreference?.init_point;
    }

    async getFormMacroPagos() {
        this.macroPagosForm = (this.solicitud?.macroPagos != undefined || this.solicitud?.macroPagos != null) ? true : false;
    }

    getEstadoSolicitud(solicitud: Solicitud) {
        switch (solicitud.lastState.numberState) {

            case 0: // CARGAR FORMULARIO
                this.activarCargarFormulario = true;

                this.activarCargaDocumentosOpcionales = false;
                this.flagDocumentosCompleto = false;
                this.activarPagoFinal = false;

                this.estadoFormulario = this.iAppOfertas.formulario.pendiente;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.pendiente;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                break;

            case 1:  // DOCUMENTOS OPCIONALES
                this.activarCargaDocumentosOpcionales = true;
                this.activarCargaExtra = true;
                this.flagDocumentosCompleto = false;
                this.activarPagoFinal = false;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.pendiente;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                break;

            case 2: // DOCUMENTOS PARCIALES ENTREGADOS
                this.activarCargaDocumentosOpcionales = true;
                this.activarCargarFormulario = false;
                this.activarCargaExtra = true;
                this.flagDocumentosCompleto = false;
                this.activarPagoFinal = false;
                this.flagPreAprobacion = false;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.pendiente;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                break;

            case 3: // este estado es cuando entrego todo los documentos, es automatico
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargarFormulario = false;
                this.activarCargaExtra = true;
                this.flagDocumentosCompleto = true;
                this.activarPagoFinal = false;
                this.flagPreAprobacion = true;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                break;

            case 4: // PRE APROBADO
                this.flagPreAprobado = true;
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargarFormulario = false;
                this.activarCargaExtra = true;
                this.flagDocumentosCompleto = true; // completo la documentacion
                this.activarPagoFinal = false;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                break;


            case 5: // ACTIVAR  PAGO TOTAL
                this.activarPagoFinal = true;
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargarFormulario = false;
                this.activarCargaExtra = true;
                this.flagDocumentosCompleto = true; // completo la documentacion

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                break;

            case 6: // ESPERA DE COMPROBACION DE PAGO
                this.flagAprobado = false;
                this.activarPagoFinal = false;
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargarFormulario = false;
                this.activarCargaExtra = true;
                this.flagDocumentosCompleto = false; // completo la documentacion
                this.flagPreAprobacion = false;
                this.flagEsperaDeComprobacionPago = true;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                break;

            case 7: // APROBADO
                this.flagAprobado = true;
                this.activarPagoFinal = false;
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargarFormulario = false;
                this.activarCargaExtra = true;
                this.flagDocumentosCompleto = false; // completo la documentacion
                this.flagPreAprobacion = false;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.completo;

                break;

            case 8: // RECHAZADO
                this.activarPagoFinal = false;
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargarFormulario = false;
                this.activarCargaExtra = false;
                this.flagDocumentosCompleto = false; // completo la documentacion
                this.flagPreAprobacion = false;
                this.flagRechazada = true;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;

                break;

            case 9: // ANULADA
                this.activarPagoFinal = false;
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargarFormulario = false;
                this.activarCargaExtra = false;
                this.flagDocumentosCompleto = false; // completo la documentacion
                this.flagPreAprobacion = false;
                this.flagRechazada = false;
                this.flagAnulada = true;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;

                break;

            case 10: // EN ESPERA INICIO CUROS
                this.flagPreAprobado = false;
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargarFormulario = false;
                this.activarCargaExtra = true;
                this.flagDocumentosCompleto = true; // completo la documentacion
                this.activarPagoFinal = false;
                this.flagEsperaInicioCurso = true;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                break;


            default:

                this.activarPagoFinal = true;
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargarFormulario = false;
                this.activarCargaExtra = false;
                this.flagDocumentosCompleto = false;
                this.flagPreAprobacion = false;

                break;
        }

    }

    async getEstadoVariables() {
        if (this.getActividad() === undefined) {
            this.router.navigateByUrl('/');
            return;
        } else {
            await this.getActividad();
            //  await this.getPais();
            await this.getEstadoSolicitud(await this.getSoliciitud());
            await this.getLinkMercadoPago();
            this.getFormMacroPagos();
            await this.documentosAsolicitar(await this.getSoliciitud());
            await this.getModulos();
            await this.armarDescargasDeDocumentosUsuario(this.solicitud.documentosEstadosSolicitar);
            this.flagLoaded = true;
            console.log('this.solicitud ', this.solicitud);
        }

    }

    async armarDescargasDeDocumentosUsuario(documentos: DocumentosEstadosSolicitar[]) {
        // Construir un arreglo con los objetos que contienen enlaces de Google Drive
        documentos.forEach(doc => {
            const enlace = this.extraerEnlacesDrive(doc.documento.descripcion);
            if (enlace) {
                this.enlacesDrive.push(enlace);
            }
        });
    }

    async documentosAsolicitar(solicitud): Promise<DocumentosEstadosSolicitar[]> {
        this.documentosEstadosSolicitar = solicitud.documentosEstadosSolicitar;
        return this.documentosEstadosSolicitar;
    }

    /* Modal Documento  - Carga - pasajes - PC */
    openDialog(label: string, estado: number, idAdjunto: string): void {
        const dialogRef = this.dialog.open(UploadFileSolicitudComponent, {
            width: '800px',
            height: '600px',
            disableClose: true,
            data: {
                solicitud: this.solicitud,
                adjunto: idAdjunto,
                label,
                numberState: estado,
                labelButton: 'ACEPTAR',
                accept: 'application/pdf, image/jpeg, image/jpg, image/png'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataModal = result;
                this.reloadCurrentRoute();
            } else {
                return;
            }
        });
    }

    /* Modal Agregar Documento Extra */
    openDialogDocExtra(label: string, idAdjunto: string): void {
        const dialogRef = this.dialog.open(AgregarDocumentosExtraComponent, {
            width: '800px',
            height: '800px',
            disableClose: true,
            data: {
                solicitud: this.solicitud,
                adjunto: 'idAdjunto',
                label,
                labelButton: 'ACEPTAR',
                accept: 'application/pdf, image/jpeg, image/jpg, image/png'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            //   if (result) {
            //     // this.router.navigate(['solicitud']);
            //     // this.reloadCurrentRoute();
            //   } else  {
            //         return;
            //   }

        });
    }

    reloadCurrentRoute() {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['solicitud']);
            return;
        });
    }

    descargarContrato(idUsuario: string, adjunto: string, nombre: string) {
        FileSaver.saveAs(this.mediaOfertaLaboralFileUser(idUsuario, adjunto), nombre);
    }

    async getNombreAdjunto(idAdjunto): Promise<string> {
        return await this.actividadesService.getNombreAdjunto(idAdjunto);
    }

    async buscarContratoAdjuntoYdescargar(solicitud: Solicitud) {
        /* buscon adjunto mediante catalogo en colleccion de aduntos */
        let descargar: string;
        const objectCatalogo: any = await this.catalogoService.getFindByKey('ADJUNTO_CONTRATO');
        const adjuntoCatalogo = objectCatalogo.properties._idAdjunto;
        for (let index = 0; index < solicitud.adjuntos.length; index++) {
            const posInicial = 0;
            const longitud = solicitud.adjuntos[index].indexOf('.');
            const idAdjunto = solicitud.adjuntos[index].substring(posInicial, longitud);
            if (idAdjunto === adjuntoCatalogo) {
                descargar = solicitud.adjuntos[index];
                index = solicitud.adjuntos.length;
            }
        }
        /* descargo adjunto */
        const nombreArchivo = this.solicitud.usuario.nombrePersona + ' ' + this.solicitud.usuario.apellidoPersona;
        this.descargarContrato(this.solicitud.usuario._id, descargar, nombreArchivo);
    }

    mediaOfertaLaboralFileUser(idUsuario: string, adjunto: string) {
        const mediaOfertaLaboralFileUser = new MediaOfertaLaboralFileUserPipe();
        return mediaOfertaLaboralFileUser.transform(idUsuario, adjunto);
    }

    async actualizarEstadoSolicitud(_id: string, numberState: number, msj: string) {
        this.solicitudesService.updateSolicitudState(_id, numberState, false).then(async (solicitudUpdated: Solicitud) => {
            await this.solicitudesService.setSolicitudSeleccionada(solicitudUpdated);
            // this.reloadCurrentRoute();
            this.router.navigateByUrl('/cursos-perfil-alumno');
            this.guiMsjService.msjFormSubmit(msj);
        });
    }

    /* Paypal */

    statusChangesEstadoPagoPaypal() {
        // this.paypalService.setInitDefaultEstadoPago(false);
        this.estadoPago = this.paypalService.getEstadoPago().subscribe(async (estadoPago: boolean) => {
            if (estadoPago) {
                await this.procesarPago(await this.paypalService.getDetail());
                this.estadoPago.unsubscribe();
            }
        });
    }

    /* Transferencia */

    statusChangesEstadoPagoTransferencia() {
        this.estadoPago = this.bankTransferService.getEstadoPago().subscribe(async (estadoPago: boolean) => {
            if (estadoPago) {
                await this.procesarPagoTransferencia();
                this.estadoPago.unsubscribe();
            }
        });
    }

    // NO SE UTILIZA ESTE MEDOTO
    async procesarPago(data: any) {

        this.flagProcesandoPago = true;

        if (this.solicitud !== undefined) {

            if (this.solicitud.actividad.circuito === 1) {
                const payments: Payments = await this.paymentsService.loadPayment('PAYPAL', this.solicitud, data);

                // Pago con servicio payments
                this.paymentsService.postPyaments(payments).then(resp => {
                    if (resp) {
                        this.cursosService.setCursosAlumno(this.solicitud.cursos, this.solicitud._nro)
                        this.actualizarEstadoSolicitud(this.solicitud._id, this.iAppOfertas.estado_6.number, 'pagarSaldoCursosModulosOK');
                    } else {
                        this.guiMsjService.msjFormSubmit('pagarSaldoError');
                        console.log('error');
                    }
                });
            } else {

                if (this.solicitud.actividad.circuito === 2) {
                    this.solicitud.payment = await this.loadPayment('PAYPAL', data);
                    this.solicitudesService.postComprarSolicitud(await this.createDtoSolicitud(this.solicitud)).then(resp => {
                        if (resp) {
                            this.cursosService.setCursosAlumno(this.solicitud.cursos, this.solicitud._nro);
                            this.actualizarEstadoSolicitud(this.solicitud._id, this.iAppOfertas.estado_6.number, 'pagarSaldoCursosModulosOK');
                        } else {
                            this.guiMsjService.msjFormSubmit('pagarSaldoError');
                            console.log('error');
                        }
                        this.flagProcesandoPago = false;
                    });
                }
            }
        } else {
            return;
        }
    }


    async createDtoSolicitud(solicitud: Solicitud) {

        const solicitudDto: any = {
            _id: solicitud._id,
            usuario: solicitud.usuario._id,
            actividad: solicitud.actividad._id,
            pais: solicitud.pais._id,
            payment: solicitud.payment
        }

        return solicitudDto;
    }

    // NO SE UTILIZA ESTE METODO el comprobante queda a criterio del adminsitrador
    async procesarPagoTransferencia() {
        this.flagProcesandoPago = true;
        this.actualizarEstadoSolicitud(this.solicitud._id, this.iAppOfertas.estado_5.number, 'pagarSaldoOK');
    }

    setDescripcionPago() { // PONER EN SERVICIO  PAYPAL PARA NO DUPLICAR EL METODO
        this.description = 'Costo analisis administrativo $USD ' + this.actividad.costoAdministrativo
            + ' - Pre Inscripción: '
            + this.actividad.nombre;
    }

    loadPayment(tipo: string, data: object): any { // PONER EN SERVICIO PAYPAL PARA NO DUPLICAR EL METODO
        return {
            tipo,
            payment: {
                result: data
                //   result: { data, costoPagar }
            },
        };
    }

    getModulos(): Curso[] {
        this.modulos = this.solicitud.cursos;
        this.calculatTotalModulos();
        return this.modulos;
    }

    calculatTotalModulos() {
        this.totalModulosCosto = 0;
        this.modulos.forEach(m => this.totalModulosCosto = this.totalModulosCosto + m.precio);
        return this.totalModulosCosto;
    }

    extraerEnlacesDrive(descripcion: string): { texto: string, enlace: string } | null {
        const regex = /(https:\/\/[\S]+)/g;
        const matches = regex.exec(descripcion);
        if (matches && matches.length > 0) {
            const enlace = matches[0];
            const texto = descripcion.replace(regex, '').trim();
            return { texto, enlace };
        }
        return null;
    }

    getMercadoPago(): Boolean {
        return this.solicitudesService.getMercadoPagoActuvo();
    }

    getMacroPagos(): Boolean {
        return this.solicitudesService.getMacroPagosActivo();
    }

    async createPaymentMacro(solicitud: string) {
        this.loadingMacroPagos = true;
        this.solicitudesService.createPagoMacroPagos(solicitud).then((formHtml: string) => {
            // Inserta el formulario embebido dentro de un contenedor específico
            const container = document.getElementById('payment-container-macro');
            if (container) {
                container.innerHTML = formHtml;
                this.isPaymentProcessed = true;
                this.loadingMacroPagos = false;
            } else {
                console.error('Contenedor de formulario no encontrado');
                this.loadingMacroPagos = false;
            }
        }).catch((error) => {
            console.error('Error al procesar el pago', error);
            this.loadingMacroPagos = false;
        });
    }

}



