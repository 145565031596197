import { Router } from '@angular/router';
import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { OfertasSolicitudComponent } from '../ofertas-solicitud/ofertas-solicitud.component';
import { Solicitud } from '../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../../../../app.config/app.config-ofertas';
import { SolicitudesService } from '../../../../../services/ofertas-laborales/solicitudes.service';
import { PerfilesService } from '../../../../../services/perfiles/perfiles.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ofertas-listado',
  templateUrl: './ofertas-listado.component.html',
  styleUrls: ['./ofertas-listado.component.scss']
})
export class OfertasListadoComponent implements OnInit, AfterViewInit {

     /* Data Table*/
     @ViewChild(MatSort) sort: MatSort;
     @ViewChild(MatPaginator) paginator: MatPaginator;
     displayedColumns: string[] =
     [
        'tramite', 'paisNombre', 'actividad', 'fechaEstado', 'estado', 'solicitante', 'email', 'identificacion', 'id'
     ];
     dataSource: any;
     solicitudes: Solicitud [];
     /* Fin Data Table*/

     flagLoaded = true;
     selectEstado: number;
     flagLoadedModal = true;
     activarButton = true;
     estado: number;

     newState: Subscription;


     constructor(
         @Inject(APP_CONFIG_OFERTAS) public iAppOfertas: IAppOfertas,
         private solicitudesService: SolicitudesService,
         private router: Router,
         public dialog: MatDialog,
         private perfilesService: PerfilesService,

     ) { 
        this.newState = this.solicitudesService.getsetNewStater().subscribe(resp => {
            this.loadDataTable(this.estado);
            this.newState = resp;
         });
     }

     ngOnInit(): void {
        this.loadDataPage();
        this.perfilesService.allowedByUserLogon(this.router.url);
     }

     async ngAfterViewInit() {     }


     async loadDataPage() {
        this.selectEstado = await this.solicitudesService.getEstadoSolicitudSeleccionado();
        if (this.selectEstado >= 0) {
            await this.verSolicitudes(this.selectEstado);
        } else {
            this.selectEstado = null;
        }
     }

     async verSolicitudes(estado: number) { 
        this.estado = estado;       
        this.flagLoaded = false;        
        this.loadDataTable(estado);
     }

     async verSolicitud(solicitud) {
        this.flagLoadedModal = false;
        this.solicitudesService.getSolicitudById(solicitud.id).then(( (sol: Solicitud) => {
              this.solicitudesService.setSolicitudSeleccionada(sol);
              // this.router.navigateByUrl('/ofertas-solicitud');
              // modal
              this.openDialogModaSolicitud();
        }));
     }

    /* Modal - Carga Solicitud */
    openDialogModaSolicitud(): void {
        const dialogRef = this.dialog.open(OfertasSolicitudComponent, {
            width: '900px',
            height: '1000px',
            disableClose: true,
        });
        this.flagLoadedModal = true;
        dialogRef.afterClosed().subscribe(async result => {});
        return;
    }



     // tslint:disable-next-line:variable-name
     async loadDataTable(estado: number) {
        if (estado === null) {
            estado = this.estado;
        }
        this.solicitudesService.getSolicitudesByEstado(estado).then( ( solds: Solicitud[] ) => {
            this.dataSource = new MatTableDataSource(solds);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.flagLoaded = true;
        });
     }

     applyFilter(event: Event) {
         const filterValue = (event.target as HTMLInputElement).value;
         this.dataSource.filter = filterValue.trim().toLowerCase();
     }

}
