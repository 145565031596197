<div class="contact-area ptb-100">

  <h1 mat-dialog-title>Confirmación</h1>
  <div mat-dialog-content>
    <p>Por favor, verifica la información antes de enviar el formulario:</p>
    <div class="confirmation-details">
      <div class="confirmation-item">
        <span>Nacionalidad:</span>
        <span>{{ formData.paisNacimiento }}</span>
      </div>
      <div class="confirmation-item">
        <span>Nombre:</span>
        <span>{{ formData.nombreApellido }}</span>
      </div>
      <div class="confirmation-item">
        <span>Email:</span>
        <span>{{ formData.email }}</span>
      </div>
      <div class="confirmation-item">
        <span>Tipo de Documento:</span>
        <span>{{ formData.tipoIdentificacion }}</span>
      </div>
      <div class="confirmation-item">
        <span>Número de Documento:</span>
        <span>{{ formData.numeroDocumento }}</span>
      </div>
      <div class="confirmation-item">
        <span>Nacionalidad:</span>
        <span>{{ formData.paisNacimiento }}</span>
      </div>
      <div class="confirmation-item">
        <span>Fecha de Nacimiento:</span>
        <span>{{ formData.fechaNacimiento }}</span>
      </div>
      <div class="confirmation-item">
        <span>Teléfono:</span>
        <span>{{ formData.telefono }}</span>
      </div>
      <div class="confirmation-item">
        <span>Enfermedad:</span>
        <span>{{ formData.enfermedad ? 'Sí' : 'No' }}</span>
      </div>
      <div class="confirmation-item">
        <span>Título Secundario:</span>
        <span>{{ formData.tituloSecundario ? 'Sí' : 'No' }}</span>
      </div>
      <div class="confirmation-item">
        <span>¿Otra formación?:</span>
        <span>{{ formData.otraFormacion }}</span>
      </div>
      <!-- Agrega más campos según sea necesario -->
    </div>
  </div>
  <div mat-dialog-actions
      class="confirmation-buttons">
    <button mat-button
            color="warn"
            (click)="cancel()">Cancelar</button>
    <button mat-raised-button
            color="primary"
            (click)="confirm()">Confirmar</button>
  </div>
</div>