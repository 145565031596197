import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from 'src/app/interfaces/interface-bakend/response';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(private httpClient: HttpClient,
              private authService: AuthService) { }

    getServerTime(){
    return new Promise(async resolve => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get(`${ URL_BACKEND}/app-config/getServerTime`,{ headers }).subscribe((result: Response) =>{
        resolve(result.response)
      });
    })

    
  }
}
