<app-loading *ngIf="!flagCargado" class="m-5"></app-loading><br>
<div *ngIf="flagCargado" class="animate__animated animate__fadeIn">
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/dashboard">{{ idioma | translate: "navbar.UL-INICIO" | async}}</a></li>
                    <li><a routerLink="/cursos-disponibles-compra">{{ idioma | translate: "navbar.UL-CURSOS" | async}}</a></li>
                    <li>{{ curso.nombre }}</li>
                </ul>
                <h2>{{ curso.nombre }}</h2>
            </div>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>

    <div class="courses-details-area pb-100">
        <div class="courses-details-image">
            <img src="assets/img/courses/banner2_curso.png" alt="image">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="courses-details-desc">
                        <ngx-tabset>
                            <ngx-tab [tabTitle]=" idioma | translate: 'curso.DESCRIPCION-GENERAL' | async">
                                <div class="courses-overview">
                                    <h3>{{ idioma | translate: "curso.DESCRIPCION-CURSO" | async}}</h3>
                                    <p>{{ curso.descripcionGeneral }}</p>
                                </div>
                            </ngx-tab>
                            <ngx-tab [tabTitle]=" idioma | translate: 'curso.CONTENIDO' | async">
                                <div class="courses-curriculum" *ngFor="let materia of curso.materias">
                                    <h3>{{ materia.nombre }}</h3>
                                    <ul>

                                        <li *ngFor="let leccion of materia.lecciones">
                                            <a class="popup-youtube d-flex justify-content-between align-items-center">
                                                <span class="courses-name">{{ leccion.descripcion }}</span>
                                                <span class="status locked"><i class="flaticon-password"></i></span>
                                            </a>
                                        </li>
                                    </ul>

                                </div>
                            </ngx-tab>
                            <ngx-tab [tabTitle]=" idioma | translate: 'curso.PROFESORES' | async">
                                <div class="courses-instructor">
                                    <div class="single-advisor-box" *ngFor="let profesor of curso.profesores">

                                        <app-profesor-tarjeta-presentacion [profesor]="profesor"></app-profesor-tarjeta-presentacion>

                                    </div>
                                </div>
                            </ngx-tab>
                        </ngx-tabset>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="courses-details-info">
                        <img [src]=" 1 | mediaCursosPipe: curso.imagen  | safeResourceUrl" [alt]="curso.imagen" width="100%" height="auto">
                        <!--<video class="animate_animated animate_fadeIn" width="305" height="210" controls>
                        <source src="assets/img/skiandorra.mp4" type="video/mp4">
                        </video>-->
                        <!--div class="animate__animated animate__fadeIn">
                            <iframe [src]="( 2 | mediaCursosPipe: curso.enlace.mp4 )| safe" type="video/mp4" style="width: auto; height: 210px;"></iframe>
                        </div-->
                        <!-- <div class="image">
                            <img src="assets/img/courses/img1.jpg" alt="image">
                            <iframe width="305" height="200" src="https://www.youtube.com/embed/5b6B7CZoD_w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <a target="_blank" href="https://www.youtube.com/embed/5b6B7CZoD_w" class="link-btn popup-youtube"></a>

                            <div class="content">
                                <i class="flaticon-play"></i>
                                <span>Vista previa</span>
                            </div>
                        </div>
                         -->
                        <ul class="info">
                            <li class="price">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><i class="flaticon-tag"></i>{{ idioma | translate: "curso.COSTO" | async}}</span> ${{ curso.precio}}
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><i class="flaticon-time"></i>{{ idioma | translate: "curso.DURACION" | async}} </span>{{ curso.cantidadHoras }} horas
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><i class="flaticon-web"></i>{{ idioma | translate: "curso.INCRIPTOS" | async}} </span> {{ curso.inscriptos}} estudiantes
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><i class="flaticon-lock"></i>{{ idioma | translate: "curso.FECHA-INICIO" | async}}</span>{{ curso.fechaInicio | date:"dd/MM/yy" }}
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><i class="flaticon-lock"></i>{{ idioma | translate: "curso.FECHA-FIN" | async}} </span>{{ curso.fechaFin | date:"dd/MM/yy" }}
                                </div>
                            </li>
                        </ul>
                        <!-- COMENTADO PROVISORIAMENTE
                        <div class="btn-box">
                            <a style="color:white !important" (click)="cursoComprar()" class="default-btn"><i class="flaticon-shopping-cart"></i>{{ idioma | translate: "curso.COMPRAR-BTN" | async}}<span></span></a>
                        </div> -->
                        <div class="courses-share">
                            <div class="share-info">
                                <span>{{ idioma | translate: "curso.COMPARTE" | async}}<i class="flaticon-share"></i></span>
                                <ul class="social-link">
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="courses-area bg-f8f9f8 pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>{{ idioma | translate: "curso.MAS-CURSOS" | async}}</h2>
            </div>

            <div class="row justify-content-center">
                <app-cursos-listar></app-cursos-listar>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <a style="color:white !important" routerLink="/cursos-disponibles-compra" class="default-btn"><i class="flaticon-webinar"></i>{{ idioma | translate: "curso.VER-TODOS" | async}}<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>