<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><a routerLink="/examenes-alumno">Mis Examenes</a></li>
                <li *ngIf="this.examen">{{examen.titulo}}</li>
            </ul>
            <h2>Examen introducción</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="features-area pb-70 mt-5 animate__animated animate__fadeIn">
    
    <div class="container" *ngIf="this.examen">
        <mat-card>   
            <div class="section-title">
                <span class="sub-title">Fecha Publicación {{ examen.fechaPublicacion | date:'short' }}</span>
                <h2>{{ examen.titulo }}​</h2>
                <p>{{ examen.descripcion }}</p>
                <div class="alert alert-warning" role="alert" *ngIf="this.intento && !this.aprobado">
                    <p>Ya ha realizado un intento de este examen</p>
                </div>
                <div class="alert alert-success" role="alert" *ngIf="this.aprobado">
                    <p>Examen Aprobado con un puntaje de <strong>{{porcentajeAprobado}}</strong></p>
                </div>     
                <a [routerLink]="['/examen-resultado']" *ngIf="this.aprobado || this.intento"><button mat-raised-button mat-button color="primary">Ver resultado de examen</button></a>
            </div>     
            <div style="text-align: center;" *ngIf="examen.imgPortada != ''">
                <img [src]=" 1 | mediaExamenesPipe: examen.imgPortada  | safe" [alt]="examen.imgPortada" width="300px">
            </div>
            <br><br>
            <div class="row" style="margin-top: 5px;">
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon">                
                            <i class="flaticon-shield-1"></i>
                        </div>
                        <h3>Aprobación</h3>
                        <p>Apruebas con un <strong>{{examen.porcentajeAprobacionMinimo}}%</strong> de respuestas correctas, sobre un total de <strong>{{cantPreguntas}}</strong> preguntas</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-time"></i>
                        </div>          
                        <ng-container *ngIf="examen.tieneLimiteTiempo === true; else elseTemplate">
                            <h3>Tiempo limitado</h3>
                            <p>Tienes <strong *ngIf="examen.limiteTiempo.hours != '0'">{{ examen.limiteTiempo.hours }} hs </strong> <strong>{{ examen.limiteTiempo.minutes }} minutos</strong> para completar el examen</p>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <h3>Sin límite de tiempo</h3>      
                        </ng-template>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-calendar"></i>
                        </div>
                        <h3>Fecha límite</h3>
                        <ng-container *ngIf="examen.fechaVencimiento != null; else elseTemplate">
                            <p><strong>{{ examen.fechaVencimiento | date:'short' }}</strong></p>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <p>Sin límite</p>
                        </ng-template>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-time-left"></i>
                        </div>
                        <h3>Reintentos</h3>
                        <ng-container *ngIf="examen.intentos != null; else elseTemplate">
                            <p>Podrás realizar el examen en <strong>{{ examen.intentos }}</strong> intento/s</p>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <p>Podrás tomar el examen la cantidad de veces necesarias para aprobar</p>
                        </ng-template>
                        <p *ngIf="contadorIntentos"><strong>{{contadorIntentos}}</strong> intento/s ya realizados.</p>
                    </div>
                </div>

                <div *ngIf="isLoading" class="loading-spinner-overlay">
                    <div class="loading-spinner-container">
                        <div class="loading-spinner"></div>
                        <p>Procesando datos para examen...</p>
                    </div>
                </div>
                <div *ngIf="isLoadingPregunta" class="loading-spinner-overlay">
                    <div class="loading-spinner-container">
                        <div class="loading-spinner"></div>
                        <p>Espere por favor...</p>
                    </div>
                </div>
                
                <!-- El resto del código HTML del componente -->

                
                <!-- <mat-dialog-actions align="center">
                    <a [routerLink]="['/examenes-alumno']"><button style="margin-right: 5px;" mat-raised-button mat-button color="accent">Salir</button></a>
                  <button (click)="comenzar()" [disabled]="!btnComenzar || progress" mat-raised-button mat-button color="primary">Comenzar <mat-icon aria-hidden="false" aria-label="Example home icon">play_arrow</mat-icon></button>
                </mat-dialog-actions> -->
                <mat-dialog-actions align="center">
                    <a [routerLink]="['/examenes-alumno']">
                        <button style="margin-right: 5px;" mat-raised-button mat-button color="accent">Salir</button>
                    </a>
                    <button (click)="comenzar()" [disabled]="!btnComenzar || progress" mat-raised-button mat-button color="primary">
                        Comenzar <mat-icon aria-hidden="false" aria-label="Example home icon">play_arrow</mat-icon>
                    </button>
                </mat-dialog-actions>

                <br>
                <div style="margin-top:10px;">
                    <mat-progress-bar mode="indeterminate" *ngIf="progress"></mat-progress-bar>
                </div>
            </div>
        </mat-card>
    </div>
    
</div>