<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li>Invoices</li>
            </ul>
            <h2>Invoices</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>



<div class="profile-area pb-70 contenido-tabla">
    <div class="container">

        <div class="profile-courses-quizzes">
            <h3>Listado de Invoices</h3>


            <!-- Mensaje Informativo -->
            <div class="alert alert-info" role="alert">
                <strong>Primero buscar por fecha y luego aplicar filtro</strong>
            </div>

            <mat-card class="search-card">
                <div class="container mt-4">
                    <mat-form-field class="mr-2">
                        <mat-label>Desde</mat-label>
                        <input matInput [matDatepicker]="pickerDesde" placeholder="Seleccione una fecha"
                            [(ngModel)]="fechaDesde">
                        <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDesde></mat-datepicker>
                    </mat-form-field>

                    <!-- Espacio entre los campos de búsqueda -->
                    <div style="width: 20px;"></div>

                    <mat-form-field class="mr-2">
                        <mat-label>Hasta</mat-label>
                        <input matInput [matDatepicker]="pickerHasta" placeholder="Seleccione una fecha"
                            [(ngModel)]="fechaHasta">
                        <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
                        <mat-datepicker #pickerHasta></mat-datepicker>
                    </mat-form-field>

                    <button mat-raised-button color="primary" class="ml-2" (click)="buscarPorFechas()">Buscar</button>

                </div>


                <div class="container mt-4">
                    <label>Resultados encontrados: <strong>{{dataSourceLength}}</strong></label>
                </div>

                <br>

                <div *ngIf="invoices.length > 0">
                    <div class="ml-2 spacing"></div>
                    <mat-checkbox class="ml-2" [(ngModel)]="applyFilterFlagNoFacturado"
                        (change)="filtrarPagadoOComprobanteNoFacturado()">Pagado - NO facturado.
                    </mat-checkbox>
                    <div style="width: 20px;"></div>
                    <mat-checkbox class="ml-2" [(ngModel)]="applyFilterFlagFacturado"
                        (change)="filtrarPagadoOComprobanteFacturado()">Pagado - Facturado.
                    </mat-checkbox>
                    <div style="width: 20px;"></div>
                    <mat-checkbox class="ml-2" [(ngModel)]="applyNoPagadoFilterFlag" (change)="filtrarNoPagado()">No
                        Pagado
                    </mat-checkbox>
                    <div style="width: 20px;"></div>
                    <mat-checkbox class="ml-2" [(ngModel)]="applyFilterFlagPagadoMP" (change)="filtrarPagadoMP()">Pagado
                        MP.
                    </mat-checkbox>
                    <div style="width: 20px;"></div>
                    <mat-checkbox class="ml-2" [(ngModel)]="applyFilterFlagPagadoTransferencia"
                        (change)="filtrarPagadoTransferencia()">Pagado Transferencia
                    </mat-checkbox>
                    <div style="width: 20px;"></div>
                    <mat-checkbox class="ml-2" [(ngModel)]="applyFilterFlagPagadoMacro" 
                        (change)="filtrarPagadoMacro()">Pagado Macro.
                    </mat-checkbox>

                </div>



            </mat-card>

            <div *ngIf="flagLoaded" class="row text-center animate__animated animate__fadeIn">
                <div class="col">
                    <i class="fa fa-graduation-cap fa-4x fa-pulse" aria-hidden="true" style="color:#fe4a55"></i>
                </div>
            </div>

            <mat-form-field>
                <mat-label>Filtro</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Actividad..." autocomplete="off">
            </mat-form-field>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <!-- Column -->
                <ng-container matColumnDef="nro">
                    <th width="5%;" mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Solicitud</th>
                    <td width="5%;" mat-cell *matCellDef="let element"> <strong># {{element.nro}}</strong> </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="persona">
                    <th width="15%;" mat-header-cell *matHeaderCellDef mat-sort-header> Persona </th>
                    <td width="15%;" mat-cell *matCellDef="let element">{{element.persona | nombreExiste}} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="email">
                    <th width="15%;" mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                    <td width="15%;" mat-cell *matCellDef="let element">{{element.email | nombreExiste}} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="fecha">
                    <th width="10%;" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                    <td width="10%;" mat-cell *matCellDef="let element"> {{element.fecha | date:'dd/MM/yyyy' }} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="actividad">
                    <th width="30%;" mat-header-cell *matHeaderCellDef mat-sort-header> Actividad </th>
                    <td width="30%;" mat-cell *matCellDef="let element">{{element.actividad}} </td>
                </ng-container>

                <!-- Column for Estado Macro -->
                <ng-container matColumnDef="pagadoMacro">
                    <th width="15%;" mat-header-cell *matHeaderCellDef mat-sort-header> Estado Macro </th>
                    <td width="15%;" mat-cell *matCellDef="let element">
                        <!-- If pagado is true and tipoPayment is MACRO_PAGOS -->
                        <div *ngIf="element.pagado == true && element.tipoPayment == 'MACRO_PAGOS'; else impagoMacro">
                            <span class="badge bg-success" style="margin-right:10px;">Macro Pagado</span>
                        </div>
                        <!-- Default case when the condition is not met -->
                        <ng-template #impagoMacro>
                            <span class="badge bg-danger" style="margin-right:10px;">Macro Impago</span>
                        </ng-template>
                    </td>
                </ng-container>

                <!-- Column for Estado MP -->
                <ng-container matColumnDef="pagado">
                    <th width="15%;" mat-header-cell *matHeaderCellDef mat-sort-header> Estado MP </th>
                    <td width="15%;" mat-cell *matCellDef="let element">
                        <!-- If pagado is true and tipoPayment is MERCADO_PAGO -->
                        <div *ngIf="element.pagado == true && element.tipoPayment == 'MERCADO_PAGO'; else impagoMP">
                            <span class="badge bg-success" style="margin-right:10px;">MP Pagado</span>
                        </div>
                        <!-- Default case when the condition is not met -->
                        <ng-template #impagoMP>
                            <span class="badge bg-danger" style="margin-right:10px;">MP Impago</span>
                        </ng-template>
                    </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="comprobanteTransferencia">
                    <th width="20%;" mat-header-cell *matHeaderCellDef mat-sort-header> Comp. Tranf </th>
                    <td width="20%;" mat-cell *matCellDef="let element">

                        <!-- Forzamos que cuando pagado es true, siempre muestre SIN CP TRANS -->
                        <div style="margin-right:10px;" *ngIf="element.pagado == true || element.comprobanteTransferencia == false">
                            <span class="badge bg-danger">SIN CP TRANS.</span>
                        </div>
                        <div style="margin-right:10px;" *ngIf="element.pagado == false && element.comprobanteTransferencia == true">
                            <span class="badge bg-success">CP TRANS.</span>
                        </div>
                   <!-- <div *ngIf="element.comprobanteTransferencia == true"><span class="badge bg-success">CP TRANS.</span></div>
                        <div *ngIf="element.comprobanteTransferencia == false"><span class="badge bg-danger">SIN CP TRANS.</span></div> -->

                        <!-- <div style="margin-right:10px;" *ngIf="element.comprobanteTransferencia == true"><span class="badge bg-success">CP TRANS.</span></div> -->
                        <!-- <div style="margin-right:10px;" *ngIf="element.comprobanteTransferencia == false"><span class="badge bg-danger">SIN CPTRANS.</span></div> -->

                    </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="link">
                    <th width="15%;" mat-header-cell *matHeaderCellDef mat-sort-header> Link Pago</th>
                    <td width="15%;" mat-cell *matCellDef="let element">
                        <button mat-icon-button matTooltip="Copiar enlace" (click)="copiarEnlace(element.link)">
                            <mat-icon>content_copy</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="importeTotal">
                    <th width="30%;" mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                    <td width="30%;" mat-cell *matCellDef="let element"> {{element.importeTotal | currency:'$'}} </td>
                </ng-container>


                <!-- Columna C.MP -->
                <ng-container matColumnDef="invoiceIDmp">
                    <th width="20%;" mat-header-cell *matHeaderCellDef mat-sort-header> C.MP </th>
                    <td width="20%;" mat-cell *matCellDef="let element">
                        <mat-icon *ngIf="element.pagado == true" (click)="verInvoiceMp(element.invoiceID)"
                            color="accent">credit_card</mat-icon>
                        <mat-icon *ngIf="element.pagado == false" color="disabled">credit_card</mat-icon>
                    </td>
                </ng-container>

                <!-- Columna C.TR -->
                <ng-container matColumnDef="invoiceIDtr">
                    <th width="15%;" mat-header-cell *matHeaderCellDef mat-sort-header> C.TR </th>
                    <td width="15%;"  mat-cell *matCellDef="let element">
                        <mat-icon *ngIf="element.comprobanteTransferencia == true" (click)="verInvoiceBankTransfer(element.idComprobanteTr,element.invoiceID )" color="accent"><i class="fa fa-file-o" aria-hidden="true"></i></mat-icon>
                        <mat-icon *ngIf="element.comprobanteTransferencia == false" color="disabled"><i class="fa fa-file-o" aria-hidden="true"></i></mat-icon>                        
                    </td>
                </ng-container>


                <!-- Columna de facturado -->
                <!-- <ng-container matColumnDef="facturado">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> F </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox *ngIf="element.pagado || element.comprobanteTransferencia" (change)="updateInvoiceFacturado(element)" [(ngModel)]="element.facturado"></mat-checkbox>
                    </td>
                </ng-container> -->

                <ng-container matColumnDef="facturado">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fact. </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox
                            *ngIf="element.pagado || element.comprobanteTransferencia || element.efectivo || element.cheque"
                            (change)="updateInvoiceFacturado(element)" [(ngModel)]="element.facturado">
                        </mat-checkbox>
                    </td>
                </ng-container>


                <!-- Columna de facturado -->
                <ng-container matColumnDef="efectivo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ef. </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox (change)="updateInvoiceEfectivo(element)"
                            [(ngModel)]="element.efectivo"></mat-checkbox>
                    </td>
                </ng-container>

                <!-- Columna de cheque -->
                <ng-container matColumnDef="cheque">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Che. </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox (change)="updateInvoiceCheque(element)"
                            [(ngModel)]="element.cheque"></mat-checkbox>
                    </td>
                </ng-container>

                <!-- Columna Aprobar -->
                <ng-container matColumnDef="aprobar">
                    <th width="10%;" mat-header-cell *matHeaderCellDef mat-sort-header> Aprobar </th>
                    <td width="10%;" mat-cell *matCellDef="let element">
                        <mat-slide-toggle color="primary" [checked]="element.lastState === 7"
                            [disabled]="element.lastState === 7" (change)="confirmarAprobar($event, element)">
                            <mat-icon>done</mat-icon>
                        </mat-slide-toggle>
                    </td>
                </ng-container>

                <!-- Columna En Espera -->
                <ng-container matColumnDef="enEspera">
                    <th width="10%;" mat-header-cell *matHeaderCellDef mat-sort-header> En Espera</th>
                    <td width="10%;" mat-cell *matCellDef="let element">
                        <mat-slide-toggle color="primary"
                            [checked]="element.lastState === 7 || element.lastState === 10"
                            [disabled]="element.lastState === 7 || element.lastState === 10"
                            (change)="confirmarEnEspera($event, element)">
                            <mat-icon>hourglass_empty</mat-icon>
                        </mat-slide-toggle>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
                    [ngClass]="{'green-row': row.facturado}"></tr>

            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"></mat-paginator>

        </div>
    </div>
</div>

=<!-- Loading Spinner -->
<div *ngIf="isLoading" class="loading-overlay">
    <mat-progress-spinner [diameter]="40" mode="indeterminate"></mat-progress-spinner>
</div>


