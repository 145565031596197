import { Component, OnInit, Input } from '@angular/core';
import { Curso } from '../../../../interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { StorageService } from '../../../../services/shared/storage.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
  selector: 'app-curso-tarjeta',
  templateUrl: './curso-tarjeta.component.html',
  styleUrls: ['./curso-tarjeta.component.scss']
})
export class CursoTarjetaComponent implements OnInit {
  @Input() curso: Curso;
  @Input() flagCursoAlumno = false;
  @Input() video: false;
  @Input() img: false;
  @Input() price: false;
  idioma: Subscription;

  profesoresCurso: any[] = [];

  constructor(
    private cursosService: CursosService,
    private translatorService: Translators,
  ) {
      this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        this.idioma = resp;
      });
   }

  ngOnInit(): void {
    this.getProfesores();
    if (this.flagCursoAlumno){
    } else{
    }
  }

  navCtrlDescripcionCurso(curso: Curso){
    this.cursosService.setCursoSeleccionado(curso);
  }

  async getProfesores(){
    const profesores = await this.curso.profesores;
    profesores.forEach( async (element: any) => {
      await this.cursosService.getProfesorFindOne(element).then((profes: any) => {
        if (profes !== '' && profes != null) {
          this.profesoresCurso.push(profes.usuario);
        }
      });
    });
  }

}
