import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, MinLengthValidator, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MediaService } from '../../../../services/shared/media.service';
import { Materia } from '../../../../interfaces/interface-bakend/cursos/materia.interface';
import { MateriasService } from '../../../../services/cursos/materias.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { Profesor } from 'src/app/interfaces/interface-bakend/cursos/profesor.interface';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-catedra-alta',
  templateUrl: './catedra-alta.component.html',
  styleUrls: ['./catedra-alta.component.scss'],
})
export class CatedraAltaComponent implements OnInit {

  form: FormGroup;
  currentDate = new Date();
  cursos: Curso[] = [];
  materias: Materia[] = [];
  profesores: Profesor[] = [];
  catedras: any[] = [];
  selectCurso: string;
  flagProcesando = false;

  displayedColumns: string[] =
  [
    'curso', 'materia', 'profesor', 'orden', 'id'
  ];

  constructor(
      private cursosService: CursosService,
      private materiasService: MateriasService,
      private guiMsjService: GuiMsjService,
      ) {
      this.createForm();
      this.getCursos();
      this.getMaterias();
      this.getProfesores();
  }

  ngOnInit(): void {}

  async seleccionarCurso(id: string){
    if (id != null ){
      this.flagProcesando = true;
      await this.cursosService.getCatedras(id).then((catedras: any[]) => {
        this.catedras = catedras;
        this.flagProcesando = false;
      });
    }
  }

  async getCursos(){
    this.cursosService.getCursos().then((cursos: Curso[]) => {
      this.flagProcesando = false;
      this.cursos = cursos;
    });
  }

  async getMaterias(){
    this.materiasService.getMaterias().then((materias: Materia[]) => {
      this.materias = materias;
    });
  }

  async getProfesores(){
    await this.cursosService.getProfesores().then( (profesores: Profesor[]) => {
      this.profesores = profesores;  
    });
  }

  createForm() {
    this.form = new FormGroup({
        curso: new FormControl('', [ Validators.required]),
        materia: new FormControl('', [ Validators.required]),
        profesor: new FormControl('', [ Validators.required]),
        orden: new FormControl('', [ Validators.required, Validators.min(1)]),
    });
  }

  async post(){
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.cursosService.postMateriaCurso(await this.setDataForma()).then( (catedra: any) => {
      this.seleccionarCurso(catedra.curso);
    });

  }

  async borrarMateria(id: string){
    this.flagProcesando = true;
    await this.cursosService.borrarMateria(id).then(( mostrar: any ) => {
      this.seleccionarCurso(mostrar.curso);
      this.flagProcesando = false;
    });
  }

  async setDataForma(): Promise<any>{
    const data: any = {
      curso: this.form.value.curso,
      materia: this.form.value.materia,
      profesor: this.form.value.profesor,
      ordenMateria: this.form.value.orden,
    };
    return data;
  }
}

