<div class="courses-details-desc">
    <ngx-tabset>
        <ngx-tab [tabTitle]=" idioma | translate: 'oferta-laboral.TAB-DESCRIPCION-GRAL' | async">
            <div class="courses-overview">
                <h3>{{ idioma | translate: "oferta-laboral.DETALLE-DESCRIPCION" | async}}</h3>
                <p style="text-align: justify;" class="angular-with-newlines">
                    {{ actividad.descripcion }}
                </p>

                <p style="text-align: justify;" class="angular-with-newlines">
                    {{ actividad.detalle }}
                </p>

                <h3>{{ idioma | translate: "oferta-laboral.DETALLE-REQUISITOS" | async}}</h3>
                <p style="text-align: justify;" class="angular-with-newlines">
                    {{ actividad.requisitos }}
                </p>

            </div>
        </ngx-tab>

        <ngx-tab [tabTitle]=" idioma | translate: 'oferta-laboral.TAB-DOCUMENTACION' | async">
            <div class="courses-instructor">
                <div class="courses-overview">
                    <h3>{{ idioma | translate: "oferta-laboral.DETALLE-DOCUMENTACION-REQUERIDA" | async}}</h3>
                    <ul>
                        <li>
                            <p style="text-align: justify;"><strong>{{ actividad.documentacionRequerida }}</strong></p>
                        </li>
                    </ul>
                </div>
                <div class="courses-overview">
                    <h3>{{ idioma | translate: "oferta-laboral.DETALLE-DOCUMENTACION-DETALLE" | async}}</h3>
                    <ul *ngFor="let doc of actividad.documentosEstadosSolicitar">
                        <li>
                            <p style="text-align: justify;" class="angular-with-newlines"><strong>{{ doc.documento.nombre }}</strong></p>
                            <p>{{doc.documento.descripcion}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </ngx-tab>

        <ngx-tab [tabTitle]="idioma | translate: 'oferta-laboral.TAB-IMPORTANTE' | async">
            <div class="courses-instructor">
                <div class="courses-overview">
                    <h3>{{ idioma | translate: "oferta-laboral.DETALLE-INFO-IMPORTANTE" | async}}</h3>
                    <ul>
                        <li>
                            <p style="text-align: justify;" class="angular-with-newlines"><strong>{{ actividad.importante }}</strong></p>
                        </li>
                    </ul>
                </div>
            </div>
        </ngx-tab>

        <ngx-tab [tabTitle]=" idioma | translate: 'oferta-laboral.TAB-FINALIZANDO' | async">
            <div class="courses-instructor">
                <div class="courses-overview">
                    <h3>{{ idioma | translate: "oferta-laboral.DETALLE-FINALIZAR" | async}}</h3>
                    <ul>
                        <li>
                            <p style="text-align: justify;" class="angular-with-newlines"><strong>{{ actividad.finalizando }}</strong></p>
                        </li>
                    </ul>
                </div>
            </div>
        </ngx-tab>

    </ngx-tabset>
</div>