import { Component, Input, OnInit } from '@angular/core';
import { Solicitud, SolicitudActividad, SolicitudCreate } from 'src/app/interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { SolicitudesService } from '../../../../../services/ofertas-laborales/solicitudes.service';
import Swal from 'sweetalert2';
import { MailerService } from 'src/app/services/shared/mailer.service';

@Component({
  selector: 'app-modificar-actividades',
  templateUrl: './modificar-actividades.component.html',
  styleUrls: ['./modificar-actividades.component.scss']
})
export class ModificarActividadesComponent implements OnInit {

  @Input() solicitud: any; 
  cursos: Curso[] = [];
  flagLoaded = false;
  solicitudActividadesSeleccionadas: SolicitudActividad[];
  solicitudActividadesNuevas: SolicitudActividad[] = [];
  isChecked = false;

  constructor(
    private cursosService: CursosService,
    private solicitudesService: SolicitudesService,
    private mailerService : MailerService,
  ) { }

  ngOnInit(): void {
    this.getCursosActividades(this.solicitud);
    this.flagLoaded = true;
  }

  async getCursosActividades(solicitud: Solicitud) {
    const cursoPromises: Promise<any>[] = solicitud.cursos.map((element: Curso) => {        
      if (element._id == undefined && element ) {
        return this.cursosService.getCursoFindOne(String(element));
      }  else {
        return this.cursosService.getCursoFindOne(element._id);
      }
    });

    try {
      this.cursos = await Promise.all(cursoPromises);
      this.solicitudActividadesSeleccionadas = solicitud.actividades;

      console.log('this.cursos', this.cursos)
      console.log('this.solicitudActividadesSeleccionadas', this.solicitudActividadesSeleccionadas)
      
      this.cursos.forEach(curso => {
        curso.actividades.forEach((actividad: any) => {
          actividad.seleccionada = this.isActividadSeleccionada(curso._id, actividad._id);
        });
      });

      
    } catch (error) {
      console.error('Error al obtener los cursos:', error);
    }
  }
      
  isActividadSeleccionada(cursoId: string, actividadId: string): boolean {
    return this.solicitudActividadesSeleccionadas.some(item => item.curso === cursoId && item._id === actividadId);
  }
  

  getActividadesPorCurso(): { [cursoId: string]: any[] } {
    const actividadesPorCurso: { [cursoId: string]: any[] } = {};

    this.solicitudActividadesSeleccionadas.forEach(actividad => {
      const cursoId = actividad.curso;
      if (!actividadesPorCurso[cursoId]) {
        actividadesPorCurso[cursoId] = [];
      }
      actividadesPorCurso[cursoId].push(actividad);
    });

    return actividadesPorCurso;
  }

  async cambiarActividad() {
    this.solicitudActividadesNuevas = [];

    this.cursos.forEach(curso => {
      curso.actividades.forEach((actividad: any) => {
        if (actividad.seleccionada) {
          actividad.curso = curso._id;
          this.solicitudActividadesNuevas.push(actividad);
        }
      });
    });
    
    if (this.solicitudActividadesNuevas.length === 0) {
      Swal.fire('Error', 'Debe seleccionar al menos una actividad.', 'error');
      return;
    }

    const solicitudUpdate: any = {
      _id: this.solicitud._id,
      usuario: this.solicitud.usuario._id, 
      pais: this.solicitud.pais._id,
      actividad: this.solicitud.actividad._id,
      actividades: this.solicitudActividadesNuevas.map((actividad: any) => {
        return {
          _id: actividad._id,
          tipo: actividad.tipo,
          nombre: actividad.nombre,
          descripcion: actividad.descripcion,
          fecha: actividad.fecha,
          fechaVencimiento: actividad.fechaVencimiento,
          curso: actividad.curso,
        };
      })
    };
    
    this.solicitud = await this.solicitudesService.getSolicitudById((await this.solicitudesService.updateSolicitud(this.solicitud._id , solicitudUpdate))._id);
    this.solicitudesService.setSolicitudSeleccionada(this.solicitud);
  
    Swal.fire('Actividad modificada', 'Se han actualizado las actividades. y notificado al usuario', 'success');

    this.isChecked = false;
    
    this.getCursosActividades(this.solicitud);
  }

  async notificarActividad(solicitud: Solicitud) {
    const arregloSolicitudes: string[] = [];
    arregloSolicitudes.push(solicitud._id);
    try {
      await this.mailerService.senEmailActividades(arregloSolicitudes);
      Swal.fire('Actividad Notificada', 'Se notifico al usuario', 'success');
    } catch (error) {
      Swal.fire('Actividad NO  Notificada', 'NO Se pudo notifico al usuario', 'error');
      console.error('Error al enviar el correo electrónico:', error);

    }
  }
  
}
