import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';
import { MateriasBoletinService } from 'src/app/services/cursos/materias-boletin.service';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { APP_CONFIG_SHARED, IAppShared } from 'src/app/app.config/app.config-shared';
import { Boletin } from 'src/app/interfaces/interface-bakend/cursos/boletin.interface';
import { BoletinesService } from 'src/app/services/cursos/boletines.service';
import { MateriaBoletinDetalle } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin-detalle.interface';

@Component({
  selector: 'app-boletin-nombre-modificacion',
  templateUrl: './boletin-nombre-modificacion.component.html',
  styleUrls: ['./boletin-nombre-modificacion.component.scss']
})
export class BoletinNombreModificacionComponent implements OnInit {

  flagFormaValido = false;
  form: FormGroup;
  boletin: Boletin;
  boletinSeleccionado: Boletin;
  materiasBoletines: MateriaBoletin[];
  modalidadCursadas: Catalogo[];
  modalidadExamenes: Catalogo[];

  constructor(
    private fb: FormBuilder,
    private guiMsjService: GuiMsjService,
    private boletinService: BoletinesService,
    private catalogoService: CatalogoService,
    private materiasBoletinesService: MateriasBoletinService,
    @Inject(APP_CONFIG_SHARED) private iAppShared: IAppShared,
    private router: Router
  ) { }

  ngOnInit(): void { 
    this.getEstadoVariables();
    this.createForm();
    this.getMateriasBoletines();
    this.getCatalogo();
  }

  async getEstadoVariables() {
    const boletinSeleccionado = await this.getBoletinSeleccionado();
    if (!boletinSeleccionado) {
      this.router.navigateByUrl('/boletines-listado');
    }
  }

  createForm() {
    this.form = this.fb.group({
      nombre: ['', Validators.required],
      materias: this.fb.array([], this.minMateriasValidator)
    });
  }

  get materias() {
    return this.form.get('materias') as FormArray;
  }

  async getMateriasBoletines() {
    await this.materiasBoletinesService.getMaterias().then((materiasBoletines: MateriaBoletin[]) => {
      this.materiasBoletines = materiasBoletines.filter(materia => materia.activa);
    });
  }

  async getCatalogo() {
    try {
      const modalidadCursadas: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.modalidadCursada, null);
      const modalidadExamenes: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.modalidadExamen, null);
      this.modalidadCursadas = modalidadCursadas;
      this.modalidadExamenes = modalidadExamenes;
    } catch (error) {
      console.error('Error al cargar modalidades', error);
    }
  }

  minMateriasValidator(control: FormArray): { [key: string]: boolean } | null {
    if (control.length < 1) {
      return { 'minMaterias': true };
    }
    return null;
  }

  async getBoletinSeleccionado() {
    this.boletin = await this.boletinService.getBoletinSeleccionado();
    if (this.boletin) {
      this.boletinSeleccionado = this.boletin;
      await this.loadBoletinData(this.boletin);
      return this.boletin;
    } else {
      this.router.navigateByUrl('/boletines-listado');
      return null;
    }
  }

  addMateria() {
    const materiaForm = this.fb.group({
      materia: ['', Validators.required],
      modalidadCursada: ['', Validators.required],
      modalidadExamen: ['', Validators.required],
      fechaExamen: [''],
      fechaNota: [''],
      notaExamen: ['', [Validators.min(0), Validators.max(100)]]
    });

    this.materias.push(materiaForm);
  }

  quitarMateria(index: number) {
    const confirmed = window.confirm('¿Estás seguro de que deseas eliminar esta materia?');
    if (confirmed) {
      this.materias.removeAt(index);
    }
  }

  async loadBoletinData(boletin: Boletin) {
    if (boletin) {
      this.form.patchValue({ nombre: boletin.nombre });
      if (Array.isArray(boletin.materias)) {
        // Limpia el FormArray de materias antes de agregar nuevas
        while (this.materias.length !== 0) {
          this.materias.removeAt(0);
        }

        boletin.materias.forEach(materia => {
          const materiaForm = this.fb.group({
            materia: [materia.materia, Validators.required],
            modalidadCursada: [materia.modalidadCursada, Validators.required],
            modalidadExamen: [materia.modalidadExamen, Validators.required],
            fechaExamen: [materia.fechaExamen],
            fechaNota: [materia.fechaNota],
            notaExamen: [materia.notaExamen, [Validators.min(0), Validators.max(100)]]
          });
          this.materias.push(materiaForm);
        });
      } else {
        console.error('El boletín no tiene materias o es undefined');
      }
    }
  }

  async setDataForm(): Promise<Boletin> {
    const data: Boletin = {
      nombre: this.form.value.nombre,
      materias: this.form.value.materias.map((materia: MateriaBoletinDetalle) => ({
        materia: materia.materia,
        modalidadCursada: materia.modalidadCursada,
        modalidadExamen: materia.modalidadExamen,
        fechaExamen: materia.fechaExamen,
        fechaNota: materia.fechaNota,
        notaExamen: materia.notaExamen
      }))
    };
    return data;
  }

  async put() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    try {
      await this.boletinService.putBoletin(this.boletin._id, await this.setDataForm());
      this.router.navigateByUrl('/boletines-listado');
    } catch (error) {
      console.error('Error al guardar el boletín', error);
    }
  }

  cancelar() {
    this.router.navigateByUrl('/boletines-listado');
  }

  get nombreNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }
}