import { User } from './../../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { Component, Input, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { MediaOfertaLaboralFileUserPipe } from '../../../../../pipes/ofertas-laborales/mediaOfertaLaboralFileUser.Pipe';
import { ActividadDAdjunto } from '../../../../../interfaces/interface-bakend/ofertas-laborales/actividad-adjuntos.interface';
import { MatDialog } from '@angular/material/dialog';
import { OfertasVerPdfComponent } from '../../../common/ofertas-ver-pdf/ofertas-ver-pdf.component';
import { ActividadesService } from '../../../../../services/ofertas-laborales/actividades.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';

declare var require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'app-ofertas-files',
  templateUrl: './ofertas-files.component.html',
  styleUrls: ['./ofertas-files.component.scss']
})
export class OfertasFilesComponent implements OnInit {

    @Input() files: string[];
    @Input() usuario: User;

    // tslint:disable-next-line:ban-types
    filesWithName: Object[] = [];

    dataFile = {
        nombre: '',
        adjunto: '',
    };


    constructor(
        private actividadesService: ActividadesService,
        private usuarioService: UsuarioService,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.files = this.quitarDuplicados(this.files);
        this.pushNombreAdjunto(this.files);
    }

    quitarDuplicados(file: string[]): string[] {
        return Array.from(new Set(file));
    }


    download(idUsuario: string, adjunto: string, nombre: string) {
        FileSaver.saveAs(this.mediaOfertaLaboralFileUser(idUsuario, adjunto), nombre);
    }

    mediaOfertaLaboralFileUser(idUsuario: string, adjunto: string) {
        const mediaOfertaLaboralFileUser = new MediaOfertaLaboralFileUserPipe();
        return mediaOfertaLaboralFileUser.transform(idUsuario, adjunto);
    }

    async pushNombreAdjunto(files: string[]) {
        try {
            if (files.length > 0) {
                for (const adjunto of files) {
                    try {
                        let nombreUsuarios = await this.usuarioService.getNombreAdjunto(adjunto);
                        let nombreAdjuntoActividad = await this.actividadesService.getNombreAdjunto(adjunto);
                        
                        if (!nombreUsuarios && nombreAdjuntoActividad) {
                          this.filesWithName.push({ nombre: nombreAdjuntoActividad, adjunto });
                        } else {
                          if (!nombreAdjuntoActividad && nombreUsuarios) {
                            this.filesWithName.push({ nombre: nombreUsuarios, adjunto });
                          } else {
                            this.filesWithName.push({ nombre: 'Nombre documentos desconocido', adjunto });
                          }
                        }                     
                        
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
      }



    /* Modal PDF  - Carga PDF */
    openDialogPdf(idUsuario: string, adjunto: string, tituloDocumento: string): void {

        const mediaOfertaLaboralFileUser = new MediaOfertaLaboralFileUserPipe();
        const path = mediaOfertaLaboralFileUser.transform(idUsuario, adjunto);

        const dialogRef = this.dialog.open(OfertasVerPdfComponent, {
          width: '800px',
          height: '800px',
          disableClose: true,
          data: {
                 path,
                 tituloDocumento,
                }
        });

        dialogRef.afterClosed().subscribe(async result => {
        });
    }

}
