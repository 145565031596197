import { environment } from 'src/environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { AccessibilityConfig, Image, ImageEvent } from '@ks89/angular-modal-gallery';
import { DomSanitizer } from '@angular/platform-browser';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
const URL_BACKEND = environment.urlBackend;

@Component({
  selector: 'app-documentos-usuarios-gallery',
  templateUrl: './documentos-usuarios-gallery.component.html',
  styleUrls: ['./documentos-usuarios-gallery.component.css']
})
export class DocumentosUsuariosGalleryComponent implements OnInit {

  @Input() imagenes: string[];

  // tslint:disable-next-line:variable-name
  @Input() id_usuario: string;

  imageIndex = 1;
  galleryId = 1;
  autoPlay = true;
  showArrows = true;
  showDots = true;

  imagesRect: Image[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit() {
    this.cargarGallery();
  }

  maximizarImagen(imagen: any) {
  }

  async cargarGallery() {
    let index = 1;
    const url = URL_BACKEND + '/media/imgUserOffer/';
    for (const imagen of this.imagenes) {
      // tslint:disable-next-line: no-unused-expression
      const img = new Image(
        index,
        {
          img: this.transformTrustUrlr(url + this.id_usuario + '/' + imagen),
          description: '' ?? 'Description...',
        },
      );
      this.imagesRect.push(img);
      index++;
    }
  }

  transformTrustUrlr(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  addRandomImage() {
    const imageToCopy: Image = this.imagesRect[Math.floor(Math.random() * this.imagesRect.length)];
    const newImage: Image = new Image(this.imagesRect.length - 1 + 1, imageToCopy.modal, imageToCopy.plain);
    this.imagesRect = [...this.imagesRect, newImage];
  }

  onChangeAutoPlay() {
    this.autoPlay = !this.autoPlay;
  }

  onChangeShowArrows() {
    this.showArrows = !this.showArrows;
  }

  onChangeShowDots() {
    this.showDots = !this.showDots;
  }

  // output evets
  onShow(event: ImageEvent) {
  }

  onFirstImage(event: ImageEvent) {
  }

  onLastImage(event: ImageEvent) {
  }
}
