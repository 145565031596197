import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';

@Component({
  selector: 'app-tipo-puestos-listado',
  templateUrl: './tipo-puestos-listado.component.html',
  styleUrls: ['./tipo-puestos-listado.component.scss']
})
export class TipoPuestosListadoComponent implements OnInit {

    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] =
    [
      'concepto', '_id'
    ];
    dataSource: any;
    modalidades: Catalogo[] = [];
    CATALOGO = 'TIPO_PUESTO';


    /* Fin Data Table*/

    flagLoaded = false;


    constructor(
        private catalogoService: CatalogoService,
        private router: Router,
        private perfilesService: PerfilesService,
    ) {}

    ngOnInit(): void {
      this.perfilesService.allowedByUserLogon(this.router.url);
      this.loadDataPage();
    }

    async verConcepto(event) {
      const concepto: Catalogo = await this.catalogoService.getFindOne(event);
      this.router.navigateByUrl('/tipo-puesto-modificacion');
      await this.catalogoService.setConcepto(concepto);
    }

    async loadDataTable(){
      await this.catalogoService.findDescendantsByKey(this.CATALOGO).then( (modalidades: Catalogo[]) => {
        this.dataSource = new MatTableDataSource(modalidades);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    async loadDataPage() {
      await this.loadDataTable();
      this.flagLoaded = true;
      return;
    }

}
