<h1 mat-dialog-title class="confirmation-title">¡Verificación de Información!</h1>
<h2 *ngIf="tipoPista"  style="color: #66ccff;">Formulario de Pre inscripción cursado en <span style="font-weight: bold;">PISTA</span></h2>
<h2 *ngIf="!tipoPista"  style="color: #66ccff;">Formulario de Pre inscripción cursado en <span style="font-weight: bold;">ON LINE</span></h2>

<div mat-dialog-content class="confirmation-content">
  <p>Por favor, verifica cuidadosamente la información antes de enviar el formulario:</p>

  <div class="confirmation-details">
    <!-- Cursos y Delegaciones Seleccionados -->
    <div *ngIf="formData.cursosDelegacionesSeleccionados && formData.cursosDelegacionesSeleccionados.length > 0"
      class="confirmation-item highlighted-item">
      <span>Cursos y Actividades Seleccionadas:</span>
      <div class="confirmation-table-container">
        <table class="confirmation-table">
          <tr>
            <th>Curso</th>
            <th *ngIf="tipoPista">Delegación</th>
            <th>Descripción</th>
            <th>Fecha Desde</th>
            <th>Fecha Hasta</th>
          </tr>
          <tr *ngFor="let cursoDelegacion of formData.cursosDelegacionesSeleccionados">
            <td>{{ cursoDelegacion?.cursoNombre }}</td>
            <td *ngIf="tipoPista">{{ cursoDelegacion?.nombre }}</td>
            <td>{{ cursoDelegacion?.descripcion }}</td>
            <td>{{ cursoDelegacion?.fecha | date:'short' }}</td>
            <td>{{ cursoDelegacion?.fechaVencimiento | date:'short' }}</td>
          </tr>
        </table>
      </div>
    </div>


    <!-- Detalles adicionales organizados en tres columnas -->
    <div class="confirmation-item-container">
      <div class="confirmation-item">
        <span>Nombre:</span>
        <span>{{ formData.nombreApellido }}</span>
      </div>
      <div class="confirmation-item">
        <span>Email:</span>
        <span>{{ formData.email }}</span>
      </div>
      <div class="confirmation-item">
        <span>Tipo de Documento:</span>
        <span>{{ formData.tipoIdentificacion }}</span>
      </div>
      <div class="confirmation-item">
        <span>Número de Documento:</span>
        <span>{{ formData.numeroDocumento }}</span>
      </div>
      <div class="confirmation-item">
        <span>Nacionalidad:</span>
        <span>{{ formData.nacionalidad }}</span>
      </div>
      <div class="confirmation-item">
        <span>Fecha de Nacimiento:</span>
        <span>{{ formData.fechaNacimiento }}</span>
      </div>
      <div class="confirmation-item">
        <span>Teléfono:</span>
        <span>{{ formData.telefono }}</span>
      </div>
      <div class="confirmation-item">
        <span>Enfermedad:</span>
        <span>{{ formData.enfermedad ? 'Sí' : 'No' }}</span>
      </div>
      <div class="confirmation-item">
        <span>Título Secundario:</span>
        <span>{{ formData.tituloSecundario ? 'Sí' : 'No' }}</span>
      </div>
      <div class="confirmation-item">
        <span>Hospitalizado en los últimos 5 años:</span>
        <span>{{ formData.hospitalizado ? 'Sí' : 'No' }}</span>
      </div>
      <!-- Puedes agregar más detalles aquí -->
    </div>
  </div>
</div>

<div mat-dialog-actions class="confirmation-buttons">
  <button mat-button color="warn" (click)="cancel()">Cancelar</button>
  <button mat-raised-button color="primary" (click)="confirm()">Confirmar</button>
</div>
