<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div *ngIf="this.invoice && this.flagLoaded">    
    <div>
        <div class="container">
            <div class="row align-items-center animate__animated animate__fadeIn">

                <mat-card-header>
                    <div mat-card-avatar class="example-header-image"></div>
                    <mat-card-title>Detalle Comprobante</mat-card-title>
                    <mat-card-subtitle>Transferencia</mat-card-subtitle>
                </mat-card-header>

                    <!-- Mensaje de información de usuario -->
                    <div class="alert alert-info mt-3">
                        <strong>Información del Usuario que creo la solicitud</strong>
                        <ul class="list-unstyled mt-2">
                            <li><strong>Nombre y Apellido:</strong> {{ this.invoice.usuario.fullName }}</li>
                            <li><strong>Identificación:</strong> {{ this.invoice.usuario.identificacion }}</li>
                        </ul>
                    </div>
                    
                    <!-- Mensaje de información de Bootstrap -->
                    <div class="alert alert-success mt-3">
                        <strong>Detalle de Administración:</strong>
                        <p>{{ this.invoice.data.observacion || 'sin detalle' }} </p>
                    </div>
                                    
                    <mat-card class="add-margin-bottom">
                        <li>Actividad: <strong>{{ this.bankTransfers.actividad.nombre}}</strong></li>
                        <li>Usuario: <strong>{{ this.bankTransfers.usuario.email}}</strong></li>
                        <li>Nombre Persona: <strong>{{ this.bankTransfers.usuario.apellidoPersona || 'SIN DATOS PERSONALES' }}</strong></li>
                        <li>Apellido Persona: <strong>{{ this.bankTransfers.usuario.nombrePersona || 'SIN DATOS PERSONALES' }}</strong></li>
                        <li>Importe Pesos: <strong>${{ this.bankTransfers.importe }}</strong></li>
                        <li>Observaciones: <strong>{{ this.bankTransfers.observaciones || 'Sin observaciones' }}</strong></li>
                    </mat-card><br>

                <mat-card-content>

                    <img *ngIf="!this.isPdf" [src]=" 1 | comprobanteTransferencia: this.bankTransfers.path | safeResourceUrl" width="100%" height="auto">

                    <div *ngIf="this.isPdf" class="animate__animated animate__fadeIn">
                        <iframe [src]="( 2 | comprobanteTransferencia: this.bankTransfers.path | safeResourceUrl)" type="application/pdf" style="width: 100%; height: 100vh;"></iframe>
                    </div>

                </mat-card-content>

                <mat-card-actions>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <br><button (click)="closeModal()" class="btn btn-secondary" type="button">SALIR</button>
                    </div>
                </mat-card-actions>


            </div>
        </div>
    </div>
</div>
<div *ngIf="!this.invoice  && this.flagLoaded">
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/ofertas-administracion-listado">Invoice</a></li>
                    <li>Detalle Comprobante</li>
                </ul>
                <h2 *ngIf="this.flagLoaded">Actividad {{ this.bankTransfers.actividad.nombre}}</h2>
            </div>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>
    <br>
    <br>
    <div>
        <div class="container">
            <div class="row align-items-center animate__animated animate__fadeIn">                
                <mat-card class="add-margin-bottom">
                    <li>Actividad: <strong>{{ this.bankTransfers.actividad.nombre}}</strong></li>
                    <li>Usuario: <strong>{{ this.bankTransfers.usuario.email}}</strong></li>
                    <li>Nombre Persona: <strong>{{ this.bankTransfers.usuario.apellidoPersona || 'SIN DATOS PERSONALES' }}</strong></li>
                    <li>Apellido Persona: <strong>{{ this.bankTransfers.usuario.nombrePersona || 'SIN DATOS PERSONALES' }}</strong></li>
                    <li>Importe Pesos: <strong>${{ this.bankTransfers.importe }}</strong></li>
                    <li>Observaciones: <strong>{{ this.bankTransfers.observaciones  || 'Sin observaciones' }}</strong></li>
                </mat-card><br>

                <mat-card-content>

                    <img *ngIf="!this.isPdf" [src]=" 1 | comprobanteTransferencia: this.bankTransfers.path | safeResourceUrl" width="100%" height="auto">

                    <div *ngIf="this.isPdf" class="animate__animated animate__fadeIn">
                        <iframe [src]="( 2 | comprobanteTransferencia: this.bankTransfers.path | safeResourceUrl)" type="application/pdf" style="width: 100%; height: 100vh;"></iframe>
                    </div>

                </mat-card-content>

                <mat-card-actions>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <br><button (click)="closeModal()" class="btn btn-secondary" type="button">SALIR</button>
                    </div>
                </mat-card-actions>


            </div>
        </div>
    </div>
</div>
