<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/dashboard">Inicio</a></li>
        <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
        <li><a routerLink="/gestos-listado">Listado de Gestos</a></li>
        <li>Alta nuevo Gesto</li>
      </ul>
      <h2>Alta nuevo Gesto</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
  <div class="container">
    <div class="row mx-auto">
      <div class="col-lg-12 col-md-12">
        <div class="apply-form">
          <form [formGroup]="form" (ngSubmit)="post()">

            <div class="row">
              <div class="col-lg-12 col-md-12 col-xs-12">

                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                  <mat-label>Nombre</mat-label>
                  <input formControlName="nombre" matInput placeholder="Nombre" autocomplete="off" autofocus>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                  <mat-label>Puntaje aprobación</mat-label>
                  <input disabled formControlName="valorAprobacion" inputmode="numeric" type="number" matInput placeholder="Ingrese puntaje de aprobación"
                    autocomplete="off">
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                  <mat-label>Vigencia en Cant. de Meses</mat-label>
                  <input type="number" formControlName="mesesVigenciaAprobacion" inputmode="numeric" placeholder="Ingrese la cantidad de meses de vigencia" matInput autocomplete="off">
                </mat-form-field>

                <mat-form-field class="example-full-width">
                  <mat-label>Seleccionar Disciplina</mat-label>
                  <mat-select formControlName="disciplina">
                    <mat-option *ngFor="let disciplina of disciplinas" [value]="disciplina._id">
                      {{disciplina.nombre}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <br>
                <mat-form-field class="example-full-width">
                  <mat-label>Seleccionar Nivel</mat-label>
                  <mat-select formControlName="nivel">
                    <mat-option *ngFor="let nivel of niveles" [value]=nivel [selected]="nivel === 1">
                      Nivel {{nivel}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <br>
                <mat-slide-toggle formControlName="activo">Gesto activo?</mat-slide-toggle>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <button mat-raised-button color="accent" type="button" routerLink="/gestos-listado" style="margin-right: 1%; background-color: #eeeeee;">Cancelar</button>
              <button mat-raised-button [disabled]="!form.valid" color="primary" class="right-button">Guardar</button>
            </div>  

          </form>
        </div>
      </div>
    </div>
  </div>
</div>