import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Materia } from '../../../../interfaces/interface-bakend/cursos/materia.interface';
import { MateriasBoletinService } from 'src/app/services/cursos/materias-boletin.service';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';


@Component({
  selector: 'app-materia-boletin-modificacion',
  templateUrl: './materia-boletin-modificacion.component.html',
  styleUrls: ['./materia-boletin-modificacion.component.scss']
})
export class MateriaBoletinModificacionComponent implements OnInit {

  form: FormGroup;
  materia: MateriaBoletin;
  flagLoaded = false;

  constructor(private router: Router,
    private materiasBoletinService: MateriasBoletinService,
    private guiMsjService: GuiMsjService) {
  }

  ngOnInit(): void {
    this.getEstadoVariables();
    this.createForm();
  }

  get nombreNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  createForm() {
    this.form = new FormGroup({
      nombre: new FormControl(this.materia?.nombre, [Validators.required]),
      activa: new FormControl(this.materia?.activa)
    });
  }

  getMateria() {
    return this.materia = this.materiasBoletinService.getMateriaSeleccionada();
  }

  async getEstadoVariables() {
    if (this.getMateria() === undefined) {
      this.router.navigateByUrl('/materias-boletin-listado');
      return;
    } else {
      await this.getMateria();
      this.flagLoaded = true;
    }
  }

  async put() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.materiasBoletinService.put(this.materia._id, await this.setDataForma()).then(async (materia: Materia) => {
      await this.guiMsjService.msjFormSubmit('DatosGuardados');
      this.router.navigateByUrl('/materias-boletin-listado');
      return;
    });
  }

  async setDataForma(): Promise<MateriaBoletin> {
    const data: MateriaBoletin = {
      _id: this.materia._id,
      nombre: this.form.value.nombre,
      activa: this.form.value.activa
    };
    return data;
  }
}
