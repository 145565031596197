import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ValidadoresService {

  constructor() { }

  /* Devuelve una función que se ejecuta de donde la llamo */
  fechaFinMenor(fechaI: string, fechaF: string){

    //Esto se corre a nivel formulario por eso se lo tengo que pasar
    return ( formulario: FormGroup ) => {
      const fecha1 = formulario.controls[fechaI];
      const fecha2 = formulario.controls[fechaF];

      if(fecha1.value > fecha2.value){
        fecha2.setErrors({esMenor: true});
      }else{
        fecha2.setErrors(null);
      }
    }
  }

  calculate_age(fechaNacimiento: string): number {
    let birth_day = parseInt(fechaNacimiento.substring(0, 2));
    let birth_month = parseInt(fechaNacimiento.substring(3, 5));
    let birth_year = parseInt(fechaNacimiento.substring(6, 10));
    let today_date = new Date();
    let today_year = today_date.getFullYear();
    let today_month = today_date.getMonth();
    let today_day = today_date.getDate();
    let age = today_year - birth_year;

    if (today_month < (birth_month - 1)) {
      age--;
    }
    if (((birth_month - 1) === today_month) && (today_day < birth_day)) {
      age--;
    }
    return age;
  }

  restarAnios(fecha: Date, anios: number) {
     const fecha1 = moment('2010-07-12');
     const fecha2 = moment('2016-08-01');
  }
}
