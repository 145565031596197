import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { SolicitudesService } from '../../../../../services/ofertas-laborales/solicitudes.service';
import { Solicitud } from '../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CursosService } from '../../../../../services/cursos/cursos.service';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modificar-modulos-solicitud',
  templateUrl: './modificar-modulos-solicitud.component.html',
  styleUrls: ['./modificar-modulos-solicitud.component.scss']
})
export class ModificarModulosSolicitudComponent implements OnInit, AfterViewInit {

  @Input() solicitud:any; // no pude dar interface porque el backend recib string

  /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] =
    [
      'nombre', 'precio'
    ];
    dataSource: any;
    isChecked = false;
    flagDisabled = false;
    total = 0;

    /* Fin Data Table*/
    flagLoaded = false;
    flagProcesando = false;
    modulos: Curso[];

    newState: Subscription;

    // Uso del decorador @ViewChild('aqui va lo que hayas definido en el html con el #') variable: ElementRef
    @ViewChild('modules') moduleElement: ElementRef;

    selectedModules: Curso[] = []; // this array will contain the selected pizzas

  constructor(
    private solicitudesService: SolicitudesService,
    private cursosService: CursosService,
  ) { 
    this.newState = this.solicitudesService.getsetNewStater().subscribe(resp => {
      this.initPostChangeStatus();
       this.newState = resp;
    });
  }

  ngOnInit(): void {
    // console.log('solicitud', this.solicitud);
  }

  async initPostChangeStatus() {
    // 1- actualizao solicitud
    this.solicitud = this.solicitudesService.getSolicitudSeleccionada();
    
    // 2- reinicio modulos seleccionados
    await this.loadDataTable();

    // 3 isChecked
    this.isChecked = false;

    // 4 flag
    this.flagDisabled = false;

  }

  async ngAfterViewInit() {
    this.modulos = await this.cursosService.getCursosModulos();
    await this.loadDataTable();
    this.flagLoaded = true;
}

  async loadDataTable(){
      this.dataSource = new MatTableDataSource(this.solicitud.cursos);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  modificar(){
    this.flagDisabled = true;
    this.actualizarSolicitudModulos();
  }
 
  asignarNuevosModulos() {
    this.solicitud.cursos =[];
    this.selectedModules.forEach( element => {this.solicitud.cursos.push(element._id)})
  }

  async actualizarSolicitudModulos(): Promise<Solicitud> {
    this.flagLoaded = false;
    this.flagProcesando = true;
    
    this.solicitud.cursos =[];

    this.selectedModules.forEach( element => {this.solicitud.cursos.push(element._id)});
    this.solicitud.usuario =  this.solicitud.usuario._id; // el backend espera string no object
    this.solicitud.pais = this.solicitud.pais._id;  // el backend espera string no object
    this.solicitud.actividad = this.solicitud.actividad._id;  // el backend espera string no object
    this.solicitud["cursosModificados"] =true;

    await this.solicitudesService.updateSolicitud(this.solicitud._id , this.solicitud);

    this.solicitud = await this.updateSolicitudSeleccionada(this.solicitud);

    this.solicitudesService.setNewState(this.solicitud);

    this.flagLoaded = true;
    this.flagProcesando = false;
    
    return this.solicitud;
  }

 async  updateSolicitudSeleccionada(solicitud: Solicitud): Promise<Solicitud>  {
    // Busco solicitud porque la modifique
    this.solicitud  =  await this.solicitudesService.getSolicitudById(this.solicitud._id); 
    // Actulizo mi servicio
    this.solicitudesService.setSolicitudSeleccionada(this.solicitud);
    return this.solicitud;
  }

  onGroupsChange(selectedModule: Curso[]) {
    this.total = 0;
    selectedModule.forEach( element => { this.total = this.total + element.precio});
    // console.log('onGroupsChange', selectedModule);
  }


}
