import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';
import { MediaService } from 'src/app/services/shared/media.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { UserDocumentoTipo } from 'src/app/interfaces/interface-bakend/seguridad/users/user-documento-tipos.interface';
import { UserDocumentoTiposService } from 'src/app/services/shared/user-documento-tipos.service';
import { Subscription } from 'rxjs';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';

@Component({
  selector: 'app-documentos-usuarios-files-upload',
  templateUrl: './documentos-usuarios-files-upload.component.html',
  styleUrls: ['./documentos-usuarios-files-upload.component.scss']
})
export class DocumentosUsuariosFilesUploadComponent implements OnInit {


  @Input() usuario: User;

  selectedFiles: { [documentId: string]: File[] } = {};
  flagCargaCorrecta = false;
  flagDisableButtonApprove = true;
  tableDocuments: object[] = [];
  documentosActivosUsuario: UserDocumentoTipo[] = [];
  loaded = false;
  selectedFileNames: { [documentId: string]: string } = {};
  fileSelected: boolean = false;
  activeDocumentId: string | null = null;
  subscriptionAvance: Subscription;  
  selectedTipoIdentificacion: Catalogo;

  value: number = 0;

  constructor(
    private usuarioService: UsuarioService,
    private actividadesService: ActividadesService,
    public dialog: MatDialog,
    private mediaService: MediaService,
    private guiMsjService: GuiMsjService,
    private userDocumentoTiposService: UserDocumentoTiposService,

  ) { 
    this.subscribeAvanceUpload();
    this.mediaService.reiniciarContadorControlUpload()
  }

  ngOnInit(): void {
    console.log('usuario', this.usuario);
    this.cargarDatosForm();
  }

  async cargarDatosForm() {
    await this.getDocumentosActivos();
    this.loaded = true;
  }

  generateTableContent() {
    this.tableDocuments = []; // Inicializa o actualiza el arreglo que contiene el contenido de la tabla
    if (this.documentosActivosUsuario.length > 0) {
      this.documentosActivosUsuario.forEach(doc => {  
        if (!this.userDocumentoTiposService.documentosSolicitado(this.usuario.adjuntos, doc._id)) {
          this.tableDocuments.push({
            id: doc?._id,
            name: doc.nombre
          });
        } else  {
          console.log('documento ya cargado..');
        }     
      });
    } else {
      this.tableDocuments = [{ message: 'No se encontraron documentos activos' }];
    }
  }

  async getDocumentosActivos() {
    try {
      const resp: UserDocumentoTipo[] = await this.userDocumentoTiposService.getUserDocumentosTiposActivos();
      this.documentosActivosUsuario = resp;
      this.generateTableContent();
    } catch (error) {
      console.error('Error al obtener los documentos', error);
    }
  }

  /*
  *
  *CARGA DOCUMENTACIÓN 
  *
  */

  openFileInput(documentId: string) {
    this.activeDocumentId = documentId;
    const input = document.getElementById('i' + documentId) as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  clearSelectionFile() {
    this.selectedFiles = {};
    this.selectedFileNames = {};
    this.activeDocumentId = null;
  }

  onFilesSelected(event: Event, documentId: string) {
    const fileInput = event.target as HTMLInputElement;
    const files: FileList = fileInput.files;

    if (!files || files.length === 0) {
      // No se encontró archivo
      this.clearSelectionFile();
      this.fileSelected = false;
      this.activeDocumentId = null;
      return;
    }

    const fileList: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      if (this.mediaService.controlSize(event)) {
        fileList.push(file);
      } else {
        alert('Archivo demasiado grande, seleccione un archivo de menos de 4MB');
        this.clearSelectionFile();
        this.flagDisableButtonApprove = true;
        this.fileSelected = false;
        return;
      }
    }

    this.selectedFiles[documentId] = fileList;
    this.selectedFileNames[documentId] = fileList[0].name;
    this.flagDisableButtonApprove = false;
    this.fileSelected = true;
  }

  async uploadFiles(): Promise<boolean> {
    let success = true; // Variable para almacenar el estado de carga de archivos
    
    for (const documentId in this.selectedFiles) {
      if (this.selectedFiles.hasOwnProperty(documentId)) {
        const selectedFiles = this.selectedFiles[documentId];
        
        for (const selectedFile of selectedFiles) {
          try {
            await this.uploadDocumentoAdjunto(selectedFile, documentId); // Carga de archivo
          } catch (error) {
            console.error('Error al cargar el archivo:', error);
            success = false; // Si hay un error, establece success como false
          }
        }
      }
    }
    
    return success; // Devuelve el estado de éxito de la carga de archivos
  }
  
  uploadDocumentoAdjunto(selectedFile: File, documentId: string) {
    this.mediaService.uploadUserFromWeb(
      selectedFile, this.usuario._id,
      documentId
    ).then(async (resp:any) => {
      if (resp) {
        if (this.value === 100) {
          this.flagCargaCorrecta = true;
          this.updateGenerateTableContent(resp.user);
          this.guiMsjService.msjFormSubmit('uploadDocumentOK');
        }
      } else {
        console.log('DOCUMENTOS NO CARGADOS');
        this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
      }
    });
  }

  subscribeAvanceUpload() {
    this.subscriptionAvance = this.mediaService
      .getAvance()
      .subscribe((resp) => {
        if (resp) {
          this.value = resp.value;
        } else {
          this.value = 0;
        }
      });
  }
    /*
  *
  *FIN CARGA DOCUMENTACIÓN 
  *
  */

  updateGenerateTableContent(user: User) {
    this.usuario.adjuntos = user.adjuntos;
    this.generateTableContent();
  }
  



}
