import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { APP_CONFIG_SHARED, IAppShared } from 'src/app/app.config/app.config-shared';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { Mesa, MesaUsuario } from 'src/app/interfaces/interface-bakend/cursos/mesa.interface';
import { Disciplina } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { MesasService } from 'src/app/services/cursos/mesas.service';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mesa-alta',
  templateUrl: './mesa-alta.component.html',
  styleUrls: ['./mesa-alta.component.scss']
})
export class MesaAltaComponent implements OnInit {
  data: any[];
  usuarios: User[] = [];
  usuariosNombres: any[];
  niveles: number[] = [1, 2, 3, 4, 5, 6, 7];
  selectedNivel = 1;
  usuariosSeleccionados: MesaUsuario[] = [];
  usuariosSeleccionadosPush: MesaUsuario[] = [];

  mesaPublicada: boolean = false;
  cursos: Curso[];
  disciplinas: Disciplina[];
  delegaciones: Catalogo[];
  tipo: string = 'INGRESO';

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns = ['select', 'nombre', 'apellido', 'email', 'identificacion'];
  dataSource: any;

  seleccion = new SelectionModel<any>(true, []);
  flagLoaded: boolean;
  form = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });

  constructor(private cursoService: CursosService,
    private mesaService: MesasService,
    private catalogoService: CatalogoService, @Inject(APP_CONFIG_SHARED)
    private iAppShared: IAppShared,
    private _formBuilder: FormBuilder,
    private guiMsjService: GuiMsjService,
    private userService: UsuarioService) { }

  ngOnInit(): void {
    this.getCursos();
    this.getDelegaciones();
    this.getDisciplinas();
    this.loadDataTable();
    this.createForm();
  }

  onChange(estado: boolean) {
    this.mesaPublicada = estado;
  }

  createForm() {
    this.form = new FormGroup({
      titulo: new FormControl('', [Validators.required]),
      descripcionCorta: new FormControl('', [Validators.required]),
      descripcionLarga: new FormControl('', [Validators.required]),
      fechaDesdeExamen: new FormControl(new Date(), [Validators.required]),
      fechaHastaExamen: new FormControl(new Date(), [Validators.required]),
      fechaPublicacionDesde: new FormControl(''),
      fechaPublicacionHasta: new FormControl(''),
      curso: new FormControl(''),
      delegacion: new FormControl('', [Validators.required]),
      disciplina: new FormControl('', [Validators.required]),
      tipoMesa: new FormControl(Validators.required),
      nivel: new FormControl('', [Validators.required]),
      mesaPublicada: new FormControl()
    });
  }

  allUsuariosSeleccionados() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.data?.length;
    return numSelected === numRows;
  }

  seleccionarMasivo() {
    this.allUsuariosSeleccionados() ?
      this.seleccion.clear() :
      this.data.forEach(row => this.seleccion.select(row));
    this.usuarios = this.seleccion.selected.map(u => u);
    this.usuariosNombres = this.seleccion.selected.map(u => (u.nombrePersona + ' ' + u.apellidoPersona + ' - ' + u.identificacion));
  }

  seleccionarIndividual(row: any) {
    this.seleccion.toggle(row);
    const usuariosSeleccionados = this.seleccion.selected.map(u => u._id);
    this.usuariosSeleccionados = this.seleccion.selected.map(u => u._id);
    this.usuariosNombres = this.seleccion.selected.map(u => (u.nombrePersona + ' ' + u.apellidoPersona + ' - ' + u.identificacion));
  }


  async getCursos() {
    this.cursos = await this.cursoService.getCursos();
  }

  async getDisciplinas() {
    await this.cursoService.getDisciplinas().then((disciplinas: Disciplina[]) => {
      this.disciplinas = disciplinas;
    });
  }

  async getDelegaciones() {
    this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.delegaciones, null).
      then((resp: Catalogo[]) => {
        return this.delegaciones = resp;
      });
  }

  async loadDataTable() {
    this.userService.getAllUsers().then((usuarios: User[]) => {
      this.data = usuarios;
      this.dataSource = new MatTableDataSource(usuarios);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async setDataForm() {
    this.usuariosSeleccionadosPush.splice(0)
    const nuevosUsuarios: MesaUsuario[] = [];

    // Verificar si cada usuario seleccionado ya existe en usuariosSeleccionadosPush
    for (const user of this.usuariosSeleccionados) {
      const index = this.usuariosSeleccionadosPush.findIndex(u => u.usuario === user);
      if (index === -1) {
        const nuevoUsuario: MesaUsuario = {
          usuario: user,
          fechaInscripcion: new Date()
        };
        nuevosUsuarios.push(nuevoUsuario);
      }
    }

    // Agregar los nuevos usuarios al array usuariosSeleccionadosPush
    this.usuariosSeleccionadosPush.push(...nuevosUsuarios);

    const data: Mesa = {
      titulo: this.form.value.titulo,
      descriptionCorta: this.form.value.descripcionCorta,
      descriptionLarga: this.form.value.descripcionLarga,
      fechaExamenDesde: this.form.value.fechaDesdeExamen,
      fechaExamenHasta: this.form.value.fechaHastaExamen,
      publica: this.mesaPublicada,
      cursos: this.form.value.curso,
      fechaPublicaDesde: this.form.value.fechaPublicacionDesde,
      fechaPublicahasta: this.form.value.fechaPublicacionHasta,
      delegacion: this.form.value.delegacion,
      disciplina: this.form.value.disciplina,
      nivel: Number(this.form.value.nivel),
      tipoMesa: this.form.value.tipoMesa,
      usuarios: this.usuariosSeleccionadosPush
    };
    if (!this.form.value.curso) {
      delete data.cursos
    }
    return data;
  }

  async post() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.mesaService.post(await this.setDataForm()).then(async (mesa: Mesa) => {
      await this.guiMsjService.msjFormSubmit('DatosGuardados');
    }).catch(error=>{
      console.warn(error);
      Swal.close();
    });
  }
}
