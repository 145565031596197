import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pregunta, Examen, Respuesta } from '../../../../../../interfaces/interface-bakend/cursos/examen.interface';
import { Subscription } from 'rxjs';
import { MediaService } from 'src/app/services/shared/media.service';
import { FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { CursosService } from '../../../../../../services/cursos/cursos.service';
import { APP_CONFIG_CURSOS, IAppCursos } from '../../../../../../app.config/app.config-cursos';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';

interface DialogData {
  posPregunta: number;
  pregunta: Pregunta;
  examen: Examen;
}

interface DataArrayMultimedia {
  posPregunta?: number;
  posRespuesta?: number;
  file: any;
}
@Component({
  selector: 'app-modal-respuesta',
  templateUrl: './modal-respuesta.component.html',
  styleUrls: ['./modal-respuesta.component.scss']
})
export class ModalRespuestaComponent implements OnInit {

  procesando = false;
  isEditable = true;
  subscriptionAvance: Subscription;
  value: number;

  respuestasBuild: Respuesta[] = [];

  respuestasMultimedia: DataArrayMultimedia[] = [];
  forma: FormGroup;
  flagFormaValido = false;
  flagFin = false;
  procesandoArchivos = false;
  flagCargandoArchivo = false;
  flagMultimediaUploadFull = false;
  flagOrdenarConceptos = false;
  flagCuadriculaOpciones = false;
  flagVerdaderoFalso = false;
  nombreArchivoUpload: string;
  tipoArchivoUpload: string;
  selectedType: string;
  examen: Examen;
  conceptosOrdenados: string[] = [];

  matrix: any;


  constructor(
  public dialogRef: MatDialogRef<ModalRespuestaComponent>,
  @Inject(MAT_DIALOG_DATA) public data: DialogData,
  @Inject(APP_CONFIG_CURSOS) private iAppCursos: IAppCursos,
  private mediaService: MediaService,
  private guiMsjService: GuiMsjService,
  private cursosService: CursosService,
  private examenService: ExamenesService,
  ) {
  this.respuestasMultimedia = [];
  this.createForm();
  this.createListenersStateForm();
  this.subscribeAvanceUpload();
   }

  ngOnInit(): void {
  }

  /* subo archivo con observable*/
  subscribeAvanceUpload(){
  this.subscriptionAvance = this.mediaService.getAvance().subscribe(resp => {
    if (resp) {
      this.value = resp.value;
    } else {
      this.value = 0;
      }
    });
  }

  async cargarRespuestas(tipo: string){

  this.initFlagcontrol();
  
  switch (tipo) {

    case this.iAppCursos.tipo_examen_CO.tipo:
      
      // 1- Busco examen
      this.examen = this.data.examen;

      // 2- Asocio respuesta
      const respuestaCO: Respuesta = {
        matriz: this.matrix,
      };

      this.examen.preguntas[this.data.posPregunta].respuestas = []; // lo limpio para cuado actualizo
      this.examen.preguntas[this.data.posPregunta].respuestas.push(respuestaCO);

      // 3- Update examen con preguntas configuradas
      this.examen = await this.cursosService.updateExamen(this.examen);
      this.data.examen = this.examen;

      this.flagMultimediaUploadFull = true;

      this.flagFin = true;

      this.procesando = false;

      break;

    case this.iAppCursos.tipo_examen_OC.tipo:

      // 1- Busco examen
      this.examen = this.data.examen;
      this.data.examen = this.examen;

      // 2- Asocio respuesta
      const respuestaOC: Respuesta = {
        ordenable: true,
        conceptosOrdenados: this.conceptosOrdenados ,
      };

      this.examen.preguntas[this.data.posPregunta].respuestas = []; // lo limpio para cuado actualizo
      this.examen.preguntas[this.data.posPregunta].respuestas.push(respuestaOC);

      // 3- Update examen con preguntas configuradas
      this.examen = await this.cursosService.updateExamen(this.examen);
      this.data.examen = this.examen;

      this.flagMultimediaUploadFull = true;

      this.flagFin = true;

      this.procesando = false;

      break;

    default:
      // 1- Busco examen
      this.examen = this.data.examen;

      // 2- Asocio respuesta y multimedia
      this.respuestasBuild = await this.asociarRespuestaConMultimedia(
            this.forma.value.respuestas, this.forma.value.esCorrecta,
            this.respuestasMultimedia
            );

      this.examen.preguntas[this.data.posPregunta].respuestas = this.respuestasBuild;

      // 3- Update examen con preguntas configuradas
      this.examen = await this.cursosService.updateExamen(this.examen);

      // 4-Upload de multimedia
      this.flagMultimediaUploadFull = await this.uploadMultimedia(this.examen, this.respuestasMultimedia, 'R', this.data.pregunta._id);

      this.cursosService.setConfigExamen(this.examen);

      this.procesando = false;
      break;
  }

  }

  createListenersStateForm() {
    return this.forma.statusChanges.subscribe( async status => {

      if (
          status === 'VALID' &&
          this.forma.value.respuestas.length > 0 &&
          !this.forma.value.respuestas.includes('')
      ) {
          this.flagFormaValido = true;
      } else {
          this.flagFormaValido = false;
      }

      // control para orden de conceptos
      if (this.forma.value.tipo === this.iAppCursos.tipo_examen_OC.tipo &&
          this.conceptosOrdenados.length > 0) {
              this.flagFormaValido = true;
     }

    // recibo matrix
      // console.log('matrix', this.matrix);
    });
  }

  createForm(){
    this.forma = new FormGroup({
      respuestas: new FormArray([],  [ Validators.required ]),
      esCorrecta: new FormArray([],  [ Validators.required ]),
      file: new FormArray([],  [ Validators.required ]),
    });
  }

  agregarRespuesta(tipo: string){
    this.respuestaPorTipoDePregunta(tipo);
  }


  respuestaPorTipoDePregunta(tipo: string): boolean {
    switch (tipo) {

        case this.iAppCursos.tipo_examen_MC.tipo:
            this.respuestas.push(new FormControl(''));
            this.esCorrecta.push(new FormControl(false));
            this.file.push(new FormControl(''));
            return true;
            break;

        case this.iAppCursos.tipo_examen_VF.tipo:
            this.flagVerdaderoFalso = true;
            if (this.respuestas.length >= 1 ) {
                this.guiMsjService.msjFormSubmit('respuestasConfiguradas');
                return false;
            } else {
                this.respuestas.push(new FormControl('ESTA RESPUESTA ES V O F'));
                this.esCorrecta.push(new FormControl(false));
                this.file.push(new FormControl(''));
                // this.forma.controls['respuesta'].disable();

                return true;
            }
            break;

        case this.iAppCursos.tipo_examen_OC.tipo:
                this.flagOrdenarConceptos = true;
                return true;
                break;

        case this.iAppCursos.tipo_examen_CO.tipo:
                this.flagCuadriculaOpciones = true;
                return true;
                break;

        default:
              return false;
              break;
    }
  }

  get respuestas(){
    return this.forma.get('respuestas') as FormArray;
  }

  get esCorrecta(){
    return this.forma.get('esCorrecta') as FormArray;
  }

  get file(){
    return this.forma.get('file') as FormArray;
  }

  borrarRespuesta(i: number){
    this.respuestas.removeAt(i);
    this.esCorrecta.removeAt(i);
    this.file.removeAt(i);
    this.borraraArchivoCargado(i);
  }

  borraraArchivoCargado(i: number){
    this.respuestasMultimedia.forEach( (item, index) => {
      if (item.posRespuesta === i) {
      this.respuestasMultimedia.splice(index, 1);
      }
    });
  }

  onFileChange(event, i) {
    if (event.target.files && event.target.files.length > 0) {
      this.controlSize(event);
      const dataFile: DataArrayMultimedia = {
        posPregunta: this.data.posPregunta,
        posRespuesta: i,
        file: event.target.files[0],
      };
      // this.respuestasMultimedia.push(dataFile);
      if ( i >=  this.respuestasMultimedia.length) {
        this.respuestasMultimedia.push(dataFile);
      } else {
        this.respuestasMultimedia[i] = dataFile;
      }
    }
  }

  async controlSize(event) {
    if (!await this.mediaService.controlSize50mb(event)) {
      this.forma.setErrors({ invalid: true });
      alert('File too Big, please select a file less than 4mb');
      return;
    }
  }

  async asociarRespuestaConMultimedia(
    arregloRespuesta: string[],
    arregloCorrecta: any[],
    arregloMultimedia: DataArrayMultimedia[],
    ): Promise<Respuesta[]> {

    return new Promise<Respuesta[]>((resolve) => {

    const respuestas: Respuesta[] = [];
    let respuesta: Respuesta;
    let elementoMultimedia: any;
    let flagContenido = false;

    for (let i = 0; i < arregloRespuesta.length; i++) {
      flagContenido = false;

      // 1- Busco si hay contenido para la respuesta
      arregloMultimedia.forEach(element => {
        if (element.posRespuesta === i) {
          elementoMultimedia = element.file;
          flagContenido = true;
        }
      });      

      // 2- Armo respuesta con o sin contenido
      if (flagContenido )  {
        respuesta = {
        texto: arregloRespuesta[i],
        contenido: {
          source: 'pendiente de upload...',
          type: elementoMultimedia.type,
          descripcion: elementoMultimedia.name,
          nombreArchivo: elementoMultimedia.name,
          contiene: true,
        },
        correcta: arregloCorrecta[i],
        seleccionado: false,
        };
      } else {
        respuesta = {
        texto: arregloRespuesta[i],
        contenido: {
          contiene: false,
        },
        correcta: arregloCorrecta[i],
        seleccionado: false,
        };
      }

      respuestas.push(respuesta);

  }
    resolve (respuestas);

    });
  }

  async uploadMultimedia(examen: Examen, arrayMultimedia: DataArrayMultimedia[], tipo, idPregunta: string): Promise<boolean> {
      return new Promise( async resolve => {
          if (arrayMultimedia.length > 0) {
            this.procesandoArchivos = true;
            await this.uploadElment(arrayMultimedia, examen, tipo, idPregunta);
            resolve(true);
          } else {
            this.flagFin = true;
            resolve(false);
          }
      });
  }

  async uploadElment(arrayMultimedia: DataArrayMultimedia[], examen: Examen, tipo: string, idPregunta?: string) {
    let cont = 0;
    arrayMultimedia.forEach(async (element: DataArrayMultimedia) => {
      await this.uploadFile(examen._id, element.posPregunta.toString(), element.file, arrayMultimedia.length, tipo, idPregunta, element.posRespuesta.toString() );
      cont ++;
      if (cont === arrayMultimedia.length) {
        this.flagFin = true;
      } else {
        this.flagFin = false;
      }
      });
  }

  initFlagcontrol() {
    this.procesando = true;
    this.isEditable = false;
    this.flagFormaValido = false;
  }

  async uploadFile(idExamen, posPregunta, file, length, tipo, idPregunta?, posRespuesta?) {
    this.value = 0;
    this.nombreArchivoUpload = file.name;
    this.tipoArchivoUpload = file.type;

    await this.mediaService.uploadFileExamen(idExamen, posPregunta, file, tipo, idPregunta, posRespuesta).then( (resp: any) => {
    if (resp) {
      if (this.value === 100) {
        this.flagCargandoArchivo = false;
        this.examen = resp.examen;
        this.cursosService.setConfigExamen(this.examen);
      }
    } else {
      this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
    }
    });

  }

  closeModal(): void {
    this.dialogRef.close();
  }

  /**
   * Cuando cierro reenvio datos recibidos, actualizados
   */
  closeModalRespuetasCargadas(): void {           
    const data: DialogData = {
        examen: this.examen,
        pregunta: this.data.pregunta,
        posPregunta: this.data.posPregunta,
    };
    this.dialogRef.close(this.data);
  }

  getTipoArchivo( tipo: string): string {
    // tslint:disable-next-line:prefer-const
    let tipoRachivo: string;
    switch (tipo) {
    case 'application/pdf':
      tipoRachivo = 'pdf';
      break;
    case 'video/mp4':
      tipoRachivo = 'mp4';
      break;
    case 'image/png':
      tipoRachivo = 'img';
      break;
    case 'image/jpeg':
      tipoRachivo = 'img';
      break;
    case 'image/jpg':
      tipoRachivo = 'img';
      break;
    default:
      break;
    }

    return tipoRachivo;
 }

}
