import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pregunta, Respuesta, TipoPregunta } from './../../interfaces/interface-bakend/cursos/examen.interface';
import { EventEmitter, Injectable } from '@angular/core';
import { Examen, UsuariosCursosAsignados } from '../../interfaces/interface-bakend/cursos/examen.interface';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Response } from '../../interfaces/interface-bakend/response';
import { GuiMsjService } from '../shared/gui-msj.service';
import { StorageService } from '../shared/storage.service';
import Swal from 'sweetalert2';
import { ExamenEvaluacion } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';

export interface Data {
  num: number;
}

const URL_BACKEND = environment.urlBackend;
@Injectable({
  providedIn: 'root'
})

export class ExamenesService {

  private formValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private indexPregunta$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private longitudPreguntas$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private onExit$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(true);
  private finalizar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  examenSeleccionado: Examen;
  examenEvaluacionRespuesta: Examen;
  examenFinalizado: Examen;
  examenEvaluado: ExamenEvaluacion;
  private examenFinal: Examen;
  private examenFinal$: Subject<Examen>;
  private examenView$ = new Subject<any>();
  private examen = new Subject<any>();
  private preguntas$:  Subject<Pregunta[]>;
  examenEvaluacionGetId: string;
  

  tiposPreguntas: TipoPregunta[] = [
    {value: 'MC', viewValue: 'Multiple choise'},
    {value: 'OC', viewValue: 'Ordenar conceptos'},
    {value: 'VF', viewValue: 'Verdadero o falso'},
    {value: 'CO', viewValue: 'Cuadrícula de varias opciones'}
  ];

  constructor(
      private authService: AuthService,
      private httpClient: HttpClient,
      private guiMsjService: GuiMsjService,
      private storageService: StorageService
  ) {
    this.examenFinal$ = new Subject();
    this.preguntas$ = new Subject();
  }


  // Funciones para el refresh de evaluaciones
  private refreshNeeded = new BehaviorSubject<boolean>(false);

  get refreshNeeded$() {
    return this.refreshNeeded.asObservable();
  }

  triggerRefresh() {
    this.refreshNeeded.next(true);
  }

  // OBSERVABLE PARA CUANDO SALGO DEL COMPONENT EXAMEN CURSAR
  get onExitExamen(){
    return this.onExit$.asObservable();
  }

  set onExitExamenStatus(status: Boolean){
    this.onExit$.next(status);
  }

  get formStatus(){
    return this.formValid$.asObservable();
  }

  // OBTENER ESTADO OBSERVABLE PARA FINALIZAR EL EXAMEN
  get finalizar(){
    return this.finalizar$.asObservable();
  }

  get indexPregunta(){
    return this.indexPregunta$.asObservable();
  }

  get longitudPreguntas(){
    return this.longitudPreguntas$.asObservable();
  }

  set formStatusExamen(status: boolean){
    this.formValid$.next(status);
  }

  set finalizarEstado(status: boolean){
    this.finalizar$.next(status);
  }

  set indexPreguntaExamen(num: number){
    this.indexPregunta$.next(num + 1);
  }

  set longitudPreguntasExamen(num: number){
    this.longitudPreguntas$.next(num);
  }

  setCursarExamen(examen: Examen) {
    this.examenFinal = examen;
    this.examenFinal$.next(this.examenFinal);
  }

  /*Observable para enviar respuestas del examen*/
  getCursarExamen(): Observable<Examen> {
    return this.examenFinal$.asObservable();
  }

  getExamen(): Observable<any> {
    return this.examen.asObservable();
  }

  setExamen(examen: Examen) {
    this.examen.next({ value: examen });
  }

  _setExamenView$(examen: Examen) {
    this.examenView$.next( { value: examen } );
  }

  _getExamenView$(): Observable<any> {
    return this.examenView$.asObservable();
  }

  /* OBSERVABLE PARA OBJETO PREGUNTAS CUANDO LAS MODIFICO/ELIMINO */
  setPreguntas$(preguntas: Pregunta[]){
    this.preguntas$.next(preguntas)
  }
  getPreguntas$(): Observable<Pregunta[]>{
    return this.preguntas$.asObservable(); 
  }

  setEstadoExamen(estado: boolean, id: string){
    return new Promise( async resolve => {

      const data = {
        activo : estado
      };

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      // tslint:disable-next-line:max-line-length
      this.httpClient.put<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/activateOne/${id}`, data, { headers }).subscribe( resp => {

        resolve(resp.response);

        // tslint:disable-next-line:no-unused-expression
        (error: any) => {
          Swal.close();
          Swal.fire('Error al modificar', '', 'error');
          console.log(error);
          resolve(false);
        };
      });
    });
  }

  getTiposPreguntas(): TipoPregunta[] {
    return this.tiposPreguntas;
  }

  getExamenes(){
    return new Promise( async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });

    });
  }

  getExamenesByFecha(data: object){
    return new Promise( async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      // tslint:disable-next-line:max-line-length
      this.httpClient.post<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones/findAllLista`, data,  { headers }).subscribe( resp => {
        resolve(resp.response);
      });

    });
  }

  async getExamenesByUser(id: string){
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      // tslint:disable-next-line:max-line-length
      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/asignaciones/findAllByUser/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getExamenesByCurso(id: string){
    return new Promise( async resolve => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      // tslint:disable-next-line:max-line-length
      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/asignaciones/findAllByCurso/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getExamenesFindAllBySearch(examenId?: string, cursoId?: string, userId?: string){
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      const body =  {
        "examen": examenId,
        "curso": cursoId,
        "usuario": userId
      }

      // tslint:disable-next-line:max-line-length
      this.httpClient.post<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/asignaciones/findAllBySearch/`, body, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  asignacionExamen(idExamen: string, idCurso: string, idUsuario: string, idGrupoAfinida: string){
    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
        });

        const data = {
            examen: idExamen,
            curso: idCurso,
            usuario: idUsuario,
            grupoAfinidad: idGrupoAfinida,
        };

        this.httpClient.
        post<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/asignaciones`, data , { headers }).subscribe( resp => {
            resolve(resp.response);
        }, error => {
            this.guiMsjService.msjFormSubmit('examenAsignadoError');
        });

    });
  }

  async setExamenSeleccionado(examen: Examen) {
    Swal.close();
    this.examenSeleccionado = examen;
    await this.storageService.setExamen(examen);
  }

  getExamenSeleccionado(): Examen {
    return this.examenSeleccionado;
  }

  /* SET EXAMEN EVALUACION RESPUESTA */
  setExamenEvaluacionRespuesta(examen: Examen){
    this.examenEvaluacionRespuesta = examen;
  }

  /* GET EXAMEN EVALUACION RESPUESTA */ 
  getExamenEvaluacionRespuesta(): Examen{
    return this.examenEvaluacionRespuesta;
  }

  // Setear examen finalizado
  setExamenFinalizado(examen: Examen) {
    Swal.close();
    this.examenFinalizado = examen;
    this.storageService.setExamenFinalizado(examen);
  }

  // Obtener el examen finalizado por el alumno
  getExamenFinalizado(): Examen {
    return this.examenFinalizado;
  }

  setExamenEvaluado(examenEvaluado: ExamenEvaluacion) {
    this.examenEvaluado = examenEvaluado;
    this.storageService.setExamenEvaluado(examenEvaluado);
  }

  getExamenEvaluado(): ExamenEvaluacion {
    return this.examenEvaluado;
  }

  getExamenFindOne(id: string): Promise<Examen> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/findOne/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
        //this.setExamenSeleccionado(resp.response);
      });
    });
  }

  getEvaluacionesByUser(): Promise<ExamenEvaluacion[]> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      const id = (await this.authService.getUserLogon())._id;

      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones/findAllByUser/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  evaluarExamen(examen: ExamenEvaluacion){
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.
        post<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones`, examen , { headers }).subscribe( resp => {
          resolve(resp.response);
        });
    });
  }

  evaluacionUpdateOne(id: string, examen: ExamenEvaluacion){
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.
        put<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones/updateOne/${id}`, examen , { headers }).subscribe( resp => {
          resolve(resp.response);
        });
    });
  }

  evaluacionBoletinUpdate(id: string, examen: Partial<ExamenEvaluacion>){
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.
        put<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones/updateBoletinOne/${id}`, examen , { headers }).subscribe( resp => {
          resolve(resp.response);
        });
    });
  }

  evaluacionBoletinUpdateOne(id: string, examen: ExamenEvaluacion){
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.
        put<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones/updateExamenBoletin/${id}`, examen , { headers }).subscribe( resp => {
          resolve(resp.response);
        });
    });
  }

  setExamenEvaluacionId(id: string){
    this.examenEvaluacionGetId = id;
  }

  getExamenEvaluacionId(){
    return this.examenEvaluacionGetId;
  }

  getEvaluacion(examen: string){
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.
        get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones/findOne/${examen}`, { headers }).subscribe( resp => {
          resolve(resp.response.respuesta);
        });
    });
  }

  getEvaluacionFindOne(examen: string){
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.
        get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones/findOne/${examen}`, { headers }).subscribe( resp => {
          resolve(resp.response);
        });
    });
  }

  /**
   * Retorna los cursos y usuarios asignados del examen
   * @param examen 
   * @returns 
   */
  getnFindAllByEvaluacion(idExamen: string): Promise<UsuariosCursosAsignados>{
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.
        get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/asignaciones/findAllByExamen/${idExamen}`, { headers }).subscribe( resp => {
          resolve(resp.response);
        });
    });
  }

  getEvaluacionesFindAll(){
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.
        get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones/findAllLista/`, { headers }).subscribe( resp => {
          resolve(resp.response);
        });
    });
  }

  getEvaluacionesFindAllAprobados(){
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.
        get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones/findAllAprobadosLista/`, { headers }).subscribe( resp => {
          resolve(resp.response);
        });
    });
  }

  getEvaluacionesFindAllDesaprobados(){
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.
        get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones/findAllNoAprobadosLista/`, { headers }).subscribe( resp => {
          resolve(resp.response);
        });
    });
  }

  getEvaluacionesFindAllByUserAprobados(user: string){
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.
        get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones/findAllAprobadosByUser/${user}`, { headers }).subscribe( resp => {
          resolve(resp.response);
        });
    });
  }

  getEvaluacionesFindAllByUserDesaprobados(user: string){
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.
        get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/evaluaciones/findAllNoAprobadosByUser/${user}`, { headers }).subscribe( resp => {
          resolve(resp.response);
        });
    });
  }

  /* UPDATE EXAMEN (SOLO PREGUNTAS) */
  updatePreguntasExamen(id: string, data){
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.put<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/updatePreguntas/${id}`, data, { headers }).subscribe( resp => {
        resolve(resp.response)
      });
    });
  }

  /*async updateExamen(examen: Examen): Promise<Examen>{
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      // tslint:disable-next-line:max-line-length
      this.httpClient.put<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/updateOne/${examen._id}`, examen,  { headers }).subscribe( resp => {
        resolve(resp.response);
      });

    });
  }*/

  /**
   * Borro examenesasignados  de cursos o usuario
   * Si le pasas null en el usuario y le pasas el curso, borra todas las asignaciones del examen en los cursos
   * Si le pasas el usuario, y null el curso te borra todas las asignaciones del examen en el usuario
   * Si pasas el examen, y no el curo, y no el usuario. Borra el examen de todos lados!!!
   * Si le pasas el examen y el usuario, borra ese parametro
   * Si le pasas el examen y  el curos, borra ese parametro
   */
   async deleteAllByUserAndCursoOpcionales(examenId: string, cursoId: string, userId?:string): Promise<UsuariosCursosAsignados>{
    return new Promise(async (resolve, reject) => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

     const body =  {
      "examen": examenId,
      "curso": cursoId,
      "usuario": userId
     }

      this.httpClient.
        post<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/asignaciones/deleteAllByUserAndCurso/`,body, { headers }).subscribe( resp => {
          resolve(resp.response);
        });
    });
  }
   
}

