import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Curso, CursoActividad } from '../../../../interfaces/interface-bakend/cursos/curso.interface';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { APP_CONFIG_SHARED, IAppShared } from 'src/app/app.config/app.config-shared';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { MediaService } from 'src/app/services/shared/media.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { ValidadoresService } from 'src/app/services/shared/validadores.service';
import { ThemePalette } from '@angular/material/core';
import { GuiToolsService } from 'src/app/services/shared/gui-tools.service';

@Component({
  selector: 'app-curso-modificacion',
  templateUrl: './curso-modificacion.component.html',
  styleUrls: ['./curso-modificacion.component.scss']
})
export class CursoModificacionComponent implements OnInit, OnDestroy {

  form: FormGroup;
  curso: Curso;
  flagLoaded: boolean = false;
  selectedFileImg: any;
  selectedFileMp4: any;
  value = 0;
  cargandoArchivo = false;
  subscriptionAvance: Subscription;
  ngUnsubscribe = new Subject<void>();
  condition = false;
  fileName = 'No file selected';
  file: File;

  /* ripple */
  centered = false;
  disabled = false;
  unbounded = false;

  /* img portada */
  fileAttr = 'Choose File';

  radius: number;
  color: string;

  imageUrl: string | ArrayBuffer =
    './assets/img/uploadImg.png';

  /*Paneles*/
  flagActivitiesPanel: boolean = false;
  flagDelagationsPanel: boolean = false;
  flagCalendarsPanel: boolean = false;
  flagAvailableDatesPanel: boolean = false;

  selectorDelegaciones: Catalogo[];
  delegationSelected: string;

  //Config componente fecha hora
  public disabled1 = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate1: Date;
  public maxDate: Date;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color1: ThemePalette = 'primary';
  public disableMinute = false;
  public hideTime = false;
  public dateControl = new FormControl(null);

  constructor(
    private catalogoService: CatalogoService, @Inject(APP_CONFIG_SHARED)
    private iAppShared: IAppShared,
    private router: Router,
    private fb: FormBuilder,
    private cursosService: CursosService,
    private mediaService: MediaService,
    private guiMsjService: GuiMsjService,
    private validadores: ValidadoresService,
  ) {
  }

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initComponent() {
    this.getEstadoVariables();
    this.getDelegaciones();
    this.createForm();
    this.loadPanels();
    this.flagLoaded = true;
  }

  getEstadoVariables() {
    if (this.getCurso() === undefined) {
      this.router.navigateByUrl('/cursos-listado');
      return;
    } else {
      this.getCurso();
    }
  }

  async getCurso() {
    return this.curso = this.cursosService.getCursoSeleccionado();
  }

  async getDelegaciones() {
    try {
      const resp: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.delegaciones, null);
      this.selectorDelegaciones = resp;
    } catch (error) {
      console.error('Error al obtener las delegaciones', error);
    }
  }

  // PANELES 

  get actividades(): FormArray {
    return this.form.get("actividades") as FormArray;
  }

  get delegaciones(): FormArray {
    return this.form.get("delegaciones") as FormArray;
  }

  get fechasDisponibles(): FormArray {
    return this.form.get("fechasDisponibles") as FormArray;
  }

  get calendarios(): FormArray {
    return this.form.get("calendarios") as FormArray;
  }

  get pista(): boolean {
    return this.form.get('pista').value;
  }

  newActivity(): FormGroup {
    return this.fb.group({
      tipo: new FormControl({ value: 'ACTIVIDAD', disabled: true }, [Validators.required]),
      nombre: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', []),
      fecha: new FormControl('', [Validators.required]),
      fechaVencimiento: new FormControl('', [Validators.required]),
      link: new FormControl('', []),
    })
  }

  newDelegation(): FormGroup {
    return this.fb.group({
      tipo: new FormControl({ value: 'DELEGACIÓN', disabled: true }, [Validators.required]),
      nombre: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', []),
      fecha: new FormControl('', [Validators.required]), // no lo llamo desde, porque hay mucho cargado ya, no quiero cambiar el nombre del campo
      fechaVencimiento: new FormControl(null),
    })
  }

  newCalendar(): FormGroup {
    return this.fb.group({
      tipo: new FormControl({ value: 'CALENDARIO', disabled: true }, [Validators.required]),
      nombre: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', []),
      fecha: new FormControl('', [Validators.required]), // no lo llamo desde, porque hay mucho cargado ya, no quiero cambiar el nombre del campo
      fechaVencimiento: new FormControl('', [Validators.required]),
      link: new FormControl('', []),
    })
  }

  newAvailableDate(): FormGroup {
    return this.fb.group({
      tipo: new FormControl({ value: 'FECHAS DISPONIBLES', disabled: true }, [Validators.required]),
      descripcion: new FormControl('', []),
      fecha: new FormControl('', [Validators.required]), // no lo llamo desde, porque hay mucho cargado ya, no quiero cambiar el nombre del campo
      fechaVencimiento: new FormControl('', [Validators.required]),
    })
  }

  addActivity() {
    this.flagActivitiesPanel = true;
    this.actividades.push(this.newActivity());
  }

  removeActivity(i: number) {
    this.actividades.removeAt(i);
  }

  addDelegation() {
    if (this.pista) {
      this.fechasDisponibles.clear();
    }
    this.flagDelagationsPanel = true;
    this.delegaciones.push(this.newDelegation());
  }

  removeDelegation(index: number) {
    this.delegaciones.removeAt(index);
  }

  addCalendar() {
    this.flagCalendarsPanel = true;
    this.calendarios.push(this.newCalendar());
  }

  removeCalendar(index: number) {
    this.calendarios.removeAt(index);
  }

  addAvailableDate() {
    if (!this.pista) {
      this.delegaciones.clear();
    }
    this.flagAvailableDatesPanel = true;
    this.fechasDisponibles.push(this.newAvailableDate());
  }

  removeAvailableDate(index: number) {
    this.fechasDisponibles.removeAt(index);
  }

  findDelegacion(delegacionId: string) {
    this.delegationSelected = null;
    this.getDelegaciones().then(() => {
      this.delegationSelected = delegacionId;
    })
      .catch((error) => {
        console.error('Error al obtener la delegacion', error);
      });
  }

  loadActivity(actividad: CursoActividad): FormGroup {
    return this.fb.group({
      tipo: new FormControl({ value: actividad?.tipo || " sin tipo", disabled: true }, [Validators.required]),
      nombre: new FormControl(actividad?.nombre || "sin nombre", [Validators.required]),
      descripcion: new FormControl(actividad?.descripcion || "sin descripcion", []),
      fecha: new FormControl(actividad?.fecha || null, [Validators.required]),
      fechaVencimiento: new FormControl(actividad?.fechaVencimiento ? actividad?.fechaVencimiento : null),
      link: new FormControl(actividad?.link || "sin link asociado", []),
    })
  }

  // cargo paneles para modificar
  async loadPanels() {
    if (this.curso.actividades.length > 0) {
      let flagActividad = false;
      let flagDelegacion = false;
      let flagCalendario = false;
      let flagFechaDisponible = false;
      this.curso.actividades.forEach(actividad => {
        switch (actividad?.tipo) {
          case 'ACTIVIDAD':
            if (!flagActividad) {
              this.flagActivitiesPanel = true;
              flagActividad = true;
            }
            this.actividades.push(this.loadActivity(actividad));
            break;
          case 'DELEGACIÓN':
            if (!flagDelegacion) {
              this.flagDelagationsPanel = true;
              flagDelegacion = true;
            }
            this.findDelegacion(actividad?.nombre);
            this.delegaciones.push(this.loadActivity(actividad));
            break;
          case 'CALENDARIO':
            if (!flagCalendario) {
              this.flagCalendarsPanel = true;
              flagCalendario = true;
            }
            this.calendarios.push(this.loadActivity(actividad));
            break;
          case 'FECHAS DISPONIBLES':
            if (!flagFechaDisponible) {
              this.flagAvailableDatesPanel = true;
              flagFechaDisponible = true;
            }
            this.fechasDisponibles.push(this.loadActivity(actividad));
            break;
          default:
            console.error('Tipo no encontrado:', actividad.tipo);
        }
      })
    }
  }

  async setPaneles(pActividades: any[], pDelegaciones: any[], pCalendarios: any[], pFechasDisponibles: any[]): Promise<CursoActividad[]> {
    let paneles: CursoActividad[] = [];

    // Procesar actividades
    if (pActividades.length > 0) {
      pActividades.forEach((actividad: any) => {
        actividad = {
          tipo: 'ACTIVIDAD',
          nombre: actividad.nombre,
          descripcion: actividad.descripcion,
          fecha: actividad.fecha,
          fechaVencimiento: actividad.fechaVencimiento,
          link: actividad.link,
        }
        paneles.push(actividad);
      });
    }

    // Procesar delegaciones solo si es un curso por pista
    if (this.pista && pDelegaciones.length > 0) {
      pDelegaciones.forEach((delegacion: any) => {
        delegacion = {
          tipo: 'DELEGACIÓN',
          nombre: delegacion.nombre,
          descripcion: delegacion.descripcion,
          fecha: delegacion.fecha,
          fechaVencimiento: delegacion.fechaVencimiento,
        }
        paneles.push(delegacion);
      });
    }

    // Procesar calendarios
    if (pCalendarios.length > 0) {
      pCalendarios.forEach((calendario: any) => {
        calendario = {
          tipo: 'CALENDARIO',
          nombre: calendario.nombre,
          descripcion: calendario.descripcion,
          fecha: calendario.fecha,
          fechaVencimiento: calendario.fechaVencimiento,
          link: calendario.link,
        }
        paneles.push(calendario);
      });
    }

    // Procesar fechas disponibles solo si es un curso online
    if (!this.pista && pFechasDisponibles.length > 0) {
      pFechasDisponibles.forEach((fechasDisponibles: any) => {
        fechasDisponibles = {
          tipo: 'FECHAS DISPONIBLES',
          descripcion: fechasDisponibles.descripcion,
          fecha: fechasDisponibles.fecha,
          fechaVencimiento: fechasDisponibles.fechaVencimiento,
        }
        paneles.push(fechasDisponibles);
      });
    }

    return paneles;
  }

  /* FIN PANELES */

  createForm() {
    this.form = this.fb.group({

      nombre: new FormControl(this.curso.nombre, [Validators.required]),
      introduccion: new FormControl(this.curso.introduccion, [Validators.required]),
      descripcionGeneral: new FormControl(this.curso.descripcionGeneral, [Validators.required]),
      cantidadHoras: new FormControl(this.curso?.cantidadHoras, [Validators.required]),
      fechaInicio: new FormControl(this.curso.fechaInicio, [Validators.required]),
      fechaFin: new FormControl(this.curso.fechaFin, [Validators.required]),
      disponible: new FormControl(this.curso.disponible, [Validators.required]),
      modulo: new FormControl(this.curso.modulo, [Validators.required]),
      precio: new FormControl(this.curso.precio, [Validators.required]),
      pista: new FormControl(this.curso?.pista),
      actividades: new FormArray([],),
      delegaciones: new FormArray([],),
      calendarios: new FormArray([],),
      fechasDisponibles: new FormArray([],),
      imagen: new FormControl('',),
      video: new FormControl(),

    }, {
      validators: this.validadores.fechaFinMenor('fechaInicio', 'fechaFin')
    });
  }

  async setDataForma(): Promise<Curso> {

    const data: Curso = {
      nombre: this.form.value.nombre,
      introduccion: this.form.value.introduccion,
      descripcionGeneral: this.form.value.descripcionGeneral,
      cantidadHoras: this.form.value.cantidadHoras,
      fechaInicio: this.form.value.fechaInicio,
      fechaFin: this.form.value.fechaFin,
      precio: this.form.value.precio,
      modulo: this.form.value.modulo,
      disponible: this.form.value.disponible,
      pista: this.form.value.pista,
      actividades: await this.setPaneles(this.form.value.actividades, this.form.value.delegaciones, this.form.value.calendarios, this.form.value.fechasDisponibles),
    };

    return data;

  }

  async put() {

    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach(control => {
        if (control instanceof FormGroup) {
          control.markAsTouched();
        } else if (control instanceof FormArray) {
          control.controls.forEach(group => group.markAsTouched());
        }
      });
    }

    // Marcar fechaVencimiento como tocado si es null en el panel de delegaciones
    const delegacionesArray = this.form.get('delegaciones') as FormArray;
    delegacionesArray.controls.forEach((delegacionControl: FormGroup) => {
      if (delegacionControl.get('fechaVencimiento').value === null) {
        delegacionControl.get('fechaVencimiento').markAsTouched();
      }
    });

    await this.guiMsjService.msjFormSubmit('Guardando');
    this.cursosService.putCurso(this.curso.id, await this.setDataForma()).then(async (curso: Curso) => {

      if (this.selectedFileImg != null) {
        await this.uploadFileImg(this.selectedFileImg, this.curso._id);
      }

      if (this.selectedFileMp4 != null) {
        await this.uploadFileMp4(this.selectedFileMp4, this.curso._id);
      }
      await this.guiMsjService.msjFormSubmit('DatosGuardados');
      this.router.navigate(['/cursos-listado']);
      return;
    });
  }

  /* MÉTODOS PARA VALIDACIÓN DE FORMULARIO */

  fechaNoValidaFormArray(formArrayName: string) {
    let formArray = this.form.get(formArrayName) as FormArray;
    let invalid = false;
    formArray.controls.forEach(control => {
      if (control.get('fecha').invalid && control.get('fecha').touched) {
        invalid = true;
      }
      if (control.get('fechaVencimiento').invalid && control.get('fechaVencimiento').touched) {
        invalid = true;
      }
      if (control.get('nombre').invalid && control.get('nombre').touched) {
        invalid = true;
      }
    });
    return invalid;
  }

  validarFormArrays() {
    const formArrays = ['actividades', 'delegaciones', 'fechasDisponibles', 'calendarios'];
    let invalid = false;
    formArrays.forEach(formArrayName => {
      if (this.fechaNoValidaFormArray(formArrayName)) {
        invalid = true;
      }
    });
    return invalid;
  }

  get nombreNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  get introduccionNoValida() {
    return this.form.get('introduccion').invalid && this.form.get('introduccion').touched;
  }

  get descripcionGralNoValida() {
    return this.form.get('descripcionGeneral').invalid && this.form.get('descripcionGeneral').touched;
  }

  get cantidadHorasNoValida() {
    return this.form.get('cantidadHoras').invalid && this.form.get('cantidadHoras').touched;
  }

  get fechaInicioNoValida() {
    return this.form.get('fechaInicio').invalid && this.form.get('fechaInicio').touched;
  }

  get fechaFinNoValida() {
    return this.form.get('fechaFin').invalid && this.form.get('fechaFin').touched;
  }

  get precioNoValido() {
    return this.form.get('precio').invalid && this.form.get('precio').touched;
  }

  /* FIN MÉTODOS VALIDACION FORMULARIO */

  /* subo archivo con observable*/
  subscribeAvanceUpload() {
    this.subscriptionAvance = this.mediaService.getAvance()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(resp => {
        if (resp) {
          this.value = resp.value;
        } else {
          this.value = 0;
        }
      });
  }

  openFileImgSelected(event) {
    this.selectedFileImg = <File>event.target.files[0];
  }

  onChangeFile(file: File, event) {
    if (file) {
      this.openFileImgSelected(event);
      this.fileName = file.name;
      this.file = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = e => {
        this.imageUrl = reader.result;
      }
    }
  }

  async uploadFileImg(selectedFile: any, idCurso: string) {
    await this.mediaService.uploadImgCursoPresentacion(selectedFile, idCurso).then(resp => {
      if (resp) {
        if (this.value === 100) { this.cargandoArchivo = false; }
      } else {
        this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
      }
    });
  }

  openFileMp4Selected(event: any) {
    this.selectedFileMp4 = <File>event.target.files[0];
  }

  async uploadFileMp4(selectedFile: any, idCurso: string) {
    this.cargandoArchivo = true;
    await this.mediaService.uploadMp4CursoPresentacion(selectedFile, idCurso).then(resp => {
      if (resp) {
        if (this.value === 100) { this.cargandoArchivo = false; }
      } else {
        this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
      }
    });
  }

}
