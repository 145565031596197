import { NgModule } from "@angular/core";
import { LoadingComponent } from '../components/common/loading/loading.component';
@NgModule({
  declarations: [
    LoadingComponent,
  ],
  imports:[
  ],
  exports:[
    LoadingComponent
  ],
})
export class CommonComponentsModule { }
