<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/profesores-configuracion">Configuración Profesores</a></li>
                <li>Listado Profesores</li>
            </ul>
            <h2>Profesores</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <h3>Listado de Profesores</h3>
            <a routerLink="/profesor-alta"><i class="fas fa-plus"></i> Agregar Nuevo</a>

            <hr>
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Column" autocomplete="off" #input>
            </mat-form-field>

            <div class="mat-elevation-z8">

                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Column -->
                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{element.nombre || 'SIN DATOS PERSONALES'}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="apellido">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido </th>
                        <td mat-cell *matCellDef="let element"> {{element.apellido || 'SIN DATOS PERSONALES'}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="estado">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.estado === false"><span class="badge bg-success">Activo</span></div>
                            <div *ngIf="element.estado === true"><span class="badge bg-danger">Suspendido</span></div>
                        </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Creado </th>
                        <td mat-cell *matCellDef="let element"> {{element.created | date:"dd/MM/yy h:mm a"}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="verProfesor(element._id)" type="button" class="btn btn-warning"><i class="fas fa-edit"></i></button>
                        </td>
                    </ng-container>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>
</div>