<app-loading *ngIf='!this.flagLoaded'>

</app-loading>

<div class="courses-area ptb-100"> 

    <div class="container">

        <app-loading *ngIf='!this.flagLoaded' class="m-5">
        </app-loading>
        
        <mat-paginator *ngIf="this.flagLoaded" [length]="this.actividadesDisponibles.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getPaginatorData($event)" aria-label="Select page" showFirstLastButtons="false">
        </mat-paginator>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 animate__animated animate__fadeIn" *ngFor="let actividad of (this.actividadesDisponibles |
        slice: lowValue : highValue)" [newspaper]="actividad">
                <app-tarjeta [click]="true" [img]="true" [video]="false" [price]="true" [actividad]="actividad"></app-tarjeta>
            </div>
        </div>

    </div>

</div>

