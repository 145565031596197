import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Examen, Pregunta } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { MediaService } from 'src/app/services/shared/media.service';

@Component({
  selector: 'app-evaluacion-input-preguntas',
  templateUrl: './evaluacion-input-preguntas.component.html',
  styleUrls: ['./evaluacion-input-preguntas.component.scss']
})
export class EvaluacionInputPreguntasComponent implements OnInit {

  @Input() preguntaConMultimedia: Pregunta;
  @Input() pregunta: string;
  @Input() preguntas: Pregunta[];
	@Input() index: number;
  @Input() preguntaID: string;
  @Input() examenID: string;
  @Output() modificacion: EventEmitter<string>;
  form: FormGroup;

  constructor(
    private examenService: ExamenesService,
    private guiMsjService: GuiMsjService,
    private mediaSerivice: MediaService,
     ) { 
    this.modificacion = new EventEmitter();
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(){
    this.form = new FormGroup({
      enunciado: new FormControl(this.pregunta, Validators.required)
    })
  }
  async updatePregunta(posicion: number){
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    this.preguntas[posicion].enunciado = this.form.value.enunciado;
    this.examenService.setPreguntas$(this.preguntas);
    const preguntas = {
      preguntas: this.preguntas
    }
    this.examenService.updatePreguntasExamen(this.examenID,preguntas).then(async (data: Pregunta[])=>{
      await this.guiMsjService.msjFormSubmit('DatosGuardados');
    })
  }

  borrarPregunta(pregunta: number, idPregunta: string){
    Swal.fire({
      title: '¿Confirma eliminar pregunta: '+ this.preguntas[pregunta].enunciado + ' ?',
      text: 'Se borrará completamente del examen',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.value) {
        this.preguntas.forEach(function(item, index, object) {
          if (idPregunta === item._id) {
            object.splice(pregunta, 1);
          }
        });
        this.examenService.setPreguntas$(this.preguntas);      
        const preguntas = {
          preguntas: this.preguntas
        }
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        this.examenService.updatePreguntasExamen(this.examenID,preguntas).then(async(examen: Examen)=>{
          this.examenService.setExamen(examen);
          await this.guiMsjService.msjFormSubmit('DatosGuardados');
        })
      }
    });
  }

  getTipoArchivo( tipo: string): string {
    const tipoArchivo = this.mediaSerivice.getTipoArchivo(tipo);
    return tipoArchivo;
  }
}
