import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Materia } from 'src/app/interfaces/interface-bakend/cursos/materia.interface';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { CursosService } from '../../../../services/cursos/cursos.service';

@Component({
  selector: 'app-materia-alta',
  templateUrl: './materia-alta.component.html',
  styleUrls: ['./materia-alta.component.scss']
})
export class MateriaAltaComponent implements OnInit {

  form: FormGroup;
  currentDate = new Date().toDateString();
  selectedFileImg: any;
  condition = false;
  fileName = 'No file selected';
  file: File;

    /* ripple */
  centered = false;
  disabled = false;
  unbounded = false;

  /* img portada */
  fileAttr = 'Choose File';
  radius: number;
  color: string;

  imageUrl: string | ArrayBuffer =
  './assets/img/uploadImg.png';

  constructor( private cursosService: CursosService, 
    private route : Router,
    private guiMsjService: GuiMsjService ) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  get nombreNoValido(){
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  get descripcionNoValida(){
    return this.form.get('descripcion').invalid && this.form.get('descripcion').touched;
  }

  get fechaNoValida(){
    return this.form.get('fechaInicio').invalid && this.form.get('fechaInicio').touched;
  }

  get fechaFinNoValida(){
    return this.form.get('fechaFin').invalid && this.form.get('fechaFin').touched;
  }

  createForm() {
    this.form = new FormGroup({
        nombre: new FormControl('', [ Validators.required]),
        descripcion: new FormControl('', [ Validators.required]),
        fechaInicio: new FormControl('', [ Validators.required]),
        fechaFin: new FormControl('', [ Validators.required]),
        imagen: new FormControl('',),
        enlace: new FormControl('',)
    });
  }

  async post(){

    await this.guiMsjService.msjFormSubmit('Guardando');
    this.cursosService.post(await this.setDataForma()).then( async (materia: Materia) => {
        // await this.uploadFile(this.selectedFile, leccion._id);
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.route.navigateByUrl('/materias-listado');
        return;
    });

  }

  openFileImgSelected(event) {
    this.selectedFileImg = <File> event.target.files[0];
  }

  onChangeFile(file: File, event){
    if(file){
      this.openFileImgSelected(event);
      this.fileName = file.name;
      this.file = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = e => {
        this.imageUrl = reader.result;
      }
    }
  }

  async setDataForma(): Promise<Materia>{

    const data: Materia = {
      nombre: this.form.value.nombre,
      description: this.form.value.descripcion,
      fechaInicio: this.form.value.fechaInicio,
      fechaFin: this.form.value.fechaFin,
      imagen: this.form.value.imagen,
      enlace: this.form.value.enlace
    };

    return data;

  }

}


