<app-loading *ngIf="!this.flagLoaded" class="m-5"></app-loading>
<div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
<div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
<div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>

<div *ngIf="this.flagLoaded" class="premium-access-area bg-f9f9f9 ptb-70 animate__animated animate__fadeIn">
    <div class="container">
        <div class="premium-access-content-style-two">
            <div class="row mx-auto">

                <div class="col-lg-10 col-md-12">
                    <div class="apply-form">
                        <mat-card class="material-card">

                            <mat-card-header *ngIf="this.isEditable">
                                <mat-card-title>Asignar Examen</mat-card-title>
                                <!-- <mat-card-title>Título Examen: {{ this.examen.titulo }}</mat-card-title> -->
                            </mat-card-header>

                            <div class="alert alert-info" role="alert">
                                <strong>ATENCIÓN:</strong> Si asigna un examen individual o grupal y este examen ya ha alcanzado el número máximo de intentos permitidos a través del curso, no podrá ser rendido de ninguna manera.
                            </div>
                            <mat-card-content>
                                Puede asignar un examen, a un curso, un usuario o grupo de afinidad. Asi como tambien a más de uno en cualquiera de las categorias.
                                <br>
                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                Asignar examen a uno o más cursos
                                            </mat-panel-title>
                                            <mat-panel-description>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <app-grilla-cursos (cursoAsignado)="( this.cursoAsignado = $event )"></app-grilla-cursos>

                                    </mat-expansion-panel>
                                </mat-accordion>

                                <br>
                                <mat-divider></mat-divider>
                                <br>

                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                Asignar examen a uno o más usuarios
                                            </mat-panel-title>
                                            <mat-panel-description>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>

                                        <app-grilla-usuarios (usuarioAsignado)="( this.usuarioAsignado = $event )"></app-grilla-usuarios>

                                    </mat-expansion-panel>
                                </mat-accordion>

                                <br>
                                <!-- <mat-divider></mat-divider>
                                <br>

                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                Asignar examen a un grupo de afinidad
                                            </mat-panel-title>
                                            <mat-panel-description>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>

                                        <app-grilla-grupo-afinidad (grupoAsignado)="( this.grupoAsignado = $event )"></app-grilla-grupo-afinidad>

                                    </mat-expansion-panel>
                                </mat-accordion>
                                <br>
                                <mat-divider></mat-divider> -->
                                <br>
                                <div class="animate__animated animate__fadeInUp" *ngIf="this.cursoAsignado || this.grupoAsignado || this.usuarioAsignado">
                                    <mat-card style="background-color: '#666699'">
                                        <mat-card-subtitle style="color:white;" *ngIf="this.cursoAsignado">Asignado a Curso/s.</mat-card-subtitle>
                                        <mat-card-subtitle style="color:white;" *ngIf="this.grupoAsignado">Asignado a a Grupo/s Afinidad.</mat-card-subtitle>
                                        <mat-card-subtitle style="color:white;" *ngIf="this.usuarioAsignado">Asignado a Usuario/s.</mat-card-subtitle>
                                    </mat-card>
                                    <mat-card-content>
                                    </mat-card-content>
                                    <mat-card-actions>
                                        <button mat-raised-button color="warn" (click)="finalizar()">Salir</button>
                                    </mat-card-actions>
                                </div>

                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>