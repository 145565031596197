<div class="page-title-area">
    <div class="container">
        <div>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('es')"><img src="assets/img/flags/espana.png" alt="Español" width="20"> ES</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('en')"><img src="assets/img/flags/usa.png" alt="English" width="20"> EN</button>
        </div>
        <div class="page-title-content">
            <h2>
                <a routerLink="/"><img src="assets/img/logo-aadidess-white.png" alt="ski-andorra" width="30%"></a><br></h2>
        </div>
        <div class="page-title-content">
            <h2 *ngIf="this.CFR === this.type">{{ idioma | translate: "legales.LEGAL-CTF-1" | async}}</h2>
            <h2 *ngIf="this.CFA === this.type">{{ idioma | translate: "legales.LEGAL-CTF-2" | async}}</h2>
            <h2 *ngIf="this.TC === this.type">{{ idioma | translate: "legales.LEGAL-CTF-TC" | async}}</h2>
            <h2 *ngIf="this.PDP === this.type">{{ idioma | translate: "legales.LEGAL-CTF-PDP" | async}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="purchase-guide-area mt-5">
    <div class="container">
        <div class="row" *ngIf="this.CFR === this.type">
            <app-formulario-registro></app-formulario-registro>
        </div>
        <div class="row" *ngIf="this.CFA === this.type">
            <app-formulario-ayuda></app-formulario-ayuda>
        </div>
        <div class="row" *ngIf="this.TC === this.type">
            <app-terminos-condiciones></app-terminos-condiciones>
        </div>
        <div class="row" *ngIf="this.PDP === this.type">
            <app-proteccion-de-datos></app-proteccion-de-datos>
        </div>
    </div>
</div>


<mat-dialog-actions align="center">
    <button mat-raised-button mat-button [mat-dialog-close]="true">{{ idioma | translate: "form.BTN-CERRAR" | async}}</button>
</mat-dialog-actions>