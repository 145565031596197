import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ClausulaAadidessIsesComponent } from '../../shared/modal-legal/clausula-aadidess-ises/clausula-aadidess-ises.component';
import { MatDialog } from '@angular/material/dialog';
const urlOpenBlank = environment.urlOpenBlank;
const urlOpenPagesHtml = environment.urlOpenPagesHtml;

@Injectable({
  providedIn: 'root'
})
export class OpenPageService {

  constructor(
    private dialog: MatDialog,
  ) { }

  openBlank(type: string) {
    switch (type) {
      case 'CAADIDESS-I.S.E.S':// cláusula formulario registro
         window.open(urlOpenBlank + 'clausula-aadidess-i.s.e.s' ,"_blank");
        break;
      case 'CFR':// cláusula formulario registro
         window.open(urlOpenBlank + 'formulario-registro' ,"_blank");
        break;
      case 'CFA':// cláusula formulario ayuda
         window.open(urlOpenBlank + 'formulario-ayuda' ,"_blank");
        break;
      case 'TC': // términos y condiciones
       window.open(urlOpenBlank + 'terminos-y-condiciones' ,"_blank");
        break;
      case 'PDP': // protección datos personales 
       window.open(urlOpenPagesHtml + 'cookiebot/proteccion-datos-personales-es.html' ,"_blank");
        break;
      default:
        break;
    }
  }

  openModal(type: string) {
    switch (type) {
      case 'CAADIDESS-I.S.E.S':// cláusula formulario registro
        const dialogRef = this.dialog.open(ClausulaAadidessIsesComponent,{
          width: '800px',
          height: '800px',
        });
        dialogRef.afterClosed().subscribe(async result => {});
        break;
      default:
        break;
    }
  }

}
