import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SolicitudesService } from 'src/app/services/ofertas-laborales/solicitudes.service';
import { PaymentsService } from 'src/app/services/shared/payments.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

const URL_SITIO = environment.urlSitio;

@Component({
    selector: 'app-macro-pagos-form',
    templateUrl: './macro-pagos-form.component.html',
    styleUrls: ['./macro-pagos-form.component.scss']
})
export class MacroPagosFormComponent implements OnInit {

    loadingMacroPagos: boolean = false;
    isPaymentProcessed: boolean = false;
    loading = false;
    tienePayment: boolean = false;
    solicitudId: string | null = null;  // Variable para almacenar el ID de la solicitud

    constructor(
        private fb: FormBuilder,
        private solicitudesService: SolicitudesService,
        private paymentsService: PaymentsService,
        private router: Router,
        private route: ActivatedRoute  // Inyectamos ActivatedRoute para obtener parámetros de la URL
    ) { }

    ngOnInit(): void {
        // Capturamos el parámetro 'solicitud' desde la URL
        this.route.queryParamMap.subscribe(async params => {
            this.solicitudId = params.get('solicitud');

            await this.getSolicitud(this.solicitudId);

            // Verifica si solicitudId tiene un valor y usa setTimeout para llamar a createPaymentMacro después de 5 segundos
            if (this.solicitudId != null) {
                setTimeout(() => {
                    this.createPaymentMacro();
                }, 5000); // 5 segundos de espera
            }
        });
    }

    async getSolicitud(id: string) {
        const solicitud = await this.solicitudesService.getSolicitudById(id);
        const invoices = solicitud.invoices[0];

        if (invoices) {
            await this.getPaymentByInvoice(solicitud?.invoices[0]._id);
        }

        // Verifica si tiene un pago
        if (this.tienePayment) {
            // Mostrar mensaje de error con SweetAlert
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No es posible pagar esta solicitud.',
                confirmButtonText: 'Aceptar'
            });
            this.router.navigateByUrl(URL_SITIO);
            return;
        }
    }

    getMacroPagos(): Boolean {
        return this.solicitudesService.getMacroPagosActivo();
    }

    async getPaymentByInvoice(invoice: string) {
        const payment = await this.paymentsService.getPaymentBySolicitud(invoice);
        this.tienePayment = payment == null || payment == undefined ? false : true;
    }

    createPaymentMacro() {
        this.loadingMacroPagos = true;
        this.solicitudesService.createPagoMacroPagos(this.solicitudId).then((formHtml: string) => {
            // Inserta el formulario embebido dentro de un contenedor específico
            const container = document.getElementById('payment-container-macro');
            if (container) {
                container.innerHTML = formHtml;
                this.isPaymentProcessed = true;
                this.loadingMacroPagos = false;

                // Autoenvía el formulario después de insertarlo en el contenedor
                const form = document.getElementById('form-firma') as HTMLFormElement;

                if (form) {
                    form.submit();
                } else {
                    console.error('Formulario no encontrado');
                }
            } else {
                console.error('Contenedor de formulario no encontrado');
                this.loadingMacroPagos = false;
            }
        }).catch((error) => {
            console.error('Error al procesar el pago', error);
            this.loadingMacroPagos = false;
        });
    }
}