import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../components/pages/home/home.component';
import { NgModule } from '@angular/core';

/* COMPONENTES DE SHARED */
import { OfertasEstadisticasComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-estadisticas/ofertas-estadisticas.component';

/* COMPONENTES DE OFERTAS LABORALES  */
import { OfertasConfiguracionComponent } from '../components/ofertas-laborales/pages/ofertas-configuracion/ofertas-configuracion.component';
import { OfertasAltaComponent } from '../components/ofertas-laborales/pages/ofertas-alta/ofertas-alta.component';
import { OfertaModificacionComponent } from '../components/ofertas-laborales/pages/oferta-modificacion/oferta-modificacion.component';
import { OfertasLaboralesListadoComponent } from '../components/ofertas-laborales/pages/ofertas-laborales-listado/ofertas-laborales-listado.component';
import { CatalogoConfiguracionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/catalogo-configuracion.component';
import { AreasConfiguracionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/areas-configuracion/areas-configuracion.component';
import { ModalidadesConfiguracionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/modalidades-configuracion/modalidades-configuracion.component';
import { TipoPuestosConfiguracionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/tipo-puestos-configuracion/tipo-puestos-configuracion.component';
import { AreasListadoComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/areas-configuracion/areas-listado/areas-listado.component';
import { AreaAltaComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/areas-configuracion/area-alta/area-alta.component';
import { AdjuntosConfiguracionComponent } from '../components/ofertas-laborales/pages/adjuntos-configuracion/adjuntos-configuracion.component';


/* COMPONENTES DE CURSOS */

// VENTA CURSOS
import { CursosDisponiblesCompraComponent } from '../components/cursos/pages/cursos-disponibles-compra/cursos-disponibles-compra.component';
import { CursoDescripcionCompraComponent } from '../components/cursos/pages/curso-descripcion-compra/curso-descripcion-compra.component';
import { CursoCompraComponent } from '../components/cursos/pages/curso-compra/curso-compra.component';

// CURSOS ALUMNO
import { CursoHabilitadoAlumnoComponent } from '../components/cursos/pages/curso-habilitado-alumno/curso-habilitado-alumno.component';
import { CursosPerfilAlumnoComponent } from '../components/cursos/pages/cursos-perfil-alumno/cursos-perfil-alumno.component';

// ABM Cursos
import { CursoAltaComponent } from '../components/cursos/pages/curso-alta/curso-alta.component';
import { CursoModificacionComponent } from '../components/cursos/pages/curso-modificacion/curso-modificacion.component';
import { CursosConfiguracionComponent } from '../components/cursos/pages/cursos-configuracion/cursos-configuracion.component';
import { CursosListadoComponent } from '../components/cursos/pages/cursos-listado/cursos-listado.component';

// ABM TITULOS

import { TitulosListadoComponent } from '../components/cursos/pages/titulos-listado/titulos-listado.component';
// ABM Materias
import { MateriaAltaComponent } from '../components/cursos/pages/materia-alta/materia-alta.component';
import { MateriaModificacionComponent } from '../components/cursos/pages/materia-modificacion/materia-modificacion.component';
import { MateriasConfiguracionComponent } from '../components/cursos/pages/materias-configuracion/materias-configuracion.component';
import { MateriasListadoComponent } from '../components/cursos/pages/materias-listado/materias-listado.component';

// ABM Lecciones
import { LeccionAltaComponent } from '../components/cursos/pages/leccion-alta/leccion-alta.component';
import { LeccionModificacionComponent } from '../components/cursos/pages/leccion-modificacion/leccion-modificacion.component';
import { LeccionesConfiguracionComponent } from '../components/cursos/pages/lecciones-configuracion/lecciones-configuracion.component';
import { LeccionesListadoComponent } from '../components/cursos/pages/lecciones-listado/lecciones-listado.component';

// ABM Cátedras
import { CatedraAltaComponent } from '../components/cursos/pages/catedra-alta/catedra-alta.component';
// import { LeccionModificacionComponent } from '../components/cursos/pages/leccion-modificacion/leccion-modificacion.component';
import { CatedrasConfiguracionComponent } from '../components/cursos/pages/catedras-configuracion/catedras-configuracion.component';
// import { LeccionesListadoComponent } from '../components/cursos/pages/lecciones-listado/lecciones-listado.component';

// ABM Evaluaciones
import { EvaluacionAltaComponent } from '../components/cursos/pages/evaluacion-alta/evaluacion-alta.component';
import { EvaluacionPreguntasConfigComponent } from '../components/cursos/pages/evaluacion-preguntas-config/evaluacion-preguntas-config.component';
import { RespuestasAltaComponent } from '../components/cursos/pages/evaluacion-preguntas-config/respuestas-alta/respuestas-alta.component';

// ABM Usuarios
import { UsuariosListadoComponent } from '../components/usuarios/pages/usuarios-listado/usuarios-listado.component';
import { UsuarioModificacionComponent } from '../components/usuarios/pages/usuario-modificacion/usuario-modificacion.component';
import { UsuariosAdministracionComponent } from '../components/usuarios/pages/usuarios-administracion/usuarios-administracion.component';
import { UsuariosAltaComponent } from '../components/usuarios/pages/usuarios-alta/usuarios-alta.component';

// Personas listado
import { PersonasListadoComponent } from '../components/usuarios/pages/personas-listado/personas-listado.component';
import { PersonaModificacionComponent } from '../components/usuarios/pages/persona-modificacion/persona-modificacion.component';


// Mi Cuenta
import { CuentaComponent } from '../components/usuarios/pages/cuenta/cuenta.component';

/* FIN COMPONENTES DE CURSOS */

/* COMPONENTES DE pre inscripción */

// Actividades Disponibles
import { OfertaBuscadorPaisesComponent } from '../components/ofertas-laborales/pages/oferta-buscador-paises/oferta-buscador-paises.component';
import { OfertaBuscadorPaisesBannerComponent } from '../components/ofertas-laborales/pages/oferta-buscador-paises/oferta-buscador-paises-banner/oferta-buscador-paises-banner.component';
import { ActividadesDisponiblesPaisComponent } from '../components/ofertas-laborales/pages/actividades-disponibles-pais/actividades-disponibles-pais.component';
import { ActividadDetalleComponent } from '../components/ofertas-laborales/pages/actividad-detalle/actividad-detalle.component';
import { ActividadPostularComponent } from '../components/ofertas-laborales/pages/actividad-postular/actividad-postular.component';
import { UsuariosSolicitudesComponent } from '../components/ofertas-laborales/pages/usuario-solicitudes/usuario-solicitudes.component';
import { SolicitudPostularComponent } from '../components/ofertas-laborales/pages/solicitud-postular/solicitud-postular.component';
import { SolicitudPostularDocumentacionComponent } from '../components/ofertas-laborales/pages/solicitud-postular-documentacion/solicitud-postular-documentacion.component';
import { OfertasListarComponent } from '../components/ofertas-laborales/pages/ofertas-listar/ofertas-listar-listado/ofertas-listar.component';
import { OfertasDisponiblesComponent } from '../components/ofertas-laborales/pages/ofertas-disponibles/ofertas-disponibles.component';
import { SolicitudComponent } from '../components/ofertas-laborales/pages/solicitud/solicitud.component';
import { OfertasAdministracionComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-administracion.component';
import { OfertasListadoComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-listado/ofertas-listado.component';
import { OfertasSolicitudComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-solicitud/ofertas-solicitud.component';
import { PagarSaldoComponent } from '../components/ofertas-laborales/pages/pagar-saldo/pagar-saldo.component';
import { AdjuntosListadoComponent } from '../components/ofertas-laborales/pages/adjuntos-configuracion/adjuntos-listado/adjuntos-listado.component';
import { AdjuntoAltaComponent } from '../components/ofertas-laborales/pages/adjuntos-configuracion/adjunto-alta/adjunto-alta.component';
import { AdjuntoModificacionComponent } from '../components/ofertas-laborales/pages/adjuntos-configuracion/adjunto-modificacion/adjunto-modificacion.component';
import { ModalidadesListadoComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/modalidades-configuracion/modalidades-listado/modalidades-listado.component';
import { TipoPuestosListadoComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/tipo-puestos-configuracion/tipo-puestos-listado/tipo-puestos-listado.component';
import { ModalidadAltaComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/modalidades-configuracion/modalidad-alta/modalidad-alta.component';
import { TipoPuestoAltaComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/tipo-puestos-configuracion/tipo-puesto-alta/tipo-puesto-alta.component';
import { AreaModificacionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/areas-configuracion/area-modificacion/area-modificacion.component';
import { ModalidadModificacionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/modalidades-configuracion/modalidad-modificacion/modalidad-modificacion.component';
import { TipoPuestoModificacionComponent } from '../components/ofertas-laborales/pages/catalogo-configuracion/tipo-puestos-configuracion/tipo-puesto-modificacion/tipo-puesto-modificacion.component';
import { InvoicesListadoComponent } from '../components/ofertas-laborales/pages/invoices-listado/invoices-listado.component';
import { InvoiceDetalleComponent } from '../components/ofertas-laborales/pages/invoices-listado/invoice-detalle/invoice-detalle.component';
import { QuienesSomos } from '../components/pages/quienes-somos/quienes-somos.component';
import { PayPalButtonComponent } from '../components/common/pay-pal-button/pay-pal-button.component';
import { PlataformaGuia } from '../components/pages/plataforma-guia/plataforma-guia.component';
import { BuscadorAereosComponent } from '../components/pages/home/buscador-aereos/buscador-aereos.component';
import { ComprobantesTransfListadoComponent } from '../components/common/comprobantes-transf-listado/comprobantes-transf-listado.component';
import { ExamenesListadoComponent } from '../components/cursos/pages/examenes-listado/examenes-listado.component';
import { TermsOfServiceComponent } from '../components/pages/terms-of-service/terms-of-service.component';
import { ExamenIntroduccionComponent } from '../components/cursos/pages/examen-introduccion/examen-introduccion.component';
// import { TransferenciaButtonComponent } from '../components/common/transferencia-button/transferencia-button.component';
import { ProfesorAltaComponent } from '../components/cursos/pages/profesor-alta/profesor-alta.component';
import { ProfesoresConfiguracionComponent } from '../components/cursos/pages/profesores-configuracion/profesores-configuracion.component';
import { ProfesoresListadoComponent } from '../components/cursos/pages/profesores-listado/profesores-listado.component';
import { ExamenesAlumnoComponent } from '../components/cursos/pages/examenes-alumno/examenes-alumno.component';
import { ExamenResultadoComponent } from '../components/cursos/pages/examen-resultado/examen-resultado.component';
import { ExamenCursarComponent } from '../components/cursos/pages/examen-cursar/examen-cursar.component';
import { FormContactoComponent } from '../components/pages/form-contacto/form-contacto.component';
import { ExitGuard } from '../guards/exit.guard';
import { TableFilterPreInscripcionesComponent } from '../components/cursos/pages/table-filter-pre-inscripciones/table-filter-pre-inscripciones.component';
import { ExamenesEvaluacionesResultadosComponent } from '../components/cursos/pages/examenes-evaluaciones-resultados/examenes-evaluaciones-resultados.component';
import { ExamenEvaluacionDetalleComponent } from '../components/cursos/pages/examen-resultado-detalle/examen-evaluacion-detalle.component';
import { EvaluacionModificarComponent } from '../components/cursos/pages/evaluacion-alta-modificar/evaluacion-modificar.component';
import { ExamenRecuperatorioComponent } from '../components/cursos/pages/examen-recuperatorio/examen-recuperatorio.component';
import { CursosListadoAlumnoCursosComponent } from '../components/cursos/pages/cursos-listado-alumno-cursos/cursos-listado-alumno-cursos.component';
import { CursosAlumnosModificarComponent } from '../components/cursos/pages/cursos-alumnos-modificar/cursos-alumnos-modificar.component';
import { TituloAltaComponent } from '../components/cursos/pages/titulo-alta/titulo-alta.component';
import { TituloModificacionComponent } from '../components/cursos/pages/titulo-modificacion/titulo-modificacion.component';
import { MesasListadoComponent } from '../components/cursos/pages/mesas-listado/mesas-listado.component';
import { MesaAltaComponent } from '../components/cursos/pages/mesa-alta/mesa-alta.component';
import { MesaModificacionComponent } from '../components/cursos/pages/mesa-modificacion/mesa-modificacion.component';
import { GestosConfiguracionComponent } from '../components/cursos/pages/gestos-configuracion/gestos-configuracion.component';
import { GestosListadoComponent } from '../components/cursos/pages/gestos-listado/gestos-listado.component';
import { GestoAltaComponent } from '../components/cursos/pages/gesto-alta/gesto-alta.component';
import { GestoModificacionComponent } from '../components/cursos/pages/gesto-modificacion/gesto-modificacion.component';
import { GestosEvaluacionesListadoComponent } from '../components/cursos/pages/gestos-evaluaciones-listado/gestos-evaluaciones-listado.component';
import { GestosEvaluacionesUsuariosListadoComponent } from '../components/cursos/pages/gestos-evaluaciones-usuarios-listado/gestos-evaluaciones-usuarios-listado.component';
import { GestoEvaluacionModificacionComponent } from '../components/cursos/pages/gesto-evaluacion-modificacion/gesto-evaluacion-modificacion.component';
import { GestosConfiguracionUsuarioComponent } from '../components/cursos/pages/gestos-configuracion-usuario/gestos-configuracion-usuario.component';
import { DocumentoUsuarioAltaComponent } from '../components/usuarios/pages/documento-usuario-alta/documento-usuario-alta.component';
import { DocumentosUsuariosConfiguracionComponent } from '../components/usuarios/pages/documentos-usuarios-configuracion/documentos-usuarios-configuracion.component';
import { DocumentoUsuarioModificacionComponent } from '../components/usuarios/pages/documento-usuario-modificacion/documento-usuario-modificacion.component';
import { DocumentosUsuariosListadoComponent } from '../components/usuarios/pages/documentos-usuarios-listado/documentos-usuarios-listado.component';
import { OfertasListadoCursosComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-listado-cursos/ofertas-listado-cursos.component';
import { FormularioInscripcionComponent } from '../components/pages/formulario-inscripcion/formulario-inscripcion.component';
import { MercadoPagoResultadoComponent } from '../components/cursos/pages/mercado-pago/mercado-pago-resultado/mercado-pago-resultado';
import { BoletinesListadoComponent } from '../components/cursos/pages/boletines-listado/boletines-listado.component';
import { BoletinAltaComponent } from '../components/cursos/pages/boletin-alta/boletin-alta.component';
import { MateriaBoletinAltaComponent } from '../components/cursos/pages/materia-boletin-alta/materia-boletin-alta.component';
import { MateriasBoletinListadoComponent } from '../components/cursos/pages/materias-boletin-listado/materias-boletin-listado.component';
import { MateriaBoletinModificacionComponent } from '../components/cursos/pages/materia-boletin-modificacion/materia-boletin-modificacion.component';
import { BoletinModificacionComponent } from '../components/cursos/pages/boletin-modificacion/boletin-modificacion.component';
import { AlumnoBoletinesListadoComponent } from '../components/cursos/pages/alumno-boletines-listado/alumno-boletines-listado.component';
import { BoletinNombreAltaComponent } from '../components/cursos/pages/boletin-nombre-alta/boletin-nombre-alta.component';
import { BoletinNombreModificacionComponent } from '../components/cursos/pages/boletin-nombre-modificacion/boletin-nombre-modificacion.component';

/* FIN COMPONENTES DE pre inscripción */


/* ADMINISTRO RUTAS HIJAS DASHBOARD */
export const dashBoardRoutes: Routes = [

    { path: '', component: HomeComponent },
    { path: 'quienes-somos', component: QuienesSomos },
    { path: 'plataforma-guia', component: PlataformaGuia },
    { path: 'buscador-aereos', component: BuscadorAereosComponent },
    { path: 'terminos-condiciones-registro', component: TermsOfServiceComponent},
    { path: 'formulario-contacto', component: FormContactoComponent},

    // Rutas Ofertas Laboral
    { path: 'ofertas-configuracion', component: OfertasConfiguracionComponent },
    { path: 'ofertas-administracion', component: OfertasAdministracionComponent },
    { path: 'ofertas-administracion-listado', component: OfertasListadoComponent},
    { path: 'solicitudes-por-cursos', component: OfertasListadoCursosComponent},
    { path: 'ofertas-alta', component: OfertasAltaComponent },
    { path: 'pre-inscripciones-listado', component: OfertasLaboralesListadoComponent },
    { path: 'oferta-modificacion', component: OfertaModificacionComponent },
    { path: 'ofertas-listar', component: OfertasListarComponent },
    { path: 'ofertas-disponibles', component: OfertasDisponiblesComponent },
    { path: 'ofertas-solicitud', component: OfertasSolicitudComponent },
    { path: 'ofertas-estadisticas', component: OfertasEstadisticasComponent},
    { path: 'ofertas-pagar-saldo', component: PagarSaldoComponent},
    { path: 'invoices-listado', component: InvoicesListadoComponent},
    { path: 'invoice-detalle', component: InvoiceDetalleComponent },
    { path: 'comprobantes-transferencia', component: ComprobantesTransfListadoComponent },

    // Ruta Catalogo
    { path: 'catalogo-configuracion', component: CatalogoConfiguracionComponent},

    // Rutas Áreas
    { path: 'areas-configuracion', component: AreasConfiguracionComponent},
    { path: 'areas-listado', component: AreasListadoComponent},
    { path: 'area-alta', component: AreaAltaComponent},
    { path: 'area-modificacion', component: AreaModificacionComponent},

    // Rutas Modalidad
    { path: 'modalidades-configuracion', component: ModalidadesConfiguracionComponent},
    { path: 'modalidades-listado', component: ModalidadesListadoComponent},
    { path: 'modalidad-alta', component: ModalidadAltaComponent},
    { path: 'modalidad-modificacion', component: ModalidadModificacionComponent},

    // Rutas Tipo Puestos
    { path: 'tipo-puestos-configuracion', component: TipoPuestosConfiguracionComponent},
    { path: 'tipo-puestos-listado', component: TipoPuestosListadoComponent},
    { path: 'tipo-puesto-alta', component: TipoPuestoAltaComponent},
    { path: 'tipo-puesto-modificacion', component: TipoPuestoModificacionComponent},

    // Rutas Adjuntos
    { path: 'adjuntos-configuracion', component: AdjuntosConfiguracionComponent},
    { path: 'adjuntos-listado', component: AdjuntosListadoComponent},
    { path: 'adjunto-alta', component: AdjuntoAltaComponent},
    { path: 'adjunto-modificacion', component: AdjuntoModificacionComponent},

    // Rutas documentos generales a solicitar por el sistema al usuario
    { path: 'documentos-usuarios-configuracion', component: DocumentosUsuariosConfiguracionComponent},
    { path: 'documento-usuario-alta', component: DocumentoUsuarioAltaComponent},
    { path: 'documento-usuario-modificacion', component: DocumentoUsuarioModificacionComponent},
    { path: 'documentos-usuarios-listado', component: DocumentosUsuariosListadoComponent},

    { path: 'oferta-buscador-paises', component: OfertaBuscadorPaisesComponent },
    { path: 'oferta-buscador-paises-banner', component: OfertaBuscadorPaisesBannerComponent },
    { path: 'actividades-disponibles-pais', component: ActividadesDisponiblesPaisComponent},
    { path: 'actividad-detalle', component: ActividadDetalleComponent},
    { path: 'actividad-postular', component: ActividadPostularComponent},
    { path: 'usuario-solicitudes', component: UsuariosSolicitudesComponent},
    { path: 'solicitud-postular', component: SolicitudPostularComponent},
    { path: 'solicitud-postular-documentacion', component: SolicitudPostularDocumentacionComponent},
    { path: 'solicitud', component: SolicitudComponent },

    // Rutas Cursos
    { path: 'cursos-disponibles-compra', component: CursosDisponiblesCompraComponent},
    { path: 'curso-descripcion-compra', component: CursoDescripcionCompraComponent},
    { path: 'curso-compra', component: CursoCompraComponent},
    { path: 'curso-habilitado-alumno', component: CursoHabilitadoAlumnoComponent}, 
    { path: 'cursos-perfil-alumno', component: CursosPerfilAlumnoComponent},

    { path: 'curso-alta', component: CursoAltaComponent },
    { path: 'curso-modificacion', component: CursoModificacionComponent },
    { path: 'cursos-configuracion', component: CursosConfiguracionComponent },
    { path: 'cursos-listado', component: CursosListadoComponent },
    { path: 'cursos-alumnos-listado', component: CursosListadoAlumnoCursosComponent },
    { path: 'cursos-alumnos-modificar', component: CursosAlumnosModificarComponent },

    { path: 'titulo-alta', component: TituloAltaComponent },
    { path: 'titulo-modificacion', component: TituloModificacionComponent },
    { path: 'titulos-listado', component: TitulosListadoComponent },

    { path: 'materia-alta', component: MateriaAltaComponent },
    { path: 'materia-modificacion', component: MateriaModificacionComponent },
    { path: 'materias-configuracion', component:  MateriasConfiguracionComponent},
    { path: 'materias-listado', component:  MateriasListadoComponent},

    { path: 'leccion-alta', component:  LeccionAltaComponent},
    { path: 'lecciones-configuracion', component: LeccionesConfiguracionComponent },
    { path: 'lecciones-listado', component:  LeccionesListadoComponent},
    { path: 'leccion-modificacion', component:  LeccionModificacionComponent},

    { path: 'catedras-configuracion', component: CatedrasConfiguracionComponent },
    { path: 'catedra-alta', component: CatedraAltaComponent },
    { path: 'grilla-filtros-pre-iscripciones', component: TableFilterPreInscripcionesComponent },

    // Profesores
    {path: 'profesores-listado', component: ProfesoresListadoComponent},
    {path: 'profesor-alta', component: ProfesorAltaComponent},
    {path: 'profesores-configuracion', component: ProfesoresConfiguracionComponent},

    { path: 'personas-listado', component: PersonasListadoComponent },
    { path: 'persona-modificacion', component: PersonaModificacionComponent },
    { path: 'evaluacion-alta', component:  EvaluacionAltaComponent},
    { path: 'evaluacion-modificar', component:  EvaluacionModificarComponent},
    { path: 'evaluacion-preguntas-config', component:  EvaluacionPreguntasConfigComponent},
    { path: 'evaluacion-respuesta-config', component:  RespuestasAltaComponent},
    { path: 'cuenta', component: CuentaComponent },
    { path: 'examenes-listado', component: ExamenesListadoComponent},
    { path: 'examenes-alumno', component: ExamenesAlumnoComponent},
    { path: 'examen-introduccion', component: ExamenIntroduccionComponent },
    { path: 'examen-cursar', component: ExamenCursarComponent, canDeactivate: [ExitGuard]},
    { path: 'examen-resultado', component: ExamenResultadoComponent },
    { path: 'examenes-resultados', component: ExamenesEvaluacionesResultadosComponent },
    { path: 'examen-evaluacion-detalle', component: ExamenEvaluacionDetalleComponent },
    { path: 'examen-recuperatorio', component: ExamenRecuperatorioComponent },

    // Mesas de examenes
    {path: 'mesas-listado', component: MesasListadoComponent},
    {path: 'mesa-alta', component: MesaAltaComponent},
    {path: 'mesa-modificacion', component: MesaModificacionComponent},

    // Boletines
    {path: 'boletines-listado', component: BoletinesListadoComponent},
    {path: 'boletin-alta', component: BoletinAltaComponent},
    {path: 'mesa-modificacion', component: MesaModificacionComponent},
    {path: 'alumnos-boletines-listado', component: AlumnoBoletinesListadoComponent},
    {path: 'boletin-nombre-alta', component: BoletinNombreAltaComponent},
    {path: 'boletin-nombre-modificacion', component: BoletinNombreModificacionComponent},

    // Materias Boletines
    {path: 'materias-boletin-listado', component: MateriasBoletinListadoComponent},
    {path: 'materia-boletin-alta', component: MateriaBoletinAltaComponent},
    {path: 'materia-boletin-modificacion', component: MateriaBoletinModificacionComponent},
    {path: 'boletin-modificacion', component: BoletinModificacionComponent},

    // Gestos
    {path: 'gestos-configuracion', component: GestosConfiguracionComponent},
    {path: 'gestos-configuracion-usuario', component: GestosConfiguracionUsuarioComponent},
    {path: 'gesto-evaluacion-modificacion', component: GestoEvaluacionModificacionComponent},
    {path: 'gestos-listado', component: GestosListadoComponent},
    {path: 'gesto-alta', component: GestoAltaComponent},
    {path: 'gesto-modificacion', component: GestoModificacionComponent},
    {path: 'gestos-evaluaciones-listado', component: GestosEvaluacionesListadoComponent},
    {path: 'gestos-evaluaciones-usuarios-listado', component: GestosEvaluacionesUsuariosListadoComponent},

    { path: 'usuarios-administracion', component: UsuariosAdministracionComponent },
    { path: 'usuarios-listado', component: UsuariosListadoComponent },
    { path: 'usuario-modificacion', component: UsuarioModificacionComponent },
    { path: 'usuario-alta', component: UsuariosAltaComponent },

    // Paypal
    {path: 'paypal', component: PayPalButtonComponent },

    // Formularios de inscripciones
    {path: 'inscripcion', component: FormularioInscripcionComponent },
   
    // Trasferencia
    // {path: 'transferencia', component: TransferenciaButtonComponent },


    // **
    { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
        imports: [
            RouterModule.forRoot( dashBoardRoutes, { useHash: true} )
        ],
        exports : [
            RouterModule
        ]

})
export class AppRoutingModule {}
