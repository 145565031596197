import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
const url = '//reservas.aereos.app/aereos.app.min.js';

@Component({
  selector: 'app-buscador-aereos',
  templateUrl: './buscador-aereos.component.html',
  styleUrls: ['./buscador-aereos.component.scss']
})
export class BuscadorAereosComponent implements OnInit {

  flagAereos = false;

  constructor(
    private router: Router ,
  ) { }

  ngOnInit(): void {
      this.loadScript();
  }

  public loadScript() {
    let node = document.createElement('script');
    node.id = 'buscador';
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('body')[0].appendChild(node);
  }


}
