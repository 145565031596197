<h2>
    {{ idioma | translate: "form.CONFIRMAR-DATOS" | async}}</h2>
<form class="row g-3">

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.NOMBRE" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.nombrePersona">
    </div>

    <!-- <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.ESTADO-CIVIL" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.estadoCivil.concepto">
    </div> -->

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.APELLIDO" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.apellidoPersona">
    </div>

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.TIPO-ID" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.tipoIdentificacion?.concepto">
    </div>

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.NRO-ID" | async}}
        </label>
        <input type="text" disabled class="form-control" [value]="this.data?.identificacion">
    </div>

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.FECHA-NACIMIENTO" | async}}
        </label>
        <input type="text" disabled class="form-control" [(ngModel)]="birth" name="birth">
    </div>

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.PAIS-NACIMIENTO" | async}}
        </label>
        <input type="text" disabled class="form-control" [value]="this.data?.paisNacimiento.name_es">
    </div>


    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.CODIGO-AREA" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.paisCodeArea">
    </div>

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.NUMERO-TELEFONO" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.telefono">
    </div>

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.DESCIPLINA" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.disciplina?.concepto">
    </div>

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.DELEGACION" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.delegacion?.concepto">
    </div>

    <div class="col-md-6">
        <label>{{ idioma | translate: "form.TITULO-SECUNDARIO" | async}}</label>
        <br>
        <mat-radio-group *ngIf="this.data?.tituloSecundario?.toString() == 'true'" aria-label="Select an option">
            <mat-radio-button disabled [checked]="true" value="true">{{ idioma | translate: "form.TITULO-SECUNDARIO-SI" | async}}
            </mat-radio-button>
            <mat-radio-button disabled value="false">{{ idioma | translate: "form.TITULO-SECUNDARIO-NO" | async}}</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group *ngIf="this.data?.tituloSecundario?.toString() == 'false'" aria-label="Select an option">
            <mat-radio-button disabled value="true">{{ idioma | translate: "form.TITULO-SECUNDARIO-SI" | async}}</mat-radio-button>
            <mat-radio-button disabled [checked]="true" value="false">{{ idioma | translate: "form.TITULO-SECUNDARIO-NO" | async}}</mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="col-md-6" *ngIf="this.data?.tituloSecundario?.toString() == 'true'">
        <label class="form-label">{{ idioma | translate: "form.TITULO-SECUNDARIO-DE" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.tituloDe">
    </div>

    <div class="col-md-6" *ngIf="this.data?.tituloSecundario?.toString() == 'true'">
        <label class="form-label">{{ idioma | translate: "form.TITULO-SECUNDARIO-EXPEDIDO" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.tituloExpedido">
    </div>

    <div class="col-md-6">
        <label>{{ idioma | translate: "form.ENFERMEDAD-PREGUNTA" | async}}</label>
        <br>
        <mat-radio-group *ngIf="this.data?.enfermedad?.toString()== 'true'" aria-label="Select an option">
            <mat-radio-button disabled [checked]="true" value="true">{{ idioma | translate: "form.ENFERMEDAD-SI" | async}}</mat-radio-button>
            <mat-radio-button disabled value="false">{{ idioma | translate: "form.ENFERMEDAD-NO" | async}}</mat-radio-button>
        </mat-radio-group>

        <mat-radio-group *ngIf="this.data?.enfermedad?.toString()== 'false'" aria-label="Select an option">
            <mat-radio-button disabled value="true">{{ idioma | translate: "form.ENFERMEDAD-SI" | async}}</mat-radio-button>
            <mat-radio-button disabled [checked]="true" value="false">{{ idioma | translate: "form.ENFERMEDAD-NO" | async}}</mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="col-md-6">
        <label>{{ idioma | translate: "form.HOSPITALIZADO-PREGUNTA" | async}}</label>
        <br>
        <mat-radio-group *ngIf="this.data?.hospitalizado?.toString() == 'true'" aria-label="Select an option">
            <mat-radio-button disabled [checked]="true" value="true">{{ idioma | translate: "form.HOSPITALIZADO-SI" | async}}</mat-radio-button>
            <mat-radio-button disabled value="false">{{ idioma | translate: "form.HOSPITALIZADO-NO" | async}}</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group *ngIf="this.data?.hospitalizado.toString() == 'false'" aria-label="Select an option">
            <mat-radio-button disabled value="true">{{ idioma | translate: "form.HOSPITALIZADO-SI" | async}}</mat-radio-button>
            <mat-radio-button disabled value="false" [checked]="true">{{ idioma | translate: "form.HOSPITALIZADO-NO" | async}}</mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "legales.LEGAL-CTF-1" | async}}</label>
        <p>
            <mat-checkbox disabled [checked]="this.data?.declaracion">{{ idioma | translate: "legales.LEGAL-CTF-5" | async}}</mat-checkbox>
        </p>
    </div>

    <!-- <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.COMUNITARIO" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.condiMigra.concepto">
    </div> -->

    <!-- <div class="col-md-6">
        <div class="form-group">
            <label>{{ idioma | translate: "form.PASAPORTE-VALIDO" | async}}</label>
            <br>
            <mat-radio-group>
                <mat-radio-button disabled *ngIf="this.data?.pasaporteValido.toString()" [checked]="true" value="true">{{ idioma | translate: "form.SI" | async}}</mat-radio-button>
                <mat-radio-button disabled *ngIf="this.data?.pasaporteValido.toString()" value="false">{{ idioma | translate: "form.NO" | async}}</mat-radio-button>

                <mat-radio-button disabled *ngIf="!this.data?.pasaporteValido.toString()" [checked]="true" value="false">{{ idioma | translate: "form.NO" | async}}</mat-radio-button>
                <mat-radio-button disabled *ngIf="!this.data?.pasaporteValido.toString()" value="false">{{ idioma | translate: "form.SI" | async}}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div> -->

    <!--
    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.IDIOMAS" | async}}</label>
        <p>
            <mat-checkbox disabled [checked]="this.data?.idiomaCa">{{ idioma | translate: "form.CATALAN" | async}}</mat-checkbox>
        </p>
        <p>
            <mat-checkbox disabled [checked]="this.data?.idiomaEs">{{ idioma | translate: "form.ESPANOL" | async}}</mat-checkbox>
        </p>
        <p>
            <mat-checkbox disabled [checked]="this.data?.idiomaFr">{{ idioma | translate: "form.FRANCES" | async}}</mat-checkbox>
        </p>
        <p>
            <mat-checkbox disabled [checked]="this.data?.idiomaEn">{{ idioma | translate: "form.INGLES" | async}}</mat-checkbox>
        </p>
    </div> -->

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button (click)="aceptar()" class="btn btn-primary me-md-2" type="button">{{ idioma | translate: "form.BTN-ACEPTAR" | async}}</button>
        <button (click)="cancelar()" class="btn btn-secondary" type="button">{{ idioma | translate: "form.BTN-CANCELAR" | async}}</button>
    </div>

</form>