<div class="page-title-area">
    <div class="container">
        <div>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('es')"><img src="assets/img/flags/espana.png" alt="Español" width="20"> ES</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('en')"><img src="assets/img/flags/usa.png" alt="English" width="20"> EN</button>
        </div>
        <div class="page-title-content">
            <h2>
                <a routerLink="/"><img src="assets/img/logo-aadidess-white.png" alt="ski-andorra" width="30%"></a><br></h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div *ngIf="this.lenguaje === 'es'">
    <div class="row">
        <div class="container col-auto p-5 text-center">

            <p style='margin:0cm;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";border:none;'><span style="color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";border:none;'><span style="color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style='text-align:left;margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:39.7pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 13px;">ENGLISH (EN)</span></strong>
                </span>
            </p>
            <br>
            <table style="width: 5.0e+2pt;margin-left:36.6pt;border-collapse:collapse;border:none;">
                <tbody>
                    <tr class="text-center">
                        <td colspan="2" style="text-align:left;width: 469.95pt;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">Aviso legal Correo electrónico</span></strong></p>
                        </td>
                    </tr>
                    <br>
                    <tr class="text-center">
                        <td style="width: 439.45pt;background: rgb(231, 230, 230);padding: 0cm 5.4pt;vertical-align: top;text-align: right;">
                            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;">Ver. 21.3</span></strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="background-color:#E7E6E6;width: 496.25pt;border: 1pt solid black;padding: 5pt;height: 20.25pt;vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><strong><span style="font-size:12px;color:black;">PROTECCI&Oacute;N DE DATOS PERSONALES</span></strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="width: 496.25pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;padding: 5pt;height: 81.5pt;vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:110%;font-size:15px;font-family:"Arial","sans-serif";margin-top:0cm;margin-right:2.7pt;margin-left:5.75pt;text-align:justify;text-indent:  .6pt;border:none;'><span style="font-size:12px;line-height:110%;color:black;">En cumplimiento de lo dispuesto en la legislaci&oacute;n aplicable y el <strong>Reglamento General (UE) 2016/679&nbsp;</strong>de Protecci&oacute;n de &nbsp;Datos, le informamos que esta comunicaci&oacute;n contiene informaci&oacute;n confidencial y privilegiada, siendo de uso exclusivo del &nbsp;destinatario. Si usted no es el destinatario, tenga en cuenta que cualquier distribuci&oacute;n, copia o uso de este comunicado, &nbsp;est&aacute; estrictamente prohibido. Si usted ha recibido esta comunicaci&oacute;n por error, comun&iacute;quelo por correo electr&oacute;nico o &nbsp; &nbsp; tel&eacute;fono y tenga la amabilidad de eliminarlo de su sistema. Puede consultar la informaci&oacute;n adicional sobre protecci&oacute;n de &nbsp;datos en:&nbsp;</span>
                                <span style="font-size:12px;line-height:110%;color:blue;">www.aadidess.cipbyte.io</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color:#E7E6E6;width: 32.6242%; border-right: 1pt solid black; border-bottom: 1pt solid black; border-left: 1pt solid black; border-image: initial; border-top: none; padding: 5pt; height: 13.65pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px; background: rgb(231, 230, 230);">Responsable de Tratamiento </span></strong>
                                </span>
                            </p>
                        </td>
                        <td style="width: 67.0316%; border-top: none; border-left: none; border-bottom: 1pt solid black; border-right: 1pt solid black; padding: 5pt; height: 14.8pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px;">AADIDESS LLC</span></strong>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color:#E7E6E6;width: 32.6242%; border-right: 1pt solid black; border-bottom: 1pt solid black; border-left: 1pt solid black; border-image: initial; border-top: none; padding: 5pt; height: 13.65pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px; background: rgb(231, 230, 230);">Finalidad </span></strong>
                                </span>
                            </p>
                        </td>
                        <td style="width: 67.0316%; border-top: none; border-left: none; border-bottom: 1pt solid black; border-right: 1pt solid black; padding: 5pt; height: 23.75pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:  95%;font-size:15px;font-family:"Arial","sans-serif";margin-top:0cm;margin-right:3.2pt;margin-left:6.2pt;text-indent:-.15pt;border:none;'><span style="font-size: 12px; line-height: 95%; color: rgb(0, 0, 0);">Comunicaci&oacute;n corporativa con terceros interesados y recepci&oacute;n de ficheros adjuntos &nbsp;necesarios para el desarrollo de la actividad laboral de la empresa.</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color:#E7E6E6;width: 32.6242%; border-right: 1pt solid black; border-bottom: 1pt solid black; border-left: 1pt solid black; border-image: initial; border-top: none; padding: 5pt; height: 13.9pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px; background: rgb(231, 230, 230);">Base jur&iacute;dica </span></strong>
                                </span>
                            </p>
                        </td>
                        <td style="width: 67.0316%; border-top: none; border-left: none; border-bottom: 1pt solid black; border-right: 1pt solid black; padding: 5pt; height: 13.9pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.45pt;border:none;'><span style="font-size: 12px; color: rgb(0, 0, 0);">Inter&eacute;s leg&iacute;timo</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color:#E7E6E6;width: 32.6242%; border-right: 1pt solid black; border-bottom: 1pt solid black; border-left: 1pt solid black; border-image: initial; border-top: none; padding: 5pt; height: 13.65pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px; background: rgb(231, 230, 230);">Destinatarios </span></strong>
                                </span>
                            </p>
                        </td>
                        <td style="width: 67.0316%; border-top: none; border-left: none; border-bottom: 1pt solid black; border-right: 1pt solid black; padding: 5pt; height: 13.65pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="font-size: 12px; color: rgb(0, 0, 0);">No se ceder&aacute;n datos a terceros, salvo obligaci&oacute;n legal.</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color:#E7E6E6;width: 32.6242%; border-right: 1pt solid black; border-bottom: 1pt solid black; border-left: 1pt solid black; border-image: initial; border-top: none; padding: 5pt; height: 23.7pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px; background: rgb(231, 230, 230);">Derechos </span></strong>
                                </span>
                            </p>
                        </td>
                        <td style="width: 67.0316%; border-top: none; border-left: none; border-bottom: 1pt solid black; border-right: 1pt solid black; padding: 5pt; height: 23.7pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:  96%;font-size:15px;font-family:"Arial","sans-serif";margin-top:0cm;margin-right:3.15pt;margin-left:5.95pt;text-indent:-.2pt;border:none;'><span style="font-size: 12px; line-height: 96%; color: rgb(0, 0, 0);">Acceder, rectificar y suprimir les datos, as&iacute; como otros derechos que se identifican en la &nbsp;+info adicional  </span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br><br><br>
        </div>
    </div>
</div>

<div *ngIf="this.lenguaje === 'en'">
    <div class="row">
        <div class="container col-auto p-5 text-center">

            <p style='margin:0cm;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";border:none;'><span style="color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"Arial","sans-serif";border:none;'><span style="color: rgb(0, 0, 0);">&nbsp;</span></p>
            <p style='text-align:left;margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:39.7pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 13px;">ENGLISH (EN)</span></strong>
                </span>
            </p>
            <br>
            <table style="width: 5.0e+2pt;margin-left:36.6pt;border-collapse:collapse;border:none;">
                <tbody>
                    <tr>
                        <td colspan="2" style="background-color:#E7E6E6;width: 496.25pt;border: 1pt solid black;padding: 5pt;height: 20.3pt;vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px;">PERSONAL DATA PROTECTION</span></strong>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="width: 496.25pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;padding: 5pt;height: 69.6pt;vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:110%;font-size:15px;font-family:"Arial","sans-serif";margin-top:0cm;margin-right:2.7pt;margin-left:5.75pt;text-align:justify;text-indent:  .65pt;border:none;'><span style="font-size: 12px; line-height: 110%; color: rgb(0, 0, 0);">In compliance with the provisions of the applicable law and of the <strong>General Regulation (EU) 2016/679&nbsp;</strong>on Data Protection, &nbsp;we inform you that this communication contains confidential and privileged information, being for the exclusive use of the &nbsp; &nbsp; recipient. If you are not the recipient, please note that any distribution, copying, or use of this release is strictly prohibited. &nbsp;If you have received this communication in error, please communicate it by email or telephone and be kind to delete it &nbsp;from your system. You can find additional information on data protection at:&nbsp;www.aadidess.cipbyte.io</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color:#E7E6E6;width: 30.9031%; border-right: 1pt solid black; border-bottom: 1pt solid black; border-left: 1pt solid black; border-image: initial; border-top: none; padding: 5pt; height: 14.7pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px; background: rgb(231, 230, 230);">Responsible for treatment </span></strong>
                                </span>
                            </p>
                        </td>
                        <td style="width: 68.7527%; border-top: none; border-left: none; border-bottom: 1pt solid black; border-right: 1pt solid black; padding: 5pt; height: 14.7pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px;">AADIDESS LLC</span></strong>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color:#E7E6E6;width: 30.9031%; border-right: 1pt solid black; border-bottom: 1pt solid black; border-left: 1pt solid black; border-image: initial; border-top: none; padding: 5pt; height: 23.9pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px; background: rgb(231, 230, 230);">Purpose </span></strong>
                                </span>
                            </p>
                        </td>
                        <td style="width: 68.7527%; border-top: none; border-left: none; border-bottom: 1pt solid black; border-right: 1pt solid black; padding: 5pt; height: 23.9pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:  96%;font-size:15px;font-family:"Arial","sans-serif";margin-top:0cm;margin-right:3.2pt;margin-left:5.95pt;text-indent:.05pt;border:none;'><span style="font-size: 12px; line-height: 96%; color: rgb(0, 0, 0);">Corporate communication with interested third parties and receipt of necessary &nbsp;attachments for the development of the company&apos;s work activity.</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color:#E7E6E6;width: 30.9031%; border-right: 1pt solid black; border-bottom: 1pt solid black; border-left: 1pt solid black; border-image: initial; border-top: none; padding: 5pt; height: 13.5pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px; background: rgb(231, 230, 230);">Legal Basis </span></strong>
                                </span>
                            </p>
                        </td>
                        <td style="width: 68.7527%; border-top: none; border-left: none; border-bottom: 1pt solid black; border-right: 1pt solid black; padding: 5pt; height: 13.5pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="font-size: 12px; color: rgb(0, 0, 0); background: white;">Legitimate interest</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color:#E7E6E6;width: 30.9031%; border-right: 1pt solid black; border-bottom: 1pt solid black; border-left: 1pt solid black; border-image: initial; border-top: none; padding: 5pt; height: 13.85pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px; background: rgb(231, 230, 230);">Recipients </span></strong>
                                </span>
                            </p>
                        </td>
                        <td style="width: 68.7527%; border-top: none; border-left: none; border-bottom: 1pt solid black; border-right: 1pt solid black; padding: 5pt; height: 13.85pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="font-size: 12px; color: rgb(0, 0, 0); background: white;">No data will be transferred to third parties, except legal obligation.</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color:#E7E6E6;width: 30.9031%; border-right: 1pt solid black; border-bottom: 1pt solid black; border-left: 1pt solid black; border-image: initial; border-top: none; padding: 5pt; height: 23.75pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:normal;font-size:15px;font-family:"Arial","sans-serif";margin-left:6.3pt;border:none;'><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 12px; background: rgb(231, 230, 230);">Rights </span></strong>
                                </span>
                            </p>
                        </td>
                        <td style="width: 68.7527%; border-top: none; border-left: none; border-bottom: 1pt solid black; border-right: 1pt solid black; padding: 5pt; height: 23.75pt; vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;line-height:  95%;font-size:15px;font-family:"Arial","sans-serif";margin-top:0cm;margin-right:2.75pt;margin-left:5.95pt;text-indent:-.15pt;border:none;'><span style="font-size: 12px; line-height: 95%; color: rgb(0, 0, 0);">Access, rectify and delete the data, as well as other rights that are identified in the&nbsp;+ &nbsp;additional   info</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br><br><br>

        </div>
    </div>
</div>