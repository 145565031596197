import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {
  formData: any; // Objeto para almacenar los datos del formulario
  cursosDelegaciones: object[] = [];

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {
    console.log(' data.formData', data.formData);
    this.formData = data.formData; // Recibiendo los datos del formulario
  }

  confirm(): void {
    this.dialogRef.close('confirmar');
  }

  cancel(): void {
    this.dialogRef.close('cancelar');
  }

}
