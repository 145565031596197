<div class="single-courses-box" *ngIf="flagLoaded">
    <div class="courses-image">
        <a *ngIf="click"  (click)="navCtrlDetalleOfertaLaboral(actividad)" routerLink='/actividad-detalle' class="d-block image">
            <img [src]=" 1 | mediaOfertaLaboral: actividad.imagen  | safe" [alt]="actividad.nombre">
            <a class="link-btn"></a>
        </a>     
        <a *ngIf="!click"   class="d-block image">
            <img [src]=" 1 | mediaCursosPipe: curso.imagen  | safe" [alt]="actividad.nombre">
            <a class="link-btn"></a>
        </a>     
        <div *ngIf="this.price && click " class="price shadow">${{ curso.precio }}</div>
    </div>
    <div class="courses-content">
        <div class="course-author d-flex align-items-center">
            <img src="assets/img/user9.png" class="rounded-circle" alt="image">
            <span *ngFor="let item of profesoresCurso">{{ item.nombrePersona }} {{ item.apellidoPersona }}</span>
        </div>
        <h3>
            <a  *ngIf="click" (click)="navCtrlDetalleOfertaLaboral(actividad)" routerLink='/actividad-detalle' class="link-btn">{{actividad.nombre}}</a>
        </h3>
        <p>{{actividad.cursos[0].introduccion}}</p>
        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
            <li><i class='flaticon-time'></i> {{curso.cantidadHoras}} {{ idioma | translate: "curso.HORAS-TOTAL" | async}}</li>
            <li *ngIf="curso.materias.length >= 0"><i class='flaticon-agenda'></i> {{lecciones}} {{ idioma | translate: "curso.LECCIONES" | async}}</li>
        </ul>
        <!-- <ul class="courses-box-footer d-flex justify-content-between align-items-center">
            <li><i class='flaticon-people'></i> {{curso.inscriptos}} {{ idioma | translate: "curso.ESTUDIANTES" | async}}</li>
        </ul> -->
        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
            <li>{{ idioma | translate: "oferta-laboral.VIGENTE" | async}} <i class='flaticon-calendar'></i> {{ actividad.vigenciaDesde | date:"dd/MM/yy" }} - {{ actividad.vigenciaHasta | date:"dd/MM/yy" }} </li>
        </ul>

    </div>
</div>