import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Materia } from '../../../../interfaces/interface-bakend/cursos/materia.interface';
import Swal from 'sweetalert2';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { MateriasBoletinService } from 'src/app/services/cursos/materias-boletin.service';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';

@Component({
  selector: 'app-materias-boletin-listado',
  templateUrl: './materias-boletin-listado.component.html',
  styleUrls: ['./materias-boletin-listado.component.scss']
})
export class MateriasBoletinListadoComponent implements OnInit, AfterViewInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
    [
      'nombre', 'activa', '_id'
    ];
  dataSource: any;
  materiasBoletin: MateriaBoletin[];
  /* Fin Data Table*/

  flagLoaded = false;

  constructor(
    private materiasBoletinService: MateriasBoletinService,
    private router: Router,
    private guiMsjService: GuiMsjService
  ) { }

  ngOnInit(): void {
    this.loadDataPage();
    this.getMateriaBoletin();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
  }

  async verMateria(event: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    await this.materiasBoletinService.getMateriaFindOne(event);
    this.router.navigateByUrl('/materia-boletin-modificacion');
  }

  async getMateriaBoletin() {
    const materia: MateriaBoletin = this.materiasBoletinService.getMateriaSeleccionada();
  }

  async loadDataTable() {
    this.materiasBoletinService.getMaterias().then((materiasBoletin: MateriaBoletin[]) => {
      this.dataSource = new MatTableDataSource(materiasBoletin);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async loadDataPage() {
    await this.loadDataTable();
    return;
  }
}
