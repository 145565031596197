import { Injectable } from '@angular/core';
import { Response } from '../../interfaces/interface-bakend/response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { GuiMsjService } from './gui-msj.service';
import { AuthService } from '../auth/auth.service';
import { UserDocumentoTipo } from '../../interfaces/interface-bakend/seguridad/users/user-documento-tipos.interface'

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class UserDocumentoTiposService {
  userDocumentType : UserDocumentoTipo;

  constructor(
      private httpClient: HttpClient,
      private router: Router,
      private guiMsjService: GuiMsjService,
      private authService: AuthService,
      private storageService: StorageService
  ) {}

  async create(userDocumentType: UserDocumentoTipo): Promise<UserDocumentoTipo> {
      const headers = new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
      });

      return new Promise(async (resolve, reject) => {
          this.httpClient
              .post<Response>(`${URL_BACKEND}/users/documento_tipos`, userDocumentType, {
                  headers,
              })
              .subscribe(
                  (resp) => {
                      Swal.close();

                      Swal.fire({
                          icon: 'success',
                          title: 'Documentos del usuario guardado satisfactoriamente',
                          showConfirmButton: false,
                          timer: 1100,
                      });

                      this.router.navigate(['/documentos-usuarios-listado']);
                      resolve(resp.response);
                  },
                  (error) => {
                      console.error(error);
                      this.guiMsjService.msjFormSubmit('crearUsuarioDocumentosTipoError');
                      this.router
                          .navigateByUrl('/', { skipLocationChange: true })
                          .then(async () => {
                              await this.router.navigateByUrl('/');
                          });
                      reject(error);
                  }
              );
      });
  }

  getUserDocumentoById(id: string): Promise<UserDocumentoTipo> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/documento_tipos/findOne/${id}`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      });
    });
  }

  async getUserDocumentosTipos(): Promise<UserDocumentoTipo[]> {
    return new Promise(async (resolve) => {
          const headers = new HttpHeaders({
              'x-token': await this.authService.loadJwToken(),
          });

          this.httpClient
              .get<Response>(`${URL_BACKEND}/users/documento_tipos/findAll`, {
                  headers,
              })
              .subscribe((resp: Response) => {
                  resolve(resp.response);
              });
      });
  }

  async getUserDocumentosTiposActivos(): Promise<UserDocumentoTipo[]> {
    return new Promise(async (resolve) => {
          const headers = new HttpHeaders({
              'x-token': await this.authService.loadJwToken(),
          });

          this.httpClient
              .get<Response>(`${URL_BACKEND}/users/documento_tipos/findActivos`, {
                  headers,
              })
              .subscribe((resp: Response) => {
                  resolve(resp.response);
              });
      });
  }

  async deleteUserDocumentoTipo(id: string): Promise<UserDocumentoTipo>{

    return new Promise( async resolve => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.delete<Response>(`${ URL_BACKEND }/users/documento_tipos/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });

  }

  async updateUserDocumentosTipo(_id: string, userDocumentType: UserDocumentoTipo): Promise<UserDocumentoTipo> {
      return new Promise(async (resolve) => {
          const headers = new HttpHeaders({
              'x-token': await this.authService.loadJwToken(),
          });
          this.httpClient
              .put<Response>(
                  `${URL_BACKEND}/users/documento_tipos/updateOne/${_id}`,
                  userDocumentType,
                  { headers }
              )
              .subscribe(
                  (resp: Response) => {
                      resolve(resp.response);
                  },
                  (error) => {
                      console.error(error);
                      this.guiMsjService.msjFormSubmit('userDocumentoTipoDuplicado');
                  }
                  );
      });
  }
  
  getUserDocumentoTipoSeleccionado(): UserDocumentoTipo {
      return this.userDocumentType;
  }

  setUserDocumentTipoSeleccionado(userDocumentType: UserDocumentoTipo) {
      this.userDocumentType = userDocumentType;
      this.storageService.setUserDocumentType( this.userDocumentType);
  }

  documentosSolicitado(documentosUsuario: string[], documentosSolicitado: string): boolean { // este metodo permite sabber que documentos ya tiene en su legajo para no volver a solicitar
    if (documentosSolicitado !== undefined && documentosUsuario.length > 0) {
        for (let i = 0; i < documentosUsuario.length; i++) {
            const element = documentosUsuario[i];
            const firstPart = element.split('.')[0];
            if (firstPart === documentosSolicitado) {
                // console.log('ENCONTRADO', documentosSolicitado, firstPart);
                return true;
            }
        }
        return false; // Si no se encontró ninguna coincidencia, devuelve false
    } else {
        return false;
    }
 }

}
