import { Response } from '../../../app/interfaces/interface-bakend/response';
import { PaisDisponible } from '../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';
import { Pais } from '../../interfaces/interface-bakend/shared/paises/pais.interfaces';
import { AuthService } from '../auth/auth.service';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class PaisesService {

  pais: PaisDisponible;

  constructor(
     private httpClient: HttpClient,
     private storageService: StorageService,
     private authService: AuthService,
  ) { }

  getPaises(): Promise<Pais[]> {
    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient.get<Response>(`${ URL_BACKEND }/paises/findAll/`, { headers }).subscribe( resp => {
            resolve(resp.response);
        });
    });
   }

  getPaisesDisponibles(): Promise<PaisDisponible[]> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/paises-disponibles/findVigentes/`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  getPaisDisponibleFinOne(id: string): Promise<PaisDisponible> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/paises-disponibles/findOne/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  setPaisSeleccionado(pais: PaisDisponible) {
    this.pais = pais;
    this.storageService.setPais(pais);
  }

  getPaisSeleccionado(): PaisDisponible {
    return this.pais;
  }

  cleanPais() {
    this.pais = null;
  }
}
