import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { GestosService } from 'src/app/services/cursos/gestos.service';
import { GestoUsuario } from 'src/app/interfaces/interface-bakend/cursos/gestoUsuario.interface';

@Component({
  selector: 'app-gestos-evaluaciones-listado',
  templateUrl: './gestos-evaluaciones-listado.component.html',
  styleUrls: ['./gestos-evaluaciones-listado.component.scss']
})
export class GestosEvaluacionesListadoComponent implements OnInit, AfterViewInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
    [
      'mesa', 'gesto', 'usuario', 'aprobado', 'nota', 'fechaVencimiento', 'disciplina', 'nivel', '_id'
    ];
  dataSource: any;
  /* Fin Data Table*/

  flagLoaded = false;


  constructor(
    private router: Router,
    private guiMsjService: GuiMsjService,
    private gestosService: GestosService
  ) { }

  ngOnInit(): void {
    this.loadDataPage();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
  }

  async editarEvaluacionGesto(id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    await this.gestosService.getGestoEvaluadoById(id);
    this.router.navigateByUrl('/gesto-evaluacion-modificacion');
  }

  async loadDataTable() {
    this.gestosService.getGestosEvaluados().then((gestos: GestoUsuario[]) => {
      this.dataSource = new MatTableDataSource(gestos);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
    // Filtrar por mesa.titulo, gesto.nombre, usuario.fullName, aprobado y nota
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const mesaTitulo = data.mesa.titulo.toLowerCase().includes(filter);
      const gestoNombre = data.gesto.nombre.toLowerCase().includes(filter);
      const disciplina = data.gesto.disciplina.nombre.toLowerCase().includes(filter);
      const usuarioFullName = data.usuario.fullName.toLowerCase().includes(filter);
      const aprobado = data.aprobado ? 'Aprobado' : 'Desaprobado';
      const nota = data.nota.toString().includes(filter);
  
      return mesaTitulo || gestoNombre || usuarioFullName || disciplina || aprobado.includes(filter) || nota;
    };
  }

  async loadDataPage() {
    await this.loadDataTable();
    return;
  }
}
