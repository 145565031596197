
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { Router } from '@angular/router';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { Curso } from '../../../../interfaces/interface-bakend/cursos/curso.interface';

@Component({
  selector: 'app-evaluacion-asociar-modificar',
  templateUrl: './evaluacion-asociar-modificar.component.html',
  styleUrls: ['./evaluacion-asociar-modificar.component.scss']
})

export class EvaluacionAsociarModificarComponent implements OnInit {
  
  flagLoaded = false;
  procesando = false;
  isEditable = true;
  flagFormaValido = false;
  cursoAsignado = false;
  grupoAsignado = false;
  usuarioAsignado = false;

  constructor(
      private guiMsjService: GuiMsjService,
      private router: Router,
  ) { }

  ngOnInit(): void {
    this.flagLoaded = true;
  }

  finalizar() {
    // this.guiMsjService.msjFormSubmit('finalizarAsignacionExamen');
    this.router.navigateByUrl('/cursos-configuracion');
  }

}
