<div *ngIf="flagLoaded">
    <mat-spinner *ngIf="!flagLoadedModal"></mat-spinner>
    <mat-form-field>
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- Column -->
            <ng-container matColumnDef="tramite">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Trámite </th>
                <td mat-cell *matCellDef="let element"> {{element.tramite}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="actividad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Actividad </th>
                <td mat-cell *matCellDef="let element"> {{element.actividad}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="solicitante">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Solicitante </th>
                <td mat-cell *matCellDef="let element"> {{element.solicitante | nombreExiste}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="identificacion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Identificación </th>
                <td mat-cell *matCellDef="let element"> {{element.identificacion}} </td>
            </ng-container>


            <!-- Column -->
            <ng-container matColumnDef="fechaEstado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                <td mat-cell *matCellDef="let element"> {{element.fechaEstado | date:"dd/MM/yy"}} </td>
            </ng-container>


            <!-- Column -->
            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let element"> {{element.estado | estadosPipe}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="progreso">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Progreso </th>
                <td mat-cell *matCellDef="let element">
                    <div class="progress">
                        <div class="progress-bar bg-secondary" role="progressbar"
                            [style.width]="calcularProgreso(element.lastState) + '%'"
                            [attr.aria-valuenow]="calcularProgreso(element.lastState)" aria-valuemin="0" aria-valuemax="100">
                            {{calcularProgreso(element.lastState) | number:'1.0-0'}}%
                        </div>
                    </div>
                </td>
            </ng-container>


            <!-- Column -->
            <ng-container matColumnDef="ver">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let element">
                    <button (click)="openDialogModal(element.id)" mat-raised-button color="accent">Ver</button>
                    <!-- <button (click)="openDialogModal(element.id)" class="btn default-btn-style-two"><i class="fas fa-file"></i> Ver </button> -->
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
        </table>
    </div>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>