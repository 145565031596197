<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li>Materias Boletin</li>
            </ul>
            <h2>Materias Boletin</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div *ngIf="flagLoaded" class="profile-area pb-70">
    <div class="container">

        <div class="profile-courses-quizzes">
            <h3>Listado de Materias Boletin</h3>

            <a routerLink="/materia-boletin-alta"><button mat-raised-button mat-button color="primary"><mat-icon
                        aria-hidden="false" aria-label="Example home icon">add</mat-icon> Nueva Materia Boletin</button></a>
            <br>
            <hr>

            <div *ngIf="flagLoaded">

                <mat-form-field>
                    <mat-label>Filtrar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Materia..." autocomplete="off">
                </mat-form-field>

                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                    <!-- Column -->
                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="activa">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Estado </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.activa; else elseTemplate">
                                <mat-chip-listbox aria-label="Fish selection">
                                    <mat-chip-option>Activa</mat-chip-option>
                                </mat-chip-listbox>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <mat-chip-listbox aria-label="Fish selection">
                                    <mat-chip-option color="warn">Inactiva</mat-chip-option>
                                </mat-chip-listbox>
                            </ng-template>
                        </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Acciones </th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="verMateria(element._id)" type="button" class="btn btn-warning"><i
                                    class="fas fa-edit"></i></button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
        </div>
    </div>
</div>