import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ExamenEvaluacion } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';
import { Examen } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { AppConfigService } from 'src/app/services/shared/app-config.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-examen-recuperatorio',
    templateUrl: './examen-recuperatorio.component.html',
    styleUrls: ['./examen-recuperatorio.component.scss']
})
export class ExamenRecuperatorioComponent implements OnInit {

  examen: Examen;
  evaluacionesByUser: ExamenEvaluacion[];
  evaluacionesDeRecuperatorios: ExamenEvaluacion[];
  porcentajeAprobado: number;
  aprobado: boolean;
  flagLoaded = false;
  fechaVencimiento: Date;
  hoy: Date = new Date();
  examenVencido = false;
  examenActivo: boolean;
  cantPreguntas: number;
  contadorRecuperables: number = 0;
  horaServer: Date = null;
  btnRecuperatorio1: boolean = false;
  btnRecuperatorio2: boolean = false;
  primerRecuperatorio: boolean = false;
  segundoRecuperatorio: boolean = false;
  intentoDeRecuperatorio: boolean = false;
  sinRecuperables: boolean = false;
  progress: boolean = false;
  cargandoRecuperatorio: boolean = true;
  constructor(private examenServices: ExamenesService, 
              private router: Router,
              private authService: AuthService,
              private appConfigService: AppConfigService,
              private guiMsjService: GuiMsjService,
              private snackBar: MatSnackBar) {
              sessionStorage.removeItem('EXAMEN_FINALIZADO');
  }

  ngOnInit(): void {
    this.appConfigService.getServerTime().then((date:Date)=>{
      this.horaServer = new Date(date);
    });
    this.getEstadoVariables();
  }
  /* CARGO EL EXAMEN SELECCIONADO PARA RECUPERAR */
  getExamen(){
    return this.examen = this.examenServices.getExamenSeleccionado();
  }

  async getEstadoVariables(){
    if (this.getExamen() === undefined) {
      this.router.navigateByUrl('/examenes-alumno');
      return;
    }else {
      this.getExamen();
      this.evaluacionesByUser = await this.examenServices.getEvaluacionesByUser();
      this.cantPreguntas = await this.cantidadDePreguntasArendir(this.examen);
      this.examenActivo = this.examen.activo;
      this.flagLoaded = true;

      /* CHEQUEO QUE EL USUARIO TENGA EVALUACIONES Y CUENTO LOS INTENTOS POR EXAMEN */
      if(this.evaluacionesByUser !== undefined){

        /* FILTRO EVALUACIONES DEL MISMO EXAMEN A RENDIR QUE SON RECUPERATORIOS */
        this.evaluacionesDeRecuperatorios = this.evaluacionesByUser.filter(examen => examen.examen === this.examen._id && examen.recuperatorio == true);

        /* RECORRO TODOS LOS RECUPERATORIOS */
        this.evaluacionesDeRecuperatorios.forEach(evaluacion=>{

          this.aprobado = evaluacion.examen.concat(evaluacion.asignacion) === this.examen._id.concat(this.examen.asignacion) && evaluacion.aprobado == true ? true : false;
          if(this.aprobado){
            Swal.fire('El examen ya ha sido aprobado!', '', 'success')
          }

          /* EL EXAMEN ES INDIVIDUAL */
          if(this.examen.individual === true){
        
            if(evaluacion.examen.concat(evaluacion.asignacion) === this.examen._id.concat(this.examen.asignacion)){
              this.porcentajeAprobado = evaluacion.puntajeObtenido;
              this.examenServices.setExamenEvaluacionId(evaluacion._id);
              this.intentoDeRecuperatorio = true;
              this.contadorRecuperables++;
            }



          }else{
            /* EL EXAMEN ES ASOCIADO A UN CURSO */
            if(evaluacion.asignacion == undefined){
              this.porcentajeAprobado = evaluacion.puntajeObtenido;
              this.examenServices.setExamenEvaluacionId(evaluacion._id);
              this.intentoDeRecuperatorio = true;
              this.contadorRecuperables++;        
            }

            /* PARA LOS NUEVOS EXAMENES ASOCIADOS AL CURSO QUE YA TIENEN ID DE ASIGNACION */

            if(evaluacion.examen.concat(evaluacion.asignacion) === this.examen._id.concat(this.examen.asignacion)){
              this.porcentajeAprobado = evaluacion.puntajeObtenido;
              this.examenServices.setExamenEvaluacionId(evaluacion._id);
              this.intentoDeRecuperatorio = true;
              this.contadorRecuperables++;
            }

          }

        });

        if(this.contadorRecuperables >= this.examen.cantidadRecuperable){
          this.btnRecuperatorio1 = false;
          this.btnRecuperatorio2 = false;
          this.sinRecuperables = true;
          this.cargandoRecuperatorio = false;
          const title = 'Ya ha alcanzado la cantidad de recuperatorios disponibles.';
          Swal.fire(
            'Sin intentos posibles!',
            title,
            'info'
          )
        }

      }
  
      if(!this.aprobado){
        const fechaPublicaR1 = new Date(this.examen.fechaPublicaR1);
        const fechaVenceR1 = new Date(this.examen.fechaVenceR1);
        const fechaPublicaR2 = new Date(this.examen.fechaPublicaR2);
        const fechaVenceR2 = new Date(this.examen.fechaVenceR2);
        this.btnRecuperatorio1 = (this.contadorRecuperables === 0 && this.horaServer.getTime() >= fechaPublicaR1.getTime() && this.horaServer.getTime() < fechaVenceR1.getTime())  ? true : false;
        this.btnRecuperatorio2 = (this.sinRecuperables === false && this.contadorRecuperables >= 1 && this.horaServer.getTime() >= fechaPublicaR2.getTime() && this.horaServer.getTime() < fechaVenceR2.getTime()) ? true : false;
        this.cargandoRecuperatorio = false;
      }

      /* VERIFICA LA FECHA DE VENCIMIENTO DEL RECUPERATORIO */
      this.fechaVencimiento = new Date(this.examen.fechaVenceR1);

      /* HORA DEL SERVER */
      this.appConfigService.getServerTime().then((hora:Date)=>{
        this.horaServer = new Date(hora);
        if(this.horaServer.getTime() > this.fechaVencimiento.getTime()){
          this.examenVencido = true;
          this.snackBar.open("Examen vencido","Cerrar",{
            duration: 3000
          });
          this.router.navigateByUrl('/examenes-alumno');
        }
      })
    
    }
  }

  comenzar(): void {
    Swal.fire({
      title: '¿Confirma comenzar el Recuperatorio?',
      text: 'Recuerde no recargar la página y tampoco salir de la misma de lo contrario perderá sus respuestas y no podrá continuar.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then(async (result) => {
      this.progress = true;
      if (result.value) {

      /* GUARDO EXAMEN PARA EVALUAR Y GENERE LAS PREGUNTAS ALEATORIAS */
      const examenEvaluar: ExamenEvaluacion = {
        usuario: (await this.authService.getUserLogon())._id,
        examen: this.examenServices.getExamenSeleccionado()._id, 
        respuesta: this.examenServices.getExamenSeleccionado(),
        asignacion: this.examenServices.getExamenSeleccionado().asignacion,
        recuperatorio: true
      }
      /* EVALÚO EXAMEN Y DEVUELVO EL ID DEL MISMO */
      await this.examenServices.evaluarExamen(examenEvaluar).then((ExamenEvaluacion: ExamenEvaluacion)=>{
        const examenEvaluacionGetOne = ExamenEvaluacion._id;
        /* SETEO EL ID DEL EXAMEN EVALUACION */ 
        this.examenServices.setExamenEvaluacionId(examenEvaluacionGetOne);
        /* SETEO EL EXAMEN(RESPUESTA) QUE CONTIENE LAS PREGUNTAS ALEATORIAS */
        this.examenServices.setExamenEvaluacionRespuesta(ExamenEvaluacion.respuesta)
      });
      /* REDIRIGO RUTA PARA CURSAR EL EXAMEN SELECCIONADO */
      this.router.navigateByUrl('/examen-cursar');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.progress = false;
        this.snackBar.open("Recuperatorio sin comenzar","Cerrar",{
          duration: 3000
        });
      }
    });
  }
  async cantidadDePreguntasArendir(examen: Examen) {
    let cantidadPreguntasRendir: number = 0;
    if (examen.cantidadPreguntasAmostrar == 0) {
      cantidadPreguntasRendir =  examen.preguntas.length;
    } else {
      cantidadPreguntasRendir = examen.cantidadPreguntasAmostrar;
    }
    return cantidadPreguntasRendir;
  }
}
