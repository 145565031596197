
<div class="form-group">
    <div class="col-sm-12">
        <div *ngIf="!loaded" class="alert alert-info" role="alert">
            Procesando, espere por favor...
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        
        <div class="col-md-12"  *ngIf = "this.tableDocuments.length <= 0">
            <br>
            <div class="alert alert-success">
                <strong>Su documentación ya se encuentra en nuestro sistema, no es necesario que la vuelva a cargar.</strong>
            </div>
        </div>
        <br>
        <table class="table custom-table">
            <tr class="file-upload-container">
                <ng-container *ngFor="let document of tableDocuments; let i = index">

                    <ng-container *ngIf="i %  3 ===  0">
                        <td colspan="3"
                            class="text-center"
                            *ngIf="document.message">
                            <strong style="font-size:  18px; color: brown;">{{ document.message
                                }}</strong>
                        </td>

                        <td colspan="9"
                            *ngIf="document.message"></td>
                    </ng-container>
                    <td class="text-center file-upload"
                    *ngIf="!document.message"
                    [class.active]="document.id === activeDocumentId"
                    [style.border-color]="fileSelected && document.id === activeDocumentId ? '#14e970' : '#dce4ec'">
                    <div class="file-upload">
                        <div class="file-select"
                             (click)="openFileInput(document.id)">
                            <div class="file-select-button" id="fileName">{{document.name}}</div>
                            <div class="file-select-name" id="noFile">
                                {{
                                selectedFileNames[document.id]
                                || 'No se seleccionó ningún archivo...'
                                }}
                            </div>
                            <input type="file"
                            [id]="'i'+ document.id"
                            [name]="'i'+ document.id"
                            style="display: none"
                            accept=".png,.jpg,.jpeg,.pdf" 
                            (change)="onFilesSelected($event, document.id)">            
                        </div>
                    </div>
                </td>
                                    
                </ng-container>
            </tr>
            <br>
            <div style="display: flex; justify-content: flex-end;">
                <button *ngIf="tableDocuments.length > 0" mat-raised-button color="primary" (click)="uploadFiles()" [disabled]="flagDisableButtonApprove" [ngClass]="{'custom-disabled': flagDisableButtonApprove}">Subir Archivos</button>
              </div>
              
        </table>
    </div>
</div>