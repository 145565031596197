<mat-card>
    <form [formGroup]="form">
        <div class="example-container">
            <mat-form-field appearance="fill" [style.width.%]=100>
                <mat-label>Respuesta N° {{indexRespuesta+1}}</mat-label>
                <input formControlName="texto" matInput autocomplete="off">
            </mat-form-field>
            <section class="example-section">
                <mat-checkbox class="example-margin" formControlName="esCorrecta">Es correcta</mat-checkbox>
            </section>
            <!--<input type="checkbox" formControlName="esCorrecta" style="margin-right: 5px !important;">Es correcta.-->
        </div>
        <br>
        <mat-accordion *ngIf="this.respuestaConMultimedia.contenido.contiene">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Ver Multimedia de Respuesta
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- ****** DEFINO TIPO DE ARCHIVO A MOSTRAR ****** -->
                <div *ngIf="this.getTipoArchivo(this.respuestaConMultimedia.contenido.type) == 'pdf'"
                    class="animate__animated animate__fadeIn">
                    <iframe
                        [src]="( 3 | mediaCursosPipe: this.respuestaConMultimedia.contenido.source | safeResourceUrl)"
                        type="application/pdf" style="width: 50%; height: 50vh;"></iframe>
                </div>

                <div *ngIf="this.getTipoArchivo(this.respuestaConMultimedia.contenido.type) == 'mp4'"
                    class="animate__animated animate__fadeIn">
                    <iframe
                        [src]="( 2 | mediaCursosPipe: this.respuestaConMultimedia.contenido.source | safeResourceUrl)"
                        type="video/mp4" style="width: 50%; height: 50vh;"></iframe>
                </div>

                <div *ngIf="this.getTipoArchivo(this.respuestaConMultimedia.contenido.type) == 'img'"
                    class="animate__animated animate__fadeIn">
                    <img [src]="( 1 | mediaCursosPipe: this.respuestaConMultimedia.contenido.source | safe)"
                        [alt]=" this.respuestaConMultimedia.contenido.nombreArchivo" style="width: 50%; width: 50%;">
                </div>
                <!-- ****** FIN DEFINO TIPO DE ARCHIVO A MOSTRAR ****** -->
            </mat-expansion-panel>
        </mat-accordion>
        <br>
        <button mat-flat-button color="accent" mat-raised-button [disabled]="!form.valid"
            (click)="updatePregunta(indexPregunta,indexRespuesta)"> Modificar</button>
    </form>
</mat-card>