<!-- <div class="banner-section mt-15"> -->
<div class="slogan-area mtb-15 bg-fffaf3 ptb-100" style="padding-top: 25px !important;">

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">{{ idioma | translate: "home.BIENVENIDA-SUBTITULO"  | async}} </span>
                    <h2>{{ idioma | translate: "home.BIENVENIDA" | async }}<br>Campus Virtual</h2>
                    <p><strong>{{ idioma | translate: "home.BIENVENIDA-PARRAFO-2" | async }}</strong></p>
                    <!-- <a routerLink="/cursos-disponibles-compra" class="link-btn">{{ idioma | translate: "form.BIENVENIDA-LINK-BTN-1" | async }}</a><br> -->
                    <a routerLink="/plataforma-guia" class="link-btn">{{ idioma | translate: "home.BIENVENIDA-PARRAFO-1" | async }}</a>&nbsp;
                    <a routerLink="/ofertas-disponibles" class="link-btn"> {{ idioma | translate: "form.BIENVENIDA-LINK-BTN-2" | async }}</a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">

                    <!-- original -->
                    <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/uTql7G6ArKU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

                    <!-- actual -->
                    <div class="embed-container" *ngIf="flagCargado">
                        <!-- <iframe src="https://www.youtube.com/embed/uTql7G6ArKU" title="YouTube video player" frameborder="0" allowfullscreen></iframe> -->
                        <iframe [src]="( 2 | mediaCursosPipe: videoHome | safeResourceUrl)" type="video/mp4" title="AADIDESS" frameborder="0" allowfullscreen></iframe>
                    </div>

                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>


    <!-- AADIDESS -->
    <div class="subscribe-area bg-fffaf3 ptb-100 d-none d-sm-block" *ngIf="this.tipoSolicitud == 3"> 
        <!-- <div class="divider"></div> -->
        <div class="shape3"><img src="assets/img/shape3.png" alt="image" width="70%"></div>
        <div class="shape4"><img src="assets/img/shape4.png" alt="image" width="70%"></div>
        <div class="shape2"><img src="assets/img/shape2.png" alt="image" width="70%"></div>
    </div>


    <!-- OTROS -->
        <div class="subscribe-area bg-fffaf3 ptb-100 d-none d-sm-block" *ngIf="this.tipoSolicitud != 3"> 
        <!-- <div class="divider"></div> -->
        <div class="shape3"><img src="assets/img/shape16.png" alt="image" width="70%"></div>
        <div class="shape4"><img src="assets/img/shape4.png" alt="image" width="70%"></div>
        <div class="shape2"><img src="assets/img/shape2.png" alt="image" width="70%"></div>
    </div>

</div>