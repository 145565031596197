  <!-- INCRIPCION DE TIPO 1 - PARA LISTADOS DE MUCHAS INCRIPCIONES SIN TARJETA -->
  <app-ofertas-listar  *ngIf="this.tipoSolicitud == 1" [flagViewTitle]="false" [highValue]="3"></app-ofertas-listar>

  <!-- INCRIPCION DE TIPO 2 - PARA LISTADOS DE POCAS INCRIPCIONES CON TARJETA -->
  <app-ofertas-listar-tarjetas *ngIf="this.tipoSolicitud == 2"  [flagViewTitle]="false" [highValue]="3"></app-ofertas-listar-tarjetas>

  <!-- INCRIPCION DE TIPO 3 - Botones AADIDESS-->
  <br>
  <button  *ngIf="this.tipoSolicitud == 3" type="button" routerLink="inscripcion-pista" class="btn btn-warning">Formulario de Pre Inscripción Cursado en Pista</button>
  <button  *ngIf="this.tipoSolicitud == 3" style="margin-left: 5px;" type="button" routerLink="inscripcion-online" class="btn btn-warning">Formulario de Pre Inscripción Cursado en Línea</button>
  <br>


  <div class="col-lg-12 col-md-12">
      <div class="courses-info">
          <a routerLink="/ofertas-disponibles" class="default-btn"><i class="flaticon-people"></i>{{ idioma | translate: "form.VER-OFERTAS-BTN" | async}} <span></span></a>
      </div>
  </div>
  <!-- FIN OFERTAS LABORALES -->
  
<!-- <app-loading *ngIf='!this.flagLoaded'>

</app-loading>

<div class="courses-area ptb-100">

    <div class="container">

        <div class="odemy-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count" *ngIf="this.flagLoaded">
                <p>{{ idioma | translate: "oferta-laboral.ENCONTRAMOS" | async}} {{this.actividadesDisponibles.length}} <span class="count"></span>{{ idioma | translate: "home.OFERTAS-TITULO" | async}}</p>
            </div>     
        </div>
        

        <mat-paginator *ngIf="this.flagLoaded" [length]="this.actividadesDisponibles.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getPaginatorData($event)" aria-label="Select page" showFirstLastButtons="false">
        </mat-paginator>

        <div class="row">
            <div class="col-lg-4 col-md-6 animate__animated animate__fadeIn" *ngFor="let actividad of (this.actividadesDisponibles | slice: lowValue : highValue)">
                <app-ofertas-avatar [actividad]="actividad"></app-ofertas-avatar>
            </div>
        </div>

    </div>

</div>
 -->
