import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { Curso, CursoActividad } from '../../../../interfaces/interface-bakend/cursos/curso.interface';
import { ValidadoresService } from '../../../../services/shared/validadores.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { APP_CONFIG_SHARED, IAppShared } from 'src/app/app.config/app.config-shared';
import { MediaService } from '../../../../services/shared/media.service';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { ThemePalette } from '@angular/material/core';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';

@Component({
  selector: 'app-curso-alta',
  templateUrl: './curso-alta.component.html',
  styleUrls: ['./curso-alta.component.scss']
})
export class CursoAltaComponent implements OnInit, OnDestroy {

  flagFormaValido = false;
  selectedFileImg: any;
  selectedFileMp4: any;
  value = 0;
  cargandoArchivo = false;
  subscriptionAvance: Subscription;
  ngUnsubscribe = new Subject<void>();
  form: FormGroup;
  condition = false;
  fileName = 'No file selected';
  file: File;

  /* ripple */
  centered = false;
  disabled = false;
  unbounded = false;

  /* img portada */
  fileAttr = 'Choose File';
  radius: number;
  color: string;

  imageUrl: string | ArrayBuffer =
    './assets/img/uploadImg.png';

  /*Paneles*/
  flagActivitiesPanel: boolean = false;
  flagDelagationsPanel: boolean = false;
  flagCalendarsPanel: boolean = false;
  flagAvailableDatesPanel: boolean = false;

  selectorDelegaciones: Catalogo[];

  //Config componente fecha hora
  public disabled1 = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate1: Date;
  public maxDate: Date;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color1: ThemePalette = 'primary';
  public disableMinute = false;
  public hideTime = false;
  public dateControl = new FormControl(null);

  constructor(
    private fb: FormBuilder,
    private cursoService: CursosService,
    private validadores: ValidadoresService,
    private mediaService: MediaService,
    private guiMsjService: GuiMsjService,
    private catalogoService: CatalogoService, @Inject(APP_CONFIG_SHARED)
    private iAppShared: IAppShared,
    private router: Router,
  ) {

    this.subscribeAvanceUpload();

    this.form = this.fb.group({
      nombre: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      introduccion: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      descripcionGral: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]),
      cantidadHoras: new FormControl('', [Validators.required]),
      fechaInicio: new FormControl('', [Validators.required]),
      fechaFin: new FormControl('', [Validators.required]),
      precio: new FormControl('', [Validators.required]),
      disponible: new FormControl(true),
      modulo: new FormControl(false),
      pista: new FormControl(false),
      actividades: new FormArray([],),
      delegaciones: new FormArray([],),
      calendarios: new FormArray([],),
      fechasDisponibles: new FormArray([],),
      imagen: new FormControl('', [Validators.required]),
      video: new FormControl('',),
    }, {
      validators: this.validadores.fechaFinMenor('fechaInicio', 'fechaFin')
    });

    this.createListenersStateForm();

  }

  ngOnInit(): void {
    this.getDelegaciones();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
   }

  async getDelegaciones() {
    try {
      const resp: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.delegaciones, null);
      this.selectorDelegaciones = resp;
    } catch (error) {
      console.error('Error al obtener las delegaciones', error);
    }
  }

  /* PANELES */

  get actividades(): FormArray {
    return this.form.get("actividades") as FormArray;
  }

  get delegaciones(): FormArray {
    return this.form.get("delegaciones") as FormArray;
  }

  get fechasDisponibles(): FormArray {
    return this.form.get("fechasDisponibles") as FormArray;
  }

  get calendarios(): FormArray {
    return this.form.get("calendarios") as FormArray;
  }

  get pista(): boolean {
    return this.form.get('pista').value;
  }

  newActivity(): FormGroup {
    return this.fb.group({
      tipo: new FormControl({ value: 'ACTIVIDAD', disabled: true }, [Validators.required]),
      nombreActividad: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', []),
      fecha: new FormControl('', [Validators.required]), // no lo llamo desde, porque hay mucho cargado ya, no quiero cambiar el nombre del campo
      fechaVencimiento: new FormControl('', [Validators.required]),
      link: new FormControl('', []),
    })
  }

  newDelegation(): FormGroup {
    return this.fb.group({
      tipo: new FormControl({ value: 'DELEGACIÓN', disabled: true }, [Validators.required]),
      delegacion: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', []),
      fecha: new FormControl('', [Validators.required]), // no lo llamo desde, porque hay mucho cargado ya, no quiero cambiar el nombre del campo
      fechaVencimiento: new FormControl('', []),
    })
  }

  newCalendar(): FormGroup {
    return this.fb.group({
      tipo: new FormControl({ value: 'CALENDARIO', disabled: true }, [Validators.required]),
      nombreCalendario: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', []),
      fecha: new FormControl('', [Validators.required]), // no lo llamo desde, porque hay mucho cargado ya, no quiero cambiar el nombre del campo
      fechaVencimiento: new FormControl('', [Validators.required]),
      link: new FormControl('', []),
    })
  }

  newAvailableDate(): FormGroup {
    return this.fb.group({
      tipo: new FormControl({ value: 'FECHAS DISPONIBLES', disabled: true }, [Validators.required]),
      descripcion: new FormControl('', []),
      fecha: new FormControl('', [Validators.required]), // no lo llamo desde, porque hay mucho cargado ya, no quiero cambiar el nombre del campo
      fechaVencimiento: new FormControl('', [Validators.required]),
    })
  }

  addActivity() {
    this.flagActivitiesPanel = true;
    this.actividades.push(this.newActivity());
  }

  removeActivity(index: number) {
    this.actividades.removeAt(index);
  }

  addDelegation() {
    if (this.pista) {
      this.fechasDisponibles.clear();
    }
    this.flagDelagationsPanel = true;
    this.delegaciones.push(this.newDelegation());
  }

  removeDelegation(index: number) {
    this.delegaciones.removeAt(index);
  }

  addCalendar() {
    this.flagCalendarsPanel = true;
    this.calendarios.push(this.newCalendar());
  }

  removeCalendar(index: number) {
    this.calendarios.removeAt(index);
  }

  addAvailableDate() {
    if (!this.pista) {
      this.delegaciones.clear();
    }
    this.flagAvailableDatesPanel = true;
    this.fechasDisponibles.push(this.newAvailableDate());
  }

  removeAvailableDate(index: number) {
    this.fechasDisponibles.removeAt(index);
  }

  async setPaneles(pActividades: any[], pDelegaciones: any[], pCalendarios: any[], pFechasDisponibles: any[]): Promise<CursoActividad[]> {
    let paneles: CursoActividad[] = [];

    // Procesar actividades
    if (pActividades.length > 0) {
      pActividades.forEach((actividad: any) => {
        actividad = {
          tipo: 'ACTIVIDAD',
          nombre: actividad.nombreActividad,
          descripcion: actividad.descripcion,
          fecha: actividad.fecha,
          fechaVencimiento: actividad.fechaVencimiento,
          link: actividad.link,
        }
        paneles.push(actividad);
      });
    }

    // Procesar delegaciones solo si es un curso por pista
    if (this.pista && pDelegaciones.length > 0) {
      pDelegaciones.forEach((delegacion: any) => {
        delegacion = {
          tipo: 'DELEGACIÓN',
          nombre: delegacion.delegacion,
          descripcion: delegacion.descripcion,
          fecha: delegacion.fecha,
          fechaVencimiento: delegacion.fechaVencimiento,
        }
        paneles.push(delegacion);
      });
    }

    // Procesar calendarios
    if (pCalendarios.length > 0) {
      pCalendarios.forEach((calendario: any) => {
        calendario = {
          tipo: 'CALENDARIO',
          nombre: calendario.nombreCalendario,
          descripcion: calendario.descripcion,
          fecha: calendario.fecha,
          fechaVencimiento: calendario.fechaVencimiento,
          link: calendario.link,
        }
        paneles.push(calendario);
      });
    }

    // Procesar fechas disponibles solo si es un curso online
    if (!this.pista && pFechasDisponibles.length > 0) {
      pFechasDisponibles.forEach((fechasDisponibles: any) => {
        fechasDisponibles = {
          tipo: 'FECHAS DISPONIBLES',
          descripcion: fechasDisponibles.descripcion,
          fecha: fechasDisponibles.fecha,
          fechaVencimiento: fechasDisponibles.fechaVencimiento,
        }
        paneles.push(fechasDisponibles);
      });
    }

    return paneles;
  }

  /* FIN PANELES */

  /*onSubmit() {
  console.log(this.form.value);
  }*/

  async setDataForma(): Promise<Curso> {

    const data: Curso = {
      nombre: this.form.value.nombre,
      descripcionGeneral: this.form.value.descripcionGral,
      cantidadHoras: this.form.value.cantidadHoras,
      fechaInicio: this.form.value.fechaInicio,
      fechaFin: this.form.value.fechaFin,
      introduccion: this.form.value.introduccion,
      precio: this.form.value.precio,
      modulo: this.form.value.modulo,
      disponible: this.form.value.disponible,
      pista: this.form.value.pista,
      actividades: await this.setPaneles(this.form.value.actividades, this.form.value.delegaciones, this.form.value.calendarios, this.form.value.fechasDisponibles),
    };
    
    return data;
  }

  async post() {

    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach(control => {
        control.markAsTouched();
      });
    }

    await this.guiMsjService.msjFormSubmit('Guardando');
    await this.cursoService.postCurso(await this.setDataForma()).then(async (curso: Curso) => {
      await this.uploadFileImg(this.selectedFileImg, curso.id);
      if (this.selectedFileMp4 != null) {
        await this.uploadFileMp4(this.selectedFileMp4, curso.id);
      }
      await this.guiMsjService.msjFormSubmit('DatosGuardados');
      this.router.navigate(['/cursos-listado']);
      return;
    });
  }

  /* MÉTODOS PARA VALIDACIÓN DE FORMULARIO */

  get nombreNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  get introduccionNoValida() {
    return this.form.get('introduccion').invalid && this.form.get('introduccion').touched;
  }

  get descripcionGralNoValida() {
    return this.form.get('descripcionGral').invalid && this.form.get('descripcionGral').touched;
  }

  get cantidadHorasNoValida() {
    return this.form.get('cantidadHoras').invalid && this.form.get('cantidadHoras').touched;
  }

  get fechaInicioNoValida() {
    return this.form.get('fechaInicio').invalid && this.form.get('fechaInicio').touched;
  }

  get fechaFinNoValida() {
    return this.form.get('fechaFin').invalid && this.form.get('fechaFin').touched;
  }

  get precioNoValido() {
    return this.form.get('precio').invalid && this.form.get('precio').touched;
  }

  /* FIN MÉTODOS VALIDACION FORMULARIO */

  /* UPLOAD */

  /* subo archivo con observable*/
  subscribeAvanceUpload() {
    this.subscriptionAvance = this.mediaService.getAvance()
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(resp => {
      if (resp) {
        this.value = resp.value;
      } else {
        this.value = 0;
      }
    });
  }

  openFileImgSelected(event) {
    this.selectedFileImg = <File>event.target.files[0];
  }

  onChangeFile(file: File, event) {
    if (file) {
      this.openFileImgSelected(event);
      this.fileName = file.name;
      this.file = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = e => {
        this.imageUrl = reader.result;
      }
    }
  }

  async uploadFileImg(selectedFile: any, idCurso: string) {
    await this.mediaService.uploadImgCursoPresentacion(selectedFile, idCurso)
    .then(resp => {
      if (resp) {
        if (this.value === 100) { this.cargandoArchivo = false; }
      } else {
        this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
      }
    })
    .catch(error => {
      console.error('Error al subir la imagen', error);
    });
  }

  openFileMp4Selected(event) {
    this.selectedFileMp4 = <File>event.target.files[0];
  }

  async uploadFileMp4(selectedFile: any, idCurso: string) {
    this.cargandoArchivo = true;
    await this.mediaService.uploadMp4CursoPresentacion(selectedFile, idCurso)
    .then(resp => {
      if (resp) {
        if (this.value === 100) { this.cargandoArchivo = false; }
      } else {
        this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
      }
    })
    .catch(error => {
      console.error('Error al subir el video', error);
    });
  }
  /* FIN UPLOAD */

  createListenersStateForm() {
    return this.form.statusChanges.subscribe(async status => {
      // console.log(this.form);
    });
  }
}
