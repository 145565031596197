import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from '../../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { Translators } from '../../../../../services/shared/translators.service';
import { GuiToolsService } from '../../../../../services/shared/gui-tools.service';

@Component({
  selector: 'app-modal-confirmar',
  templateUrl: './modal-confirmar.component.html',
  styleUrls: ['./modal-confirmar.component.scss']
})
export class ModalConfirmarComponent implements OnInit {

  idioma: Subscription;

  constructor(
       private guiToolsService: GuiToolsService,
       /* Modal Ref. */
       public dialogRef: MatDialogRef<ModalConfirmarComponent>,
       @Inject(MAT_DIALOG_DATA) public data: User,
       /* Fin Modal Ref. */
       private translatorService: Translators
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
    this.idioma = resp;
  });
}

  birth: string;
  pasaporteValido;


  ngOnInit(): void {
    this.birth = this.guiToolsService.dateTransform( this.data.birth);
    this.pasaporteValido =  this.data.pasaporteValido.toString();
  }

  aceptar() {
    this.dialogRef.close(true);
  }

  cancelar() {
    this.dialogRef.close(false);
  }

}
