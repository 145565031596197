<div class="page-title-area">
    <div class="container">
        <div>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('es')"><img src="assets/img/flags/espana.png" alt="Español" width="20"> ES</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('en')"><img src="assets/img/flags/usa.png" alt="English" width="20"> EN</button>
        </div>
        <div class="page-title-content">
            <h2>
                <a routerLink="/"><img src="assets/img/logo-aadidess-white.png" alt="ski-andorra" width="30%"></a><br></h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div *ngIf="this.lenguaje === 'es'">
    <div class="row">
        <div class="col-lg-3 col-md-3 col-xs-3"></div>
        <div class="col-lg-9 col-md-9 col-xs-9">
            <div class="contenedor" style="width:min-content;justify-content: center;display:inline-block;margin-left:10px;margin-top:10px;">
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><span style="font-size:13px;background:white;">&nbsp;</span></p>
                <table style="border: none;margin-left:5.4pt;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td colspan="2" style="width: 469.95pt;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; Protecci&oacute;n de datos &nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 469.95pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 405.7pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><em><strong><span style='font-size:13px;font-family:"Arial","sans-serif";'>Textos Legales legislaci&oacute;n aplicable estado de Florida&nbsp;</span></strong></em><em><span style='font-family:"Arial","sans-serif";'>-&nbsp;</span></em><em><strong><span style='font-size:13px;font-family:"Arial","sans-serif";'>  2016/679&nbsp;</span></strong></em></p>
                            </td>
                            <td style="width: 64.25pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><em><span style="font-size:13px;color:windowtext;">Ver. 21.3</span></em></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 469.95pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><em><span style='font-size:13px;font-family:"Arial","sans-serif";'>&nbsp;</span></em></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:windowtext;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong></p>
                <table style="border: none;margin-left:5.4pt;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td style="width: 35.45pt;background: rgb(231, 230, 230);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style="font-size:13px;color:windowtext;">1.1</span></strong></p>
                            </td>
                            <td style="width: 439.45pt;background: rgb(231, 230, 230);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; Aviso Legal &nbsp;&nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 474.9pt;padding: 0cm 5.4pt;height: 4.05pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 35.45pt;background: rgb(231, 230, 230);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style="font-size:13px;color:windowtext;">1.2</span></strong></p>
                            </td>
                            <td style="width: 439.45pt;background: rgb(231, 230, 230);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; Pol&iacute;tica de Privacidad &nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 474.9pt;padding: 0cm 5.4pt;height: 3.7pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><em><span style='font-size:13px;font-family:"Arial","sans-serif";'>&nbsp;</span></em></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 35.45pt;background: rgb(231, 230, 230);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style="font-size:13px;color:windowtext;">1.3</span></strong></p>
                            </td>
                            <td style="width: 439.45pt;background: rgb(231, 230, 230);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; Pol&iacute;tica de Cookies &nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 35.45pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style="font-size:13px;color:windowtext;">&nbsp;</span></strong></p>
                            </td>
                            <td style="width: 439.45pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 35.45pt;background: rgb(231, 230, 230);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style="font-size:13px;color:windowtext;">1.4</span></strong></p>
                            </td>
                            <td style="width: 439.45pt;background: rgb(231, 230, 230);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp;&nbsp;Informaci&oacute;n Adicional&nbsp;</span></strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <table style="border: none;margin-left:5.4pt;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td style="width: 35.45pt;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style="font-size:13px;color:white;">1.1</span></strong></p>
                            </td>
                            <td style="width: 439.45pt;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; Aviso Legal &nbsp;</span></strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;"><br> <strong>DATOS IDENTIFICATIVOS DEL PRESTADOR DE SERVICIOS DE LA SOCIEDAD DE LA INFORMACI&Oacute;N</strong></span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>De conformidad a las disposiciones aplicables sobre el tratamiento de datos personales en el sector de las comunicaciones electr&oacute;nicas, ponemos</span>
                    <span style="font-size:13px;color:windowtext;">&nbsp;en su conocimiento la siguiente informaci&oacute;n:</span>
                </p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">AADIDESS LLC, [</span></strong><span style="font-size:13px;">de ahora en adelante <strong>AADIDESS],&nbsp;</strong></span><span style="font-size:13px;color:windowtext;">es el titular del portal Web <strong>aadidess.cipbyte.io.io</strong>,&nbsp;</span>
                    <span style="font-size:13px;">que act&uacute;a exclusivamente como explotadora, y gestora de los contenidos de los portales Web</span><span style="font-size:13px;color:windowtext;">&nbsp;domiciliada en: Sede Central Morales 483 PB (8400) San Carlos de Bariloche</span>
                    <span style="font-size:13px;">, empresa constituida con las disposiciones aplicables, es la encargada de la explotaci&oacute;n, gesti&oacute;n y funcionamiento del portal Web mencionado anteriormente.</span>
                </p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;"><br>&nbsp;</span><span style="font-size:13px;">Si quiere contactar con nosotros, puede hacerlo mediante correo postal a la direcci&oacute;n mencionada al p&aacute;rrafo anterior, o bien a trav&eacute;s del correo electr&oacute;nico&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">info@aadidess.io</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">INTRODUCCI&Oacute;N</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">El presente Aviso legal regula el uso de esta p&aacute;gina web. El acceso en la web es gratuito excepto en el relativo al coste de la conexi&oacute;n a trav&eacute;s de la red de telecomunicaciones suministrada por el proveedor de acceso contratado por los usuarios.<br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:windowtext;">ACEPTACI&Oacute;N</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">La utilizaci&oacute;n del portal Web atribuye la condici&oacute;n de usuario, e implica la aceptaci&oacute;n total y sin reservas de todas y cada una de las disposiciones incluidas en este Aviso Legal, en la versi&oacute;n publicada para&nbsp;</span><strong><span style="font-size:13px;">AADIDESS</span></strong>
                    <span style="font-size:13px;color:windowtext;">&nbsp;</span><span style="font-size:13px;">en el mismo momento en que el usuario acceda a la Web. En consecuencia, el usuario tiene que leer atentamente el presente <strong>Aviso Legal</strong> en cada una de las ocasiones en que se proponga utilizar la Web, puesto que puede sufrir modificaciones.</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:windowtext;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:windowtext;">INFORMACI&Oacute;N SOBRE LOS V&Iacute;NCULOS &ldquo;LINKS&rdquo;</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">AADIDESS</span></strong><span style="font-size:13px;color:windowtext;">&nbsp;</span><span style="font-size:13px;">no se hace responsable de las webs no propias o de terceros, a las cuales se puede acceder mediante v&iacute;nculos &ldquo;<strong>links</strong>&rdquo; o de cualquier contenido puesto a disposici&oacute;n por terceros.</span>
                    <span style="font-size:13px;"><br> Cualquier uso de un v&iacute;nculo o acceso a una Web no propia es realizado por voluntad y riesgo exclusivo del usuario,&nbsp;<strong>AADIDESS</strong></span><span style="font-size:13px;color:windowtext;">&nbsp;</span>
                    <span style="font-size:13px;">no recomienda ni garantiza ninguna informaci&oacute;n obtenida a trav&eacute;s de un v&iacute;nculo ajeno a la web de&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">aadidess.cipbyte.io.io,</span></strong>
                    <span style="font-size:13px;color:windowtext;">&nbsp;</span><span style="font-size:13px;">ni se responsabiliza de ninguna p&eacute;rdida, reclamaci&oacute;n o perjuicio derivada del uso o mal uso de un v&iacute;nculo, o de la informaci&oacute;n obtenida a trav&eacute;s de &eacute;l, incluyendo otros v&iacute;nculos o webs, de la interrupci&oacute;n en el servicio o en el acceso, o del uso &nbsp; o mal uso de un v&iacute;nculo, tanto al conectar al portal Web&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">aadidess.cipbyte.io.io</span></strong>
                    <span style="font-size:13px;">&nbsp;c&oacute;mo al acceder a la informaci&oacute;n de otras webs desde el mismo portal Web.</span><span style="font-size:13px;color:windowtext;"><br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:windowtext;"><br>&nbsp;</span></strong><strong><span style="font-size:13px;">LIMITACI&Oacute;N DE GARANT&Iacute;AS Y RESPONSABILIDADES</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;">hace todo lo posible para evitar cualquier error en relaci&oacute;n con los contenidos de esta p&aacute;gina web.&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;">adopta las medidas y los mecanismos necesarios para garantizar la seguridad de las comunicaciones y las transacciones que se produzcan mediante el portal web, seg&uacute;n el estado de la tecnolog&iacute;a y de acuerdo con la tipolog&iacute;a de las transacciones y los servicios que se ponen a disposici&oacute;n de los usuarios.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;">no ser&aacute; responsable de los da&ntilde;os que se puedan ocasionar al sistema inform&aacute;tico de los usuarios por cualquier causa ajena o no imputable a este sitio web, incluidos, sin limitaciones, los da&ntilde;os ocasionados a causa de interferencias, omisiones, interrupciones, virus inform&aacute;ticos, aver&iacute;as telef&oacute;nicas o desconexiones en el funcionamiento operativo del sistema electr&oacute;nico. Asimismo,&nbsp;</span><strong><span style="font-size:13px;">AADIDESS</span></strong>
                    <span style="font-size:13px;">&nbsp;tampoco responder&aacute; de ninguna interrupci&oacute;n, error o fallo que se produzca en el sistema, debido a un mal funcionamiento de la red o de los servidores que est&aacute;n conectados.<br>&nbsp;</span>
                    <span style="font-size:13px;"><br>&nbsp;</span>
                </p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style="font-size:13px;">PROPIEDAD INTELECTUAL E INDUSTRIAL, MARCAS REGISTRADAS</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">La estructura, dise&ntilde;o y forma de presentaci&oacute;n de los elementos (gr&aacute;ficos, im&aacute;genes, ficheros logotipos, combinaciones de colores y cualquier elemento susceptible de protecci&oacute;n) est&aacute;n protegidos por derechos de propiedad intelectual, titularidad del&nbsp;</span><strong><span style="font-size:13px;">AADIDESS.</span></strong>
                    <span style="font-size:13px;"><br>&nbsp;<br> Est&aacute; prohibida la reproducci&oacute;n, la transformaci&oacute;n, la distribuci&oacute;n, la comunicaci&oacute;n p&uacute;blica, y en general cualquier otra forma de explotaci&oacute;n, parcial o total de los elementos
                        referidos en el apartado anterior. Estos actos de explotaci&oacute;n s&oacute;lo podr&aacute;n ser realizados en virtud de la autorizaci&oacute;n expresa de&nbsp;<strong>AADIDESS</strong> y que, en todo caso, tendr&aacute;n que
                        hacer referencia expl&iacute;cita a la titularidad de los citados derechos de propiedad intelectual de&nbsp;<strong>AADIDESS.</strong><br>&nbsp;<br> S&oacute;lo est&aacute; autorizado para el uso privado del material documental
                        elaborado para&nbsp;<strong>AADIDESS.</strong> En ning&uacute;n caso, podr&aacute; suprimir, alterar, eludir o manipular cualquier dispositivo de protecci&oacute;n o sistemas de seguridad que pueda estar instalado.<br>&nbsp;<br>                        Excepto autorizaci&oacute;n expresa de&nbsp;<strong>AADIDESS</strong> no se permite el enlace a &ldquo;p&aacute;ginas finales&rdquo;, el &ldquo;frame&rdquo; y cualquier otra manipulaci&oacute;n similar. Los enlaces tienen que ser
                        siempre en la p&aacute;gina principal o &ldquo;home page&rdquo; de <strong>aadidess.cipbyte.io.io</strong><br>&nbsp;<br> Los signos distintivos (marcas, nombres comerciales) de&nbsp;<strong>AADIDESS</strong> est&aacute;n protegidos por derechos
                        de propiedad industrial, quedando prohibida la utilizaci&oacute;n o manipulaci&oacute;n de cualquiera de estos, excepto autorizaci&oacute;n expresa y por escrito de&nbsp;<strong>AADIDESS.</strong></span>
                </p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">COMPRA ON-LINE SEGURA&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">La seguridad es una prioridad para <strong>AADIDESS</strong> por lo que realizamos el m&aacute;ximo esfuerzo para asegurar que nuestros procesos de transacciones sean seguros y de que su informaci&oacute;n personal est&eacute; protegida.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-left:36.0pt;text-align:justify;'><strong><sup><span style="font-size:13px;">&nbsp;</span></sup></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-left:36.0pt;text-align:justify;'><strong><sup><span style="font-size:13px;">&nbsp;</span></sup></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">NO COMPARTA SU INFORMACI&Oacute;N PERSONAL</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">AADIDESS</span></strong><span style="font-size:13px;">&nbsp;nunca le enviar&aacute; un correo electr&oacute;nico ni le llamar&aacute; por tel&eacute;fono para pedirle que revele o verifique la contrase&ntilde;a de su cuenta, ni sus n&uacute;meros de cuenta bancaria o tarjeta de cr&eacute;dito, ni cualquier otro tipo de informaci&oacute;n personal. En caso de que alguien contacte con usted o de recibir un correo electr&oacute;nico no solicitado que le pida alguno de los datos anteriores, no responda y comun&iacute;quelo inmediatamente a <strong>AADIDESS</strong>, para que procedan a la investigaci&oacute;n del incidente.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">IDENTIFIQUE LOS CORREOS ELECTR&Oacute;NICOS FALSOS (intentos de &apos;spoofing&apos; o &apos;phishing&apos;)</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:3.75pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;background:white;">Ignore todo correo electr&oacute;nico que reciba en el que se le solicite informaci&oacute;n personal o que le redirija en otra p&aacute;gina web que no sea propiedad de <strong>AADIDESS</strong>, o de sociedades del grupo, o que le pida que pague por un medio diferente del autorizado por <strong>AADIDESS</strong>, puesto que podr&iacute;a ser un intento de suplantaci&oacute;n de personalidad (&apos;spoofing&apos; o &apos;phishing&apos;), y tendr&aacute; que considerarlo fraudulento.</span></p>
                <p style='margin:0cm;margin-bottom:3.75pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:3.75pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">AADIDESS</span></strong><span style="font-size:13px;">&nbsp;utiliza el dominio&nbsp;</span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&ldquo;</span></strong><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>aadidess.cipbyte.io.io</span></strong><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&rdquo;</span></strong>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span><span style="font-size:13px;">para sus correos electr&oacute;nicos. Si usted recibe un correo electr&oacute;nico con un formato diferente, por ejemplo: aadidess.cipbyte.io.security@hotmail.com, puede estar seguro de que es un correo falso.</span></p>
                <p style='margin:0cm;margin-bottom:3.75pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Algunos correos electr&oacute;nicos que practican phishing contienen enlaces a sitios web que utilizan la palabra &quot;<strong>AADIDESS</strong>&rdquo; en su URL, pero le dirigir&aacute;n a un sitio web completamente diferente. Si desplaza el rat&oacute;n por encima del enlace, podr&aacute; ver la URL asociada, que probablemente tendr&aacute; un formato diferente a las enlazadas en los sitios web aut&eacute;nticos de&nbsp;</span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>LEVEL</span></strong><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;7</span></strong><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>.</span></strong></p>
                <p style='margin:0cm;margin-bottom:3.75pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Si aun as&iacute; usted hace clic en un correo electr&oacute;nico que practica phishing y es redirigido a una p&aacute;gina que se asemeje a &quot;A su Cuenta&quot; o a cualquiera que le solicite verificar o modificar su informaci&oacute;n personal, ign&oacute;rela y consid&eacute;rela como fraudulenta.</span></p>
                <p style='margin:0cm;margin-bottom:3.75pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">COMUNIQUE LOS INTENTOS DE &apos;phishing&apos;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;background:white;">Env&iacute;e un correo electr&oacute;nico a la direcci&oacute;n&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">info@aadidess.io</span></strong><span style="font-size:13px;background:white;">&nbsp;y adjunte el correo electr&oacute;nico que considere falso. Al facilitarnos esta informaci&oacute;n nos est&aacute; ayudando a localizar su origen.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;background:white;">Si no puede adjuntar el correo electr&oacute;nico falso, reenv&iacute;elo a&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">info@aadidess.io</span></strong><span style="font-size:13px;background:white;">&nbsp;e incluya la mayor cantidad de informaci&oacute;n posible sobre el mismo.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">&nbsp;</span></strong></p>
                <table style="border: none;margin-left:5.4pt;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td style="width: 49.65pt;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style="font-size:13px;color:white;">1.2</span></strong></p>
                            </td>
                            <td style="width: 431.1pt;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; Pol&iacute;tica de Privacidad &nbsp;</span></strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:13px;color:windowtext;"><br> <strong>RESPONSABLE</strong></span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">De conformidad con la legislaci&oacute;n aplicable y lo establecido por&nbsp;</span><span style="font-size:13px;color:windowtext;">el</span><span style="font-size:13px;">&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">Reglamento General (UE) 2016/679 de Protecci&oacute;n de datos personales&nbsp;</span></strong>
                    <span style="font-size:13px;color:windowtext;">le informamos que los datos personales facilitados a trav&eacute;s de los formularios, as&iacute; como su direcci&oacute;n de correo electr&oacute;nico, han sido incluidos en un tratamiento titularidad de&nbsp;</span><strong><span style="font-size:13px;">AADIDESS LLC, <span style="background:white;">[</span></span></strong>
                    <span style="font-size:13px;">de ahora en adelante<strong>&nbsp;AADIDESS],&nbsp;</strong><span style="background:white;">con domicilio social en&nbsp;</span></span><span style="font-size:13px;color:windowtext;">Sede Central Morales 483 PB (8400) San Carlos de Bariloche</span>
                    <span style="font-size:13px;">, empresa constituida con las disposiciones aplicables, es la encargada de la explotaci&oacute;n, gesti&oacute;n y funcionamiento del portal Web mencionado anteriormente.</span>
                </p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;"><br>&nbsp;</span><span style="font-size:13px;">Se informa que los datos pueden ser tratados con la finalidad de facilitar informaci&oacute;n de nuestros productos y/o servicios por medios electr&oacute;nicos, siempre que nos autorice a su tratamiento. Sus datos podr&aacute;n ser objeto de la elaboraci&oacute;n de perfiles, con el fin de aplicar un plan de fidelizaci&oacute;n de acuerdo a la vinculaci&oacute;n comercial del cliente y ofrecer ofertas que sean adecuadas a sus preferencias.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Usted podr&aacute; en cualquier momento oponerse a este tipo de comunicaciones, enviando un correo electr&oacute;nico a la direcci&oacute;n&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">info@aadidess.io</span></strong>
                    <span style="font-size:13px;">&nbsp;</span><span style="font-size:13px;color:windowtext;">e&nbsp;</span><span style="font-size:13px;">indicando en el asunto <strong>&quot;Baja de la lista de distribuci&oacute;n&quot;.</strong></span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">As&iacute; mismo, podr&aacute; ejercer los derechos de <strong>a</strong>cceso, <strong>r</strong>ectificaci&oacute;n, <strong>s</strong>upresi&oacute;n, <strong>o</strong>posici&oacute;n, <strong>p</strong>ortabilidad y <strong>l</strong>imitaci&oacute;n, enviando un escrito acompa&ntilde;ado de una fotocopia del D.N.I o documento acreditativo equivalente a la siguiente direcci&oacute;n electr&oacute;nica: &nbsp;</span><strong><span style="font-size:13px;color:windowtext;">info@aadidess.io</span></strong>
                    <span style="font-size:13px;">&nbsp;</span><span style="font-size:13px;color:windowtext;">indicando en el asunto &quot;<strong>Protecci&oacute;n de datos</strong>&quot;.<br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;"><br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:windowtext;">POL&Iacute;TICA</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">AADIDESS</span></strong><span style="font-size:13px;color:windowtext;">&nbsp;est&aacute; especialmente sensibilizado con la protecci&oacute;n de los datos de los usuarios de los servicios que acceden al sitio web. Mediante la presente Pol&iacute;tica de Privacidad se informa a los usuarios de <strong>aadidess.cipbyte.io.io</strong> del tratamiento y usos a los que se someten los datos personales que se recaban en la web mediante la <strong>informaci&oacute;n adicional</strong>, con el fin de que decidan, libre y voluntariamente, si desean facilitar la informaci&oacute;n solicitada.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">MEDIDAS DE SEGURIDAD</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Finalmente se informa que&nbsp;<strong>AADIDESS,&nbsp;</strong>ha adoptado en el sistema de informaci&oacute;n las medidas t&eacute;cnicas y organizativas adecuadas, con objeto de garantizar la seguridad y confidencialidad de los datos almacenados, evitando as&iacute;, su alteraci&oacute;n, p&eacute;rdida, tratamiento o acceso no autorizado; teniendo en cuenta el estado de la t&eacute;cnica, los costes de aplicaci&oacute;n, y la naturaleza, el alcance, el contexto y las finalidades del tratamiento, as&iacute; como riesgos de probabilidad y gravedad variables asociadas a cada uno de los tratamientos..<br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">DERECHOS DE LOS USUARIOS</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">El interesado de los datos personales podr&aacute; ejercitar los derechos que le asisten, de acuerdo con el Reglamento General de Protecci&oacute;n de Datos, y que son:</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">A</span></strong><span style="font-size:13px;">.- El derecho de <strong>acceso</strong>, se podr&aacute; ejercitar con periodicidad anual, excepto inter&eacute;s leg&iacute;timo acreditado.&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">AADIDESS</span></strong><strong><span style="font-size:13px;">,&nbsp;</span></strong><span style="font-size:13px;">proceder&aacute; a notificar su decisi&oacute;n en el plazo de un mes. Si fuera en sentido afirmativo, el interesado podr&aacute; acceder a la referida informaci&oacute;n en el plazo de los 10 d&iacute;as siguientes a su notificaci&oacute;n.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;"><br> <strong>B</strong>.- El derecho de <strong>rectificaci&oacute;n&nbsp;</strong>y <strong>supresi&oacute;n</strong> podr&aacute;n ser ejercitados, con arreglo a las siguientes previsiones, siempre que el interesado considere que los datos recogidos en nuestros tratamientos son <em>inexactos, incompletos, inadecuados o excesivos.</em> En este caso, podr&aacute; ejercitarlos a trav&eacute;s de alguno de los medios anteriormente previstos.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;"><br>&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">AADIDESS</span></strong><strong><span style="font-size:13px;">,&nbsp;</span></strong><span style="font-size:13px;">proceder&aacute; a la rectificaci&oacute;n o supresi&oacute;n dentro de los 10 d&iacute;as siguientes al de la recepci&oacute;n de la solicitud.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">C</span></strong><span style="font-size:13px;">.- El derecho de <strong>oposici&oacute;n&nbsp;</strong>podr&aacute; ser ejercitado, con arreglo a las siguientes previsiones, siempre que el interesado quiera oponerse al tratamiento de sus datos personales tanto si los datos se tratan l&iacute;citamente por inter&eacute;s leg&iacute;timo o consentimiento relativo a fines publicitarios.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">D</span></strong><span style="font-size:13px;">.- El derecho de&nbsp;<strong>portabilidad</strong> podr&aacute; ser ejercitado, con arreglo a las siguientes previsiones, siempre que el interesado considere que los datos recogidos en nuestros tratamientos tienen que ser devueltos al titular de los datos, o en su caso a un tercero (Encargado de tratamiento).<br>&nbsp;</span>
                    <span style="font-size:13px;"><br> <strong>E</strong>.- El derecho de <strong>limitaci&oacute;n del tratamiento&nbsp;</strong>podr&aacute; ser ejercitado, previamente el derecho de oposici&oacute;n al tratamiento de sus datos, y que mientras no se resuelva el derecho
                        de oposici&oacute;n, se limite el tratamiento de los datos.</span>
                </p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">TRANSFERENCIAS DE DATOS PERSONALES A TERCEROS PA&Iacute;SES</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">En&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;color:windowtext;">nos tomamos muy en serio los datos personales que nos facilita al utilizar nuestros servicios y, nos comprometemos a mantener sus datos personales completamente a salvo.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Ciertos aspectos de nuestras actividades de procesamiento de datos conforme a lo dispuesto en las leyes vigentes, generar&aacute; la transferencia de su informaci&oacute;n personal de un pa&iacute;s o territorio a otro. En cumplimiento de la legislaci&oacute;n vigente, antes de trasferir sus datos personales de su pa&iacute;s o territorio de origen a otro pa&iacute;s o territorio, le solicitaremos su consentimiento previo. Estas transferencias se realizar&aacute;n aportando todas las garant&iacute;as apropiadas y asumidas por el responsable. &nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">CAMBIO DE NORMATIVA</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">AADIDESS</span></strong><strong><span style="font-size:13px;">,&nbsp;</span></strong><span style="font-size:13px;">se reserva el derecho de modificar la presente pol&iacute;tica con el objeto de adaptarla a las novedades legislativas o jurisprudenciales. Tales cambios ser&aacute;n comunicados con la antelaci&oacute;n que sea necesaria en nuestra p&aacute;gina web, sin perjuicio de reclamar el consentimiento necesario de los afectados cuando este no se considere otorgado con arreglo a los t&eacute;rminos de la presente pol&iacute;tica.</span>
                    <span style="font-size:13px;"><br>&nbsp;<br>&nbsp;Cualquier duda, pregunta o comentario que pudiera tener en referencia a la presente normativa, no dude en consultarlo dirigiendo su comunicaci&oacute;n a:&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">info@aadidess.io</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <table style="width: 4.8e+2pt;border: none;margin-left:5.4pt;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td style="width: 49.65pt;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style="font-size:13px;color:white;">1.3</span></strong></p>
                            </td>
                            <td style="width: 431.1pt;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; Pol&iacute;tica de Cookies &nbsp;</span></strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;"><br>&nbsp;</span><span style="font-size:13px;background:white;">El dominio&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">aadidess.cipbyte.io.io</span></strong><span style="font-size:13px;color:windowtext;">&nbsp;u</span>
                    <span style="font-size:13px;background:white;">tiliza procedimientos autom&aacute;ticos de recogida <strong>&ldquo;Cookies&rdquo;</strong> para reunir informaci&oacute;n personal como puede ser el tipo de navegador o sistema operativo, p&aacute;gina de referencia, ruta, dominio
                        ISSP, etc. Todo ello con el fin de mejorar los servicios prestados. Las &ldquo;<strong>Cookies&rdquo;,&nbsp;</strong>nos ayudan a adaptar esta p&aacute;gina web a sus necesidades personales.</span>
                </p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;background:white;">&iquest;Qu&eacute; son las Cookies?</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;background:white;"><br>&nbsp;</span></strong><span style="font-size:13px;background:white;">Una <strong>&ldquo;Cookie&rdquo;</strong> es un peque&ntilde;o archivo que se almacena en el ordenador del usuario y nos permite reconocerle. El conjunto de <strong>&ldquo;Cookies&rdquo;&nbsp;</strong>nos ayuda a mejorar la calidad de nuestra web permiti&eacute;ndonos as&iacute; personalizar hasta cierto punto la navegaci&oacute;n de cada usuario por nuestra web. Las <strong>&ldquo;Cookies&rdquo;&nbsp;</strong>actualmente son esenciales para el funcionamiento de Internet, aportando innumerables ventajas en la prestaci&oacute;n de servicios interactivos, facilit&aacute;ndole la navegaci&oacute;n y usabilidad de nuestra web.</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;background:white;">Tenga en cuenta que las <strong>&ldquo;Cookies&rdquo;&nbsp;</strong>no pueden da&ntilde;ar su equipo y que, a cambio, el que est&eacute;n activadas nos ayudan a identificar y resolver los errores y mejorar la navegabilidad de nuestro sitio web proporcionando una mejor experiencia.</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;background:white;">Se utilizan <strong>Cookies</strong> para:</span></p>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:windowtext;text-align:     justify;'><span style="font-size:13px;background:white;">Asegurar que las p&aacute;ginas web puedan funcionar correctamente.</span></li>
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:windowtext;text-align:     justify;'><span style="font-size:13px;background:white;">Almacenar las preferencias, como el idioma o la medida de la letra.</span></li>
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;background:white;">Conocer la experiencia de navegaciones del usuario.</span></li>
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;background:white;">Recopilar informaci&oacute;n estad&iacute;stica an&oacute;nima, como p&aacute;ginas ha visitado el usuario o cu&aacute;nto tiempo ha estado en el sitio web.<strong><br>&nbsp;</strong></span></li>
                </ul>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">Tipolog&iacute;a, finalidad y funcionamiento</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">Las Cookies en funci&oacute;n de su permanencia, pueden dividirse en cookies de sesi&oacute;n o permanentes. Las primeras expiran cuando el usuario cierra el navegador. Las segundas expiran cuando se cumple el objetivo por el que sirven o bien cuando se borran manualmente.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;"><br> <span style="background:white;">Adem&aacute;s, en funci&oacute;n de su objetivo, los Cookies se pueden clasificar de la siguiente forma:</span></span>
                </p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;background:white;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">Requeridas:&nbsp;</span></strong><span style="font-size:13px;color:windowtext;">son aquellas estrictamente necesarias e imprescindibles para el correcto funcionamiento de la p&aacute;gina web. Normalmente se generan cuando el usuario accede al sitio web o inicia sesi&oacute;n en el mismo y se utiliza para identificar en el sitio web con los siguientes objetivos:</span></li>
                </ul>
                <ul style="list-style-type: undefined;margin-left:80px;">
                    <li><span style="font-size:13px;color:windowtext;">Mantienen al usuario identificado de forma que, si abandona el sitio web, el navegador o el dispositivo y en otro momento vuelve a acceder a la misma p&aacute;gina, seguir&aacute; identificado, facilitando as&iacute; su navegaci&oacute;n sin tener que volver a identificar.</span></li>
                    <li><span style="font-size:13px;background:white;">Comprobar si el usuario este autorizado para acceder a ciertos servicios o zonas del sitio web<br>&nbsp;<br>&nbsp;</span></li>
                </ul>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;background:white;">De preferencia:&nbsp;</span></strong><span style="font-size:13px;background:white;">Permiten registrar en el sitio web toda aquella informaci&oacute;n relacionada con la forma en que se comporta la web o su aspecto, como por ejemplo el idioma o la regi&oacute;n donde se encuentra.</span></li>
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;background:white;">Estad&iacute;sticas / anal&iacute;ticas:&nbsp;</span></strong><span style="font-size:13px;background:white;">S</span><span style="font-size:13px;background:white;">e utilizan para analizar y mejorar la experiencia de navegaci&oacute;n, optimizar el funcionamiento del sitio web, y ver como interact&uacute;an los visitantes, mediante la recopilaci&oacute;n y notificaci&oacute;n de informaci&oacute;n normalmente de forma an&oacute;nima, aunque en ocasiones tambi&eacute;n permiten identificar de manera &uacute;nica e inequ&iacute;voca al usuario para obtener informes sobre los intereses de los usuarios en los servicios que ofrece el sitio web</span>
                        <span style="font-size:13px;color:#212121;background:white;">.</span>
                    </li>
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;background:white;">De m&aacute;rqueting: &nbsp;</span></strong><span style="font-size:13px;background:white;">Son aquellas que recaban informaci&oacute;n sobre los anuncios mostrados a los usuarios del sitio web. Pueden ser de dos tipos:</span></li>
                </ul>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:0cm;margin-right:0cm;margin-left:36.0pt;text-align:justify;'><span style="font-size:13px;background:white;">&nbsp;</span></p>
                <ul style="list-style-type: undefined;margin-left:80px;">
                    <li><strong><span style="font-size:13px;color:windowtext;">An&oacute;nimas:</span></strong><span style="font-size:13px;color:windowtext;">&nbsp;S&oacute;lo recopilan informaci&oacute;n sobre los espacios publicitarios mostrados en el sitio web, con independencia del usuario que accede sin identificarse expresamente.</span></li>
                    <li><strong><span style="font-size:13px;background:white;">Personalizadas:</span></strong><span style="font-size:13px;background:white;">&nbsp;Recopilan informaci&oacute;n personal del usuario del sitio web por parte de un tercero, para la personalizaci&oacute;n de estos espacios publicitarios.</span></li>
                </ul>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:0cm;margin-right:0cm;margin-left:36.0pt;text-align:justify;'><strong><span style="font-size:13px;background:white;">&nbsp;</span></strong></p>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;background:white;">De geolocalizaci&oacute;n:&nbsp;</span></strong><span style="font-size:13px;background:white;">Estas cookies se utilizan para averiguar en qu&eacute; pa&iacute;s o regi&oacute;n se encuentra el usuario que accede a un servicio del sitio web, para poder ofrecer contenidos o servicios adecuados a su localizaci&oacute;n.<br>&nbsp;</span></li>
                </ul>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;background:white;">Privacidad adecuada/ Gesti&oacute;n de cookies:</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;background:white;">Para m&aacute;s informaci&oacute;n sobre la configuraci&oacute;n apropiada de las cookies y las opciones de activaci&oacute;n, restricci&oacute;n y/o inhabilitaci&oacute;n se debe acudir en la secci&oacute;n de ayuda de su navegador para conocer m&aacute;s:</span></p>
                <table style="width:487.35pt;border-collapse:collapse;border:none;">
                    <tbody>
                        <tr>
                            <td style="width: 90.45pt;border: 1pt solid windowtext;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:12px;">Google Chrome</span></strong></p>
                            </td>
                            <td style="width: 14cm;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647" target="_blank"><span style="font-size:12px;color:#3C81A2;">http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</span></a></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 90.45pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:12px;">Internet Explorer</span></strong></p>
                            </td>
                            <td style="width: 14cm;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><a href="http://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"><span style="font-size:12px;color:#3C81A2;">http://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</span></a></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 90.45pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:12px;">Mozilla Firefox</span></strong></p>
                            </td>
                            <td style="width: 14cm;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank"><span style="font-size:12px;color:#3C81A2;">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</span></a></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 90.45pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:12px;">Apple Safari</span></strong></p>
                            </td>
                            <td style="width: 14cm;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><a href="http://support.apple.com/kb/ph5042" target="_blank"><span style="font-size:12px;color:#3C81A2;">http://support.apple.com/kb/ph5042</span></a></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";background:white;'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";background:white;'>El usuario debe conocer que, al deshabilitar las Cookies, algunas de las funcionalidades y servicios pueden quedar deshabilitados o tener un comportamiento diferente al esperado, como, por ejemplo, permanecer identificado, mantener las compras en el carrito, su localizaci&oacute;n, etc. entre otros y que se degrade notablemente su navegaci&oacute;n.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";background:white;'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:windowtext;">Actualizaci&oacute;n de la Pol&iacute;tica de cookies</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";background:white;'>El responsable de tratamiento puede modificar esta Pol&iacute;tica de cookies en funci&oacute;n de exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha pol&iacute;tica a las instrucciones dictadas.</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";background:white;'>&nbsp;</span></p>
                <app-cookie></app-cookie>
                <!-- <script id="CookieDeclaration" src="https://consent.cookiebot.com/cb7c056e-db7f-4526-9dba-4c12ecb7a14a/cd.js" type="text/javascript" async></script> -->
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <table style="width: 4.8e+2pt;border: none;margin-left:5.4pt;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td style="width: 49.65pt;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style="font-size:13px;color:white;">1.4</span></strong></p>
                            </td>
                            <td style="width: 431.1pt;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; Informaci&oacute;n Adicional &nbsp;</span></strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <table style="width: 5.0e+2pt;border-collapse:collapse;border:none;">
                    <tbody>
                        <tr>
                            <td colspan="3" style="width: 496.15pt;border: 1pt solid black;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 3.65pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:  3.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:3.0pt;margin-right:0cm;margin-left:0cm;line-height:115%;'><strong><span style="font-size:12px;line-height:115%;color:maroon;">PROTECCI&Oacute;N DE DATOS PERSONALES   2016/679&nbsp;</span></strong></p>
                                <p style='margin:0cm;margin-bottom:  3.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:3.0pt;margin-right:0cm;margin-left:0cm;line-height:115%;'><strong><span style="font-size:12px;line-height:115%;color:maroon;">Informaci&oacute;n adicional</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 78pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(231, 230, 230);padding: 1.4pt 5.4pt;height: 23.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Responsable<br>&nbsp;</span></strong></p>
                            </td>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 23.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Identidad del responsable de tratamiento&nbsp;</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 23.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;line-height:115%;'><strong><span style="font-size:12px;line-height:115%;">AADIDESS LLC</span></strong></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;line-height:115%;'><span style="font-size:12px;line-height:115%;color:windowtext;">EIN 30-1272497</span><span style="font-size:12px;line-height:115%;"><br>&nbsp;2625 WESTON ROAD - SUITE D - WESTON FL 33 USA</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;line-height:115%;'><span style="font-size:12px;line-height:115%;">info@aadidess.io</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 78pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(231, 230, 230);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Representante UE</span></strong></p>
                            </td>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Identificaci&oacute;n y contacto del representante UE</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: white;padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:12px;line-height:115%;">Mallafr&egrave; Consultors, S.L.U.</span></strong></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">e-mail:&nbsp;</span><span style="font-size:12px;line-height:115%;color:blue;">representant.ue@mallafre-consultors.cat</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="3" style="width: 78pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(231, 230, 230);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Finalidad</span></strong></p>
                            </td>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Descripci&oacute;n del tratamiento</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: white;padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">Atendiendo al <em>&ldquo;principio de limitaci&oacute;n de la finalidad&rdquo;</em> los datos recogidos ser&aacute;n tratados exclusivamente para fines determinados, expl&iacute;citos y leg&iacute;timos y no ser&aacute;n tratados ulteriormente de manera incompatible a dichos fines.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Periodo de conservaci&oacute;n</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: white;padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">Los datos personales proporcionados se conservar&aacute;n mientras se mantenga la relaci&oacute;n mercantil/ comercial siempre y cuando, usted no nos solicite su supresi&oacute;n. Le informamos que, atendiendo a la previsi&oacute;n legal, nos vemos obligados a conservarlos por razones fiscales y contables, y a ponerlos a disposici&oacute;n de una entidad p&uacute;blica competente que as&iacute; lo solicitase.&nbsp;</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Decisiones automatizadas</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: white;padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">Le informamos de la existencia de decisiones automatizadas, incluida la elaboraci&oacute;n de perfiles con el fin de mejorar su experiencia de usuario y facilitarles informaci&oacute;n, servicios o productos adecuados a sus preferencias.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 78pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(231, 230, 230);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Legitimaci&oacute;n</span></strong></p>
                            </td>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Bases jur&iacute;dicas de tratamiento</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: white;padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">Sus datos personales ser&aacute;n tratados, seg&uacute;n se le ha informado en su recogida, atendiendo a las Bases jur&iacute;dicas identificadas en el   2016/679.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="3" style="width: 78pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(231, 230, 230);padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Destinatarios</span></strong></p>
                            </td>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Destinatarios de la cesi&oacute;n</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">Los datos recogidos se comunicar&aacute;n a la empresa SOCIETAT AN&Ograve;NIMA D&rsquo;EQUIPAMENTS TUR&Iacute;STIC &ndash; ESPORTIU DE LA PARR&Ograve;QUIA D&rsquo;ENCAMP (AADIDESS), para fines determinados, atendiendo el estricto cumplimiento de las normativas internas de Protecci&oacute;n de Datos.&nbsp;</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 14.65pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Transferencias internacionales de datos</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 14.65pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">S&iacute; se prev&eacute;n, y estas aportan todas las garant&iacute;as apropiadas asumidas por el responsable o encargado de tratamiento establecidas seg&uacute;n lo previsto en el art.46 del   2016/679.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 14.6pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Encargados de tratamiento</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 14.6pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">Los datos podr&aacute;n ser cedidos a Encargados de tratamiento con acceso a datos, con quienes se formalizan las obligaciones y responsabilidades exigidas por el   2016/679 y que ofrezcan las garant&iacute;as suficientes.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 78pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(231, 230, 230);padding: 1.4pt 5.4pt;height: 5.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Derechos&nbsp;</span></strong></p>
                            </td>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 5.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Ejercicio de derechos</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 5.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">El interesado podr&aacute; ejercitar los derechos que le asisten, de acuerdo con el   2016/679 y que son:</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">&nbsp;</span></p>
                                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                                    <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:26px;">
                                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="line-height:115%;font-size:12px;">Derecho a solicitar el acceso a los datos personales relativos al interesado.&nbsp;</span></li>
                                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="line-height:115%;font-size:12px;">Derecho a solicitar su rectificado o supresi&oacute;n.</span></li>
                                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="line-height:115%;font-size:12px;">Derecho a solicitar la limitaci&oacute;n de su tratamiento.&nbsp;</span></li>
                                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="line-height:115%;font-size:12px;">Derecho a oponerse al tratamiento.&nbsp;</span></li>
                                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="line-height:115%;font-size:12px;">Derecho a la portabilidad de sus datos.</span></li>
                                    </ul>
                                </div>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-left:36.0pt;text-align:justify;line-height:  115%;'><span style="font-size:12px;line-height:115%;">&nbsp;</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">El interesado podr&aacute; ejercer tales derechos presentando un escrito en la direcci&oacute;n del responsable de tratamiento o enviando un comunicado al correo electr&oacute;nico&nbsp;</span>
                                    <span style="font-size:12px;line-height:115%;color:blue;">info@aadidess.io</span><span style="font-size:12px;line-height:115%;color:red;">&nbsp;</span><span style="font-size:12px;line-height:115%;">acompa&ntilde;ado de un documento identificativo. En el caso que el interesado act&uacute;e a trav&eacute;s de un representante legal, este deber&aacute; aportar el documento identificativo y acreditativo de su representaci&oacute;n legal. En el caso de considerar vulnerados sus derechos y quiere conocer m&aacute;s informaci&oacute;n y/o como ejercerlo puede dirigirse a la autoridad de control competente.&nbsp;</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>

            </div>
        </div>
    </div>
</div>
<div *ngIf="this.lenguaje === 'en'">
    <div class="row">
        <div class="col-lg-3 col-md-3 col-xs-3"></div>
        <div class="col-lg-9 col-md-9 col-xs-9">
            <div class="contenedor" style="width:min-content;justify-content: center;display:inline-block;margin-left:10px;margin-top:10px;">
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";background:white;'>&nbsp;</span></p>
                <table style="border: none;margin-left:5.4pt;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td colspan="2" style="width: 469.95pt;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; &nbsp; &nbsp;Data protection &nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 469.95pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:#990000;background:white;'>&nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 405.7pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><em><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>Legal Texts applicable legislation state of Florida - GDPR 2016/679&nbsp;</span></strong></em></p>
                            </td>
                            <td style="width: 64.25pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><em><strong><span style='font-size:13px;font-family:"Arial","sans-serif";'>Ver. 21.3</span></strong></em></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 469.95pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><em><span style='font-size:13px;font-family:  "Helvetica","sans-serif";'>&nbsp;</span></em></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong></p>
                <table style="border: none;margin-left:5.4pt;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td style="width: 35.3pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>1.1</span></strong></p>
                            </td>
                            <td style="width: 434.65pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:#990000;background:white;'>&nbsp; &nbsp; &nbsp;Legal Notice &nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 469.95pt;padding: 0cm 5.4pt;height: 4.05pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:#990000;background:white;'>&nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 35.3pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>1.2</span></strong></p>
                            </td>
                            <td style="width: 434.65pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:#990000;background:white;'>&nbsp; &nbsp; &nbsp;Privacy Policy &nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="width: 469.95pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><em><span style='font-size:13px;font-family:  "Helvetica","sans-serif";'>&nbsp;</span></em></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 35.3pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>1.3</span></strong></p>
                            </td>
                            <td style="width: 434.65pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:#990000;background:white;'>&nbsp; &nbsp; &nbsp;Cookies Policy &nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 35.3pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;</span></strong></p>
                            </td>
                            <td style="width: 434.65pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:#990000;background:white;'>&nbsp;</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 35.3pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style="font-size:13px;color:windowtext;">1.4</span></strong></p>
                            </td>
                            <td style="width: 434.65pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; Additional Information</span></strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;</span></p>
                <table style="border: none;margin-left:5.4pt;background:#943841;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td style="width: 35.45pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:white;'>1.1</span></strong></p>
                            </td>
                            <td style="width: 445.3pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:#990000;background:white;'>&nbsp; &nbsp; &nbsp;Legal Notice &nbsp;</span></strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;IDENTIFYING DATA OF THE INFORMATION SOCIETY SERVICES LENDER</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>In accordance with the processing of personal data and the protection of privacy in the sector of electronic communications, we put your knowledge to the following information:</span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>AADIDESS LLC, [</span></strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>hereafter<strong>&nbsp;AADIDESS],&nbsp;</strong>is the owner of the web page&nbsp;</span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>aadidess.cipbyte.io.io</span></strong>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";'>,</span><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;and will act exclusively as the manager of the contents of the present Web, addressed at:&nbsp;</span>
                    <span style="font-size:13px;color:windowtext;">2625 WESTON ROAD - SUITE D - WESTON FL 33 USA</span>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>,&nbsp;</span><span style="font-size:13px;color:windowtext;">EIN 30-1272497</span><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>,&nbsp;</span>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";'>is responsible for the management and functioning of the Web previously mentioned.</span>
                </p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>If you would like to contact us, you can do so by post at the previously mentioned address, or by</span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;</span></strong>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>e-mail at:<strong>&nbsp;</strong></span><strong><span style="font-size:13px;color:windowtext;">info@aadidess.io</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>INTRODUCTION</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>This Legal Notice regulates the use of this web page. Access on the web is free except for the cost of the connection through the telecommunications network provided by the access provider hired by users.<br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>ACCEPTANCE<br>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>If you use the web, you are considered a user, and this implies full acceptance without any reservation of every and each point in this Legal Notice, published by <strong>AADIDESS,</strong> from the moment the user accesses the web. In consequence, the user must read carefully the present Legal Notice every time he or she wishes to use the web, as these may be modified.</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>INFORMATION ABOUT LINKS</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;AADIDESS&nbsp;</span></strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>is not responsible for websites that are not its own or those of third parties, which can be accessed through links or any content made available by third parties.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>Any use of links or access to a web not owned by <strong>AADIDESS&nbsp;</strong> is at the users responsibility and <strong>AADIDESS&nbsp;</strong> does not recommend nor guarantee any information obtained by a link through a third party which comes from a web that is not from&nbsp;</span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>aadidess.cipbyte.io.io</span></strong>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";'>, neither is it responsible for any loss, claims or damages derived from the use or bad use of a link or the information obtained through it, including other links or webs, interruption of service or in the access, or trying to use
                        or the incorrect use of a link, even if the connection is via the Web&nbsp;</span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>aadidess.cipbyte.io.io</span></strong>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;or by accessing the information by other webs from the same Web page.</span>
                </p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'><br>&nbsp;</span></p>
                <p><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style="font-size:13px;">LIMITATION OF WARRANTIES AND RESPONSIBILITIES</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;">makes every effort to avoid any errors in relation to the contents of this website.&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;">adopts the necessary measures and mechanisms to guarantee the security of communications and transactions that occur through the web portal, according to the state of technology and in accordance with the type of transactions and services that are provided. available to users.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;">will not be responsible for any damage that may be caused to the user&rsquo;s computer system by any cause beyond or not attributable to this website, including, without limitation, damages caused by interference, omissions, interruptions, viruses&rsquo; computer, telephone breakdowns or disconnections in the operational functioning of the electronic system. Likewise,&nbsp;</span><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong>
                    <span style="font-size:13px;">will not be liable for any interruption, error or failure that occurs in the system, due to a malfunction of the network or the servers that are connected.</span><span style="font-size:13px;"><br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>INDUSTRIAL AND INTELLECTUAL PROPERTY, TRADEMARKS</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;background:white;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>The structure, design and way of presenting the elements (graphs, images, files, logotypes, color combinations and any element susceptible to protection) are protected by intellectual property laws, owned by <strong>AADIDESS.</strong></span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>It is prohibited to reproduce, transform, distribute, communicate in public, make publicly available and in generally exploit in any form partially or totally the elements referred to in the previous section. These acts of exploitation can only be carried out if authorized by <strong>AADIDESS</strong>, and if this were to be the case, there must be explicit reference to the fact that&nbsp;<strong>AADIDESS&nbsp;</strong>is the intellectual owner of the material.<br>&nbsp;<br> Only documental material produced by <strong>AADIDESS&nbsp;</strong>is authorized for private use, and in no case, can the material be deleted, changed, eluded or any of the security systems installed be manipulated.</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>It is forbidden to link to the final pages, the frame and any other similar manipulation. The links must always be to the principal page or homepage <strong>aadidess.cipbyte.io.io</strong></span>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;<br> Distinctive signs (brands, commercial names) belonging to&nbsp;<strong>AADIDESS</strong>, are protected by industrial property rights and the use or manipulation of them is forbidden except if there is written authorization
                        by&nbsp;<strong>AADIDESS.</strong></span>
                </p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>SECURE ON-LINE SHOPPING</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>Security is a priority and therefore we make the maximum effort to assure that our process in terms of transactions is secure and in order to keep your personal information safe.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>DO NOT SHARE YOUR PERSONAL INFORMATION</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>AADIDESS&nbsp;</span></strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>will never send you an e-mail and neither call you by telephone to ask you for your password or to verify your password belonging to your account and likewise will never ask for your bank account number or credit card and neither any other personal information. If anybody should contact you or send you and e-mail that you have not asked for, you should never give them any of your personal data previously mentioned. Also, you should never respond and communicate to the appropriate authorities in order to proceed with investigating the incident.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>IDENTIFY FALSE E-MAILS (&ldquo;SPOOFING OR PHISHING)&rdquo;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>Ignore all e-mails in which they demand personal information or ones which redirect you to another web page that does not belong to or any societies belonging to the group or in which they ask you to pay via a means which has not being authorized by <strong>AADIDESS</strong>, as this could be an attempt at identity theft (&ldquo;spoofing or phishing&rdquo;) and must be considered as fraudulent.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>AADIDESS&nbsp;</span></strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>uses the <strong>&ldquo;</strong></span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>aadidess.cipbyte.io.io</span></strong><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&rdquo;</span></strong>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;domain for all its e-mails. If you receive an e-mail in a different format, for example:&nbsp;</span><span style="font-size:13px;">aadidess.cipbyte.io</span>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";'>.</span>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";'>security@hotmail.com</span><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>, you can be assured that it is a false mail.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>Some e-mails that use phishing contain links to a web page that uses the word <strong>AADIDESS&nbsp;</strong>at their URL, but they will direct you to a web page that is completely different. If you drag your mouse over the link, you will be able to see the associated URL, which will probably have a different format to the correct web pages authorized by&nbsp;</span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>aadidess.cipbyte.io.io</span></strong><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>.</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>Even if you click an e-mail that uses phishing and are redirected to a page that resembles &ldquo;your account&rdquo; or to any page that asks you to verify or modify your personal information, ignore it and consider it as fraudulent.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;INFORM ANY ATTEMPTS AT PHISHING</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>Send an e-mail to <strong>info@aadidess.io</strong> and attach any e-mail that you consider to be false. By attaching these e-mails, you will be helping to locate their origin.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>If you cannot attach the false mail, forward it to <strong>info@aadidess.io</strong> and include as much information as possible about it.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;</span></p>
                <p><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;</span></p>
                <table style="width: 4.8e+2pt;border: none;margin-left:5.4pt;background:#943841;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td style="width: 42.55pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:white;'>1.2</span></strong></p>
                            </td>
                            <td style="width: 438.2pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:#990000;background:white;'>&nbsp; &nbsp; &nbsp;Privacy Policy &nbsp;</span></strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'><br>&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">RESPONSIBLE</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>In accordance with the applicable legislation and what is established by&nbsp;</span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>General Regulation (UE) 2016/679 on data protection</span></strong>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>,</span><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;we inform you that personal data given via the forms, and likewise your e-mail address have been included in our file titled <strong>AADIDESS LLC [</strong>hereafter, <strong>AADIDESS],&nbsp;</strong>at the business&nbsp;address of:&nbsp;</span>
                    <span style="font-size:13px;color:windowtext;">Sede Central Morales 483 PB (8400) San Carlos de Bariloche</span><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>, company incorporated with the applicable provisions, is responsible for the management and functioning of the Web previously mentioned.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'><br>&nbsp;</span><span style="font-size:13px;">We inform you that the data could be used to send you commercial communications, in any format, and this could be related to any other services offered by our business which might be of interest to you. If you should at any time oppose this type of communications, please send us an e-mail address to&nbsp;</span><strong><span style="font-size:13px;color:windowtext;">info@aadidess.io</span></strong>
                    <span style="font-size:13px;color:windowtext;">&nbsp;</span><span style="font-size:13px;">indicating the subject as <strong>&ldquo;Unsubscribe from the distribution list&rdquo;.</strong></span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Likewise, you can exercise the right to <strong>a</strong>ccess, <strong>r</strong>ectify, <strong>s</strong>uppression, <strong>o</strong>pposition, <strong>p</strong>ortability and <strong>l</strong>imitation by presenting or sending an application in writing to the email address:</span><strong><span style="font-size:13px;color:windowtext;">&nbsp;info@aadidess.io</span></strong>
                    <span style="font-size:13px;color:windowtext;">&nbsp;</span><span style="font-size:13px;">and with a photocopy of your ID or similar documentation indicating the subject as <strong>&ldquo;Data protection&rdquo;.</strong></span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;</span></strong>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'><br>&nbsp;<br>&nbsp;</span>
                </p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>POLICY&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;AADIDESS&nbsp;</span></strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>is especially sensitive to protecting the personal data of our users which has been obtained through the services offered on our Web. The present privacy policy informs all the users of&nbsp;</span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>aadidess.cipbyte.io.io</span></strong>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";'>,&nbsp;</span><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>how the personal data collected from the through the <strong>additional information</strong>, in order that they decide, freely and voluntarily, if they wish to provide the requested information</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>SECURITY MEASURES</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>Finally it is reported that&nbsp;</span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>AADIDESS&nbsp;</span></strong><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>,&nbsp;</span></strong>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";'>has adopted in the information system the appropriate technical and organizational measures, in order to guarantee the security and confidentiality of the stored data, thus avoiding its alteration, loss, treatment or unauthorized access;
                        taking into account the state of the art, the costs of application, and the nature, scope, context and purposes of the treatment, as well as risks of probability and variable severity associated with each of the treatments.</span>
                </p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;RIGHTS OF THE USERS</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;The interested party of the personal data may exercise the rights that assist him, in accordance with the General Data Protection Regulation, and which are:</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br> <strong>A</strong>. - The right to <strong>access</strong> is to be exercised annually, except if the party interested shows legitimate accreditation.&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>AADIDESS&nbsp;</span></strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>will proceed to notify its decisions within a timeframe of a month. If it were to be accepted, the interested party would be able to access the previously mentioned information within 10 days after its notification.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br> <strong>B</strong>. - The right of<strong>&nbsp;rectification</strong> and <strong>suppression&nbsp;</strong>can be exercised, conforming to the following previsions, whenever the interested party considers that the data collected in our files is <em>inexact</em>, <em>incomplete</em>, <em>inadequate</em> or&nbsp;<em>excessive</em>. If this is the case, you can exercise these rights via one of the means previously anticipated.<br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>AADIDESS&nbsp;</span></strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>will proceed with the rectification or suppression within 10 days of receiving the application.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>C</span></strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>. -&nbsp;</span><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>The right of <strong>opposition</strong> may be exercised, in accordance with the following provisions, provided that the interested party wishes to oppose the processing of their personal data, whether or not the data is lawfully treated as being of legitimate interest or relative consent for advertising purposes.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br>&nbsp;D</span></strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>. - The right of<strong>&nbsp;portability</strong> they may be exercised, in accordance with the following provisions, provided that the interested party considers that the data collected in our treatments must be returned to the data holder or to another third party (Treatment Manager).<br>&nbsp;</span>
                    <span style='font-size:13px;font-family:"Helvetica","sans-serif";'><br> <strong>E</strong>. - The right of <strong>limitation of treatment</strong> may be exercised, prior to opposition right for the treatment of their data, and that until the Opposition Law is not resolved, the treatment of them
                        will be limited.</span>
                </p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>TRANSFERS OF PERSONAL DATA TO A THIRD COUNTRIES</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>At LEVEL&nbsp;</span></strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>7 we take the personal data you provide us when using our services very seriously and we are committed to keeping your personal data completely safe.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>Certain aspects of our data processing activities under applicable law will result in the transfer of your personal information from one country or territory to another. In compliance with current legislation, before transferring your personal data from your country or territory of origin to another country or territory, we will request your prior consent. These transfers will be made providing all the appropriate guarantees assumed by the person in charge.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>REGULATION CHANGES</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>AADIDESS,&nbsp;</span></strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>reserves</span><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;the right to modify the present policy with the objective of adapting it to the legislative or precedents changes. Such changes will be communicated within the necessary time on our Web page, and it can ask the affected parties for their consent if it is believed that it does not have the consent according to the present policy.<br>&nbsp;<br>&nbsp;If you should have any doubt, question or comments referring to the present regulations, please do not hesitate to ask about them by sending a communication to: &nbsp;</span><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'>info@aadidess.io</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:black;'><br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <table style="border: none;margin-left:5.4pt;background:#943841;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td style="width: 35.45pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:white;'>1.3</span></strong></p>
                            </td>
                            <td style="width: 445.3pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:#990000;background:white;'>&nbsp; &nbsp; &nbsp;Cookie Policy &nbsp;</span></strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:windowtext;'><br>&nbsp;</span><span style="font-size:13px;color:windowtext;background:white;">The domain&nbsp;</span><strong><span style="font-size:13px;color:windowtext;background:white;">aadidess.cipbyte.io.io</span></strong>
                    <span style="font-size:13px;color:windowtext;background:white;">&nbsp;uses automatic &ldquo;</span><strong><span style="font-size:13px;color:windowtext;background:white;">Cookies</span></strong><span style="font-size:13px;color:windowtext;background:white;">&rdquo; collection procedures to gather personal information such as the type of browser or operating system, reference page, route, ISSP domain, etc. All this in order to improve the services provided. &quot;</span><strong><span style="font-size:13px;color:windowtext;background:white;">Cookies</span></strong>
                    <span style="font-size:13px;color:windowtext;background:white;">&quot; help us adapt this website to your personal needs.</span>
                </p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;background:white;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;background:white;">What are cookies?</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;background:white;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;background:white;">A &quot;<strong>Cookie</strong>&quot; is a small file that is stored on the user&apos;s computer and allows us to recognize him. The set of &quot;<strong>Cookies</strong>&quot; helps us improve the quality of our website, thus allowing us to personalize to a certain extent the navigation of each user on our website. &quot;<strong>Cookies</strong>&quot; are currently essential for the operation of the Internet, providing innumerable advantages in the provision of interactive services, facilitating the navigation and usability of our website.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;background:white;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;background:white;">Please note that &quot;<strong>Cookies</strong>&quot; cannot harm your equipment and, in return, that they are activated help us to identify and resolve errors and improve the navigability of our website by providing a better experience.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">Cookies</span></strong><span style="font-size:13px;color:windowtext;">&nbsp;are used to:</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:windowtext;text-align:justify;'><span style="font-size:13px;">Make sure that web pages work properly.</span></li>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:windowtext;text-align:justify;'><span style="font-size:13px;">Store your preferences, like language or font size.</span></li>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:windowtext;text-align:justify;'><span style="font-size:13px;">Find out about the user&rsquo;s browsing experience.</span></li>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:windowtext;text-align:justify;'><span style="font-size:13px;">Compile anonymous statistical information, like which pages the user has visited or how long they have spent on the website.</span></li>
                </ul>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">Type, purpose and operation</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">Depending on their permanence, Cookies can be divided into two categories: session cookies and persistent cookies. The former expires when the user closes the browser. Whereas the latter expire when their aim is achieved or when they are deleted manually.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">In addition, depending on their aim, Cookies can be classified as follows:</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">Required:&nbsp;</span></strong><span style="font-size:13px;color:windowtext;">those that are strictly necessary and essential for the correct functioning of the Website. They are normally generated once the user visits the website or logs in and are used as a form of identification on the website with the following objectives:</span></li>
                </ul>
                <ul style="list-style-type: undefined;margin-left:80px;">
                    <li><span style="font-size:13px;color:windowtext;color:windowtext;">They keep the user identified in such a way that, if they leave the website, exit the browser or device and then visit the page at a later time, they continued to be identified, which makes browsing easier as they do not have to identify themselves over again.</span></li>
                    <li><span style="font-size:13px;background:white;">They confirm whether or not the User is authorized to access certain services or areas of the Website.<br>&nbsp;<br>&nbsp;</span></li>
                </ul>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;background:white;">Preferred:&nbsp;</span></strong><span style="font-size:13px;background:white;">These enable the Website to record information related to the way that it behaves and its appearance - such as the language or the region where it is located.</span></li>
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;background:white;">Statistical/analytical:&nbsp;</span></strong><span style="font-size:13px;background:white;">These are used to analyses and improve the browsing experience, optimize the functioning of the website, and see how visitors interact, by collecting and reporting information - usually anonymously, but sometimes also uniquely and unmistakably identifying the user - in order to obtain reports on the interests of users in the services offered by the website</span>
                        <span style="font-size:13px;color:#212121;background:white;">.</span>
                    </li>
                    <li style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;background:white;">Marketing: &nbsp; &nbsp; &nbsp; &nbsp;</span></strong><span style="font-size:13px;background:     white;">These cookies collect information about adverts shown to website users. There are two types:</span></li>
                </ul>
                <ul style="list-style-type: undefined;margin-left:80px;">
                    <li><strong><span style="font-size:13px;color:windowtext;">Anonymous:</span></strong><span style="font-size:13px;color:windowtext;color:windowtext;">&nbsp;These collect information only about the advertising spaces shown on the Website, regardless of the user who browses without not expressly identifying him or herself.</span></li>
                    <li><strong><span style="font-size:13px;background:white;background:white;">Custom:</span></strong><span style="font-size:;background:white;">&nbsp;They collect the user&rsquo;s personal information from the website on behalf of a third party, in order to customize ad spaces.</span></li>
                </ul>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:0cm;margin-right:0cm;margin-left:36.0pt;text-align:justify;'><strong><span style="font-size:13px;background:white;">&nbsp;</span></strong></p>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:windowtext;text-align:justify;'><strong><span style="font-size:13px;color:black;background:white;">Geolocation:&nbsp;</span></strong><span style="font-size:13px;color:black;background:white;">These cookies are used to determine the country of the user when they visit a service on a website, in order to offer content and services suitable to their location.</span></li>
                </ul>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:windowtext;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:windowtext;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:windowtext;">Adequate privacy / Cookie management</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">For more information about the appropriate configuration of cookies and the activation, restriction and / or disabling options, you should go to the help section of your browser to learn more:</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <table style="width:487.35pt;border-collapse:collapse;border:none;">
                    <tbody>
                        <tr>
                            <td style="width: 90.45pt;border: 1pt solid windowtext;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:12px;">Google Chrome</span></strong></p>
                            </td>
                            <td style="width: 14cm;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647" target="_blank"><span style="font-size:12px;color:#3C81A2;">http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</span></a></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 90.45pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:12px;">Internet Explorer</span></strong></p>
                            </td>
                            <td style="width: 14cm;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><a href="http://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"><span style="font-size:12px;color:#3C81A2;text-decoration:none;">http://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</span></a></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 90.45pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:12px;">Mozilla Firefox</span></strong></p>
                            </td>
                            <td style="width: 14cm;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank"><span style="font-size:12px;color:#3C81A2;">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</span></a></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 90.45pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:12px;">Apple Safari</span></strong></p>
                            </td>
                            <td style="width: 14cm;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><a href="http://support.apple.com/kb/ph5042" target="_blank"><span style="font-size:12px;color:#3C81A2;">http://support.apple.com/kb/ph5042</span></a></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;background:white;"><br>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;background:white;">The user must know that, when disabling Cookies, some of the features and services may be disabled or have a different behaviour than expected, such as, for example, staying identified, keeping purchases in the cart, its location, etc. among others and that its navigation is notably degraded.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">Updating of the Cookies Policy</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">The data controller may modify this Cookies Policy based on legislative or regulatory requirements, or in order to adapt said pol</span><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>icy to the instructions issued.</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p><span style='font-size:13px;font-family:"Helvetica","sans-serif";color:black;'><br>&nbsp;</span></p>
                <app-cookie></app-cookie>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-indent:36.0pt;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <table style="width: 4.8e+2pt;border: none;margin-left:5.4pt;background:#943841;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td style="width: 42.55pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:center;'><strong><span style="font-size:13px;color:white;">1.4</span></strong></p>
                            </td>
                            <td style="width: 438.2pt;padding: 0cm 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; Additional Information&nbsp;</span></strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-indent:36.0pt;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
                <table style="width: 5.0e+2pt;border-collapse:collapse;border:none;">
                    <tbody>
                        <tr>
                            <td colspan="3" style="width: 496.15pt;border: 1pt solid black;background: rgb(217, 217, 217);padding: 1.4pt 5.4pt;height: 3.65pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:  3.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:3.0pt;margin-right:0cm;margin-left:0cm;line-height:115%;'><strong><span style="font-size:12px;line-height:115%;color:maroon;">PROTECTION OF PERSONAL DATA GDPR (UE) 2016/679&nbsp;</span></strong></p>
                                <p style='margin:0cm;margin-bottom:  3.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:3.0pt;margin-right:0cm;margin-left:0cm;line-height:115%;'><strong><span style="font-size:12px;line-height:115%;color:maroon;">Additional Information</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 78pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(231, 230, 230);padding: 1.4pt 5.4pt;height: 23.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Responsible</span></strong></p>
                            </td>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 23.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Identification of the Person in charge of Treatment.</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 23.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;line-height:115%;'><strong><span style="font-size:12px;line-height:115%;">AADIDESS LLC</span></strong></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;line-height:115%;'><span style="font-size:12px;line-height:115%;color:windowtext;">EIN 30-1272497</span><span style="font-size:12px;line-height:115%;"><br>&nbsp;2625 WESTON ROAD - SUITE D - WESTON FL 33 USA</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;line-height:115%;'><span style="font-size:12px;line-height:115%;">info@aadidess.io</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 78pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(231, 230, 230);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">UE Representative</span></strong></p>
                            </td>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Identification and contact of the UE Representative</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: white;padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:12px;line-height:115%;">Mallafr&egrave; Consultors, SL.U.</span></strong></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">e-mail:&nbsp;</span><span style="font-size:12px;line-height:115%;color:blue;">representant.ue@mallafre-consultors.cat</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="3" style="width: 78pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(231, 230, 230);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Purposes&nbsp;</span></strong></p>
                            </td>
                            <td style="width: 77.95pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Description of the treatment</span></strong></p>
                            </td>
                            <td style="width: 347.3pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: white;padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">In accordance with the &ldquo;<em>principle of limitation of purpose</em>&rdquo;, the data collected will be processed exclusively for specific, explicit and legitimate purposes and will not be further processed in a manner incompatible with said purposes.</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">We inform you of the existence of automated decisions, including the creation of profiles in order to improve your user experience and provide you with information, services or products appropriate to your preferences.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 77.95pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Conservation time</span></strong></p>
                            </td>
                            <td style="width: 347.3pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: white;padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">The personal data provided will be kept as long as the commercial / commercial relationship is maintained as long as you do not request its deletion. We inform you that in accordance with the legal provision, we are obliged to keep them for tax and accounting reasons, and to make them available to a competent public entity that so requests.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 77.95pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Automated decisions</span></strong></p>
                            </td>
                            <td style="width: 347.3pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: white;padding: 1.4pt 5.4pt;height: 21.75pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">The report on the existence of automated decisions, including the elaboration of profiles in order to enhance the user&apos;s experience and provide information, services or products adapted to the specific preferences.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 78pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(231, 230, 230);padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Legitimation</span></strong></p>
                            </td>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Legal basis of treatment</span></strong></p>
                            </td>
                            <td style="width: 326pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Courier New";text-align:justify;background:white;'><span style='font-size:12px;font-family:"Arial","sans-serif";color:#212121;'>The legal bases for the treatment of your data are legitimized in the collection of the same, stating if it is a legal, contractual, public interest, legitimate interest or your explicit consent requirement.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="3" style="width: 78pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(231, 230, 230);padding: 1.4pt 5.4pt;height: 2.1pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Recipients</span></strong></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:12px;">&nbsp;</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:12px;">&nbsp;</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:12px;">&nbsp;</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:12px;">&nbsp;</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:12px;">&nbsp;</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:12px;">&nbsp;</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:12px;">&nbsp;</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:12px;">&nbsp;</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:12px;">&nbsp;</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:12px;">&nbsp;</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="font-size:12px;">&nbsp;</span></p>
                            </td>
                            <td style="width: 77.95pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 2.1pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Recipients of the assignment</span></strong></p>
                            </td>
                            <td style="width: 347.3pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 2.1pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;color:#212121;">The data collected will be communicated to the company&nbsp;</span><span style="font-size:12px;line-height:115%;">SOCIETAT AN&Ograve;NIMA D&rsquo;EQUIPAMENTS TUR&Iacute;STIC &ndash; ESPORTIU DE LA PARR&Ograve;QUIA D&rsquo;ENCAMP (AADIDESS)</span>
                                    <span style="font-size:12px;line-height:115%;color:#212121;">, for certain purposes, in accordance with strict compliance with internal Data Protection regulations.</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 77.95pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 14.65pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">International data transfers&nbsp;</span></strong></p>
                            </td>
                            <td style="width: 347.3pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 14.65pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">Yes, they occur, and they provide all the appropriate guarantees assumed by the data controller or processor established in one of more third countries as provided by the art. 46 of the GDPR (UE) 2016/679.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 77.95pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 40.2pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Treatment processors</span></strong></p>
                            </td>
                            <td style="width: 347.3pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 40.2pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">The data may be transferred to Data Processors with access to data, with whom the obligations and responsibilities required by the GDPR (UE) 2016/679 are formalized and who offer sufficient guarantees.</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 78pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(231, 230, 230);padding: 1.4pt 5.4pt;height: 2.1pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Rights&nbsp;</span></strong></p>
                            </td>
                            <td style="width: 77.95pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(242, 242, 242);padding: 1.4pt 5.4pt;height: 2.1pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:12px;">Exercise of rights</span></strong></p>
                            </td>
                            <td style="width: 347.3pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 1.4pt 5.4pt;height: 2.1pt;vertical-align: top;">
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">The interested party may exercise the rights that assist him, in accordance with the   2016/679 and which are:</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">&nbsp;</span></p>
                                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                                    <ul style="margin-bottom:0cm;list-style-type: disc;margin-left:26px;">
                                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="line-height:115%;font-size:12px;">Right to request access to personal data regarding the interested party.</span></li>
                                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="line-height:115%;font-size:12px;">Right to request its rectification or deletion.</span></li>
                                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="line-height:115%;font-size:12px;">Right to request the limitation of your treatment.</span></li>
                                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="line-height:115%;font-size:12px;">Right to object to the treatment.</span></li>
                                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><span style="line-height:115%;font-size:12px;">Right to the portability of your data.</span></li>
                                    </ul>
                                </div>
                                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:36.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:12px;line-height:115%;">&nbsp;</span></p>
                                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:12px;">The interested party may exercise these rights by submitting a letter to the address of the data controller or by sending a statement to the email&nbsp;</span><span style="font-size:12px;color:blue;">info@aadidess.io</span>
                                    <span style="font-size:12px;">&nbsp;accompanied by an identification document. In the event that the interested party acts through a legal representative, he must provide the identification document and the proof of his legal representation. In
                                        the case of considering your right to the protection of violated personal data and want to know more information about this right and how to exercise it, you can contact to the competent control authority.</span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-indent:36.0pt;'><span style='font-size:13px;font-family:"Helvetica","sans-serif";'>&nbsp;</span></p>
            </div>
        </div>
    </div>
</div>