import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { Gesto } from 'src/app/interfaces/interface-bakend/cursos/gesto.interface';
import { GestosService } from 'src/app/services/cursos/gestos.service';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { Disciplina } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';

@Component({
  selector: 'app-gesto-modificacion',
  templateUrl: './gesto-modificacion.component.html',
  styleUrls: ['./gesto-modificacion.component.scss']
})
export class GestoModificacionComponent implements OnInit {

  flagFormaValido = false;
  form: FormGroup;
  gesto: Gesto;
  disciplinas: Disciplina[];
  disciplina: any;
  selectedNivel: number;
  niveles: number[] = [1, 2, 3, 4, 5, 6, 7];

  constructor(
    private fb: FormBuilder,
    private gestosService: GestosService,
    private cursoService: CursosService,
    private guiMsjService: GuiMsjService,
    private router: Router) {
    this.getEstadoVariables();
    this.createForm();
  }

  ngOnInit(): void {
  }

  async getEstadoVariables() {
    if (this.getGesto() === undefined) {
      this.router.navigateByUrl('/gestos-listado');
      return;
    } else {
      this.getGesto();
      this.getDisciplinas();
      this.disciplina = this.gesto?.disciplina;
      this.selectedNivel = this.gesto?.nivel;
    }
  }

  getGesto() {
    return this.gesto = this.gestosService.getGestoSeleccionado();
  }

  async createForm() {
    this.form = this.fb.group({
      nombre: new FormControl(this.gesto?.nombre, Validators.required),
      mesesVigenciaAprobacion: new FormControl(this.gesto?.mesesVigenciaAprobacion, Validators.required),
      valorAprobacion: new FormControl(this.gesto?.valorAprobacion, Validators.required),
      activo: new FormControl(this.gesto?.activo),
      nivel: new FormControl(this.gesto?.nivel, Validators.required),
      disciplina: new FormControl('', Validators.required)
    });
  }

  async setDataForma(): Promise<Gesto> {
    const data: Gesto = {
      nombre: this.form.value.nombre,
      mesesVigenciaAprobacion: this.form.value.mesesVigenciaAprobacion,
      activo: this.form.value.activo,
      valorAprobacion: this.form.value.valorAprobacion,
      nivel: this.form.value.nivel,
      disciplina: this.form.value.disciplina
    };
    return data;
  }

  async getDisciplinas() {
    await this.cursoService.getDisciplinas().then((disciplinas: Disciplina[]) => {
      this.disciplinas = disciplinas;
    });
  }

  async updateGesto() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    await this.gestosService.updateGesto(this.gesto?._id, await this.setDataForma()).then(async (gesto: Gesto) => {
      this.router.navigate(['/gestos-listado']);
      return;
    });
  }
}
