import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { UserList } from '../../../../interfaces/interface-bakend/seguridad/users/personas.interface';
import { PersonasService } from '../../../../services/shared/personas.service';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { UsuarioModificacionComponent } from '../usuario-modificacion/usuario-modificacion.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2'
import { AlumnoTitulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { PersonaModificacionComponent } from '../persona-modificacion/persona-modificacion.component';
import { GestosEvaluacionesUsuariosListadoComponent } from 'src/app/components/cursos/pages/gestos-evaluaciones-usuarios-listado/gestos-evaluaciones-usuarios-listado.component';
import { AlumnoBoletinesModalComponent } from 'src/app/components/cursos/pages/alumno-boletines-modal/alumno-boletines-modal.component';

@Component({
  selector: 'app-personas-listado',
  templateUrl: './personas-listado.component.html',
  styleUrls: ['./personas-listado.component.scss']
})
export class PersonasListadoComponent implements OnInit, AfterViewInit {

    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] =
    [
       'nombre', 'apellido', 'identificacion' , 'email', 'telefono', '_id'
    ];
    dataSource: any;
    personas: UserList [];

    /* Fin Data Table*/
    usuarioLoad = false;
    flagLoaded = true;
    isButtonDisabled: boolean;
    searchTerm: string = '';

    constructor(
        private personasService: PersonasService,
        private router: Router,
        private perfilesService: PerfilesService,
        private guiMsjService: GuiMsjService,
        private usuariosService: UsuarioService,
        private cursosService: CursosService,
        public dialog: MatDialog,

    ) { }

    ngOnInit(): void {
        this.perfilesService.allowedByUserLogon(this.router.url);
    }

    async ngAfterViewInit() {    }

    async buscarPersona() {
        this.flagLoaded = true;
        const data = {
            searchTerm: this.searchTerm
        };

        await this.personasService.getAllPersonasByTerns(data).then((personas: User[]) => {
            this.dataSource = new MatTableDataSource(personas);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.flagLoaded = true;
        });
    }

    async verPuntajesCargados(_id: string) {
   
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        
        await this.usuariosService.getFindOne(_id);

          const dialogRef = this.dialog.open(GestosEvaluacionesUsuariosListadoComponent, {
            width: '1300px',
            height: '900px',
          });
      
          Swal.close();

          dialogRef.afterClosed().subscribe(resp => {
          });
              
      }


    async cargarPersona() {
        
        await this.personasService.setPersonaSeleccionada( null );

        await this.guiMsjService.msjFormSubmit('Espere');

        Swal.showLoading();

        const dialogRef = this.dialog.open(PersonaModificacionComponent, {
            width: '1200px',
            height: '900px',
        });
      
        Swal.close()
        
        dialogRef.afterClosed().subscribe(resp => {
            location.reload();
        });
    }

    async loadDataTable(){

        await this.personasService.getAllPersonas().then( (personas: User[]) => {
            this.dataSource = new MatTableDataSource(personas);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });

    }

    openDialogVerBoletines(id: string) {
        this.isButtonDisabled = true;
        const dialogRef = this.dialog.open(AlumnoBoletinesModalComponent, {
            width: '1200px',
            height: '600px',
            data: { id }
        });

        dialogRef.afterClosed().subscribe(() => {
            this.isButtonDisabled = false;
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    async cargarTitulo(_id) {

        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();

        await this.personasService.setPersonaSeleccionada( await this.personasService.getById(_id));
       
        const dialogRef = this.dialog.open(PersonaModificacionComponent, {
        width: '1200px',
        height: '900px',
        });

        Swal.close();

        dialogRef.afterClosed().subscribe(resp => {
        });
        
    
      }

}