import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { OfertasSolicitudComponent } from '../ofertas-solicitud/ofertas-solicitud.component';
import { Solicitud, SolicitudList } from '../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../../../../app.config/app.config-ofertas';
import { SolicitudesService } from '../../../../../services/ofertas-laborales/solicitudes.service';
import { Subscription } from 'rxjs';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { PersonaModificacionComponent } from 'src/app/components/usuarios/pages/persona-modificacion/persona-modificacion.component';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { PersonasService } from 'src/app/services/shared/personas.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { GestosEvaluacionesUsuariosListadoComponent } from 'src/app/components/cursos/pages/gestos-evaluaciones-usuarios-listado/gestos-evaluaciones-usuarios-listado.component';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';
import { Actividad, DocumentosEstadosSolicitar } from 'src/app/interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { APP_CONFIG_SHARED, IAppShared } from 'src/app/app.config/app.config-shared';
import { UserDocumentoTiposService } from 'src/app/services/shared/user-documento-tipos.service';
import { UserDocumentoTipo } from 'src/app/interfaces/interface-bakend/seguridad/users/user-documento-tipos.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActividadDocumentoEstado } from 'src/app/interfaces/interface-bakend/ofertas-laborales/documento.interface';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-ofertas-listado',
    templateUrl: './ofertas-listado-cursos.component.html',
    styleUrls: ['./ofertas-listado-cursos.component.scss']
})
export class OfertasListadoCursosComponent implements OnInit, AfterViewInit {

    /* Data Table */
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns: string[] = ['nro_solicitud', 'createdAt', 'cursoNombre', 'descripcion', 'usuario_nombrePersona', 'usuario_identificacion', 'usuario_email', 'lastState_numberState', 'condicion', 'documentosAdjuntosPersonales', 'documentosAdjuntosSolicitud', 'fechaLibreSolicitudes', 'leido', 'progreso', 'id'];

    dataSource: any;
    solicitudes: Solicitud[];
    dataSourceLength = 0;
    /* Fin Data Table */

    flagLoaded = true;
    flagLoadData = false;
    selectEstado: number;
    flagLoadedModal = true;
    activarButton = true;
    estado: number;

    newState: Subscription;
    cursos: Curso[];
    cursosFiltrados: Curso[] = [];
    cursoSeleccionado: Curso;
    cursoId: string;

    filtroPista = false;
    color = 'warn';
    selectedCurso: string;

    filtroBusqueda = ''; // Término de búsqueda

    selectedEstados: number[] = [];
    selectedEstadosSeleccionados: number[] = [];
    allSelected = false;
    preescripciones: Actividad[] = [];
    preescripcionesFiltradas: Actividad[] = [];
    actividadSeleccionado: Actividad;
    preescripcionId: string;

    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
    selectorDelegaciones: Catalogo[];
    delegationSelected: string;

    documentosActivosGral: UserDocumentoTipo[] = [];
    documentosSolicitarGral: object[] = [];

    selectedDocumentsPersonal: string[] = [];
    selectedDocumentsSolicitud: string[] = [];

    documentosEstadosSolicitar: DocumentosEstadosSolicitar[];

    fechaDesde: Date;
    fechaHasta: Date;

    selectAllValue: any = 'select-all';  // Un identificador para la opción "seleccionar todo"

    soldsCargadas: any[] = [];
    nombrePreescripcion!: string;
    actividadCounts: { [key: string]: number } = {}; // Contadores de actividad para las solapas

    cantidadSolicitudesPendientes = 0;
    cantidadSolicitudesLeidas = 0;
    cantidadSolicitudesTotal = 0;

    solicitudCounts: { pendientes: { [key: string]: number }, leidas: { [key: string]: number } } = {
        pendientes: {},
        leidas: {}
    };

    loading = false;

    constructor(
        @Inject(APP_CONFIG_OFERTAS)
        public iAppOfertas: IAppOfertas,
        @Inject(APP_CONFIG_SHARED)
        private iAppShared: IAppShared,
        private solicitudesService: SolicitudesService,
        public dialog: MatDialog,
        private cursosService: CursosService,
        private guiMsjService: GuiMsjService,
        private personasService: PersonasService,
        private usuariosService: UsuarioService,
        private actividadesService: ActividadesService,
        private catalogoService: CatalogoService,
        private userDocumentoTiposService: UserDocumentoTiposService,
        private snackBar: MatSnackBar
    ) {
        const hoy = new Date();
        this.fechaDesde = hoy;
        this.fechaHasta = hoy;

        this.newState = this.solicitudesService.getsetNewStater().subscribe(resp => {
            this.newState = resp;
        });
    }

    async ngOnInit() {
        this.loadData();
    }

    async loadData() {
        await this.getDelegaciones();
        await this.getPreescripciones();
        await this.filtroActividades(false);
        this.flagLoadData = true;
    }

    async ngAfterViewInit() { }

    async filtroActividades(tipo: boolean) {
        this.resetTabContent();
        this.filtroPista = tipo;
        this.preescripcionesFiltradas = this.preescripciones.filter(preescripccion => preescripccion.pista === this.filtroPista);
        this.selectPreescripcion(null, this.preescripcionesFiltradas[0]);
        this.delegationSelected = null;
        this.selectedEstadosSeleccionados = [];
        this.selectedEstados = [];
        this.resetearTab();
    }

    async aceptarSeleccion() {
        this.cantidadSolicitudesLeidas = 0;
        this.cantidadSolicitudesPendientes = 0;
        this.cantidadSolicitudesTotal = 0;
        this.soldsCargadas = [];

        if (!this.filtroPista) { // control online
            if (this.selectedEstados.length > 0 && this.fechaDesde && this.fechaHasta) {
                this.selectedEstadosSeleccionados = this.selectedEstados;
                await this.loadDataTable(this.filtroPista, this.selectedEstadosSeleccionados, this.actividadSeleccionado);
            } else {
                alert('Los campos Fecha desde - Fecha Hasta y al menos un estado deben estar completos...');
            }
        } else { // control pista
            if (this.selectedEstados.length > 0 && this.delegationSelected && this.fechaDesde && this.fechaHasta) {
                this.selectedEstadosSeleccionados = this.selectedEstados;
                // tslint:disable-next-line:max-line-length
                await this.loadDataTable(this.filtroPista, this.selectedEstadosSeleccionados, this.actividadSeleccionado, this.delegationSelected);
            } else {
                alert('Los campos Fecha desde - Fecha Hasta, con al menos un Estado, y una Delegación deben estar completos...');
            }
        }
    }

    async getCursos() {
        this.cursos = await this.cursosService.getCursos();
    }

    async selectCurso(event: MatTabChangeEvent, curso?: Curso) {
        const nombreCurso = event?.tab?.textLabel;
        // tslint:disable-next-line:no-shadowed-variable
        const cursoEncontrado: Curso = this.cursos.find(curso => curso.nombre === nombreCurso) || curso;

        if (cursoEncontrado) {
            this.cursoSeleccionado = cursoEncontrado;
        } else {
            console.log('Curso no encontrado');
        }

        await this.loadDataTable(this.filtroPista, this.selectedEstadosSeleccionados, this.actividadSeleccionado)
    }

    // async selectPreescripcion(event: MatTabChangeEvent, activadad?: Actividad) {
    //     this.nombrePreescripcion = event?.tab?.textLabel;
    //     console.log(' this.nombrePreescripcion ',  this.nombrePreescripcion );
    //     console.log(' ts.activadad ', activadad);
        
    //     // tslint:disable-next-line:max-line-length
    //     const preescripcionEncontrada: Actividad = this.preescripciones.find(preescripcion => preescripcion.nombre === this.nombrePreescripcion) || activadad;
  
    //     if (preescripcionEncontrada) {
    //         this.actividadSeleccionado = preescripcionEncontrada;
    //     } else {
    //         console.log('Preescripcion no encontrado');
    //     }

    //     if (this.selectedEstadosSeleccionados.length > 0 && !this.filtroPista) { // solo cuando selecciona estados del selector
    //         await this.loadDataTable(this.filtroPista, this.selectedEstadosSeleccionados, this.actividadSeleccionado);
    //     }

    //     // tslint:disable-next-line:max-line-length
    //     if (this.selectedEstadosSeleccionados.length > 0 && this.filtroPista && this.delegationSelected) { // solo cuando selecciona estados del selector
    //         await this.loadDataTable(this.filtroPista, this.selectedEstados, this.actividadSeleccionado, this.delegationSelected);
    //     }
    // }

    async selectPreescripcion(event: MatTabChangeEvent, activadad?: Actividad) {

        const tabLabel = event?.tab?.textLabel;
        // const nombreOriginal = tabLabel ? tabLabel.split(' (')[0] : ''; // Extrae el nombre original FUNCIONAL
        const nombreOriginal = tabLabel.match(/^(.*?)\s*\((Leídas|Pendientes):\s*\d+,\s*(Leídas|Pendientes):\s*\d+\)$/)?.[1] || tabLabel; //  Bug J.C.RAMOS 25-07-2024 

        // tslint:disable-next-line:max-line-length
        const preescripcionEncontrada: Actividad = this.preescripciones.find(preescripcion => preescripcion.nombre === nombreOriginal) || activadad;

        if (preescripcionEncontrada) {
            this.actividadSeleccionado = preescripcionEncontrada;
        } else {
            console.log('Preescripcion no encontrado');
        }

        if (this.selectedEstadosSeleccionados.length > 0 && !this.filtroPista) { // solo cuando selecciona estados del selector
            await this.loadDataTable(this.filtroPista, this.selectedEstadosSeleccionados, this.actividadSeleccionado);
        }

        // tslint:disable-next-line:max-line-length
        if (this.selectedEstadosSeleccionados.length > 0 && this.filtroPista && this.delegationSelected) { // solo cuando selecciona estados del selector
            await this.loadDataTable(this.filtroPista, this.selectedEstados, this.actividadSeleccionado, this.delegationSelected);
        }
    }

    openDialogModaSolicitud(): void {
        const dialogRef = this.dialog.open(OfertasSolicitudComponent, {
            width: '900px',
            height: '1000px',
            disableClose: true,
        });
        this.flagLoadedModal = true;
        dialogRef.afterClosed().subscribe(async result => { });
        return;
    }

    async loadDataTable(tipoSolicitud: boolean, estados: number[], preescripcion: Actividad, delegacion?: string) {
        const data = {
            pista: this.filtroPista,
            fechaDesde: this.formatDate(this.fechaDesde),
            fechaHasta: this.formatDate(this.fechaHasta)
        };
        this.flagLoaded = false;

        if (this.soldsCargadas.length > 0) {
            console.log('no llamo');
            this.formatDataTable(this.soldsCargadas, estados, preescripcion, delegacion).then(resp => {
                    this.dataSource = new MatTableDataSource(resp);
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                    this.flagLoaded = true;
                    this.dataSourceLength = this.dataSource.data.length; // Guarda la longitud del dataSource
                    this.solicitudes = resp;
                });
        } else {
            console.log('llamo');
            this.solicitudesService.getActividadesByFechaPistaOnline(data).then((solds: any[]) => {
                this.soldsCargadas = solds;
                this.calculateSolicitudCounts(this.soldsCargadas); // Calcular la cantidad de solicitudes por actividad
                this.formatDataTable(solds, estados, preescripcion, delegacion).then(resp => {
                    this.dataSource = new MatTableDataSource(resp);
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                    this.flagLoaded = true;
                    this.dataSourceLength = this.dataSource.data.length; // Guarda la longitud del dataSource
                    this.solicitudes = resp;
                });
            });
        }
        
    }

    calculateSolicitudCounts(solicitudes: SolicitudList[]) {
        console.log('calculateSolicitudCounts - this.solicitudes', this.solicitudes);
        // Reinicializar los contadores
        this.solicitudCounts = {
            pendientes: {},
            leidas: {}
        };

        solicitudes.forEach(solicitud => {
            if (this.selectedEstados.includes(solicitud.lastState)) { // Verificar si el estado está seleccionado
                const actividadNombre = solicitud.actividad ? solicitud.actividad : 'Desconocida';
                // Inicializar los contadores si no existen
                if (!this.solicitudCounts.pendientes[actividadNombre]) {
                    this.solicitudCounts.pendientes[actividadNombre] = 0;
                }
                if (!this.solicitudCounts.leidas[actividadNombre]) {
                    this.solicitudCounts.leidas[actividadNombre] = 0;
                }

                if (!solicitud.leido) {
                    this.solicitudCounts.pendientes[actividadNombre]++;
                    this.cantidadSolicitudesPendientes++;
                    this.cantidadSolicitudesTotal++;
                } else if (solicitud.leido) {
                    this.solicitudCounts.leidas[actividadNombre]++;
                    this.cantidadSolicitudesLeidas++;
                    this.cantidadSolicitudesTotal++;
                }
            }
        });

        console.log('this.solicitudCounts', this.solicitudCounts);
    }


    getKeys(obj: { [key: string]: number }): string[] {
        return Object.keys(obj);
    }

    async verSolicitud(solicitud: Solicitud) {
        this.loading = true; // Mostrar el indicador de carga
        try {
            const sol = await this.solicitudesService.getSolicitudById(solicitud._id);
            this.solicitudesService.setSolicitudSeleccionada(sol);
            await this.updateSolicitudLeido(sol);
            solicitud.leido = true; // Actualizar el estado localmente para reflejar el cambio en el ícono
            this.openDialogModaSolicitud();
        } catch (error) {
            console.error('Error al ver la solicitud:', error);
        } finally {
            this.loading = false; // Ocultar el indicador de carga
        }
    }

    async updateSolicitudLeido(sol: Solicitud) {
        const solicitud: any = {
            _id: sol._id,
            leido: true,
            usuario: sol.usuario,
            pais: sol.pais._id,
            actividad: sol.actividad._id
        };

        await this.solicitudesService.updateSolicitudLeido(sol._id, solicitud);
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    filtrarPreescripcion() {
        this.preescripcionesFiltradas = this.preescripciones.filter(preescripcion =>
            preescripcion.nombre.toLowerCase().includes(this.filtroBusqueda.toLowerCase())
        );
    }

    async cargarPersona(element) {
        const solicitud: Solicitud = await this.solicitudesService.getSolicitudById(element._id);
        const _id = solicitud.usuario._id;

        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();

        await this.personasService.setPersonaSeleccionada(await this.personasService.getById(_id));

        const dialogRef = this.dialog.open(PersonaModificacionComponent, {
            width: '1200px',
            height: '900px',
        });

        Swal.close();

        dialogRef.afterClosed().subscribe(resp => {
        });
    }

    async verPuntajesCargados(element) {
        const solicitud: Solicitud = await this.solicitudesService.getSolicitudById(element._id);
        const _id = solicitud.usuario._id;

        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();

        await this.usuariosService.getFindOne(_id);

        const dialogRef = this.dialog.open(GestosEvaluacionesUsuariosListadoComponent, {
            width: '1300px',
            height: '900px',
        });

        Swal.close();

        dialogRef.afterClosed().subscribe(resp => {
        });
    }

    calcularPorcentaje(estado: number): number {
        if (Math.round((estado * 100) / 7) > 100) {
            return 100;
        }
        return Math.round((estado * 100) / 7);
    }

    async formatDataTable(
        solicitudes: SolicitudList[],
        estados: number[],
        preescripcion: Actividad,
        delegacion?: string
    ): Promise<any[]> {
        return new Promise(async resolve => {
            const format: any[] = [];
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < solicitudes.length; i++) {
                const solicitud = solicitudes[i];

                if (solicitud.tramite === 3360) { console.log('=====', solicitud); }
                if (estados.includes(solicitud.lastState) && solicitud.actividadId === preescripcion._id) {
                    if (!delegacion || solicitud.actividades[0]?.nombre === delegacion) {
                        this.documentosPendientes();
                        const documentosPersonalesEstado = await this.verificarDocumentosEntregadosPersonales(solicitud.usuarioAdjuntos);
                        // tslint:disable-next-line:max-line-length
                        const documentosSolicitudEstado = await this.verificarDocumentosEntregadosSolicitud(solicitud.documentosEstadosSolicitar, solicitud.usuarioAdjuntos);

                        const actividad = solicitud.actividades && solicitud.actividades[0] ? solicitud.actividades[0] : {};
                        const descripcion = solicitud.actividades && solicitud.actividades[0] ? solicitud.actividades[0].descripcion : {};

                        const elemento = {
                            nro_solicitud: solicitud.tramite,
                            tipo: solicitud.actividad,
                            createdAt: solicitud.fechaSolicitud,
                            actividad: solicitud.actividad,
                            lastState_numberState: solicitud.lastState,
                            usuario_nombrePersona: solicitud.solicitante,
                            usuario_identificacion: solicitud.identificacion,
                            usuario_email: solicitud.emailSolicitante,
                            usuarioId: solicitud.usuarioId,
                            fechaActividad: actividad.fecha || null,
                            descripcion: descripcion || '',
                            _id: solicitud.id,
                            data: solicitud,
                            condicion: solicitud.condicion,
                            cursoNombre: solicitud.cursos[0].nombre,
                            solicitud,
                            usuarioAdjuntos: documentosPersonalesEstado.documentosSolicitarGral,
                            selectedDocumentsPersonal: documentosPersonalesEstado.selectedDocumentsPersonal,
                            documentosSolicitudEstado,
                            fechaLibreSolicitudes: solicitud.fechaLibreSolicitudes,
                            actividadNombre: solicitud.actividad,
                            leido: solicitud.leido
                        };
                        format.push(elemento);
                    }
                }
            }
            resolve(format);
        });
    }

    resetTabContent() {
        this.dataSource = [];
        this.dataSource = new MatTableDataSource<Solicitud>();
    }

    resetearTab() {
        this.tabGroup.selectedIndex = -1;
    }

    getColor(stateNumber: number): string {
        switch (stateNumber) {
            case 8: return '#dc3545';
            case 7: return '#28a745';
            case 6: return '#17a2b8';
            case 5: return '#ffc107';
            case 4: return '#ffc107';
            case 3: return 'rgb(173, 216, 230)';
            case 2: return '#ffc107';
            case 1: return '#6c757d';
            case 9: return '#F1948A';
            case 10: return '#F1948A';
            default: return '#6c757d';
        }
    }

    calculatePercentage(stateNumber: number): number {
        return Math.min(Math.round((stateNumber / 7) * 100), 100);
    }

    getStatusText(stateNumber: number): string {
        switch (stateNumber) {
            case 8: return 'RECHAZADO';
            case 7: return 'APROBADO';
            case 6: return 'CONTROLAR PAGO';
            case 5: return 'PAGO SOLICITADO';
            case 4: return 'PRE-APROBADO';
            case 3: return 'DOCUMENTACIÓN COMPLETA';
            case 2: return 'DOCUMENTACIÓN PARCIAL';
            case 1: return 'FORMULARIO CARGADO';
            case 0: return 'PRE INSCRIPCIÓN';
            case 9: return 'ANULADA';
            case 10: return 'EN ESPERA';
            default: return '';
        }
    }

    getStateName(stateNumber: number): string {
        const estado = this.iAppOfertas.selectEstados[stateNumber];
        return estado ? estado.name : 'Desconocido';
    }

    isSelected(stateNumber: number): boolean {
        return this.selectedEstados.includes(stateNumber);
    }

    async getPreescripciones() {
        try {
            this.preescripciones = await this.actividadesService.getActividades();

            if (this.preescripciones.length === 0) {
                console.error('No se encontraron preescripciones');
            } else {
                this.filtrarPreescripcion();
            }
        } catch (error) {
            console.error('Error al obtener las preescripciones', error);
        }
    }

    updateCondicion(element: any) {
        element.solicitud.condicion = element.condicion;
        const solicitudUpdate: any = {
            _id: element.solicitud.id,
            pais: this.iAppOfertas.paisDefault,
            actividad: element.solicitud.actividadId,
            condicion: element.condicion
        };

        this.solicitudesService.updateSolicitud(solicitudUpdate._id, solicitudUpdate)
            .then((updatedSolicitud: Solicitud) => {
                console.log('Solicitud actualizada correctamente:', updatedSolicitud);
            })
            .catch(error => {
                console.error('Error al actualizar la solicitud:', error);
            });
    }

    async getDelegaciones() {
        try {
            const resp: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.delegaciones, null);
            this.selectorDelegaciones = resp;
        } catch (error) {
            console.error('Error al obtener las delegaciones', error);
        }
    }

    async documentosPendientes() {
        await this.getDocumentosActivosGral();
    }

    async getDocumentosActivosGral() {
        try {
            const resp: UserDocumentoTipo[] = await this.userDocumentoTiposService.getUserDocumentosTiposActivos();
            this.documentosActivosGral = resp;
        } catch (error) {
            console.error('Error al obtener los documentos', error);
        }
    }

    verificarDocumentosEntregadosPersonales(documentosAdjuntos: string[]): Promise<any> {
        return new Promise((resolve, reject) => {
            this.selectedDocumentsPersonal = [];
            this.documentosSolicitarGral = [];
            if (this.documentosActivosGral.length > 0) {
                if (documentosAdjuntos) {
                    this.documentosActivosGral.forEach(doc => {
                        if (!this.userDocumentoTiposService.documentosSolicitado(documentosAdjuntos, doc._id)) {
                            this.documentosSolicitarGral.push({
                                id: doc?._id,
                                name: doc.nombre,
                                documentosAdjuntosChecked: false,
                            });
                        } else {
                            this.documentosSolicitarGral.push({
                                id: doc?._id,
                                name: doc.nombre,
                                documentosAdjuntosChecked: true
                            });
                            this.selectedDocumentsPersonal.push(doc?._id);
                        }
                    });
                } else {
                    this.documentosSolicitarGral = [];
                    this.selectedDocumentsPersonal = [];
                }

                const result = {
                    documentosSolicitarGral: this.documentosSolicitarGral,
                    selectedDocumentsPersonal: this.selectedDocumentsPersonal
                };

                resolve(result);

            } else {
                this.documentosSolicitarGral = [{ message: 'No se encontraron documentos activos' }];
                const result = {
                    documentosSolicitarGral: this.documentosSolicitarGral,
                    selectedDocumentsPersonal: this.selectedDocumentsPersonal
                };

                resolve(result);
            }
        });
    }

    // tslint:disable-next-line:max-line-length
    verificarDocumentosEntregadosSolicitud(documentosAdjuntosSolicitud: ActividadDocumentoEstado[], usuarioAdjuntos: string[]): Promise<any> {
        return new Promise((resolve, reject) => {
            const documentosSolicitudSolicitar = this.generaDocumentosSolicitudSolicitar(documentosAdjuntosSolicitud, usuarioAdjuntos);
            this.selectedDocumentsSolicitud = [];

            if (documentosAdjuntosSolicitud) {
                documentosAdjuntosSolicitud.forEach(doc => {
                    const idPart = doc.documento._id.split('.')[0];
                    if (usuarioAdjuntos.some(id => id.startsWith(idPart))) {
                        this.selectedDocumentsSolicitud.push(doc._id);
                    }
                });

                documentosAdjuntosSolicitud.forEach(doc => {
                    if (doc.entregado) {
                        this.selectedDocumentsSolicitud.push(doc?._id);
                    }
                });

            } else {
                documentosAdjuntosSolicitud = [];
                this.selectedDocumentsSolicitud = [];
            }

            const result = {
                documentosAdjuntosSolicitud,
                selectedDocumentsSolicitud: this.selectedDocumentsSolicitud
            };

            resolve(result);
        });
    }

    async onToggleChangeFechalibre(element: any) {
        const usuario = {
            _id: element.usuarioId,
            fechaLibreSolicitudes: element.fechaLibreSolicitudes
        };
        await this.usuariosService.updateOne(usuario);
    }

    // tslint:disable-next-line:max-line-length
    generaDocumentosSolicitudSolicitar(documentosAdjuntosSolicitud: ActividadDocumentoEstado[], usuarioAdjuntos: string[]): DocumentosEstadosSolicitar[] {
        const documentos = [];
        if (documentosAdjuntosSolicitud) {
            documentosAdjuntosSolicitud.forEach(doc => {
                if (!this.solicitudesService.documentosSolicitado(usuarioAdjuntos, doc.documento._id)) {
                    documentos.push(doc);
                } else {
                    console.log('documento ya cargado..');
                }
            });
        } else {
            console.log('No se encontraron documentos activos');
        }
        console.log('retorna documentos a solicitar', documentos);
        return documentos;
    }

    formatDate(date: Date): string {
        return date.toISOString().split('T')[0];
    }

    isAllSelected() {
        return this.selectedEstados.length === this.iAppOfertas.selectEstados.length;
    }

    toggleSelectAll(event: Event) {
        event.stopPropagation();
        this.allSelected = !this.allSelected;
        if (this.allSelected) {
            this.selectedEstados = this.iAppOfertas.selectEstados.map(estado => estado.number);
        } else {
            this.selectedEstados = [];
        }
    }

    selectEstados(selectedValues: number[]) {
        if (!this.allSelected) {
            this.selectedEstados = selectedValues;
        }
    }

    exportExcel(): void {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSource.filteredData.map(solicitud => {
            return {
                'Nro. Solicitud': solicitud.nro_solicitud,
                'Fecha Solicitud': solicitud.createdAt,
                'Delegación': solicitud.actividad,
                'Módulo': solicitud.cursoNombre,
                'Nombre': solicitud.usuario_nombrePersona,
                'D.N.I': solicitud.usuario_identificacion,
                'Email': solicitud.usuario_email,
                'Estado': this.getStatusText(solicitud.lastState_numberState),
                'Condición': solicitud.condicion,
                'F. Libres Sol.': solicitud.fechaLibreSolicitudes ? 'Sí' : 'No'
            };
        }));
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Solicitudes');
        XLSX.writeFile(wb, 'Solicitudes.xlsx');
    }

    getTabLabel(preescripcion: Actividad): string {
        const leidas = this.solicitudCounts.leidas[preescripcion.nombre] || 0;
        const pendientes = this.solicitudCounts.pendientes[preescripcion.nombre] || 0;
        return `${preescripcion.nombre} (Leídas: ${leidas}, Pendientes: ${pendientes})`;
    }
    
}
