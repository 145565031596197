import { PaisesService } from '../../../../../services/shared/paises.service';
import { PaisDisponible } from './../../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Translators } from '../../../../../services/shared/translators.service';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';

@Component({
    selector: 'app-oferta-buscador-paises-banner',
    templateUrl: './oferta-buscador-paises-banner.component.html',
    styleUrls: ['./oferta-buscador-paises-banner.component.scss']
})
export class OfertaBuscadorPaisesBannerComponent implements OnInit {

    paisesDisponibles: PaisDisponible[] = [];
    selectedItem: PaisDisponible;
    idioma: Subscription;
    logoUrl: string;

    constructor(
        private paisesService: PaisesService,
        private router: Router,
        private translatorService: Translators,
        private configBeginService: ConfigBeginService
    ) {

    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        this.idioma = resp;
    });
    }

    bgImage = [
        {
            img: 'assets/img/trabajo/mapa.jpg'
        }
    ];

    async ngOnInit() {
        this.logoUrl = (await this.configBeginService.getConfiguracionGral()).urlLogo;
        this.getPaisesDisponibles();
    }

    onChange(event): void {
        const pais = this.paisesDisponibles.filter(x => x.pais.name_es === event)[0];
        this.selectedItem = event;  // don't forget to update the model here
        if (pais !== undefined) {
            this.paisesService.setPaisSeleccionado(pais);
        } else {
            return;
        }
    }

    async getPaisesDisponibles() {
        await this.paisesService.getPaisesDisponibles().then((resp: PaisDisponible[]) => {
            this.paisesDisponibles = resp;
        });
    }


}
