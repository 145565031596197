import { PaisesService } from '../../../../services/shared/paises.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { AfterContentInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PageEvent } from '@angular/material/paginator/public-api';
import { Subscription } from 'rxjs';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Actividad } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { StorageService } from '../../../../services/shared/storage.service';
import { Translators } from '../../../../services/shared/translators.service';

@Component({
  selector: 'app-actividades-disponibles-pais',
  templateUrl: './actividades-disponibles-pais.component.html',
  styleUrls: ['./actividades-disponibles-pais.component.scss']
})
export class ActividadesDisponiblesPaisComponent implements OnInit, AfterContentInit {

    pais: PaisDisponible;
    actividadesDisponibles: Actividad[];
    nombrePais: string;

    flagLoaded = false;

    // MatPaginator Output
    pageEvent: any;

    // MatPaginator Inputs
    length: number = 0;
    pageSize: number = 3;
    pageSizeOptions: number[] = [3, 6, 9, 12, 24];

    lowValue: number = 0;
    highValue: number = 3; // default view

    idioma: Subscription;

    constructor(
        private paisesService: PaisesService,
        private actividadesService: ActividadesService,
        private router: Router,
        private storageService: StorageService,
        private sanitizer: DomSanitizer,
        private translatorService: Translators,

    ) {
        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
          });
     }

    ngOnInit(): void {
        this.getEstadoVariables();
    }

    ngAfterContentInit(): void {
    }

    async getPaisActividadesDisponibles() {
        await this.getActividadesByPaisDisponiblesVigentes(this.getPais());
        this.nombrePais = this.paisesService.getPaisSeleccionado().pais.name_es;
    }

    async getActividadesByPais(pais: PaisDisponible){
         this.actividadesDisponibles =  await this.actividadesService.getActividadesFindByPaisDisponible(pais);
    }

    async getActividadesByPaisDisponiblesVigentes(pais: PaisDisponible){
         this.actividadesDisponibles =  await this.actividadesService.getActividadesByPaisDisponiblesVigentes(pais);
    }

    getPais(){
        return this.pais =  this.paisesService.getPaisSeleccionado();
    }

    async getEstadoVariables(){
        if (this.getPais() === undefined) {
            this.router.navigateByUrl('/');
            return;
        } else {
           await  this.getPaisActividadesDisponibles();
           this.flagLoaded = true;
        }
    }

    navCtrlDetalleOfertaLaboral(actividad: Actividad) {
        this.actividadesService.setActividadSeleccionada(actividad);
    }


    public getPaginatorData(event: PageEvent): PageEvent {
        this.lowValue = event.pageIndex * event.pageSize;
        this.highValue = this.lowValue + event.pageSize;
        return event;
    }


}
