<!-- <form [formGroup]="form">
    <div *ngFor="let row of form.controls;let i=index">
        <input *ngFor="let col of row.controls" [formControl]="col">
    </div>
</form> -->
<br>
<section>
    <div class="material-button-row">
        <mat-label>Agregar Concepto</mat-label>
        <button [disabled]="this.flagActivarConceptos" (click)="agregarConcepto()" mat-mini-fab color="primary">
        <mat-icon>plus_one</mat-icon>
      </button>
        <mat-label>Agregar Opciones</mat-label>
        <button [disabled]="this.flagActivarOpciones" (click)="agregarOpcionesEncabezado()" mat-mini-fab color="primary">
        <mat-icon>plus_one</mat-icon>
      </button>
        <mat-label>Limpiar Cuadricula</mat-label>
        <button (click)="limpiarCuadricula()" mat-mini-fab color="warn">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
</section>

<br>
<mat-divider></mat-divider>

<br>
<form [formGroup]="form">
    <!-- <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="scroll-viewport"> -->
    <div *ngFor="let row of form.controls;let f=index" class="animate__animated animate__fadeInUp">
        <mat-form-field *ngFor="let col of row.controls;let c=index" appearance="fill" style="width: 130px;" class="animate__animated animate__fadeInUp">

            <mat-label *ngIf=" f == 0 && c !== 0 ">Opción {{c}}</mat-label>
            <textarea *ngIf=" f == 0 && c !== 0  " rows="1" cols="40" [formControl]="col" matInput></textarea>

            <mat-label *ngIf="c == 0  && f !== 0 ">Concepto {{f}} </mat-label>
            <textarea *ngIf=" c == 0  && f !== 0 " rows="1" cols="40" [formControl]="col" matInput></textarea>

            <!-- vertice -->
            <textarea [(ngModel)]="vacio" hidden disabled *ngIf=" c == 0  && f == 0 " rows="1" cols="40" [formControl]="col" matInput></textarea>

            <mat-select *ngIf="f !== 0 && c !== 0" [formControl]="col" [errorStateMatcher]="matcher">
                <mat-option value="1">V</mat-option>
                <mat-option value="0">F</mat-option>
            </mat-select>
        </mat-form-field>

    </div>
    <!-- </cdk-virtual-scroll-viewport> -->
</form>
<br>
<mat-divider></mat-divider>
<br>
