import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import Swal from 'sweetalert2';
import { APP_CONFIG, IAppConfig } from '../../app.config/app.config';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Translators } from '../../services/shared/translators.service';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  form: FormGroup;
  siteKey: string;
  idioma: Subscription;
  logoUrl: string;
  
  constructor(
    @Inject(APP_CONFIG)
    private iAppConfig: IAppConfig,
    private authservice: AuthService,
    public guiMsjService: GuiMsjService,
    private translatorService: Translators,
    public configBeginService: ConfigBeginService,
    ) {
    this.createForm();

    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        this.idioma = resp;
    });
  }

  async ngOnInit() {
    this.logoUrl = (await this.configBeginService.getConfiguracionGral()).urlLogo;
    this.setSiteKey(this.iAppConfig.recaptcha.siteKey);
  }

  useLanguage(idioma: string){
    this.translatorService.setIdiomaSeleccionado(idioma);
  }

  setSiteKey(key: string) {
    this.siteKey = key;
  }

  get emailNoValido(){
    return this.form.get('email').invalid && this.form.get('email').touched;
  }

  createForm() {
    this.form = new FormGroup({
        email: new FormControl('', [ Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'), ]),
        recaptcha: new FormControl('', Validators.required)
    });
  }

  async resetPassword(){

    if (this.form.invalid){
      return Object.values( this.form.controls ).forEach( control => {
          control.markAsTouched();
      } );
    }

    this.authservice.resetPassword(
      this.form.value.email
    );

    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();

  }

}
