<div class="features-area pb-70 mt-5 animate__animated animate__fadeIn">
  <div class="container" *ngIf="evaluacion">
    <app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar></div>
        <p>Alumno:
          <strong>{{ alumno.nombrePersona ? alumno.nombrePersona : ''}} {{ alumno.apellidoPersona ?
            alumno.apellidoPersona : ''}}</strong> <br>
          <strong>{{ alumno.email }}</strong>
        </p>
        <mat-card-title>Examen: {{examenEvaluado.titulo}}</mat-card-title>
        <mat-card-subtitle>Descripción: {{examenEvaluado.descripcion}}
          <p>Estado examen: <strong>{{evaluacion.examenFinalizado ? 'Finalizado' : 'Incompleto'}}</strong></p>
          <p><strong>{{evaluacion.examenTimeOut ? 'TimeOut - Error' : ''}}</strong></p>
          <p><strong>{{evaluacion.examenExternalError ? 'Error externo al sistema' : ''}}</strong></p>
          <p>Puntaje aprobación: <strong>{{examenEvaluado.porcentajeAprobacionMinimo ?
              examenEvaluado.porcentajeAprobacionMinimo : ''}}</strong></p>
          <p><strong>{{evaluacion.recuperatorio ? 'Es Recuperatorio' : ''}}</strong></p>
        </mat-card-subtitle><br>

      </mat-card-header>
      <mat-divider></mat-divider>
      <br>
      <h4>Preguntas</h4>
      <mat-card-content *ngIf="examenEvaluado">
        <br>
        <ng-container *ngFor="let pregunta of examenEvaluado.preguntas">

          <mat-accordion>
            <mat-expansion-panel hideToggle>

            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <strong>{{pregunta.enunciado}}</strong>
                </mat-panel-title>
                <mat-panel-description>
                  <p>Puntaje: <strong> {{pregunta.puntajeCalculado | number : '1.2-2'}}</strong></p>
                  <p style="margin-left: 5px;" *ngIf="pregunta.tipo === 'MC'"> Tipo pregunta: <strong> (Multiple
                      Choice)</strong></p>
                  <p style="margin-left: 5px;" *ngIf="pregunta.tipo === 'VF'"> Tipo pregunta: <strong> (Verdadero o
                      Falso)</strong></p>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" *ngIf="pregunta.tipo === 'MC'">Respuestas</th>
                    <th scope="col">Correcta</th>
                    <th scope="col">Seleccionado (Respuesta del alumno)</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let respuesta of pregunta.respuestas; let i = index">
                    <tr>
                      <th scope="row">{{i+1}}</th>
                      <td *ngIf="pregunta.tipo === 'MC'">{{respuesta.texto}}</td>
                      <ng-container *ngIf="pregunta.tipo === 'VF'; else elseTemplate">
                        <td>{{respuesta.correcta ? 'Verdadero' : 'Falso'}}</td>
                        <td>{{respuesta.seleccionado ? 'Verdadero' : 'Falso'}}</td>
                      </ng-container>
                      <ng-template #elseTemplate>
                        <td>{{respuesta.correcta ? 'si' : 'no'}}</td>
                        <td>{{respuesta.seleccionado ? 'si' : 'no'}}</td>
                      </ng-template>
                    </tr>
                  </ng-container>


                </tbody>
              </table>
            </mat-expansion-panel>
          </mat-accordion>

          <br>

        </ng-container>

        <p>Puntaje Obtenido: <strong>{{ evaluacion.puntajeObtenido ? evaluacion.puntajeObtenido : 'SIN PUNTAJE'
            }}</strong></p>
        <br>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="warn" (click)="closeModal()">Cerrar</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>