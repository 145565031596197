import { APP_CONFIG_CURSOS, IAppCursos } from './../../../../app.config/app.config-cursos';
import { APP_CONFIG_OFERTAS, IAppOfertas } from './../../../../app.config/app.config-ofertas';
import { Actividad } from './../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { MediaService } from './../../../../services/shared/media.service';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Solicitud } from '../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { SolicitudesService } from '../../../../services/ofertas-laborales/solicitudes.service';
import { Translators } from '../../../../services/shared/translators.service';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BankTransfer } from 'src/app/interfaces/interface-bakend/invoices/bankTransfer.interfaces';
import { InvoicesService } from 'src/app/services/shared/invoices.service';
import { BankTransferService } from '../../../../services/shared/bank-transfer.service';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';
import { CalculoPagoTransferencia } from '../../../../interfaces/interface-bakend/invoices/bankTransfer.interfaces';
import { Curso } from '../../../../interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';

@Component({
  selector: 'app-upload-comprobante-solicitud',
  templateUrl: './upload-comprobante-solicitud.component.html',
  styleUrls: ['./upload-comprobante-solicitud.component.scss']
})
export class UploadComprobanteSolicitudComponent implements OnInit {

    activarCargaDocumento = false;
    value = 0;
    subscriptionAvance: Subscription;
    flagLoading = false;
    flagCargado = false;
    selectedFile: any;
    solicitud: Solicitud;
    flagDisableButton = true;
    usuario: User;
    actividad: Actividad;
    curso: Curso;
    bankTransfer: BankTransfer;
    flagLoaded = false;

    idioma: Subscription;

    constructor(
        private mediaService: MediaService,
        private guiMsjService: GuiMsjService,
        private router: Router,
        private translatorService: Translators,
        private authService: AuthService,
        private bankTransferService: BankTransferService,
        private actividadesService: ActividadesService,
        private cursoService: CursosService,
        private solicitudesService: SolicitudesService,

        /* Modal Ref. */
        public dialogRef: MatDialogRef<UploadComprobanteSolicitudComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        /* Fin Modal Ref. */
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        @Inject(APP_CONFIG_CURSOS) private iAppCursos: IAppCursos)

        {
            this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
                this.idioma = resp;
              });

            this.subscribeAvanceUpload();
        }

    ngOnInit(): void {
        this.initValue();
    }

    async initValue() {
        await this.getActividad();
        await this.getUsuariologon();
      }

    async getCurso() {
        this.curso = await this.cursoService.getCursoSeleccionado();
    }

    async getUsuariologon() {
        this.usuario = await this.authService.getUserLogon();
        this.flagLoaded = true;
    }

    async getActividad() {
        this.actividad = await this.actividadesService.getActividadSeleccionada();
    }

    onFileSelected(event) {
        if (this.mediaService.controlSize(event)) {
            this.flagDisableButton = false;
            this.selectedFile = (event.target.files[0] as File);
        } else {
            this.flagDisableButton = true;
            alert('File too Big, please select a file less than 4mb');
            return;
        }
    }

    async uploadComprobanteAdjunto(selectedFile) {
        this.mediaService.uploadComprobanteTransferencia(
                selectedFile,  this.usuario._id, this.bankTransfer._id,
            ).then( async resp => {
            if (resp) {
                if (this.value === 100) {
                    this.flagLoading = false;
                    this.flagDisableButton = false;
                    this.bankTransferService.setEstadoPago(true);
                    this.closeModal();
                    this.guiMsjService.msjFormSubmit('uploadDocumentOK');
                }
            } else {
                this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
            }
        });
    }

    subscribeAvanceUpload(){
        this.subscriptionAvance = this.mediaService.getAvance().subscribe(resp => {
            if (resp) {
                this.value = resp.value;
            } else {
                this.value = 0;
                }
        });
    }

    async cargar(): Promise<void> {
        this.flagDisableButton = true;
        this.flagLoading = true;

        let bankTransfer: BankTransfer = await this.createBankTransfer();

        // Subo comprobante
        await this.uploadComprobanteAdjunto(this.selectedFile).then( async () => {

            // Ahora que la carga del comprobante se ha completado, busco el comprobante con el path del documento que subí
            bankTransfer = await this.bankTransferService.getBankTransferById(bankTransfer._id);
         
            // Busco solicitud seleccionada
            let solicitud: any =  await this.solicitudesService.getSolicitudSeleccionada();

            // Asocio a la solicitud el BackTransfer para poder visualiar en INVOICE
            solicitud.comprobanteTransferenciaBancaria = bankTransfer;

            // Actualizo solicitud
            solicitud.usuario =  solicitud.usuario._id; // el backend espera string no object
            solicitud.pais = solicitud.pais._id;  // el backend espera string no object
            solicitud.actividad = solicitud.actividad._id;  // el backend espera string no object

            solicitud = await this.solicitudesService.updateSolicitud(solicitud._id, solicitud);

            // ACTUALIZO SOLICITUD Y ENVIO EMAIL A ADMINSITRACION PARA NOTIFICAR QUE RECIBIERON PAGO
            await this.actualizarEstadoSolicitud(this.solicitudesService.getSolicitudSeleccionada()._id, this.iAppOfertas.estado_6.number, 'solicitudEstadoOK', true, this.iAppOfertas.estado_6.name);

            this.solicitudesService.getsetNewStater();
            this.flagCargado = true;
            this.flagLoading = false;
        });

    }

    async createBankTransfer(): Promise<BankTransfer> {
        this.bankTransfer = {
            actividad: this.actividad._id,
            fecha: new Date(),
            usuario: this.usuario._id,
            path: '',
            importe: this.data.valorCobrar,
            cotizacionUSD: 0,
        };

        this.bankTransfer =  await this.bankTransferService.createBankTransfer(this.bankTransfer);

        return await this.bankTransfer;
    }

    async closeModal(): Promise<void> {
        this.dialogRef.close(true);
    }


    async actualizarEstadoSolicitud(_id: string, numberState: number, msj: string,  notificaAdmin: boolean, state) {
        this.solicitudesService.updateSolicitudState(_id, numberState, notificaAdmin, state).then( async (solicitudUpdated: Solicitud) => {
            await this.solicitudesService.setSolicitudSeleccionada(solicitudUpdated);
            this.solicitudesService.setNewState(solicitudUpdated);
            this.router.navigate(['solicitud']);
        });
    }

    reloadCurrentRoute() {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['solicitud']);
            return;
        });
    }


}
