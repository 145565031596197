import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
  selector: 'app-formulario-registro',
  templateUrl: './formulario-registro.component.html',
  styleUrls: ['./formulario-registro.component.scss']
})
export class FormularioRegistroComponent implements OnInit {

  lenguaje = 'es';
  idioma: Subscription;
  empresa: string;
  urlLogo: string;

  constructor(
    private translatorService: Translators,
    private configBeginService: ConfigBeginService
    ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      switch (resp.value){
        case 'es':
          this.lenguaje = 'es';
          break;
        case 'en':
          this.lenguaje = 'en';
          break;
        default:
          this.lenguaje = 'es';
          break;
        }
    });
  }

  async ngOnInit() {
    this.empresa = (await this.configBeginService.getConfiguracionGral()).empresa;
    this.urlLogo = (await this.configBeginService.getConfiguracionGral()).urlLogo;
  }

  useLanguage(idioma: string){
    this.translatorService.setIdiomaSeleccionado(idioma);
  }

}
