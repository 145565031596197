<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/usuarios-administracion">Configuración Usuario</a></li>
                <li>Configurar Documentos Usuarios</li>
            </ul>
            <h2>Configurar Documentos Usuarios</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg"
             alt="image"></div>
</div>

<div class="features-area pb-70 animate__animated animate__fadeIn">
    <div class="container">

        <div class="row m-4">

            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-folder-open"></i>
                    </div>
                    <h3>Listado de Documentos</h3>
                    <p>Acceda al listado de Documentos del Usuario</p>
                    <a routerLink="/documentos-usuarios-listado"
                       class="btn btn-info">Ver Documentos</a>
                </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-file-alt"></i>
                    </div>
                    <h3>Nuevo Documento</h3>
                    <p>Cree una nuevo Documento</p>
                    <a routerLink="/documento-usuario-alta"
                       class="btn btn-success">Crear Documento</a>
                </div>
            </div>

        </div>
    </div>