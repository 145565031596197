import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { MediaService } from '../../../../services/shared/media.service';
import { Profesor } from '../../../../interfaces/interface-bakend/cursos/profesor.interface';
import { Materia } from '../../../../interfaces/interface-bakend/cursos/materia.interface';
import { LeccionesService } from '../../../../services/cursos/lecciones.service';
import { MateriasService } from '../../../../services/cursos/materias.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { Leccion } from '../../../../interfaces/interface-bakend/cursos/leccion.interface';

@Component({
  selector: 'app-leccion-alta',
  templateUrl: './leccion-alta.component.html',
  styleUrls: ['./leccion-alta.component.scss']
})
export class LeccionAltaComponent implements OnInit {

    myModel = true;
    form: FormGroup;
    currentDate = new Date();
    profesores: Profesor[] = [];
    materias: Materia[] = [];
    estado: boolean = true;

    // Upload
    flagSelectFile = false;
    selectedFile: any;
    value = 0;
    cargandoArchivo = false;
    subscriptionAvance: Subscription;

    constructor(
        private leccionesService: LeccionesService,
        private materiasService: MateriasService,
        private router: Router ,
        private mediaService: MediaService,
        private guiMsjService: GuiMsjService,
        ) {
        this.subscribeAvanceUpload();
        this.createForm();
        this.getMaterias();
    }

    ngOnInit(): void {}

    get nombreNoValido(){
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
    }

    get descripcionNoValida(){
    return this.form.get('descripcion').invalid && this.form.get('descripcion').touched;
    }

    get fechaNoValida(){
    return this.form.get('fechaInicio').invalid && this.form.get('fechaInicio').touched;
    }

    get fechaFinNoValida(){
    return this.form.get('fechaFin').invalid && this.form.get('fechaFin').touched;
    }

    async getMaterias(){
    this.materiasService.getMaterias().then( (materias: Materia[]) => {
        this.materias = materias;
    });
    }

    createForm() {
    this.form = new FormGroup({
        nombre: new FormControl('', [ Validators.required]),
        descripcion: new FormControl('', [ Validators.required]),
        fechaInicio: new FormControl('', [ Validators.required]),
        fechaFin: new FormControl('', [ Validators.required]),
        estado: new FormControl(true),
        orden: new FormControl('', Validators.required),
        puntaje: new FormControl('',  [Validators.max(100), Validators.min(0), Validators.required] ),
        materia: new FormControl('', [ Validators.required ]),
        file: new FormControl('', [ Validators.required]),
    });
    }

    async post(){
      await this.guiMsjService.msjFormSubmit('Guardando');
      this.leccionesService.post(await this.setDataForma()).then( async (leccion: Leccion) => {
          await this.uploadFile(this.selectedFile, leccion._id);
          await this.guiMsjService.msjFormSubmit('DatosGuardados');
          this.router.navigateByUrl('/lecciones-listado');
          return;
      });
    }

    async setDataForma(): Promise<Leccion>{

      const data: Leccion = {
          nombre: this.form.value.nombre,
          descripcion: this.form.value.descripcion,
          fechaInicio: this.form.value.fechaInicio,
          fechaFin: this.form.value.fechaFin,
          puntaje: this.form.value.puntaje,
          estado: this.form.value.estado,
          orden: this.form.value.orden,
          materia: this.form.value.materia
      };

      return data;

    }
   /* UPLOAD */

    /* subo archivo con observable*/
    subscribeAvanceUpload(){
        this.subscriptionAvance = this.mediaService.getAvance().subscribe(resp => {
        if (resp) {
            this.value = resp.value;
        } else {
            this.value = 0;
            }
        });
    }

    openFileSelected(event) {
        this.selectedFile = <File> event.target.files[0];
    }

    async uploadFile(selectedFile, idLeccion) {
        const type = await this.getTypeFile(selectedFile.type);
        this.cargandoArchivo = true;
        await this.mediaService.uploadLeccion(selectedFile, idLeccion, type).then( resp => {
          if (resp) {
            if (this.value === 100) {this.cargandoArchivo = false; }
          } else {
            this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
          }
        });
    }

    getTypeFile(type): string {
        switch (type) {
            case 'image/jpeg': // img - image/png
              return 'img';
              break;

            case 'image/png': // img - image/png
              return 'img';
              break;

            case 'video/mp4': // mp4 - video/mp4
              return 'mp4';
              break;

            case 'application/pdf': // pdf - application/pdf
              return 'pdf';
              break;

            default:
              return 'sinEspecificar';
              break;
          }
    }

     /* FIN UPLOAD */

}

