
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/home">{{ idioma | translate: "navbar.UL-INICIO" | async}}</a></li>
                <li><a *ngIf="this.loaded" routerLink="/actividad-detalle">{{this.actividad.nombre}}</a></li>
                <li>{{ idioma | translate: "oferta-laboral.LINK-POSTULAR-PAGO" | async}}</li>
            </ul>
            <h2>{{ idioma | translate: "oferta-laboral.LINK-POSTULAR-PAGO" | async}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="checkout-area ptb-100">
    <div class="container">
        <form>
            <!-- ##### C1  ##### NO SE USA , ERA UTILIZADO CON HUBTRAVEL-->
            <div class="row animate__animated animate__fadeIn" *ngIf="(actividad.circuito === 1)">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <div class="row">
                            <app-card [activar]="false" (cardData)="(cardDataUser = $event)" (cardDataFlag)="(cardDataFlag = $event)"></app-card>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="courses-details-info">
                        <app-ofertas-detalle-items *ngIf="(actividad.circuito === 1)" [pais]=pais [actividad]=actividad (EcostoPagar)="(costoPagar = $event)" (Edescription)="(description= $event)"></app-ofertas-detalle-items>
                        <!-- Boton Paypal -->
                        <div class="payment-box" *ngIf="(actividad.circuito === 1)">
                            <app-pay-pal-button [valor]="actividad.costoAdministrativo" [description]="description" [currency_code]="this.currency_code"></app-pay-pal-button>
                            <br>
                            <br>
                            <mat-progress-bar *ngIf="this.flagProcesandoPago" mode="indeterminate"></mat-progress-bar>
                            <!-- <span *ngIf="this.flagProcesandoPago" class="d-block p-2 bg-primary text-white">{{ idioma | translate: "form.MSJ-PROCESANDO" | async}}</span> -->
                            <div *ngIf="this.flagProcesandoPago" class="alert alert-success m-3" width="100%" role="alert">
                                {{ idioma | translate: "form.MSJ-PROCESANDO" | async}}
                            </div>
                        </div>
                        <!-- Fin Paypal -->
                    </div>
                </div>
            </div>
            <!-- #####  FIN C1 #####  -->

            <!-- ##### C2  ##### -->
            <div class="row animate__animated animate__fadeIn" *ngIf="(actividad.circuito === 2)">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <div class="row align-items-center">                        
                            <!-- <app-ofertas-avatar [actividad]="actividad"></app-ofertas-avatar> -->
                            <app-tarjeta [click]="false"  [img]="true" [video]="false" [price]="true" [actividad]="actividad"></app-tarjeta>

                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <div class="row align-items-center">
                            <div class="single-courses-box">
                                <div class="col-lg-12 col-md-12">
                                    <div class="courses-details-info">
                                        <ul class="info">
                                            <h4>Módulo/s que selecciono:</h4>
                                            <div *ngIf="this.modulos.length == 0" class="d-flex justify-content-between align-items-center">
                                                <span>Seleccione al menos un Módulo...</span>
                                            </div>
                                            <div *ngFor="let item of this.modulos">
                                                <li class="price">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span><i class="flaticon-lock"></i>{{item.nombre}}  </span> ${{item.precio}}
                                                    </div>
                                                </li>
                                            </div>
                                            <li class="price">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <span>Total: </span> ${{this.totalModulosCosto}}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div clas="row" >
                                    <div class="payment-box" *ngIf="(this.modulos.length > 0)">
                                        <button [disabled]="flagProcesandoPago" type="button" (click)="abrirFormularioPreincripcion()"><i class="fas fa-sign-in-alt"></i> {{ idioma | translate: "oferta-laboral.POSTULAR-BTN" | async}}</button>
                                        <br>
                                        <br>
                                        <mat-progress-bar *ngIf="this.flagProcesandoPago" mode="indeterminate"></mat-progress-bar>
                                        <div *ngIf="this.flagProcesandoPago" class="alert alert-success m-3" width="100%" role="alert">
                                            {{ idioma | translate: "form.MSJ-PROCESANDO" | async}}
                                        </div>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ##### FIN C2  ##### -->
        </form>
    </div>
</div>

<!-- TARJETA CREDITO
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a *ngIf="this.loaded" routerLink="/oferta-laborar-detalle">{{this.actividad.nombre}</a></li>
                <li>Postular a la oferta</li>
            </ul>
            <h2>Postular a la oferta</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="checkout-area ptb-100">
    <div class="container">
        <div class="user-actions">
        </div>
        <form>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <div class="row">
                            <app-card [activar]="true" (cardData)="(cardDataUser = $event)" (cardDataFlag)="(cardDataFlag = $event)"></app-card>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="courses-details-info">
                        <app-ofertas-detalle-items [pais]=pais [actividad]=actividad (EcostoPagar)="(costoPagar = $event)" (Edescription)="(description= $event)"></app-ofertas-detalle-items>
                        <div class="payment-box">
                            <div class="payment-method">
                                <div class="page-title-area">
                                    <div class="container">
                                        <div class="page-title-content">
                                            <h2>
                                                <a routerLink="/"><img src="assets/img/logo-Paypal.png" alt="ski-andorra" width="30%"></a><br>Secure Payments by Paypal</h2>
                                        </div>
                                    </div>
                                </div>

                                <p>
                                    <input type="radio" id="credit-card" name="radio-group" checked>
                                    <label for="credit-card">Tarjeta crédito</label>
                                </p>

                                <p>
                                    <input type="radio" id="paypal" name="radio-group" value="true">
                                    <label for="paypal">PayPal</label>
                                </p>

                            </div>

                            <button *ngIf="!cardDataFlag || this.flagProcesandoPago" disabled class="default-btn"><i class="flaticon-shopping-cart"></i>Pagar USD $ {{ actividad.costoAdministrativo }}</button>
                            <button *ngIf="cardDataFlag && !this.flagProcesandoPago" (click)="procesarPago( cardDataUser, costoPagar )" type="button" class="default-btn"><i class="flaticon-shopping-cart"></i>Pagar USD $ {{ actividad.costoAdministrativo }}<span></span></button>

                            <br>
                            <br>
                            <mat-progress-bar *ngIf="this.flagProcesandoPago" mode="indeterminate"></mat-progress-bar>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div> -->