
<div class="card">
    <div class="card-header text-center">
        <h3>{{ data.label }}</h3>
    </div>
    <div class="card-body">
        <form>
            <div class="mb-3 file-upload-wrapper">
                <input id="fileInput" class="form-control file-upload-input" [accept]="data.accept" type="file"
                    (change)="onFileSelected($event)">
                <label for="fileInput" class="file-upload-label">
                    <i class="fas fa-upload"></i> Selecciona un archivo
                </label>
            </div>

            <div class="row" *ngIf="!flagLoading">
                <div *ngIf="previewUrl" class="text-center mb-3">
                    <div *ngIf="selectedFile?.type.startsWith('image/')"
                        class="img-container d-flex justify-content-center align-items-center">
                        <img *ngIf="selectedFile?.type.startsWith('image/')" [src]="previewUrl" alt="Image Preview" class="rounded"
                            width="75px" height="75px">
                    </div>
                    <p *ngIf="selectedFile?.type === 'application/pdf'">{{ selectedFile?.name }}</p>
                </div>
            </div>

            <div *ngIf="flagLoading" class="mt-3 text-center">
                <div class="badge bg-warning text-wrap" style="width: 6rem;">
                    {{ idioma | translate: 'form.MSJ-PROCESANDO' | async }}
                </div>
                <br><br>
                <div class="progress">                  
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [style.width.%]="value">
                        {{ value }}%
                    </div>
                </div>
            </div>

            <br>

            <div class="text-center">
                <button (click)="aceptar()" [disabled]="flagDisableButton" class="btn btn-success me-2"
                    [ngClass]="{'custom-disabled': flagDisableButton}">
                    {{ idioma | translate: 'form.BTN-ACEPTAR' | async }}
                </button>
                <button (click)="closeModal()" class="btn btn-warning">
                    {{ idioma | translate: 'form.BTN-CANCELAR' | async }}
                </button>
            </div>

           
        </form>
    </div>
</div>