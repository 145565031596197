import { Component, Input, OnInit } from '@angular/core';
import { Profesor } from '../../../../interfaces/interface-bakend/cursos/profesor.interface';

@Component({
  selector: 'app-profesor-tarjeta-presentacion',
  templateUrl: './profesor-tarjeta-presentacion.component.html',
  styleUrls: ['./profesor-tarjeta-presentacion.component.scss']
})
export class ProfesorTarjetaPresentacionComponent implements OnInit {

  @Input() profesor: Profesor;
  rutaImagenDefault: string = 'assets/img/user9.png';
  constructor() { }

  ngOnInit(): void {
    console.log('profesor', this.profesor);
  }

  convertirACamelCase(texto: string): string {
    // Convertir a camelCase
    if (texto) {
      return texto.split(' ').map((word, index) => {
        // Convertir la primera letra de cada palabra a mayúscula
        if (index === 0) {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
      }).join('');
    }
   
  }
  
}
