import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fechaPickerPipe',
})
export class FechaPickerPipe implements PipeTransform {
  transform(value: string ){  
    // type fecha mongo --> "2019-06-20T00:00:00.000Z" 
    if ( typeof value === 'string' && value.length >= 10){
      return moment(value.substring(0, 10)).utcOffset(0).toDate();
    }
    else {
      return '';
    }
  }
}