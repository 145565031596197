import { Response } from '../../interfaces/interface-bakend/response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';
import { stringify } from 'querystring';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ConfigBeginService } from './config-begin.service';

const URL_BACKEND = environment.urlBackend;
const DICCIONARIO = environment.diccionario;

@Injectable({
  providedIn: 'root'
})
export class Translators {

  private idiomaSeleccionado = new Subject<any>();

  idioma: string ;

  translation: string;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private configBeginService: ConfigBeginService,

  ) {
      this.storageService.getIdioma().then( resp => {
        this.setInitDefaultIdioma(resp.replace(/['"]+/g, ''));
        this.storageService.setIdioma(resp.replace(/['"]+/g, ''));
      });
   }

  async getTranslation(lang: string, key: string){
    return new Promise( resolve => {
        this.httpClient.get<Response>(`${ URL_BACKEND }/translators/translateByKey/${lang}/${key}`).subscribe( resp  => {
          resolve(resp.response);
      });
    });
  }

  async getTranslationByOne(lang: string, key: string, file: string){
    return new Promise( resolve => {
        this.httpClient.get<Response>(`${ URL_BACKEND }/translators/translateOne/${lang}/${file}/${key}`).subscribe( resp  => {
          resolve(resp.response);
      });
    });
  }

  setIdioma(idioma: string, url: string){
    this.idioma = idioma;

     /* REFRESH FORM */
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(async () => {
          await this.router.navigate([url]);
      });

  }


  // JUANCA
  async getTranslationGeneral(idioma, key: string): Promise<string>{

    // Control default idioma
    if (idioma?.value !== undefined) {
        this.idioma = idioma.value;
    } 

    const data = {
      "lang": this.idioma ,
      "i18nFile": DICCIONARIO,
      "key": key
    };

    return new Promise( resolve => {
      this.httpClient.post<Response>(`${ URL_BACKEND }/translators/translateOne`, data).subscribe( resp  => {
        resolve(resp.response);
      });
    });
  }

  getIdiomaSeleccionado(): Observable<any> {
    return this.idiomaSeleccionado.asObservable();
  }

  setIdiomaSeleccionado(idioma: string) {
    this.idioma = idioma;
    this.idiomaSeleccionado.next({ value: idioma });
    this.storageService.setIdioma(idioma);
  }

  setInitDefaultIdioma(idioma) {
    this.idioma = idioma;
  }

  getIdioma(): string {
    return this.idioma;
  }


}
