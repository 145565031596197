import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { GestosService } from 'src/app/services/cursos/gestos.service';
import { GestoUsuario } from 'src/app/interfaces/interface-bakend/cursos/gestoUsuario.interface';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalElegirMesaExamenComponent } from '../modal-elegir-mesa-examen/modal-elegir-mesa-examen.component';
import { MesasUsuariosAsociadosModalComponent } from '../mesas-usuarios-asociados-modal/mesas-usuarios-asociados-modal.component';
import { OfertasListadoCursosComponent } from 'src/app/components/ofertas-laborales/pages/ofertas-administracion/ofertas-listado-cursos/ofertas-listado-cursos.component';

@Component({
  selector: 'app-gestos-evaluaciones-usuarios-listado',
  templateUrl: './gestos-evaluaciones-usuarios-listado.component.html',
  styleUrls: ['./gestos-evaluaciones-usuarios-listado.component.scss']
})
export class GestosEvaluacionesUsuariosListadoComponent implements OnInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
    [
      'mesa', 'gesto', 'usuario', 'aprobado', 'nota', 'fechaVencimiento', '_id'
    ];
  dataSource: any;
  /* Fin Data Table*/

  flagLoaded = false;
  usuario: User;
  resultados: boolean = false;

  constructor(
    private router: Router,
    private guiMsjService: GuiMsjService,
    private gestosService: GestosService,
    private usuarioService: UsuarioService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<GestosEvaluacionesUsuariosListadoComponent>,

    // public dialogRef: MatDialogRef<OfertasListadoCursosComponent>,

  ) { }


  ngOnInit(): void {
    this.getEstadoVariables();
  }

  async editarEvaluacionGesto(id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    await this.gestosService.getGestoEvaluadoById(id);
    this.router.navigateByUrl('/gesto-evaluacion-modificacion');
  }

  getUsuario() {
    return this.usuario = this.usuarioService.getUsuarioSeleccionado();
  }

  async getEstadoVariables() {
    if (this.getUsuario() === undefined) {
      this.router.navigateByUrl('/mesas-listado');
      return;
    } else {
      this.getUsuario();
      await this.gestosService.getGestosEvaluadosByUser(this.getUsuario()._id).then((gestos: GestoUsuario[]) => {
        console.log('gestos', gestos);
        if (gestos.length >= 1) {
          this.resultados = true;
          this.dataSource = new MatTableDataSource(gestos);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;    
        }
        this.flagLoaded = true;
      });
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
    // Filtrar por mesa.titulo, gesto.nombre, usuario.fullName, aprobado y nota
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const mesaTitulo = data.mesa.titulo.toLowerCase().includes(filter);
      const gestoNombre = data.gesto.nombre.toLowerCase().includes(filter);
      const usuarioFullName = data.usuario.fullName.toLowerCase().includes(filter);
      const aprobado = data.aprobado ? 'Aprobado' : 'Desaprobado';
      const nota = data.nota.toString().includes(filter);
  
      return mesaTitulo || gestoNombre || usuarioFullName || aprobado.includes(filter) || nota;
    };
  }

  async elegirMesa(){
    this.dialog.open(ModalElegirMesaExamenComponent, {
      height: '500px',
      width: '700px',
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
