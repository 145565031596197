import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { Catalogo } from '../../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from '../../../../../../services/shared/catalogo.service';

@Component({
  selector: 'app-modalidad-alta',
  templateUrl: './modalidad-alta.component.html',
  styleUrls: ['./modalidad-alta.component.scss']
})
export class ModalidadAltaComponent implements OnInit {

    form: FormGroup;
    catalogo: Catalogo;
    KEY = 'MODALIDAD';

    constructor(
        private router: Router,
        private catalogoService: CatalogoService,
        public guiMsjService: GuiMsjService,
        private perfilesService: PerfilesService,
        ) {
        this.createForm();
        this.getCatalogo();
    }

    ngOnInit(): void {
        this.perfilesService.allowedByUserLogon(this.router.url);
    }

    get conceptoNoValido(){
      return this.form.get('concepto').invalid && this.form.get('concepto').touched;
    }

    async getCatalogo() {

      this.catalogo = await this.catalogoService.getFindByKey(this.KEY);

    }

    createForm() {

      this.form = new FormGroup({
          concepto: new FormControl('', [ Validators.required, Validators.min(3)]),
      });
    }

    async post(){
      await this.guiMsjService.msjFormSubmit('Guardando');
      this.catalogoService.post(await this.setDataForma());
      await this.guiMsjService.msjFormSubmit('DatosGuardados');
      this.router.navigateByUrl('/modalidades-listado');
    }

    async setDataForma(): Promise<Catalogo>{

      const data: Catalogo = {
          key: null,
          concepto: this.form.value.concepto,
          parent: this.catalogo._id,
          properties: {},
      };

      return data;

    }

}

