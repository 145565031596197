<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ idioma | translate: "oferta-laboral.TITULO-ADJ-DOC" | async}} {{this.nombrePais}} - {{nombreActividad}}</h2>
        </div>
    </div>
</div>
<div class="andorra-document-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row  mx-auto">
            <div class="col-lg-9 col-md-12">
                <div class="document-content">
                    <h3>{{ idioma | translate: "oferta-laboral.TITULO-DOC-REQ" | async}} {{nombreActividad}}</h3>
                    <hr>
                    <form>
                        <div class="form-group" *ngFor="let adj of documentosAdjuntar; let i = index">
                            <label [ngStyle]="{'background': this.uploadedDocument.includes(i)?'#28a745':'#ffffff'}" class="form-label">{{adj.nombre}}</label>
                            <input type="file" accept="image/jpeg, image/jpg, image/png,application/pdf" (change)="onFileSelected($event, i, adj); selectedItem = i; ">
                        </div>
                        <button *ngIf="this.flagLoadingAllDocuments" (click)="enviarDocumentacion()">{{ idioma | translate: "oferta-laboral.BTN-ENVIAR-DOC" | async}}</button>
                        <button *ngIf="!this.flagLoadingAllDocuments" disabled>{{ idioma | translate: "oferta-laboral.BTN-ENVIAR-DOC" | async}}</button>
                        <button (click)="cancelar()">{{ idioma | translate: "form.BTN-CANCELAR" | async}}</button>
                        <br>
                        <br>
                        <mat-progress-bar *ngIf='flagEnviando' mode="indeterminate"></mat-progress-bar>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>