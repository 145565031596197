import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { DocumentosEstadosSolicitar } from 'src/app/interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { AgregarDocumentosExtraComponent } from '../solicitud/agregar-documentos-extra/agregar-documentos-extra.component';
import { Solicitud } from '../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { MatDialog } from '@angular/material/dialog';
import { DocumentosService } from 'src/app/services/documentos/documentos.service';
import { Subscription } from 'rxjs';
import { SolicitudesService } from '../../../../services/ofertas-laborales/solicitudes.service';
import { Router } from '@angular/router';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../../../app.config/app.config-ofertas';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';
import { MediaService } from 'src/app/services/shared/media.service';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';

@Component({
  selector: 'app-solicitud-postular-documentacion-incripcion',
  templateUrl: './solicitud-postular-documentacion-incripcion.component.html',
  styleUrls: ['./solicitud-postular-documentacion-incripcion.component.scss']
})
export class SolicitudPostularDocumentacionIncripcionComponent implements OnInit, OnDestroy {

  @Input() documentosEstadosSolicitar: DocumentosEstadosSolicitar[];
  @Input() usuario: User;
  @Input() solicitud: Solicitud;
  @Input() activarCargaDocumentosOpcionales: boolean;

  _documentosSubscription: Subscription;
  flaProcesando = false;
  constructor(
    public dialog: MatDialog,
    private documentosService: DocumentosService,
    private solicitudesService: SolicitudesService,
    private router: Router,
    private guiMsjService: GuiMsjService,
    private mediaService: MediaService,
    @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
  ) {
    this._documentosSubscription = this.documentosService.getDocumentosSolicitar().subscribe(resp => {
      this.next(resp);
    });
  }

  ngOnDestroy(): void {
    this._documentosSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.documentosEstadosSolicitar = this.generaDocumentosSolicitar(this.documentosEstadosSolicitar, this.usuario);
  }


  async next(resp) {

    // 0-Busco solicitud porque le agregue un archivo
    this.solicitud = await this.solicitudesService.getSolicitudSeleccionada();
    // this.solicitud  =  await this.solicitudesService.getSolicitudById(this.solicitud._id);
    // this.solicitudesService.setSolicitudSeleccionada(this.solicitud);

    // 1-ACTUALIZAR LA SOLICITUD -  CON LOS DOCUMENTOS CARGDOS
    this.solicitud =  await this.actualizarSolicitudDocumentoCargado(this.solicitud, resp.idAdjunto);

    // 2-ACTUALIZO SOLICITUD SI ENTREGO TODOS LOS DOCUMENTOS
    await this.actualizarEstadoSolicitudDocumentacionCompleta(this.solicitud);

    // 3-ACTUALIZAR EL ARREGLO
    this.documentosEstadosSolicitar = this.solicitud.documentosEstadosSolicitar;

  }

  openDialogDocUpload(label: string, idAdjunto: string, index: string){
    this.mediaService.incrementarContadorControlUpload();
    this.documentosService.openDialogDocUpload(this.solicitud, idAdjunto, label, index);
  }

  /**
   * Actualiza el documento cargado por el usuario en la solicitud coleccion  documentosEstadosSolicitar
   * @param solicitud
   * @param idAdjunto
   */
  async actualizarSolicitudDocumentoCargado(solicitud: Solicitud, idAdjunto: string): Promise<Solicitud> {
    await this.findDocumentoCargado(solicitud, idAdjunto);
    this.solicitud = await this.solicitudesService.updateSolicitud(solicitud._id , solicitud);
    return this.solicitud;
  }

  async findDocumentoCargado(solicitud, idAdjunto: string): Promise<Solicitud> {
    solicitud.documentosEstadosSolicitar.forEach( elemento => {
      if (elemento.documento._id == idAdjunto) {
        elemento.entregado = true;
      }
    });
    if (solicitud.pais.hasOwnProperty('_id')) { solicitud.pais = solicitud.pais._id; };// pongo el id para que no me rebote el PUT (espera un string)
    if (solicitud.actividad.hasOwnProperty('_id')) { solicitud.actividad = solicitud.actividad._id; };// pongo el id para que no me rebote el PUT (espera un string)
    if (solicitud.usuario.hasOwnProperty('_id')) { solicitud.usuario = solicitud.usuario._id; };// pongo el id para que no me rebote el PUT (espera un string)
    return solicitud;
  }

 controlEntregaTotalDocumentos(solicitud): boolean {
    let flag = false;
    let cont = 0;
    solicitud.documentosEstadosSolicitar.forEach( elemento => {
        if (elemento.entregado == true) {
          cont++;
        }
      });
    if (cont >= solicitud.documentosEstadosSolicitar.length) { flag=true} else{flag=false}
    return flag;
  }


  async actualizarEstadoSolicitudDocumentacionCompleta(solicitud: Solicitud) {
      this.flaProcesando = true;
      if (this.controlEntregaTotalDocumentos(solicitud)){
        if (
            solicitud.lastState.numberState !== this.iAppOfertas.estado_4.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_5.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_6.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_7.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_8.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_9.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_10.number) {
            // tslint:disable-next-line:max-line-length
            this.solicitudesService.updateSolicitudState(solicitud._id, this.iAppOfertas.estado_3.number, false, this.iAppOfertas.estado_3.name, true).then( async (solicitudUpdated: Solicitud) => {
              this.solicitud = solicitudUpdated;
              await this.solicitudesService.setSolicitudSeleccionada(solicitudUpdated);
              this.solicitudesService.setNewState(solicitudUpdated); // CAMBIO ESTADO
              this.router.navigate(['solicitud']);
              this.flaProcesando = false;
              this.guiMsjService.msjFormSubmit('solicitudDocCompleta');
            });
          }

      } else {
        // Si no se han entregado todos los documentos, se verifica el estado anterior de la solicitud
        if (solicitud.lastState.numberState !== this.iAppOfertas.estado_3.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_4.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_5.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_6.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_7.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_8.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_9.number &&
            solicitud.lastState.numberState !== this.iAppOfertas.estado_10.number) {
            // tslint:disable-next-line:max-line-length
            this.solicitudesService.updateSolicitudState(solicitud._id, this.iAppOfertas.estado_2.number, false).then( async (solicitudUpdated: Solicitud) => {
            this.solicitud = solicitudUpdated;
            await this.solicitudesService.setSolicitudSeleccionada(solicitudUpdated);
            this.solicitudesService.setNewState(solicitudUpdated); // CAMBIO ESTADO
            this.router.navigate(['solicitud']);
            this.flaProcesando = false;
          });
        } else {
          this.flaProcesando = false;
        }

      }
  }

  reloadCurrentRoute() {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['solicitud']);
            return;
        });
  }


  generaDocumentosSolicitar(documentosEstadosSolicitar: DocumentosEstadosSolicitar[], usuario: User): DocumentosEstadosSolicitar[] {
    const documentos = [];
    if (documentosEstadosSolicitar.length > 0) {
      documentosEstadosSolicitar.forEach(doc => {
        console.log('controlo con',usuario.adjuntos, doc.documento._id );
        if (!this.solicitudesService.documentosSolicitado(usuario.adjuntos, doc.documento._id)) {
          documentos.push(doc);
        } else  {
          console.log('documento ya cargado..');
        }
      });
    } else {
      console.log('No se encontraron documentos activos' );
    }

    return documentos;
  }


}
