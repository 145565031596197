<div class="container mt-5">
  <!-- Mostrar el mensaje cuando el procesamiento haya terminado -->
  <div style="display: flex; justify-content: center">
    <div class="alert alert-success w-75 p-3 text-center mt-2" role="alert">
      <h4 class="alert-heading">¡Su pago fue aprobado!</h4>
      <p class="text-danger">
        ATENCIÓN: NO es necesario enviar el comprobante de pago, recibirás la FACTURA por email.
      </p>
      <p>Agradecemos su pago a AADIDESS. Una vez que AADIDESS confirme su pago, le notificaremos de inmediato.</p>
      <p>La entidad de pago se pondrá en contacto con usted lo antes posible para informarle sobre el estado de su
        transacción.</p>
      <!-- Mostrar el spinner mientras se está procesando -->
      <div *ngIf="isLoading"
        style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Cargando...</span>
        </div>
        <p>Ahora lo redirigiremos al campus.</p>
      </div>
    </div>
  </div>
</div>