import { Component, Inject, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BoletinesService } from 'src/app/services/cursos/boletines.service';
import { MateriaBoletinDetalle } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin-detalle.interface';

@Component({
  selector: 'app-alumno-boletin-detalle-modal',
  templateUrl: './alumno-boletin-detalle-modal.component.html',
  styleUrls: ['./alumno-boletin-detalle-modal.component.scss']
})
export class AlumnoBoletinDetalleModalComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['materia', 'modalidadCursada', 'modalidadExamen', 'fechaExamen', 'fechaNota', 'notaExamen', 'fechaMateriaEnviada'];
  dataSource: MatTableDataSource<MateriaBoletinDetalle>;
  materias: MateriaBoletinDetalle[] = [];

  constructor(
    private router: Router,
    private boletinesService: BoletinesService,
    private dialogRef: MatDialogRef<AlumnoBoletinDetalleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { materias: MateriaBoletinDetalle[] },
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.materias = this.data.materias;
  }

  async ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.materias);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    
    // Notificar a Angular que se han hecho cambios
    this.cdr.detectChanges();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = `${data.materia.nombre || ''} ${data.modalidadCursada.concepto || ''} ${data.modalidadExamen.concepto || ''} ${data.fechaExamen || ''} ${data.fechaNota || ''} ${data.notaExamen || ''}`.toLowerCase();
      return dataStr.includes(filter);
    };

    this.dataSource.filter = filterValue;

    // Si el filtro es vacío, la tabla mostrará todos los datos
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}