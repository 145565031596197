import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
  selector: 'app-plataforma-guia',
  templateUrl: './plataforma-guia.component.html',
  styleUrls: ['./plataforma-guia.component.scss']
})
export class PlataformaGuia implements OnInit {

  idioma: Subscription;

  constructor(private translatorService: Translators) { 
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });
  }

  ngOnInit(): void {
  }

}
