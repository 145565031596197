import { environment } from './../../../environments/environment';
import { Pipe, PipeTransform } from '@angular/core';

const URL_BACKEND = environment.urlBackend;

@Pipe({
  name: 'mediaOfertaLaboralFileUser'
})
export class MediaOfertaLaboralFileUserPipe implements PipeTransform {
  transform( userid: string, file: string ): string {
    return `${ URL_BACKEND }/media/fileUserOffer/${userid}/${file}`;
  }

}
// EXAMPLE http://192.168.0.103:3002/post/imagen/5ec6bbcb022e2826b8da7b2e/diakdu54kkailuggy.5ec6bbcb022e2826b8da7b2e.jpg
