<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li><a routerLink="/materias-configuracion">Configuración Materia</a></li>
                <li>Materias</li>
            </ul>
            <h2>Materias</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div *ngIf="flagLoaded" class="profile-area pb-70">
    <div class="container">

        <div class="profile-courses-quizzes">
            <h3>Listado de Materias</h3>

            <a routerLink="/materia-alta"><button mat-raised-button mat-button color="primary"><mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon> Nueva Materia</button></a>
            <br>
            <hr>

            <div *ngIf="flagLoaded">

                <mat-form-field>
                    <mat-label>Filtrar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Materia..." autocomplete="off">
                </mat-form-field>

                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                    <!-- Column -->
                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                    </ng-container>


                    <!-- Column -->
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Descripción </th>
                        <td mat-cell *matCellDef="let element"> {{element.description }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="fechaInicio">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Fecha Inicio </th>
                        <td mat-cell *matCellDef="let element"> {{element.fechaInicio | date:"dd/MM/yy"}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="fechaFin">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Fecha Fin </th>
                        <td mat-cell *matCellDef="let element"> {{element.fechaFin | date:"dd/MM/yy"}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="enlace">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Enlace </th>
                        <td mat-cell *matCellDef="let element"> {{element.enlace}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Acciones </th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="verMateria(element._id)" type="button" class="btn btn-warning"><i class="fas fa-edit"></i></button>
                        </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="descargar">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> </th>
                        <td mat-cell *matCellDef="let element">
                            <button class="btn default-btn-style-two"><i class="fas fa-file-download"></i> Solicitud</button>
                        </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="indentificacion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> </th>
                        <td mat-cell *matCellDef="let element">
                            <button class="btn default-btn-style-two" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                <i class="fas fa-qrcode"></i> Tarjeta QR
                            </button>

                            <!-- Modal -->
                            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Identificación</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body text-center">
                                            <img src="assets/img/qr-falso.png" width="50%" alt="codigo-qr"><img class="pt-5" src="assets/img/logo-solapa.png" width="20%" alt="codigo-qr">
                                            <br><br>
                                            <h3><b>TARJETA VÁLIDA PARA LECTURA</b></h3>
                                            <div class="alert alert-secondary" role="alert">
                                                <p> Presenta esta tarjeta, de ser solicitada,<br>para validar el permiso</p>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
        </div>
    </div>
</div>