import { Component, OnInit, Input, Inject } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { APP_CONFIG_OFERTAS, IAppOfertas } from 'src/app/app.config/app.config-ofertas';
import { SolicitudCreate, State } from 'src/app/interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';
import { SolicitudesService } from 'src/app/services/ofertas-laborales/solicitudes.service';
import { PaisesService } from 'src/app/services/shared/paises.service';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
  selector: 'app-boton-tarjeta',
  templateUrl: './boton-tarjeta.component.html',
  styleUrls: ['./boton-tarjeta.component.scss']
})
export class BotonTarjetaComponent implements OnInit {
  @Input() routerLink: string;
  @Input() onLine: boolean;
  idioma: Subscription;
  solicitudCreate: SolicitudCreate;
  state: State ;

  constructor(
    private translatorService: Translators,
    private router: Router,
    @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
  ) {
      this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        this.idioma = resp;
      });
   }

  async ngOnInit() {   
  }

  navegarConParametro(valor: boolean) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        tipoPista: valor // Valor que deseas pasar
      }
    };
    this.router.navigate(['/inscripcion'], navigationExtras);
  }
  

  

}
