import { Inject, Pipe, PipeTransform } from '@angular/core';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../app.config/app.config-ofertas';

@Pipe({
  name: 'detalleEstadosPipe'
})
export class DetalleEstadosPipe implements PipeTransform {

    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
    ){}

    transform( stateNums: number ): string {

        switch (stateNums) {
            case this.iAppOfertas.estado_0.number:
                return this.iAppOfertas.estado_0.detail;
                break;

            case this.iAppOfertas.estado_1.number:
                return this.iAppOfertas.estado_1.detail;

                break;

            case this.iAppOfertas.estado_2.number:
                return this.iAppOfertas.estado_2.detail;
                break;

            case this.iAppOfertas.estado_3.number:
                return this.iAppOfertas.estado_3.detail;
                break;

            case this.iAppOfertas.estado_4.number:
                return this.iAppOfertas.estado_4.detail;
                break;

            case this.iAppOfertas.estado_5.number:
                return this.iAppOfertas.estado_5.detail;
                break;

            case this.iAppOfertas.estado_6.number:
                return this.iAppOfertas.estado_6.detail;

            case this.iAppOfertas.estado_7.number:
                return this.iAppOfertas.estado_7.detail;
                break;

            // case this.iAppOfertas.estado_7.number:
            //     return this.iAppOfertas.estado_7.detail;
            //     break;
            // case this.iAppOfertas.estado_8.number:
            //     return this.iAppOfertas.estado_8.detail;
            //     break;

            // case this.iAppOfertas.estado_9.number:
            //     return this.iAppOfertas.estado_9.detail;
            //     break;

            default:
                return 'NOT DETAIL';
                break;
        }

    }

}
