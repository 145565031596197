<app-loading *ngIf="!dataSource" class="m-5"></app-loading><br>

<div class="profile-area pb-70">
  <div class="container" style="max-width: 100%">
    <h3>Detalle de Materias del Boletin</h3>

    <mat-form-field>
      <mat-label>Filtrar</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Buscar por materia, modalidad..." autocomplete="off">
    </mat-form-field>

    <div class="mat-elevation-z8">
      <br>
      <div *ngIf="!dataSource" class="spinner-container">
        <mat-spinner></mat-spinner>
      </div>        
      
      <table mat-table [dataSource]="dataSource" matSort class="custom-table">
    
        <!-- Column: Materia -->
        <ng-container matColumnDef="materia">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Materia </th>
          <td mat-cell *matCellDef="let element"> {{element.materia.nombre || 'SIN DATOS'}} </td>
        </ng-container>
    
        <!-- Column: Modalidad Cursada -->
        <ng-container matColumnDef="modalidadCursada">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Modalidad Cursada </th>
          <td mat-cell *matCellDef="let element"> {{element.modalidadCursada.concepto || 'SIN DATOS'}} </td>
        </ng-container>
    
        <!-- Column: Modalidad Examen -->
        <ng-container matColumnDef="modalidadExamen">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Modalidad Examen </th>
          <td mat-cell *matCellDef="let element"> {{element.modalidadExamen.concepto || 'SIN DATOS'}} </td>
        </ng-container>

        <!-- Column: Fecha Examen -->
        <ng-container matColumnDef="fechaExamen">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Examen </th>
          <td mat-cell *matCellDef="let element"> {{element.fechaExamen | date: 'dd/MM/yyyy' || 'SIN DATOS'}} </td>
        </ng-container>

        <!-- Column: Fecha Nota -->
        <ng-container matColumnDef="fechaNota">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Nota </th>
          <td mat-cell *matCellDef="let element"> {{element.fechaNota | date: 'dd/MM/yyyy' || 'SIN DATOS'}} </td>
        </ng-container>

        <!-- Column: Nota Examen -->
        <ng-container matColumnDef="notaExamen">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nota Examen </th>
          <td style="text-align: center;" mat-cell *matCellDef="let element"> {{element.notaExamen || 'PENDIENTE'}} </td>
        </ng-container>

        <!-- Column: Fecha Nota -->
        <ng-container matColumnDef="fechaMateriaEnviada">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Materia Enviada </th>
          <td mat-cell *matCellDef="let element"> {{element.fechaMateriaEnviada | date: 'dd/MM/yyyy h:m:s' || 'SIN DATOS'}} </td>
        </ng-container>
    
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="6">No hay registros para mostrar</td>
        </tr>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
      <mat-paginator [pageSizeOptions]="[50, 100, 150, 200]" aria-label="Select page of users"></mat-paginator>
    </div>
  </div>
</div>
