import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagar-saldo',
  templateUrl: './pagar-saldo.component.html',
  styleUrls: ['./pagar-saldo.component.scss']
})
export class PagarSaldoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
