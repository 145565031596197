import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserDocumentoTiposService } from 'src/app/services/shared/user-documento-tipos.service';
import { UserDocumentoTipo } from 'src/app/interfaces/interface-bakend/seguridad/users/user-documento-tipos.interface';
import Swal from 'sweetalert2';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';

@Component({
  selector: 'app-documentos-usuarios-listado',
  templateUrl: './documentos-usuarios-listado.component.html',
  styleUrls: ['./documentos-usuarios-listado.component.scss']
})
export class DocumentosUsuariosListadoComponent implements OnInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
    [
      'nombre', 'descripcion', 'activo', '_id'
    ];
  dataSource: any;
  documentos: UserDocumentoTipo[];
  /* Fin Data Table*/

  flagLoaded = false;


  constructor(
    private userDocumentosTipoService: UserDocumentoTiposService,
    private router: Router,
    private guiMsjService: GuiMsjService
  ) { }

  ngOnInit(): void {
    this.loadDataPage();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
  }

  async verDocumento(_id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const documento: UserDocumentoTipo = await this.userDocumentosTipoService.getUserDocumentoById(_id);
    if (documento !== undefined) {
      this.userDocumentosTipoService.setUserDocumentTipoSeleccionado(documento);
      this.router.navigateByUrl('/documento-usuario-modificacion');
      Swal.close();
    }
    else {
      Swal.close();
      console.error('El documento seleccionado no encontrado');
    }
  }

  async updateActivoFieldAndSave(_idDocument: string, newActiveValue: boolean) {
    try {
      const documento = await this.userDocumentosTipoService.getUserDocumentoById(_idDocument);
      if (documento !== undefined) {
        documento.activo = newActiveValue;
        await this.userDocumentosTipoService.updateUserDocumentosTipo(documento._id, documento);
      } else {
        throw new Error(`Document with id ${_idDocument} not found.`);
      }
    } catch (error) {
      console.error(`Error al actualizar el documento ${_idDocument}:`, error);
    }
  }
  

  async loadDataPage() {
    await this.loadDataTable();
    return;
  }

  async loadDataTable() {
    this.userDocumentosTipoService.getUserDocumentosTipos().then((documentos: UserDocumentoTipo[]) => {
      this.dataSource = new MatTableDataSource(documentos);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



}
