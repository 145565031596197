<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li>Nueva Cátedra</li>
            </ul>
            <h2>Nueva Cátedra</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">

            <div class="col-lg-12 col-md-12 col-xs-12">
                <div class="apply-form">
                    <mat-card class="material-card">

                        <mat-card-header>
                            <mat-card-title>Asignar materias y profesores a cursos</mat-card-title>
                        </mat-card-header>

                        <mat-card-content>
                            <div class="apply-form">
                                <form [formGroup]="form" (ngSubmit)="post()">
                                    <div class="row">

                                        <div class="col-lg-6">

                                            <div class="form-group">
                                                <mat-form-field appearance="fill" [style.width.%]=100>
                                                    <mat-label>Curso</mat-label>
                                                    <mat-select formControlName="curso" name="cursoSeleccion" [(ngModel)]="selectCurso" (ngModelChange)="seleccionarCurso($event)">
                                                        <mat-option *ngFor="let item of cursos" [value]="item._id">{{ item.nombre }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group">
                                                <mat-form-field appearance="fill" [style.width.%]=100>
                                                    <mat-label>Materia</mat-label>
                                                    <mat-select formControlName="materia">

                                                        <mat-option *ngFor="let item of materias" value="{{ item._id }}">{{ item.nombre }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                        </div>

                                        <div class="col-lg-6">

                                            <div class="form-group">
                                                <mat-form-field appearance="fill" [style.width.%]=100>
                                                    <mat-label>Profesor</mat-label>
                                                    <mat-select formControlName="profesor">

                                                        <mat-option *ngFor="let item of profesores" value="{{ item._id }}">{{ item.usuario?.fullName }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="form-group">
                                                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                                    <mat-label>Orden</mat-label>
                                                    <input type="number" formControlName="orden" matInput placeholder="Ingrese orden" autocomplete="off">
                                                </mat-form-field>
                                            </div>

                                        </div>

                                        <button [disabled]="!form.valid || flagProcesando" disabled mat-button color="primary"> <i class="far fa-plus-square"></i> Asignar</button>

                                    </div>

                                </form>

                            </div>

                            <p>Listado de Cátedras</p>

                            <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="example-viewport">

                                <app-loading *ngIf="flagProcesando" class="m-5"></app-loading>

                                <div *ngIf="this.catedras.length == 0">No hay datos para mostrar</div>

                                <table mat-table [dataSource]="catedras" class="mat-elevation-z8">

                                    <!-- Position Column -->

                                    <ng-container matColumnDef="curso">
                                        <th mat-header-cell *matHeaderCellDef> Curso </th>
                                        <td mat-cell *matCellDef="let element"> {{ element.curso.nombre}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="materia">
                                        <th mat-header-cell *matHeaderCellDef> Materia </th>
                                        <td mat-cell *matCellDef="let element"> {{ element.materia.nombre }} </td>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="profesor">
                                        <th mat-header-cell *matHeaderCellDef> Profesor </th>
                                        <td mat-cell *matCellDef="let element"> {{ element.profesor.presentacion }} </td>
                                    </ng-container>

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="orden">
                                        <th mat-header-cell *matHeaderCellDef> Orden </th>
                                        <td mat-cell *matCellDef="let element"> {{ element.ordenMateria }} </td>
                                    </ng-container>

                                    <!-- Symbol Column -->
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef> Borrar </th>
                                        <td mat-cell *matCellDef="let element">

                                            <!--<button (click)="borrarMateria(element._id)" [disabled]="flagProcesando" disabled mat-button color="warn">Borrar</button> -->

                                            <button *ngIf=!flagProcesando (click)="borrarMateria(element._id)" mat-raised-button color="warn">Borrar</button>
                                            <button *ngIf=flagProcesando disabled mat-raised-button color="accent">Borrar</button>

                                        </td>

                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>


                            </cdk-virtual-scroll-viewport>


                        </mat-card-content>

                    </mat-card>



                </div>
            </div>

        </div>
    </div>
</div>