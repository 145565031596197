<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li>Cursos disponibles</li>
            </ul>
            <h2>Cursos disponibles</h2>
            <div class="col-lg-12 col-md-12" *ngIf="cursosDisponibles?.length === 0">
                <div class="courses-info">
                    <p>No hay cursos disponibles en este momento. Por favor, revisa más tarde.</p>
                </div>
            </div>
        </div>       
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf='!this.flagLoaded' class="m-5"></app-loading>

<div class="courses-area ptb-100">

    <div class="container">

        <div class="odemy-grid-sorting row align-items-center">

            <div class="col-lg-6 col-md-6 result-count">
                <p>Encontramos <span class="count">{{cursosDisponibles.length}}</span> cursos disponibles para ti</p>
            </div>
            <!--<div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <select>
                        <option>Default</option>
                        <option>Popularity</option>
                        <option>Latest</option>
                        <option>Price: low to high</option>
                        <option>Price: high to low</option>
                    </select>
                </div>
            </div>-->
        </div>

        <!-- PAGINATOR  -->
        <mat-paginator [length]="cursosDisponibles.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getPaginatorData($event)" aria-label="Select page" showFirstLastButtons="false">
        </mat-paginator>



        <div class="row" class="row animate__fadeIn">
            <div class="col-lg-4 col-md-6" *ngFor="let curso of (cursosDisponibles |
                    slice: lowValue : highValue)" [newspaper]="curso">
                <app-curso-tarjeta [img]="true" [video]="false" [price]="true" [curso]="curso"></app-curso-tarjeta>
            </div>
        </div>


    </div>
</div>
    