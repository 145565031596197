import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';

@Component({
  selector: 'app-profesores-listado',
  templateUrl: './profesores-listado.component.html',
  styleUrls: ['./profesores-listado.component.scss']
})
export class ProfesoresListadoComponent implements OnInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
  [
    'nombre', 'apellido', 'email', 'estado', 'created', '_id'
  ];
  dataSource: any;
  invoices: any[];
  /* Fin Data Table*/

  flagLoaded = false;

  constructor(
    private cursosService: CursosService,
    private usuariosService: UsuarioService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.listarProfesores();
  }

  async verProfesor(id) {
    await this.usuariosService.getFindOne(id);
    this.router.navigateByUrl('/usuario-modificacion');
  }

  async listarProfesores() {
      await this.loadDataTable();
      this.flagLoaded = true;

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async loadDataTable(){
      await this.cursosService.getProfesores().then( (profesores: any[]) => {
          this.formatDataTable(profesores).then( ( data: any[] ) => {
              this.dataSource = new MatTableDataSource(data);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
          });
      });
  }

  formatDataTable( profesores: any[]) {
      return new Promise( resolve => {
        const format: any[] = [];
        let elemento: {
          nombre: string,
          apellido: string,
          email: boolean,
          estado: string,
          created: string,
          _id: string,
        };

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < profesores.length; i++) {
          if (profesores[i].usuario !== null) {
            elemento = {
                nombre: profesores[i].usuario.nombrePersona,
                apellido: profesores[i].usuario.apellidoPersona,
                email: profesores[i].usuario.email,
                estado: profesores[i].usuario.suspendido,
                created: profesores[i].usuario.createdAt,
                _id: profesores[i].usuario._id,
            };
            format.push(elemento);
          }
        }
        resolve(format);
      });

  }

}

