import { NgModule } from '@angular/core';

// Imports
import { ChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { AccordionModule } from 'ngx-accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { DashboardComponent } from '../dashboard/dashboard.component';

@NgModule({
    declarations: [
        DashboardComponent
    ],

    imports: [
      ChartsModule,
      CommonModule,
      AppRoutingModule,
      MaterialModule,
      BrowserAnimationsModule,
      CountUpModule,
      StickyNavModule,
      TabsModule,
      NgxScrollTopModule,
      LightboxModule,
      AccordionModule,
      FormsModule,
      ReactiveFormsModule,
      SharedModule,
      GalleryModule,
    ],
  })

  export class DashboardModule { }
