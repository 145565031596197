import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { PerfilesService } from '../../../../services/perfiles/perfiles.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { AlumnoTitulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { GestosService } from 'src/app/services/cursos/gestos.service';
import { Alumno } from 'src/app/interfaces/interface-bakend/cursos/alumno.interface';
import { BoletinesService } from 'src/app/services/cursos/boletines.service';
import { AlumnoBoletin } from 'src/app/interfaces/interface-bakend/cursos/alumno-boletin.interface';
import { AlumnoBoletinDetalleModalComponent } from '../alumno-boletin-detalle-modal/alumno-boletin-detalle-modal.component';
import { MateriaBoletinDetalle } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin-detalle.interface';

@Component({
  selector: 'app-alumno-boletines-listado',
  templateUrl: './alumno-boletines-listado.component.html',
  styleUrls: ['./alumno-boletines-listado.component.scss']
})
export class AlumnoBoletinesListadoComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['boletinNombre', 'usuarioNombre', 'identificacion', 'created', '_id'];
  dataSource: MatTableDataSource<AlumnoBoletin>;
  loading = true;
  isButtonDisabled: boolean = false;
  boletines: AlumnoBoletin[];

  constructor(
    private cursosService: CursosService,
    private router: Router,
    public dialog: MatDialog,
    private guiMsjService: GuiMsjService,
    private boletinesService: BoletinesService,
  ) { }

  ngOnInit(): void {
    //this.perfilesService.allowedByUserLogon(this.router.url);
    //this.getBoletinesByAlumno();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
  }

  async updateBoletin(boletin: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    await this.boletinesService.getAlumnoBoletinFindOne(boletin);
    this.router.navigateByUrl('/boletin-modificacion');
  }

  async loadDataTable() {
    this.loading = true;  // Iniciar el loading
    await this.boletinesService.getAlumnoBoletines()
      .then((boletines: AlumnoBoletin[]) => {
        this.boletines = boletines;
        this.dataSource = new MatTableDataSource(boletines);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.loading = false;  // Detener el loading
      })
      .catch((error) => {
        this.loading = false;  // Detener el loading
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const filterString = filter.toLowerCase();
      return (
        data.boletin.nombre.toLowerCase().includes(filterString) ||
        data.alumno.usuario.nombrePersona.toLowerCase().includes(filterString) ||
        data.alumno.usuario.apellidoPersona.toLowerCase().includes(filterString) ||
        data.alumno.usuario.identificacion.toLowerCase().includes(filterString)
      );
    };
    this.dataSource.filter = filterValue;
  }

  async loadDataPage() {
    await this.loadDataTable();
  }

  async agregarNuevoBoletin(id: string) {
    const alumno: Alumno = await this.cursosService.getAlumnoById(id);
    this.cursosService.setAlumnoSeleccionado(alumno);
    this.router.navigate(['/boletin-alta']);
  }

  // Filtro el boletin para mostrar las materias en el modal
  filtrarBoletinesModal(id: string): AlumnoBoletin[] {
    return this.boletines.filter(boletin => boletin._id === id);
  }

  // Recibo ID de AlumnoBoletin
  async openDialogVerDetalleBoletin(id: string) {
    const filteredBoletines: AlumnoBoletin[] = this.filtrarBoletinesModal(id);
    const materias: MateriaBoletinDetalle[] = filteredBoletines[0].materias;

    // Seteo en el storage el arreglo de materias del boletin
    this.boletinesService.setMateriasBoletin(materias);
    this.isButtonDisabled = true;
    const dialogRef = this.dialog.open(AlumnoBoletinDetalleModalComponent, {
      width: '1200px',
      height: '600px',
      data: { materias }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isButtonDisabled = false;
    });
  }

  deleteBoletin(id: string) {
    Swal.fire({
      title: '¿Estás seguro de Borrar este Boletin?',
      text: 'Esta acción no se puede deshacer',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.boletinesService.deleteBoletin(id).then(
          () => {
            Swal.fire(
              'Eliminado!',
              'El boletín ha sido eliminado.',
              'success'
            ).then(() => {
              // Navegar después de que el usuario haya visto el mensaje de éxito
              this.router.navigate(['/alumnos-boletines-listado']).then(() => {
                this.loadDataPage();
              });
            });
          },
          (error) => {
            Swal.fire(
              'Error',
              'Hubo un problema al eliminar el boletín.',
              'error'
            );
          }
        );
      }
    });
  }

}
