import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ActividadesService } from '../../../../../services/ofertas-laborales/actividades.service';
import { ActividadDAdjunto } from '../../../../../interfaces/interface-bakend/ofertas-laborales/actividad-adjuntos.interface';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { PerfilesService } from '../../../../../services/perfiles/perfiles.service';

@Component({
  selector: 'app-adjunto-alta',
  templateUrl: './adjunto-alta.component.html',
  styleUrls: ['./adjunto-alta.component.scss']
})
export class AdjuntoAltaComponent implements OnInit {

    myModel = true;
    form: FormGroup;
    estado = true;

    constructor(
        private router: Router ,
        private actividadesService: ActividadesService,
        public guiMsjService: GuiMsjService,
        private perfilesService: PerfilesService,
        ) {
        this.createForm();
    }

    ngOnInit(): void {
        // this.perfilesService.allowedByUserLogon(this.router.url);
    }

    get nombreNoValido(){
      return this.form.get('nombre').invalid && this.form.get('nombre').touched;
    }

    get descripcionNoValida(){
      return this.form.get('descripcion').invalid && this.form.get('descripcion').touched;
    }

    createForm() {
      this.form = new FormGroup({
        nombre: new FormControl('', [ Validators.required]),
        descripcion: new FormControl('', [ Validators.required]),
      });
    }

    async post(){
      await this.guiMsjService.msjFormSubmit('Guardando');
      this.actividadesService.postAdjunto(await this.setDataForma());
      this.router.navigateByUrl('/adjuntos-listado');
      await this.guiMsjService.msjFormSubmit('DatosGuardados');
    }

    async setDataForma(): Promise<ActividadDAdjunto>{

      const data: ActividadDAdjunto = {
        nombre: this.form.value.nombre,
        descripcion: this.form.value.descripcion,
        tipo: 'prueba',
        icon: '',
        extension: '.pdf',
        pesoMaximo: 0,
        situacionMigratoria: 1,
        costoAdministrativo: 0,
        costoPostAprobacion: 0,
      };

      return data;

    }

}

