import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Curso, CursoActividad } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { Actividad } from 'src/app/interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { Pais } from 'src/app/interfaces/interface-bakend/shared/paises/pais.interfaces';
import { PaisesService } from 'src/app/services/shared/paises.service';
import { Translators } from 'src/app/services/shared/translators.service';
import { GuiToolsService } from 'src/app/services/shared/gui-tools.service';
import { MatDialog } from '@angular/material/dialog';
import { APP_CONFIG_SHARED, IAppShared } from 'src/app/app.config/app.config-shared';
import { UserDocumentoTipo } from 'src/app/interfaces/interface-bakend/seguridad/users/user-documento-tipos.interface';
import { UserDocumentoTiposService } from 'src/app/services/shared/user-documento-tipos.service';
import { MediaService } from 'src/app/services/shared/media.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Solicitud, SolicitudActividad, SolicitudCreate, State } from 'src/app/interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { ActivatedRoute, Router } from '@angular/router';
import { SolicitudesService } from 'src/app/services/ofertas-laborales/solicitudes.service';
import { APP_CONFIG_OFERTAS, IAppOfertas } from 'src/app/app.config/app.config-ofertas';
import { Catalogo } from '../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';
import { InscripcionConfirmationDialogComponen } from './inscripcion-confirmation-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormularioInscripcionProcensandoComponent } from './formulario-inscripcion-procensando.component';

@Component({
  selector: 'app-formulario-inscripcion',
  templateUrl: './formulario-inscripcion.component.html',
  styleUrls: ['./formulario-inscripcion.component.scss']
})
export class FormularioInscripcionComponent implements OnInit {

  @Input() data: any[]; // Arreglo de objetos que se muestra en la grilla
  @Output() selectionChanged = new EventEmitter<any[]>(); // Emite los elementos seleccionados
  tipoPista: boolean // Emite los elementos seleccionados
  selectedRows: Set<any> = new Set<any>();
  selectedItemsDelegaciones: any[] = [];

  displayedColumnsPista: string[] = [
    'Curso',
    'Delegación',
    'Descripción',
    'Inscripción Desde',
    'Inscripción Hasta',
    'Seleccionar'
  ];
  displayedColumnsOnline: string[] = [
    'Curso',
    'Fecha',
    'Descripción',
    'Inscripción Desde',
    'Inscripción Hasta',
    'Seleccionar'
  ];

  myForm: FormGroup;
  cursos: Curso[];
  preescripcionesPista: Actividad[];
  modulos: Curso[];
  modulosSeleccionados: Curso[] = [];
  delegaciones: CursoActividad[] = [];
  paises: Pais[] = [];
  usuario: User;
  fullname: string = '';
  email: string;
  identificacion: string;
  enfermedad: boolean;
  tituloSecundario: boolean;
  hospitalizado: boolean;
  nacionalidad: string;
  selectedPais: Pais;
  selectedCurso: boolean = false;
  selectedModulo: boolean = false;
  selectedDelegaciones: Catalogo[];
  selectedCursoDelegacion: CursoActividad[] = [];
  tiposIdentificaciones: Catalogo[] = [];
  tiposIdentificacion: string;
  idioma: Subscription;
  state: State ;
  flagProcesando = false;
  documentosActivosUsuario: UserDocumentoTipo[] = [];
  tableDocuments: object[] = [];

  delegacionesPorModulo: any[][] = [];
  selectedItem: any[] = [];

  selectedRowsArray: Set<any>[] = [];

  loaded = false;
  selected = -1;

  /* Carga de documentacion del usuario*/

  flagDisableButtonApprove: boolean = true;
  flagCargaCorrecta: boolean = false;
  selectedFiles: { [documentId: string]: File[] } = {};
  selectedFileNames: { [documentId: string]: string } = {};
  value: number = 0;
  activeDocumentId: string | null = null;
  fileSelected: boolean = false;
  subscriptionAvance: Subscription;
  solicitudCreate: SolicitudCreate;
  selectedTipoIdentificacion: Catalogo;
  precioModulo: number;

  tieneFechasLibresFlag: boolean = false; // Flag para verificar fechas libres


  // Variables para la barra de progreso
  totalSteps = 9;
  currentStep = 0;
  progressPercentage = 0;

  constructor(
    private fb: FormBuilder,
    private cursosService: CursosService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private actividadesService: ActividadesService,
    private guiMsjService: GuiMsjService,
    private paisesService: PaisesService,
    private translatorService: Translators,
    private userDocumentoTiposService: UserDocumentoTiposService,
    private guiToolsService: GuiToolsService,
    private mediaService: MediaService,
    private catalogoService: CatalogoService, @Inject(APP_CONFIG_SHARED)
    private iAppShared: IAppShared,
    private dialog: MatDialog,
    private router: Router,
    private usarioService: UsuarioService,
    private solicitudesService: SolicitudesService,
    @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
      this.guiToolsService.refreshComponent('/inscripcion-pista');
    });

    this.subscribeAvanceUpload();
    this.mediaService.reiniciarContadorControlUpload();
  }

  async ngOnInit() {
    // Inicializar el array de conjuntos de selecciones para cada tabla
    this.modulosSeleccionados.forEach(() => {
        this.selectedRowsArray.push(new Set<any>());
    });
    this.cargarDatosForm();
  }

  async capturoDatos() {
    this.route.queryParams.subscribe(params => {
      this.tipoPista = params['tipoPista'] === "true"; // Convertir la cadena a booleano
    });
  }


  async cargarDatosForm() {
    await this.createForm();
    this.updateProgress(1);
    await this.getUserLogueado();
    this.updateProgress(2);
    await this.capturoDatos();
    this.updateProgress(3);
    await this.tieneFechasLibres(this.usuario);
    this.updateProgress(4);
    await this.getPaises();
    this.updateProgress(5);
    await this.getTipoIdentificaciones();
    this.updateProgress(6);
    await this.getDocumentosActivos();
    this.updateProgress(7);
    await this.getDelegaciones();
    this.updateProgress(8);
    await this.getPreescripciones();
    this.updateProgress(9);
    await this.getCursos();
    await this.createListenersStateForm();
    this.tieneFechasLibresFlag = await this.tieneFechasLibres(this.usuario);
    this.loaded = true;
    this.updateProgress(10);
    this.mostrarMensajeInformacion();
  }

  mostrarMensajeInformacion() {
    Swal.fire({
        title: 'Información',
        text: 'TE INFORMAMOS QUE LOS CAMBIOS O MODIFICACIONES EN CUANTO A FECHAS Y DELEGACION ELEGIDA EN LA SOLICITUD A LA CUAL TE ESTÁS PREINSCRIBIENDO PODÉS REALIZARLOS SOLO HASTA DIEZ DÍAS ANTES DE LA ACTIVIDAD. PASADO ESTE PLAZO, SI NO ASISTIERAS A LA MISMA PERDERÁS EL ARANCEL ABONADO.',
        icon: 'info',
        confirmButtonText: 'Aceptar',
        showClass: {
            popup: 'animate__animated animate__fadeIn'
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOut'
        }
    });
 }

  createListenersStateForm() {
    this.myForm.valueChanges.subscribe(async status => {
      // console.log(this.myForm);
    });
  }

  async getDocumentosActivos() {
    try {
      const resp: UserDocumentoTipo[] = await this.userDocumentoTiposService.getUserDocumentosTiposActivos();
      this.documentosActivosUsuario = resp;
      this.generateTableContent();
    } catch (error) {
      console.error('Error al obtener los documentos', error);
    }
  }

  generateTableContent() {
    this.tableDocuments = []; // Inicializa o actualiza el arreglo que contiene el contenido de la tabla
    if (this.documentosActivosUsuario.length > 0) {
      this.documentosActivosUsuario.forEach(doc => {
        if (!this.userDocumentoTiposService.documentosSolicitado(this.usuario.adjuntos, doc._id)) {
          this.tableDocuments.push({
            id: doc?._id,
            name: doc.nombre
          });
        } else  {
          console.log('documento ya cargado..');
        }
      });
    } else {
      this.tableDocuments = [{ message: 'No se encontraron documentos activos' }];
    }
  }

  async getDelegaciones() {
    try {
      const resp: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.delegaciones, null);
      this.selectedDelegaciones = resp;
    } catch (error) {
      console.error('Error al obtener las delegaciones', error);
    }
  }

  async getPreescripciones() {
    try {
      const preescripciones = await this.actividadesService.getActividades();

      // Obtener la fecha de hoy en formato YYYY-MM-DD
      const today = new Date().toISOString().slice(0, 10);

      this.preescripcionesPista = preescripciones.filter(preescripcion => {
        // Convertir vigenciaDesde y vigenciaHasta a formato YYYY-MM-DD
        const vigenciaDesde = new Date(preescripcion.vigenciaDesde).toISOString().slice(0, 10);
        const vigenciaHasta = new Date(preescripcion.vigenciaHasta).toISOString().slice(0, 10);

        // Filtrar por pista, vigente, vigenciaDesde y vigenciaHasta
        return preescripcion?.pista === this.tipoPista
          && preescripcion?.vigente === true
          && vigenciaDesde <= today
          && vigenciaHasta >= today;
      });

      if (this.preescripcionesPista.length === 0) {
        console.error('No se encontraron preescripciones');
      }

    } catch (error) {
      console.error('Error al obtener las preescripciones', error);
    }
  }

  async getCursos() {
    const cursos = await this.cursosService.getCursos();
    this.cursos = cursos.filter(curso => curso.pista == this.tipoPista);
    this.modulos = cursos.filter(curso => curso.modulo == true && curso.pista == this.tipoPista);
  }

  async getUserLogueado() {

    this.usuario = await this.authService.getUserLogon();

    this.fullname = this.usuario?.fullName;
    this.email = this.usuario?.email;
    this.enfermedad = this.usuario?.enfermedad;
    this.hospitalizado = this.usuario?.hospitalizado;
    this.nacionalidad = this.usuario?.paisNacimiento?.name_es;
    this.tiposIdentificacion = this.usuario.tipoIdentificacion?.concepto;
    this.tituloSecundario = this.usuario?.tituloSecundario;
    this.selectedPais = this.usuario?.paisNacimiento;

    this.myForm.patchValue({
      enfermedad: this.usuario?.enfermedad,
      numeroDocumento: this.usuario?.identificacion,
      tituloSecundario: this.usuario?.tituloSecundario,
      telefono: this.usuario?.telefono,
      otraFormacion: this.usuario?.otraFormacion,
      nombreApellido: this.usuario?.fullName,
      email: this.usuario?.email,
    });

    if (this.usuario.birth !== undefined) {
      const formattedBirthDate = this.guiToolsService.datePipeTransform(this.usuario.birth, 'yyyy-MM-dd');
      const birthDate = formattedBirthDate ? new Date(formattedBirthDate) : new Date();
      const data = this.guiToolsService.datePipeTransform(birthDate.setDate(birthDate.getDate() + 2), 'yyyy-MM-dd');
      this.myForm.patchValue({
        birth: data
      });
    }

    // myFormtear la fecha antes de asignarla al formulario
    const fechamyFormteada = this.datePipe.transform(this.usuario?.birth, 'yyyy-MM-dd', 'UTC');

    // Asignar la fecha myFormteada al formulario
    this.myForm.patchValue({
      fechaNacimiento: fechamyFormteada
    });
  }

  async tieneFechasLibres(usuario: User): Promise<boolean> {
    return usuario.fechaLibreSolicitudes;
  }

  async getPaises() {
    await this.paisesService.getPaises().then((resp: Pais[]) => {
      this.paises = resp;
    });
  }

  onChangePais(event): void {
    this.selectedPais = this.paises.filter(x => x.name_es === event)[0];
  }

  getTipoIdentificaciones() {
    this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.tiposIdentificaciones,
        this.translatorService.getIdioma()).
        then( (resp: Catalogo[]) => {
            return this.tiposIdentificaciones =  resp;
        });
  }

  getRowClass(row: any): string {
    return this.selectedRows.has(row) ? 'selected-row' : '';
  }


  /*
  *
  *VALIDACIONES
  *
  */

  get paisNacimiento() {
    return this.myForm.get('nacionalidad').invalid
  }

  get fechaNacimiento() {
    return this.myForm.get('fechaNacimiento').invalid
  }

  get telefono() {
    return this.myForm.get('telefono').invalid
  }

  /*
  *
  *FIN VALIDACIONES
  *
  */

  createForm() {
    this.myForm = this.fb.group({
      curso: ['', Validators.required],
      modulos: ['', Validators.required],
      // delegacion: ['', Validators.required],
      nombreApellido: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.required],
      nacionalidad: ['', Validators.required],
      fechaNacimiento: ['', Validators.required],
      tipoIdentificacion: ['', Validators.required],
      numeroDocumento: ['', Validators.required],
      enfermedad: [''],
      tituloSecundario: [''],
      hospitalizado: [''],
      pista: this.tipoPista,
      aceptarTerminos: ['', Validators.requiredTrue],
      dniFile: '',
      tituloFile: '',
      certificadoFile: '',
      cursosDelegacionesSeleccionados: ['', Validators.required]
    });
  }

  async controlDelegacionesRepetidas(selectedRows): Promise<boolean> {
    const moduloRepetido = this.encontrarModuloRepetido(selectedRows);
    if (moduloRepetido && moduloRepetido !== '') {
      this.showAlert(moduloRepetido);
      return true;
    } else {
      console.log("No hay _id repetidos en el arreglo.");
      return false;
    }
  }

  openConfirmationDialog(): void {
    const dialogRef = this.dialog.open(InscripcionConfirmationDialogComponen, {
        width: '80%', // Ajusta el ancho de la modal según sea necesario
        height: '95%', // Ajusta la altura de la modal según sea necesario
        data: { formData: this.myForm.value , tipoPista: this.tipoPista}, // Pasando los datos del formulario al modal
    });

    dialogRef.afterClosed().subscribe(async (result) => {
        if (result === 'confirmar') {

          this.flagProcesando = true;

          // Mostrar el cartel de proceso
          const modalRef = this.modalService.open(FormularioInscripcionProcensandoComponent, { backdrop: 'static', keyboard: false });
          modalRef.componentInstance.titulo = 'Procesando';
          modalRef.componentInstance.mensaje = 'Espere por favor...';

          // Realizar tu proceso aquí, por ejemplo, una llamada HTTP
          this.procesarFormulario().then(()=>{  modalRef.close();  });

        } else {
            console.log('Canceló la confirmación');
        }
    });
 }

 async procesarFormulario(): Promise<void>{

    // CONTROLO DELEGACIONES REPETIDAS
    if (await this.controlDelegacionesRepetidas(this.selectedItemsDelegaciones)) {
      // Detener todo el proceso si se seleccionaron más de una delegación
      this.flagProcesando = false;
      return;
    }

    // CONTROL CANTIDAD SOLICITUDES GENERADAS
    if (await this.yaCursoModulos(this.usuario._id, await this.armarSolicitud(this.selectedItemsDelegaciones))) {
      // Detener todo el proceso si se seleccionaron más de una delegación
      this.flagProcesando = false;
      return;
    }


    // CONTROLO SELECTORES
    if (!await this.controlSelectores()) {
        // Detener todo el proceso si hay un problema con los selectores
        this.flagProcesando = false;
        return;
    }

    // UPDATE USUARIO / CREATE SOLICITUD
    const flagUsuario = await this.usuarioUpdate(await this.setDatamyForm()); // actualizo usuario
    const flagSolicitud: SolicitudCreate =  await this.solicitudesService.create(await this.armarSolicitud(this.selectedItemsDelegaciones));  // creo solicitud
    const flagUpload = await this.uploadFiles(); // subo archivos
    const flagControl = this.controlSolicitudCrear(flagUsuario, flagSolicitud, flagUpload); // controlo proceso

    // CONTROL GRAL
    if (flagControl) {
      this.resetForm();

      Swal.fire({
          title: '¡Formulario Cargado!',
          html: `
            <div style="text-align: left; background-color: #ffe6e6; padding: 10px; border-radius: 5px;">
              <p><strong>¡Gracias por completar el formulario!</strong></p>
              <p>Estamos procesando su solicitud de cursado. Recibirá notificaciones por correo electrónico sobre el avance de su solicitud.</p>
              <p>Lo redirigiremos a su solicitud para que pueda verla. ¡Gracias por su paciencia y colaboración!</p>
              <hr style="border: 1px solid #ff6666; margin: 10px 0;">
              <p style="color: red; font-weight: bold; font-size: 1.2em;">¡¡¡IMPORTANTE!!!</p>
              <p><strong>Para todos los eventos en pista es OBLIGATORIO</strong> presentar antes del cierre de inscripción la ficha médica completa y una foto de tu carnet de obra social o seguro personal.</p>
              <p>Podrás descargar y completar esta documentación en la próxima pantalla.</p>
              <p><strong>Si no entregas esta documentación antes del cierre de la inscripción, se dará de BAJA la misma.</strong></p>
            </div>
          `,
          icon: 'success',
          confirmButtonText: 'Aceptar'
      }).then(async () => {
          // Redirigir al usuario a la página de solicitud
          this.loaded = false;
          await this.solicitudesService.setSolicitudSeleccionada(await this.solicitudesService.getSolicitudById(flagSolicitud._id));
          this.loaded = true;
          this.router.navigateByUrl('/solicitud');
      });


    } else {
      this.guiMsjService.msjFormSubmit('crearSolicitudError');
    }

 }

  async usuarioUpdate(usuarioUpdated: User): Promise<boolean> {
    return new Promise( async resolve => {
      this.usarioService.updateUser(
        this.usuario._id,
        usuarioUpdated
      ).then((usuario: User) => {
        if (usuario) {
          resolve (true);
        } else {
          resolve (false);
        }
      });
    });

  }

  async setDatamyForm(): Promise<User>{

    this.selectedPais = this.paises.filter(x => x.name_es ===  this.myForm.value.nacionalidad)[0];

    const datamyForm: any =
    {
      aceptarTerminos: this.myForm.value.aceptarTerminos,
      enfermedad: this.myForm.value.enfermedad,
      birth: new Date(this.myForm.value.fechaNacimiento).toISOString(),
      otraFormacion: this.myForm.value.otraFormacion,
      paisNacimiento: this.selectedPais,
      pista: this.tipoPista,
      telefono: this.myForm.value.telefono,
      tituloSecundario: this.myForm.value.tituloSecundario,
      hospitalizado: this.myForm.value.hospitalizado,
      fromApp: false,
      fromWeb: true,
      esPersona: true,
    };

    return datamyForm;

 }

  controlSolicitudCrear(flagUsuario, flagSolicitud, flagUpload ): boolean {
    if (flagUsuario && flagSolicitud && flagUpload) {
      return true;
    } else {
      return false;
    }
  }

  async armarSolicitud(modulosSeleccionados: any): Promise<SolicitudCreate> {
    const actividades: SolicitudActividad[] = [];

    // Recorrer los elementos seleccionados de delegaciones para obtener las actividades completas
    this.selectedItemsDelegaciones.forEach(delegacion => {
        const actividad: SolicitudActividad = {
            _id: delegacion._id,
            tipo: delegacion.tipo,
            nombre: delegacion.nombre,
            descripcion: delegacion.descripcion,
            fecha: delegacion.fecha,
            fechaVencimiento: delegacion.fechaVencimiento,
            link: delegacion.link,
            delegacion: delegacion.delegacion,
            cursoNombre: delegacion.cursoNombre,
            seleccionado: delegacion.seleccionado,
            curso: delegacion._idModulo
        };
        actividades.push(actividad);
    });

    // Crear la solicitud con los datos necesarios y el arreglo de actividades obtenido
    const solicitud: SolicitudCreate = {
        pais: this.actividadesService.getActividadSeleccionada().paisDisponible.pais._id,
        actividad: await this.actividadesService.getActividadSeleccionada()._id,
        state: { numberState: this.iAppOfertas.estado_1.number },
        type: this.iAppOfertas.tipo_constantes.name,
        usuario: (await this.authService.getUserLogon())._id,
        cursos: await this.obtenerIdCusosModulos(modulosSeleccionados),
        documentosEstadosSolicitar: await this.actividadesService.getActividadSeleccionada().documentosEstadosSolicitar,
        actividades: actividades, // Agregar el arreglo de actividades completo a la solicitud,
        pista: this.tipoPista,

    };

    // Validar que los arreglos tengan al menos un elemento
    if (solicitud.cursos.length === 0 || solicitud.actividades.length === 0) {
        // Manejar el caso en el que no hay elementos en los arreglos
        console.error('No se pueden crear la solicitud: cursos o actividades vacíos');
        // Aquí puedes lanzar una excepción, mostrar un mensaje de error o tomar alguna otra acción apropiada
        throw new Error('No se pueden crear la solicitud: cursos o actividades vacíos');
    }

    return solicitud;
}


  async obtenerIdCusosModulos(modulosSeleccionados: any): Promise<string[]> {

    const ids: string[] = [];

    // Iterar sobre cada objeto en modulosSeleccionados utilizando forEach
    modulosSeleccionados.forEach((modulo: any) => {
        // Verificar si el objeto tiene la propiedad _idModulo
        if (modulo && modulo._idModulo) {
            // Agregar el valor de _idModulo al arreglo de ids
            ids.push(modulo._idModulo);
        }
    });

    return ids;
  }

  async yaCursoModulos(idUsuario: string, solicitud: SolicitudCreate): Promise<boolean> {
    const objetResultado: any = await this.solicitudesService.controlSolicitudModulosUsuario(idUsuario, solicitud);

    // Verificar si la solicitud tiene algún curso realizado este año
    const cursosEsteAno = objetResultado.cursosRealizados.filter((cursoRealizado: any) => {
        const cursoFecha = new Date(cursoRealizado.solicitudCreatedAt);
        const esCursoEsteAno = cursoFecha.getFullYear() === new Date().getFullYear();
        return solicitud.cursos.includes(cursoRealizado._id) && esCursoEsteAno;
    });

    // Verificar si la solicitud es una excepción para alguno de los cursos
    // const esExcepcion = objetResultado.cursosRealizados.some((cursoRealizado: any) => {
    //     const cursoFecha = new Date(cursoRealizado.solicitudCreatedAt);
    //     const esCursoEsteAno = cursoFecha.getFullYear() === new Date().getFullYear();
    //     if (solicitud.cursos[0] === cursoRealizado._id && esCursoEsteAno) {
    //       if (
    //         cursoRealizado.estadoSolicitud !== this.iAppOfertas.estado_7.number &&
    //         cursoRealizado.estadoSolicitud !== this.iAppOfertas.estado_9.number &&
    //         cursoRealizado.estadoSolicitud !== this.iAppOfertas.estado_8.number
    //         ) {
    //         console.log('=======NO ES excepcion mismo curso y NO aprobado NO Anulada NO Rechazada');
    //         return false;
    //       }

    //       if (
    //         cursoRealizado.estadoSolicitud === this.iAppOfertas.estado_9.number ||
    //         cursoRealizado.estadoSolicitud === this.iAppOfertas.estado_8.number
    //       ) {
    //         console.log('=======ES excepcion mismo curso y NO aprobado');
    //         return true;
    //       }

    //       // tslint:disable-next-line:max-line-length
    //       if (cursoRealizado.estadoSolicitud === this.iAppOfertas.estado_7.number) {
    //         console.log('=======ES excepcion es el mismo curso y  esta aprobado');
    //         return true;
    //       }

    //      } else {
    //       return true;
    //     }
    // });

    // Verificar si la solicitud es una excepción para alguno de los cursos
    const esExcepcion = objetResultado.cursosRealizados.every((cursoRealizado: any) => {
    const cursoFecha = new Date(cursoRealizado.solicitudCreatedAt);
    const esCursoEsteAno = cursoFecha.getFullYear() === new Date().getFullYear();

    if (solicitud.cursos[0] === cursoRealizado._id && esCursoEsteAno) {
      if (
        cursoRealizado.estadoSolicitud !== this.iAppOfertas.estado_7.number &&
        cursoRealizado.estadoSolicitud !== this.iAppOfertas.estado_9.number &&
        cursoRealizado.estadoSolicitud !== this.iAppOfertas.estado_8.number
      ) {
        console.log('=======NO ES excepcion mismo curso y NO aprobado NO Anulada NO Rechazada');
        return false;
      }

      if (
        cursoRealizado.estadoSolicitud === this.iAppOfertas.estado_9.number ||
        cursoRealizado.estadoSolicitud === this.iAppOfertas.estado_8.number
      ) {
        console.log('=======ES excepcion mismo curso y NO aprobado');
        return true;
      }

      if (cursoRealizado.estadoSolicitud === this.iAppOfertas.estado_7.number) {
        console.log('=======ES excepcion es el mismo curso y  esta aprobado');
        return true;
      }
    }

    // Si no es el mismo curso o no es del año actual, no afecta la excepción
    return true;
  });

    console.log('Resultado de esExcepcion:', esExcepcion);

    if (cursosEsteAno.length > 0 && !esExcepcion) {
        // Obtener los nombres de los cursos realizados este año
        const nombresCursos: string[] = [];
        cursosEsteAno.forEach((curso: any) => {
            nombresCursos.push(curso.nombre);
        });

        // Crear el mensaje de alerta con los nombres de los cursos realizados este año
        const mensajeAlerta = `Ya tiene una solicitud con al menos un curso este año de los siguientes seleccionados: ${nombresCursos.join(', ')}. Recuerde que no es posible realizar la solicitud del mismo curso más de una vez en el año.`;

        // Mostrar la alerta con Swal
        await Swal.fire({
            title: 'Cursos Realizados',
            text: mensajeAlerta,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });

        return true;
    } else {
        return false;
    }
  }

  showAlert(moduloRepetido: string) {
    Swal.fire({
      icon: "error",
      title: "Selecciono más de una delegación",
      html: `El módulo con el nombre <strong>${moduloRepetido}</strong> tiene más de una delegación seleccionada`,
      footer: '<span style="color: blue;"><strong>Por favor, seleccione solo una delegación por módulo.</strong></span>',
      showCloseButton: true,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
      }
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
        this.dialog.closeAll();
      }
    });
  }

  encontrarModuloRepetido(grilla: any): string | null {
    const ids = new Set<string>();

    for (const modulo of grilla) {
      if (ids.has(modulo._idModulo)) {
        return modulo.cursoNombre;
      } else {
        ids.add(modulo._idModulo);
      }
    }

    return null;
  }

  /**
  /*
  *
  *GRILLAS
  *
  */

  // Mapea el nombre de las delegaciones por el concepto
  async mapConceptoDelegaciones(delegaciones: CursoActividad[]) {
    try {
      await this.getDelegaciones();
      this.delegaciones = delegaciones.map(delegacion => {
        const delegacionEncontrada = this.selectedDelegaciones.find(catalogo => catalogo._id === delegacion.nombre);
        return {
          ...delegacion,
          nombre: delegacionEncontrada ? delegacionEncontrada.concepto : ''
        };
      });
    } catch (error) {
      console.error('Error al mapear delegaciones', error);
    }
  }

  // Ordena this.delegaciones por nombreCurso
  ordenarDelegaciones(delegaciones: CursoActividad[]) {
    delegaciones.sort((a, b) => {
      const nombreCursoA = a.cursoNombre.toLowerCase();
      const nombreCursoB = b.cursoNombre.toLowerCase();

      if (nombreCursoA < nombreCursoB) {
        return -1;
      }
      if (nombreCursoA > nombreCursoB) {
        return 1;
      }
      return 0;
    });
  }

  seleccionarCurso(cursoSeleccionado: any) {
    const preescripcion: Actividad[] = this.preescripcionesPista.filter(preescripcion => {
      return preescripcion._id == cursoSeleccionado.value;
    });

    if (preescripcion[0]?.cursos?.length > 0) {
      // Reiniciar todos los datos relacionados con las grillas y los elementos seleccionados
      this.selectedModulo = false;
      this.modulosSeleccionados = [];
      this.delegaciones = [];
      this.delegacionesPorModulo = [];
      this.selectedRowsArray = [];
      this.selectedItemsDelegaciones = [];
      // Establecer el nuevo curso seleccionado y cargar las actividades asociadas
      this.selectedCurso = true;
      this.modulos = preescripcion[0].cursos;
      this.actividadesService.setActividadSeleccionada(preescripcion[0]);
    }

  }

  seleccionarModulos(moduloSeleccionado) {

    const modulos : any[] = []; // conservo el arreglo por si se decide que se seleccione mas de un modulo
    modulos.push(moduloSeleccionado.value);

    if (!modulos || modulos.length === 0) {
        this.selectedModulo = false;
        this.modulosSeleccionados = [];
        this.delegaciones = [];
        this.delegacionesPorModulo = [];
        // Limpiar todos los conjuntos de elementos seleccionados
        this.selectedRowsArray = [];
        // Limpiar también los elementos seleccionados en el formulario
        this.myForm.patchValue({
            cursosDelegacionesSeleccionados: []
        });
        return;
    }

    // Lógica para seleccionar módulos y cargar las delegaciones asociadas
    this.modulosSeleccionados = modulos;
    this.selectedModulo = true;

    // Reiniciar delegaciones y delegacionesPorModulo
    this.delegaciones = [];
    this.delegacionesPorModulo = [];

    // Agregar las delegaciones de los módulos seleccionados
    this.modulosSeleccionados.forEach(curso => {
        curso.actividades.forEach(actividad => {
            actividad['cursoNombre'] = curso.nombre;
            actividad._idModulo = curso._id;
            this.delegaciones.push(actividad);
        });

        if (this.tipoPista) {
          this.delegaciones = this.delegaciones.filter(delegacion => delegacion.tipo == 'DELEGACIÓN');
        } else {
          this.delegaciones = this.delegaciones.filter(delegacion => delegacion.tipo == 'FECHAS DISPONIBLES');
        }
    });

    // Iterar sobre los módulos seleccionados
    this.modulosSeleccionados.forEach((modulo, index) => {

        // Filtrar las delegaciones correspondientes a este módulo
        const delegacionesModulo = this.delegaciones.filter(delegacion => delegacion.cursoNombre === modulo.nombre);
        // Agregar las delegaciones filtradas a la lista delegacionesPorModulo
        this.delegacionesPorModulo.push(delegacionesModulo);

        // Inicializar el conjunto de elementos seleccionados para este módulo
        this.selectedRowsArray[index] = new Set();

        // Agregar los elementos seleccionados de este módulo al conjunto correspondiente
        delegacionesModulo.forEach(delegacion => {
            if (delegacion.seleccionado) {
                this.selectedRowsArray[index].add(delegacion);
            }
        });

        // Carga precio
        this.precioModulo = modulo.precio;

    });

    // Actualizar el valor del formulario con los elementos seleccionados de todos los módulos
    this.updateFormValueForAllModules();

    // Llamar al nuevo método para mostrar mensajes
    this.mostrarMensajesPorModuloSeleccionado(moduloSeleccionado.value);
  }
  
  mostrarMensajesPorModuloSeleccionado(moduloSeleccionado): void {
      const moduloNombre = moduloSeleccionado.nombre;

      if (moduloNombre.includes('Examen de Ingreso')) {
        this.mostrarMensaje('Examen de Ingreso', `¡HOLA!
  AL PREINSCRIBIRTE AL EXAMEN DE INGRESO TU VACANTE EN EL MÓDULO PISTA ESTA RESERVADA PARA LA FECHA DE CURSADA CORRESPONDIENTE A ESE EXAMEN.
POR LO TANTO, NO REALICES LA PREINSCRIPCIÓN AL MODULO PISTA HASTA NO APROBAR EL EXAMEN DE INGRESO, DE REALIZARLA LA MISMA SERA RECHAZADA
`);
      } else if (moduloNombre.includes('Clínica Pre Ingreso')) {
        this.mostrarMensaje('Clínica Pre Ingreso', `¡HOLA!
  AL PREINSCRIBIRTE A LA CLÍNICA PRE INGRESO NO ES NECESARIO QUE TE INSCRIBAS AL EXAMEN DE INGRESO, EL MISMO ESTA INCLUIDO CON LA CLINICA.  
  TU VACANTE EN EL MÓDULO PISTA ESTA RESERVADA PARA LA FECHA DE CURSADA CORRESPONDIENTE A ESE EXAMEN.
  POR LO TANTO, TAMPOCO REALICES LA PREINSCRIPCIÓN AL MODULO PISTA HASTA NO APROBAR EL EXAMEN DE INGRESO, DE REALIZARLA LA MISMA SERA RECHAZADA.`);
      } else if (moduloNombre.includes('Pista')) {
        this.mostrarMensaje('Pista', `¡HOLA!
  SOLO PODÉS PREINSCRIBIRTE A ESTA ACTIVIDAD SI CONTAS CON EL MÓDULO TEÓRICO CURSADO Y EL EXAMEN DE INGRESO A PISTA APROBADO.  SI NO CUMPLIS CON ESTOS REQUISITOS TU PREINSCRIPCION SERÁ RECHAZADA.`);
      }
  }

  mostrarMensaje(titulo: string, mensaje: string): void {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'info',
      showConfirmButton: true,
      confirmButtonText: 'Cerrar',
       showClass: {
            popup: 'animate__animated animate__fadeIn'
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOut'
        }
    });
  }


  updateFormValueForAllModules() {
    const selectedItems = this.selectedRowsArray.reduce((accumulator, selectedRows, index) => {
        if (this.modulosSeleccionados.includes(this.modulosSeleccionados[index])) {
            accumulator.push(...Array.from(selectedRows));
        }
        return accumulator;
    }, []);
    this.myForm.patchValue({
        cursosDelegacionesSeleccionados: selectedItems
    });
 }


  getDelegacionesPorModulo(modulo) {
    return this.delegaciones.filter(delegacion => delegacion.cursoNombre === modulo.nombre);
  }

  updateFormValue(index: number) {
      const selectedItems = Array.from(this.selectedRowsArray[index]); // Convertir el conjunto a un array
      this.myForm.patchValue({
          cursosDelegacionesSeleccionados: selectedItems
      });
  }

  selectItem(event: any, element: any, index: number) {
    // Deselecciona todos los elementos del conjunto antes de seleccionar el nuevo
    this.selectedRowsArray[index].clear();

    // Agrega solo el elemento seleccionado al conjunto
    this.selectedRowsArray[index].add(element);

    // Establece el estado seleccionado del elemento
    element.seleccionado = true;

    // Actualiza el conjunto de elementos seleccionados en la lista general
    this.selectedItemsDelegaciones[index] = element;

    // Emite el evento de selección
    this.emitSelection();

    // Actualiza el valor del formulario solo con el elemento seleccionado
    this.updateFormValue(index);
  }

  isSelected(element: any, index: number): boolean {
      // Comprueba si el elemento está en el conjunto de elementos seleccionados
      return this.selectedRowsArray[index].has(element);
  }

  emitSelection() {
    this.selectionChanged.emit(this.selectedItemsDelegaciones); // Emite la lista de elementos seleccionados
  }

    /**
  /*
  *
  *FIN GRILLAS
  *
  */

  resetForm() {
    this.selectedRows.clear();
    this.selectedItemsDelegaciones = [];
    this.delegaciones = [];
    this.selectedCurso = false;
    this.selectedModulo = false;
  }

  controlSelectores(): boolean {
    let flag = true;
    if (this.selectedPais == null && this.myForm.value.nacionalidad  == null) {
      alert('seleccione una opción PAIS correcta', );
      this.myForm.controls['nacionalidad'].setValue('');
      flag = false;
    } else {
      this.selectedPais = this.paises.filter(x => x.name_es === this.myForm.value.nacionalidad)[0];
    }
    return flag;
  }

  /*
  *
  *CARGA DOCUMENTACIÓN
  *
  */

  openFileInput(documentId: string) {
    this.activeDocumentId = documentId;
    const input = document.getElementById('i' + documentId) as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  clearSelectionFile() {
    this.selectedFiles = {};
    this.selectedFileNames = {};
    this.activeDocumentId = null;
  }

  onFilesSelected(event: Event, documentId: string) {
    const fileInput = event.target as HTMLInputElement;
    const files: FileList = fileInput.files;

    if (!files || files.length === 0) {
      // No se encontró archivo
      this.clearSelectionFile();
      this.fileSelected = false;
      this.activeDocumentId = null;
      return;
    }

    const fileList: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      if (this.mediaService.controlSize(event)) {
        fileList.push(file);
      } else {
        alert('Archivo demasiado grande, seleccione un archivo de menos de 4MB');
        this.clearSelectionFile();
        this.flagDisableButtonApprove = true;
        this.fileSelected = false;
        return;
      }
    }

    this.selectedFiles[documentId] = fileList;
    this.selectedFileNames[documentId] = fileList[0].name;
    this.flagDisableButtonApprove = false;
    this.fileSelected = true;
  }

  async uploadFiles(): Promise<boolean> {
    let success = true; // Variable para almacenar el estado de carga de archivos

    for (const documentId in this.selectedFiles) {
      if (this.selectedFiles.hasOwnProperty(documentId)) {
        const selectedFiles = this.selectedFiles[documentId];

        for (const selectedFile of selectedFiles) {
          try {
            await this.uploadDocumentoAdjunto(selectedFile, documentId); // Carga de archivo
          } catch (error) {
            console.error('Error al cargar el archivo:', error);
            success = false; // Si hay un error, establece success como false
          }
        }
      }
    }

    return success; // Devuelve el estado de éxito de la carga de archivos
  }

  uploadDocumentoAdjunto(selectedFile: File, documentId: string) {
    this.mediaService.uploadUserFromWeb(
      selectedFile, this.usuario._id,
      documentId
    ).then(async resp => {
      if (resp) {
        if (this.value === 100) {
          this.flagCargaCorrecta = true;
          console.log('DOCUMENTOS CARGADOS');
          // this.guiMsjService.msjFormSubmit('uploadDocumentOK');
        }
      } else {
        console.log('DOCUMENTOS NO CARGADOS');
        this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
      }
    });
  }

  subscribeAvanceUpload() {
    this.subscriptionAvance = this.mediaService
      .getAvance()
      .subscribe((resp) => {
        if (resp) {
          this.value = resp.value;
        } else {
          this.value = 0;
        }
      });
  }


  onChangeTipoIdentificacion(event): void {
    this.selectedTipoIdentificacion = this.tiposIdentificaciones.filter(x => x.concepto === event)[0];
  }

    /*
  *
  *FIN CARGA DOCUMENTACIÓN
  *
  */

  transform(value: number): string {
    // Formatear el número como precio
    return '$' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  isDateOutOfRange(date: string): boolean {
    const today = new Date();
    const dateObj = new Date(date); // Convertir la cadena de texto a un objeto Date
    const dateMilliseconds = dateObj.getTime();
    const todayMilliseconds = today.getTime();
    return dateMilliseconds < todayMilliseconds;
  }

  isRadioButtonDisabled(element: any): boolean {
    console.log('=======isRadioButtonDisabled', this.tieneFechasLibresFlag);
    if (this.tieneFechasLibresFlag) {
      return false;
    }
    const today = new Date();
    return new Date(element.fecha) < today && new Date(element.fechaVencimiento) < today;
  }

  updateProgress(step: number) {
    this.currentStep = step;
    this.progressPercentage = Math.round((this.currentStep / this.totalSteps) * 100);
  }




}





