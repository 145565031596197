<div class="page-title-area">
  <div class="container">
      <div class="page-title-content">
          <ul>
              <li><a routerLink="/">Inicio</a></li>
              <li><a routerLink="/examenes-alumno">Mis Examenes</a></li>
              <li *ngIf="evaluacion">{{examenEvaluado.titulo}}</li>
          </ul>
          <h2>Examen Evaluación Resultado</h2>
      </div>
  </div>
  <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="features-area pb-70 mt-5 animate__animated animate__fadeIn">  
  <div class="container" *ngIf="evaluacion">
  
      <mat-card>
          <mat-card-header>
          <div mat-card-avatar></div>
          <div class="example-button-row" align="end">
            <mat-spinner *ngIf="cargandoAlumno"></mat-spinner>
          </div>
          <ng-container *ngIf="alumno">
            <p>Alumno:</p>
            
            <ng-container *ngIf="alumno.esPersona === true; else elseTemplate">
              <p style="margin-right: 5px;"><strong>{{alumno.fullname}}</strong></p>
            </ng-container>
            <ng-template #elseTemplate>
              <p style="margin-right: 5px;"><strong>{{ alumno.nombrePersona ? alumno.nombrePersona : ''}} {{ alumno.apellidoPersona ? alumno.apellidoPersona : ''}}</strong></p><br>
            </ng-template>
            
            <br>
            <p><strong>{{ alumno.email }}</strong></p>
          </ng-container>
          <mat-card-title>Examen: {{examenEvaluado.titulo}}</mat-card-title>
          <mat-card-subtitle>Descripción: {{examenEvaluado.descripcion}}
              <p>{{evaluacion.recuperatorio ? 'Estado Recuperatorio' : 'Estado Examen'}}: <strong>{{evaluacion.examenFinalizado ? 'Finalizado' : 'Incompleto'}}</strong></p>
              <p><strong>{{evaluacion.examenSinTiempo ? 'TimeOut - Error' : ''}}</strong></p>
              <p>Puntaje de aprobación: <strong>{{examenEvaluado.porcentajeAprobacionMinimo ? examenEvaluado.porcentajeAprobacionMinimo : ''}}</strong></p>
              <p>Puntaje Obtenido: <strong>{{ evaluacion.puntajeObtenido ? evaluacion.puntajeObtenido : 'SIN PUNTAJE' }}</strong></p>
              <p><strong>{{evaluacion.recuperatorio ? 'Es Recuperatorio' : ''}}</strong></p>
          </mat-card-subtitle><br>
          
          </mat-card-header>
          <mat-divider></mat-divider>
          <br>
          <h4>Preguntas</h4>
          <mat-card-content *ngIf="examenEvaluado">
              <br>
              <ng-container *ngFor="let pregunta of examenEvaluado.preguntas">

                  <mat-accordion>
                      <mat-expansion-panel hideToggle>
      
                      </mat-expansion-panel>
                      <mat-expansion-panel (opened)="panelOpenState = true"
                                           (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <strong>{{pregunta.enunciado}}</strong>
                          </mat-panel-title>
                          <mat-panel-description>
                            <p>Puntaje: <strong> {{pregunta.puntajeCalculado | number : '1.2-2'}}</strong></p>
                            <!--<p style="margin-left: 5px;" *ngIf="pregunta.tipo === 'MC'">Tipo pregunta: <strong> (Multiple Choice)</strong></p>
                            <p style="margin-left: 5px;" *ngIf="pregunta.tipo === 'VF'">Tipo pregunta: <strong> (Verdadero o Falso)</strong></p>-->
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" *ngIf="pregunta.tipo === 'MC'">Respuestas</th>
                              <th scope="col" *ngIf="examenEvaluado.mostrarRespuestaCorrectas">Correcta</th>
                              <th scope="col">Seleccionado (Mi respuesta)</th>
                            </tr>
                          </thead>
                          <tbody>
                              <ng-container *ngFor="let respuesta of pregunta.respuestas; let i = index">
                                <tr>
                                  <th scope="row">{{i+1}}</th>
                                  <td *ngIf="pregunta.tipo === 'MC'">{{respuesta.texto}}</td>
                                  <ng-container *ngIf="pregunta.tipo === 'VF'; else elseTemplate">
                                    <td *ngIf="examenEvaluado.mostrarRespuestaCorrectas">{{respuesta.correcta ? 'Verdadero' : 'Falso'}}</td>
                                    <td>{{respuesta.seleccionado ? 'Verdadero' : 'Falso'}}</td>
                                  </ng-container>
                                  <ng-template #elseTemplate>
                                    <td *ngIf="examenEvaluado.mostrarRespuestaCorrectas">{{respuesta.correcta ? 'si' : 'no'}}</td>
                                    <td>{{respuesta.seleccionado ? 'si' : 'no'}}</td>
                                  </ng-template>   
                              </tr>
                              </ng-container>
                              
                          
                          </tbody>
                        </table>
                      </mat-expansion-panel>
                    </mat-accordion>

                    <br>

              </ng-container>
          
              <p>Puntaje Obtenido: <strong>{{ evaluacion.puntajeObtenido ? evaluacion.puntajeObtenido : 'SIN PUNTAJE' }}</strong></p>
              <br>
              <a mat-stroked-button [routerLink]="['/examenes-alumno']">Volver a Mis Examenes</a>
          </mat-card-content>
          <mat-card-actions>
          </mat-card-actions>
      </mat-card>
  </div>
</div>