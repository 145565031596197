import { Component, INJECTOR, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { GuiToolsService } from '../../services/shared/gui-tools.service';
import { ConfigBeginService } from '../../services/shared/config-begin.service';
import Swal from 'sweetalert2';
import { Translators } from 'src/app/services/shared/translators.service';
import { APP_CONFIG, IAppConfig } from '../../app.config/app.config';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { MatDialog } from '@angular/material/dialog';
import { TermsConditions } from './terms-conditions/terms-conditions.component';
import { Register } from 'src/app/interfaces/interface-frontend/register';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { OpenPageService } from '../../services/shared/open-page.service';

@Component({
    selector: 'app-profile-authentication',
    templateUrl: './profile-authentication.component.html',
    styleUrls: ['./profile-authentication.component.scss'],
})
export class ProfileAuthenticationComponent implements OnInit {

    loginForm: FormGroup;
    registerForm: FormGroup;
    loadingForm = false;
    loading: Subscription;
    fieldTextType: boolean;
    idioma: Subscription;
    flagLoaded = false;
    siteKey: string;
    flagLoadedModal = true;
    showPasswordLogin: boolean;
    showPasswordRegister: boolean;
    showPasswordConfirm: boolean;
    concepto: string;
    check:boolean = false;
    flagPreCarga = false;
    flagExisteIdentificacion = false;
    selectedTipoIdentificacion: Catalogo;
    flagFormaValido = false;
    idPasaporte : string;
    isDisabled: boolean = true;
    logoUrl: string = null;

    constructor(
        private authservice: AuthService,
        private configBeginService: ConfigBeginService,
        private guiToolsService: GuiToolsService,
        private translatorService: Translators,
        @Inject(APP_CONFIG) private iAppConfig: IAppConfig,
        public guiMsjService: GuiMsjService,
        public dialog: MatDialog,
        private catalogoService : CatalogoService,
        private usuariosService: UsuarioService,
        private openPageService: OpenPageService,
    ) {
        this.loading = this.guiToolsService.getLoading().subscribe(resp => {
            this.loadingForm = resp;
        });

        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
        });

        this.createforms();

    }

    async ngOnInit() {   
        this.logoUrl = (await this.configBeginService.getConfiguracionGral()).urlLogo;
        console.log( this.logoUrl);
        this.setSiteKey(this.iAppConfig.recaptcha.siteKey);
        this.setIdPasaporte(this.iAppConfig.dniid);
        await this.authservice.getCheck$().subscribe((acepta: boolean) => {
            this.check = acepta;
        });
        this.createListenersStateForm();
    }

    setSiteKey(key: string) {
        this.siteKey = key;
    }

    setIdPasaporte(id: string) {
        this.idPasaporte = id;
    }

    useLanguage(idioma: string){
        this.translatorService.setIdiomaSeleccionado(idioma);
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    get emailAccess(){
        return this.loginForm.get('email').invalid && this.loginForm.get('email').touched;
    }

    get passwordAccess(){
        return this.loginForm.get('password').invalid && this.loginForm.get('password').touched;
    }

    get emailNoValido(){
        return this.registerForm.get('email').invalid && this.registerForm.get('email').touched;
    }

    get passwordNoValido(){
        return this.registerForm.get('password').invalid && this.registerForm.get('password').touched;
    }

    get confirmPasswordNoValido(){
        const pass1 = this.registerForm.get('password').value;
        const pass2 = this.registerForm.get('confirmPassword').value;
        return ( pass1 === pass2 ) ? false : true;
    }

    get getNombreNoValido(){
        return this.registerForm.get('nombrePersona').invalid && this.registerForm.get('nombrePersona').touched;
    }

    get getApellidoNoValido(){
        return this.registerForm.get('apellidoPersona').invalid && this.registerForm.get('apellidoPersona').touched;
    }

    get getIdentificacionNoValida(){
        return this.registerForm.get('identificacion').invalid && this.registerForm.get('identificacion').touched;
    }


    async login() {


        if (this.loginForm.invalid){

            return Object.values( this.loginForm.controls ).forEach( control => {
                control.markAsTouched();
            } );
        }

        this.authservice.login(
            this.loginForm.value.email,
            this.loginForm.value.password
        );

        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();

    }

  createListenersStateForm() {

  
        return this.registerForm.statusChanges.subscribe( async status => {
            if (status === 'VALID') {
                this.flagFormaValido = true;
            } else {
              this.flagFormaValido = false;
            }
        });
    }

    async registerUser() {

        if (this.registerForm.invalid){

            return Object.values( this.registerForm.controls ).forEach( control => {
                control.markAsTouched();
            } );
        }

        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        await this.authservice.registerUser(await this.setDataForma());

    }

    async setDataForma(): Promise<Register>{

        const data: Register = {
          email: this.registerForm.value.email,
          emailConfirm : this.registerForm.value.email,
          password : this.registerForm.value.password,
          passwordConfirm : this.registerForm.value.confirmPassword,
          nombrePersona : this.registerForm.value.nombrePersona,
          apellidoPersona : this.registerForm.value.apellidoPersona,
          tipoIdentificacion : this.idPasaporte,
          identificacion : this.registerForm.value.identificacion,       
          aceptaTerminos : this.registerForm.value.aceptaTerminos,
          proteccionDeDatos : this.registerForm.value.proteccionDeDatos,
          clausula : this.registerForm.value.clausula,
          consentimiento : this.registerForm.value.consentimiento,
          fromWeb: true,
          fromApp: false
        };

        return data;

    }
    // CREACION DE FORMULARIOS

    async createforms() {
        await this.createFormLogin();
        await this.createFormRegister();
        this.flagLoaded = true;
    }

    createFormLogin() {
        this.loginForm = new FormGroup({
            // email: new FormControl('ramossistemas@gmail.com', [ Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'), ]),
            // password: new FormControl('svBpIYfd', [ Validators.required, Validators.minLength(8) ]),
            email: new FormControl('', [ Validators.required, ]),
            password: new FormControl('', [ Validators.required, Validators.minLength(8) ]),
        });

    }

    createFormRegister() {
        this.registerForm = new FormGroup({
            email: new FormControl('', [ Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'), ]),
            password: new FormControl('', [ Validators.required, Validators.minLength(8), Validators.pattern(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)]),
            confirmPassword: new FormControl('', [ Validators.required, Validators.minLength(8) ]),
            aceptaTerminos: new FormControl('',Validators.requiredTrue),
            proteccionDeDatos: new FormControl('',Validators.requiredTrue,),
            clausula: new FormControl(false,),
            consentimiento: new FormControl('',Validators.requiredTrue),
            nombrePersona: new FormControl('', [ Validators.required, Validators.minLength(3) ]),
            apellidoPersona: new FormControl('', [ Validators.required, Validators.minLength(3) ]),
            identificacion: new FormControl('',  [ Validators.required, Validators.minLength(8), Validators.maxLength(15)]),
            recaptcha: new FormControl('', Validators.required)
        });
    }

    verTerminos(){
        this.openDialog();
    }

    openDialog() {
        const dialogRef = this.dialog.open(TermsConditions,{

            width: '900px',
            height: '800px',
            disableClose: true,

        });
        dialogRef.afterClosed().subscribe(async result => {});
        return;
      }

    openBlank(type: string) {
    this.openPageService.openBlank(type);
    }

}
