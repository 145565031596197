import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Solicitud } from '../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '../../interfaces/interface-bakend/response';
import { GuiMsjService } from './gui-msj.service';
import { Payments } from '../../interfaces/interface-bakend/payments/payments.interfaces';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
      private authService: AuthService,
      private httpClient: HttpClient,
      private guiMsjService: GuiMsjService,
  ) { }

  async postPyaments(payments: Payments): Promise<Solicitud> {
    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    return new Promise( resolve => {
        this.httpClient.post<Response>(`${ URL_BACKEND }/payments`, payments , { headers }).subscribe( resp => {
            resolve(resp.response);
        }, error => {
            console.log('error', error);
            this.guiMsjService.msjFormSubmit('NotifyServiceFailed');
        });
    });
  }

  loadPayment(tipo: string, solicitud: Solicitud, data: any): Payments {
    let payment: Payments ;

    if (solicitud.actividad.circuito === 1 ){
      payment = {
        tipo,
        payment: {
          invoice: solicitud.invoices[1]._id,
          usuario: solicitud.usuario._id,
          observaciones: solicitud.actividad.nombre,
          importe: solicitud.saldo,
          result: data,
        }
      };
    }

    if (solicitud.actividad.circuito === 2 ){
     payment = {
        tipo,
        payment: {
          usuario: solicitud.usuario._id,
          observaciones: solicitud.actividad.nombre,
          importe: solicitud.saldo,
          result: data,
        }
      };
    }

    return payment;
  }

  getPaymentBySolicitud(invoice: string): Promise<any> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/payments/findByInvoice/${invoice}`, {}).subscribe(resp => {
        resolve(resp.response);
      });
    });
  }
}
