
<div class="container">
    <app-loading *ngIf="!flagLoaded" class="m-5"></app-loading>
    <div *ngIf="flagLoaded" class="products-details-desc">
        <h3>Depósito o Transferencia Bancaria:</h3>
        <div class="alert alert-warning" role="alert" *ngIf="this.getMercadoPago()">   
            <p class="text-info font-weight-bold" >
                ATENCIÓN: Si realizas el pago con Mercado Pago, no es necesario subir el comprobante de transferencia.
                Este paso es solo para operaciones bancarias.
            </p>     
        </div>
    
        <p>
            Para cancelar tu saldo, utiliza nuestra opción <strong>'ENVIAR COMPROBANTE DE TRANSFERENCIA'</strong>. Una vez realizado el pago potr TRANSFERENCIA, sube el comprobante. Verificaremos el comprobante y activaremos los módulos de inscripción, lo cual te será comunicado por correo electrónico. Es necesario enviar un escaneo o foto legible del cupón de depósito o transferencia bancaria, con el número de operación claramente visible; de lo contrario, se rechazará. El comprobante de depósito debe tener el sello de pago y número de comprobante visibles.
        </p>
        
        <button (click)="openDialogComprobante()" class="default-btn">ENVIAR COMPROBANTE DE TRANSFERENCIA</button>
        <br>
        <br>
    </div>

    <mat-divider></mat-divider>

    <!-- <div class="products-details-desc">
        <h3>Otras opciones de pago</h3>
        <h4>*A través de Tarjeta de Crédito:</h4>
        <p>
            VISA o MASTERCARD HASTA 3 CUOTAS SIN INTERÉS –  6, 9 o 12 cuotas (con recargo). El pago con tarjeta de crédito se realiza telefónicamente con nuestras oficinas. Ver teléfonos de contacto. *Efectivo o cheque: En la oficina de Bariloche podrá abonar con
            efectivo, Cheques personales, Tarjeta de débito o Tarjeta de Crédito Visa o Mastercard. Para consultas dirigirse por teléfono al (0294) 4428789 o por mail a central@aadidess.com
        </p>
        <mat-divider></mat-divider>
        <br>
        <h4>*A través de Efectivo o cheque:</h4>
        <p>En la oficina de Bariloche podrá abonar con efectivo, Cheques personales, Tarjeta de débito o Tarjeta de Crédito Visa o Mastercard. Para consultas dirigirse por teléfono al (0294) 4428789 o por mail a central@aadidess.com</p>
        <br>
    </div> -->
</div>