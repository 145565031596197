import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { Curso } from '../../../../interfaces/interface-bakend/cursos/curso.interface';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-cursos-listado',
  templateUrl: './cursos-listado.component.html',
  styleUrls: ['./cursos-listado.component.scss']
})
export class CursosListadoComponent implements OnInit, AfterViewInit {

  /* Data Table*/

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
  [
    //'nombre', 'introduccion', 'descripcion', 'fechaInicio', 'fechaFin', 'precio', 'disponible', '_id'
    'nombre', 'introduccion', 'fechaInicio', 'fechaFin', 'precio', 'disponible', 'pista', '_id','probar' 
  ];
  dataSource: any;
 // cursos : Curso [];

  /* Fin Data Table*/

  flagLoaded = false;
  cursoDisponible: string;

  constructor(
    private cursosService : CursosService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    await this.verCursos();
  }

  async verCursos() {
    this.flagLoaded = false;
    this.loadDataTable();
  }

  async loadDataTable(){

    await this.cursosService.getCursos().then( (cursos : Curso[]) =>{
      this.formatDataTable(cursos).then( ( data: Curso[] ) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.flagLoaded = true;
      });
      
    });

  }

  formatDataTable( cursos: Curso[]) {
    return new Promise( resolve => {
      const format: any[] = [];
      let elemento: {
        _id: string,
        nombre: string,
        introduccion: string,
        descripcionGeneral: string,
        fechaInicio: Date,
        fechaFin: Date,
        precio: number,
        disponible: string,
        pista: boolean,
        curso: Curso
      };
       // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < cursos.length; i++) {


        if(cursos[i].disponible){
          this.cursoDisponible= 'Sí';
        }else{
          this.cursoDisponible= 'No';
        }

        elemento = {
          _id: cursos[i].id,
          nombre: cursos[i].nombre,
          introduccion: cursos[i].introduccion,
          descripcionGeneral: cursos[i].descripcionGeneral,
          fechaInicio: cursos[i].fechaInicio,
          fechaFin: cursos[i].fechaFin,
          precio: cursos[i].precio,
          disponible: this.cursoDisponible,
          curso: cursos[i],
          pista: cursos[i].pista
      };
        format.push(elemento);
      }
      resolve(format);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

async editarCurso(cursoSeleccionado: Curso) {
  //const cursoSeleccionado: Curso = await this.cursosService.getCursoFindOne(idCurso);
  //await this.setMateria(materia);
  await this.cursosService.setCursoSeleccionado(cursoSeleccionado);
  this.router.navigateByUrl('/curso-modificacion');
}

async probarCurso(cursoSeleccionado: Curso) { 
  await this.cursosService.setCursoSeleccionado(cursoSeleccionado);
  this.router.navigateByUrl('/curso-habilitado-alumno');
}

}
