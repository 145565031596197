<div class="row">
    <div class="col-lg-12">
        <div class="bg-white rounded-lg shadow-sm p-5">
            <!-- Credit card form tabs -->
            <!-- <ul role="tablist" class="nav bg-light nav-pills rounded-pill nav-fill mb-3 color">
                <li class="nav-item">
                    <a data-toggle="pill" class="nav-link active rounded-pill"><i class="fa fa-credit-card"></i>
                        {{ idioma | translate: "pagos.PAGO-TITULO" | async}}
                            </a>
                </li>
            </ul> -->
            <!-- End -->

            <div *ngIf="!this.activar">
                <!-- PARA PONER UNA DESCRIPCION DEL PAGO -->
                <!-- <p>{{ idioma | translate: "pagos.PAGO-DESCRIPCION" | async}}</p> -->
                <div class="features-area pt-100 pb-70 bg-fff8f8">
                    <div class="container">
                        <div class="section-title">
                            <span class="sub-title">{{ idioma | translate: "pagos.ATENCION-ANTES-DE-SU-PAGO" | async}} </span>
                            <h2>{{ idioma | translate: "pagos.CORROBORE-TARJETA" | async}}</h2>
                            <p>{{ idioma | translate: "pagos.TEXTO-TARJETA" | async}}</p>
                            <h2>{{ idioma | translate: "pagos.OPCION-PAYPAL" | async}}</h2>
                            <p>{{ idioma | translate: "pagos.OPCION-PAYPAL-TEXTO" | async}}</p>
                            <!-- <h2>Si tú pago es rechazado</h2>
                            <p><strong>Recomendamos los sigueinte, para una soluci&oacute;n</strong></p>
                            <ul>
                                <ol>
                                    <li style="text-align: left;">Ve a la p&aacute;gina web de PayPal</li>
                                    <li style="text-align: left;">Inicia sesi&oacute;n con tu usuario y contrase&ntilde;a, o crea uno</li>
                                    <li style="text-align: left;">Toca sobre &ldquo;Asociar Tarjeta&rdquo; en el men&uacute; superior de la pantalla</li>
                                    <li style="text-align: left;">Aparecer&aacute; una pantalla con datos a rellenar</li>
                                    <li style="text-align: left;">Rellena los datos para vincular una tarjeta </li>
                                    <li style="text-align: left;">Toca sobre el bot&oacute;n &ldquo;Asociar Tarjeta"&nbsp;</li>
                                    <li style="text-align: left;">Para finalizar debes confirmar tu tarjeta : Te consultara&nbsp; &iquest;Quieres utilizar tu cuenta de PayPal sin l&iacute;mites de gasto o retirada?</li>
                                    <li style="text-align: left;">Hace Click en "Confirme su tarjeta"</li>
                                    <li style="text-align: left;">Luego Click en en "Obtener C&oacute;digo"</li>
                                    <li style="text-align: left;">Paypal enviara un c&oacute;digo, que deber&iacute;a aparecer en el estado de cuenta de su tarjeta, en un plazo de 2 a 3 d&iacute;as h&aacute;biles.&nbsp;</li>
                                    <li style="text-align: left;">Regresa a Paypal carga el c&oacute;digo, e intenta tu pago nuevamente pagando con tu cuenta de PayPal y tarjeta asociada.</li>
                                </ol>
                            </ul> -->
                        </div>
                    </div>
                </div>
                <div class="payment-method">
                    <div class="page-title-area">
                        <div class="container">
                            <div class="page-title-content">
                                <h2>
                                    <a routerLink="/"><img src="assets/img/logo-Paypal.png" alt="ski-andorra" width="30%"></a><br>{{ idioma | translate: "pagos.PAYPAL-SLOGAN" | async}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.activar">
                <!-- Credit card form content -->
                <div class="tab-content">

                    <!-- credit card info-->
                    <div id="nav-tab-card" class="tab-pane fade show active">
                        <form [formGroup]="forma" role="form">
                            <div class="form-group">
                                <label for="username">Nombre titular (en la tarjeta)</label>
                                <input formControlName="card_holder_first_name" type="text" placeholder="ingrese su nombre" class="form-control uppercase" [class.is-invalid]="card_holder_first_name">
                                <small *ngIf="card_holder_first_name" class="text-danger">
                                    *
                             </small>
                            </div>
                            <div class="form-group">
                                <label for="username">Apellido titular (en la tarjeta)</label>
                                <input formControlName="card_holder_last_name" type="text" placeholder="ingrese su apellido" class="form-control uppercase" [class.is-invalid]="card_holder_last_name">
                                <small *ngIf="card_holder_last_name" class="text-danger"> * </small>
                            </div>
                            <div class="form-group">
                                <label for="cardNumber">Número de tarjeta</label>
                                <div class="input-group">
                                    <input formControlName="card_number" type="text" placeholder="Número de tarjeta" class="form-control" [class.is-invalid]="card_number">
                                    <small *ngIf="card_number" class="text-danger"> * </small>
                                    <div class="input-group-append">
                                        <span class="input-group-text text-muted">
                                                <i class="fa fa-cc-visa mx-1"></i>
                                                <i class="fa fa-cc-amex mx-1"></i>
                                                <i class="fa fa-cc-mastercard mx-1"></i>
                                            </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-8">
                                    <div class="form-group">
                                        <label><span class="hidden-xs">Vencimiento</span></label>
                                        <div class="input-group">
                                            <input formControlName="card_expiration_month" type="number" placeholder="MM" class="form-control" [class.is-invalid]="card_expiration_month">
                                            <small *ngIf="card_expiration_month" class="text-danger"> * </small>
                                            <input formControlName="card_expiration_year" type="number" placeholder="YY" class="form-control" [class.is-invalid]="card_expiration_year">
                                            <small *ngIf="card_expiration_year" class="text-danger"> * </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group mb-4">
                                        <label data-toggle="tooltip" title="Los 3 dígitos detrás de su tarjeta">CVV
                                            </label>
                                        <input formControlName="security_code" type="text" placeholder="***" value="000" class="form-control" [class.is-invalid]="security_code">
                                        <small *ngIf="security_code" class="text-danger"> * </small>
                                    </div>
                                </div>

                                <div class="col-sm-8">
                                    <div class="form-group">
                                        <label for="">Tipo de identificación</label>
                                        <input formControlName="tipoIdentificacion" type="text" placeholder="Ingrese su tipo de identificación" class="form-control uppercase" [class.is-invalid]="tipoIdentificacion">
                                        <small *ngIf="tipoIdentificacion" class="text-danger"> * </small>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="username">Número de identificación</label>
                                    <input formControlName='identificacion' type="text" placeholder="Ingrese su número de identificación" class="form-control" [class.is-invalid]="identificacion">
                                    <small *ngIf="identificacion" class="text-danger"> * </small>
                                </div>

                                <div class="form-group">
                                    <label for="username">E-mail</label>
                                    <input formControlName="email" type="text" placeholder="Ingrese su E-mail" autocomplete="off" class="form-control" [class.is-invalid]="email">
                                    <small *ngIf="email" class="text-danger"> * </small>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="">
                                            <label for="username">Cod. área</label>
                                            <input formControlName="phoneAreaCode" type="text" placeholder="Cod. área" autocomplete="off" class="form-control" [class.is-invalid]="phoneAreaCode">
                                            <small *ngIf="phoneAreaCode" class="text-danger"> * </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="">
                                            <label for="username">Teléfono</label>
                                            <input formControlName="phoneNumber" type="text" placeholder="Teléfono" autocomplete="off" class="form-control" [class.is-invalid]="phoneNumber">
                                            <small *ngIf="phoneNumber" class="text-danger"> * </small>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>
                    <!-- End -->

                </div>
            </div>

            <!-- End -->

        </div>
    </div>
</div>
