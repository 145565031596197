import { Component, OnInit } from '@angular/core';
import { Translators } from '../../../../services/shared/translators.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { CookiebotService } from 'src/app/services/cookiebot/cookiebot.service';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {

  lenguaje = 'es';
  idioma: Subscription;
  
  constructor(
    private translatorService: Translators,
    private cookiebotService: CookiebotService,
  ) { 

    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      switch (resp.value){
        case 'es':
          this.lenguaje = 'es';
          break;
        case 'en':
          this.lenguaje = 'en';
          break;
        default:
          this.lenguaje = 'es';
          break;
        }
    });

    this.loadScriptCookieDeclaration(this.idioma);
  }

  ngOnInit(): void {
  }

  public loadScriptCookieDeclaration(idioma) {
    this.cookiebotService.loadScriptCookieDeclaration(idioma);
  }

}
