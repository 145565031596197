import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../../services/shared/storage.service';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { Actividad } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { PaisesService } from '../../../../services/shared/paises.service';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Subscription } from 'rxjs';
import { Translators } from '../../../../services/shared/translators.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-actividad-detalle',
  templateUrl: './actividad-detalle.component.html',
  styleUrls: ['./actividad-detalle.component.scss']
})
export class ActividadDetalleComponent implements OnInit {

  actividad: Actividad;
  pais: PaisDisponible;
  nombrePais: string;
  idioma: Subscription;
  allChecked = false;
  flagSelected = false;
  flagProcesando = false;
  flagLoaded = false;
  modulos: any;
  modulosSelected: Curso[] = [];

  constructor(
    private router: Router,
    private actividadesService: ActividadesService,
    private paisesService: PaisesService,
    private translatorService: Translators,
    private authService: AuthService,
    private guiMsjService: GuiMsjService,
    private cursosService: CursosService,
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });
  }

  ngOnInit(): void {
    this.getActividad();
  }

  async getActividad() {
    this.actividad = this.actividadesService.getActividadSeleccionada();
    console.log('this.actividad', this.actividad);
    if (this.actividad === undefined) {
      this.router.navigateByUrl('/');
    } else {
      await this.getPais();
      // PREPARO MODULOS 
      this.modulos = this.actividad;
      this.modulos["name"] = 'Todos los  Módulos';
      this.modulos["selected"] = false;
      this.modulos["disabled"] = false;
      this.modulos.cursos.forEach((t => { t["selected"] = false; }));
      // FIN PREPARO MODULOS
      this.flagLoaded = true;
      return this.actividad;
    }
   
  }

  getPais() {
    this.nombrePais = this.paisesService.getPaisSeleccionado().pais.name_es;
    return this.pais = this.paisesService.getPaisSeleccionado();
  }


  async actividadPostular() {
    /* AGREGO MODAL PARA REDIRIGIR AL FORMULARIO DE INSCRIPCION */
    // let timerInterval
    // Swal.fire({
    //   title: 'Atención: La fecha de Pre inscripción es a partir del 10 de Abril de 2023!',
    //   html: 'Usted será redirigido al formulario de pre inscripción vigente. Muchas gracias.',
    //   timer: 8000,
    //   timerProgressBar: true,
    //   didOpen: () => {
    //     Swal.showLoading()
    //     const b: any = Swal.getHtmlContainer().querySelector('b')
    //     timerInterval = setInterval(() => {
    //       b.textContent = Swal.getTimerLeft()
    //     }, 100)
    //   },
    //   willClose: () => {
    //     clearInterval(timerInterval)
    //   }
    // }).then((result) => {
    //   window.location.href = 'https://aadidess.com/inscribite-a-los-cursos/';
    // })

    //alert('ATENCION: LA FECHA DE PRE-INSCRIPCION ES A PARTIR DEL 10 DE ABRIL, MUCHAS GRACIAS ');
    // CODIGO 100% FUNCIONAL COMENTADO MOMENTANEAMENTE HASTA EL 10 DE ABRIL
    this.flagProcesando = true;
    await this.cursosService.setModulosSeleccionados(await this.filtrarModulsoSelected(this.modulos.cursos)); // seteo los modulos que selecciono a cursar
    const flagInvitado  = await this.authService.esUsuarioInvitado();
    if (flagInvitado) {
        this.guiMsjService.msjFormSubmit('invitado');
        this.router.navigateByUrl('/profile-authentication');
    } else {;
        this.router.navigateByUrl('/actividad-postular');
    }
    this.flagProcesando = false
    // FIN CODIGO 100% FUNCIONAL COMENTADO MOMENTANEAMENTE HASTA EL 10 DE ABRIL
  }

  // Return true if selected items are less then total(allChecked) but more then 0
  //https://www.freakyjolly.com/angular-material-dynamic-checkbox-list-with-indeterminate-and-validations/

  async filtrarModulsoSelected(cursos): Promise<Curso[]> {
    this.modulosSelected = [];
    await cursos.forEach(m => { if (m.selected) { this.modulosSelected.push(m) } });
    return this.modulosSelected;
  }

  isFewSelected(): boolean {
    this.controlSeleccionIndividual();
    return this.modulos.cursos.filter(t => t._id).length > 0 && !this.allChecked;
  }

  controlSeleccionIndividual() {
    if (this.modulos.cursos !== undefined) {
      this.modulos.cursos.forEach(element => {
        this.flagSelected = false;
        if (element.selected)  { this.flagSelected = true; } 
      });
    }
  }

  // Update Master and list checkboxes
  setAll(selected: boolean) {
    this.allChecked = selected;
    if (this.modulos.cursos == null) {
      return;
    }
    if (selected) { this.flagSelected = true; } else { this.flagSelected = false; }
    this.modulos.cursos.forEach(t => t.selected = selected);
  }

  // Check master checkbox if all items selected
  updateAllComplete() {
    this.flagSelected = true;
    this.allChecked = this.modulos.cursos != null && this.modulos.cursos.every(t => t.selected);
  }

  alertSleccioneUnModulo() {
    alert('Selececcione al menos un módulo');
  }

}
