import { AfterViewInit, Inject } from '@angular/core';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatStepper } from '@angular/material/stepper/public-api';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../../../../app.config/app.config-ofertas';
import { Subscription } from 'rxjs';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, AfterViewInit {

  @ViewChild('stepper') stepper: MatStepper;

  @Input()  stepperSet: number;
  @Input()  vertical: boolean;

  flagLoaded = false;

  step0Completed = false;
  step1Completed = false;
  step2Completed = false;
  step3Completed = false;
  step4Completed = false;
  step5Completed = false;
  step6Completed = false;
  step7Completed = false;
  step8Completed = false;
  step9Completed = false;

  idioma: Subscription;


  constructor(
    @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
    private translatorService: Translators,
    ) {
        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
        });
     }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    setTimeout(() => {
        this.stepper.selectedIndex = this.stepperSet + 1;
        this.setEstadosStepper(this.stepperSet);
      }, 0);
  }

  setEstadosStepper(stepperSet) {

    switch (stepperSet) {
        case 0:
            this.step0Completed = true;
            this.step1Completed = false;
            this.step2Completed = false;
            this.step3Completed = false;
            this.step4Completed = false;
            this.step5Completed = false;
            this.step6Completed = false;  
            this.step7Completed = false;         
            this.step8Completed = false;         
            this.step9Completed = false;         
            break;
        case 1:
            this.step0Completed = true;
            this.step1Completed = false;
            this.step2Completed = false;
            this.step3Completed = false;
            this.step4Completed = false;
            this.step5Completed = false;
            this.step6Completed = false;  
            this.step7Completed = false;
            this.step8Completed = false;
            this.step9Completed = false;
            break;
        case 2:
            this.step0Completed = true;
            this.step1Completed = true;
            this.step2Completed = true;
            this.step3Completed = false;
            this.step4Completed = false;
            this.step5Completed = false;
            this.step6Completed = false;
            this.step7Completed = false;
            this.step8Completed = false;
            this.step9Completed = false;
            break;
        case 3:
            this.step0Completed = true;
            this.step1Completed = true;
            this.step2Completed = true;
            this.step3Completed = true;
            this.step4Completed = false;
            this.step5Completed = false;
            this.step6Completed = false;
            this.step7Completed = false;
            this.step8Completed = false;
            this.step9Completed = false;
            break;
        case 4:
            this.step0Completed = true;
            this.step1Completed = true;
            this.step2Completed = true;
            this.step3Completed = true;
            this.step4Completed = true;
            this.step5Completed = false;          
            this.step5Completed = false;          
            this.step6Completed = false;            
            this.step7Completed = false;
            this.step8Completed = false;
            this.step9Completed = false;
            break;
        case 5:
            this.step0Completed = true;
            this.step1Completed = true;
            this.step2Completed = true;
            this.step3Completed = true;
            this.step4Completed = true;
            this.step5Completed = true;
            this.step6Completed = false;   
            this.step7Completed = false;
            this.step8Completed = false;
            this.step9Completed = false;
            break;
        case 6:
            this.step0Completed = true;
            this.step1Completed = true;
            this.step2Completed = true;
            this.step3Completed = true;
            this.step4Completed = true;
            this.step5Completed = true;
            this.step6Completed = true;
            this.step7Completed = false;
            this.step8Completed = false;
            this.step9Completed = false;
            break;
        case 7:
            this.step0Completed = true;
            this.step1Completed = true;
            this.step2Completed = true;
            this.step3Completed = true;
            this.step4Completed = true;
            this.step5Completed = true;
            this.step6Completed = true;
            this.step7Completed = true;
            this.step8Completed = false;
            this.step9Completed = false;
            break;
        case 8:
            this.step0Completed = true;
            this.step1Completed = true;
            this.step2Completed = true;
            this.step3Completed = true;
            this.step4Completed = true;
            this.step5Completed = true;
            this.step6Completed = true;
            this.step7Completed = true;
            this.step8Completed = true;
            this.step9Completed = false;
            break;
        case 9:
            this.step0Completed = true;
            this.step1Completed = true;
            this.step2Completed = true;
            this.step3Completed = true;
            this.step4Completed = true;
            this.step5Completed = true;
            this.step6Completed = true;
            this.step7Completed = true;
            this.step8Completed = true;
            this.step9Completed = true;
            break;
    
        default:
            break;
    }

    this.flagLoaded = true;

  }

}
