<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li>Configurar Materias</li>
            </ul>
            <h2>Configurar Materias</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="features-area pb-70 animate__animated animate__fadeIn">
    <div class="container">

        <div class="row m-4">
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-list-ul"></i>
                    </div>
                    <h3>Listado de Materias</h3>
                    <p>Acceda al listado de Materias de un curso en particular</p>

                    <a routerLink="/materias-listado" class="btn btn-warning">Ver Materias</a>

                </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-plus-square"></i>
                    </div>
                    <h3>Nueva Materia</h3>
                    <p>Cree una nueva Materia a un Curso</p>
                    <a routerLink="/materia-alta" class="btn btn-warning">Crear Materia</a>
                </div>
            </div>
            <!--<div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Modificar Materia</h3>
                    <p>Edite las características de una Materia</p>
                    <a routerLink="/materia-modificacion" class="btn btn-warning">Editar Materia</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-trash-alt"></i>
                    </div>
                    <h3>Eliminar Materia</h3>
                    <p>Borre una Materias de un Curso</p>
                    <a routerLink="/profile-authentication" class="btn btn-danger">Borrar Materia</a>
                </div>
            </div>-->
        </div>

    </div>
</div>