<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li>Mis Examenes</li>
            </ul>
            <h2>Mis Examenes</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<!-- <app-loading *ngIf="!flagLoadedAsignados" class="m-5"></app-loading><br> -->
<div class="profile-area pb-70">
    <div class="container">
        <div class="alert alert-warning" role="alert" *ngIf="alumnoSinExamenes">
            <h5>Sin examenes Individuales asignados</h5>
        </div>
        <br>
        <div class="alert alert-info" role="alert">
            <h5>Puedes tener examenes o recuperatorios de cursos pendientes. Dirigite a tus cursos , pestaña examenes. </h5>
            <li><a routerLink="/cursos-perfil-alumno">Click aquí para ir a tus cursos..</a></li>
        </div>
        <br>
        <mat-tab-group>
            <mat-tab label="Asignados Individual">
                <br>
                <mat-form-field>
                    <mat-label>Filtrar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Admin" autocomplete="off">
                </mat-form-field>
                <!-- <mat-spinner *ngIf="!flagLoadedAsignados"></mat-spinner> -->

                <div  *ngIf="!flagLoadedAsignados" class="loading-spinner-overlay">
                    <div class="loading-spinner-container">
                        <div class="loading-spinner"></div>
                        <p>Espere por favor cargando sus examenes...</p>
                    </div>
                </div>

                <br>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort #empTbSort="matSort">

                    <ng-container matColumnDef="nombreExamen">
                        <th mat-header-cell *matHeaderCellDef class="uppercase" mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{ element.nombre }} </td>
                    </ng-container>

                    <ng-container matColumnDef="preguntas">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Preguntas </th>
                        <td mat-cell *matCellDef="let element"> {{element.preguntas }} </td>
                    </ng-container>

                    <ng-container matColumnDef="aprobacion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Aprobación </th>
                        <td mat-cell *matCellDef="let element">{{element.aprobacion}}%</td>
                    </ng-container>

                    <ng-container matColumnDef="vigencia">
                        <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header> Vigencia </th>
                        <td width="20%" mat-cell *matCellDef="let element" [ngClass]="{'row-vencido': isVencido(element.vencimiento)}">
                            {{element.publicacion | date:'short'}} - {{element.vencimiento | date:'short'}}
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef="estado">
                        <th width="5%" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                        <td width="5%" mat-cell *matCellDef="let element"> {{ element.estado ? 'Activo' : 'Inactivo' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="puntaje">
                        <th width="5%" mat-header-cell *matHeaderCellDef mat-sort-header> Puntaje </th>
                        <td width="5%" mat-cell *matCellDef="let element"> {{ element.puntaje }}</td>
                    </ng-container>

                    <ng-container matColumnDef="_id">
                        <th width="25%" mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td width="25%" mat-cell *matCellDef="let element">
                            <button style="margin-right: 5px;" [title]="!element.disabled ? 'Comenzar examen' : 'Examen no disponible aún'" [disabled]="!element.estado || element.disabled" mat-raised-button mat-button color="primary" type="button" (click)="verExamen(1,element._id,element.asignacion, element.individual)"
                                disabled><mat-icon aria-hidden="false" aria-label="Example home icon">not_started</mat-icon></button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>

                </table>

                <mat-paginator #paginatorFirst [pageSizeOptions]="[5, 10, 15, 20, 25, 50, 100]" showFirstLastButtons></mat-paginator>

            </mat-tab>

            <mat-tab label="Recuperatorios">
                <br>
                <mat-form-field>
                    <mat-label>Filtrar</mat-label>
                    <input matInput (keyup)="applyFilter2($event)" placeholder="Instructor..." autocomplete="off">
                </mat-form-field>

                <mat-spinner *ngIf="!flagLoadedRecuperatorios"></mat-spinner>
                <br>
                <div class="alert alert-warning" role="alert" *ngIf="sinRecuperatorios">
                    No tiene recuperatorios posibles
                </div>
                <table mat-table [dataSource]="dataSourceWithObjectColumn" class="mat-elevation-z8" matSort #empTbSortWithObject="matSort">

                    <ng-container matColumnDef="nombreExamen">
                        <th mat-header-cell *matHeaderCellDef class="uppercase" mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{ element.nombre }} </td>
                    </ng-container>

                    <ng-container matColumnDef="cantidadRecuperable">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cant. Recuperatorios </th>
                        <td mat-cell *matCellDef="let element"> {{element.cantidadRecuperable }} </td>
                    </ng-container>

                    <ng-container matColumnDef="aprobacion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Aprobación </th>
                        <td mat-cell *matCellDef="let element">{{element.aprobacion}}%</td>
                    </ng-container>

                    <ng-container matColumnDef="vigencia">
                        <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header> Vigencia </th>
                        <td width="20%" mat-cell *matCellDef="let element">
                            <p>Recuperatorio 1: {{element.r1.fechaPublicacionR1 | date:'short'}} - {{element.r1.fechaVencimiento1 | date:'short'}}</p>
                            <p *ngIf="element.r2.fechaPublicacionR2">Recuperatorio 2: {{element.r2.fechaPublicacionR2 | date:'short'}} - {{element.r2.fechaVencimiento2 | date:'short'}}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="estado">
                        <th width="5%" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                        <td width="5%" mat-cell *matCellDef="let element"> {{ element.estado ? 'Activo' : 'Inactivo' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="_id">
                        <th width="25%" mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td width="25%" mat-cell *matCellDef="let element">
                            <button [title]="!element.disabled ? 'Comenzar recuperatorio' : 'Recuperatorio no disponible aún'" [disabled]="!element.estado || element.disabled" mat-raised-button mat-button color="accent" type="button" (click)="verExamen(2,element._id,element.asignacion, element.individual)"
                                disabled><mat-icon aria-hidden="false" aria-label="Example home icon">not_started</mat-icon></button>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumnsWithObject"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsWithObject;" class="example-element-row"></tr>

                </table>

                <mat-paginator #paginatorSecond [pageSizeOptions]="[5, 10, 15, 20, 25, 50, 100]" showFirstLastButtons></mat-paginator>

            </mat-tab>

            <mat-tab label="Examenes Aprobados">
                <br>
                <mat-form-field>
                    <mat-label>Filtrar</mat-label>
                    <input matInput (keyup)="applyFilter3($event)" placeholder="Instructor..." autocomplete="off">
                </mat-form-field>

                <mat-spinner *ngIf="!flagLoadedEvaluacionesAprobados"></mat-spinner>
                <br>
                <div class="alert alert-warning" role="alert" *ngIf="sinAprobados">
                    Sin examenes aprobados
                </div>
                <table mat-table [dataSource]="dataSourceAprobados" class="mat-elevation-z8" matSort #sortAprobados="matSort">

                    <ng-container matColumnDef="nombreExamen">
                        <th mat-header-cell *matHeaderCellDef class="uppercase" mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{ element.nombre }} </td>
                    </ng-container>

                    <ng-container matColumnDef="fechaEvaluacion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Evaluación </th>
                        <td mat-cell *matCellDef="let element">{{element.fechaEvaluacion | date:'short'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="estado">
                        <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                        <td width="10%" mat-cell *matCellDef="let element"> {{ element.estado === true ? 'Aprobado' : 'Desaprobado' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="puntajeObtenido">
                        <th width="5%" mat-header-cell *matHeaderCellDef class="uppercase" mat-sort-header> Puntaje Obtenido </th>
                        <td width="5%" mat-cell *matCellDef="let element"> {{ element.puntajeObtenido }} </td>
                    </ng-container>

                    <ng-container matColumnDef="esRecuperatorio">
                        <th width="5%" mat-header-cell *matHeaderCellDef mat-sort-header> Es Recuperatorio </th>
                        <td width="5%" mat-cell *matCellDef="let element"> {{ element.esRecuperatorio ? 'Si' : 'No' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                        <th width="15%" mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td width="15%" mat-cell *matCellDef="let element">
                            <button style="margin-right: 5px;" mat-raised-button mat-button type="button" title="Ver Examen Resultado" (click)="verResultado(element.id);"><mat-icon aria-hidden="false" aria-label="Example home icon">visibility</mat-icon></button>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAprobados"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAprobados;" class="example-element-row"></tr>

                </table>

                <mat-paginator #paginatorThird [pageSizeOptions]="[5, 10, 15, 20, 25, 50, 100]" showFirstLastButtons></mat-paginator>

            </mat-tab>

            <mat-tab label="Examenes Desaprobados">
                <br>
                <mat-form-field>
                    <mat-label>Filtrar</mat-label>
                    <input matInput (keyup)="applyFilter4($event)" placeholder="Instructor..." autocomplete="off">
                </mat-form-field>

                <mat-spinner *ngIf="!flagLoadedEvaluacionesDesaprobados"></mat-spinner>
                <br>
                <div class="alert alert-warning" role="alert" *ngIf="sinDesaprobados">
                    Sin examenes desaprobados
                </div>
                <table mat-table [dataSource]="dataSourceDesaprobados" class="mat-elevation-z8" matSort #sortAprobados="matSort">

                    <ng-container matColumnDef="nombreExamen">
                        <th mat-header-cell *matHeaderCellDef class="uppercase" mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{ element.nombre }} </td>
                    </ng-container>

                    <ng-container matColumnDef="fechaEvaluacion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Evaluación </th>
                        <td mat-cell *matCellDef="let element">{{element.fechaEvaluacion | date:'short'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="estado">
                        <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                        <td width="10%" mat-cell *matCellDef="let element"> {{ element.estado === true ? 'Aprobado' : 'Desaprobado' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="puntajeObtenido">
                        <th width="5%" mat-header-cell *matHeaderCellDef class="uppercase" mat-sort-header> Puntaje Obtenido </th>
                        <td width="5%" mat-cell *matCellDef="let element"> {{ element.puntajeObtenido }} </td>
                    </ng-container>

                    <ng-container matColumnDef="esRecuperatorio">
                        <th width="5%" mat-header-cell *matHeaderCellDef mat-sort-header> Es Recuperatorio </th>
                        <td width="5%" mat-cell *matCellDef="let element"> {{ element.esRecuperatorio ? 'Si' : 'No' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                        <th width="15%" mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td width="15%" mat-cell *matCellDef="let element">
                            <button style="margin-right: 5px;" mat-raised-button mat-button type="button" title="Ver Examen Resultado" (click)="verResultado(element.id);"><mat-icon aria-hidden="false" aria-label="Example home icon">visibility</mat-icon></button>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumnsDesaprobados"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsDesaprobados;" class="example-element-row"></tr>

                </table>

                <mat-paginator #paginatorFourth [pageSizeOptions]="[5, 10, 15, 20, 25, 50, 100]" showFirstLastButtons></mat-paginator>

            </mat-tab>

        </mat-tab-group>

    </div>
</div>