<section class="example-section" [formGroup]="form">

    <form [formGroup]="form">
        <h6>Seleccione una opción:</h6>
        <mat-radio-group aria-labelledby="example-radio-group-label"
        class="example-radio-group" formControlName="seleccionado">
            <mat-radio-button class="example-radio-button" *ngFor="let opcion of opciones; let i = index" [value]="opcion" (change)="seleccionarOpcion(opcion.valor)">
                {{ opcion.texto }}
            </mat-radio-button>
        </mat-radio-group>

        <div>
            <button *ngIf="index != 0" mat-button matStepperPrevious>Anterior</button>
            <button mat-raised-button mat-button color="primary" *ngIf="longitud != index+1" [disabled]="!form.valid" matStepperNext>Siguiente</button>
        </div>

    </form>

</section>