<div class="container mt-5"> <!-- Agregamos la clase mt-5 para agregar margen superior -->
 
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-info" role="alert">
            <h4 class="alert-heading">Confirmación de Pago</h4>
            <p>Agradecemos su pago. Una vez que AADIDESS confirme su pago, le notificaremos de inmediato.</p>
            <p>Por favor, espere mientras lo redirigimos al campus.</p>
            <hr>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div style="display: flex; justify-content: center">
  <div *ngIf="pagoResults?.status === 'approved'" style="display: flex; justify-content: center">
   
    <div class="alert alert-success w-75 p-3 text-center mt-2" role="alert">
      <h4 class="alert-heading">Su pago fue aprobado!</h4>

      <p class="text-danger">
        ATENCIÓN: NO es necesario enviar el comprobante de pago, recibiras la FACTURA por email.
      </p>  

      <p>Agradecemos su pago a AADIDESS. Una vez que AADIDESS confirme su pago, le notificaremos de inmediato.</p>
      <p>La entidad de pago se pondrá en contacto con usted lo antes posible para informarle sobre el estado de su transacción.</p>
      <p>Ahora lo redirigiremos al campus.</p>
    </div>
    
  </div>  
  
  <div *ngIf="pagoResults?.status === 'in_process'" style="display: flex; justify-content: center">
    <div  class="alert alert-warning w-75 p-3 text-center mt-2" role="alert">
      <h4 class="alert-heading">Su pago se encuentra pendiente de aprobación!</h4>
          <p>La entidad de pago se pondrá en contacto con usted lo antes posible para informarle sobre el estado de su transacción.</p>
          <p>Ahora lo redirigiremos al campus.</p>
          <hr>
    </div>
  </div>

  <div *ngIf="pagoResults?.status === 'rejected'" style="display: flex; justify-content: center">
    <div  class="alert alert-warning w-75 p-3 text-center mt-2" role="alert">
      <h4 class="alert-heading">Su pago no ha podido ser procesado!</h4>
          <p>Le pedimos que por favor lo intente nuevamente más tarde, o bien intente procesar su pago por otro medio.</p>
          <p>Ahora lo redirigiremos al campus.</p>
          <hr>
        </div>
  </div>

</div>
