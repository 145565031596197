import { Component, OnInit, Inject } from '@angular/core';
import { GuiToolsService } from '../../../../../../../services/shared/gui-tools.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaypalPurchaseUnit } from '../../../../../../../interfaces/interface-frontend/shared/paypalPurchaseUnit.nterface';
import { Solicitud } from '../../../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';

@Component({
  selector: 'app-modal-confirmar-solicitar-pago',
  templateUrl: './modal-confirmar-solicitar-pago.component.html',
  styleUrls: ['./modal-confirmar-solicitar-pago.component.scss']
})
export class ModalConfirmarSolicitarPagoComponent implements OnInit {

  solicitud: Solicitud;

  constructor(
    private guiToolsService: GuiToolsService,
    /* Modal Ref. */
    public dialogRef: MatDialogRef<ModalConfirmarSolicitarPagoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PaypalPurchaseUnit
    /* Fin Modal Ref. */
  ) { }

  ngOnInit(): void {
      this.cargarSolicitud(this.data);
  }

  cargarSolicitud(data: PaypalPurchaseUnit) {
    this.solicitud = data.data.solicitud; // data es un objeto generíco
  }

  aceptar() {
    this.dialogRef.close(true);
  }

  cancelar() {
    this.dialogRef.close(false);
  }

}
