import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Invoice } from 'src/app/interfaces/interface-bakend/invoices/invoices.interfaces';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { InvoicesService } from 'src/app/services/shared/invoices.service';
import { InvoicesListadoComponent } from '../invoices-listado.component';

@Component({
  selector: 'app-invoice-detalle',
  templateUrl: './invoice-detalle.component.html',
  styleUrls: ['./invoice-detalle.component.scss']
})
export class InvoiceDetalleComponent implements OnInit {

    activarCerrarModal = true;

    invoice: any;
    solicitud: any;
    invoices: [];
    flagLoaded = false;
    flagSpinner = false ;
    fecha: string;
    dataModal: any;
    usuario: string;
    tipoPago : string = null;
    payments: [];

    constructor(
        private invoicesService: InvoicesService,
        public dialog: MatDialog,
        private guiMsjService: GuiMsjService,

        /* Modal Ref. */
        public dialogRef: MatDialogRef<InvoicesListadoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string // los datos los recibo por servicio
        /* Fin Modal Ref. */
    ) { }

    ngOnInit(): void {
        this.getInvoice();
    }

    async getInvoice(): Promise<any> {
        this.flagLoaded = true;
        this.invoice = await this.invoicesService.getInvoiceSeleccionado();
        this.solicitud = this.invoice.solicitud;
        this.invoices = this.invoice.solicitud.invoices;
        this.payments = this.invoice.payments;
        this.usuario = this.invoice.usuario.nombrePersona + " " + this.invoice.usuario.apellidoPersona;
        this.fecha = this.invoice.fecha;

        return this.invoice;
    }

    // MODAL CLOSE
    closeModal(): void {
        this.dialogRef.close();
    }

    /* DESACTIVAR BOTONES */
    async desactivarBotonoes() {
      this.activarCerrarModal = false;
    }

}
