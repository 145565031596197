
<div *ngFor="let item of this.invoices">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Detalle Comprobante</mat-card-title>
            <mat-card-subtitle>Mercado Pago</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>

            <!-- Mensaje de información de usuario -->
            <div class="alert alert-info mt-3">
                <strong>Información del Usuario que creo la solicitud</strong>
                <ul class="list-unstyled mt-2">
                    <li><strong>Nombre y Apellido:</strong> {{ this.invoice.usuario.fullName }}</li>
                    <li><strong>Identificación:</strong> {{ this.invoice.usuario.identificacion }}</li>
                </ul>
            </div>

            <!-- Mensaje de éxito en color verde para Mercado Pago Nro. Referencia -->
            <div class="alert alert-success mt-3">
                <strong>Mercado Pago Nro. Referencia</strong>
                <p>{{ item?.payments[0]?.result.id || 'sin referencia'}} </p>
            </div>
            
            <!-- Mensaje de información de Bootstrap -->
            <div class="alert alert-success mt-3">
                <strong>Detalle de Administración:</strong>
                <p>{{ item?.data?.observacion || 'sin detalle' }} </p>
            </div>
            
            <li>Fecha: <strong>{{ item.createdAt | date:'medium'}}</strong></li>
            <li>Importe: <strong>{{ item.importeTotal | currency:'$' }}</strong></li>
            <li>Saldo Invoice: <strong>{{ item.saldo | currency:'$' }}</strong></li>
        
            <div *ngFor="let payment of item.payments">

                <div *ngIf="payment.result.payer !== undefined">
                    <li>(Titular de pago): <strong>{{ payment.result.payer?.name || 'No Informado por MP'}} {{
                            payment.result.payer?.name?.surname }}</strong></li>
                    <li>Tipo Pago: <strong>{{ this.tipoPago || 'MP'}}</strong></li>
                    <li>Estado: <strong>{{ payment.result.status }}</strong>
                    <li>Email: <strong>{{ payment.result?.payer?.email }}</strong></li>
                </div>

                <div *ngFor="let purchase of payment.result.purchase_units">
                    <li>Descripción: <strong>{{ purchase.description }}</strong></li>
                </div>
            </div>
            <li *ngIf="item.pagado == true">Pagado:<span class="badge bg-success"> Pagado</span></li>
            <li *ngIf="item.pagado == false">Pagado:<span class="badge bg-danger"> Impago</span></li>
            <li>Observaciones: <strong>{{ item.observaciones }}</strong></li>
        </mat-card-content>
    </mat-card><br>
</div>
<!-- Botón de Cerrar Ventana -->
<div class="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
    <button (click)="closeModal()" class="btn btn-primary" type="button">Cerrar Ventana</button>
</div>
