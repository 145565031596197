<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li><a routerLink="/lecciones-configuracion">Configurar Lecciones</a></li>
                <li>Nueva Lección</li>
            </ul>
            <h2>Nueva Lección</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="form" (ngSubmit)="post()">
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Nombre</mat-label>
                                <input formControlName="nombre" matInput placeholder="Nombre" [class.is-invalid]="nombreNoValido" autocomplete="off">
                                <small *ngIf="nombreNoValido" class="text-danger">Ingrese un nombre</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Descripción</mat-label>
                                <textarea formControlName="descripcion" [class.is-invalid]="descripcionNoValida" matInput placeholder="Ingrese una descripción"></textarea>
                                <small *ngIf="descripcionNoValida" class="text-danger">Ingrese una descripción</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field appearance="fill" [style.width.%]=100>
                                <mat-label>Materia</mat-label>
                                <mat-select formControlName="materia">

                                    <mat-option *ngFor="let item of materias" value="{{ item._id }}">{{ item.nombre }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <mat-form-field color="accent" appearance="fill" [style.width.%]=100>
                                        <mat-label>Fecha Inicio</mat-label>
                                        <input formControlName="fechaInicio" matInput [matDatepicker]="picker2" [class.is-invalid]="fechaNoValida" autocomplete="off">
                                        <small *ngIf="fechaNoValida" class="text-danger">Ingrese una fecha de inicio</small>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2 color="primary"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <mat-form-field color="accent" appearance="fill" [style.width.%]=100>
                                        <mat-label>Fecha Finalización</mat-label>
                                        <input formControlName="fechaFin" matInput [matDatepicker]="picker3" [class.is-invalid]="fechaFinNoValida" autocomplete="off">
                                        <small *ngIf="fechaFinNoValida" class="text-danger">Ingrese una fecha de finalización</small>
                                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                        <mat-datepicker #picker3 color="primary"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Puntaje</mat-label>
                                        <input type="number" formControlName="puntaje" matInput placeholder="Ingrese puntaje" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Orden</mat-label>
                                        <input type="number" formControlName="orden" matInput placeholder="Ingrese orden" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <mat-slide-toggle formControlName="estado">Disponible</mat-slide-toggle>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="formFile" class="form-label">Video Mp4 /jpg-png-jpeg/ Pdf</label>
                            <input formControlName="file" class="form-control-xs" type="file" accept="image/jpeg, image/jpg, image/png,application/pdf, video/mp4" (change)="openFileSelected($event);">
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <button type="button" routerLink="/lecciones-configuracion" class="btn btn-warning">Cancelar</button>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <button [disabled]="!form.valid || this.cargandoArchivo" type="submit" disabled>Guardar</button>
                            </div>
                        </div>

                        <br>
                        <br>
                    </form>
                    <div *ngIf="this.cargandoArchivo">
                        <div class="progress" style="height: 20px;">
                            <div class="progress-bar bg-info" role="progressbar" [style.width]="this.value + '%'" aria-valuenow="this.value" aria-valuemin="0" aria-valuemax="100">{{this.value}}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>