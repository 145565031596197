import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GestoUsuario } from 'src/app/interfaces/interface-bakend/cursos/gestoUsuario.interface';
import { GestosService } from 'src/app/services/cursos/gestos.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';

@Component({
  selector: 'app-gesto-evaluacion-modificacion',
  templateUrl: './gesto-evaluacion-modificacion.component.html',
  styleUrls: ['./gesto-evaluacion-modificacion.component.scss']
})
export class GestoEvaluacionModificacionComponent implements OnInit {

  form: FormGroup;
  gestoEvaluado: GestoUsuario;

  constructor(private fb: FormBuilder,
    private gestosService: GestosService,
    private guiMsjService: GuiMsjService,
    private router: Router) {
    this.getEstadoVariables();
    this.createForm();
  }

  ngOnInit(): void {
  }

  async getEstadoVariables() {
    if (this.getGestoEvaluado() === undefined) {
      this.router.navigateByUrl('/gestos-evaluaciones-listado');
      return;
    } else {
      this.getGestoEvaluado();
    }
  }

  getGestoEvaluado() {
    return this.gestoEvaluado = this.gestosService.getGestoEvaluadoSeleccionado();
  }

  async createForm() {
    this.form = this.fb.group({
      nota: new FormControl(this.gestoEvaluado?.nota, Validators.required),
    });
  }

  async setDataForma(){
    const data: GestoUsuario = {
      nota: this.form.value.nota,
      fechaEvaluacion: this.gestoEvaluado?.fechaEvaluacion,
      fechaVencimiento: this.gestoEvaluado?.fechaVencimiento,
      gesto: this.gestoEvaluado.gesto._id,
      mesa: this.gestoEvaluado.mesa._id,
      usuario: this.gestoEvaluado.usuario._id
    };
    return data;
  }

  async updateGestoEvaluado() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    await this.gestosService.updateGestoEvaluado(this.gestoEvaluado?._id ,await this.setDataForma()).then(async (gesto: GestoUsuario) => {
      this.router.navigate(['/gestos-evaluaciones-listado']);
      return;
    });
  }

}
