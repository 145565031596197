import { OfertasLaboralesModule } from './ofertas-laborales.module';
import { NgModule } from '@angular/core';
import { CursoAltaComponent } from '../components/cursos/pages/curso-alta/curso-alta.component';
import { CursoCompraComponent } from '../components/cursos/pages/curso-compra/curso-compra.component';
import { CursoDescripcionCompraComponent } from '../components/cursos/pages/curso-descripcion-compra/curso-descripcion-compra.component';
import { CursoHabilitadoAlumnoComponent } from '../components/cursos/pages/curso-habilitado-alumno/curso-habilitado-alumno.component';
import { CursoModificacionComponent } from '../components/cursos/pages/curso-modificacion/curso-modificacion.component';
import { CursosConfiguracionComponent } from '../components/cursos/pages/cursos-configuracion/cursos-configuracion.component';
import { CursosDisponiblesCompraComponent } from '../components/cursos/pages/cursos-disponibles-compra/cursos-disponibles-compra.component';
import { CursosPerfilAlumnoComponent } from '../components/cursos/pages/cursos-perfil-alumno/cursos-perfil-alumno.component';
import { EvaluacionAltaComponent } from '../components/cursos/pages/evaluacion-alta/evaluacion-alta.component';
import { EvaluacionPreguntasConfigComponent } from '../components/cursos/pages/evaluacion-preguntas-config/evaluacion-preguntas-config.component';
import { LeccionAltaComponent } from '../components/cursos/pages/leccion-alta/leccion-alta.component';
import { LeccionModificacionComponent } from '../components/cursos/pages/leccion-modificacion/leccion-modificacion.component';
import { LeccionesConfiguracionComponent } from '../components/cursos/pages/lecciones-configuracion/lecciones-configuracion.component';
import { LeccionesListadoComponent } from '../components/cursos/pages/lecciones-listado/lecciones-listado.component';
import { MateriaAltaComponent } from '../components/cursos/pages/materia-alta/materia-alta.component';
import { MateriaModificacionComponent } from '../components/cursos/pages/materia-modificacion/materia-modificacion.component';
import { MateriasConfiguracionComponent } from '../components/cursos/pages/materias-configuracion/materias-configuracion.component';
import { MateriasListadoComponent } from '../components/cursos/pages/materias-listado/materias-listado.component';
import { UsuariosListadoComponent } from '../components/usuarios/pages/usuarios-listado/usuarios-listado.component';
import { UsuarioModificacionComponent } from '../components/usuarios/pages/usuario-modificacion/usuario-modificacion.component';
import { UsuariosAdministracionComponent } from '../components/usuarios/pages/usuarios-administracion/usuarios-administracion.component';
import { UsuariosAltaComponent } from '../components/usuarios/pages/usuarios-alta/usuarios-alta.component';
import { PersonasListadoComponent } from '../components/usuarios/pages/personas-listado/personas-listado.component';
import { PersonaModificacionComponent } from '../components/usuarios/pages/persona-modificacion/persona-modificacion.component';
import { CuentaComponent } from '../components/usuarios/pages/cuenta/cuenta.component';
import { ProfesorTarjetaPresentacionComponent } from '../components/cursos/common/profesor-tarjeta-presentacion/profesor-tarjeta-presentacion.component';
import { ItemLeccionComponent } from '../components/cursos/pages/curso-habilitado-alumno/item-leccion/item-leccion.component';
import { CursosListadoComponent } from '../components/cursos/pages/cursos-listado/cursos-listado.component';
import { CursosDetalleItemsComponent } from '../components/cursos/common/cursos-detalle-items/cursos-detalle-items.component';
import { RespuestasAltaComponent } from '../components/cursos/pages/evaluacion-preguntas-config/respuestas-alta/respuestas-alta.component';
import { CatedrasConfiguracionComponent } from '../components/cursos/pages/catedras-configuracion/catedras-configuracion.component';
import { CatedraAltaComponent } from '../components/cursos/pages/catedra-alta/catedra-alta.component';

// Imports
import { ChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from './material.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { AccordionModule } from 'ngx-accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { CursoTarjetaComponent } from '../components/cursos/common/curso-tarjeta/curso-tarjeta.component';
import { PipesModule } from './pipes.module';
import { CommonComponentsModule } from './common.components.module';
import { ModalModificarClaveComponent } from '../components/usuarios/pages/cuenta/modal-modificar-clave/modal-modificar-clave.component';
import { ModalModificarPresentacionComponent } from '../components/usuarios/pages/cuenta/modal-modificar-presentacion/modal-modificar-presentacion.component';
import { ModalRespuestaComponent } from '../components/cursos/pages/evaluacion-preguntas-config/respuestas-alta/modal-respuesta/modal-respuesta.component';
import { EvaluacionConfigComponent } from '../components/cursos/pages/evaluacion-alta/evaluacion-config/evaluacion-config.component';
import { OrdenarConceptosComponent } from '../components/cursos/pages/evaluacion-preguntas-config/respuestas-alta/ordenar-conceptos/ordenar-conceptos.component';
import { CuadriculaOpcionesComponent } from '../components/cursos/pages/evaluacion-preguntas-config/respuestas-alta/cuadricula-opciones/cuadricula-opciones.component';
import { EvaluacionAsociarComponent } from '../components/cursos/pages/evaluacion-asociar/evaluacion-asociar.component';
import { GrillaCursosComponent } from '../components/cursos/pages/evaluacion-asociar/grilla-cursos/grilla-cursos.component';
import { GrillaUsuariosComponent } from '../components/cursos/pages/evaluacion-asociar/grilla-usuarios/grilla-usuarios.component';
import { GrillaGrupoAfinidadComponent } from '../components/cursos/pages/evaluacion-asociar/grilla-grupo-afinidad/grilla-grupo-afinidad.component';
import { ExamenesListadoComponent } from '../components/cursos/pages/examenes-listado/examenes-listado.component';
import { ExamenIntroduccionComponent } from '../components/cursos/pages/examen-introduccion/examen-introduccion.component';
import { ProfesorAltaComponent } from '../components/cursos/pages/profesor-alta/profesor-alta.component';
import { ProfesoresConfiguracionComponent } from '../components/cursos/pages/profesores-configuracion/profesores-configuracion.component';
import { ProfesoresListadoComponent } from '../components/cursos/pages/profesores-listado/profesores-listado.component';
import { CursosListarComponent } from '../components/cursos/pages/cursos-listar/cursos-listar.component';
import { ExamenesAlumnoComponent } from '../components/cursos/pages/examenes-alumno/examenes-alumno.component';
import { ExamenCursarComponent } from '../components/cursos/pages/examen-cursar/examen-cursar.component';
import { ExamenResultadoComponent } from '../components/cursos/pages/examen-resultado/examen-resultado.component';
import { MultipleChoiceComponent } from '../components/cursos/pages/examen-cursar/multiple-choice/multiple-choice.component';
import { VerdaderoFalsoComponent } from '../components/cursos/pages/examen-cursar/verdadero-falso/verdadero-falso.component';
import { DatetimePickerModule } from './datetimepicker.module';
import { TableFilterPreInscripcionesComponent } from '../components/cursos/pages/table-filter-pre-inscripciones/table-filter-pre-inscripciones.component';
import { ExamenEvaluacionDetalleComponent } from '../components/cursos/pages/examen-resultado-detalle/examen-evaluacion-detalle.component';
import { ExamenesEvaluacionesResultadosComponent } from '../components/cursos/pages/examenes-evaluaciones-resultados/examenes-evaluaciones-resultados.component';
import { EvaluacionModificarComponent } from '../components/cursos/pages/evaluacion-alta-modificar/evaluacion-modificar.component';
import { EvaluacionConfigModificarComponent } from '../components/cursos/pages/evaluacion-alta-modificar/evaluacion-config-modificar/evaluacion-config-modificar.component';
import { GrillaCursosModificarComponent } from '../components/cursos/pages/evaluacion-asociar-modificar/grilla-cursos-modificar/grilla-cursos-modificar.component';
import { GrillaUsuariosModificarComponent } from '../components/cursos/pages/evaluacion-asociar-modificar/grilla-usuarios-modificar/grilla-usuarios-modificar.component';
import { GrillaGrupoAfinidadModificarComponent } from '../components/cursos/pages/evaluacion-asociar-modificar/grilla-grupo-afinidad-modificar/grilla-grupo-afinidad-modificar.component';
import { EvaluacionAsociarModificarComponent } from '../components/cursos/pages/evaluacion-asociar-modificar/evaluacion-asociar-modificar.component';
import { EvaluacionConfigPreguntasComponent } from '../components/cursos/pages/evaluacion-alta-modificar/evaluacion-config-preguntas/evaluacion-config-preguntas.component';
import { EvaluacionInputPreguntasComponent } from '../components/cursos/pages/evaluacion-alta-modificar/evaluacion-input-preguntas/evaluacion-input-preguntas.component';
import { EvaluacionInputRespuestasComponent } from '../components/cursos/pages/evaluacion-alta-modificar/evaluacion-input-respuestas/evaluacion-input-respuestas.component';
import { ExamenRecuperatorioComponent } from '../components/cursos/pages/examen-recuperatorio/examen-recuperatorio.component';
import { ActividadesCursosComponent } from '../components/cursos/pages/actividades-cursos/actividades-cursos.component';
import { CalendarioCursoOnLineComponent } from '../components/cursos/pages/curso-habilitado-alumno/calendario-curso-on-line/calendario-curso-on-line.component';
import { CursosListadoAlumnoCursosComponent } from '../components/cursos/pages/cursos-listado-alumno-cursos/cursos-listado-alumno-cursos.component';
import { CursosAlumnosModificarComponent } from '../components/cursos/pages/cursos-alumnos-modificar/cursos-alumnos-modificar.component';
import { ModalModificarClaveUsersComponent } from '../components/usuarios/pages/modal-modificar-clave/modal-modificar-clave.component';
import { TitulosListadoComponent } from '../components/cursos/pages/titulos-listado/titulos-listado.component';
import { TituloAltaComponent } from '../components/cursos/pages/titulo-alta/titulo-alta.component';
import { TituloModificacionComponent } from '../components/cursos/pages/titulo-modificacion/titulo-modificacion.component';
import { MesasListadoComponent } from '../components/cursos/pages/mesas-listado/mesas-listado.component';
import { MesaAltaComponent } from '../components/cursos/pages/mesa-alta/mesa-alta.component';
import { MesaModificacionComponent } from '../components/cursos/pages/mesa-modificacion/mesa-modificacion.component';
import { GestosConfiguracionComponent } from '../components/cursos/pages/gestos-configuracion/gestos-configuracion.component';
import { GestosListadoComponent } from '../components/cursos/pages/gestos-listado/gestos-listado.component';
import { GestoModificacionComponent } from '../components/cursos/pages/gesto-modificacion/gesto-modificacion.component';
import { GestoAltaComponent } from '../components/cursos/pages/gesto-alta/gesto-alta.component';
import { GestosEvaluacionesListadoComponent } from '../components/cursos/pages/gestos-evaluaciones-listado/gestos-evaluaciones-listado.component';
import { GestosEvaluacionesUsuariosListadoComponent } from '../components/cursos/pages/gestos-evaluaciones-usuarios-listado/gestos-evaluaciones-usuarios-listado.component';
import { MesasUsuariosAsociadosModalComponent } from '../components/cursos/pages/mesas-usuarios-asociados-modal/mesas-usuarios-asociados-modal.component';
import { GestoEvaluacionModificacionComponent } from '../components/cursos/pages/gesto-evaluacion-modificacion/gesto-evaluacion-modificacion.component';
import { GestosConfiguracionUsuarioComponent } from '../components/cursos/pages/gestos-configuracion-usuario/gestos-configuracion-usuario.component';
import { ModalElegirMesaExamenComponent } from '../components/cursos/pages/modal-elegir-mesa-examen/modal-elegir-mesa-examen.component';
import { ModalConfirmarModificacionComponent } from '../components/usuarios/pages/persona-modificacion/modal-confirmar/modal-confirmar-mofificacion.component';
import { MercadoPagoResultadoComponent } from '../components/cursos/pages/mercado-pago/mercado-pago-resultado/mercado-pago-resultado';
import { MateriaBoletinAltaComponent } from '../components/cursos/pages/materia-boletin-alta/materia-boletin-alta.component';
import { BoletinAltaComponent } from '../components/cursos/pages/boletin-alta/boletin-alta.component';
import { BoletinesListadoComponent } from '../components/cursos/pages/boletines-listado/boletines-listado.component';
import { MateriasBoletinListadoComponent } from '../components/cursos/pages/materias-boletin-listado/materias-boletin-listado.component';
import { MateriaBoletinModificacionComponent } from '../components/cursos/pages/materia-boletin-modificacion/materia-boletin-modificacion.component';
import { BoletinModificacionComponent } from '../components/cursos/pages/boletin-modificacion/boletin-modificacion.component';
import { AlumnoBoletinesModalComponent } from '../components/cursos/pages/alumno-boletines-modal/alumno-boletines-modal.component';
import { AlumnoBoletinesListadoComponent } from '../components/cursos/pages/alumno-boletines-listado/alumno-boletines-listado.component';
import { AlumnoBoletinDetalleModalComponent } from '../components/cursos/pages/alumno-boletin-detalle-modal/alumno-boletin-detalle-modal.component';
import { BoletinNombreAltaComponent } from '../components/cursos/pages/boletin-nombre-alta/boletin-nombre-alta.component';
import { BoletinNombreModificacionComponent } from '../components/cursos/pages/boletin-nombre-modificacion/boletin-nombre-modificacion.component';
import { ExamenesBoletinesModalComponent } from '../components/cursos/pages/examenes-boletines-modal/examenes-boletines-modal.component';
import { MacroPagosSuccessComponent } from '../components/cursos/pages/macro-pagos-success/macro-pagos-success.component';
import { MacroPagosErrorComponent } from '../components/cursos/pages/macro-pagos-error/macro-pagos-error.component';

@NgModule({
  declarations: [
    CursoAltaComponent,
    CursoCompraComponent,
    CursoDescripcionCompraComponent,
    CursoHabilitadoAlumnoComponent,
    CursoModificacionComponent,
    CursosConfiguracionComponent,
    CursosDisponiblesCompraComponent,
    CursosPerfilAlumnoComponent,
    EvaluacionAltaComponent,
    EvaluacionPreguntasConfigComponent,
    EvaluacionAsociarComponent,
    EvaluacionAsociarModificarComponent,
    LeccionAltaComponent,
    LeccionModificacionComponent,
    LeccionesConfiguracionComponent,
    LeccionesListadoComponent,
    MateriaAltaComponent,
    MateriaModificacionComponent,
    MateriasConfiguracionComponent,
    MateriasListadoComponent,
    ItemLeccionComponent,
    MultipleChoiceComponent,
    VerdaderoFalsoComponent,
    CursosListadoComponent,
    RespuestasAltaComponent,
    CatedrasConfiguracionComponent,
    CatedraAltaComponent,
    ModalRespuestaComponent,
    EvaluacionConfigComponent,
    EvaluacionConfigModificarComponent,
    OrdenarConceptosComponent,
    CuadriculaOpcionesComponent,
    GrillaCursosComponent,
    GrillaUsuariosComponent,
    GrillaGrupoAfinidadComponent,
    GrillaCursosModificarComponent,
    GrillaUsuariosModificarComponent,
    GrillaGrupoAfinidadModificarComponent,
    ProfesorAltaComponent,
    ProfesoresListadoComponent,
    ProfesoresConfiguracionComponent,
    CursosListarComponent,
    TableFilterPreInscripcionesComponent,
    CursosListadoAlumnoCursosComponent,
    CursosAlumnosModificarComponent,
    TitulosListadoComponent,
    TituloAltaComponent,

    // Componentes en common de Cursos
    CuentaComponent,
    UsuariosListadoComponent,
    UsuarioModificacionComponent,
    UsuariosAdministracionComponent,
    UsuariosAltaComponent,
    ProfesorTarjetaPresentacionComponent,
    CursoTarjetaComponent,
    CursosDetalleItemsComponent,
    PersonasListadoComponent,
    PersonaModificacionComponent,
    ModalConfirmarModificacionComponent,

    // Modal de cuenta - hay que sacarlo eh unificar en un modulo common
    ModalModificarClaveComponent,
    ModalModificarClaveUsersComponent,
    ModalModificarPresentacionComponent,
    ExamenesListadoComponent,
    ExamenesAlumnoComponent,
    ExamenIntroduccionComponent,
    ExamenCursarComponent,
    ExamenResultadoComponent,
    ExamenesEvaluacionesResultadosComponent,
    ExamenEvaluacionDetalleComponent,
    EvaluacionModificarComponent,
    EvaluacionConfigPreguntasComponent,
    EvaluacionInputPreguntasComponent,
    EvaluacionInputRespuestasComponent,
    ExamenRecuperatorioComponent,
    ActividadesCursosComponent,
    CalendarioCursoOnLineComponent,
    TituloModificacionComponent,
    MesasListadoComponent,
    MesaAltaComponent,
    MesaModificacionComponent,
    GestosConfiguracionComponent,
    GestosListadoComponent,
    GestoAltaComponent,
    GestoModificacionComponent,
    GestosEvaluacionesListadoComponent,
    GestosEvaluacionesUsuariosListadoComponent,
    MesasUsuariosAsociadosModalComponent,
    GestoEvaluacionModificacionComponent,
    GestosConfiguracionUsuarioComponent,
    ModalElegirMesaExamenComponent,
    MercadoPagoResultadoComponent,
    MacroPagosSuccessComponent,
    MacroPagosErrorComponent,
    BoletinAltaComponent,
    MateriaBoletinAltaComponent,
    BoletinesListadoComponent,
    MateriaBoletinAltaComponent,
    MateriasBoletinListadoComponent,
    MateriaBoletinModificacionComponent,
    BoletinModificacionComponent,
    AlumnoBoletinesModalComponent,
    AlumnoBoletinesListadoComponent,
    AlumnoBoletinDetalleModalComponent,
    BoletinNombreAltaComponent,
    BoletinNombreModificacionComponent,
    ExamenesBoletinesModalComponent
  ],
  imports: [
    OfertasLaboralesModule,
    ChartsModule,
    CommonModule,
    AppRoutingModule,
    MaterialModule,
    CarouselModule,
    BrowserAnimationsModule,
    CountUpModule,
    StickyNavModule,
    TabsModule,
    NgxScrollTopModule,
    LightboxModule,
    AccordionModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    GalleryModule,
    PipesModule,
    CommonComponentsModule,
    DatetimePickerModule
  ],
  exports: [
    CursoAltaComponent,
    CursoCompraComponent,
    CursoDescripcionCompraComponent,
    CursoHabilitadoAlumnoComponent,
    CursoModificacionComponent,
    CursosConfiguracionComponent,
    CursosDisponiblesCompraComponent,
    CursosPerfilAlumnoComponent,
    EvaluacionAltaComponent,
    EvaluacionModificarComponent,
    EvaluacionPreguntasConfigComponent,
    EvaluacionAsociarComponent,
    EvaluacionAsociarModificarComponent,
    LeccionAltaComponent,
    LeccionesConfiguracionComponent,
    MateriaAltaComponent,
    MateriaModificacionComponent,
    MateriasConfiguracionComponent,

    EvaluacionConfigComponent,
    OrdenarConceptosComponent,
    CuadriculaOpcionesComponent,
    GrillaCursosComponent,
    GrillaUsuariosComponent,
    GrillaGrupoAfinidadComponent,
    GrillaCursosModificarComponent,
    GrillaUsuariosModificarComponent,
    GrillaGrupoAfinidadModificarComponent,
    GrillaCursosModificarComponent,
    GrillaGrupoAfinidadModificarComponent,
    CursosListarComponent,
    VerdaderoFalsoComponent,
    MultipleChoiceComponent,
    ActividadesCursosComponent,
    CursosListadoAlumnoCursosComponent,
    CursosAlumnosModificarComponent,

    // Componentes en common
    CursoTarjetaComponent,
    ProfesorTarjetaPresentacionComponent,
    CursosDetalleItemsComponent,
    UsuariosAdministracionComponent,
    UsuariosAltaComponent,

    // Modal de cuenta - hay que sacarlo eh unificar en un modulo common
    ModalModificarClaveComponent,
    ModalModificarPresentacionComponent,
    CatedrasConfiguracionComponent,
    CatedraAltaComponent,

    TituloModificacionComponent,
    MesaAltaComponent,
    BoletinAltaComponent,
    MateriaBoletinAltaComponent,
    BoletinesListadoComponent,
    MateriaBoletinAltaComponent,
    AlumnoBoletinesListadoComponent,
    BoletinNombreAltaComponent,
    BoletinNombreModificacionComponent
  ],

})
export class CursosModule { }
