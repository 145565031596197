import { PaypalPurchaseUnit } from './../../../../../../interfaces/interface-frontend/shared/paypalPurchaseUnit.nterface';
import { OfertasLaboralesService } from './../../../../../../services/ofertas-laborales/ofertas-laborales.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { Solicitud } from '../../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { Actividad } from '../../../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { GuiToolsService } from '../../../../../../services/shared/gui-tools.service';
import { CatalogoService } from '../../../../../../services/shared/catalogo.service';
import { Catalogo } from '../../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { ModalConfirmarSolicitarPagoComponent } from './modal-confirmar-solicitar-pago/modal-confirmar-solicitar-pago.component';
import { IAppOfertas, APP_CONFIG_OFERTAS } from '../../../../../../app.config/app.config-ofertas';
import { Curso } from '../../../../../../interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from '../../../../../../services/cursos/cursos.service';


@Component({
  selector: 'app-modal-solicitar-pago',
  templateUrl: './modal-solicitar-pago.component.html',
  styleUrls: ['./modal-solicitar-pago.component.scss']
})
export class ModalSolicitarPagoComponent implements OnInit {

    forma: FormGroup;
    flagFormaValido = false;
    isChecked = true;
    cuentaPaypal: string;
    flagTrabajoAniosAnteriores: boolean;
    flagCostoAdministrativoPago: boolean;
    envioCobro: number; // variable que escucha cambios de valores
    flagConfirmar: boolean;
    modulos: Curso[];
    modulosId: string[]=[];
    totalModulosCosto = 0;
    selectedCurrency: string ; // Selecciona el primer elemento por defecto

    /* Paypal */
    tipoMonedas: Catalogo[] = [];
    cobroPaypal: PaypalPurchaseUnit;



    constructor(
        private guiToolsService: GuiToolsService,
        private ofertasLaboralesService: OfertasLaboralesService,
        private catalogoService: CatalogoService,
        private cursosService: CursosService,

        /* Modal Ref. */
        public dialogRef: MatDialogRef<ModalSolicitarPagoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Solicitud,
        /* Fin Modal Ref. */

        public dialog: MatDialog,


        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
    ) {
        this.getModulos();
        this.calcularTotalModulos();
        this.createForm(this.data);
        this.createListenersStateForm();
    }

    ngOnInit(): void {
        this.controlEstados(this.data);
        this.getTiposMonedas();
        this.cuentaPaypal = '****@paypal.com';
    }

    async controlEstados(data: Solicitud) {
       this.flagTrabajoAniosAnteriores =
       await  this.getPostulanteDeOfertasAnteriores(data.usuario._id, this.iAppOfertas.estado_4.number);
       this.flagCostoAdministrativoPago =
       await  this.getPostulanteDeOfertasAnteriores(data.usuario._id, this.iAppOfertas.estado_4.number);
    }

    async onSubmit() {
        this.cobroPaypal = await this.setDataForma(this.data);
        this.openDialogConfirmar(this.cobroPaypal);
    }

    cambioValoresCobro() {
        this.envioCobro = 900;
    }

    createListenersStateForm() {
        return this.forma.statusChanges.subscribe( async status => {
            if (status === 'VALID') {
                this.flagFormaValido = true;
            } else {
              this.flagFormaValido = false;
            }
        });
    }

    createForm(data: Solicitud) {
        this.forma = new FormGroup({           
            moneda: new FormControl('', [ Validators.required ]),
            totalPagar: new FormControl(this.totalModulosCosto, [ Validators.required, Validators.min(0)]),
            descuento: new FormControl(0, [ Validators.required, Validators.min(0)]),
            recargo: new FormControl(0, [ Validators.required, Validators.min(0)]),

            totalEnvioCobro: new FormControl(data.actividad.costoPostAprobacion, [ Validators.required, Validators.min(0)]),

            fechaCobro: new FormControl(
                 this.guiToolsService.datePipeTransform(new Date() , 'yyyy-MM-dd'),
                 [ Validators.required ]
             ),

            observacion: new FormControl(''),
        });
    }

    async setDataForma(data: Solicitud): Promise<PaypalPurchaseUnit>{

        const pagoPaypal: PaypalPurchaseUnit = {
            description: this.setDescripcionPago(data),
            amount: {
                currency_code: this.tipoMonedas.filter(x => x._id === this.forma.value.moneda)[0].concepto,
                value: this.forma.value.totalEnvioCobro, // dos decimales es el formato de paypal
            },
            data:
            {
                linkPaypalMe: this.forma.value.linkPaypalMe,
                moneda:  this.tipoMonedas.filter(x => x._id === this.forma.value.moneda)[0],
                totalPagar: this.forma.value.totalPagar,
                descuento: this.forma.value.descuento,
                recargo: this.forma.value.recargo,
                totalEnvioCobro: this.forma.value.totalEnvioCobro,
                fechaCobro: this.forma.value.fechaCobro,
                observacion: this.forma.value.observacion,
                solicitud: data,
            },
            importeNeto: this.forma.value.totalPagar,
            importeDecuento: this.forma.value.descuento,
            importeRecargo: this.forma.value.recargo,
        };

        return pagoPaypal;

    }

    setDescripcionPago(data: Solicitud): string {
        return  'Valor $ ' + this.totalModulosCosto;
        + ' - Pre Inscripción: '
        + this.data.actividad.nombre;
    }

    async getTiposMonedas() {
        this.tipoMonedas = await this.catalogoService.findDescendantsByKey('MONEDAS');
        this.selectedCurrency = this.tipoMonedas[0]._id; // Selecciona el primer elemento por defecto

    }

    async getPostulanteDeOfertasAnteriores(idUser: string, state: number): Promise<boolean> {
        return new Promise( async resolve => {
            await this.ofertasLaboralesService.getPostulanteAprobadoDeActividadesAnteriores(idUser, state).then( (resp: Actividad[] ) => {
                if (resp.length > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    async costoAdministrativoPagado(idUser: string, state: number): Promise<boolean> {
        return new Promise( async resolve => {
            // await this.ofertasLaboralesService.getCostoAdministrativoPagado(idUser, state).then( (resp: Actividad[] ) => {
            //     if (resp.length > 0) {
            //         resolve(true);
            //     } else {
            //         resolve(false);
            //     }
            // });
            resolve(true);
        });
    }

    cancelar(flag: boolean): void {
        this.dialogRef.close(flag);
    }

   /* Modal Confirmar */
   openDialogConfirmar(data: PaypalPurchaseUnit): void {

    const dialogRef = this.dialog.open(ModalConfirmarSolicitarPagoComponent, {
      width: '950px',
      height: '800px',
      disableClose: true,
      data
    });

    dialogRef.afterClosed().subscribe(async result => {

      if (result) {
          // Confirmo envío pago modal
          this.procearEnvioSolicitudDePago(this.cobroPaypal);
      } else {
          // NO Confirmo envío pago modal
          return;
      }

    });
  }

  procearEnvioSolicitudDePago(envioParaPagoPaypal: PaypalPurchaseUnit){
    this.dialogRef.close(envioParaPagoPaypal);
  }

  calculoTotalSolicitarCobro() {
    let calculo = 0;
    calculo = ((this.forma.value.totalPagar - this.forma.value.descuento) + this.forma.value.recargo);
    this.forma.patchValue({
        totalEnvioCobro:  calculo
    });
    return calculo;
  }

  getModulos(): Curso[] {
    this.modulos = this.data.cursos;
    return this.modulos;
  }

  calcularTotalModulos() {
    this.totalModulosCosto = 0;
    this.modulos.forEach(m => this.totalModulosCosto = this.totalModulosCosto + m.precio);      
    return this.totalModulosCosto;
  }

    /* Validaciones */

    get fechaCobro() {
        return this.forma.get('fechaCobro').invalid && this.forma.get('fechaCobro').touched;
    }

    get totalPagar() {
        return this.forma.get('totalPagar').invalid && this.forma.get('totalPagar').touched;
    }

    get totalEnvioCobro() {
        return this.forma.get('totalEnvioCobro').invalid && this.forma.get('totalEnvioCobro').touched;
    }

    get descuento() {
        return this.forma.get('descuento').invalid && this.forma.get('descuento').touched;
    }

    get recargo() {
        return this.forma.get('recargo').invalid && this.forma.get('recargo').touched;
    }

    get moneda() {
        return this.forma.get('moneda').invalid && this.forma.get('moneda').touched;
    }

}
