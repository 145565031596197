import { Router } from '@angular/router';
import { Component, OnInit, AfterViewInit, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GuiMsjService } from './../../../../services/shared/gui-msj.service';
import { Actividad } from './../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { Pais } from './../../../../interfaces/interface-bakend/shared/paises/pais.interfaces';
import { PaisDisponible } from './../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PaisesService } from '../../../../services/shared/paises.service';
import { RegExpService } from '../../../../services/shared/reg-exp.service';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { SolicitudesService } from '../../../../services/ofertas-laborales/solicitudes.service';
import { CatalogoService } from '../../../../services/shared/catalogo.service';
import { Catalogo } from '../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { IAppShared } from '../../../../app.config/app.config-shared';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../../../app.config/app.config-ofertas';
import { GuiToolsService } from '../../../../services/shared/gui-tools.service';
import { ModalConfirmarComponent } from './modal-confirmar/modal-confirmar.component';
import { Solicitud } from './../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { APP_CONFIG_SHARED } from './../../../../app.config/app.config-shared';
import { Subscription } from 'rxjs';
import { Translators } from '../../../../services/shared/translators.service';
import { OpenPageService } from '../../../../services/shared/open-page.service';
import { DocumentosEstadosSolicitar } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { FechaPickerPipe } from 'src/app/pipes/ofertas-laborales/fecha-picker.pipe';

/**
 * Fecha: 04/05/2021
 * Requerimiento: nuevos campos para formulario de registro de pre inscripciones
 * Modificación para Aadidess J.C RAMOS. 
 * campos:
 * delegacion: catalogo
 * disciplina: catalogo
 * enfermedad: Booleano
 * hospitalizado: Booleano
 * tido documento:  ahora es DNI - SE DEFINIO EN CONSTANTES (cambio por PASAPORTE EL _id): 
 * La modificación del tipo de documento se aplica al momento de registro, luego queda seteado por defaul mediante this.iAppConfig.dniid
 */

@Component({
  selector: 'app-solicitud-postular',
  templateUrl: './solicitud-postular.component.html',
  styleUrls: ['./solicitud-postular.component.scss']
})
export class SolicitudPostularComponent implements OnInit {

    forma: FormGroup;
    actividad: Actividad;
    pais: PaisDisponible;
    nombrePais: string;
    nombreActividad: string;
    solicitud: Solicitud;
    flagLoaded = false;
    flagProcesando = false;
    flagFormaValido = false;
    paises: Pais[] = [];
    paisCodeAreas: Pais[] = [];
    estadosCiviles: Catalogo[] = [];
    tiposIdentificaciones: Catalogo[] = [];
    paisDondeAplicaFormas: Catalogo[] = [];
    idiomas: Catalogo[] = [];
    selectedEstadoCivil: Catalogo;
    selectedTipoIdentificacion: Catalogo;
    selectedCondiMigra: Catalogo;
    selectedPais: Pais;
    usuario: User;
    flagExisteUsuarioConIdentificacion = false;
    flagExisteIdentificacion = false;
    flagPreCarga = false;
    flagoConfirmData = false;
    flagTitulo = false;

    idioma: Subscription;

    // AADIDESS
    delegaciones: Catalogo[] = [];
    selectedDelegacion: Catalogo;

    disciplinas: Catalogo[] = [];
    selectedDisciplina: Catalogo;

    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        @Inject(APP_CONFIG_SHARED) private iAppShared: IAppShared,
        private paisesService: PaisesService,
        private actividadesService: ActividadesService,
        private solicitudesService: SolicitudesService,
        private catalogoService: CatalogoService,
        private usarioService: UsuarioService,
        private authservice: AuthService,
        private guiMsjService: GuiMsjService,
        private guiToolsService: GuiToolsService,
        private router: Router,
        private usuariosService: UsuarioService,
        public dialog: MatDialog,
        private translatorService: Translators,
        private openPageService: OpenPageService,
        private fechaPickerPipe: FechaPickerPipe,
        private cdRef: ChangeDetectorRef,
    ) {

        this.idioma =  this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
            this.guiToolsService.refreshComponent('/solicitud-postular');
        });

        this.createForm();
        this.createListenersStateForm();
    }

    ngOnInit(): void {
        /**
         * * LUCAS: Es necesario cargar el arreglo de las delegaciones y las diciplinas antes del seteo de los selectores para que el primer renderizado de la app se inicie correctamente ya que se esta modificando el objeto "usuario"
         * */
        this.getDelegaciones();
        this.getDisciplinas();
    }

    async ngAfterViewInit(): Promise<void> {
        await this.getData();
        this.cdRef.detectChanges(); 
    }
    
    async getData() {

        await this.getPaises();
        await this.getActividad();
        await this.getPais();
        await this.getSolicitud();
        await this.getEstadosCiviles();
        await this.getTipoIdentificaciones();
        await this.getPaisDondeAplicaFormas();
        await this.cargarFormulario(
            // tslint:disable-next-line:max-line-length
            // hay que buscar por identificacion acon el cambio de bruno y que me traiga si tiene o no solicitud
            // por el momento hata ver bien esto, sigue asi
            await this.getFindOneByEmail(
                await this.solicitudesService.getSolicitudSeleccionada().usuario.email
            )
        );

        this.flagLoaded = true;
    }

    /* Métodos form */

    async onSubmit(){
        /* 0- confirmo datos
        /* 1- update user */
        /* 2- update solicitud estado */
        /* 3- refreshing state solicitud */
        if (!this.controlSelectores()) { return }

         const usuarioUpdated: User =  await this.setDataForma();
    
        this.flagoConfirmData = await this.openDialogConfirmData(usuarioUpdated);

        if (this.flagoConfirmData) {
            this.flagFormaValido = false;
            this.flagProcesando = true;

            usuarioUpdated.hospitalizado = Boolean(usuarioUpdated.hospitalizado);
            usuarioUpdated.tituloSecundario = Boolean(usuarioUpdated.tituloSecundario)
            usuarioUpdated.enfermedad = Boolean(usuarioUpdated.enfermedad);

            this.cactualizarUsuarioYestadoSolicitud(usuarioUpdated);
        } else {
            return;
        }

    }

    async cargarFormulario(flagExisteUsuarioConIdentificacion: boolean) {

		if (flagExisteUsuarioConIdentificacion) {
            this.flagPreCarga = true;
            await this.cargaDeSelectoresDisciplinaDelegacion(this.usuario);
            await this.cargarFormDatosRegistro(this.usuario);
        } else {
            this.flagPreCarga = false;
            return;
        }

    }

    createForm() {
      this.forma = new FormGroup({
          nombre: new FormControl('', [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
          apellido: new FormControl('', [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
          tipoIdentificacion: new FormControl('',  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
          identificacion: new FormControl('',  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),         
          paisNacimiento: new FormControl('',  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
          estadoCivil: new FormControl('',),
          paisCodeArea: new FormControl('', [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
          telefono: new FormControl('', [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
          condiMigra: new FormControl('',),
          pasaporteValido: new FormControl('',),
          ca: new FormControl('', ),
          es: new FormControl('', ),
          fr: new FormControl('', ),
          en: new FormControl('', ),
          birth: new FormControl(
            this.guiToolsService.datePipeTransform
            (
               new Date().setFullYear(new Date().getFullYear() - 35
            ), 'YYYY-MM-DD'),
        [ Validators.required ]),

        // AADIDESS
        delegacion: new FormControl('',  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
        disciplina: new FormControl('',  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]), 
        enfermedad: new FormControl('', [ Validators.required ] ),
        hospitalizado: new FormControl('', [ Validators.required] ),
        declaracion: new FormControl('', [ Validators.requiredTrue] ),
        cp: new FormControl('', [ Validators.required] ),
        tituloSecundario: new FormControl('', [ Validators.required] ),
        tituloDe: new FormControl('',  ),
        tituloExpedido: new FormControl('',  )
      });
    }

    /**
     * * LUCAS: usuario.diciplina/usuario.delegacion no es un ObjectId en la BD, viene como una cadena, hay que mutar el usuario ya que usuario.disciplina/usuario.delegacion es de tipo string y no de tipo Catalogo
    **/
    async cargaDeSelectoresDisciplinaDelegacion(usuario :User) {
        await this.setValueDisciplina(usuario?.disciplina);
        await this.setValueDelegacion(usuario?.delegacion);
    }

    async setValueDisciplina(userDisciplina :Catalogo) {
        const idDisciplina: any = userDisciplina;
        const elementoDisciplina = this.disciplinas.find((disciplina: Catalogo) => disciplina?._id === idDisciplina);
        this.usuario = {
            ...this.usuario,
            disciplina: {
            _id: idDisciplina,
            key: '',
            concepto: elementoDisciplina?.concepto,
            properties: '',
            parent: ''
            }
        };

        this.forma.patchValue({
            disciplina: elementoDisciplina?.concepto,
        });
        this.selectedDisciplina = this.usuario?.disciplina;
    }

    async setValueDelegacion(userDelegacion: Catalogo) {
        const idDelegacion: any = userDelegacion;   
        const elementoDelegacion = this.delegaciones.find((delegacion: Catalogo) => delegacion?._id === idDelegacion);
        
        this.usuario = {
            ...this.usuario,
            delegacion: {
            _id: idDelegacion,
            key: '',
            concepto: elementoDelegacion?.concepto,
            properties: '',
            parent: ''
            }
        };

        this.forma.patchValue({
            delegacion: elementoDelegacion?.concepto,
        });
        this.selectedDelegacion = this.usuario?.delegacion;
    }

    async cargarFormDatosRegistro(usuario: User) {
        this.forma.controls['nombre'].setValue(usuario.nombrePersona);
        this.forma.controls['apellido'].setValue(usuario.apellidoPersona);
        this.forma.controls['tipoIdentificacion'].setValue(usuario.tipoIdentificacion?.concepto);
        this.forma.controls['identificacion'].setValue(usuario.identificacion);
        this.forma.controls['paisNacimiento'].setValue(usuario?.paisNacimiento?.name_es);
        this.forma.controls['paisCodeArea'].setValue(usuario?.paisCodeArea);
        this.forma.controls['telefono'].setValue(usuario?.telefono);
        this.forma.controls['birth'].setValue(this.fechaPickerPipe.transform(usuario?.birth));
        this.forma.controls['cp'].setValue(usuario?.cp);
        this.forma.controls['disciplina'].setValue(usuario?.disciplina?.concepto);
        this.forma.controls['tituloSecundario'].setValue(usuario?.tituloSecundario);
        this.forma.controls['enfermedad'].setValue(usuario?.enfermedad);
        this.forma.controls['hospitalizado'].setValue(usuario?.hospitalizado);
        this.forma.controls['tituloDe'].setValue(usuario?.tituloDe);
        this.forma.controls['tituloExpedido'].setValue(usuario?.tituloExpedido);

        // cargo los selectores
        this.selectedTipoIdentificacion = usuario.tipoIdentificacion;
        this.selectedPais = usuario?.paisNacimiento;

        this.flagTitulo= usuario?.tituloSecundario;
        this.flagFormaValido = false;
    }

    async setDataForma(): Promise<User>{
        const dataForma: User = {
            nombrePersona: this.forma.value.nombre.toUpperCase(),
            apellidoPersona: this.forma.value.apellido.toUpperCase(),

            tipoIdentificacion: this.selectedTipoIdentificacion,

            identificacion: this.forma.value.identificacion,

            birth: this.forma.value.birth,

            paisNacimiento: this.selectedPais,

            estadoCivil: this.findObjectEstadoCivil(this.forma.value.estadoCivil),

            paisCodeArea: this.forma.value.paisCodeArea,
            telefono: this.forma.value.telefono,

            condiMigra: this.selectedCondiMigra,

            // pasaporteValido: this.forma.value.pasaporteValido, 
            pasaporteValido: false, // especifico para aadidess en false
            idiomaCa: this.forma.value.ca || false,
            idiomaEs: this.forma.value.es || false,
            idiomaFr: this.forma.value.fr || false,
            idiomaEn: this.forma.value.en || false,
            fromApp: false,
            fromWeb: true,

            esPersona: true,

            // AADIDESS
            delegacion: this.selectedDelegacion,
            disciplina: this.selectedDisciplina,
            enfermedad: this.forma.value.enfermedad,
            hospitalizado: this.forma.value.hospitalizado,
            declaracion: this.forma.value.declaracion,
            cp: this.forma.value.cp,
            tituloSecundario: this.forma.value.tituloSecundario,
            tituloExpedido: this.forma.value.tituloExpedido,
            tituloDe: this.forma.value.tituloDe
        };

        return dataForma;

    }

    createListenersStateForm() {
        return this.forma.statusChanges.subscribe( async status => {
            //   console.log({ status });
            //   console.log( this.forma );
            // cuando cargo el form, valido el tipo de documento y nro identificacion para pre-cargar
            // si no existe el email y datos de la persona, valido el tipo y nro.
            if (this.flagLoaded && !this.flagPreCarga && this.forma.value.identificacion) {
            this.flagExisteIdentificacion =
            await this.usuariosService.
            getExistsTipoAndIdentificacion(this.selectedTipoIdentificacion._id, this.forma.value.identificacion);
            }
            if (status === 'VALID' && !this.flagExisteIdentificacion) {
                this.flagFormaValido = true;
            } else {
            this.flagFormaValido = false;
            }
        });
    }

    controlSelectores(): boolean {
        let flag = true;
        if (this.selectedPais == null) {
            alert('seleccione una opción PAIS correcta');
            this.forma.controls['paisNacimiento'].setValue('');
            flag = false;
        }   
        if (this.selectedDelegacion == null) {
            alert('seleccione una opción  DELEGACION correcta');
            this.forma.controls['delegacion'].setValue('');
            flag = false;
        }   
        if (this.selectedDisciplina == null) {
            alert('seleccione una opción DISCIPLINA correcta');
            this.forma.controls['delegacion'].setValue('');
            flag = false;
        } 
        return flag;  
    }

    /* FIN Métodos form */

    cactualizarUsuarioYestadoSolicitud(usuarioUpdated: User) {
        this.authservice.getUserLogon().then( ( userLogon: User )  => {
                this.usarioService.updateUser( userLogon._id, usuarioUpdated ).then( (usuario: User) => {
                const _id = this.solicitud._id;
                const numberState = this.iAppOfertas.estado_1.number;
                this.solicitudesService.updateSolicitudState(_id, numberState, false).then( async (sol: Solicitud) => {
                    this.flagProcesando = false;
                    await this.solicitudesService.setSolicitudSeleccionada(sol); // refresh service state
                    this.guiMsjService.msjFormSubmit('formularioCargado');
                    this.router.navigateByUrl('/solicitud');
                });
            });
        });
    }

    async getFindOneByEmail(email: string): Promise<boolean> {

        return new Promise( resolve => {
            this.usarioService.getFindOneByEmail(email).then( (resp: User) => {
                if (resp !== null && resp.identificacion !== undefined) {
                    this.usuario = resp;
                    this.flagExisteUsuarioConIdentificacion = true;
                    resolve(true);
                } else {
                    this.flagExisteUsuarioConIdentificacion = false;
                    resolve(false);
                }
            });
        });

    }

    onChangePais(event): void {
        this.selectedPais = this.paises.filter(x => x.name_es === event)[0];          
    }

    onChangeDelegacion(event): void {
        this.selectedDelegacion = this.delegaciones.filter(x => x.concepto === event)[0];
    }

    onChangeDisciplina(event): void {
        this.selectedDisciplina = this.disciplinas.filter(x => x.concepto === event)[0];
    }

    onChangeTipoIdentificacion(event): void {
        this.selectedTipoIdentificacion = this.tiposIdentificaciones.filter(x => x.concepto === event)[0];
    }

    onChangeCondiMigra(event): void {
        this.selectedCondiMigra = this.paisDondeAplicaFormas.filter(x => x.concepto === event)[0];
    }

    onChangeEstadoCivil(event): void {
        this.selectedEstadoCivil = this.estadosCiviles.filter(x => x.concepto === event)[0];
    }

    async getPaises() {
        await this.paisesService.getPaises().then((resp: Pais[]) => {
            this.paises = resp;
        });
    }

    async getActividad(){
        this.nombreActividad = this.actividadesService.getActividadSeleccionada()?.nombre;
        return this.actividad = await this.actividadesService.getActividadSeleccionada();
    }

    async getPais(){
        this.nombrePais = this.paisesService.getPaisSeleccionado().pais.name_es;
        return this.pais =  await this.paisesService.getPaisSeleccionado();
    }

    async getSolicitud() {
        return this.solicitud = await this.solicitudesService.getSolicitudSeleccionada();
    }

    getEstadosCiviles() {
        this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.estadoCivil,
            this.translatorService.getIdioma()).
            then( (resp: Catalogo[]) => {
                return this.estadosCiviles =  resp;
        });
    }

    getTipoIdentificaciones() {
        this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.tiposIdentificaciones,
            this.translatorService.getIdioma()).
            then( (resp: Catalogo[]) => {
                return this.tiposIdentificaciones =  resp;
            });
    }

    getPaisDondeAplicaFormas() {
        this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.paisDondeAplica,
            this.translatorService.getIdioma()).then( (resp: Catalogo[]) => {
            return this.paisDondeAplicaFormas =  resp;
        });
    }

    getIdiomas() {
        this.catalogoService.findDescendantsByKey(this.iAppShared.tiposIdioma).then( (resp: Catalogo[]) => {
            return this.idiomas =  resp;
        });
    }

    openDialogConfirmData(data: User): Promise<boolean> {

        return new Promise<boolean>( resolve => {
            const dialogRef = this.dialog.open(ModalConfirmarComponent, {
                width: '800px',
                height: '800px',
                disableClose: true,
                data
            });

            dialogRef.afterClosed().subscribe( (result: boolean) => {
                resolve(result);
            });
        });

    }

    findObjectEstadoCivil(event) {
        return this.estadosCiviles.filter(x => x._id === event)[0];
    }

    /*  AADIDESS */
    getDelegaciones() {
        this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.delegaciones, null).
            then( (resp: Catalogo[]) => {
                return this.delegaciones =  resp;
            });
    }

    getDisciplinas() {
        this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.disciplinas ,null).
            then( (resp: Catalogo[]) => {
                return this.disciplinas =  resp;
            });
    }

    openBlank(type: string) {
        this.openPageService.openModal(type);
    }

    /*  FIN AADIDESS */


    /* Validaciones */

    get nombre() {
        return this.forma.get('nombre').invalid // && this.forma.get('nombre').touched;
    }

    get apellido() {
        return this.forma.get('apellido').invalid // && this.forma.get('apellido').touched;
    }

    get tipoIdentificacion() {
        return this.forma.get('tipoIdentificacion').invalid // && this.forma.get('tipoIdentificacion').touched;
    }

    get identificacion() {
        return this.forma.get('identificacion').invalid // && this.forma.get('identificacion').touched;
    }

    get birth() {
        return this.forma.get('birth').invalid // && this.forma.get('birth').touched;
    }

    get paisNacimiento() {
        return this.forma.get('paisNacimiento').invalid // && this.forma.get('paisNacimiento').touched;
    }

    get delegacion() {
        return this.forma.get('delegacion').invalid // && this.forma.get('delegacion').touched;
    }

    get disciplina() {
        return this.forma.get('disciplina').invalid // && this.forma.get('disciplina').touched;
    }

    get estadoCivil() {
        return this.forma.get('estadoCivil').invalid // && this.forma.get('estadoCivil').touched;
    }

    get paisCodeArea() {
        return this.forma.get('paisCodeArea').invalid // && this.forma.get('paisCodeArea').touched;
    }

    get condiMigra() {
        return this.forma.get('condiMigra').invalid //  && this.forma.get('condiMigra').touched;
    }

    get telefono() {
        return this.forma.get('telefono').invalid // && this.forma.get('telefono').touched;
    }

    get pasaporteValido() {
        return this.forma.get('pasaporteValido').invalid && this.forma.get('pasaporteValido').touched;
    }

    get declaracion() {
        return this.forma.get('declaracion').invalid && this.forma.get('declaracion').touched;
    }

    get cp() {
        return this.forma.get('cp').invalid && this.forma.get('cp').touched;
    }

}
