import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    path: string;
    tituloDocumento: string;
}


@Component({
  selector: 'app-ofertas-ver-pdf',
  templateUrl: './ofertas-ver-pdf.component.html',
  styleUrls: ['./ofertas-ver-pdf.component.scss']
})
export class OfertasVerPdfComponent implements OnInit {

  path: string;
  tituloDocumento: string;

  constructor(
       /* Modal Ref. */
       public dialogRef: MatDialogRef<OfertasVerPdfComponent>,
       @Inject(MAT_DIALOG_DATA) public data: DialogData)   { }

  ngOnInit(): void {
    this.path = this.data.path;
    this.tituloDocumento = this.data.tituloDocumento;
  }

  closeModal(): void {
    this.dialogRef.close();
  }


}
