import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ExamenEvaluacion } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';
import { Examen } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';

@Component({
  selector: 'app-examen-evaluacion-detalle',
  templateUrl: './examen-evaluacion-detalle.component.html',
  styleUrls: ['./examen-evaluacion-detalle.component.scss']
})
export class ExamenEvaluacionDetalleComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ExamenEvaluacionDetalleComponent>,
    private examenService: ExamenesService, 
    private router: Router,
    private cursosService: CursosService,
    private usuarioServices: UsuarioService) { }

  evaluacion: ExamenEvaluacion;
  examenEvaluado: Examen;
  flagLoaded = false;
  alumno: any;
  ngOnInit(): void {
    this.getExamenEvaluacionFinal();
  }

  async getExamenEvaluacionFinal() {
    console.log('this.examenService.getExamenEvaluacionId()', this.examenService.getExamenEvaluacionId());
    if(this.examenService.getExamenEvaluacionId() !== undefined){
      await this.examenService.getEvaluacionFindOne(this.examenService.getExamenEvaluacionId()).then(async (examenEvaluadoFinal: any)=>{
        this.alumno = await this.usuarioServices.getFindOne(examenEvaluadoFinal.usuario._id);
        this.evaluacion = examenEvaluadoFinal;
        this.examenEvaluado = examenEvaluadoFinal.respuesta;
        this.flagLoaded = true;
      })
    }else{
      this.router.navigateByUrl('/examenes-resultados')
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
