<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li>Listado Personas</li>
            </ul>
            <h2>Listado Personas</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-area pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <h3>Listado de Personas</h3>
            <a (click)="cargarPersona()"><i class="fas fa-plus"></i> Agregar Nueva</a>
            <div class="search-container">
                <mat-form-field class="search-input">
                    <mat-label>Buscar</mat-label>
                    <input matInput [(ngModel)]="searchTerm" placeholder="Buscar por nombre, apeddilo, DNI o email.."
                        autocomplete="off">
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="buscarPersona()">Buscar</button>
            </div>

            <app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

            <div class="mat-elevation-z8">

                <table mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{element.nombrePersona}} </td>
                    </ng-container>

                    <ng-container matColumnDef="apellido">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido </th>
                        <td mat-cell *matCellDef="let element"> {{element.apellidoPersona}} </td>
                    </ng-container>

                    <ng-container matColumnDef="identificacion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Identificación </th>
                        <td mat-cell *matCellDef="let element"> {{element.identificacion}} </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <ng-container matColumnDef="telefono">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
                        <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
                    </ng-container>

                    <ng-container matColumnDef="_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
                        <td mat-cell *matCellDef="let element" style="width: 50%;">
                            <div style="display: inline-flex;">

                                <button type="button" (click)="openDialogVerBoletines(element._id)"
                                    [disabled]="isButtonDisabled" style="margin-right: 5px;"
                                    class="btn btn-success d-flex align-items-center" title="Ver Boletines">
                                    <i class="fa fa-book fa-fw" aria-hidden="true"></i> Boletines
                                </button>

                                <button style="margin-right: 5px;"
                                    class="btn btn-primary d-flex align-items-center ml-2"
                                    (click)="verPuntajesCargados(element._id)" type="button"
                                    title="Ver Puntajes Cargados">
                                    <i class="fa fa-hand-spock-o"></i> Puntajes
                                </button>

                                <button class="btn btn-warning d-flex align-items-center ml-2"
                                    (click)="cargarTitulo(element._id)" type="button" title="Editar Persona">
                                    <i class="fas fa-edit"></i> Editar
                                </button>

                            </div>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                </table>

            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 50, 100, 150, 200]" showFirstLastButtons></mat-paginator>

        </div>
    </div>
</div>