<h2 mat-dialog-title>TÉRMINOS Y CONDICIONES DE REGISTRO</h2>
<mat-dialog-content class="mat-typography">
    <h3>Esta Web pertenece a la Empresa AADIDESS (en adelante LA EMPRESA) y su acceso y utilización está sujeta a la aceptación y cumplimiento de los términos y condiciones que se exponen a continuación:</h3>

    <h3>Información de esta web</h3>
    <p>Esta página muestra información sobre precios de cursos, escuelas, destinos y mucho más. AADIDESS no puede garantizar que todas las ofertas laborales estén disponibles a los precios mostrados en todo momento, ya que tienen mucha variabilidad. Asimismo,
        hemos puesto mucho cuidado y atención a que dicha información esté siempre lo más actualizada posible.</p>

    <h3>Precios, restricciones y condiciones</h3>
    <p>Pueden existir restricciones y condiciones en todos los productos, ofertas y servicios ofrecidos en esta página web, y dichas condiciones y restricciones pueden afectar a los precios mostrados. Por estas razones los precios mostrados en esta página
        están sujetos a cualquier cambio sin previo aviso. Muchos de los productos, cursos y servicios están sujetos a disponibilidad. El cliente es responsable de tener su pasaporte en regla, y toda la información necesaria para viajar. Nuestro equipo
        puede apoyar a nuestros clientes en la obtención de dicha información, sin embargo, la última responsabilidad es del cliente.</p>

    <h3>Propiedad Intelectual</h3>
    <p>Todo el material que contiene esta página web (sin ninguna limitación) incluyendo el software, el diseño, texto y gráficos (incluidas marcas registradas) es propiedad de AADIDESS. Esta página debe ser usada para propósitos personales y no comerciales.
        Marcas, sean registradas o no registradas y sus logos no deben ser usadas ni modificadas sin obtener previamente un consentimiento por escrito.</p>

    <h3>Uso de la página web</h3>

    <p>Para acceder a algunos de nuestros servicios, puede ser necesario registrarse en la página y hacerse una cuenta con la dirección de email y una contraseña (” tu cuenta”). El email con el que se registre un usuario debe ser el suyo propio, y dicho
        usuario es el único responsable de mantener dicha contraseña confidencial, al igual que todas las actividades que sucedan dentro de su cuenta.</p>

    <h3>General</h3>

    <p>Última actualización de estos términos y condiciones: Noviembre 2021.</p>

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button mat-button [mat-dialog-close]="true" (click)="cancelar()">Cancelar</button>
    <button mat-raised-button mat-button [mat-dialog-close]="true" (click)="aceptar()" cdkFocusInitial>Aceptar</button>
</mat-dialog-actions>