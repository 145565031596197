import { Component, OnInit, Input } from '@angular/core';
import { NotaLegalComponent } from './nota-legal/nota-legal.component';
import { MatDialog } from '@angular/material/dialog';
import { Translators } from '../../services/shared/translators.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { OpenPageService } from '../../services/shared/open-page.service';
@Component({
  selector: 'app-modal-legal',
  templateUrl: './modal-legal.component.html',
  styleUrls: ['./modal-legal.component.scss']
})
export class ModalLegalComponent implements OnInit {

  idioma: Subscription;
  @Input() CFR = false; // cláusula formulario registro
  @Input() CFA = false; // cláusula formulario ayuda
  @Input() TC = false; // términos y condiciones
  @Input() PDP = false; // protección datos personales 

  constructor(
      private translatorService: Translators,
      public dialog: MatDialog,
      private openPageService: OpenPageService

  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        this.idioma = resp;
    });
  }

  ngOnInit(): void {
  }


  openModal(type) {
      const dialogRef = this.dialog.open(NotaLegalComponent,{

          width: '1200px',
          height: '800px',
          data: type,
        //   disableClose: false;
      });
      dialogRef.afterClosed().subscribe(async result => {});
      return;
  }

  openBlank(type: string) {
    this.openPageService.openBlank(type);
  }

}


