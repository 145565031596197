import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from '../shared/storage.service';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '../../interfaces/interface-bakend/response';
import { GuiMsjService } from '../shared/gui-msj.service';
import { Router } from '@angular/router';

const URL_BACKEND = environment.urlBackend;
@Injectable({
  providedIn: 'root'
})
export class PerfilesService {

  constructor(
      private authService: AuthService,
      private httpClient: HttpClient,
      private guiMsjService: GuiMsjService,
      private router: Router,
  ) { }


  async allowedByUserLogon(redirectTo: string): Promise<boolean>{
    return new Promise( async resolve => {
        const headers =  new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        const data = {
            redirectTo,
            tipoMenu: 'menuWeb'
        };

        this.httpClient.post<Response>(`${ URL_BACKEND }/perfiles/allowedByUserLogon/`,  data , { headers })
            .subscribe( resp => {
                // console.log('usario con permisos para url', resp.response);
                if (!resp.response) {
                    this.router.navigateByUrl('/home');
                    this.guiMsjService.msjFormSubmit('SinPermisoParaUrl');
                    resolve(false);
                } else {
                    resolve(true);
                }
            });
        });
  }
}
