import { Materia } from './../../../../interfaces/interface-bakend/cursos/materia.interface';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { MateriasService } from '../../../../services/cursos/materias.service';
import { LeccionesService } from '../../../../services/cursos/lecciones.service';
import { MediaService } from '../../../../services/shared/media.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { Leccion } from '../../../../interfaces/interface-bakend/cursos/leccion.interface';

@Component({
  selector: 'app-leccion-modificacion',
  templateUrl: './leccion-modificacion.component.html',
  styleUrls: ['./leccion-modificacion.component.scss']
})
export class LeccionModificacionComponent implements OnInit {

  form: FormGroup;
  leccion: Leccion;
  currentDate = new Date();
  flagLoaded = false;
  materias: Materia[] = [];
  estado: boolean = true;

  // Upload
  flagSelectFile = false;
  selectedFile: any;
  value = 0;
  cargandoArchivo = false;
  subscriptionAvance: Subscription;

  condition = false;
  fileName = 'No file selected';
  file: File;
  imageUrl: string | ArrayBuffer = './assets/img/userDefault.png';

  constructor(
    private leccionesService: LeccionesService,
    private materiasService: MateriasService,
    private router: Router ,
    private mediaService: MediaService,
    private guiMsjService: GuiMsjService,
    ) {
    this.getMaterias();
    this.subscribeAvanceUpload();

  }

  ngOnInit(): void {
    this.getEstadoVariables();
    this.createForm();
  }


  get nombreNoValido(){
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  get descripcionNoValida(){
    return this.form.get('descripcion').invalid && this.form.get('descripcion').touched;
  }

  get fechaNoValida(){
    return this.form.get('fechaInicio').invalid && this.form.get('fechaInicio').touched;
  }

  get fechaFinNoValida(){
    return this.form.get('fechaFin').invalid && this.form.get('fechaFin').touched;
  }

  async getMaterias(){
    this.materiasService.getMaterias().then( (materias : Materia[]) =>{
        this.materias = materias;
    });
  }

  createForm() {
    this.form = new FormGroup({
        nombre: new FormControl(this.leccion.nombre, [ Validators.required]),
        descripcion: new FormControl(this.leccion.descripcion, [ Validators.required]),
        fechaInicio: new FormControl(this.leccion.fechaInicio, [ Validators.required]),
        fechaFin: new FormControl(this.leccion.fechaFin, [ Validators.required]),
        imagen: new FormControl('',),
        puntaje: new FormControl(this.leccion.puntaje),
        estado: new FormControl(this.leccion.estado),
        orden: new FormControl(this.leccion.orden),
        materia: new FormControl(this.leccion.materia,[ Validators.required]),
        file: new FormControl(''),
    });
  }

  getLeccion(){
    return this.leccion = this.leccionesService.getLeccionSeleccionada();
  }

  async getEstadoVariables(){
    if (this.getLeccion() === undefined) {
        this.router.navigateByUrl('/lecciones-listado');
        return;
    } else {
        this.getLeccion();
        this.flagLoaded = true;

    }
  }

  async put(){
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.leccionesService.put(this.leccion._id,await this.setDataForma()).then( async (leccion: Leccion) => {
      if(this.condition){
        await this.uploadFile(this.selectedFile, leccion._id);
      }
      await this.guiMsjService.msjFormSubmit('DatosGuardados');
      this.router.navigateByUrl('/lecciones-listado');

      return;
    });
  }

  onChangeFile(file: File, event){
    this.condition = true;
    if(file){
      this.openFileSelected(event);
      this.fileName = file.name;
      this.file = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = e => {
        this.imageUrl = reader.result;
      }
    }
  }

  async setDataForma(): Promise<Leccion>{

    const data: Leccion = {
        nombre: this.form.value.nombre,
        descripcion: this.form.value.descripcion,
        fechaInicio: this.form.value.fechaInicio,
        fechaFin: this.form.value.fechaFin,
        puntaje: this.form.value.puntaje,
        estado: this.form.value.estado,
        orden: +this.form.value.orden,
        materia: this.form.value.materia
    };

    return data;

  }



  /* UPLOAD */

    /* subo archivo con observable*/
    subscribeAvanceUpload(){
      this.subscriptionAvance = this.mediaService.getAvance().subscribe(resp => {
      if (resp) {
          this.value = resp.value;
      } else {
          this.value = 0;
          }
      });
    }

    openFileSelected(event) {
      this.selectedFile = <File> event.target.files[0];
      console.log(<File> event.target.files[0]);
      if(<File> event.target.files[0] === undefined){
        this.condition = false;
      }else{
        this.condition = true;
      }
    }

    getTypeFile(type): string {
      switch (type) {
          case 'image/jpeg': // img - image/png
            return 'img';
            break;

          case 'image/png': // img - image/png
            return 'img';
            break;

          case 'video/mp4': // mp4 - video/mp4
            return 'mp4';
            break;

          case 'application/pdf': // pdf - application/pdf
            return 'pdf';
            break;

          default:
            return 'sinEspecificar';
            break;
        }
  }

    async uploadFile(selectedFile, idLeccion) {
      this.cargandoArchivo = true;
      const type = this.getTypeFile(selectedFile.type);
      await this.mediaService.uploadUpdateLeccion(selectedFile, idLeccion, type).then( resp => {
        if (resp) {
          if (this.value === 100) {this.cargandoArchivo = false; }
        } else {
          this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
        }
      });
    }
 /* FIN UPLOAD */

}
