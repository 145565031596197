<br>
<section>
    <mat-label>Agregar Concepto</mat-label>
    <button (click)="agregarConcepto()" mat-mini-fab color="primary" aria-label="Example icon button with a plus one icon">
        <mat-icon>plus_one</mat-icon>
      </button>
</section>
<br>
<mat-divider></mat-divider>
<br>
<form [formGroup]="forma">
    <cdk-virtual-scroll-viewport *ngIf="conceptos.controls.length> 0" appendOnly itemSize="50" class="scroll-viewport">
        <table>
            <thead>
                <th>N°</th>
                <th>Concepto</th>
                <th>Borrar</th>
            </thead>
            <tbody>
                <tr *ngFor="let control of conceptos.controls; let i=index">
                    <td width="10%">{{ i+1 }}</td>
                    <td class="animate__animated animate__fadeInUp">
                        <mat-form-field appearance="fill" style="width: 800px;">
                            <mat-label>Escriba su concepto ordenado aquí</mat-label>
                            <textarea rows="2" cols="40" [formControl]="conceptos.controls[i]" matInput></textarea>
                        </mat-form-field>
                    </td>
                    <td>
                        <button class="btn-tabla" (click)="borrarConcepto(i)"><i class="far fa-trash-alt"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </cdk-virtual-scroll-viewport>
</form>
<br>
<mat-divider></mat-divider>
<br>
<mat-card>
    <mat-card-title>El usuario deberá ordenar los conceptos .</mat-card-title>
    <p>
        El sistema desordenara los conceptos ingresados, para que el alumno los ordene. Como en el ejemplo a continuación.
    </p>
    <div *ngIf="random.length > 0" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let concepto of random" cdkDrag>
            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
            {{ concepto }}
        </div>
    </div>
</mat-card>
