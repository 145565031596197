import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../shared/storage.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Gesto } from 'src/app/interfaces/interface-bakend/cursos/gesto.interface';
import { environment } from 'src/environments/environment';
import { Response } from '../../interfaces/interface-bakend/response';
import Swal from 'sweetalert2';
import { GestoUsuario, GestoUsuarioCargaMasiva } from 'src/app/interfaces/interface-bakend/cursos/gestoUsuario.interface';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class GestosService {

  gestoSeleccionado: Gesto;
  gestoEvaluadoSeleccionado: GestoUsuario;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private authService: AuthService,
  ) { }

  getGestos(): Promise<Gesto[]> {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/materias/examenes/gestos/findAll`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });

    });
  }

  getGestoById(id: string): Promise<Gesto> {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/materias/examenes/gestos/findOne/${id}`, { headers }).subscribe(resp => {
        resolve(resp.response);
        this.setGestoSeleccionado(resp.response);
        Swal.close();
      });

    });
  }

  getGestosEvaluadosByUser(id: string): Promise<GestoUsuario[]> {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/materias/examenes/gestos/usuarios/findAllByUser/${id}`, { headers }).subscribe(resp => {
        resolve(resp.response);
        Swal.close();
      });

    });
  }

  getGestoEvaluadoById(id: string): Promise<GestoUsuario> {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/materias/examenes/gestos/usuarios/findOne/${id}`, { headers }).subscribe(resp => {
        resolve(resp.response);
        this.setGestoEvaluadoSeleccionado(resp.response);
        Swal.close();
      });

    });
  }

  getGestosEvaluados(): Promise<GestoUsuario[]> {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/materias/examenes/gestos/usuarios/findAll`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });

    });
  }

  setGestoSeleccionado(gesto: Gesto) {
    this.gestoSeleccionado = gesto;
    this.storageService.setGesto(gesto);
  }

  getGestoSeleccionado(): Gesto {
    return this.gestoSeleccionado;
  }

  setGestoEvaluadoSeleccionado(gesto: GestoUsuario) {
    this.gestoEvaluadoSeleccionado = gesto;
    this.storageService.setGestoEvaluado(gesto);
  }

  getGestoEvaluadoSeleccionado(): GestoUsuario {
    return this.gestoEvaluadoSeleccionado;
  }

  postGesto(data: Gesto) {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post(`${URL_BACKEND}/materias/examenes/gestos/`, data, { headers }).subscribe(
        resp => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: 'Gesto guardado correctamente',
            showConfirmButton: false,
            timer: 1100
          });
          resolve(true);
        },
        (error) => {
          Swal.close();
          Swal.fire('Error al guardar gesto', '', 'error');
          resolve(false);
          this.router.navigate(['/gestos-listado']);
        }
      );
    });
  }

  updateGesto(id: string, data: Gesto) {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put(`${URL_BACKEND}/materias/examenes/gestos/updateOne/${id}`, data, { headers }).subscribe(
        resp => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: 'Gesto modificado correctamente',
            showConfirmButton: false,
            timer: 1100
          });
          resolve(true);
        },
        (error) => {
          Swal.close();
          Swal.fire('Error al modificado gesto', '', 'error');
          resolve(false);
        }
      );
    });
  }

  updateGestoEvaluado(id: string, data: GestoUsuario) {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put(`${URL_BACKEND}/materias/examenes/gestos/usuarios/updateOne/${id}`, data, { headers }).subscribe(
        resp => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: '¡El puntaje se ha modificado exitosamente!',
            showConfirmButton: false,
            timer: 1100
          });
          resolve(true);
        },
        (error) => {
          Swal.close();
          Swal.fire('Error al modificar puntaje, intente nuevamente', '', 'error');
          resolve(false);
        }
      );
    });
  }

  post(data: GestoUsuarioCargaMasiva) {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post(`${URL_BACKEND}/materias/examenes/gestos/usuarios/cargaMasivaMesa`, data, { headers }).subscribe(
        resp => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: '¡Los puntajes se han cargado exitosamente! ¿Deseas ver los puntajes cargados?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
          }).then((result) => {
            if (result.isConfirmed) {
              // Redirige a la ruta específica si se selecciona "Sí"
              this.router.navigate(['/gestos-evaluaciones-listado']);
              resolve(true);
            } else {
              // Redirige a otra ruta si se selecciona "No"
              this.router.navigate(['/mesas-listado']);
              resolve(true);
            }
          });
        },
        (error) => {
          Swal.close();
          Swal.fire('Ha ocurrido un error al guardar la carga de puntajes. Por favor, inténtalo nuevamente.', '', 'error');
          resolve(false);
        }
      );
    });
  }

  // En algún método o función
  mostrarSwal() {
    Swal.fire({
      title: '¿Ver puntajes cargados?',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        // Redirige a la ruta específica si se selecciona "Sí"
        this.router.navigate(['/gestos-evaluaciones-listado']);
      } else {
        // Redirige a otra ruta si se selecciona "No"
        this.router.navigate(['/mesas-listado']);
      }
    });
  }
}
