import { Component, OnInit, Inject, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Translators } from 'src/app/services/shared/translators.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalLegalComponent } from '../modal-legal.component';

@Component({
  selector: 'app-nota-legal',
  templateUrl: './nota-legal.component.html',
  styleUrls: ['./nota-legal.component.scss']
})
export class NotaLegalComponent implements OnInit {

  CFR = 'CFR'; // cláusula formulario registro
  CFA = 'CFA'; // cláusula formulario ayuda
  TC = 'TC'; // términos y condiciones
  PDP = 'PDP'; // protección datos personales 

  idioma: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ModalLegalComponent>,
    @Inject(MAT_DIALOG_DATA) public type: string,
    private translatorService: Translators, 
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });
  }

  ngOnInit(): void {
    this.useLanguage('es')
  }

  useLanguage(idioma: string){
    this.translatorService.setIdiomaSeleccionado(idioma);
  }


}
