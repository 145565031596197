import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator/public-api';
import { Curso } from '../../../../interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from '../../../../services/cursos/cursos.service';

@Component({
  selector: 'app-courses-style-three',
  templateUrl: './cursos-disponibles-compra.component.html',
  styleUrls: ['./cursos-disponibles-compra.component.scss']
})
export class CursosDisponiblesCompraComponent implements OnInit {

    cursosDisponibles: Curso[] = [];

    // MatPaginator Output
    pageEvent: any;

    // MatPaginator Inputs
    length: number = 0;
    pageSize: number = 3;
    pageSizeOptions: number[] = [3, 6, 9, 12, 24];

    lowValue: number = 0;
    highValue: number = 3; // default view
    flagLoaded = false;

    constructor(
        private cursosService: CursosService
    ) { }

    ngOnInit(): void {
        this.getCursosDisponibles();
    }

    getCursosDisponibles() {
        this.cursosService.getCursosDisponibles().then((cursos: Curso[]) => {
            this.cursosDisponibles = cursos;
            this.flagLoaded = true;
        });
    }

    public getPaginatorData(event: PageEvent): PageEvent {
        this.lowValue = event.pageIndex * event.pageSize;
        this.highValue = this.lowValue + event.pageSize;
        return event;
    }

}
