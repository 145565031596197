import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';
import { MateriasBoletinService } from 'src/app/services/cursos/materias-boletin.service';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { APP_CONFIG_SHARED, IAppShared } from 'src/app/app.config/app.config-shared';
import { Boletin } from 'src/app/interfaces/interface-bakend/cursos/boletin.interface';
import { BoletinesService } from 'src/app/services/cursos/boletines.service';
import { MateriaBoletinDetalle } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin-detalle.interface';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { Alumno } from 'src/app/interfaces/interface-bakend/cursos/alumno.interface';
import { AlumnoBoletin } from 'src/app/interfaces/interface-bakend/cursos/alumno-boletin.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';

@Component({
  selector: 'app-titulo-alta',
  templateUrl: './boletin-alta.component.html',
  styleUrls: ['./boletin-alta.component.scss']
})
export class BoletinAltaComponent implements OnInit {

  flagFormaValido = false;
  form: FormGroup;
  materiasBoletines: MateriaBoletin[]; // Materias disponibles para seleccionar
  modalidadCursadas: Catalogo[];
  modalidadExamenes: Catalogo[];
  alumno: Alumno;
  boletines: Boletin[];
  materiasBoletinPreCargadas: MateriaBoletinDetalle[]; // Materias de un boletin seleccionado para precarga. Se activa cuando selecciono un boletin
  isLoading = false;
  usuario: User;

  constructor(
    private fb: FormBuilder,
    private guiMsjService: GuiMsjService,
    private materiasBoletinesService: MateriasBoletinService,
    private boletinService: BoletinesService,
    private catalogoService: CatalogoService, @Inject(APP_CONFIG_SHARED)
    private iAppShared: IAppShared,
    private cursosService: CursosService,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioService,
    private router: Router) {

    this.createForm();
    this.createListenersStateForm();
    this.getMateriasBoletines();
    this.getBoletines();
    this.getCatalogo();
    this.getAlumno();
  }

  ngOnInit() {
  }

  async getCatalogo() {
    try {
      const modalidadCursadas: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.modalidadCursada, null);
      const modalidadExamenes: Catalogo[] = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.modalidadExamen, null);
      this.modalidadCursadas = modalidadCursadas;
      this.modalidadExamenes = modalidadExamenes;
    } catch (error) {
      console.error('Error al cargar modalidades', error);
    }
  }

  async getMateriasBoletines() {
    await this.materiasBoletinesService.getMaterias().then((materiasBoletines: MateriaBoletin[]) => {
      this.materiasBoletines = materiasBoletines;
    });
  }

  async getBoletines() {
    await this.boletinService.getBoletines().then((boletines: Boletin[]) => {
      this.boletines = boletines;
    });
  }

  async createForm() {
    this.form = this.fb.group({
      boletin: ['', Validators.required],
      materias: this.fb.array([])
      //materias: this.fb.array([], this.minMateriasValidator)
    });
  }

  get materias() {
    return this.form.get('materias') as FormArray;
  }

  addMateria() {
    const materiaForm = this.fb.group({
      materia: ['', Validators.required],
      modalidadCursada: ['', Validators.required],
      modalidadExamen: ['', Validators.required],
      fechaExamen: [''],
      fechaNota: [''],
      notaExamen: ['', [Validators.min(0), Validators.max(100)]]
    });

    this.materias.push(materiaForm);
  }

  async getAlumno() {
    this.alumno = this.cursosService.getAlumnoSeleccionado();
    if (!this.alumno) {
      this.router.navigate(['/alumnos-boletines-listado']);
    }
  }

  seleccionarBoletin(event: any): void {
    this.isLoading = true;
    const boletinId = event.value;
    const boletin = this.boletines.find(boletin => boletin._id === boletinId);
    if (boletin) {
      this.materiasBoletinPreCargadas = boletin.materias;
      this.loadMateriasBoletin();
    }
  }

  loadMateriasBoletin() {
    // Limpio el array de materias previamente
    this.materias.clear();

    // Cargo las materias en el form
    this.materiasBoletinPreCargadas.forEach(materia => {
      const materiaForm = this.fb.group({
        materia: [materia.materia, Validators.required],
        modalidadCursada: [materia.modalidadCursada, Validators.required],
        modalidadExamen: [materia.modalidadExamen, Validators.required],
        fechaExamen: [materia.fechaExamen],
        fechaNota: [materia.fechaNota],
        notaExamen: [materia.notaExamen, [Validators.min(0), Validators.max(100)]]
      });
      this.materias.push(materiaForm);
      this.isLoading = false;
    });
  }

  // Validador de minimas materias a cargar
  minMateriasValidator(control: FormArray): { [key: string]: boolean } | null {
    if (control.length < 1) {
      return { 'minMaterias': true };
    }
    return null;
  }

  quitarMateria(index: number) {
    const confirmed = window.confirm('¿Estás seguro de que deseas eliminar esta materia?');
    if (confirmed) {
      this.materias.removeAt(index);
    }
  }

  async setDataForm(): Promise<AlumnoBoletin> {
    const data: AlumnoBoletin = {
      alumno: this.alumno?._id,
      boletin: this.form.value.boletin,
      materias: this.form.value.materias.map((materia: MateriaBoletinDetalle) => ({
        materia: materia.materia, // Objeto único
        modalidadCursada: materia.modalidadCursada,
        modalidadExamen: materia.modalidadExamen,
        fechaExamen: materia.fechaExamen,
        fechaNota: materia.fechaNota,
        notaExamen: materia.notaExamen
      }))
    };
    return data;
  }

  async post() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    await this.boletinService.post(await this.setDataForm()).then(async (boletin: AlumnoBoletin) => {
      //await this.guiMsjService.msjFormSubmit('DatosGuardados');
      this.router.navigate(['/alumnos-boletines-listado']);
      return;
    });
  }

  /* MÉTODOS PARA VALIDACIÓN DE FORMULARIO */
  get nombreNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  createListenersStateForm() {
    return this.form.statusChanges.subscribe(async status => {
      //  console.log(this.form);
    });
  }
}
