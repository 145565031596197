import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Pregunta, Respuesta } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { MediaService } from 'src/app/services/shared/media.service';

@Component({
  selector: 'app-evaluacion-input-respuestas',
  templateUrl: './evaluacion-input-respuestas.component.html',
  styleUrls: ['./evaluacion-input-respuestas.component.scss']
})
export class EvaluacionInputRespuestasComponent implements OnInit {

  @Input() respuesta: string;
  @Input() respuestaConMultimedia: Respuesta;
  @Input() respuestas: Respuesta[];
  @Input() preguntas: Pregunta[];
  @Input() indexPregunta: number;
  @Input() indexRespuesta: number;
  @Input() examenID: string;
  @Input() esCorrecta: boolean;

  //preguntas: Pregunta[];
  form: FormGroup;

  constructor(
    private examenService: ExamenesService,
    private guiMsjService: GuiMsjService,
    private mediaSerivice: MediaService,
  ) { 
    this.subscriptionPreguntas();
  }

  ngOnInit(): void {
    //this.subscriptionPreguntas();
    this.createForm();
  }
  /* observable al examen view*/
  async subscriptionPreguntas() {
    await this.examenService.getPreguntas$().subscribe(async preguntas => {
      this.preguntas = preguntas;
    });
  }
  createForm() {
    this.form = new FormGroup({
      texto: new FormControl(this.respuesta),
      esCorrecta: new FormControl(this.esCorrecta),
    })
  }
  async updatePregunta(pPregunta: number, pRespuesta: number) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    // Chequear que no sea null el objeto preguntas
    if (this.preguntas) {
      this.preguntas[pPregunta].respuestas[pRespuesta].texto = this.form.value.texto;
      this.preguntas[pPregunta].respuestas[pRespuesta].correcta = this.form.value.esCorrecta;
      this.examenService.setPreguntas$(this.preguntas);
      const preguntas = {
        preguntas: this.preguntas
      }
      this.examenService.updatePreguntasExamen(this.examenID, preguntas).then(async (data: Pregunta[]) => {
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
      }).catch((error)=>{
        alert(error);
      })
    }else{
      console.log("Error al acceder al objeto preguntas")
    }
  }

  getTipoArchivo(tipo: string): string {
    const tipoArchivo = this.mediaSerivice.getTipoArchivo(tipo);
    return tipoArchivo;
  }

}
