<!-- ACTIVAR PARA AADIDESS SI LO SOLICITA ESTA EN VARIAS PAG (PROFILE / GYM Y HOME)-->
<!-- <app-cookiebot></app-cookiebot> -->
<app-vendor-about></app-vendor-about>

<!-- BUSCADOR -->
<!-- <app-buscador-aereos></app-buscador-aereos> -->
<!-- FIN BUSCADOR -->

<div class="container">    
    <br>
    <div class="section-title">
        <!--<span class="sub-title">Go at your own pace</span>-->
        <h3>{{ idioma | translate: "home.OFERTAS-TITULO" | async}} </h3>
        <p>{{ idioma | translate: "home.OFERTAS-DESCRIPCION" | async}} </p>
    </div>

    <!-- INCRIPCION DE TIPO 1 - PARA LISTADOS DE MUCHAS INCRIPCIONES SIN TARJETA -->
    <app-ofertas-listar  *ngIf="this.tipoSolicitud == 1" [flagViewTitle]="false" [highValue]="3"></app-ofertas-listar>

    <!-- INCRIPCION DE TIPO 2 - PARA LISTADOS DE POCAS INCRIPCIONES CON TARJETA -->
    <app-ofertas-listar-tarjetas *ngIf="this.tipoSolicitud == 2"  [flagViewTitle]="false" [highValue]="3"></app-ofertas-listar-tarjetas>

    <!-- INCRIPCION DE TIPO 3 - Botones AADIDESS-->
    <br>
    <app-boton-tarjeta  *ngIf="this.tipoSolicitud == 3"></app-boton-tarjeta>
    <br>

    <!-- BOTON LISTADO DE CURSOS -->
    <div class="col-lg-12 col-md-12">
        <div class="courses-info">
            <a routerLink="/ofertas-disponibles" class="default-btn"><i class="flaticon-people"></i>{{ idioma | translate: "form.VER-OFERTAS-BTN" | async}} <span></span></a>
        </div>
    </div>


    <!-- CURSOS  -->
    <div *ngIf="this.cursos">
       
        <div class="section-title">
            <h3>{{ idioma | translate: "home.CURSOS-TITULO" | async}} </h3>
            <p>{{ idioma | translate: "home.CURSOS-DESCRIPCION" | async}} </p>
        </div>
    

        <app-cursos-listar></app-cursos-listar>
    
        <div class="col-lg-12 col-md-12">
            <div class="courses-info">
                <a routerLink="/cursos-disponibles-compra" class="default-btn"><i class="flaticon-heart"></i>{{ idioma | translate: "form.VER-CURSOS-BTN" | async}} <span></span></a>
            </div>
        </div>
        
    </div>

    <!-- FIN CURSOS  -->
</div>

<!-- DISPONIBLE EN APP STORE -->
<!-- <app-overview></app-overview> -->

<div class="subscribe-area bg-f9f9f9 ptb-70">
    <!-- <div class="container">
        <app-subscribe></app-subscribe>
    </div> -->
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image" width="80%"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>


<!-- #### Código original comentado ####-->

<!--<app-slogan></app-slogan>-->

<!--<app-funfacts-style-one></app-funfacts-style-one>-->

<!--<app-vendor-popular-courses></app-vendor-popular-courses>-->

<!--<app-instructor></app-instructor>-->

<!--<app-premium-access-two></app-premium-access-two>-->

<!--<div class="testimonials-area ptb-100">
    <app-testimonials></app-testimonials>
</div>-->

<!--<app-view-all-courses-two></app-view-all-courses-two>-->

<!--div class="blog-area ptb-100">
    <app-blog></app-blog>
    </div>-->