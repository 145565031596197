import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { Router } from '@angular/router';
import { MediaService } from '../../../../services/shared/media.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { Disciplina, Titulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';

@Component({
  selector: 'app-titulo-modificacion',
  templateUrl: './titulo-modificacion.component.html',
  styleUrls: ['./titulo-modificacion.component.scss']
})
export class TituloModificacionComponent implements OnInit {

  flagFormaValido = false;
  form: FormGroup;
  disciplinas: Disciplina[] = [];
  titulo: Titulo;
  flagLoaded: boolean = false;

  constructor(
      private fb: FormBuilder,
      private cursosService: CursosService,
      private guiMsjService: GuiMsjService,
      private router: Router){
    }
  
    ngOnInit(): void {
      this.initComponent();
    }
  
    async initComponent() {
      await this.getEstadoVariables();
      await this.createForm();
      await this.getDisciplinas();
      this.flagLoaded = true;
    }

    async getEstadoVariables(){
      if (this.getTitulo() === undefined) {
        this.router.navigateByUrl('/titulos-listado');
        return;
      }else {
        await this.getTitulo();           
      }
    }

    async getDisciplinas(){
      await this.cursosService.getDisciplinas().then( (disciplinas: Disciplina[]) => {
        this.disciplinas = disciplinas;
      });
    }

    async createForm(){
      this.form = this.fb.group({
        nombre: new FormControl(this.titulo.nombre,  [ Validators.required]),
        disciplina: new FormControl(this.titulo.disciplina,  [Validators.required]),
        nivel: new FormControl(this.titulo.nivel),
        activo: new FormControl(this.titulo.activo),
        mesesVigencia: new FormControl(this.titulo.mesesVigencia, [Validators.required]),
        carnet: new FormControl(this.titulo.carnet),
        disenio: new FormControl(this.titulo.disenio),
        estampilla: new FormControl(this.titulo.estampilla),
        observaciones: new FormControl(this.titulo.observaciones)
      });
    }

    async setDataForma(): Promise<Titulo>{

      const data: Titulo = {
        nombre: this.form.value.nombre,
        disciplina: this.form.value.disciplina,
        activo: this.form.value.activo,
        carnet: this.form.value.carnet || "",
        observaciones: this.form.value.observaciones || "",
        disenio: this.form.value.disenio || "",
        nivel: this.form.value.nivel,
        mesesVigencia: this.form.value.mesesVigencia,
        estampilla: this.form.value.estampilla || "",
        icono: ""
      };
      return data;
    }

    async update(){
      await this.guiMsjService.msjFormSubmit('Guardando');
      await this.cursosService.updateTitulo(this.titulo._id, await this.setDataForma()).then( async (titulo: Titulo) => {
          await this.guiMsjService.msjFormSubmit('DatosGuardados');
          this.router.navigate(['/titulos-listado']);
          return;
      });
    }

    getTitulo(){
      return this.titulo = this.cursosService.getTituloSeleccionado();
    }

    /* MÉTODOS PARA VALIDACIÓN DE FORMULARIO */
    get nombreNoValido() {
      return this.form.get('nombre').invalid && this.form.get('nombre').touched;
    }

    get introduccionNoValida() {
      return this.form.get('introduccion').invalid && this.form.get('introduccion').touched;
    }

    get descripcionGralNoValida() {
      return this.form.get('descripcionGral').invalid && this.form.get('descripcionGral').touched;
    }

    get cantidadHorasNoValida() {
      return this.form.get('cantidadHoras').invalid && this.form.get('cantidadHoras').touched;
    }

    get fechaInicioNoValida() {
      return this.form.get('fechaInicio').invalid && this.form.get('fechaInicio').touched;
    }

    get fechaFinNoValida() {
      return this.form.get('fechaFin').invalid && this.form.get('fechaFin').touched;
    }

    get precioNoValido() {
      return this.form.get('precio').invalid && this.form.get('precio').touched;
    }

  createListenersStateForm() {
      return this.form.statusChanges.subscribe( async status => {
        //  console.log(this.form);
      });
  }
}
