import { Solicitud } from './../../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { Router } from '@angular/router';
import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SolicitudesService } from '../../../../../../services/ofertas-laborales/solicitudes.service';
import { MatDialog } from '@angular/material/dialog';
import { OfertasSolicitudComponent } from '../../ofertas-solicitud/ofertas-solicitud.component';
import { Subscription } from 'rxjs';
import { EstadisticasService } from 'src/app/services/ofertas-laborales/estadisticas.service';
import { APP_CONFIG_OFERTAS, IAppOfertas } from 'src/app/app.config/app.config-ofertas';

@Component({
  selector: 'app-ofertas-table',
  templateUrl: './ofertas-table.component.html',
  styleUrls: ['./ofertas-table.component.scss']
})
export class OfertasTableComponent implements OnInit {

  @Input() solicitudes: Solicitud[];

  flagLoaded = false;
  flagLoadedModal = true;

  /* Data Table*/

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
  [
    'tramite', 'actividad', 'fechaEstado', 'solicitante', 'identificacion', 'estado',  'progreso', 'ver'
  ];
  dataSource: any;
  estados: any;

  refresh: Subscription;

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  /* Fin Data Table*/

  constructor(
      private solicitudesService: SolicitudesService,
      private router: Router,
      public dialog: MatDialog,
      private estadisticasService: EstadisticasService,
      @Inject(APP_CONFIG_OFERTAS) public iAppOfertas: IAppOfertas,

  ) {
    this.estados = iAppOfertas;
    this.refresh = this.estadisticasService.refresh().subscribe(resp => {
        this.loadDataTable(this.solicitudes);
    });
   }

  ngOnInit(): void {
    console.log(this.solicitudes);
    console.log('iAppOfertas', this.iAppOfertas);
    this.loadDataTable(this.solicitudes);
  }

  calcularProgreso(lastState: number): number {
    const maxStateNumber = Math.max(...Object.values(this.estados.selectEstados).map((estado: any) => estado.number));
    return (lastState / maxStateNumber) * 100;
  }

  // tslint:disable-next-line:variable-name
  async loadDataTable(data: Solicitud[]) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.flagLoaded = true;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* No esta en uso */
  async verSolicitud(id) {
    this.solicitudesService.getSolicitudById(id).then(( (sol: Solicitud) => {
          this.solicitudesService.setSolicitudSeleccionada(sol);
          this.router.navigateByUrl('/ofertas-solicitud');
    }));
 }

  /* Modal - Carga Solicitud */
  openDialogModal(data): void {
    this.flagLoadedModal = false;
    this.solicitudesService.getSolicitudById(data).then(( (sol: Solicitud) => {
        this.solicitudesService.setSolicitudSeleccionada(sol);

        const dialogRef = this.dialog.open(OfertasSolicitudComponent, {
            width: '900px',
            height: '1000px',
            disableClose: true,
            data,
          });

        this.flagLoadedModal = true;

        dialogRef.afterClosed().subscribe(async result => {
          });

        return;
    }));


  }



}
