import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ExamenEvaluacion } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';
import { Examen } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { AppConfigService } from 'src/app/services/shared/app-config.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-examen-introduccion',
    templateUrl: './examen-introduccion.component.html',
    styleUrls: ['./examen-introduccion.component.scss']
})
export class ExamenIntroduccionComponent implements OnInit {

  examen: Examen;
  evaluacionesByUser: ExamenEvaluacion[];
  evaluacionesDeExamenes: ExamenEvaluacion[];
  porcentajeAprobado: number;
  aprobado: boolean;
  intento: boolean = false;
  flagLoaded = false;
  fechaVencimiento: Date;
  fechaPublicacion: Date;
  hoy: Date = new Date();
  examenVencido = false;
  examenActivo: boolean;
  cantPreguntas: number;
  contadorIntentos: number = 0;
  horaServer: Date;
  btnComenzar: Boolean = false;
  isLoadingPregunta: Boolean = false;
  progress: boolean = false;
  isLoading: boolean = false;
  constructor(private examenServices: ExamenesService, 
              private router: Router,
              private authService: AuthService,
              private appConfigService: AppConfigService,
              private guiMsjService: GuiMsjService,
              private snackBar: MatSnackBar) {
    sessionStorage.removeItem('EXAMEN_FINALIZADO');
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.appConfigService.getServerTime().then((hora:Date)=>{
      this.horaServer = new Date(hora);
    });
    await this.getEstadoVariables();
    this.isLoading = false;
  }
  /* CARGO EL EXAMEN SELECCIONADO PARA CURSAR */
  getExamen(){
    return this.examen = this.examenServices.getExamenSeleccionado();
  }

  async getEstadoVariables(flagRedireccionar?: boolean){
    if (this.getExamen() === undefined) {
      this.router.navigateByUrl('/examenes-alumno');
      return;
    }else {
      this.getExamen();
      this.flagLoaded = true;
      this.evaluacionesByUser = await this.examenServices.getEvaluacionesByUser();
      this.cantPreguntas = await this.cantidadDePreguntasArendir(this.examen);
      this.examenActivo = this.examen.activo;
      /* VERIFICA LA FECHA DE VENCIMIENTO DEL EXAMEN */ 
      this.fechaVencimiento = new Date(this.examen.fechaVencimiento);
      this.fechaPublicacion = new Date(this.examen.fechaPublicacion);
      //this.btnComenzar = this.fechaPublicacion.getTime() < this.horaServer.getTime() ? true : false
      if(this.horaServer.getTime() > this.fechaVencimiento.getTime()){
        this.examenVencido = true;
        this.snackBar.open("Examen vencido","Cerrar",{
          duration: 3000
        });
        this.router.navigateByUrl('/examenes-alumno');
      }
      
      /* CHEQUEO QUE EL USUARIO TENGA EVALUACIONES Y CUENTO LOS INTENTOS POR EXAMEN */
      if(this.evaluacionesByUser !== undefined){
        /* FILTRO EVALUACIONES DEL MISMO EXAMEN A RENDIR QUE NO SON RECUPERATORIOS */
        this.evaluacionesDeExamenes = this.evaluacionesByUser.filter(examen => examen.examen === this.examen._id && examen.recuperatorio != true);

        console.log('this.evaluacionesDeExamenes ', this.evaluacionesDeExamenes );

        
        this.evaluacionesDeExamenes.forEach((evaluacion: any) => {
          
          const fechaCreatedAt = new Date(evaluacion.createdAt);
          const anioCreatedAt = fechaCreatedAt.getFullYear();
          const anioActual = new Date().getFullYear();

          /* EL EXAMEN ES INDIVIDUAL */
          if(this.examen.individual === true ){

            if(evaluacion.examen.concat(evaluacion.asignacion) === this.examen._id.concat(this.examen.asignacion)  && anioCreatedAt === anioActual){
              this.contadorIntentos++;
            }

          }else{
            /* EL EXAMEN ES ASOCIADO A UN CURSO */

            if (evaluacion.asignacion === undefined && anioCreatedAt === anioActual) {
              this.contadorIntentos++;        
            }

            /* PARA LOS NUEVOS EXAMENES ASOCIADOS AL CURSO QUE YA TIENEN ID DE ASIGNACION */

            // tslint:disable-next-line:max-line-length
            if(evaluacion.examen.concat(evaluacion.asignacion) === this.examen._id.concat(this.examen.asignacion)  && anioCreatedAt === anioActual){

              this.contadorIntentos++;
            }

          }
          
        })
        
        /* COMPROBAR SI PUEDO RENDIR EL EXAMEN */
        if(this.aprobado != true){
          if(this.contadorIntentos >= this.examen.intentos){
            let title = '';
            if(this.getExamen().cantidadRecuperable>=1){
              const cantidadRecuperable = this.getExamen().cantidadRecuperable;
              title = 'El examen ya no es posible rendirlo, pero tiene '+ cantidadRecuperable+ ' recuperatorio/s posibles. Quiere ir al recuperatorio?';

              Swal.fire({
                title: title,
                showDenyButton: true,
                confirmButtonText: 'Ir a Recuperatorios',
                denyButtonText: `No, Salir`,
              }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  const examenIntroduccion = await this.examenServices.getExamenFindOne(this.examen._id);
                  examenIntroduccion.asignacion = this.examen.asignacion;
                  examenIntroduccion.individual = this.examen.individual;
                  if(examenIntroduccion != undefined){
                    this.examenServices.setExamenSeleccionado(examenIntroduccion);
                    this.router.navigateByUrl('/examen-recuperatorio');
                  }
                  
                } else if (result.isDenied) {
                  this.router.navigateByUrl('/examenes-alumno');
                }
              })


            }else{
              title = 'El examen ya no es posible rendirlo.';
              Swal.fire(
                'Sin intentos posibles!',
                title,
                'info'
              ).then( ()=> {
                if (flagRedireccionar) {
                  console.log('CONTROLO NUEVAMENTE');
                  this.router.navigateByUrl('/examenes-alumno');
                }
              });
            }
      
          }else{
            if(this.fechaPublicacion.getTime() < this.horaServer.getTime()){
              this.btnComenzar = true;
            }
          }
        }else{
          this.btnComenzar = false;
        }
        
      }else{
        if(this.fechaPublicacion.getTime() < this.horaServer.getTime()){
          this.btnComenzar = true;
        }
        
      }

      
    }
  }

  async comenzar() {
    this.btnComenzar = false;
    this.isLoadingPregunta = true;
    await this.getEstadoVariables(true);
    Swal.fire({
      title: '¿Confirma comenzar el Examen?',
      text: 'Se contará como un intento realizado. Recuerde no recargar la página y tampoco salir de la misma de lo contrario perderá sus respuestas y no podrá continuar.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then(async (result) => {
      this.progress = true;
      if (result.value) {
      this.isLoadingPregunta = false;
      this.isLoading = true;

      /* GUARDO EXAMEN PARA EVALUAR Y GENERE LAS PREGUNTAS ALEATORIAS */
      const examenEvaluar: ExamenEvaluacion = {
        usuario: (await this.authService.getUserLogon())._id,
        examen: this.examenServices.getExamenSeleccionado()._id, 
        respuesta: this.examenServices.getExamenSeleccionado(),
        curso: this.examenServices.getExamenSeleccionado().curso ? this.examenServices.getExamenSeleccionado().curso : null,
        asignacion: this.examenServices.getExamenSeleccionado().asignacion ? this.examenServices.getExamenSeleccionado().asignacion : null,
      }
      /* EVALÚO EXAMEN Y DEVUELVO EL ID DEL MISMO */
      await this.examenServices.evaluarExamen(examenEvaluar).then((ExamenEvaluacion: ExamenEvaluacion)=>{
        const examenEvaluacionGetOne = ExamenEvaluacion._id;
        /* SETEO EL ID DEL EXAMEN EVALUACION */ 
        this.examenServices.setExamenEvaluacionId(examenEvaluacionGetOne);
        /* SETEO EL EXAMEN(RESPUESTA) QUE CONTIENE LAS PREGUNTAS ALEATORIAS */
        this.examenServices.setExamenEvaluacionRespuesta(ExamenEvaluacion.respuesta)
      });
      /* REDIRIGO RUTA PARA CURSAR EL EXAMEN SELECCIONADO */
      this.router.navigateByUrl('/examen-cursar').then(() => {
        this.isLoading = false;
      });
      } else if (result.dismiss === Swal.DismissReason.cancel) {        
        this.progress = false;        
        this.snackBar.open("Examen sin comenzar","Cerrar",{
          duration: 3000
        });
        this.btnComenzar = true;
        this.isLoadingPregunta = false;
      }
    });
  }

  async cantidadDePreguntasArendir(examen: Examen) {
    let cantidadPreguntasRendir: number = 0;
    if (examen.cantidadPreguntasAmostrar == 0) {
      cantidadPreguntasRendir =  examen.preguntas.length;
    } else {
      cantidadPreguntasRendir = examen.cantidadPreguntasAmostrar;
    }
    return cantidadPreguntasRendir;
  }
}
