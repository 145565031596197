import { PaisDisponible } from './../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '../../interfaces/interface-bakend/response';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { StorageService } from '../shared/storage.service';
import { GuiMsjService } from '../shared/gui-msj.service';
import Swal from 'sweetalert2';
import { Actividad } from '../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { ActividadDAdjunto } from '../../interfaces/interface-bakend/ofertas-laborales/actividad-adjuntos.interface';
import { AuthService } from '../auth/auth.service';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class ActividadesService {

  actividadSeleccion: Actividad;
  adjuntoSeleccionado: ActividadDAdjunto;
  pagocomprobante: boolean;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private guiMsjService: GuiMsjService,
    private authService: AuthService,
  ) {
  }

  getNombresActividadesAsync( localidadedes: Actividad[], page?: number, size?: number, timeout = 1000): Observable<Actividad[]> {
    return new Observable<Actividad[]>(observer => {
      observer.next(this.getActividadIndividual(localidadedes, page, size));
      observer.complete();
    }).pipe(delay(timeout));
  }

  getActividadIndividual(localidadedesFiltradas: Actividad[], page?: number, size?: number): Actividad[] {
    let actividades = [];
    localidadedesFiltradas.forEach(localidad => {
      actividades.push(localidad);
    });
    if (page && size) {
      actividades = actividades.slice((page - 1) * size, ((page - 1) * size) + size);
    }
    return actividades;
  }

  setAdjuntoSeleccionado(adjunto: ActividadDAdjunto) {
    this.adjuntoSeleccionado = adjunto;
    this.storageService.setAdjunto(adjunto);
  }

  setActividadSeleccionada(actividad: Actividad) {
    this.actividadSeleccion = actividad;
    this.storageService.setActividad(actividad);
  }

  getActividadSeleccionada(): Actividad {
    return this.actividadSeleccion;
  }

  getAdjuntoSeleccionado(): ActividadDAdjunto {
    return this.adjuntoSeleccionado;
  }

  // se utiliza para saber si el pago es con comprobante, en base a esto actualizado estado de solicitud
  setPagoConComprobante(flag: boolean) {
    this.pagocomprobante = flag;
  }

  getPagoConComprobante(): boolean {
    return this.pagocomprobante;
  }

  async getActividadesVigentes(): Promise<Actividad[]>{

    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    return new Promise( resolve => {
      this.httpClient.get<Response>(`${ URL_BACKEND }/actividades/findVigentesWithOutFecha`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getActividades(): Promise<Actividad[]>{

    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    return new Promise( resolve => {
      this.httpClient.get<Response>(`${ URL_BACKEND }/actividades/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getActividadesFindOne(id: string): Promise<Actividad> {

    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    return new Promise( resolve => {
      this.httpClient.get<Response>(`${ URL_BACKEND }/actividades/findOne/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getActividadesFindByPaisDisponible(pais: PaisDisponible): Promise<Actividad[]>{

    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    const idPaisDisponible = pais._id;
    return new Promise( resolve => {
      // tslint:disable-next-line:max-line-length
      this.httpClient.get<Response>(`${ URL_BACKEND }/actividades/findByPaisDisponible/${idPaisDisponible}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getActividadesByPaisDisponiblesVigentes(pais: PaisDisponible): Promise<Actividad[]>{

    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    const idPaisDisponible = pais._id;
    return new Promise( resolve => {
      // tslint:disable-next-line:max-line-length
      this.httpClient.get<Response>(`${ URL_BACKEND }/actividades/findByPaisDisponibleVigentes/${idPaisDisponible}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getAdjuntos(): Promise<ActividadDAdjunto[]>{

    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    return new Promise( resolve => {
      this.httpClient.get<Response>(`${ URL_BACKEND }/actividad-adjuntos/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getAdjuntosFindOne(id: string): Promise<ActividadDAdjunto> {

    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    return new Promise( resolve => {
      this.httpClient.get<Response>(`${ URL_BACKEND }/actividad-adjuntos/findOne/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getNombreAdjunto(adjunto: string): Promise<string>{
    const posInicial = 0;
    const longitud = adjunto.indexOf('.');
    const idAdjunto = adjunto.substring(posInicial, longitud);

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/actividad-adjuntos/getNombre/${idAdjunto}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getPathAdjunto(userid: string, file: string): Promise<string>{

    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    return new Promise( resolve => {
      this.httpClient.get<Response>(`${ URL_BACKEND }/media/pathFileUserOffer/${userid}/${file}`, { headers }).subscribe( resp => {
        if (resp['ok']) {
            resolve(resp['path']);
        } else {
            this.guiMsjService.msjFormSubmit('documentosEnviadosERROR');
        }
      });
    });
  }

  async postAdjunto(data: ActividadDAdjunto){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post<Response>(`${ URL_BACKEND }/actividad-adjuntos`, data, { headers }).subscribe( resp => {

        resolve(resp.response);

        // tslint:disable-next-line:no-unused-expression
        (error) => {
          Swal.close();
          Swal.fire('Error al guardar', '', 'error');
          console.log(error);
          resolve(false);
        };
      });
    });

  }

  async putAdjunto(id: string, data: ActividadDAdjunto){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put<Response>(`${ URL_BACKEND }/actividad-adjuntos/updateOne/${id}`, data, { headers }).subscribe( resp => {

        resolve(resp.response);

        // tslint:disable-next-line:no-unused-expression
        (error) => {
          Swal.close();
          Swal.fire('Error al guardar', '', 'error');
          console.log(error);
          resolve(false);
        };
      });
    });

  }

  async post(data: Actividad){

console.log(data);
    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    return new Promise( resolve => {
      this.httpClient.post<Response>(`${ URL_BACKEND }/actividades`, data, { headers }).subscribe( resp => {

        resolve(resp.response);

        // tslint:disable-next-line:no-unused-expression
        (error) => {
          Swal.close();
          Swal.fire('Error al guardar', '', 'error');
          console.log(error);
          resolve(false);
        };
      });
    });
  }

  async put(id: string , data: Actividad){

    return new Promise( async resolve => {

    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
     });

    this.httpClient.put<Response>(`${URL_BACKEND}/actividades/updateOne/${id}`, data, { headers })
      .subscribe( (resp: Response)  => {
        resolve(resp.response);
      }, error => {
        this.guiMsjService.msjFormSubmit('documentosEnviadosERROR');
        console.log('error', error);
      });
    });
  }


}
