import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-documentos-usuarios-configuracion',
  templateUrl: './documentos-usuarios-configuracion.component.html',
  styleUrls: ['./documentos-usuarios-configuracion.component.scss']
})
export class DocumentosUsuariosConfiguracionComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
