import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { Examen, Pregunta, Respuesta } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';

@Component({
  selector: 'app-verdadero-falso',
  templateUrl: './verdadero-falso.component.html',
  styleUrls: ['./verdadero-falso.component.scss'],

})
export class VerdaderoFalsoComponent implements OnInit {

    form: FormGroup;

    @Input() respuestas: Respuesta[];
    @Input() tipoPregunta: string;
    @Input() index: number;
    @Input() longitud: number;
    @Input() examen: Examen;
    @Output() respuestasEmitter: EventEmitter<any>;
    preguntas: Pregunta[] = [];

    estado = false;
    btnFinalizar: boolean;
    respuestasArray: Respuesta[] = [];
    opciones = [
      {
        "texto": "VERDADERO",
        "valor": true
      },{
        "texto": "FALSO",
        "valor": false
      }
    ];
    examenParcial: Examen;

    constructor(private examenServices: ExamenesService) {
      this.respuestasEmitter = new EventEmitter();
    }

    ngOnInit(): void {
      this.respuestasArray = this.respuestas;
      this.form = new FormGroup({
        seleccionado: new FormControl('', Validators.required)
      });
      this.ultimaPregunta();
    }

    seleccionarOpcion(seleccionado: boolean){
      this.respuestas[0].seleccionado = seleccionado;
      this.examenServices.setCursarExamen(this.examen);
    }

    // Funcion para saber si estoy posicionado en la ultima pregunta del examen
    ultimaPregunta(){
      return this.form.statusChanges.subscribe( async status => {
        this.examenServices.indexPreguntaExamen = this.index;
        this.examenServices.longitudPreguntasExamen = this.longitud;
        if (status === 'VALID') {
            this.examenServices.formStatusExamen = true;
        }else{
            this.examenServices.formStatusExamen = false;
        }
      });
    }
}
