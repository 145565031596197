import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '../../interfaces/interface-bakend/response';
import { environment } from '../../../environments/environment';
import { StorageService } from '../shared/storage.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Materia } from '../../interfaces/interface-bakend/cursos/materia.interface';
import { AuthService } from '../auth/auth.service';
import { Mesa } from 'src/app/interfaces/interface-bakend/cursos/mesa.interface';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class MesasService {

  mesaSeleccion: Mesa;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private authService: AuthService,
  ) { }

  getMesas(): Promise<Mesa[]> {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/materias/mesas/findAll`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });

    });
  }

  duplicarMesa(id: string): Promise<Mesa> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/materias/mesas/duplicarMesa/${id}`, { headers }).subscribe(resp => {
        
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Mesa duplicada correctamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigateByUrl('/mesas-listado');
        resolve(resp.response);
      });
    });
  }

  getMesaFindOne(id: string): Promise<Mesa> {
    return new Promise(async (resolve, reject) => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/materias/mesas/findOne/${id}`, { headers })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.log('Error 400:', error);
            Swal.close();
            return throwError('Error en la petición.');
          })
        )
        .subscribe(
          (resp) => {
            resolve(resp.response);
            this.setMesaSeleccionada(resp.response);
            Swal.close();
          },
          (error) => {
            console.log('Error en la suscripción:', error);
            Swal.close();
            reject('Error al obtener la mesa.');
          }
        );
    });
  }
  setMesaSeleccionada(mesa: Mesa) {
    this.mesaSeleccion = mesa;
    this.storageService.setMesa(mesa);
  }

  getMesaSeleccion(): Mesa {
    return this.mesaSeleccion;
  }

  post(data: Mesa) {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post(`${URL_BACKEND}/materias/mesas`, data, { headers }).subscribe(
        resp => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: 'Mesa guardada correctamente',
            showConfirmButton: false,
            timer: 1100
          });

          this.router.navigate(['/mesas-listado']);
          resolve(true);
        },
        (error) => {
          Swal.close();
          Swal.fire('Error al guardar', '', 'error');
          resolve(false);
        }
      );
    });
  }

  put(id: string, data: Mesa) {

    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put(`${URL_BACKEND}/materias/mesas/updateOne/${id}`, data, { headers }).subscribe(resp => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Mesa modificada correctamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/mesas-listado']);
        resolve(true)
          ,
          (error) => {
            Swal.close();
            Swal.fire('Error al modificar', '', 'error');
            console.log(error);
            resolve(false);
          };
      });
    });

  }

}
