<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li><a routerLink="/cursos-listado">Cursos</a></li>
                <li>Alta Curso</li>
            </ul>
            <h2>Alta Curso</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg"
             alt="image"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="form"
                          (ngSubmit)="post()">

                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-xs-12">

                                <mat-form-field class="example-full-width"
                                                appearance="fill"
                                                [style.width.%]=100>
                                    <mat-label>Nombre</mat-label>
                                    <input formControlName="nombre"
                                           matInput
                                           placeholder="Nombre"
                                           [class.is-invalid]="nombreNoValido"
                                           autocomplete="off">
                                </mat-form-field>

                                <mat-form-field class="example-full-width"
                                                appearance="fill"
                                                [style.width.%]=100>
                                    <mat-label>Descripción General</mat-label>
                                    <textarea formControlName="descripcionGral"
                                              matInput
                                              [class.is-invalid]="descripcionGralNoValida"
                                              autocomplete="off"></textarea>
                                    <small *ngIf="descripcionGralNoValida"
                                           class="text-danger">Ingrese una descripción</small>
                                </mat-form-field>

                                <mat-form-field class="example-full-width"
                                                appearance="fill"
                                                [style.width.%]=100>
                                    <mat-label>Cantidad de Horas</mat-label>
                                    <input type="number"
                                           formControlName="cantidadHoras"
                                           matInput
                                           [class.is-invalid]="cantidadHorasNoValida"
                                           autocomplete="off">
                                    <small *ngIf="cantidadHorasNoValida"
                                           class="text-danger">Ingrese una cantidad de horas</small>
                                </mat-form-field>

                                <mat-form-field color="accent"
                                                appearance="fill"
                                                [style.width.%]=100>
                                    <mat-label>Fecha Inicio</mat-label>
                                    <input formControlName="fechaInicio"
                                           matInput
                                           [matDatepicker]="picker2"
                                           [class.is-invalid]="fechaInicioNoValida"
                                           autocomplete="off">
                                    <small *ngIf="fechaInicioNoValida"
                                           class="text-danger">Ingrese una fecha de inicio</small>
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2
                                                    color="primary"></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field color="accent"
                                                appearance="fill"
                                                [style.width.%]=100>
                                    <mat-label>Fecha Finalización</mat-label>
                                    <input formControlName="fechaFin"
                                           matInput
                                           [matDatepicker]="picker3"
                                           [class.is-invalid]="fechaFinNoValida"
                                           autocomplete="off">
                                    <small *ngIf="fechaFinNoValida"
                                           class="text-danger">Ingrese una fecha de finalización
                                        válida</small>
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3
                                                    color="primary"></mat-datepicker>
                                </mat-form-field>

                            </div>

                            <div class="col-lg-6 col-md-12 col-xs-12">

                                <mat-form-field class="example-full-width"
                                                appearance="fill"
                                                [style.width.%]=100>
                                    <mat-label>Introducción corta</mat-label>
                                    <input formControlName="introduccion"
                                           matInput
                                           [class.is-invalid]="introduccionNoValida"
                                           autocomplete="off">
                                    <small *ngIf="introduccionNoValida"
                                           class="text-danger">Ingrese una introducción corta</small>
                                </mat-form-field>

                                <mat-form-field appearance="fill"
                                                floatLabel="always"
                                                [style.width.%]=100>
                                    <mat-label>Precio</mat-label>
                                    <input formControlName="precio"
                                           matInput
                                           type="number"
                                           placeholder="0"
                                           class="example-right-align"
                                           [class.is-invalid]="precioNoValido"
                                           autocomplete="off">
                                    <span matPrefix>$&nbsp;</span>
                                    <span matSuffix>00</span>
                                    <small *ngIf="precioNoValido"
                                           class="text-danger">Ingrese un precio</small>
                                </mat-form-field>

                                <div class="form-group">
                                    <label for="formFile"
                                           class="form-label">Módulo - esta opción permite que este
                                        disponible, al cargar pre incripciones </label>
                                    <mat-slide-toggle formControlName="modulo">Módulo</mat-slide-toggle>
                                </div>

                                <div class="form-group">
                                    <mat-slide-toggle
                                                      formControlName="disponible">Disponible</mat-slide-toggle>
                                </div>

                                <div class="form-group">
                                    <label for="formFile"
                                           class="form-label">Pista - esta opción identifica el curso como
                                        pista.</label>
                                    <mat-slide-toggle formControlName="pista">Pista</mat-slide-toggle>
                                </div>

                            </div>
                        </div>

                        <!-- ACTIVIDADES -->
                        <button type="button"
                                (click)="addActivity()"
                                mat-raised-button
                                color="accent">
                            <mat-icon>plus_one</mat-icon>
                            Agregar Actividad</button>
                        <div *ngIf="flagActivitiesPanel && actividades.length">
                            <mat-card>
                                <hr>
                                <cdk-virtual-scroll-viewport *ngIf="actividades"
                                                             appendOnly
                                                             itemSize="50"
                                                             class="example-viewport">

                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Tipo</th>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Descripción</th>
                                                <th scope="col">Fecha Desde </th>
                                                <th scope="col">Fecha Hasta</th>
                                                <th scope="col">Link</th>
                                                <th scope="col">Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="actividades">
                                            <tr *ngFor="let actividad of actividades.controls; let i=index"
                                                [formGroupName]="i"
                                                class="animate__animated animate__fadeIn">

                                                <td>{{i+1}}</td>

                                                <td>
                                                    <mat-form-field class="example-full-width"
                                                                    appearance="fill"
                                                                    [style.width.%]=100>
                                                        <mat-label>Tipo</mat-label>
                                                        <input formControlName="tipo"
                                                               matInput
                                                               autocomplete="off"
                                                               di>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field class="example-full-width"
                                                                    appearance="fill"
                                                                    [style.width.%]=100>
                                                        <mat-label>Nombre</mat-label>
                                                        <input formControlName="nombreActividad"
                                                               matInput
                                                               autocomplete="off">

                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field class="example-full-width"
                                                                    appearance="fill"
                                                                    [style.width.%]=100>
                                                        <mat-label>Descripción</mat-label>
                                                        <input formControlName="descripcion"
                                                               matInput
                                                               autocomplete="off">
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field color="accent"
                                                                    appearance="fill"
                                                                    [style.width.%]=100>
                                                        <mat-label>Fecha Inicio</mat-label>
                                                        <input matInput
                                                               formControlName="fecha"
                                                               [ngxMatDatetimePicker]="picker4"
                                                               placeholder="Fecha Inicio"
                                                               [formControl]="dateControl"
                                                               [min]="minDate1"
                                                               [max]="maxDate"
                                                               [disabled]="disabled1">
                                                        <mat-datepicker-toggle matSuffix
                                                                               [for]="picker4"></mat-datepicker-toggle>
                                                        <ngx-mat-datetime-picker #picker4
                                                                                 [showSpinners]="showSpinners"
                                                                                 [showSeconds]="showSeconds"
                                                                                 [stepHour]="stepHour"
                                                                                 [stepMinute]="stepMinute"
                                                                                 [stepSecond]="stepSecond"
                                                                                 [touchUi]="touchUi"
                                                                                 [color]="color1"
                                                                                 [enableMeridian]="enableMeridian"
                                                                                 [disableMinute]="disableMinute"
                                                                                 [hideTime]="hideTime">
                                                        </ngx-mat-datetime-picker>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field color="accent"
                                                                    appearance="fill"
                                                                    [style.width.%]=100>
                                                        <mat-label>Fecha Vencimiento</mat-label>
                                                        <input matInput
                                                               formControlName="fechaVencimiento"
                                                               [ngxMatDatetimePicker]="picker5"
                                                               placeholder="Fecha Inicio"
                                                               [formControl]="dateControl"
                                                               [min]="minDate1"
                                                               [max]="maxDate"
                                                               [disabled]="disabled1">
                                                        <mat-datepicker-toggle matSuffix
                                                                               [for]="picker5"></mat-datepicker-toggle>
                                                        <ngx-mat-datetime-picker #picker5
                                                                                 [showSpinners]="showSpinners"
                                                                                 [showSeconds]="showSeconds"
                                                                                 [stepHour]="stepHour"
                                                                                 [stepMinute]="stepMinute"
                                                                                 [stepSecond]="stepSecond"
                                                                                 [touchUi]="touchUi"
                                                                                 [color]="color1"
                                                                                 [enableMeridian]="enableMeridian"
                                                                                 [disableMinute]="disableMinute"
                                                                                 [hideTime]="hideTime">
                                                        </ngx-mat-datetime-picker>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field class="example-full-width"
                                                                    appearance="fill"
                                                                    [style.width.%]=100>
                                                        <mat-label>Link</mat-label>
                                                        <input formControlName="link"
                                                               matInput
                                                               autocomplete="off">
                                                    </mat-form-field>
                                                </td>
                                                <td style="text-align: center;">
                                                    <div class="example-full-width"
                                                         appearance="fill">
                                                        <!-- <button class="btn-tabla" (click)="removeActivity(i)"><i class="far fa-trash-alt"></i></button> -->
                                                        <mat-icon (click)="removeActivity(i)"
                                                                  color="primary"
                                                                  style="cursor: pointer"
                                                                  matSuffix>delete</mat-icon>
                                                    </div>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>

                                </cdk-virtual-scroll-viewport>
                            </mat-card>
                        </div>
                        <!-- FIN ACTIVIDADES -->

                        <!-- CALENDARIOS -->
                        <button type="button"
                                (click)="addCalendar()"
                                mat-raised-button
                                color="accent"
                                class="btn-calendario">
                            <mat-icon>plus_one</mat-icon>
                            Agregar Calendario</button>
                        <div *ngIf="flagCalendarsPanel && calendarios.length">
                            <mat-card>
                                <hr>
                                <cdk-virtual-scroll-viewport *ngIf="calendarios"
                                                             appendOnly
                                                             itemSize="50"
                                                             class="example-viewport">

                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Tipo</th>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Descripción</th>
                                                <th scope="col">Fecha Desde </th>
                                                <th scope="col">Fecha Hasta</th>
                                                <th scope="col">Link</th>
                                                <th scope="col">Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="calendarios">
                                            <tr *ngFor="let calendario of calendarios.controls; let i=index"
                                                [formGroupName]="i"
                                                class="animate__animated animate__fadeIn">

                                                <td>{{i+1}}</td>

                                                <td>
                                                    <mat-form-field class="example-full-width"
                                                                    appearance="fill"
                                                                    [style.width.%]=100>
                                                        <mat-label>Tipo</mat-label>
                                                        <input formControlName="tipo"
                                                               matInput
                                                               autocomplete="off">
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field class="example-full-width"
                                                                    appearance="fill"
                                                                    [style.width.%]=100>
                                                        <mat-label>Nombre</mat-label>
                                                        <input formControlName="nombreCalendario"
                                                               matInput
                                                               autocomplete="off">

                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field class="example-full-width"
                                                                    appearance="fill"
                                                                    [style.width.%]=100>
                                                        <mat-label>Descripción</mat-label>
                                                        <input formControlName="descripcion"
                                                               matInput
                                                               autocomplete="off">
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field color="accent"
                                                                    appearance="fill"
                                                                    [style.width.%]=100>
                                                        <mat-label>Fecha Inicio</mat-label>
                                                        <input matInput
                                                               formControlName="fecha"
                                                               [ngxMatDatetimePicker]="picker4"
                                                               placeholder="Fecha Inicio"
                                                               [formControl]="dateControl"
                                                               [min]="minDate1"
                                                               [max]="maxDate"
                                                               [disabled]="disabled1">
                                                        <mat-datepicker-toggle matSuffix
                                                                               [for]="picker4"></mat-datepicker-toggle>
                                                        <ngx-mat-datetime-picker #picker4
                                                                                 [showSpinners]="showSpinners"
                                                                                 [showSeconds]="showSeconds"
                                                                                 [stepHour]="stepHour"
                                                                                 [stepMinute]="stepMinute"
                                                                                 [stepSecond]="stepSecond"
                                                                                 [touchUi]="touchUi"
                                                                                 [color]="color1"
                                                                                 [enableMeridian]="enableMeridian"
                                                                                 [disableMinute]="disableMinute"
                                                                                 [hideTime]="hideTime">
                                                        </ngx-mat-datetime-picker>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field color="accent"
                                                                    appearance="fill"
                                                                    [style.width.%]=100>
                                                        <mat-label>Fecha Vencimiento</mat-label>
                                                        <input matInput
                                                               formControlName="fechaVencimiento"
                                                               [ngxMatDatetimePicker]="picker5"
                                                               placeholder="Fecha Inicio"
                                                               [formControl]="dateControl"
                                                               [min]="minDate1"
                                                               [max]="maxDate"
                                                               [disabled]="disabled1">
                                                        <mat-datepicker-toggle matSuffix
                                                                               [for]="picker5"></mat-datepicker-toggle>
                                                        <ngx-mat-datetime-picker #picker5
                                                                                 [showSpinners]="showSpinners"
                                                                                 [showSeconds]="showSeconds"
                                                                                 [stepHour]="stepHour"
                                                                                 [stepMinute]="stepMinute"
                                                                                 [stepSecond]="stepSecond"
                                                                                 [touchUi]="touchUi"
                                                                                 [color]="color1"
                                                                                 [enableMeridian]="enableMeridian"
                                                                                 [disableMinute]="disableMinute"
                                                                                 [hideTime]="hideTime">
                                                        </ngx-mat-datetime-picker>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field class="example-full-width"
                                                                    appearance="fill"
                                                                    [style.width.%]=100>
                                                        <mat-label>Link</mat-label>
                                                        <input formControlName="link"
                                                               matInput
                                                               autocomplete="off">
                                                    </mat-form-field>
                                                </td>
                                                <td style="text-align: center;">
                                                    <div class="example-full-width"
                                                         appearance="fill">
                                                        <!-- <button class="btn-tabla" (click)="removeActivity(i)"><i class="far fa-trash-alt"></i></button> -->
                                                        <mat-icon (click)="removeCalendar(i)"
                                                                  color="primary"
                                                                  style="cursor: pointer"
                                                                  matSuffix>delete</mat-icon>
                                                    </div>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>

                                </cdk-virtual-scroll-viewport>
                            </mat-card>
                        </div>

                        <!-- FIN CALENDARIOS -->

                        <!--DELEGACIONES -->
                        <div *ngIf="pista">
                            <button type="button"
                                    (click)="addDelegation()"
                                    mat-raised-button
                                    color="accent"
                                    class="btn-delegacion">
                                <mat-icon>plus_one</mat-icon>
                                Agregar Delegación</button>
                            <div *ngIf="flagDelagationsPanel && delegaciones.length">
                                <mat-card>
                                    <hr>
                                    <cdk-virtual-scroll-viewport *ngIf="delegaciones"
                                                                 appendOnly
                                                                 itemSize="50"
                                                                 class="example-viewport">

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Tipo</th>
                                                    <th scope="col">Delegación</th>
                                                    <th scope="col">Descripción</th>
                                                    <th scope="col">Fecha Desde </th>
                                                    <th scope="col">Fecha Hasta</th>
                                                    <th scope="col">Opciones</th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="delegaciones">
                                                <tr *ngFor="let delegacion of delegaciones.controls; let i=index"
                                                    [formGroupName]="i"
                                                    class="animate__animated animate__fadeIn">

                                                    <td>{{i+1}}</td>

                                                    <td>
                                                        <mat-form-field class="example-full-width"
                                                                        appearance="fill"
                                                                        [style.width.%]=100>
                                                            <mat-label>Tipo</mat-label>
                                                            <input formControlName="tipo"
                                                                   matInput
                                                                   autocomplete="off">
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field class="example-full-width"
                                                                        appearance="fill"
                                                                        [style.width.%]=100>
                                                            <mat-label>Seleccionar Delegación</mat-label>
                                                            <mat-select formControlName="delegacion">
                                                                <mat-option *ngFor="let delegacion of selectorDelegaciones"
                                                                            [value]="delegacion.concepto">
                                                                    {{delegacion.concepto}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field class="example-full-width"
                                                                        appearance="fill"
                                                                        [style.width.%]=100>
                                                            <mat-label>Descripción</mat-label>
                                                            <input formControlName="descripcion"
                                                                   matInput
                                                                   autocomplete="off">
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field color="accent"
                                                                        appearance="fill"
                                                                        [style.width.%]=100>
                                                            <mat-label>Fecha Inicio</mat-label>
                                                            <input matInput
                                                                   formControlName="fecha"
                                                                   [ngxMatDatetimePicker]="picker4"
                                                                   placeholder="Fecha Inicio"
                                                                   [formControl]="dateControl"
                                                                   [min]="minDate1"
                                                                   [max]="maxDate"
                                                                   [disabled]="disabled1">
                                                            <mat-datepicker-toggle matSuffix
                                                                                   [for]="picker4"></mat-datepicker-toggle>
                                                            <ngx-mat-datetime-picker #picker4
                                                                                     [showSpinners]="showSpinners"
                                                                                     [showSeconds]="showSeconds"
                                                                                     [stepHour]="stepHour"
                                                                                     [stepMinute]="stepMinute"
                                                                                     [stepSecond]="stepSecond"
                                                                                     [touchUi]="touchUi"
                                                                                     [color]="color1"
                                                                                     [enableMeridian]="enableMeridian"
                                                                                     [disableMinute]="disableMinute"
                                                                                     [hideTime]="hideTime">
                                                            </ngx-mat-datetime-picker>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field color="accent"
                                                                        appearance="fill"
                                                                        [style.width.%]=100>
                                                            <mat-label>Fecha Vencimiento</mat-label>
                                                            <input matInput
                                                                   formControlName="fechaVencimiento"
                                                                   [ngxMatDatetimePicker]="picker5"
                                                                   placeholder="Fecha Hasta"
                                                                   [formControl]="dateControl"
                                                                   [min]="minDate1"
                                                                   [max]="maxDate"
                                                                   [disabled]="disabled1">
                                                            <mat-datepicker-toggle matSuffix
                                                                                   [for]="picker5"></mat-datepicker-toggle>
                                                            <ngx-mat-datetime-picker #picker5
                                                                                     [showSpinners]="showSpinners"
                                                                                     [showSeconds]="showSeconds"
                                                                                     [stepHour]="stepHour"
                                                                                     [stepMinute]="stepMinute"
                                                                                     [stepSecond]="stepSecond"
                                                                                     [touchUi]="touchUi"
                                                                                     [color]="color1"
                                                                                     [enableMeridian]="enableMeridian"
                                                                                     [disableMinute]="disableMinute"
                                                                                     [hideTime]="hideTime">
                                                            </ngx-mat-datetime-picker>
                                                        </mat-form-field>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <div class="example-full-width"
                                                             appearance="fill">
                                                            <mat-icon (click)="removeDelegation(i)"
                                                                      color="primary"
                                                                      style="cursor: pointer"
                                                                      matSuffix>delete</mat-icon>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </cdk-virtual-scroll-viewport>
                                </mat-card>
                            </div>
                        </div>
                        <!-- FIN DELEGACIONES -->

                        <!-- FECHAS DISPONIBLES -->
                        <div *ngIf="!pista">
                            <button type="button"
                                    (click)="addAvailableDate()"
                                    mat-raised-button
                                    color="accent"
                                    class="btn-fecha-disponible">
                                <mat-icon>plus_one</mat-icon>
                                Agregar Fechas Disponibles</button>
                            <div *ngIf="flagAvailableDatesPanel && fechasDisponibles.length">
                                <mat-card>
                                    <hr>
                                    <cdk-virtual-scroll-viewport *ngIf="fechasDisponibles"
                                                                 appendOnly
                                                                 itemSize="50"
                                                                 class="example-viewport">

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Tipo</th>
                                                    <th scope="col">Descripción</th>
                                                    <th scope="col">Fecha Desde </th>
                                                    <th scope="col">Fecha Hasta</th>
                                                    <th scope="col">Opciones</th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="fechasDisponibles">
                                                <tr *ngFor="let fechaDisponible of fechasDisponibles.controls; let i=index"
                                                    [formGroupName]="i"
                                                    class="animate__animated animate__fadeIn">

                                                    <td>{{i+1}}</td>

                                                    <td>
                                                        <mat-form-field class="example-full-width"
                                                                        appearance="fill"
                                                                        [style.width.%]=100>
                                                            <mat-label>Tipo</mat-label>
                                                            <input formControlName="tipo"
                                                                   matInput
                                                                   autocomplete="off">
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field class="example-full-width"
                                                                        appearance="fill"
                                                                        [style.width.%]=100>
                                                            <mat-label>Descripción</mat-label>
                                                            <input formControlName="descripcion"
                                                                   matInput
                                                                   autocomplete="off">
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field color="accent"
                                                                        appearance="fill"
                                                                        [style.width.%]=100>
                                                            <mat-label>Fecha Inicio</mat-label>
                                                            <input matInput
                                                                   formControlName="fecha"
                                                                   [ngxMatDatetimePicker]="picker4"
                                                                   placeholder="Fecha Inicio"
                                                                   [formControl]="dateControl"
                                                                   [min]="minDate1"
                                                                   [max]="maxDate"
                                                                   [disabled]="disabled1">
                                                            <mat-datepicker-toggle matSuffix
                                                                                   [for]="picker4"></mat-datepicker-toggle>
                                                            <ngx-mat-datetime-picker #picker4
                                                                                     [showSpinners]="showSpinners"
                                                                                     [showSeconds]="showSeconds"
                                                                                     [stepHour]="stepHour"
                                                                                     [stepMinute]="stepMinute"
                                                                                     [stepSecond]="stepSecond"
                                                                                     [touchUi]="touchUi"
                                                                                     [color]="color1"
                                                                                     [enableMeridian]="enableMeridian"
                                                                                     [disableMinute]="disableMinute"
                                                                                     [hideTime]="hideTime">
                                                            </ngx-mat-datetime-picker>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field color="accent"
                                                                        appearance="fill"
                                                                        [style.width.%]=100>
                                                            <mat-label>Fecha Vencimiento</mat-label>
                                                            <input matInput
                                                                   formControlName="fechaVencimiento"
                                                                   [ngxMatDatetimePicker]="picker5"
                                                                   placeholder="Fecha Inicio"
                                                                   [formControl]="dateControl"
                                                                   [min]="minDate1"
                                                                   [max]="maxDate"
                                                                   [disabled]="disabled1">
                                                            <mat-datepicker-toggle matSuffix
                                                                                   [for]="picker5"></mat-datepicker-toggle>
                                                            <ngx-mat-datetime-picker #picker5
                                                                                     [showSpinners]="showSpinners"
                                                                                     [showSeconds]="showSeconds"
                                                                                     [stepHour]="stepHour"
                                                                                     [stepMinute]="stepMinute"
                                                                                     [stepSecond]="stepSecond"
                                                                                     [touchUi]="touchUi"
                                                                                     [color]="color1"
                                                                                     [enableMeridian]="enableMeridian"
                                                                                     [disableMinute]="disableMinute"
                                                                                     [hideTime]="hideTime">
                                                            </ngx-mat-datetime-picker>
                                                        </mat-form-field>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <div class="example-full-width"
                                                             appearance="fill">
                                                            <!-- <button class="btn-tabla" (click)="removeActivity(i)"><i class="far fa-trash-alt"></i></button> -->
                                                            <mat-icon (click)="removeAvailableDate(i)"
                                                                      color="primary"
                                                                      style="cursor: pointer;"
                                                                      matSuffix>delete</mat-icon>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </cdk-virtual-scroll-viewport>
                                </mat-card>
                            </div>
                        </div>

                        <!-- FIN FECHAS DISPONIBLES -->

                        <hr>

                        <div class="row">
                            <div class="form-group"
                                 style="margin-top: 3%;">
                                <label for="formFile"
                                       class="form-label">Video Presentación</label>
                                <input formControlName="video"
                                       class="form-control-xs"
                                       type="file"
                                       accept="video/mp4"
                                       (change)="openFileMp4Selected($event);">
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group">
                                <label class="form-label">Imagen Presentación (750px X 500px)</label>
                                <input [disabled]="condition"
                                       class="file-input"
                                       type="file"
                                       formControlName="imagen"
                                       #fileInput
                                       accept="image/jpeg, image/jpg, image/png"
                                       (change)="onChangeFile(fileInput.files[0], $event)" />
                                <div matRipple
                                     [matRippleCentered]="centered"
                                     [matRippleDisabled]="disabled"
                                     [matRippleUnbounded]="unbounded"
                                     [matRippleRadius]="radius"
                                     [matRippleColor]="color">
                                    <img [src]="imageUrl" />
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <button type="button"
                                        routerLink="/cursos-configuracion"
                                        class="btn btn-warning">Cancelar</button>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <button [disabled]="!form.valid || this.cargandoArchivo"
                                        type="submit">Guardar</button>
                            </div>
                        </div>
                    </form>

                    <div class="mt-5"
                         *ngIf="this.cargandoArchivo">
                        <div class="progress"
                             style="height: 20px;">
                            <div class="progress-bar bg-info"
                                 role="progressbar"
                                 [style.width]="this.value + '%'"
                                 aria-valuenow="this.value"
                                 aria-valuemin="0"
                                 aria-valuemax="100">{{this.value}}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>