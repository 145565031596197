<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li>Configuración Ofertas</li>
            </ul>
            <h2>Configurar Ofertas</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg"
             alt="image"></div>
</div>

<div class="features-area pb-70 animate__animated animate__fadeIn">
    <div class="container">

        <div class="row m-5">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-list-ul"></i>
                    </div>
                    <h3>Listado de Pre Inscripciones</h3>
                    <p>Acceda al listado de Ofertas del sistema</p>
                    <a routerLink="/pre-inscripciones-listado"
                       class="btn btn-warning">Ver Pre Inscripciones</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-plus-square"></i>
                    </div>
                    <h3>Nueva Pre Inscripción</h3>
                    <p>Cree una nueva Oferta en el sistema</p>
                    <a routerLink="/ofertas-alta"
                       class="btn btn-warning">Crear Pre Incripción</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-book-open"></i>
                    </div>
                    <h3><b>Catálogo</b></h3>
                    <p>Acceda a la configuración áreas, modalidades y tipo de puestos</p>
                    <a routerLink="/catalogo-configuracion"
                       class="btn btn-info">
                        Ir a Catálogo
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-book-open"></i>
                    </div>
                    <h3><b>Adjuntos</b></h3>
                    <p>Acceda a la configuración de adjuntos</p>
                    <a routerLink="/adjuntos-configuracion"
                       class="btn btn-primary">
                        Ir a Adjuntos
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>