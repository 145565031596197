<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/ofertas-administracion-listado">Solicitudes</a></li>
                <li *ngIf="flagLoaded"> Solicitud: {{ this.solicitud._id }}</li>
            </ul>
            <h2 *ngIf="this.flagLoaded">Detalle Solicitud - {{this.solicitud.lastState.numberState | estadosPipe}}</h2>
            <div *ngIf="this.flagLabelAprobado" class="p-3 mb-2 bg-success text-white">APROBADA
            </div>
            <div *ngIf="this.flagLabelRechazado" class="p-3 mb-2 bg-danger text-white">RECHAZADA
            </div>

        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<br>
<br>
<div *ngIf="flagLoaded">
    <div class="container">
        <div class="row align-items-center">
            <!-- estados administrativos-->
            <span *ngIf="flagPreAprobacion" class="alert alert-primary" role="alert">Solicitud Lista para Pre Aprobar</span>
            <span *ngIf="flagPreAprobado" class="alert alert-primary" role="alert">Solicitud Pre-Aprobada</span>
            <span *ngIf="flagAprobado" class="alert alert-success" role="alert"> Solicitud Aprobada</span>
            <span *ngIf="activarAprobar" class="alert alert-primary" role="alert">Solicitud lista para Control de Pago</span>
            <span *ngIf="activaAnulada" class="alert alert-primary" role="alert">Solicitud Anulada</span>

            <app-ofertas-estado-detalle [solicitud]="solicitud" [usuario]="usuario" [nombrePais]="nombrePais" [estadoPagoTotal]="estadoPagoTotal" [estadoFormulario]="estadoFormulario" [estadoDocumentacion]="estadoDocumentacion" [estadoPagoTotal]="estadoPagoTotal"></app-ofertas-estado-detalle>
            <br>

            <app-modificar-modulos-solicitud [solicitud]="solicitud"></app-modificar-modulos-solicitud>

            <app-modificar-actividades [solicitud]="solicitud"></app-modificar-actividades>
            
            <mat-divider></mat-divider>


            <br>
            <div class="col-lg-8">
                <!-- stepper -->
                <app-stepper [stepperSet]="this.solicitud.lastState.numberState" [vertical]="false"></app-stepper>
            </div>
            <br>
            <div class="courses-details-area pb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header [ngClass]="{'contacto': activarFormulario}">
                                        <mat-panel-title>
                                            Datos Formulario
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div *ngIf=activarFormulario>
                                        <app-ofertas-formulario [usuario]="usuario" [solicitud]="solicitud"></app-ofertas-formulario>
                                    </div>
                                    <div *ngIf=!activarFormulario>El usuario no realizo la carga del formulario </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="row" *ngIf='this.imgs.length > 0'>
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header class="fotos">
                                        <mat-panel-title>
                                            Documentación Solicitud - Fotos
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <app-ofertas-gallery [imagenes]="this.imgs" [id_usuario]="this.usuario._id"></app-ofertas-gallery>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <div class="row" *ngIf='!this.imgs.length > 0'>
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            SIN Documentación Solicitud - Fotos
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div class="form-group">
                                        <div class="col-sm-12">
                                            <mat-list>
                                                <mat-list-item>
                                                    <i class="fa fa-file-archive-o fa-2x" aria-hidden="true"></i>
                                                </mat-list-item>
                                            </mat-list>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="row" *ngIf='this.files.length > 0'>
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header class="archivos">
                                        <mat-panel-title>
                                            Documentación Trámite - Archivos
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <app-ofertas-files [files]="this.files" [usuario]="this.usuario"></app-ofertas-files>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <div class="row" *ngIf='!this.files.length > 0'>
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            SIN Documentación Solicitud - Archivos
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div class="form-group">
                                        <div class="col-sm-12">
                                            <mat-list>
                                                <mat-list-item>
                                                    <i class="fa fa-file-archive-o fa-2x" aria-hidden="true"></i>
                                                </mat-list-item>
                                            </mat-list>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <br>
                    <br>
                    <!-- SPIINER -->
                    <!-- <div *ngIf="flagSpinner">
                        <app-spinner-colors></app-spinner-colors>
                    </div> -->
                    <div class="row">
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header class="clasico">
                                        <mat-panel-title>
                                            Tratar Solicitud
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <br>
                                    <mat-card>
                                        <mat-card-header>
                                            <div mat-card-avatar></div>
                                            <mat-card-title>PRE - APROBAR</mat-card-title>
                                            <mat-card-subtitle>Documentación correcta..</mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-actions>
                                            <button *ngIf=activarPreAprobar (click)="actualizarEstadoSolicitud(this.solicitud._nro, false, this.solicitud._id, this.iAppOfertas.estado_4.number, this.iAppOfertas.estado_4.name, true)" mat-raised-button color="accent">Pre-Aprobar</button>
                                            <button *ngIf=!activarPreAprobar mat-raised-button color="accent" disabled>Pre-Aprobar</button>
                                        </mat-card-actions>
                                    </mat-card>
                                    <br>
                                    <mat-card>
                                        <mat-card-header>
                                            <div mat-card-avatar></div>
                                            <mat-card-title>SOLICITAR PAGO DE SALDO</mat-card-title>
                                            <mat-card-subtitle>Solicitar el pago pendiente...</mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-actions> 
                                            <!-- <button (click)="solicitarPagoTotal(this.solicitud, this.iAppOfertas.estado_5.number)" mat-raised-button color="success">SOLICITAR PAGO TOTAL</button> -->
                                            <!-- <button mat-raised-button color="success" disabled>SOLICITAR PAGO TOTAL</button>  -->
                                            <button *ngIf=activarSolicitarPagoTotal (click)="solicitarPagoTotal(this.solicitud, this.iAppOfertas.estado_5.number)" mat-raised-button color="success">SOLICITAR PAGO TOTAL</button>
                                            <button *ngIf=!activarSolicitarPagoTotal mat-raised-button color="success" disabled>SOLICITAR PAGO TOTAL</button> 
                                            <!-- SPIINER -->
                                            <div *ngIf="flagSpinner">
                                                <app-spinner-colors></app-spinner-colors>
                                            </div>
                                        </mat-card-actions>
                                    </mat-card>
                                    <br>
                                    

                                    <mat-card>
                                        <mat-card-header>
                                            <div mat-card-avatar></div>
                                            <mat-card-title>EN ESPERA INICIO DE CURSO</mat-card-title>
                                            <mat-card-subtitle>en espera...</mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-actions>
                                            <p>Recuerde que  EN ESPERA INICIO DE CURSO, es un estado de espera no activa funciones.</p>
                                            <br>
                                            <mat-slide-toggle *ngIf="activarEspera" [(ngModel)]="isCheckedEspera">Active la opción para aprobar</mat-slide-toggle>
                                            <hr>
                                            <section *ngIf="isCheckedEspera">
                                                <button *ngIf="activarEspera" (click)="actualizarEstadoSolicitud(this.solicitud._nro, true, this.solicitud._id, this.iAppOfertas.estado_10.number, this.iAppOfertas.estado_10.name, true)" mat-raised-button color="success">En espera</button>
                                            </section>
                                            <button *ngIf=!activarEspera mat-raised-button color="accent" disabled>Active la opción para aprobar</button>
                                        </mat-card-actions>
                                    </mat-card>
                                    <br>

                                    <mat-card>
                                        <mat-card-header>
                                            <div mat-card-avatar></div>
                                            <mat-card-title>APROBAR</mat-card-title>
                                            <mat-card-subtitle>Pago realizado...</mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-actions>
                                            <p>Recuerde que al APROBAR, al cliente se le activaran los módulos correspondientes a la solicitud, y los documentos de la solicitud serán enviados a su legajo.</p>
                                            <br>
                                            <mat-slide-toggle *ngIf="activarAprobar" [(ngModel)]="isCheckedAprobar">Active la opción para aprobar</mat-slide-toggle>
                                            <hr>
                                            <section *ngIf="isCheckedAprobar" class="section">
                                                <button *ngIf="activarAprobar" (click)="actualizarEstadoSolicitud(this.solicitud._nro, true, this.solicitud._id, this.iAppOfertas.estado_7.number, this.iAppOfertas.estado_7.name, true)" mat-raised-button color="success">Aprobar</button>
                                            </section>
                                            <button *ngIf=!activarAprobar mat-raised-button color="accent" disabled>Aprobar</button>
                                        </mat-card-actions>
                                    </mat-card>
                                    <br>
                                    <!-- <mat-card>
                                        <mat-card-header>
                                            <div mat-card-avatar></div>
                                            <mat-card-title>3-ENVIAR CONTRATO</mat-card-title>
                                        </mat-card-header>
                                        <div>
                                            <mat-card-content>
                                                Cuando contrato es cargado y enviado, la solicitud queda APROBADA.. (último estado)
                                            </mat-card-content>
                                            <mat-card-actions>
                                                <button *ngIf="activarEnviarContrato" (click)="openDialog(labelContrato, this.iAppOfertas.estado_8.number, this.adjuntoCatalogo.properties._idAdjunto)" color="success">ENVIAR CONTRATO<span></span></button>
                                                <button *ngIf="!activarEnviarContrato" mat-raised-button color="success" disabled>ENVIAR CONTRATO</button>
                                            </mat-card-actions>
                                        </div>
                                    </mat-card>
                                    <br> -->
                                    <mat-card>
                                        <mat-card-header>
                                            <mat-card-title>ANULAR</mat-card-title>
                                            <mat-card-content>
                                                <p>IMPORTANTE: Al anular una solicitud, NO afecta los cursos asignados al alumno.</p>
                                            </mat-card-content>
                                        </mat-card-header>
                                        <mat-card-actions>
                                            <mat-slide-toggle *ngIf="!activaAnulada" [(ngModel)]="isCheckedAnular">Active la opción para anular</mat-slide-toggle>
                                            <hr>
                                            <section *ngIf="isCheckedAnular" lass="section">
                                                <button *ngIf=!activaAnulada (click)="actualizarEstadoSolicitud(this.solicitud._nro, false, this.solicitud._id, this.iAppOfertas.estado_9.number,this.iAppOfertas.estado_9.name, true )" mat-raised-button color="accent">Anular</button>
                                                <button *ngIf=activaAnulada mat-raised-button color="warn" disabled>Anular</button>
                                            </section>
                                        </mat-card-actions>
                                    </mat-card>
                                    <br>                                   

                                    <mat-card>
                                        <mat-card-header>
                                            <mat-card-title>RECHAZAR</mat-card-title>
                                            <!-- <mat-card-subtitle>Solo puede rechazar a partir de que el usuario cargo el formulario</mat-card-subtitle> -->
                                            <mat-card-content>
                                                <p>IMPORTANTE: Recuerde que puede rechazar una solicitud APROBADA. Al momento de rechazar una solicitud APROBADA, Se quitaran los cursos asignados al usuario/alumno, al momento de su aprobación. Si RECHAZA,
                                                    quita los cursos al alumno</p>
                                            </mat-card-content>
                                        </mat-card-header>
                                        <mat-card-actions>
                                            <mat-slide-toggle *ngIf="activarRechazar" [(ngModel)]="isCheckedRechazar">Active la opción para rechazar</mat-slide-toggle>
                                            <hr>
                                            <section *ngIf="isCheckedRechazar">
                                                <br>
                                                <mat-form-field class="motivo-text-area" mat-focus-on-activation>
                                                    <mat-label>Motivo de Rechazo</mat-label>
                                                    <textarea matInput placeholder="Escriba el motivo por el cual se rechaza la solicitud..." mat-autosize="true" [(ngModel)]="motivoRechazo"></textarea>
                                                </mat-form-field>
                                                <br>
                                                <button *ngIf="activarRechazar" (click)="actualizarEstadoSolicitud(this.solicitud._nro, false, this.solicitud._id, this.iAppOfertas.estado_8.number,this.iAppOfertas.estado_8.name, true, motivoRechazo)" mat-raised-button color="warn">Rechazar</button>
                                                <button *ngIf=!activarRechazar mat-raised-button color="warn" disabled>Rechazar</button>
                                            </section>
                                        </mat-card-actions>                                        
                                    </mat-card>
                                    
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header class="clasico">
                                        <mat-panel-title>
                                            Descripción
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <app-detalle [actividad]=actividad [nombrePais]=nombrePais></app-detalle>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button *ngIf=activarCerrarModal (click)="closeModal()" class="btn btn-secondary" type="button">SALIR</button>
                        <button *ngIf=!activarCerrarModal (click)="closeModal()" class="btn btn-secondary" type="button" disabled>SALIR</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>