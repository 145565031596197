

<div class="row">
    <div class="section-title">
        <span class="sub-title">Calendarios</span>
        <p>En esta sección encontrarás tus calendarios</p>

        <div *ngIf="!this.flag" class="alert alert-info">
            <p>No hay Calendarios en línea disponibles por el momento</p>
        </div>
    </div>
    <app-loading *ngIf="!flagLoaded" class="m-5"></app-loading>
    <div class="row justify-content-left">
        <div class="col-lg-12 col-sm-12 col-md-12" *ngIf="flagLoaded">
            <ol class="list-group list-group-numbered animat ">
                <mat-card *ngFor="let actividad of actividadesCalendario ; let i = index">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="">
                                
                                <div class="fw-bold" (click)="link(actividad.link)">
                                    <p>{{i+1}}. {{ actividad.nombre }}.</p>
                                </div>
                                <span class="location"><i class="bx bx-map"></i>{{ actividad.tipo }}</span> Descripción: {{ actividad.descripcion }}
                                <p><b><a class="linkClass" (click)="link(actividad.link)">Haz clic aquí para ir a al calendario..
                                    <mat-icon aria-hidden="false" aria-label="Example home icon">touch_app</mat-icon>
                                </a></b></p>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <span class="badge bg-primary rounded-pill">{{ actividad.fecha | date:"dd/MM/yy HH:mm a'"}}</span> -
                            <span class="badge bg-danger rounded-pill">{{ actividad.fechaVencimiento | date:"dd/MM/yy HH:mm a'"}}</span>
                        </div>
                    </div>
                </mat-card>
            </ol>
        </div>
    </div>
</div>
<br> 
