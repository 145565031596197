import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '../../interfaces/interface-bakend/response';
import { Solicitud, State, SolicitudSearch, SolicitudSearchElement, SolicitudCreate } from '../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { StorageService } from '../shared/storage.service';
import { GuiMsjService } from '../shared/gui-msj.service';
import { IAppOfertas, APP_CONFIG_OFERTAS } from '../../app.config/app.config-ofertas';
import { CardData } from '../../interfaces/interface-frontend/shared/cardData.interface';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Actividad } from 'src/app/interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { UserDocumentoTiposService } from '../shared/user-documento-tipos.service';
import { PersonasService } from '../shared/personas.service';
import { UsuarioService } from '../shared/usuario.service';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';

const URL_BACKEND = environment.urlBackend;
const MERCADO_PAGO = environment.mercadoPagoActivo;
const MACRO_PAGOS = environment.macroPagosActivo;


@Injectable({
    providedIn: 'root'
})
export class SolicitudesService {

    solicitud: Solicitud;
    estado: number;
    token: any;

    private _state = new Subject<any>();


    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        private httpClient: HttpClient,
        private storageService: StorageService,
        private guiMsjService: GuiMsjService,
        private authService: AuthService,
        private userDocumentoTiposService: UserDocumentoTiposService,
        private usuarioService: UsuarioService,
        private router: Router,
    ) { }

    setNewState(solicitud: Solicitud) {
        this._state.next({ state: solicitud });
    }

    getsetNewStater(): Observable<any> {
        return this._state.asObservable();
    }

    enviarDocumentacion(solicitud: Solicitud, user: User): Promise<Solicitud> {
        return new Promise(async resolve => {

            const data = {
                _idSolicitud: solicitud._id,
                _idUser: user._id,
            };

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.post<Response>(`${URL_BACKEND}/solicitudes/sendDocumentsJobOffer/`, data, { headers })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                }, error => {
                    this.guiMsjService.msjFormSubmit('documentosEnviadosERROR');
                    console.log('error', error);
                });
        });
    }

    updateSolicitud(_id: string, solicitud: Solicitud): Promise<Solicitud> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.put<Response>(`${URL_BACKEND}/solicitudes/updateOne/${_id}`, solicitud, { headers })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                }, error => {
                    console.log('error', error);
                });
        });
    }

    updateSolicitudState(_id: string, stateNumber: number, notificaAdmin: boolean, state?: string, notificar?: boolean, observaciones?: string): Promise<Solicitud> {
        return new Promise(async resolve => {

            const data = {
                numberState: stateNumber,
                state: state || '',
                notificar: notificar || false,
                notificaAdmin: notificaAdmin || false,
                observaciones: observaciones || '',
                solicitud: _id,
            };

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            // tslint:disable-next-line:max-line-length
            this.httpClient.post<Response>(`${URL_BACKEND}/solicitudes/addNewState/`, data, { headers })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                }, error => {
                    console.log('error', error);
                });
        });
    }

    async getSolicitudes(): Promise<Solicitud[]> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.get<Response>(`${URL_BACKEND}/solicitudes/findAll`, { headers })
                .subscribe(resp => {
                    resolve(resp.response);
                });
        });
    }

    async getSolicitudesByUser(idUser: string): Promise<Solicitud[]> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.get<Response>(`${URL_BACKEND}/solicitudes/findByIdUser/${idUser}`, { headers })
                .subscribe(resp => {
                    resolve(resp.response);
                });
        });
    }

    async getSolicitudById(id: string): Promise<Solicitud> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.get<Response>(`${URL_BACKEND}/solicitudes/findOne/${id}`,  {})
                .subscribe(resp => {
                    resolve(resp.response);
                });
        });
    }

    async getSolicitudesByEstado(estado: number): Promise<Solicitud[]> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.get<Response>(`${URL_BACKEND}/solicitudes/findByStatus/${estado}`, { headers })
                .subscribe(resp => {
                    resolve(resp.response);
                });
        });
    }

    async getSolicitudesByTipo(tipoSolicitud: boolean): Promise<Solicitud[]> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            console.log('envio tipo', tipoSolicitud);

            this.httpClient.get<Response>(`${URL_BACKEND}/solicitudes/findByTipo/${tipoSolicitud}`, { headers })
                .subscribe(resp => {
                    console.log('solicitudes/findByTipo', resp);
                    resolve(resp.response);
                });
        });
    }

    async getSolicitudesByTipoYCurso(tipoSolicitud: boolean, curso: string): Promise<Solicitud[]> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            if (curso != undefined) {
                this.httpClient.get<Response>(`${URL_BACKEND}/solicitudes/findByCurso/${tipoSolicitud}/${curso}`, { headers })
                    .subscribe(resp => {
                        resolve(resp.response);
                    });
            } else {
                this.httpClient.get<Response>(`${URL_BACKEND}/solicitudes/findByTipo/${tipoSolicitud}`, { headers })
                    .subscribe(resp => {
                        resolve(resp.response);
                    });
            }
        });
    }

    postCreateSolicitud(solicitud: Solicitud): Promise<Solicitud> {

        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.post<Response>(`${URL_BACKEND}/solicitudes/`, solicitud, { headers }).subscribe(resp => {
                resolve(resp.response);
            }, error => {
                console.log('error', error);
                this.guiMsjService.msjFormSubmit('crearSolicitudError');
            });
        });
    }

    async postComprarSolicitud(solicitud: SolicitudCreate): Promise<Solicitud> {
        console.log('solicitud enviada a comprarSolicitud', solicitud);
        const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise(async resolve => {
            await this.httpClient.post<Response>
                (`${URL_BACKEND}/solicitudes/comprarSolicitud`, solicitud, { headers }).subscribe(resp => {
                    resolve(resp.response);
                }, error => {
                    this.guiMsjService.msjFormSubmit('crearSolicitudError');
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(async () => {
                        await this.router.navigateByUrl('/');
                    });
                });
        });

    }

    async create(solicitud: SolicitudCreate): Promise<SolicitudCreate> {

        const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise(async resolve => {
            await this.httpClient.post<Response>
                (`${URL_BACKEND}/solicitudes`, solicitud, { headers }).subscribe(resp => {
                    console.log('respuesta', resp.response);
                    resolve(resp.response);
                }, error => {
                    this.guiMsjService.msjFormSubmit('crearSolicitudError');
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(async () => {
                        await this.router.navigateByUrl('/');
                    });
                });
        });

    }


    setSolicitudSeleccionada(solicitud: Solicitud) {
        this.solicitud = solicitud;
        this.storageService.setSolicitud(solicitud);
    }

    getSolicitudSeleccionada() {
        return this.solicitud;
    }

    cleanPais() {
        this.solicitud = null;
    }

    getEstadoSolicitudSeleccionado() {
        // falta storageService
        return this.estado;
    }

    setEstadoSolicitud(estado: number) {
        // falta storageService
        this.estado = estado;
    }

    cleanEstado() {
        // falta storageService
        this.estado = null;
    }

    async getSolicitudesByFilter(inscripcion: string, delegacion: string[], disciplina: string[], cursos: Curso[], estados: number[]): Promise<SolicitudSearchElement[]> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            let actividades = []
            actividades.push(inscripcion);
            const data: SolicitudSearch = {
                actividades: actividades,
                delegaciones: delegacion,
                disciplinas: disciplina,
                modulos: cursos,
                states: estados,
            }

            this.httpClient.post<Response>(`${URL_BACKEND}/solicitudes/findBySearchConditions/`, data, { headers })
                .subscribe(resp => {
                    resolve(resp.response);
                });
        });
    }


    async moverDocumentosFileSolicitudAusuario(usuario: User, adjuntosSolicitud: string[]): Promise<string[]> {
        // console.log('adjuntosUsuarios', adjuntosUsuarios);
        console.log('adjuntosSolicitud', adjuntosSolicitud);
        console.log('adjuntosUsuarios', usuario.adjuntos);

        // control cuando no existe el arreglo
        if (usuario.adjuntos == undefined) {
            usuario.adjuntos = [];
        }

        if (adjuntosSolicitud.length > 0) {
            adjuntosSolicitud.forEach((doc: any) => {
                if (!this.userDocumentoTiposService.documentosSolicitado(usuario.adjuntos, doc._id)) {
                    console.log('pusheo en usuario doc nuevo', doc);
                    usuario.adjuntos.push(doc);
                } else {
                    console.log('documento ya cargado..');
                }
            });
        } else {
            console.log('solicitud sin documentos que copiar');
        }
        console.log('arreglo con nuevos documento', usuario.adjuntos);

        // ACTUALIZO USUARIO CON ARREGLO NUEVO
        if (await this.usuarioService.updateOne({ _id: usuario._id, adjuntos: usuario.adjuntos })) {
            console.log('update');
        } else {
            console.log('error update');
        }
        return usuario.adjuntos;
    }

    documentosSolicitado(documentosUsuario: string[], documentosSolicitado: string): boolean { // este metodo permite sabber que documentos ya tiene en su legajo para no volver a solicitar
        if (documentosSolicitado !== undefined && documentosUsuario.length > 0) {
            for (let i = 0; i < documentosUsuario.length; i++) {
                const element = documentosUsuario[i];
                const firstPart = element.split('.')[0];
                if (firstPart === documentosSolicitado) {
                    return true;
                }
            }
            return false; // Si no se encontró ninguna coincidencia, devuelve false
        } else {
            return false;
        }
    }

    // TODOS
    async controlSolicitudModulosUsuarioGral(_idUsuario: string, solicitudBuscar: any): Promise<{ existeCurso: boolean, cursosRealizados: any[], estadoPermitido: boolean }> {
        const solicitudes: any[] = await this.getSolicitudesByUser(_idUsuario);

        // Obtener todos los cursos presentes en alguna solicitud
        const cursosRealizados: any[] = [];
        solicitudes.forEach(solicitud => {
            solicitud.cursos.forEach((curso: any) => {
                cursosRealizados.push(curso);
            });
        });

        // Verificar si hay al menos un curso presente en alguna solicitud
        const existeCurso = solicitudBuscar.cursos.some((curso: any) => {
            return cursosRealizados.some(c => c._id === curso);
        });

        // Verificar si el último estado es 0 o 9 y si tiene el curso realizado
        let estadoPermitido = false;
        if (solicitudes.length > 0) {
            const ultimoEstado = solicitudes[solicitudes.length - 1].lastState;
            estadoPermitido = (ultimoEstado && (ultimoEstado.numberState === 8 || ultimoEstado.numberState === 9)) || false;
        }

        const tieneCursoRealizado = existeCurso && estadoPermitido;

        return { existeCurso, cursosRealizados, estadoPermitido: tieneCursoRealizado };
    }

    // AADIDESS
    async controlSolicitudModulosUsuario(_idUsuario: string, solicitudBuscar: any): Promise<{ existeCurso: boolean, cursosRealizados: any[], estadoPermitido: boolean }> {
        const solicitudes: any[] = await this.getSolicitudesByUser(_idUsuario);

        // Obtener todos los cursos presentes en alguna solicitud
        const cursosRealizados: any[] = [];
        solicitudes.forEach(solicitud => {
            solicitud.cursos.forEach((curso: any) => {
                // Agregar las propiedades _id y createdAt de la solicitud al curso
                curso._idSolicitud = solicitud._id;
                curso.solicitudCreatedAt = solicitud.createdAt;
                curso.estadoSolicitud = solicitud.lastState.numberState

                // Agregar el curso al arreglo de cursos realizados
                cursosRealizados.push(curso);
            });
        });

        // Verificar si hay al menos un curso presente en alguna solicitud
        const existeCurso = solicitudBuscar.cursos.some((curso: any) => {
            return cursosRealizados.some(c => c._id === curso);
        });

        // Verificar si el último estado es 0 o 9 y si tiene el curso realizado
        let estadoPermitido = false;

        if (solicitudes.length > 0) {
            const ultimoEstado = solicitudes[solicitudes.length - 1].lastState;
            estadoPermitido = (ultimoEstado && (ultimoEstado.numberState === 8 || ultimoEstado.numberState === 9)) || false;
        }

        const tieneCursoRealizado = existeCurso && estadoPermitido;

        return { existeCurso, cursosRealizados, estadoPermitido: tieneCursoRealizado };
    }


    getMercadoPagoActuvo(): Boolean {
        return MERCADO_PAGO;
    }

    getMacroPagosActivo(): Boolean {
        return MERCADO_PAGO;
    }

    async getActividadesByFechaPistaOnline(data: object): Promise<Actividad[]> {

        const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise(resolve => {
            this.httpClient.post<Response>(`${URL_BACKEND}/solicitudes/findByTipoByFechaDesdeHasta`, data, { headers }).subscribe(resp => {
                resolve(resp.response);
            });
        });
    }

    updateSolicitudLeido(_id: string, solicitud: Solicitud): Promise<Solicitud> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            // Cast titular, beneficiario, usuario a string
            const updatedSolicitud = {
                _id: solicitud._id,
                leido: solicitud.leido,
                pais: solicitud.pais,
                actividad: solicitud.actividad,
            };

            this.httpClient.put<Response>(`${URL_BACKEND}/solicitudes/updateOne/${_id}`, updatedSolicitud, { headers })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                }, error => {
                    console.log('error', error);
                });
        });
    }


    // async controlSolicitudModulosUsuario(_idUsuario: string, solicitudBuscar: any): Promise<{ existeCurso: boolean, cursosRealizados: any[], estadoPermitido: boolean }> {
    //     const solicitudes: any[] = await this.getSolicitudesByUser(_idUsuario);

    //     // Obtener todos los cursos presentes en alguna solicitud
    //     const cursosRealizados: any[] = [];
    //     solicitudes.forEach(solicitud => {
    //         solicitud.cursos.forEach((curso: any) => {
    //             cursosRealizados.push(curso);
    //         });
    //     });

    //     // Verificar si hay al menos un curso presente en alguna solicitud
    //     const existeCurso = solicitudBuscar.cursos.some((curso: any) => {
    //         return cursosRealizados.some(c => c._id === curso);
    //     });

    //     // Verificar si el último estado es 0 o 9 y si tiene el curso realizado
    //     const ultimoEstado = solicitudes.length > 0 ? solicitudes[solicitudes.length - 1].lastState : null;
    //     const estadoPermitido = (ultimoEstado.numberState === 8 || ultimoEstado.numberState === 9);
    //     const tieneCursoRealizado = existeCurso && estadoPermitido;

    //     return { existeCurso, cursosRealizados, estadoPermitido: tieneCursoRealizado };
    // }




    // async controlSolicitudModulosUsuario(_idUsuario: string, solicitudBuscar: any): Promise<{ existeCurso: boolean, cursosRealizados: any[] }> {
    //     const solicitudes: any[] = await this.getSolicitudesByUser(_idUsuario);

    //     // Obtener todos los cursos presentes en alguna solicitud
    //     const cursosRealizados: any[] = [];
    //     solicitudes.forEach(solicitud => {
    //         solicitud.cursos.forEach((curso: any) => {
    //             cursosRealizados.push(curso);
    //         });
    //     });

    //     // Verificar si hay al menos un curso presente en alguna solicitud
    //     const existeCurso = solicitudBuscar.cursos.some((curso: any) => {
    //         return cursosRealizados.some(c => c._id === curso);
    //     });

    //     /// AGREGAR QUE CUANDO ESTA RECHAZADA O ANULADA SOLO PUEDE HACER OTRA SOLICITUD
    //     return { existeCurso, cursosRealizados };
    // }

    /*procesarPagoMacroPagos(data: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.post(`${URL_BACKEND}/payments/macro-pagos/create_payment/`, data, { headers, responseType: 'text' })
                .subscribe(
                    (formHtml: string) => {
                        // Devuelve el HTML resuelto
                        resolve(formHtml);
                    },
                    (error) => {
                        console.error('Error processing payment', error);
                        reject(error);
                    }
                );
        });
    }*/

    createPagoMacroPagos(solicitud: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
                'Content-Type': 'application/json'
            });

            // Enviar la solicitud como un objeto JSON
            this.httpClient.post(`${URL_BACKEND}/payments/macro-pagos/create_payment`, { solicitud }, { responseType: 'text' })
                .subscribe(
                    (formHtml: string) => {
                        resolve(formHtml);
                    },
                    (error) => {
                        console.error('Error processing payment', error);
                        reject(error);
                    }
                );
        });
    }

}
