import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Profesor } from '../../../../interfaces/interface-bakend/cursos/profesor.interface';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';

@Component({
  selector: 'app-profesor-alta',
  templateUrl: './profesor-alta.component.html',
  styleUrls: ['./profesor-alta.component.scss']
})
export class ProfesorAltaComponent implements OnInit {

  forma: FormGroup;
  selectUsuarios: User[];
  flagValidForma: boolean;
  flagAlta: boolean;

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private cursosService: CursosService,
    private guiMsjService: GuiMsjService,
  ) {
    this.createForm();
    this.getUsuariosNoProfesores();
  }

  ngOnInit(): void {
  }

  createForm() {
    this.forma = new FormGroup({
        usuarios: new FormControl('',  [ Validators.required]),
    });
  }

  createListenersStateForm() {
    return this.forma.statusChanges.subscribe( async status => {
        if (status === 'VALID') {
            this.flagValidForma = true;
        } else {
            this.flagValidForma = false;

        }
    });
  }

  async setDataForma(): Promise<Profesor>{
    console.log(this.usuarios);

    const dataProfesor: Profesor = {

       usuario: this.forma.value.usuarios

    };

    return dataProfesor;
  }


  async getUsuariosNoProfesores(){
    this.selectUsuarios = await this.usuarioService.getAllUsers();
  }

  async ngSubmit(){

    await this.guiMsjService.msjFormSubmit('Guardando');
    this.cursosService.postProfesor(await this.setDataForma()).then( async (profesor: Profesor) => {
        // await this.uploadFile(this.selectedFile, leccion._id);
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.router.navigateByUrl('/profesores-configuracion');
        return;
    });
  }

  get usuarios() {
    return this.forma.get('usuarios').invalid && this.forma.get('usuarios').touched;
  }

}
