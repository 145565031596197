<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{ idioma | translate: "navbar.UL-INICIO" | async}}</a></li>
                <li>{{ idioma | translate: "terminos-servicio.TITULO" | async}}</li>
            </ul>
            <h2>{{ idioma | translate: "terminos-servicio.TITULO" | async}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="terms-of-service-area mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-9">
                <div class="terms-of-service-content">

                    <h3>{{ idioma | translate: "terminos-servicio.SUBTITULO" | async}}</h3>

                    <h3>{{ idioma | translate: "terminos-servicio.TERMINO-TITULO-1" | async}}</h3>
                    <p>{{ idioma | translate: "terminos-servicio.TERMINO-1" | async}}</p>

                    <h3>{{ idioma | translate: "terminos-servicio.TERMINO-TITULO-2" | async}}</h3>
                    <p>{{ idioma | translate: "terminos-servicio.TERMINO-2" | async}}</p>

                    <h3>{{ idioma | translate: "terminos-servicio.TERMINO-TITULO-3" | async}}</h3>
                    <p>{{ idioma | translate: "terminos-servicio.TERMINO-3" | async}}</p>

                    <h3>{{ idioma | translate: "terminos-servicio.TERMINO-TITULO-4" | async}}</h3>
                    <p>{{ idioma | translate: "terminos-servicio.TERMINO-4" | async}}</p>

                    <h3>{{ idioma | translate: "terminos-servicio.TERMINO-TITULO-5" | async}}</h3>
                    <p>{{ idioma | translate: "terminos-servicio.TERMINO-5" | async}}</p>

                    <br><br>
                </div>
            </div>
        </div>
    </div>
</div>