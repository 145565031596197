import { Pipe, PipeTransform, Inject } from '@angular/core';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../app.config/app.config-ofertas';

@Pipe({
  name: 'nombreExiste'
})
export class NombreExistePipe implements PipeTransform {

    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
    ){}

  transform(value: string): string {
      if (value !== 'undefined undefined' && value !== undefined) {
          return value;
      } else {
        return this.iAppOfertas.nombrePendienteCarga.detalle;
      }
  }

}
