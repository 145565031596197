<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>{{ idioma | translate:"footer.EMPRESA" | async}}</h3>
                    <p>{{ idioma | translate:"footer.DETALLE" | async}}</p>
                    <!--<p>{{ idioma | translate:"footer.FOOTER-DESCRIPCION" | async}}</p>-->
                    <ul class="footer-contact-info">
                        <li><i class='bx bxs-lock'></i><a (click)="openBlank('TC')" style="color: white; text-decoration:none;">{{ idioma | translate:"legales.LEGAL-CTF-TC" | async}} </a></li>
                        <li><i class='bx bxs-lock'></i><a (click)="openBlank('PDP')" style="color: white; text-decoration:none;">{{ idioma | translate:"legales.LEGAL-CTF-PDP" | async}}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>{{ idioma | translate:"footer.FOOTER-DIRECCION-TITULO" | async}}</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>{{ idioma | translate:"footer.CP" | async}}</li>
                        <p><i class='bx bx-info'></i>{{ idioma | translate:"footer.TELEFONO" | async}}</p>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <app-tecnodret></app-tecnodret>>
            </div>
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class='bx bx-copyright'></i>{{ idioma | translate:"footer.BY" | async}}<a target="_blank" href="https://cipbyte.io/"> CipByte</a></p>
                </div>
            </div>
        </div>

    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>