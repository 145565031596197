<section *ngIf="this.CFR">
    <button type="button" (click)="openBlank('CFR')" mat-button color="primary">{{ idioma | translate:"legales.LEGAL-CTF-1" | async}}</button>
    <!-- <button type="button" (click)="openBlank('CFR')" mat-button color="primary">{{ idioma | translate:"legales.LEGAL-CTF-1" | async}}</button> -->
</section>
<section *ngIf="this.CFA">
    <button type="button" (click)="openBlank('CFA')" mat-button color="primary">{{ idioma | translate:"legales.LEGAL-CTF-2" | async}}</button>
    <!-- <button type="button" (click)="openBlank('CFA')" mat-button color="primary">{{ idioma | translate:"legales.LEGAL-CTF-2" | async}}</button> -->
</section>
<section *ngIf="this.TC">
    <button type="button" (click)="openBlank('TC')" mat-button color="primary">{{ idioma | translate:"legales.LEGAL-CTF-TC" | async}}</button>
    <!-- <button type="button" (click)="openBlank('TC')" mat-button color="primary">{{ idioma | translate:"legales.LEGAL-CTF-TC" | async}}</button> -->
</section>
<section *ngIf="this.PDP">
    <button type="button" (click)="openBlank('PDP')" mat-button color="primary">{{ idioma | translate:"legales.LEGAL-CTF-PDP" | async}}</button>
    <!-- <button type="button" (click)="openBlank('PDP')" mat-button color="primary">{{ idioma | translate:"legales.LEGAL-CTF-PDP" | async}}</button> -->
</section>