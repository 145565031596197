import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Respuesta } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';

@Component({
  selector: 'app-ordenar-conceptos',
  templateUrl: './ordenar-conceptos.component.html',
  styleUrls: ['./ordenar-conceptos.component.scss']
})
export class OrdenarConceptosComponent implements OnInit {

  forma: FormGroup;
  random: [] = [];
  respuestas: Respuesta[] = [];
  @Output() conceptosOrdenados = new EventEmitter<string[]>();
  @Output() flagFormaValido = new EventEmitter<boolean>();

  constructor() {
    this.createForm();
    this.createListenersStateForm();
  }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.random, event.previousIndex, event.currentIndex);
  }

  createListenersStateForm() {
      return this.forma.statusChanges.subscribe( async status => {
        console.log(this.forma);
        this.random = this.randomArrayShuffle(this.forma.controls.conceptos.value);

        if (status === 'VALID' &&
            this.forma.value.conceptos.length > 0 &&
            !this.forma.value.conceptos.includes('') ){
            this.conceptosOrdenados.emit(this.forma.controls.conceptos.value);
            this.flagFormaValido.emit(true);
        } else {
            return;
        }

      });
  }

  createForm(){
      this.forma = new FormGroup({
        conceptos: new FormArray([], [ Validators.required ]),
      });
  }

  get conceptos(){
      return this.forma.get('conceptos') as FormArray;
  }

  agregarConcepto(){
      this.conceptos.push(new FormControl(''));
  }

  borrarConcepto(i: number){
      this.conceptos.removeAt(i);
  }

  randomArrayShuffle(array): [] { // no esta en uso
    // tslint:disable-next-line:one-variable-per-declaration
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

}
