import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { Perfil } from '../../../../interfaces/interface-bakend/seguridad/acl/perfil.interface';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { AlumnoTitulo, Disciplina, Titulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { Alumno } from 'src/app/interfaces/interface-bakend/cursos/alumno.interface';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2'
import { ModalModificarClaveUsersComponent } from '../modal-modificar-clave/modal-modificar-clave.component';
import { async } from '@angular/core/testing';
import { get } from 'http';


@Component({
  selector: 'app-usuario-modificacion',
  templateUrl: './usuario-modificacion.component.html',
  styleUrls: ['./usuario-modificacion.component.scss']
})
export class UsuarioModificacionComponent implements OnInit, AfterViewInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
  [
    'nombre', 'disciplina', 'otorgamiento', 'vencimiento', 'certifica', 'observaciones', '_id'
  ];
  dataSource: any;

  forma: FormGroup;
  formTitulos: FormGroup;
  usuario: User;
  selectPerfiles: Perfil[];
  flagLoaded = false;
  flagDisableButton = false;
  flagValidForma = false;
  estado = true;
  isChecked = true;
  emailChecked: boolean = false;
  titulos: Titulo[] = [];
  disciplinas: Disciplina[] = [];
  titulosDelAlumno: AlumnoTitulo[] = [];
  subscriptions: Subscription;

  data$: Observable<AlumnoTitulo[]>;
  setTitulo: any;
  editaTitulo: boolean = false;
  tituloSeleccionado: Titulo;


  constructor(
      private router: Router,
      private usuarioService: UsuarioService,
      private guiMsjService: GuiMsjService,
      private cursosService: CursosService,
      public dialog: MatDialog,
      private formBuilder: FormBuilder,
      public dialogRef: MatDialogRef<ModalModificarClaveUsersComponent>,
      @Inject(MAT_DIALOG_DATA) public data: object

    ){
      
    }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getEstadoVariables();
  }

  reset(){
    this.formTitulos.reset();
    this.editaTitulo = false;
  }

  async createForm() {
    this.forma = new FormGroup({
      nombrePersona: new FormControl({ value: this.usuario.nombrePersona || 'SIN REGISTRAR SU NOMBRE' ,  disabled: true }),
      apellidoPersona: new FormControl( { value: this.usuario.apellidoPersona  || 'SIN REGISTRAR SU APELLIDO', disabled: true }),
      nickname: new FormControl({ value: this.usuario.nickname  || 'SIN REGISTRAR SU NICKNAME', disabled: true}),
      email: new FormControl({ value: this.usuario.email || '', disabled: false }  ),
      perfil: new FormControl( (this.usuario.perfil ? this.usuario.perfil._id : null ) , [ Validators.required ] ),
      estado: new FormControl({ value: this.usuario.suspendido } ,  Validators.required ),
      email_verified: new FormControl({ value: this.usuario.email_verified } ,  Validators.required )
    });

      this.formTitulos = this.formBuilder.group({
        titulo: ['', Validators.required],
        fechaOtorgamiento: [new Date(), Validators.required],
        fechaVencimiento: [new Date(), Validators.required],
        certifica: [true],
        disciplina: ['', Validators.required], // Agregamos el validador aquí
        nivel: ['', Validators.required],
        observaciones: ['']
      });
    
  }

  async loadDataTable(){
    await this.cursosService.getTitulosAlumnosFindAllByAlumno(await (await this.cursosService.findAlumnoByUsuario(this.usuario._id))._id).then((titulos: AlumnoTitulo[])=>{
      this.dataSource = new MatTableDataSource(titulos);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
  }

  applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async loadDataPage() {
      await this.loadDataTable();
      this.flagLoaded = true;
      return;
  }

  async getUsuario(){
      this.isChecked = this.usuarioService.getUsuarioSeleccionado().suspendido;
      this.emailChecked = this.usuarioService.getUsuarioSeleccionado().email_verified;
      return this.usuario = this.usuarioService.getUsuarioSeleccionado();
  }

  async getEstadoVariables(){
    if (this.usuarioService.getUsuarioSeleccionado() === undefined) {
        this.router.navigateByUrl('/usuarios-listado');
        return;
    } else {
        await this.getUsuario();
        await this.getPerfiles();
        await this.createForm();
        await this.getDisciplinas();
        await this.getTitulos();
        await this.getTitulosAlumnos();
        await this.loadDataPage();
        
        this.flagLoaded = true;
    }
  }

  async setDataForma(): Promise<User>{
    this.usuario.email = this.forma.value.email;
    this.usuario.nickname = this.forma.value.nickname;
    this.usuario.perfil = this.forma.value.perfil;
    this.usuario.suspendido = this.forma.value.estado;
    this.usuario.email_verified = this.forma.value.email_verified;
    if (typeof this.usuario.delegacion === 'string') {
      this.usuario.delegacion = {
        _id: this.usuario.delegacion
      };
    }

    if (typeof this.usuario.disciplina === 'string') {
      this.usuario.disciplina = {
        _id: this.usuario.disciplina
      };
    }
    return this.usuario;
  }

  async getPerfiles() {
      this.selectPerfiles = await  this.usuarioService.getPerfiles();
  }

  async ngSubmit(){
    this.flagDisableButton = true;
    const flag = await this.usuarioService.updateOne(await this.setDataForma());
    if (flag) {
        this.flagLoaded = false;
        this.guiMsjService.msjFormSubmit('UsuarioModificadoOK');
        this.dialogRef.close();
    } else {
        this.guiMsjService.msjFormSubmit('UsuarioModificadoError');
    }
  }

  async seleccionarTitulo(titulo: string){
    if(titulo != null){
      await this.cursosService.tituloGetOneById(titulo).then((titulo: Titulo)=>{
        this.tituloSeleccionado = titulo;
        if(this.editaTitulo === false){
          const fechaVencimiento = new Date();
          if(titulo.mesesVigencia){
            fechaVencimiento.setMonth(fechaVencimiento.getMonth()+titulo.mesesVigencia)
            this.formTitulos.controls['fechaVencimiento'].setValue(fechaVencimiento);
          }else{
            this.formTitulos.controls['fechaVencimiento'].setValue(new Date());
          }
        }
      }) 
    }    
  }

  async editarTituloAlumno(data: AlumnoTitulo): Promise<void> {
    this.editaTitulo = true;
    this.setTitulo = data;
    this.formTitulos.setValue({
      titulo: data.titulo._id,
      disciplina: data.disciplina._id,
      fechaOtorgamiento: data.fechaOtorgamiento,
      fechaVencimiento: data.fechaVencimiento,
      nivel: data.nivel,
      observaciones: data.observaciones,
      certifica: data.certifica
    })
  }

  async borrarTitulo(id: string){
    Swal.fire({
      title: 'Confirma borrar el titulo al alumno?',
      showDenyButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `No borrar`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await this.guiMsjService.msjFormSubmit('Guardando');
        await this.cursosService.borarTituloAlumno(id).then(async ()=>{
          await this.loadDataTable();
          await this.guiMsjService.msjFormSubmit('DatosGuardados');
        }).catch((error)=>{
        console.warn(error)
     })} 
    })
  }

  async agregarTitulo(){
    await this.guiMsjService.msjFormSubmit('Guardando');
    const alumno: Alumno = await this.cursosService.findAlumnoByUsuario(this.usuario._id);

    console.log(this.formTitulos);

    if (this.formTitulos.valid) {

    const alumnoTitulo: AlumnoTitulo = {
      titulo: this.formTitulos.value.titulo,
      alumno: alumno._id,
      fechaOtorgamiento: this.formTitulos.value.fechaOtorgamiento,
      fechaVencimiento: this.formTitulos.value.fechaVencimiento,
      certifica: this.formTitulos.value.certifica? true : false,
      disciplina: this.formTitulos.value.disciplina,
      nivel: this.formTitulos.value.nivel,
      observaciones: this.formTitulos.value.observaciones
    };
   

    if(!this.editaTitulo){
      await this.cursosService.postTituloAlumno(alumnoTitulo).then(async ()=>{
        await this.loadDataTable();
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.formTitulos.reset();
      }).catch(error=>{
        console.log(error);
      })
    }else{
      await this.cursosService.updateTituloAlumno(this.setTitulo._id,alumnoTitulo).then(async ()=>{
        await this.loadDataTable();
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.formTitulos.reset();
        this.editaTitulo = false;
      }).catch(error=>{
        console.log(error);
      })
    }  

   } else {
    alert('Le falta cargar algúnn campo')
   }
  }

  async getTitulos(){
    await this.cursosService.getTitulos().then( (titulos: Titulo[]) => {
      this.titulos = titulos;
    });
  }

  async getDisciplinas(){
    await this.cursosService.getDisciplinas().then( (disciplinas: Disciplina[]) => {
      this.disciplinas = disciplinas;
    });
  }

  async getTitulosAlumnos(){
    await this.cursosService.getTitulosAlumnosFindAllByAlumno(await (await this.cursosService.findAlumnoByUsuario(this.usuario._id))._id).then((titulos: AlumnoTitulo[])=>{
      this.titulosDelAlumno = titulos;
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

 /* validaciones form */

  get fechaOtorgamientoNoValida(){
    return this.formTitulos.get('fechaOtorgamiento').invalid && this.formTitulos.get('fechaOtorgamiento').touched;
  }

  get fechaVencimientoNoValida(){
    return this.formTitulos.get('fechaVencimiento').invalid && this.formTitulos.get('fechaVencimiento').touched;
  }

  get nombrePersona() {
    return this.forma.get('nombrePersona').invalid && this.forma.get('nombrePersona').touched;
  }

}
