<app-loading *ngIf="!flagLoaded" class="m-5">
</app-loading>

<div class="profile-area pb-70 animate__animated animate__fadeIn">
    <div class="container">
        <div class="profile-courses-quizzes">
            <h3>Listado de Usuarios</h3>
            <hr>
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Column" autocomplete="off" #input>
            </mat-form-field>

            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Checkbox Column  -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox [checked]="selection.hasValue() &&  isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" (change)="masterToggle()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
                            <mat-checkbox (change)="onChangeToggled(row)" [checked]="selection.isSelected(row)">

                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{element.nombrePersona || 'SIN DATOS PERSONALES'}} {{element.apellidoPersona}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="estado">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.suspendido === false"><span class="badge bg-success">Activo</span></div>
                            <div *ngIf="element.suspendido === true"><span class="badge bg-danger">Suspendido</span></div>
                        </td>
                    </ng-container>


                    <!-- Column -->
                    <ng-container matColumnDef="identificacion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Identificación </th>
                        <td mat-cell *matCellDef="let element"> {{element.identificacion}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Creado </th>
                        <td mat-cell *matCellDef="let element"> {{element.createdAt | date:"dd/MM/yy h:mm a"}} </td>
                    </ng-container>


                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200, 300, 500]" aria-label="Select page of users"></mat-paginator>

                <div *ngIf="flagAsignando">
                    <br>
                    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
                </div>
            </div>
            <div mat-dialog-actions align="end">
                <button *ngIf="!this.disabled" [disabled]="!this.flagSelected" (click)="asignarExamen()" mat-raised-button color="accent">Asignar</button>
            </div>

            <hr>
            <div *ngIf="this.disabled" class="alert alert-success" role="alert">
                Usuarios asignados correctamente..!
            </div>
        </div>
    </div>
</div>