import { Component, OnInit } from '@angular/core';
import { Curso } from '../../../interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from '../../../services/cursos/cursos.service';
import { PageEvent } from '@angular/material/paginator/public-api';
import { Subscription } from 'rxjs';
import { Translators } from '../../../services/shared/translators.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfServiceComponentHome } from './terms-of-service-home/terms-of-service-home.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';
import { ConfiguracionApp } from 'src/app/interfaces/interface-bakend/app-web-config/app-web-config.interfaces';

const url = '//reservas.aereos.app/aereos.app.min.js';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  idioma: Subscription;
  tipoSolicitud = 0;
  cursos: boolean = false;

  constructor(
      private cursosService: CursosService,
      private translatorService: Translators,
      private router: Router,
      public dialog: MatDialog,
      private authService: AuthService,
      private configBeginService: ConfigBeginService,
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });
  }

  ngOnInit(): void {
    // this.validarTerminosHome();
    this.getConfiguracionGral();
  }

  async getConfiguracionGral() {
    const configApp: ConfiguracionApp = await this.configBeginService.getConfiguracionGral();
    this.tipoSolicitud = +configApp.tipoSolicitud.numero;
    this.cursos =  configApp.cursos;
   }

  async validarTerminosHome(){
    const validateTerms = await this.authService.getUserLogon();
    if (validateTerms.aceptaTerminos === false || validateTerms.aceptaTerminos == null){
      this.openDialog();
    }
    return;
  }

  openDialog() {
    const dialogRef = this.dialog.open(TermsOfServiceComponentHome,{
      disableClose: true
    });
  }

  refreshHome() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/']);
    });
  }

    // Cargar el buscador
  public loadScript() {
    let node = document.createElement('script');
    node.id = 'buscador';
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  public removeScript() {
    const c = document.getElementById('buscador');
    document.body.removeChild(c);
    alert(document.body.innerHTML);   // muestra todos NO BORRO
  }
}


