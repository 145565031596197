import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service';
import { Solicitud } from '../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { SolicitudesService } from '../../../../services/ofertas-laborales/solicitudes.service';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { PaisesService } from '../../../../services/shared/paises.service';
import { Actividad } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Translators } from '../../../../services/shared/translators.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-usuario-solicitudes',
  templateUrl: './usuario-solicitudes.component.html',
  styleUrls: ['./usuario-solicitudes.component.scss']
})
export class UsuariosSolicitudesComponent implements OnInit{

    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] =
    [
    //   'pais', 'actividad', '_nro', 'fecha', 'progreso', 'ver', 'descargar', 'indentificacion'
      'pais', 'actividad', '_nro', 'fecha', 'progreso',  'ver'
    ];
    dataSource: any;
    solicitudes: Solicitud [];
    /* Fin Data Table*/

    usuario: User;
    flagLoaded = false;

    idioma: Subscription;

    constructor(
        private solicitudesService: SolicitudesService,
        private actividadesService: ActividadesService,
        private paisesService: PaisesService,
        private authservice: AuthService,
        private router: Router,
        private translatorService: Translators
    ) {

        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
          });

    }

    ngOnInit(): void {
     this.loadDataPage();
    }

    async verSolicitud(event) {
        const solicitud: Solicitud = event.ver;
        await this.setActividad(solicitud.actividad);
        await this.setPaisDisponible(solicitud.actividad.paisDisponible._id);
        await this.solicitudesService.setSolicitudSeleccionada(solicitud);
        this.router.navigateByUrl('/solicitud');
    }
    // tslint:disable-next-line:variable-name
    async getSolicitudesByUser(_id: string) {
        this.loadDataTable(_id);
    }

    // tslint:disable-next-line:variable-name
    async loadDataTable(_id: string) {
        this.solicitudesService.getSolicitudesByUser(_id).then( ( solds: Solicitud[] ) => {
            this.formatDataTable(solds).then( ( data: Solicitud[] ) => {
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.flagLoaded = true;
            });
        });
    }

    formatDataTable(solicitud: Solicitud[]) {
        return new Promise(resolve => {
            const format: any[] = [];
            let elemento: {
                pais: string,
                actividad: string,
                _nro: string,
                fecha: Date,
                progreso: number,
                ver: Solicitud,
                descargar: Solicitud,
                indentificacion: Solicitud,
            };
            
            // Recorremos el array de solicitudes en orden inverso
            for (let i = solicitud.length - 1; i >= 0; i--) {
                elemento = {
                    pais: solicitud[i].pais.name_es,
                    actividad: solicitud[i].actividad.nombre,
                    _nro: solicitud[i]._nro,
                    fecha: solicitud[i].createdAt,
                    progreso: solicitud[i].lastState.numberState,
                    ver: solicitud[i],
                    descargar: solicitud[i],
                    indentificacion: solicitud[i],
                };
                format.push(elemento);
            }
            resolve(format);
        });
    }    

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    setActividad(actividad: Actividad) {
        return this.actividadesService.setActividadSeleccionada(actividad);
    }

    async setPaisDisponible(id: string) {
       await this.paisesService.getPaisDisponibleFinOne(id).then( (pais: PaisDisponible) => {
           return this.paisesService.setPaisSeleccionado(pais);
       });
    }

    async loadDataPage() {
        await this.getSolicitudesByUser((this.usuario = await this.authservice.getUserLogon())._id);
        return;
    }

}
