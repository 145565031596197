<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/usuarios-administracion">Configuración Usuario</a></li>
                <li><a routerLink="/documentos-usuarios-configuracion">Configurar Documentos Usuarios</a>
                </li>
                <li>Modificar Documento</li>
            </ul>
            <h2>Modificar Documento</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg"
             alt="image"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="form"
                          (ngSubmit)="update()">

                        <div class="form-group">
                            <mat-form-field class="example-full-width"
                                            appearance="fill"
                                            [style.width.%]=100>
                                <mat-label>Nombre</mat-label>
                                <input formControlName="nombre"
                                       matInput
                                       placeholder="Nombre"
                                       [class.is-invalid]="nombreNoValido"
                                       autocomplete="off">
                                <small *ngIf="nombreNoValido"
                                       class="text-danger">Ingrese un nombre</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field class="example-full-width"
                                            appearance="fill"
                                            [style.width.%]=100>
                                <mat-label>Descripción</mat-label>
                                <textarea formControlName="descripcion"
                                          tabindex="11"
                                          [class.is-invalid]="descripcionNoValida"
                                          matInput
                                          rows="7"
                                          placeholder="Descripción"></textarea>
                                <small *ngIf="descripcionNoValida"
                                       class="text-danger">Ingrese una descripción</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-label>Activar Documento</mat-label>
                            <br>
                            <br>
                            <mat-slide-toggle formControlName="activo">Activo</mat-slide-toggle>
                        </div>


                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <button type="button"
                                        routerLink="/documentos-usuarios-listado"
                                        class="btn btn-warning">Cancelar</button>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <button [disabled]="!form.valid"
                                        type="submit"
                                        disabled>Guardar</button>
                            </div>
                        </div>

                        <br>
                        <br>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>