import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Materia } from '../../../../interfaces/interface-bakend/cursos/materia.interface';
import { MateriasService } from '../../../../services/cursos/materias.service';


@Component({
  selector: 'app-materia-modificacion',
  templateUrl: './materia-modificacion.component.html',
  styleUrls: ['./materia-modificacion.component.scss']
})
export class MateriaModificacionComponent implements OnInit {

  form: FormGroup;
  materia: Materia;
  flagLoaded = false;

  constructor(private router: Router,
    private materiasService: MateriasService,
    private guiMsjService: GuiMsjService) {
  }

  ngOnInit(): void {
    this.getEstadoVariables();
    this.createForm();
  }

  get nombreNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  get descripcionNoValida() {
    return this.form.get('descripcion').invalid && this.form.get('descripcion').touched;
  }

  get fechaNoValida() {
    return this.form.get('fechaInicio').invalid && this.form.get('fechaInicio').touched;
  }

  get fechaFinNoValida() {
    return this.form.get('fechaFin').invalid && this.form.get('fechaFin').touched;
  }

  createForm() {
    this.form = new FormGroup({
      nombre: new FormControl(this.materia?.nombre, [Validators.required]),
      descripcion: new FormControl(this.materia?.description, [Validators.required]),
      fechaInicio: new FormControl(this.materia?.fechaInicio, [Validators.required]),
      fechaFin: new FormControl(this.materia?.fechaFin, [Validators.required]),
      imagen: new FormControl(''),
      enlace: new FormControl(this.materia?.enlace)
    });
  }

  getMateria() {
    return this.materia = this.materiasService.getMateriaSeleccionada();
  }

  async getEstadoVariables() {
    if (this.getMateria() === undefined) {
      this.router.navigateByUrl('/materias-listado');
      return;
    } else {
      await this.getMateria();
      this.flagLoaded = true;

    }
  }

  async put() {

    await this.guiMsjService.msjFormSubmit('Guardando');
    this.materiasService.put(this.materia._id, await this.setDataForma()).then(async (materia: Materia) => {
      await this.guiMsjService.msjFormSubmit('DatosGuardados');
      this.router.navigateByUrl('/materias-listado');

      return;
    });

  }

  async setDataForma(): Promise<Materia> {

    const data: Materia = {

      _id: this.materia._id,
      nombre: this.form.value.nombre,
      description: this.form.value.descripcion,
      fechaInicio: this.form.value.fechaInicio,
      fechaFin: this.form.value.fechaFin,
      imagen: this.form.value.imagen,
      enlace: this.form.value.enlace
    };

    return data;

  }

}
