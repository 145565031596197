<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/dashboard">Inicio</a></li>
        <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
      </ul>
      <h2>Listado de Gestos</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div *ngIf="flagLoaded" class="profile-area pb-70">
  <div class="container">

    <div class="profile-courses-quizzes">
      <h3>Listado de Gestos</h3>

      <a routerLink="/gesto-alta"><button mat-raised-button mat-button color="primary"
          style="margin-right: 5px;"><mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon> Nuevo
          Gesto</button></a>

      <a routerLink="/mesas-listado"><button mat-raised-button mat-button color="primary"><mat-icon aria-hidden="false"
            aria-label="Example home icon">list</mat-icon> Ir a Mesas</button></a>
      <br>
      <hr>

      <div *ngIf="flagLoaded">

        <mat-form-field>
          <mat-label>Filtrar</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Primer gesto..." autocomplete="off">
        </mat-form-field>

        <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

            <!-- Column -->
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font" class="font"> Nombre </th>
              <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="disciplina">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Disciplina </th>
              <td mat-cell *matCellDef="let element"> {{element.disciplina.nombre}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="valorAprobacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Puntaje aprobación </th>
              <td mat-cell *matCellDef="let element" class="td-nombre" mat-column="nombre">{{element.valorAprobacion}}
              </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="mesesVigenciaAprobacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Meses vigencia </th>
              <td mat-cell *matCellDef="let element"> {{element.mesesVigenciaAprobacion}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="nivel">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Nivel </th>
              <td mat-cell *matCellDef="let element"> {{element.nivel}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="activo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Estado </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.activo; else elseTemplate">
                  <mat-chip-listbox aria-label="Fish selection">
                    <mat-chip-option>Activo</mat-chip-option>
                  </mat-chip-listbox>
                </ng-container>
                <ng-template #elseTemplate>
                  <mat-chip-listbox aria-label="Fish selection">
                    <mat-chip-option color="warn">Inactivo</mat-chip-option>
                  </mat-chip-listbox>
                </ng-template>
              </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="font"> Acciones </th>
              <td mat-cell *matCellDef="let element">
                <button (click)="editarGesto(element._id)" type="button" style="margin-right: 5%;" title="Editar mesa"
                  class="btn btn-warning"><i class="fas fa-edit"></i></button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>