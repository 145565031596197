import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { PerfilesService } from '../../../../services/perfiles/perfiles.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { AlumnoTitulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { GestosService } from 'src/app/services/cursos/gestos.service';
import { Alumno } from 'src/app/interfaces/interface-bakend/cursos/alumno.interface';
import { BoletinesService } from 'src/app/services/cursos/boletines.service';
import { AlumnoBoletin } from 'src/app/interfaces/interface-bakend/cursos/alumno-boletin.interface';
import { MateriaBoletinDetalle } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin-detalle.interface';
import { AlumnoBoletinDetalleModalComponent } from '../alumno-boletin-detalle-modal/alumno-boletin-detalle-modal.component';

@Component({
  selector: 'app-alumno-boletines-modal',
  templateUrl: './alumno-boletines-modal.component.html',
  styleUrls: ['./alumno-boletines-modal.component.scss']
})
export class AlumnoBoletinesModalComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['nombre', '_id'];
  dataSource: any;
  usuarios: User[];

  usuarioLoad = false;
  flagLoaded = false;
  loading = true;
  alumno: Alumno;
  isButtonDisabled: boolean = false;
  boletines: AlumnoBoletin[];

  constructor(
    private usuariosService: UsuarioService,
    private cursosService: CursosService,
    private router: Router,
    private perfilesService: PerfilesService,
    public dialog: MatDialog,
    private guiMsjService: GuiMsjService,
    private boletinesService: BoletinesService,
    private dialogRef: MatDialogRef<AlumnoBoletinesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string }
  ) { }

  ngOnInit(): void {
    this.perfilesService.allowedByUserLogon(this.router.url);
    //this.getBoletinesByAlumno();
  }

  async ngAfterViewInit() {
    await this.loadDataPage(await this.getAlumno());
  }

  // Filtro el boletin para mostrar las materias en el modal
  filtrarBoletinesModal(id: string): AlumnoBoletin[] {
    return this.boletines.filter(boletin => boletin._id === id);
  }

  // Recibo ID de AlumnoBoletin
  async openDialogVerDetalleBoletin(id: string) {
    const filteredBoletines: AlumnoBoletin[] = this.filtrarBoletinesModal(id);
    const materias: MateriaBoletinDetalle[] = filteredBoletines[0].materias;

    // Seteo en el storage el arreglo de materias del boletin
    this.boletinesService.setMateriasBoletin(materias);
    this.isButtonDisabled = true;
    const dialogRef = this.dialog.open(AlumnoBoletinDetalleModalComponent, {
      width: '1200px',
      height: '600px',
      data: { materias }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isButtonDisabled = false;
    });
  }

  async updateBoletin(boletin: string) {
    this.dialogRef.close(); 
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    await this.boletinesService.getAlumnoBoletinFindOne(boletin);
    this.router.navigateByUrl('/boletin-modificacion');
  }

  async verAlumnoBoletines(id: string){
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();

    await this.usuariosService.getFindOne(id);
    const dialogRef = this.dialog.open(AlumnoBoletinesModalComponent, {
      width: '1000px',
      height: '800px',
    });

    Swal.close();

    dialogRef.afterClosed().subscribe(resp => {
    });
  }

  async loadDataTable(alumno: Alumno) {
    this.loading = true;  // Iniciar el loading
    await this.boletinesService.getBoletinesAlumnosFindAllByAlumno(alumno._id)
      .then((boletines: AlumnoBoletin[]) => {
        this.dataSource = new MatTableDataSource(boletines);
        this.boletines = boletines;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.flagLoaded = true;
        this.loading = false;  // Detener el loading
      })
      .catch(async (error) => {
        //await this.guiMsjService.msjFormSubmit('errorListadoUsuarios');
        this.flagLoaded = true;
        this.loading = false;  // Detener el loading
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async getAlumno(): Promise<Alumno>{
    this.alumno = await this.cursosService.crearAlumno(this.data.id);
    return this.alumno;
  }

  async loadDataPage(alumno: Alumno) {
    await this.loadDataTable(alumno);
    return;
  }

  async altaBoletin() {
    this.dialogRef.close(); 
    const alumno: Alumno = await this.cursosService.crearAlumno(this.data.id);
    this.cursosService.setAlumnoSeleccionado(alumno);
    this.router.navigate(['/boletin-alta']);
  }

  async getBoletinesByAlumno(id: string){
    const boletines = await this.boletinesService.getBoletinesAlumnosFindAllByAlumno(id);
  }
}