
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/home">{{ idioma | translate: "navbar.UL-INICIO" | async}}</a></li>
                <li>{{ idioma | translate: "navbar.UL-OFERTA-LABORAL" | async}}</li>
            </ul>
            <h2>{{ idioma | translate: "navbar.LI-VER-OFERTAS" | async}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<br>
<app-ofertas-listar  *ngIf="this.tipoSolicitud == 1"[flagViewTitle]="false" [highValue]="3"></app-ofertas-listar>
<app-ofertas-listar-tarjetas *ngIf="this.tipoSolicitud == 2"  [flagViewTitle]="false" [highValue]="3"></app-ofertas-listar-tarjetas>
<app-boton-tarjeta  *ngIf="this.tipoSolicitud == 3"></app-boton-tarjeta>