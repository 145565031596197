import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cursos-configuracion',
  templateUrl: './cursos-configuracion.component.html',
  styleUrls: ['./cursos-configuracion.component.scss']
})
export class CursosConfiguracionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
