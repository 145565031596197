<div class="andorra-apply-content ptb-70 animate__animated animate__fadeIn">
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="container">
        <app-loading *ngIf="flagProcesando" class="m-5">
        </app-loading>
        <div class="row mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="forma">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">

                                <mat-form-field appearance="fill" style="width: 100%;">
                                    <mat-label>Seleccionar Materia de Boletin</mat-label>
                                    <mat-select formControlName="materiaBoletin">
                                        <mat-option *ngFor="let materias of materiasBoletin" [value]="materias._id">
                                            {{ materias.nombre }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div class="form-group">
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Título del examen</mat-label>
                                        <input formControlName="titulo" matInput placeholder="Ingrese título" [class.is-invalid]="tituloNoValido" autocomplete="off">
                                        <small *ngIf="tituloNoValido" class="text-danger">Ingrese un título</small>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Descripción</mat-label>
                                        <textarea formControlName="descripcion" rows="7" cols="40" [class.is-invalid]="descripcionNoValida" matInput placeholder="Ingrese una descripción"></textarea>
                                        <small *ngIf="descripcionNoValida" class="text-danger">Ingrese una descripción - Máximo: 250 caracteres.</small>
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="col-lg-4 col-md-6">

                                <div class="form-group">
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Porcentaje de aprobación (min. 60%)</mat-label>
                                        <input [disabled]="condition" type="number" formControlName="porcentaje" matInput placeholder="Ingrese porcentaje (Min. %60)" autocomplete="off">
                                        <span matSuffix>%</span>
                                        <small *ngIf="porcentajeNoValido" class="text-danger">Ingrese un porcentaje</small>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Cantidad de intentos</mat-label>
                                        <input [disabled]="condition" type="number" formControlName="intentos" matInput placeholder="Ingrese cantidad de intentos" autocomplete="off">
                                        <small *ngIf="intentosNoValido" class="text-danger">Ingrese cantidad de intentos</small>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Cantidad de preguntas a mostrar ( 0 = TODAS)</mat-label>
                                        <input [disabled]="condition" type="number" formControlName="cantidadPreguntasAmostrar" matInput placeholder="Ingrese cantidad de preguntas" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="row">

                                    <mat-label></mat-label>
                                    <div class="form-group">
                                        <mat-slide-toggle (click)="onClick(!this.estado)" [(ngModel)]="this.estado" formControlName="mostrarRespuestas">Mostrar respuestas al finalizar</mat-slide-toggle>
                                    </div>

                                    <div class="form-group">
                                        <mat-slide-toggle [disabled]="!this.estado" [(ngModel)]="this.mostrarCorrectas" formControlName="mostrarRespuestasCorrectas">Mostrar respuestas correctas</mat-slide-toggle>
                                    </div>

                                    <div class="form-group">
                                        <mat-slide-toggle formControlName="tieneLimiteTiempo">Tiene límite de tiempo</mat-slide-toggle>
                                    </div>


                                    <div *ngIf="this.forma.value.tieneLimiteTiempo" class="form-group animate__animated animate__fadeIn">
                                        <mat-form-field appearance="fill" [style.width.%]=100>
                                            <mat-label>Tiempo límite</mat-label>
                                            <input [disabled]="condition" type="time" formControlName="limiteTiempo" matInput placeholder="Ingrese tiempo límite" autocomplete="off">
                                        </mat-form-field>
                                        <small>Horas : Minutos</small>
                                    </div>

                                </div>

                                <mat-divider></mat-divider>

                                <br>
                                <div class="form-group">
                                    <mat-slide-toggle formControlName="activo">Activar examen</mat-slide-toggle>
                                </div>

                            </div>
                        </div>

                        <mat-divider></mat-divider>
                        <br>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">

                                <mat-form-field>
                                    <input matInput formControlName="fechaPublicacion" [ngxMatDatetimePicker]="picker" placeholder="Fecha Publicación" [formControl]="dateControl" [min]="minDate1" [max]="maxDate" [disabled]="disabled1">
                                    <small *ngIf="fechaPublicacionNoValida" class="text-danger">Ingrese una fecha de publicación</small>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color1" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute"
                                        [hideTime]="hideTime">
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                                <br>
                                <mat-form-field>
                                    <input matInput formControlName="fechaVencimiento" [ngxMatDatetimePicker]="picker1" placeholder="Fecha Vencimiento" [formControl]="dateControl" [min]="minDate1" [max]="maxDate" [disabled]="disabled1">
                                    <small *ngIf="fechaVencimientoNoValida" class="text-danger">Ingrese una fecha de finalización</small>
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #picker1 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color1" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute"
                                        [hideTime]="hideTime">
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>


                                <!--<mat-form-field color="accent" appearance="fill" [style.width.%]=100>
                                    <mat-label>Publicación</mat-label>
                                    <input [disabled]="condition" formControlName="fechaPublicacion" matInput [matDatepicker]="picker2" [min]="minDate" [class.is-invalid]="fechaPublicacionNoValida" autocomplete="off">
                                    <small *ngIf="fechaPublicacionNoValida" class="text-danger">Ingrese una fecha de publicación</small>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2 color="primary"></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field color="accent" appearance="fill" [style.width.%]=100>
                                    <mat-label>Vencimiento</mat-label>
                                    <input [disabled]="condition" formControlName="fechaVencimiento" matInput [matDatepicker]="picker3" [min]="minDate" [class.is-invalid]="fechaVencimientoNoValida" autocomplete="off" (ngModelChange)="compararFechas($event)">
                                    <small *ngIf="fechaVencimientoNoValida" class="text-danger">Ingrese una fecha de finalización</small>
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3 color="primary"></mat-datepicker>
                                </mat-form-field>-->
                            </div>
                            <!--<div class="col-lg-4 col-md-6">

                                <mat-form-field appearance="fill" [style.width.%]=100>
                                    <mat-label>Hora P.</mat-label>
                                    <input [disabled]="condition" type="time" formControlName="horaPublicacion" matInput placeholder="Ingrese hora publicación" autocomplete="off">
                                </mat-form-field>

                                <mat-form-field appearance="fill" [style.width.%]=100>
                                    <mat-label>Hora V.</mat-label>
                                    <input [disabled]="condition" type="time" formControlName="horaVencimiento" matInput placeholder="Ingrese hora vencimiento" autocomplete="off">
                                </mat-form-field>


                            </div>-->

                            <div class="col-lg-6 col-md-6">
                                <mat-label>Imagen de portada (300px X 300px)</mat-label>
                                <input [disabled]="condition" class="file-input" type="file" accept="image/*" name="file" #fileInput (change)="onChangeFile(fileInput.files[0], $event)" />
                                <!-- DA EL NOMBRE DEL FILE -->
                                <!-- <span class="file-name">
                                     {{ fileName }}
                                </span> -->
                                <br>
                                <mat-divider></mat-divider>
                                <br>
                                <div class="material-ripple-container mat-elevation-z4" matRipple [matRippleCentered]="centered" [matRippleDisabled]="disabled" [matRippleUnbounded]="unbounded" [matRippleRadius]="radius" [matRippleColor]="color">
                                    <img [src]="imageUrl" />
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <mat-divider></mat-divider>
                            <br>
                            <div class="row">
                                <!-- SI TIENE RECUPERATORIO -->

                                <div class="form-group">
                                    <mat-slide-toggle formControlName="recuperatorio">Tiene recuperatorio</mat-slide-toggle>
                                </div>

                                <mat-card *ngIf="this.forma.value.recuperatorio" class="form-group animate__animated animate__fadeIn">
                                    <mat-card-title>Recuperatorio</mat-card-title>
                                    <mat-card-subtitle>Composición</mat-card-subtitle>

                                    <div class="row">
                                        <div class="col-lg-4 col-md-6">
                                            <div class="form-group">
                                                <mat-form-field appearance="fill" [style.width.%]=100>
                                                    <mat-label>Porcentaje de aprobación</mat-label>
                                                    <input [disabled]="condition" type="number" formControlName="porcentajeR" matInput placeholder="Ingrese porcentaje (Min. %60)" autocomplete="off">
                                                    <span matSuffix>%</span>
                                                    <mat-icon matSuffix matTooltip="Porcentaje de respuestas correctas para poder aprobar." aria-label="Button that shows a red tooltip">help</mat-icon>
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group">
                                                <mat-form-field appearance="fill" [style.width.%]=100>
                                                    <mat-label>Nota mínima de acceso</mat-label>
                                                    <input [disabled]="condition" type="number" formControlName="notaMinimaR" matInput placeholder="Ingrese cantidad de intentos" autocomplete="off">
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-6">

                                            <div class="form-group">
                                                <mat-checkbox formControlName="preguntasAleatoriasR">Usar preguntas aleatorias</mat-checkbox>
                                            </div>

                                            <div class="form-group">
                                                <mat-checkbox formControlName="repetirPreguntasR">Repetir preguntas del test reprobado</mat-checkbox>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-md-6">

                                            <div class="form-group">
                                                <mat-checkbox formControlName="preguntasDiferentesR">Usar preguntas diferentes a las del test</mat-checkbox>
                                                <br>
                                                <mat-label>Las preguntas obligatorias se repetirán de todos modos. En caso de no haber suficientes preguntas diferentes, algunas serán repetidas.</mat-label>
                                            </div>
                                        </div>

                                    </div>

                                    <mat-divider></mat-divider>
                                    <br>
                                    <mat-label>Defina la cantidad de recuperatorios</mat-label>
                                    <!-- <mat-card-actions> -->
                                    <div class="row">
                                        <mat-form-field appearance="fill" [style.width.%]=30>
                                            <mat-label>Cantidad de recuperatorios (MAX. 2)</mat-label>
                                            <input [disabled]="condition" type="number" formControlName="cantidadRecuperable" matInput placeholder="Ingrese cantidad de recuperatorios" autocomplete="off">
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6">
                                            <!-- recuperatorio 1 -->
                                            <div *ngIf="this.forma.value.cantidadRecuperable  >= 1" class="animate__animated animate__fadeIn">
                                                <mat-card>
                                                    <mat-card-subtitle>Recuperatorio - R1</mat-card-subtitle>
                                                    <mat-card-content>

                                                        <div class="form-group">
                                                            
                                                            <mat-form-field>
                                                                <input matInput formControlName="fechaPublicaR1" [ngxMatDatetimePicker]="publicacionRecuperatorio1" placeholder="Fecha Publicación R1" [formControl]="dateControl" [min]="minDate1" [max]="maxDate" [disabled]="disabled1">
                                                                <small *ngIf="fechaPublicaR1Novalida" class="text-danger">Fecha de Publicación - R1</small>
                                                                <mat-datepicker-toggle matSuffix [for]="publicacionRecuperatorio1"></mat-datepicker-toggle>
                                                                <ngx-mat-datetime-picker #publicacionRecuperatorio1 [showSpinners]="showSpinners" [class.is-invalid]="fechaPublicaR1Novalida" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color1" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute"
                                                                    [hideTime]="hideTime">
                                                                </ngx-mat-datetime-picker>
                                                            </mat-form-field>

                                                        </div>

                                                        <div class="form-group">

                                                            <mat-form-field>
                                                                <input matInput formControlName="fechaVenceR1" [ngxMatDatetimePicker]="vencimientoRecuperatorio1" placeholder="Fecha Vencimiento R1" [formControl]="dateControl" [min]="minDate1" [max]="maxDate" [disabled]="disabled1">
                                                                <small *ngIf="fechaVenceR1NoValida" class="text-danger">Fecha de Vencimiento - R1</small>
                                                                <mat-datepicker-toggle matSuffix [for]="vencimientoRecuperatorio1"></mat-datepicker-toggle>
                                                                <ngx-mat-datetime-picker #vencimientoRecuperatorio1 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [class.is-invalid]="ffechaVenceR1NoValida" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color1" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute"
                                                                    [hideTime]="hideTime">
                                                                </ngx-mat-datetime-picker>
                                                            </mat-form-field>

                                                        </div>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <!-- recuperatorio 2 -->
                                            <div *ngIf="this.forma.value.cantidadRecuperable  >= 2" class="animate__animated animate__fadeIn">
                                                <mat-card-subtitle>Recuperatorio - R2</mat-card-subtitle>
                                                <mat-card>
                                                    <mat-card-content>
                                                        <div class="form-group">

                                                            <mat-form-field>
                                                                <input matInput formControlName="fechaPublicaR2" [ngxMatDatetimePicker]="publicacionRecuperatorio2" placeholder="Fecha Publicación R2" [formControl]="dateControl" [min]="minDate1" [max]="maxDate" [disabled]="disabled1">
                                                                <small *ngIf="fechaPublicaR2Novalida" class="text-danger">Fecha de Publicación - R2</small>
                                                                <mat-datepicker-toggle matSuffix [for]="publicacionRecuperatorio2"></mat-datepicker-toggle>
                                                                <ngx-mat-datetime-picker #publicacionRecuperatorio2 [showSpinners]="showSpinners" [class.is-invalid]="fechaPublicaR2Novalida" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color1" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute"
                                                                    [hideTime]="hideTime">
                                                                </ngx-mat-datetime-picker>
                                                            </mat-form-field>

                                                        </div>

                                                        <div class="form-group">

                                                            <mat-form-field>
                                                                <input matInput formControlName="fechaVenceR2" [ngxMatDatetimePicker]="vencimientoRecuperatorio2" placeholder="Fecha Vencimiento R2" [formControl]="dateControl" [min]="minDate1" [max]="maxDate" [disabled]="disabled1">
                                                                <small *ngIf="fechaVenceR2NoValida" class="text-danger">Fecha de Vencimiento - R2</small>
                                                                <mat-datepicker-toggle matSuffix [for]="vencimientoRecuperatorio2"></mat-datepicker-toggle>
                                                                <ngx-mat-datetime-picker #vencimientoRecuperatorio2 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [class.is-invalid]="fechaVenceR2NoValida" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color1" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute"
                                                                    [hideTime]="hideTime">
                                                                </ngx-mat-datetime-picker>
                                                            </mat-form-field>

                                                        </div>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- SI TIENE RECUPERATORIO -->
                                    <!-- </mat-card-actions> -->
                                </mat-card>

                                <!-- FIN SI TIENE RECUPERATORIO -->

                            </div>

                            <p *ngIf="this.flagContinuarPreguntas">
                                Configuración gral. guardadas correctamente. Puede continuar con la carga de preguntas.
                            </p>

                            <mat-divider></mat-divider>

                            <mat-progress-bar *ngIf="flagProcesando" mode="indeterminate"></mat-progress-bar>

                            <section>
                                <div class="material-button-row">
                                    <button mat-raised-button color="warn" [disabled]="flagProcesando" routerLink="/examenes-listado">Salir</button>

                                    <button mat-raised-button color="primary" [disabled]="!forma.valid" (click)="guardarConfigGralExamen()" type="submit">Guardar</button>
                                </div>
                            </section>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>