<mat-vertical-stepper *ngIf="vertical" #stepper>
    <mat-step [editable]="false">
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-PAGO" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-PAGO-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step1Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-DATOS-PERSONALES" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-DATOS-PERSONALES-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step2Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS_ENVIADOS_PARCIAL" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS_ENVIADOS_PARCIAL_DETALLE" | async }} </p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step3Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS_ENVIADOS" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS_ENVIADOS_DETALLE" | async }} </p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step4Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-EN-REVISION" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-EN-REVISION-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step5Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-PRE-APROBADO" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-PRE-APROBADO-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step6Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-PAGO-2" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-PAGO-2-DETALLE" | async }}</p>
    </mat-step>
    <mat-step color="accent" [editable]="false" [completed]='step7Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-ESPERA-COMPROBACION-PAGO" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-ESPERA-COMPROBACION-PAGO" | async }}</p>
    </mat-step>
    <mat-step color="accent" [editable]="false" [completed]='step8Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-APROBADO" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-APROBADO-DETALLE" | async }}</p>
    </mat-step>

    <!-- PONER EL step DE RECHAZADO 7-->
    <mat-step *ngIf="stepperSet === 8" [errorMessage]="true" [completed]="true">
        <ng-template color="warn" matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-RECHAZADA" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-RECHAZADA-DETALLE" | async }}</p>
    </mat-step>

    <!-- PONER EL step DE ANULADA 9-->
    <mat-step *ngIf="stepperSet === 9" [errorMessage]="true" [completed]="true">
        <ng-template color="warn" matStepLabel>Solicitud Anulada</ng-template>
        <p>Su Solicitud fue anulada</p>
    </mat-step>

</mat-vertical-stepper>

<mat-horizontal-stepper *ngIf="!vertical" #stepper>
    <mat-step [editable]="false">
        <!-- <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-PAGO" | async }}</ng-template> -->
        <p>{{ idioma | translate: "solicitudes.STEPPER-PAGO-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step1Completed'>
        <!-- <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-DATOS-PERSONALES" | async }}</ng-template> -->
        <p>{{ idioma | translate: "solicitudes.STEPPER-DATOS-PERSONALES-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step2Completed'>
        <!-- <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS_ENVIADOS_PARCIAL" | async }}</ng-template> -->
        <p>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS_ENVIADOS_PARCIAL_DETALLE" | async }} </p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step3Completed'>
        <!-- <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS_ENVIADOS" | async }}</ng-template> -->
        <p>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS_ENVIADOS_DETALLE" | async }} </p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step4Completed'>
        <!-- <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-EN-REVISION" | async }}</ng-template> -->
        <p>{{ idioma | translate: "solicitudes.STEPPER-EN-REVISION-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step5Completed'>
        <!-- <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-PRE-APROBADO" | async }}</ng-template> -->
        <p>{{ idioma | translate: "solicitudes.STEPPER-PRE-APROBADO-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step6Completed'>
        <!-- <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-PAGO-2" | async }}</ng-template> -->
        <p>{{ idioma | translate: "solicitudes.STEPPER-PAGO-2-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step7Completed'>
        <!-- <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-ESPERA-COMPROBACION-PAGO" | async }}</ng-template> -->
        <p>{{ idioma | translate: "solicitudes.STEPPER-ESPERA-COMPROBACION-PAGO" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step8Completed'>
        <!-- <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-APROBADO" | async }}</ng-template> -->
        <p>{{ idioma | translate: "solicitudes.STEPPER-APROBADO-DETALLE" | async }}</p>
    </mat-step>

    <!-- PONER EL step DE RECHAZADO 8-->
    <mat-step *ngIf="stepperSet === 8" [errorMessage]="true" [completed]="true">
        <ng-template color="warn" matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-RECHAZADA" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-RECHAZADA-DETALLE" | async }}</p>
    </mat-step>

    <!-- PONER EL step DE ANULADA 9-->
    <mat-step *ngIf="stepperSet === 9" [errorMessage]="true" [completed]="true">
        <ng-template color="warn" matStepLabel>Solicitud Anulada</ng-template>
        <p>Su Solicitud fue anulada</p>
    </mat-step>

</mat-horizontal-stepper>

<!-- <mat-step [editable]="false">
        <ng-templatematStepLabel>Pago</ng-templatematStepLabel>
        <p>realiza el pago administrativo...</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step1Completed'>
        <ng-template matStepLabel>Datos personales</ng-template>
        <p>carga tus datos personales...</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step2Completed'>
        <ng-template matStepLabel>Documentos</ng-template>
        <p>carga tú documentación... </p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step3Completed'>
        <ng-template matStepLabel>Enrevisión</ng-template>
        <p>tú documentación esta en revisión.. </p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step4Completed'>
        <ng-template matStepLabel>Pre-Aprobado</ng-template>
        <p>Felicitaciones!! - en breve recibiras una solicitud de pago...</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step5Completed'>
        <ng-template matStepLabel>Pago</ng-template>
        <p>realiza el pago para continuar con el trámite...</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step6Completed'>
        <ng-template matStepLabel>Pasajes</ng-template>
        <p>carga tus pasajes...</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step7Completed'>
        <ng-template matStepLabel>Libreta sanitaria</ng-template>
        <p>carga tú libreta sanitaria...</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step8Completed'>
        <ng-template matStepLabel>Pre-Contrato</ng-template>
        <p>en breve te llegara tu pre-Contrato, por email...</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step9Completed'>
        <ng-template matStepLabel>Aprobado</ng-template>
        <p>Felicitaciones!, tu solicitud fue aprobada...</p>
    </mat-step>

    <mat-step *ngIf="stepperSet === 9" [errorMessage]="true" [completed]="true">
        <ng-template color="warn" matStepLabel>Rechazada</ng-template>
        <p>Tú solicitud fue rechada...</p>
    </mat-step> -->









<!-- <mat-horizontal-stepper>
    <mat-step label="Fill out your name">
        <mat-form-field>
            <mat-label>First name</mat-label>
            <input matInput>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Last name</mat-label>
            <input matInput>
        </mat-form-field>
        <div>
            <button mat-button matStepperNext>Next</button>
        </div>
    </mat-step>

    <mat-step label="Fill out your phone number">
        <mat-form-field>
            <mat-label>Phone number</mat-label>
            <input matInput>
        </mat-form-field>
        <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
        </div>
    </mat-step>

    <mat-step label="Fill out your address">
        <mat-form-field>
            <mat-label>Address</mat-label>
            <input matInput>
        </mat-form-field>
        <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
        </div>
    </mat-step>

    <mat-step label="Confirm your information">
        Everything seems correct.
        <div>
            <button mat-button matDialogClose>Done</button>
        </div>
    </mat-step>
</mat-horizontal-stepper>
-->