import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Examen } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-examenes-listado',
  templateUrl: './examenes-listado.component.html',
  styleUrls: ['./examenes-listado.component.scss']
})
export class ExamenesListadoComponent implements OnInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
  [
    'nombre', 'preguntas', 'aprobacion', 'vigencia', 'estado', '_id'
  ];
  dataSource: any;
  examenes: Examen [];
  estadoExamen: boolean;
  /* Fin Data Table*/

  flagLoaded = false;
  activo = false;

  constructor(
      private examenesService: ExamenesService,
      private router: Router,
      private guiMsjService: GuiMsjService
  ) { }

  ngOnInit(): void {
    this.loadDataTable();
    this.estadoExamen = false;
  }

  async loadDataTable(){
    await this.examenesService.getExamenes().then( (examenes: Examen[]) => {
        this.formatDataTable(examenes).then( ( data: any[] ) => {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.flagLoaded = true;
        });
    });
  }

  formatDataTable( examenes: Examen[]) {
    return new Promise( resolve => {
      const format: any[] = [];
      let elemento: {
        nombre: string,
        preguntas: number,
        aprobacion: number,
        publicacion: Date,
        vencimiento: Date,
        estado: boolean,
        _id: string,
      };
        // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < examenes.length; i++) {

        elemento = {
            nombre: examenes[i].titulo,
            preguntas: examenes[i].preguntas.length,
            aprobacion: examenes[i].porcentajeAprobacionMinimo,
            publicacion: examenes[i].fechaPublicacion,
            vencimiento: examenes[i].fechaVencimiento,
            estado: examenes[i].activo,
            _id: examenes[i]._id,
      };
        format.push(elemento);
      }
      resolve(format);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async verExamen(examen) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const examenIntroduccion = await this.examenesService.getExamenFindOne(examen);
    this.examenesService.setExamenSeleccionado(examenIntroduccion);
    this.router.navigateByUrl('/examen-introduccion');
  }

  async modificarExamen(examen) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const examenIntroduccion = await this.examenesService.getExamenFindOne(examen);
    this.examenesService.setExamenSeleccionado(examenIntroduccion);
    this.router.navigateByUrl('/evaluacion-modificar');
  }

  onClick(estado: boolean, id: string){
    this.examenesService.setEstadoExamen(estado, id);
  }

}
