<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li>Alta de Profesor</li>
            </ul>
            <h2>Alta de Profesor</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-2 col-md-12"></div>
            <div class="col-lg-8 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="forma" (ngSubmit)="ngSubmit()">

                        <div class="form-group">
                            <mat-form-field appearance="fill" [style.width.%]=100>
                                <mat-label>Usuarios</mat-label>
                                <mat-select formControlName="usuarios" [class.is-invalid]="usuarios">
                                    <mat-option *ngFor="let u of selectUsuarios" value="{{ u._id }}">{{ u.apellidoPersona}}, {{u.nombrePersona}} / {{u.email}}</mat-option>
                                </mat-select>
                                <small *ngIf="usuarios" class="text-danger">Seleccione un Usuario</small>
                            </mat-form-field>
                        </div>

                        <button [disabled]="!forma.valid" type="submit" class="btn btn-warning">Guardar</button>

                        <a routerLink="/profesores-listado"> <button type="button">Cancelar</button></a>
                        <br>
                        <br>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>