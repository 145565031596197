<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading>
<br>
<mat-expansion-panel>
    <mat-expansion-panel-header class="thirdColor">
        <mat-panel-title>
            Esta opción permite modificar las actividades de una solicitud
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <div class="profile-area pb-70">
        <div class="container">
            <div class="profile-courses-quizzes">
                <hr>
                <h3>Actividades del alumno</h3>
                <p>Si desea modificar active la opción.</p>
                <mat-slide-toggle [(ngModel)]="isChecked">Activar Modificación</mat-slide-toggle>
                <hr>
                    
                <!-- En el HTML -->
                <div class="card" style="margin-bottom: 20px;"> 
                    <div class="card-header">
                        <h4 class="card-title">Cursos y Actividades</h4>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Curso</th>
                                        <th>Actividad</th>
                                        <th>Fecha</th>
                                        <th>Fecha de Vencimiento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let curso of cursos">
                                        <ng-container *ngIf="getActividadesPorCurso()[curso._id]">
                                            <tr *ngFor="let actividad of getActividadesPorCurso()[curso._id]">
                                                <td>{{ curso.nombre }}</td>
                                                <td>{{ actividad.nombre }}</td>
                                                <td>{{ actividad.fecha | date }}</td>
                                                <td>{{ actividad.fechaVencimiento | date }}</td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </tbody>                                
                            </table>                                                                   
                        </div>  
                        <div mat-dialog-actions align="end">
                            <button mat-raised-button (click)="notificarActividad(solicitud)" color="info" type="button">
                              <i class="fas fa-envelope" style="margin-right: 5px; color: #17a2b8;"></i> <!-- Color azul claro (info) -->
                              Notificar Actividad
                            </button>
                        </div>
                                     
                    </div>                  
                </div>
            
                <div *ngIf="isChecked">
                   
                    <hr>
                    <h3>Actividades disponibles</h3>
                    <hr>

                    <div class="card" *ngFor="let curso of cursos" style="margin-bottom: 20px;">
                        <div class="card-header">
                            <h4 class="card-title">{{ curso.nombre }}</h4>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Actividad</th>
                                            <th>Fecha</th>
                                            <th>Fecha de Vencimiento</th>
                                            <th>Seleccionar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let actividad of curso.actividades">
                                            <td>{{ actividad.nombre }}</td>
                                            <td>{{ actividad.fecha | date }}</td>
                                            <td>{{ actividad.fechaVencimiento | date }}</td>
                                            <td><input type="checkbox" [(ngModel)]="actividad.seleccionada"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>

                <div *ngIf="isChecked">
                    <div mat-dialog-actions align="end">
                        <button mat-raised-button (click)="cambiarActividad()" color="warn" type="button">Cambiar Actividad</button>
                    </div>
                </div>   

            </div>
        </div>
    </div>
</mat-expansion-panel>





<!-- 
<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>
<mat-expansion-panel>
    <mat-expansion-panel-header class="thirdColor">
        <mat-panel-title>
            Esta opción permite modificar las actividades de una solicitud
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <div class="profile-area pb-70">
        <div class="container">
            <div class="profile-courses-quizzes">
                <hr>
                <h3>Actividades del alumno</h3>
                <p>Si desea modificar active la opción.</p>
                <mat-slide-toggle [(ngModel)]="isChecked">Activar Modificación</mat-slide-toggle>
                <hr>
                    
                <div class="card" style="margin-bottom: 20px;">
                    <div class="card-header">
                        <h4 class="card-title">Cursos y Actividades</h4>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Curso</th>
                                        <th>Actividad</th>
                                        <th>Fecha</th>
                                        <th>Fecha de Vencimiento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let curso of cursos">
                                        <ng-container *ngFor="let actividad of curso.actividades">
                                            <ng-container *ngIf="isActividadSeleccionada(actividad._id)">
                                                <tr>
                                                    <td>{{ curso.nombre }}</td>
                                                    <td>{{ actividad.nombre }}</td>
                                                    <td>{{ actividad.fecha | date }}</td>
                                                    <td>{{ actividad.fechaVencimiento | date }}</td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
                <div *ngIf="isChecked">
                   
                    <hr>
                    <h3>Actividades disponibles</h3>
                    <hr>

                    <div class="card" *ngFor="let curso of cursos" style="margin-bottom: 20px;">
                        <div class="card-header">
                            <h4 class="card-title">{{ curso.nombre }}</h4>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Actividad</th>
                                            <th>Fecha</th>
                                            <th>Fecha de Vencimiento</th>
                                            <th>Seleccionar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let actividad of curso.actividades">
                                            <td>{{ actividad.nombre }}</td>
                                            <td>{{ actividad.fecha | date }}</td>
                                            <td>{{ actividad.fechaVencimiento | date }}</td>
                                            <td><input type="checkbox" [(ngModel)]="actividad.seleccionada"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer bg-light">
                            Todas las actividades disponibles.
                            <div mat-dialog-actions align="end">
                                <button mat-raised-button (click)="cambiarActividad(curso)" color="warn" type="button">Cambiar Actividad</button>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>   

            </div>
        </div>
    </div>
</mat-expansion-panel> -->
