import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LeccionesService } from '../../../../services/cursos/lecciones.service';
import { Leccion } from '../../../../interfaces/interface-bakend/cursos/leccion.interface';
import Swal from 'sweetalert2';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';

@Component({
  selector: 'app-lecciones-listado',
  templateUrl: './lecciones-listado.component.html',
  styleUrls: ['./lecciones-listado.component.scss']
})
export class LeccionesListadoComponent implements OnInit, AfterViewInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
    [
      'nombre', 'descripcion', 'fechaInicio', 'fechaFin', 'materia', '_id'
    ];
  dataSource: any;
  lecciones: Leccion[];
  /* Fin Data Table*/

  flagLoaded = false;


  constructor(
    private leccionesService: LeccionesService,
    private router: Router,
    private guiMsjService: GuiMsjService
  ) { }

  ngOnInit(): void {
    this.loadDataPage();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
  }

  async verLeccion(event: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const leccion: Leccion = await this.leccionesService.getLeccionFindOne(event);
    this.router.navigateByUrl('/leccion-modificacion');
    this.leccionesService.setLeccionSeleccionada(leccion);
  }

  async loadDataTable() {
    this.leccionesService.getLecciones().then((lecciones: Leccion[]) => {
      this.dataSource = new MatTableDataSource(lecciones);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async loadDataPage() {
    await this.loadDataTable();
    return;
  }

}
