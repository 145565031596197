import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { MediaOfertaLaboralFileUserPipe } from '../../../../pipes/ofertas-laborales/mediaOfertaLaboralFileUser.Pipe';
import { MatDialog } from '@angular/material/dialog';
import { OfertasVerPdfComponent } from 'src/app/components/ofertas-laborales/common/ofertas-ver-pdf/ofertas-ver-pdf.component';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import * as FileSaver from 'file-saver';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';

@Component({
  selector: 'app-documentos-usuarios-files',
  templateUrl: './documentos-usuarios-files.component.html',
  styleUrls: ['./documentos-usuarios-files.component.scss']
})
export class DocumentosUsuariosFilesComponent implements OnInit {


  @Input() files: string[];
  @Input() usuario: User;

  // tslint:disable-next-line:ban-types
  userDocuments: Object[] = [];

  constructor(
    private usuarioService: UsuarioService,
    private actividadesService: ActividadesService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.pushNombreAdjunto(this.files);
  }

  download(idUsuario: string, adjunto: string, nombre: string) {
    FileSaver.saveAs(this.mediaOfertaLaboralFileUser(idUsuario, adjunto), nombre);
  }

  mediaOfertaLaboralFileUser(idUsuario: string, adjunto: string) {
    const mediaOfertaLaboralFileUser = new MediaOfertaLaboralFileUserPipe();
    return mediaOfertaLaboralFileUser.transform(idUsuario, adjunto);
  }

  async pushNombreAdjunto(files: string[]) {
    try {
        if (files.length > 0) {
            for (const adjunto of files) {
                try {
                    let nombreUsuarios = await this.usuarioService.getNombreAdjunto(adjunto);
                    let nombreAdjuntoActividad = await this.actividadesService.getNombreAdjunto(adjunto);
                    
                    if (!nombreUsuarios && nombreAdjuntoActividad) {
                      this.userDocuments.push({ nombre: nombreAdjuntoActividad, adjunto });
                    } else {
                      if (!nombreAdjuntoActividad && nombreUsuarios) {
                        this.userDocuments.push({ nombre: nombreUsuarios, adjunto });
                      } else {
                        this.userDocuments.push({ nombre: 'Nombre documentos desconocido', adjunto });
                      }
                    }
                   
                    
                } catch (error) {
                    console.error(error);
                }
            }
        }
    } catch (error) {
        console.error(error);
    }
  }

  /* Modal PDF  - Carga PDF */
  openDialogPdf(idUsuario: string, adjunto: string, tituloDocumento: string): void {

    const mediaOfertaLaboralFileUser = new MediaOfertaLaboralFileUserPipe();
    const path = mediaOfertaLaboralFileUser.transform(idUsuario, adjunto);

    const dialogRef = this.dialog.open(OfertasVerPdfComponent, {
      width: '800px',
      height: '800px',
      disableClose: true,
      data: {
        path,
        tituloDocumento,
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
    });
  }

  // FUNCIONES PARA BUSCAR NOMBRE DE LOS FILE DE UNA SOLICITUD ,CUANDO ESTOS SE MUEVEN DE LA SOLICITUD AL USUARIO.
  async getNombreAdjuntosSolicitud(idAdjunto): Promise<string> {
    return await this.actividadesService.getNombreAdjunto(idAdjunto);
  }

  



}
