import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import Swal from 'sweetalert2';
import { APP_CONFIG, IAppConfig } from '../../app.config/app.config';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { StorageService } from 'src/app/services/shared/storage.service';
import { Translators } from 'src/app/services/shared/translators.service';
import { Subscription } from 'rxjs';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';

@Component({
    selector: 'app-activate-account',
    templateUrl: './activate-account.component.html',
    styleUrls: ['./activate-account.component.scss'],
})
export class ActivateAccountComponent implements OnInit {
    form: FormGroup;
    siteKey: string;
    showPin: boolean;
    emailActivar: string = null;
    pinActivar: string = null;
    logoUrl: string = null;
    idioma: Subscription;
    await: any;

    constructor(
        private authservice: AuthService,
        private router: Router,
        @Inject(APP_CONFIG)
        private iAppConfig: IAppConfig,
        private route: ActivatedRoute,
        private guiMsjService: GuiMsjService,
        private storageService: StorageService,
        private translatorService: Translators,
        public configBeginService: ConfigBeginService
    ) {
        this.createForm();

        this.idioma = this.translatorService
            .getIdiomaSeleccionado()
            .subscribe((resp) => {
                this.idioma = resp;
            });
    }

    async ngOnInit() {
        this.getConfiguracionGral();
        this.obtenerParametros();
        this.setSiteKey(this.iAppConfig.recaptcha.siteKey);
    }

    async getConfiguracionGral() {
        this.logoUrl =  (await this.configBeginService.getConfiguracionGral()).urlLogo;
    }

    useLanguage(idioma: string) {
        this.translatorService.setIdiomaSeleccionado(idioma);
    }

    async obtenerParametros() {
        this.route.queryParamMap.subscribe((params) => {
            this.pinActivar = params.get('pin');
            this.emailActivar = params.get('email');

            this.createForm();

            this.form.controls['recaptcha'].enable();

            this.confirmAccount();
        });
    }

    setSiteKey(key: string) {
        this.siteKey = key;
    }

    get emailNoValido() {
        return this.form.get('email').invalid && this.form.get('email').touched;
    }

    get pinNoValido() {
        return this.form.get('pin').invalid && this.form.get('pin').touched;
    }

    validateTokenActivate() {
        const token = this.authservice.validateToken();
        if (!token) {
            this.router.navigateByUrl('/profile-authentication');
        }
    }

    createForm() {
        this.form = new FormGroup({
            email: new FormControl(this.emailActivar, [
                Validators.required,
                Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$'),
            ]),
            pin: new FormControl(this.pinActivar, [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(8),
            ]),
            recaptcha: new FormControl('', Validators.required),
        });
    }

    async confirmAccount() {
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        this.authservice.confirmAccount(await this.setDataForma());
    }

    async setDataForma() {
        const data: any = {
            email: this.form.value.email,
            pin: this.form.value.pin,
        };

        return data;
    }
}
