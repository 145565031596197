<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading>
<div class="andorra-apply-content ptb-70 animate__animated animate__fadeIn" *ngIf="flagLoaded">
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="container">
        <h4>Modificar Texto, Valores Respuestas y Eliminar Preguntas</h4>
        <h5 *ngIf="cantidadPreguntas">Cantidad de Preguntas: <strong>{{cantidadPreguntas}}</strong></h5>
        <div *ngIf="!flagExamen">
            <div class="alert alert-warning">Examen sin contenido...</div>
        </div>
        <mat-card *ngFor="let pregunta of preguntas; let i = index">
            <mat-card>
                <app-evaluacion-input-preguntas [preguntaConMultimedia]="pregunta" [pregunta]="pregunta.enunciado" [preguntas]="preguntas" [examenID]="examenID" [index]="i" [preguntaID]="pregunta._id"></app-evaluacion-input-preguntas>
                <mat-list *ngFor="let respuesta of pregunta.respuestas; let r = index">
                    <app-evaluacion-input-respuestas [esCorrecta]="respuesta.correcta" [examenID]="examenID" [preguntas]="preguntas" [respuesta]="respuesta.texto" [respuestaConMultimedia]="respuesta" [indexPregunta]="i" [indexRespuesta]="r"></app-evaluacion-input-respuestas>
                </mat-list>
            </mat-card>
            <br>
            <mat-divider></mat-divider><br>
        </mat-card>
        <mat-divider></mat-divider><br>
    </div>
</div>