<app-loading *ngIf='!this.flagLoaded' class="m-5">
</app-loading>

<div class="section-title" *ngIf="cursosDisponibles?.length === 0">
    <p>No hay cursos disponibles en este momento. Por favor, revisa más tarde.</p>
</div>

<mat-paginator [length]="this.cursosDisponibles.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getPaginatorData($event)" aria-label="Select page" showFirstLastButtons="false">
</mat-paginator>

<div class="row">
    <div class="col-lg-4 col-md-6 animate__animated animate__fadeIn" *ngFor="let curso of (this.cursosDisponibles |
slice: lowValue : highValue)" [newspaper]="curso">
        <app-curso-tarjeta [img]="true" [video]="false" [price]="true" [curso]="curso"></app-curso-tarjeta>
    </div>
</div>
