<div class="container mt-5">
  <br>
  <!-- Mostrar mensaje de error cuando el procesamiento haya fallado -->
  <div style="display: flex; justify-content: center;">
    <div class="alert alert-danger w-75 p-3 text-center mt-2" role="alert">
      <h4 class="alert-heading">Error al Procesar el Pago</h4>
      <p class="text-danger">
        ATENCIÓN: No se pudo procesar el pago. Por favor, inténtelo de nuevo o contáctese con la Administración.
      </p>
      
      <!-- Mostrar el spinner mientras se está procesando -->
      <div
        style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Cargando...</span>
        </div>
        <p>Ahora lo redirigiremos al campus.</p>
      </div>
    </div>
  </div>
</div>
