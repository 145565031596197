<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/dashboard">Inicio</a></li>
        <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
        <li><a routerLink="/lecciones-configuracion">Configurar Lecciones</a></li>
        <li>Lecciones</li>
      </ul>
      <h2>Lecciones</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div *ngIf="flagLoaded" class="profile-area pb-70">
  <div class="container">

    <div class="profile-courses-quizzes">
      <h3>Listado de Lecciones</h3>
      <a routerLink="/leccion-alta"><button mat-raised-button mat-button color="primary"><mat-icon aria-hidden="false"
            aria-label="Example home icon">add</mat-icon> Nueva Lección</button></a>
      <br>

      <hr>

      <mat-form-field>
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" autocomplete="off">
      </mat-form-field>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

        <!-- Column -->
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
          <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>


        <!-- Column -->
        <ng-container matColumnDef="descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let element"> {{element.descripcion }} </td>
        </ng-container>

        <!-- Column -->
        <ng-container matColumnDef="fechaInicio">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Inicio </th>
          <td mat-cell *matCellDef="let element"> {{element.fechaInicio | date:"dd/MM/yy"}} </td>
        </ng-container>

        <!-- Column -->
        <ng-container matColumnDef="fechaFin">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Fin </th>
          <td mat-cell *matCellDef="let element"> {{element.fechaFin | date:"dd/MM/yy"}} </td>
        </ng-container>

        <!-- Column -->
        <ng-container matColumnDef="materia">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Materia </th>
          <td mat-cell *matCellDef="let element"> {{element.materia.nombre}} </td>
        </ng-container>

        <!-- Column -->
        <ng-container matColumnDef="_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let element">
            <button (click)="verLeccion(element._id)" type="button" class="btn btn-warning"><i
                class="fas fa-edit"></i></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

    </div>
  </div>
</div>