import { Injectable } from '@angular/core';
import { Curso } from '../../interfaces/interface-bakend/cursos/curso.interface';
import { PaisDisponible } from '../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Examen } from '../../interfaces/interface-bakend/cursos/examen.interface';
import { Catalogo } from '../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { Actividad } from '../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { Solicitud } from '../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { Materia } from '../../interfaces/interface-bakend/cursos/materia.interface';
import { Leccion } from '../../interfaces/interface-bakend/cursos/leccion.interface';
import { User } from '../../interfaces/interface-bakend/seguridad/users/user.interface';
import { ActividadDAdjunto } from '../../interfaces/interface-bakend/ofertas-laborales/actividad-adjuntos.interface';
import { Invoice } from 'src/app/interfaces/interface-bakend/invoices/invoices.interfaces';
import { Perfil } from '../../interfaces/interface-bakend/seguridad/acl/perfil.interface';
import { ExamenEvaluacion } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';
import { Titulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { Mesa } from 'src/app/interfaces/interface-bakend/cursos/mesa.interface';
import { Gesto } from 'src/app/interfaces/interface-bakend/cursos/gesto.interface';
import { GestoUsuario } from 'src/app/interfaces/interface-bakend/cursos/gestoUsuario.interface';
import { UserDocumentoTipo } from 'src/app/interfaces/interface-bakend/seguridad/users/user-documento-tipos.interface';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';
import { Boletin } from 'src/app/interfaces/interface-bakend/cursos/boletin.interface';
import { AlumnoBoletin } from 'src/app/interfaces/interface-bakend/cursos/alumno-boletin.interface';
import { MateriaBoletinDetalle } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin-detalle.interface';
import { Alumno } from 'src/app/interfaces/interface-bakend/cursos/alumno.interface';

const PAIS = 'PAIS';
const ACTIVIDAD = 'ACTIVIDAD';
const CURSO = 'CURSO';
const TITULO = 'TITULO';
const SOLICITUD = 'SOLICITUD';
const MATERIA = 'MATERIA';
const MESA = 'MESA';
const GESTO = 'GESTO';
const GESTO_EVALUADO = 'GESTO_EVALUADO';
const LECCION = 'LECCION';
const TRADUCCION = 'TRADUCCION';
const USUARIO = 'USUARIO';
const EXAMEN = 'EXAMEN';
const EXAMEN_FINALIZADO = 'EXAMEN_FINALIZADO';
const ADJUNTO = 'ADJUNTO';
const CONCEPTO = 'CONCEPTO';
const IDIOMA = 'IDIOMA';
const PERSONA = 'PERSONA';
const INVOICE = 'INVOICE';
const PERFIL = 'PERFIL';
const EMAIL_ACTIVAR = 'EMAIL_ACTIVAR';
const IDIOMA_COOKIEBOT = 'IDIOMA_COOKIEBOT';
const EXAMEN_EVALUADO = 'EXAMEN_EVALUADO';
const USER_DOCUMENTS_TYPES = "USER_DOCUMENTS_TYPES";
const MATERIA_BOLETIN = 'MATERIA_BOLETIN';
const BOLETIN = 'BOLETIN';
const ALUMNO_BOLETIN = 'ALUMNO_BOLETIN';
const MATERIAS_BOLETIN = 'MATERIAS_BOLETIN';
const ALUMNO = 'ALUMNO';

@Injectable({
  providedIn: 'root'
})

export class StorageService {

  constructor() { }


  async setStoreGenerics(nameValue: string, value: string) {
    await sessionStorage.setItem(nameValue, value);
  }

  async getStoreGenerics(nameValue: string): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(nameValue) || null);
  }

  async clearStorage(nameValue: string) {
    await sessionStorage.removeItem(nameValue);
  }

  async setIdiomaCookiebot(nameValue: string) {
    await localStorage.setItem(IDIOMA_COOKIEBOT, JSON.stringify(nameValue));
  }

  async getIdiomaCookiebot(): Promise<string> {
    const flag = await localStorage.getItem(IDIOMA_COOKIEBOT);
    if (flag !== null) {
      return await localStorage.getItem(IDIOMA_COOKIEBOT);
    } else {
      return 'undefined';
    }
  }

  async clearIdiomaCookiebot() {
    await localStorage.removeItem(IDIOMA_COOKIEBOT);
  }

  async setPais(pais: PaisDisponible) {
    await sessionStorage.setItem(PAIS, JSON.stringify(pais));
  }

  async getPais(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(PAIS) || null);
  }

  async setAdjunto(adjunto: ActividadDAdjunto) {
    await sessionStorage.setItem(ADJUNTO, JSON.stringify(adjunto));
  }

  async getConcepto(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(CONCEPTO) || null);
  }

  async setConcepto(concepto: Catalogo) {
    await sessionStorage.setItem(CONCEPTO, JSON.stringify(concepto));
  }

  async setActividad(actividad: Actividad) {
    await sessionStorage.setItem(ACTIVIDAD, JSON.stringify(actividad));
  }

  async getActividad(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(ACTIVIDAD) || null);
  }

  async getAdjunto(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(ADJUNTO) || null);
  }

  async setSolicitud(solicitud: Solicitud) {
    await sessionStorage.setItem(SOLICITUD, JSON.stringify(solicitud));
  }

  async getSolicitud(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(SOLICITUD) || null);
  }

  async setCurso(curso: Curso) {
    await sessionStorage.setItem(CURSO, JSON.stringify(curso));
  }

  async setTitulo(titulo: Titulo) {
    await sessionStorage.setItem(TITULO, JSON.stringify(titulo));
  }

  async getTitulo(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(TITULO) || null);
  }

  async getCurso(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(CURSO) || null);
  }

  async setMateria(materia: Materia) {
    await sessionStorage.setItem(MATERIA, JSON.stringify(materia));
  }

  async setBoletin(boletin: Boletin) {
    await sessionStorage.setItem(BOLETIN, JSON.stringify(boletin));
  }

  async setAlumnoBoletin(boletin: AlumnoBoletin) {
    await sessionStorage.setItem(ALUMNO_BOLETIN, JSON.stringify(boletin));
  }

  async setAlumno(alumno: Alumno) {
    await sessionStorage.setItem(ALUMNO, JSON.stringify(alumno));
  }

  async setMateriasBoletin(materias: MateriaBoletinDetalle[]) {
    await sessionStorage.setItem(MATERIAS_BOLETIN, JSON.stringify(materias));
  }

  async setMateriaBoletin(materia: MateriaBoletin) {
    await sessionStorage.setItem(MATERIA_BOLETIN, JSON.stringify(materia));
  }

  async setMesa(mesa: Mesa) {
    await sessionStorage.setItem(MESA, JSON.stringify(mesa));
  }

  async setGesto(gesto: Gesto) {
    await sessionStorage.setItem(GESTO, JSON.stringify(gesto));
  }

  async setGestoEvaluado(gesto: GestoUsuario) {
    await sessionStorage.setItem(GESTO_EVALUADO, JSON.stringify(gesto));
  }

  async setLeccion(leccion: Leccion) {
    await sessionStorage.setItem(LECCION, JSON.stringify(leccion));
  }

  async setUsuario(usuario: User) {
    await sessionStorage.setItem(USUARIO, JSON.stringify(usuario));
  }

  async getUsuario(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(USUARIO) || null);
  }

  // Setear examen finalizado en sessionStorage

  async setExamenFinalizado(examen: Examen) {
    await sessionStorage.setItem(EXAMEN_FINALIZADO, JSON.stringify(examen));
  }

  // Obtener el examen finalizado luego de cursarlo
  async getExamenFinalizado(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(EXAMEN_FINALIZADO) || null);
  }

  async setExamenEvaluado(evaluacion: ExamenEvaluacion) {
    sessionStorage.setItem(EXAMEN_EVALUADO, JSON.stringify(evaluacion));
  }

  getExamenEvaluado() {
    return sessionStorage.getItem(JSON.parse(EXAMEN_EVALUADO) || null);
  }

  async setExamen(examen: Examen) {
    await sessionStorage.setItem(EXAMEN, JSON.stringify(examen));
  }

  async getExamen(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(EXAMEN) || null);
  }

  async setIdioma(idioma: string) {
    await localStorage.setItem(IDIOMA, JSON.stringify(idioma));
  }

  async getIdioma(): Promise<string> {
    let idioma;
    if (localStorage.getItem(IDIOMA) != null) {
      idioma = await localStorage.getItem(IDIOMA || null);
    } else {
      this.setIdioma('es');
      idioma = 'es';
    }
    return idioma;
  }

  async setPersona(persona: User) {
    await sessionStorage.setItem(PERSONA, JSON.stringify(persona));
  }

  async getPersona(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(PERSONA) || null);
  }

  async setInvoice(invoice: Invoice) {
    await sessionStorage.setItem(INVOICE, JSON.stringify(invoice));
  }

  async getInvoice(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(INVOICE) || null);
  }

  async setPerfil(perfil: Perfil) {
    await sessionStorage.setItem(PERSONA, JSON.stringify(perfil));
  }

  async getPerfil(): Promise<string> {
    return await sessionStorage.getItem(JSON.parse(PERFIL) || null);
  }

  async setEmailActivar(email: string) {
    await sessionStorage.setItem(EMAIL_ACTIVAR, email);
  }

  async getEmailActivar(): Promise<string> {
    return await sessionStorage.getItem(EMAIL_ACTIVAR || null);
  }

  async setUserDocumentType(userDocumentType: UserDocumentoTipo) {
    await sessionStorage.setItem(USER_DOCUMENTS_TYPES, JSON.stringify(userDocumentType));
  }

  async getUserDocumentType(): Promise<string> {
    return await sessionStorage.getItem(USER_DOCUMENTS_TYPES || null);
  }
}
