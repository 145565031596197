<!-- ACTIVAR PARA AADIDESS SI LO SOLICITA ESTA EN VARIAS PAG (PROFILE / GYM Y HOME)-->
<!-- <app-cookiebot></app-cookiebot> -->
<div class="gym-home-area" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div>
        <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('en')"><img src="assets/img/flags/usa.png" alt="English" width="20"> EN</button>
        <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('es')"><img src="assets/img/flags/espana.png" alt="Español" width="20"> ES</button>
        <!-- <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('ca')"><img src="assets/img/flags/catalunya.png" alt="Catala" width="20"> CA</button>
        <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('it')"><img src="assets/img/flags/italia.png" alt="Italiano" width="20"> IT</button>
        <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('ar')"><img src="assets/img/flags/arabia-saudita.png" alt="Arab" width="20"> AR</button>
        <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('fr')"><img src="assets/img/flags/francia.png" alt="French" width="20"> FR</button>
        <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('de')"><img src="assets/img/flags/alemania.png" alt="German" width="20"> DE</button>
        <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('br')"><img src="assets/img/flags/brasil.png" alt="Portugués" width="20"> PT</button> -->
    </div>
    <br>

    <div class="gym-banner-slides">
        <owl-carousel-o [options]="gymBannerSlides">
            <ng-template carouselSlide>
                <div class="gym-banner-item">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-content">
                                    <h1>{{ idioma | translate: "home.BIENVENIDA" | async}} <br>Campus Virtual</h1>
                                    <p>{{ idioma | translate: "home.BIENVENIDA-SUBTITULO" | async}}</p>
                                    <img [src]="logoUrl" alt="Campus" class="img-fluid mx-auto d-block"> <!-- Center the logo on mobile -->
                                    <div class="row">
                                        <!-- <div class="col-lg-4 col-md-12">
                                            <button type="button" (click)="this.getGuestToken()" class="default-btn animate__animated animate__bounceInUp"><i class="fas fa-user-check"></i>{{ idioma | translate: "login.INGRESAR" | async}}</button>
                                        </div> -->
                                        <div class="col-lg-4 col-md-12">
                                            <button routerLink="/profile-authentication" type="button" (click)="this.navigateRoot('/profile-authentication')" class="default-btn animate__animated animate__bounceInUp"><i class="fas fa-user-circle"></i>{{ idioma | translate: "login.INGRESAR" | async}}</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-12">
                                            <app-modal-legal [TC]="true" [PDP]="true"></app-modal-legal>
                                        </div>
                                    </div>
                                    <!-- TECNODRET LOGO -->
                                    <!--<app-tecnodret></app-tecnodret>-->
                                    <!-- FIN TECNODRET LOGO -->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-image">
                                    <img [src]="slide1" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="gym-banner-item">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-content">
                                    <h1>{{ idioma | translate: "home.BANNER1-TITULO" | async}}</h1>
                                    <p>{{ idioma | translate: "home.BANNER1-SUBTITULO" | async}}</p>
                                    <img [src]="logoUrl" alt="Campus" class="img-fluid mx-auto d-block"> <!-- Center the logo on mobile -->
                                    <div class="row">
                                        <!-- <div class="col-lg-4 col-md-12">
                                            <button type="button" (click)="this.getGuestToken()" class="default-btn animate__animated animate__bounceInUp"><i class="fas fa-user-check"></i>{{ idioma | translate: "login.INGRESAR" | async}}</button>
                                        </div> -->
                                        <div class="col-lg-4 col-md-12">
                                            <button routerLink="/profile-authentication" type="button" (click)="this.navigateRoot('/profile-authentication')" class="default-btn animate__animated animate__bounceInUp"><i class="fas fa-user-circle"></i>{{ idioma | translate: "login.INGRESAR" | async}}</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-12">
                                            <app-modal-legal [TC]="true" [PDP]="true"></app-modal-legal>
                                        </div>
                                    </div>
                                    <!-- TECNODRET LOGO -->
                                    <app-tecnodret></app-tecnodret>
                                    <!-- FIN TECNODRET LOGO -->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-image">
                                    <img [src]="slide2" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="gym-banner-item">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-content">
                                    <h1>{{ idioma | translate: "home.BANNER2-TITULO" | async}}</h1>
                                    <p>{{ idioma | translate: "home.BANNER2-SUBTITULO" | async}}</p>
                                    <img [src]="logoUrl" alt="Campus" class="img-fluid mx-auto d-block"> <!-- Center the logo on mobile -->
                                    <div class="row">
                                        <!-- <div class="col-lg-4 col-md-12">
                                            <button type="button" (click)="this.getGuestToken()" class="default-btn animate__animated animate__bounceInUp"><i class="fas fa-user-check"></i>{{ idioma | translate: "login.INGRESAR" | async}}</button>
                                        </div> -->
                                        <div class="col-lg-4 col-md-12">
                                            <button routerLink="/profile-authentication" type="button" (click)="this.navigateRoot('/profile-authentication')" class="default-btn animate__animated animate__bounceInUp"><i class="fas fa-user-circle"></i>{{ idioma | translate: "login.INGRESAR" | async}}</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-12">
                                            <app-modal-legal [TC]="true" [PDP]="true"></app-modal-legal>
                                        </div>
                                    </div>
                                    <!-- TECNODRET LOGO -->
                                    <app-tecnodret></app-tecnodret>
                                    <!-- FIN TECNODRET LOGO -->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-image">
                                    <img [src]="slide3" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>

</div>