import { ModalRespuestaComponent } from './modal-respuesta/modal-respuesta.component';
import { Pregunta } from './../../../../../interfaces/interface-bakend/cursos/examen.interface';
import { Component, Inject, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Examen } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { CursosService } from '../../../../../services/cursos/cursos.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { Subscription } from 'rxjs';
interface DialogData {
    posPregunta: number;
    pregunta: Pregunta;
    examen: Examen;
}
@Component({
  selector: 'app-respuestas-alta',
  templateUrl: './respuestas-alta.component.html',
  styleUrls: ['./respuestas-alta.component.scss']
})
export class RespuestasAltaComponent implements OnInit {

  @Output() guardado = new EventEmitter();
  @Input() modificar = false;
  forma: FormGroup;
  examen: Examen;
  subscriptionExamenValues: Subscription;
  pregunta: Pregunta;
  flagLoaded = false;
  procesando = false;
  isEditable = true;
  flagFormaValido = false;
  flagSubscriptionExamenValues = true;


  constructor(
    private cursosService: CursosService,
    private router: Router,
    public dialog: MatDialog,
    private guiMsjService: GuiMsjService,
    private examenesService: ExamenesService,
    ) {
        this.subscriptionExamen();
        this.createForm();
        this.createListenersStateForm();
   }

   ngOnInit(): void {
    // this.cargarFormaTest(); // para test
    this.cargarExamenModificar();
   }

  /*******************************MODIFICAR EXAMEN CONDICION *******************************/
  async cargarExamenModificar() {
    if (this.modificar) {
      await this.setValoresExamenModificar();
    } else {
      this.flagSubscriptionExamenValues = true;
    }
  }

  async vaciarArreglosPreguntaExamenModificar() {
    this.preguntas.clear();
    this.preguntas.clear();
  }

  async setValoresExamenModificar() {
      this.examen = await this.examenesService.getExamenSeleccionado();
      this.cursosService.setConfigExamen(this.examen);
      await this.cursosService.setConfigExamen(this.examen);
      await this.cargarForma();
  }

  async cargarArreglosExamenModificar(preguntas: Pregunta[]) {
    preguntas = this.examen.preguntas;
    return preguntas;
  }
 /*******************************FIN MODIFICAR EXAMEN CONDICION *******************************/

  /* observable al examen */
  subscriptionExamen() {
    this.subscriptionExamenValues = this.examenesService.getExamen().subscribe( async resp => { 
          this.examen = resp.value;
          await this.cargarForma();
    });
  }

  createListenersStateForm() {
    return this.forma.statusChanges.subscribe( async status => {
        if (
           status === 'VALID'
        ) {
            this.controlFormaRespuestasCargadas(this.examen);
        } else {
          this.flagFormaValido = false;
        }
    });
  }

  controlFormaRespuestasCargadas(examen: Examen): void  {
    let cont = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < examen.preguntas.length; i++) {
        const pregunta = examen.preguntas[i];
        if (pregunta.respuestas !== undefined) {
            if (pregunta.respuestas.length > 0) {
                cont ++;
            } else {
            }
         }
     }
    if (cont === examen.preguntas.length) {
        this.flagFormaValido = true;
    } else {
        this.flagFormaValido = false;
    }

  }

  createForm(){
    this.forma = new FormGroup({
      preguntas: new FormArray([]),
      tipos: new FormArray([]),
    });
  }

  get preguntas(){
    return this.forma.get('preguntas') as FormArray;
  }

  get tipos(){
    return this.forma.get('tipos') as FormArray;
  }

  async guardarRespuestas() {
    // las preguntas ya fueron guardadas en esta instancia, solo es una formalidad para control de flujo el guardarRespuestas
    this.guiMsjService.msjFormSubmit('respuestasCargadas');
    this.guardado.emit(true);

    // actualizo srvicios y vistas
    this.examen = await this.examenesService.getExamenFindOne(this.examen._id);
    this.examenesService.setExamen(this.examen);
    this.examenesService._setExamenView$(this.examen);

    this.disabledForma();
  }

    async cargarForma() {

    /*******************************MODIFICAR EXAMEN CONDICION *******************************/
    if (this.modificar) { 
      this.procesando = true;
      this.vaciarArreglosPreguntaExamenModificar();
      this.flagSubscriptionExamenValues = false;
    }
    /*******************************FIN MODIFICAR EXAMEN CONDICION *******************************/


    if ( this.cursosService.getConfigExamen() !== undefined) {
        this.examen = await this.cursosService.getExamenById((await this.cursosService.getConfigExamen())._id);
        // cargo las preguntas
        if (this.examen.preguntas.length > 0){
            // tslint:disable-next-line:prefer-for-of
            for (let index = 0; index < this.examen.preguntas.length; index++) {
              this.preguntas.push(new FormControl(this.examen.preguntas[index]));
              this.tipos.push(new FormControl(this.examen.preguntas[index].tipo));
            }
        } 
        this.flagLoaded = true;
        this.isEditable = true;
        this.procesando = false;
    } else {
        return;
    }
  }

  async cargarFormaTest() {
        this.examen = await this.cursosService.getExamenById('61d5a94eb62ffb9613b5e276');
        // cargo las preguntas
        if (this.examen.preguntas.length > 0){
            // tslint:disable-next-line:prefer-for-of
            for (let index = 0; index < this.examen.preguntas.length; index++) {
              this.preguntas.push(new FormControl(this.examen.preguntas[index]));
              this.tipos.push(new FormControl(this.examen.preguntas[index].tipo));
            }
        }
        this.flagLoaded = true;
        this.isEditable = true;
  }

  /* Modal Carga Respuestas  */
  openDialogRespuesta(posPregunta: number, pregunta: Pregunta, modificar: boolean): void {
      
      let flagControlBorrar = false;
      
      // controlo si tiene multimedia, si tiene no puede modificar
      this.examen.preguntas[posPregunta].respuestas.forEach(element => {
        if (element.contenido.contiene) {
          flagControlBorrar = true;
        }
      });

      if (flagControlBorrar) {this.guiMsjService.msjFormSubmit('modificarRespuestaMultimedia');} 

      if (!flagControlBorrar && modificar) {
        this.guiMsjService.msjFormSubmit('modificarRespuesta'); 
        this.openModalRespuesta(posPregunta ,pregunta, this.examen);
      }  

      if (!flagControlBorrar && !modificar) {this.openModalRespuesta(posPregunta, pregunta, this.examen)}

      
  }

  openModalRespuesta(posPregunta,pregunta,examen) {
    const dialogRef = this.dialog.open(ModalRespuestaComponent, {
      width: '1400px',
      height: '800px',
      disableClose: true,
      data: {
              posPregunta,
              pregunta,
              examen,
          }
      });

    dialogRef.afterClosed().subscribe(async (result: DialogData)  => {
      if (result) {

        this.examen = await this.cursosService.getConfigExamen();

        this.controlFormaRespuestasCargadas(this.examen);

        /******************************* LUEGO DE AREGAR AL MENOS UNA PREGUNTA ES MODIFICAR*******************************/
        this.modificar = true;
        this.cargarForma();
        /******************************* LUEGO DE AREGAR AL MENOS UNA PREGUNTA ES MODIFICAR *******************************/

        /******************************* MODIFICAR EXAMEN CONDICION *******************************/
        this.examenesService._setExamenView$(this.examen);
        /******************************* FIN MODIFICAR EXAMEN CONDICION *******************************/

      } else {
          return;
      }
    });
  }
  disabledForma(){

    // tslint:disable-next-line:no-string-literal
    this.forma.controls['preguntas'].disable();
    // tslint:disable-next-line:no-string-literal
    this.forma.controls['tipos'].disable();
  }


}
