import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { Materia } from 'src/app/interfaces/interface-bakend/cursos/materia.interface';
import { Actividad } from 'src/app/interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';
import { PaisesService } from 'src/app/services/shared/paises.service';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
  selector: 'app-tarjeta',
  templateUrl: './tarjeta.component.html',
  styleUrls: ['./tarjeta.component.scss']
})
export class TarjetaComponent implements OnInit {
  @Input() actividad: Actividad;
  @Input() flagCursoAlumno = false;
  @Input() video: false;
  @Input() img: false;
  @Input() price: false;
  @Input() click: false;
  idioma: Subscription;
  flagLoaded = false;

  profesoresCurso: any[] = [];
  curso: Curso;
  lecciones = 0;

  constructor(
    private cursosService: CursosService,
    private translatorService: Translators,
    private actividadesService: ActividadesService,
    private paisesService: PaisesService,
  ) {
      this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        this.idioma = resp;
      });
   }

  async ngOnInit() {
    this.curso = await this.cursosService.getCursoFindOne(this.actividad.cursos[0]._id);
    this.lecciones = this.cantLecciones(this.curso);
    this.getProfesores();
    this.flagLoaded = true;
  }

  cantLecciones(curso: Curso): number {
    let cant = 0;
    curso.materias.forEach( (element: Materia) => {
      cant = cant + element.lecciones.length
    }); 
    return cant
  }

  navCtrlDescripcionCurso(actividad: Actividad){
    this.actividadesService.setActividadSeleccionada(actividad);
  }

  async getProfesores(){
    const profesores = await this.actividad.cursos[0].profesores; // TIENE UN SOLO CURSO LA ACTIVIDAD 
    if (profesores !== undefined) {
      profesores.forEach( async (element: any) => {
        await this.cursosService.getProfesorFindOne(element).then((profes: any) => {
          if (profes !== '' && profes != null) {
            this.profesoresCurso.push(profes.usuario);
          }
        });
      });
    }
  }

  navCtrlDetalleOfertaLaboral(actividad: Actividad) {
    this.actividadesService.setActividadSeleccionada(actividad);
    this.paisesService.setPaisSeleccionado(actividad.paisDisponible);
  }

}
