<div *ngIf="flagLoaded" class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">

    <div class="container">
        <div class="clearfix">
            <img src="assets/img/apply.jpg" class="float-md-start ms-5 me-3" alt="solicitud" width="25%">
            <h3>Formulario de solicitud</h3>
        </div>
        <hr>
        <div class="row  mx-auto">
            <div class="col-lg-2 col-md-12"></div>
            <div class="col-lg-12 col-md-12">
                <div class="apply-form">
                    <h2>Formulario</h2>
                    <form [formGroup]="forma">
                        <div class="row">

                            <div class="col-lg-6 col-md-6">

                                <div class="form-group">
                                    <label>País</label>
                                    <input disabled type="text" class="form-control" value="{{ this.solicitud.pais.name_es }}" readonly>
                                </div>
                                <div class="form-group">
                                    <label for="exampleDataList" class="form-label">Actividad</label>
                                    <input disabled type="text" class="form-control" value="{{ this.solicitud.actividad.nombre }}" readonly><br>
                                </div>

                                <div class="form-group">
                                    <label>Nombre</label>
                                    <input disabled formControlName="nombre" type="text" class="form-control">
                                </div>

                                <div class="form-group">
                                    <label>Apellido</label>
                                    <input disabled formControlName="apellido" type="text" class="form-control">
                                </div>


                                <div class="form-group">
                                    <label for="tipoIdentificacion">Tipo de Identificación</label>
                                    <input disabled formControlName="tipoIdentificacion" type="text" class="form-control">
                                </div>

                                <div class="form-group">
                                    <label>Número de Identificación</label>
                                    <input disabled formControlName="identificacion" type="text" class="form-control">
                                </div>

                                <div class="form-group">
                                    <label>Fecha de Nacimiento</label>
                                    <input disabled formControlName="birth" type="text" lass="form-control">
                                </div>

                                <div class="form-group">
                                    <label class="form-label">País de nacimiento</label>
                                    <input disabled formControlName="paisNacimiento" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">

                                <!-- <div class="form-group">
                                    <label for="estadoCivilDataList" class="form-label">Estado civil</label>
                                    <input disabled formControlName="estadoCivil" type="text" class="form-control">
                                </div> -->

                                <div class="form-group">
                                    <label class="form-label">Código de área</label>
                                    <input disabled formControlName="paisCodeArea" class="form-control" placeholder="Código de área">
                                </div>

                                <div class="form-group">
                                    <label>Número de teléfono</label>
                                    <div class="input-group">
                                        <input disabled formControlName="telefono" type="text" placeholder="Número de teléfono" class="form-control">
                                    </div>
                                </div>

                                <!-- <div class="form-group" hidden>
                                    <label>Seleccione una de las siguientes opciones, con respecto al país donde condiMigra:</label>
                                    <input disabled formControlName="condiMigra" type="text" placeholder="Número de teléfono" class="form-control">
                                </div> -->

                                <!-- <div class="form-group" hidden>
                                    <label>¿Actualmente posee un Pasaporte válido?</label>
                                    <mat-radio-group *ngIf="this.usuario.pasaporteValido" formControlName="pasaporteValido" aria-label="Select an option">
                                        <mat-radio-button disabled [checked]="true" value="true">Sí</mat-radio-button>
                                        <mat-radio-button disabled>No</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-radio-group *ngIf="!this.usuario.pasaporteValido" formControlName="pasaporteValido" aria-label="Select an option">
                                        <mat-radio-button disabled>Sí</mat-radio-button>
                                        <mat-radio-button disabled [checked]="true" value="true">No</mat-radio-button>
                                    </mat-radio-group>
                                </div> -->

                                <!-- MONTANIA -->
                                <div class="form-group">
                                    <label class="form-label">Otra formación</label>
                                    <input disabled formControlName="otraFormacion" class="form-control" placeholder="....">
                                </div>

                                <!-- AADIDESS DELEGACION CATALOGO -->
                                <div class="form-group">
                                    <label class="form-label">Delegación</label>
                                    <input disabled formControlName="delegacion" class="form-control" placeholder="....">
                                </div>                                

                                <!-- AADIDESS DISCIPLINA CATALOGO-->
                                <div class="form-group">
                                    <label class="form-label">Disciplina</label>
                                    <input disabled formControlName="disciplina" class="form-control" placeholder="....">
                                </div>

                                <!-- AADIDESS -->
                                <div class="form-group">
                                    <label>¿Padece alguna enfermedad que requiera tratamiento periódico?</label>
                                    <mat-radio-group *ngIf="this.usuario?.enfermedad.toString() == 'true'" aria-label="Select an option">
                                        <mat-radio-button disabled [checked]="true" value="true">SI</mat-radio-button>
                                        <mat-radio-button disabled value="false">NO</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-radio-group *ngIf="this.usuario?.enfermedad.toString() == 'false'" aria-label="Select an option">
                                        <mat-radio-button disabled value="false">SI</mat-radio-button>
                                        <mat-radio-button disabled value="false" [checked]="true">NO</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <!-- AADIDESS -->
                                <div class="form-group">
                                    <label>¿Fue hospitalizado en los últimos 5(cinco) años?</label>
                                    <mat-radio-group *ngIf="this.usuario?.hospitalizado.toString() == 'true'" aria-label="Select an option">
                                        <mat-radio-button disabled [checked]="true" value="true">SI</mat-radio-button>
                                        <mat-radio-button disabled value="false">NO</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-radio-group *ngIf="this.usuario.hospitalizado.toString() == 'false'" aria-label="Select an option">
                                        <mat-radio-button disabled value="false">SI</mat-radio-button>
                                        <mat-radio-button disabled value="false" [checked]="true">NO</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <!-- AADIDESS -->
                                <div class="form-group">
                                    <label>Título secundario?</label>
                                    <mat-radio-group *ngIf="this.usuario?.tituloSecundario.toString() == 'true'" aria-label="Select an option">
                                        <mat-radio-button disabled [checked]="true" value="true">SI</mat-radio-button>
                                        <mat-radio-button disabled value="false">NO</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-radio-group *ngIf="this.usuario.tituloSecundario.toString() == 'false'" aria-label="Select an option">
                                        <mat-radio-button disabled value="false">SI</mat-radio-button>
                                        <mat-radio-button disabled value="false" [checked]="true">NO</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <!-- AADIDESS -->
                                <div *ngIf="this.usuario?.tituloSecundario.toString() == 'true'">
                                    <div class="form-group">
                                        <label for="exampleDataList" class="form-label">Título Secundario de? </label>
                                        <input formControlName="tituloDe" class="form-control" type="text" placeholder="'...">
                                    </div>

                                    <!-- AADIDESS -->
                                    <div class="form-group">
                                        <label for="exampleDataList" class="form-label">Título Secundario Expedido</label>
                                        <input formControlName="tituloExpedido" class="form-control" type="text" placeholder="'...">
                                    </div>
                                </div>


                                <!-- <div class="form-group" hidden>

                                    <label>Idiomas</label>

                                    <p>
                                        <mat-checkbox disabled formControlName="ca">ca</mat-checkbox>
                                    </p>
                                    <p>
                                        <mat-checkbox disabled formControlName="es">es</mat-checkbox>
                                    </p>
                                    <p>
                                        <mat-checkbox disabled formControlName="fr">fr</mat-checkbox>
                                    </p>
                                    <p>
                                        <mat-checkbox disabled formControlName="en">en</mat-checkbox>
                                    </p>

                                </div> -->
                            </div>

                        </div>
                    </form>

                    <button (click)="modificarPersona()" class="btn btn-primary me-md-2" type="button">Modificar persona</button>

                </div>
            </div>
        </div>
    </div>
</div>