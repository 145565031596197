import { User } from './../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { Component, Input, OnInit } from '@angular/core';
import { Solicitud } from '../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';

@Component({
  selector: 'app-ofertas-estado-detalle',
  templateUrl: './ofertas-estado-detalle.component.html',
  styleUrls: ['./ofertas-estado-detalle.component.scss']
})
export class OfertasEstadoDetalleComponent implements OnInit {

  imageUrl: string | ArrayBuffer = './assets/img/userDefault.png';

  @Input() solicitud: Solicitud;
  @Input() usuario: User;
  @Input() nombrePais: string;
  @Input() estadoFormulario: string;
  @Input() estadoDocumentacion: string;
  @Input() estadoPagoTotal: string;

  constructor() { }

  ngOnInit(): void {
  }

}
