import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExamenEvaluacion } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';
import { Examen } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { StorageService } from 'src/app/services/shared/storage.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';

@Component({
    selector: 'app-examen-resultado',
    templateUrl: './examen-resultado.component.html',
    styleUrls: ['./examen-resultado.component.scss']
})
export class ExamenResultadoComponent implements OnInit {

  constructor(private examenService: ExamenesService, 
    private router: Router,
    private cursosService: CursosService,
    private usuariosService: UsuarioService) { }

  evaluacion: ExamenEvaluacion;
  examenEvaluado: Examen;
  flagLoaded = false;
  alumno: any;
  cargandoAlumno: boolean = true;
  ngOnInit(): void {
    this.getExamenEvaluacionFinal();
  }

  async getExamenEvaluacionFinal(){
    if(this.examenService.getExamenEvaluacionId() != undefined){     
      await this.examenService.getEvaluacionFindOne(this.examenService.getExamenEvaluacionId()).then((examenEvaluadoFinal: any)=>{
        this.usuariosService.getFindOne(examenEvaluadoFinal.usuario._id).then((usuario: User)=>{
          this.alumno = usuario;
          this.cargandoAlumno = false;
        });
        this.evaluacion = examenEvaluadoFinal;
        this.examenEvaluado = examenEvaluadoFinal.respuesta;
        this.flagLoaded = true;
      })
    }else{
      this.router.navigateByUrl('/examenes-alumno')
    }
    
  }
}
