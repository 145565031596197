import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Solicitud } from '../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { MediaService } from '../../../../../services/shared/media.service';
import { GuiMsjService } from '../../../../../services/shared/gui-msj.service';
import { SolicitudesService } from '../../../../../services/ofertas-laborales/solicitudes.service';
import { Router } from '@angular/router';
import { Translators } from '../../../../../services/shared/translators.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface DialogData {
    solicitud: Solicitud;
    adjunto: string;
    label: string;
    numberState: number;
    labelButton: string;
    accept: string; // image/jpeg, image/jpg, image/png, application/pdf
}

@Component({
  selector: 'app-agregar-documentos-extra',
  templateUrl: './agregar-documentos-extra.component.html',
  styleUrls: ['./agregar-documentos-extra.component.scss']
})
export class AgregarDocumentosExtraComponent implements OnInit, OnDestroy {

    activarCargaDocumento = false;
    value = 0;
    subscriptionAvance: Subscription;
    flagLoading = false;
    selectedFile: File | null = null;
    previewUrl: SafeUrl | null = null;
    flagDisableButton = true;
    flagCargaCorrecta = false;
    idioma: Subscription;

    constructor(
        private mediaService: MediaService,
        private guiMsjService: GuiMsjService,
        private solicitudesService: SolicitudesService,
        private router: Router,
        private translatorService: Translators,
        private sanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<AgregarDocumentosExtraComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.subscriptionAvance = new Subscription();
    }

    ngOnInit(): void {
        this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
        });
        this.mediaService.reiniciarContadorControlUpload();
        this.subscribeAvanceUpload();
    }

    ngOnDestroy(): void {
        this.subscriptionAvance.unsubscribe();
    }

    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files[0]) {
            const file = input.files[0];
            if (this.mediaService.controlSize(event)) {
                this.selectedFile = file;
                this.flagDisableButton = false;
                this.previewFile(file);
            } else {
                alert('File too Big, please select a file less than 4mb');
            }
        }
    }

    previewFile(file: File): void {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target?.result) {
                this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(e.target.result as string);
                console.log('previewFile', this.previewUrl);
            }
        };
        reader.readAsDataURL(file);
    }

    async uploadDocumentoAdjunto(): Promise<void> {
        if (!this.selectedFile) return;

        let idUsuario;
        if (this.data.solicitud.usuario.hasOwnProperty('_id')) {
         idUsuario = this.data.solicitud.usuario._id; 
        } 
         else { 
            idUsuario = this.data.solicitud.usuario
        }  

        try {
            const resp = await this.mediaService.uploadOneAttachJobOfferFromWeb(
                this.selectedFile, idUsuario,
                this.data.adjunto, this.data.solicitud._id
            );

            if (resp['ok'] && this.value === 100) {
                this.solicitudesService.setSolicitudSeleccionada(resp['solicitud']);
                this.flagCargaCorrecta = true;
                this.guiMsjService.msjFormSubmit('uploadDocumentOK');
            } else {
                this.flagCargaCorrecta = false;
                this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
            }
        } catch (error) {
            this.flagCargaCorrecta = false;
            this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
        } finally {
            this.flagLoading = false;
        }
    }

    subscribeAvanceUpload(): void {
        this.subscriptionAvance = this.mediaService.getAvance().subscribe(resp => {
            this.value = resp ? resp.value : 0;
        });
    }

    async aceptar(): Promise<void> {
        if (!this.selectedFile) { return; }

        this.flagLoading = true;
        this.flagDisableButton = true;
        this.mediaService.incrementarContadorControlUpload();

        if (this.mediaService.controlContador()) {
            await this.uploadDocumentoAdjunto();
            this.mediaService.reiniciarContadorControlUpload();
            this.dialogRef.close(this.flagCargaCorrecta);
        } else {
            this.flagLoading = false;
        }
    }

    closeModal(): void {
        this.dialogRef.close(false);
    }
}
