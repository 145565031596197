import { Inject, Pipe, PipeTransform } from '@angular/core';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../app.config/app.config-ofertas';

@Pipe({
  name: 'estadosPipe'
})
export class EstadosPipe implements PipeTransform {

    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
    ){}

    transform( stateNums: number ): string {

        switch (stateNums) {
            case this.iAppOfertas.estado_0.number:
                return this.iAppOfertas.estado_0.name;
                break;

            case this.iAppOfertas.estado_1.number:
                return this.iAppOfertas.estado_1.name;

                break;

            case this.iAppOfertas.estado_2.number:
                return this.iAppOfertas.estado_2.name;
                break;

            case this.iAppOfertas.estado_3.number:
                return this.iAppOfertas.estado_3.name;
                break;

            case this.iAppOfertas.estado_4.number:
                return this.iAppOfertas.estado_4.name;
                break;

            case this.iAppOfertas.estado_5.number:
                return this.iAppOfertas.estado_5.name;
                break;

            case this.iAppOfertas.estado_6.number:
                return this.iAppOfertas.estado_6.name;
                break;

            case this.iAppOfertas.estado_7.number:
                return this.iAppOfertas.estado_7.name;
                break;

            case this.iAppOfertas.estado_8.number:
                return this.iAppOfertas.estado_8.name;
                break;

            case this.iAppOfertas.estado_9.number:
                return this.iAppOfertas.estado_9.name;
                break;

            case this.iAppOfertas.estado_10.number:
                return this.iAppOfertas.estado_10.name;
                break;

            // case this.iAppOfertas.estado_9.number:
            //     return this.iAppOfertas.estado_9.name;
            //     break;

            default:
                return 'NOT DESCRIPTION';
                break;
        }



    }

}
