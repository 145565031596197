<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>
<mat-expansion-panel>
    <mat-expansion-panel-header class="secondaryColorAadidess">
        <mat-panel-title>
            Esta opción permite modificar los módulos seleccionados
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="profile-area pb-70">
        <div class="container">
            <div class="profile-courses-quizzes">
                <hr>
                <h3>Módulos Seleccionados</h3>
                <p>Si desea modificar active la opción.</p>
                <div class="alert alert-info" role="alert">
                    <strong>ATENCIÓN:</strong> Si utiliza circuitos en modo presencial o en modo online, NO PUEDE CAMBIAR EL MÓDULO. <strong>Solicite al usuario que realice una nueva solicitud desde cero.</strong>
                </div>                  
                <mat-slide-toggle [(ngModel)]="isChecked">Activar Modificación</mat-slide-toggle>
                <hr>

                <div class="mat-elevation-z8">

                    <table mat-table [dataSource]="dataSource" matSort>

                        <!-- Column -->
                        <ng-container matColumnDef="nombre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                            <td mat-cell *matCellDef="let element"> {{ element.nombre }} </td>
                        </ng-container>

                        <!-- Column -->
                        <ng-container matColumnDef="precio">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
                            <td mat-cell *matCellDef="let element"> ${{element.precio}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                    </table>

                    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isChecked">
        <mat-divider></mat-divider>
        <br>
        <mat-selection-list #modules [(ngModel)]="selectedModules" (ngModelChange)="onGroupsChange($event)">
            <mat-list-option *ngFor="let module of this.modulos" [value]="module">
                {{module.nombre}}: ${{module.precio}}
            </mat-list-option>
        </mat-selection-list>

        <p>
            Módulos Seleccionados: {{selectedModules.length}} Módulos Seleccionados: ${{total}}
        </p>
        <app-loading *ngIf="flagProcesando" class="m-5"></app-loading><br>
        <div mat-dialog-actions align="end">
            <button *ngIf="selectedModules.length == 0" disabled mat-raised-button color="warn" type="button">MODIFICAR</button>
            <button *ngIf="selectedModules.length > 0" [disabled]="flagDisabled" mat-raised-button (click)="modificar()" color="warn" type="button">MODIFICAR</button>
        </div>
    </div>
</mat-expansion-panel>