import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG_OFERTAS, IAppOfertas } from 'src/app/app.config/app.config-ofertas';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config/app.config';
import { environment } from 'src/environments/environment';

const URL_SITIO = environment.urlSitio;

@Component({
  selector: 'app-macro-pagos-error',
  templateUrl: './macro-pagos-error.component.html',
  styleUrls: ['./macro-pagos-error.component.scss']
})
export class MacroPagosErrorComponent implements OnInit {

  isLoading = true; // Controla el spinner
  isMessageDisplayed = false; // Controla la visualización del mensaje

  constructor(
    @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
    @Inject(APP_CONFIG) public iAppConfig: IAppConfig,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {   
    await this.pagoResult();
  }

  async pagoResult() {
    try {
      // Simula la carga durante el procesamiento del pago
      setTimeout(() => {
        this.isLoading = false;
        this.isMessageDisplayed = true; 

        setTimeout(() => {
          this.continuar();
        }, 5000);
      }, 10000);

    } catch (error) {
      console.error('Error en mercadoPagoResult:', error);
      this.isLoading = false;
      this.isMessageDisplayed = true;
      this.continuar();
    }
  }

  async continuar() {
    this.router.navigateByUrl(URL_SITIO); // Redirige a la página principal
  }
}
