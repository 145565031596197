import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { PaypalPurchaseUnit } from '../../../interfaces/interface-frontend/shared/paypalPurchaseUnit.nterface';
import { PaypalButtonStyle } from '../../../interfaces/interface-frontend/shared/paypalButtonStyle.interface';
import { Observable, Subject } from 'rxjs';
import { PaypalService } from '../../../services/shared/paypal.service';
import { GuiMsjService } from '../../../services/shared/gui-msj.service';


@Component({
  selector: 'app-pay-pal-button',
  templateUrl: './pay-pal-button.component.html',
  styleUrls: ['./pay-pal-button.component.scss']
})

/* Este componente trabaja con un observable definido en el padre que lo instancia*/
/* de esta forma, a través del servicio de PaypalService podemos manejar los datos retornados */
export class PayPalButtonComponent implements OnInit {


 @Input() valor: number;
 @Input() description: string;
 // tslint:disable-next-line:variable-name
 @Input() currency_code: string;

 @ViewChild('paypalRef', { static: true }) paypalElment: ElementRef;

 purchase: PaypalPurchaseUnit;
 button: PaypalButtonStyle;

    constructor(
        private guiMsjService: GuiMsjService,
        public paypalService: PaypalService,
    ) { }

    ngOnInit(): void {
        this.setButtonStylePaypal();
        this.setValorPaypal(this.description, this.currency_code, this.valor);

        window.paypal
        .Buttons({
            style: {
                layout:  this.button.layout,
                color:   this.button.color,
                shape:   this.button.shape,
                label:   this.button.label,
            },
            createOrder: (data, actions) => {
                return actions.order.create({

                    purchase_units: [
                    {
                        description: this.purchase.description,
                        amount: {
                            currency_code: this.purchase.amount.currency_code,
                            value: this.purchase.amount.value,
                        },
                    }
                    ]
                });
            },
            onApprove:  (data, actions) => {
            return actions.order.capture().then( detail => {
                    this.paypalService.setDetail(detail);
                    this.paypalService.setEstadoPago(true); // aviso cambio para mi observable estadoPago
                    this.guiMsjService.msjFormSubmit('paypalOK');
                });
            },
            onError: error => {
                console.log('ERROR PAYPAL PAGO', error);
                this.msjErrorProcesoPago();
                // tslint:disable-next-line:max-line-length
                // Swal.fire('ERROR AL QUERER PROCESAR SU PAGO', 'Corrobore que su tarjeta, esté activa para pagos internacionales', 'warning');
                // this.guiMsjService.msjFormSubmit('paypalError');
            }
        })
        .render( this.paypalElment.nativeElement );
    }

    // tslint:disable-next-line:variable-name
    setValorPaypal(description: string, currency_code: string, value: number): PaypalPurchaseUnit {
        return this. purchase = {
            description,
            amount: {
                currency_code,
                value,
            }
        };
    }

    setButtonStylePaypal(): PaypalButtonStyle {
        return this.button = {
            layout:  'vertical',
            color:   'gold',
            shape:   'pill',
            label:   'paypal'
        };
    }

    msjErrorProcesoPago() {
        this.paypalService.setErrorProcesarPago('no se pudo procesar pago, tarjeta rechazada...');
        Swal.fire({
            title: '<strong>Error al intentar procesar su pago, Paypal rechazo el pago..</strong>',
            icon: 'info',
            html:
           '<p><strong>Recomendamos los siguientes pasos, para una soluci&oacute;n</strong></p>' +
            '<ul>' +
            '<ol>' +
            '<li  style="text-align: left;">Ve a la p&aacute;gina web de PayPal</li>' +
            '<li  style="text-align: left;">Inicia sesi&oacute;n con tu usuario y contrase&ntilde;a, o crea uno</li>' +
            '<li  style="text-align: left;">Toca sobre &ldquo;Asociar Tarjeta&rdquo; en el men&uacute; superior de la pantalla</li>' +
            '<li  style="text-align: left;">Aparecer&aacute; una pantalla con datos a rellenar</li>' +
            '<li  style="text-align: left;">Rellena los datos para vincular una tarjeta </li>' +
            '<li  style="text-align: left;">Toca sobre el bot&oacute;n &ldquo;Asociar Tarjeta"&nbsp;</li>' +
            '<li  style="text-align: left;">Para finalizar debes confirmar tu tarjeta : Te consultara&nbsp; &iquest;Quieres utilizar tu cuenta de PayPal sin l&iacute;mites de gasto o retirada?</li>' +
            '<li  style="text-align: left;">Hace Click en "Confirme su tarjeta"</li>' +
            '<li  style="text-align: left;">Luego Click en en "Obtener C&oacute;digo"</li>' +
            '<li  style="text-align: left;">Paypal enviara un c&oacute;digo, que deber&iacute;a aparecer en el estado de cuenta de su tarjeta, en un plazo de 2 a 3 d&iacute;as h&aacute;biles.&nbsp;</li>' +
            '<li  style="text-align: left;">Regresa a Paypal carga el c&oacute;digo, e intenta tu pago nuevamente pagando con tu cuenta de PayPal y tarjeta asociada.</li>' +
            '<ol>' +
            '</ul>' ,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Gracias!',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonText:
              '<i class="fa fa-thumbs-down"></i>',
            cancelButtonAriaLabel: 'Thumbs down'
        });
    }

}




// SANDBOX JUANCA
// https://www.sandbox.paypal.com/mep/dashboard
// sb-yt2jg7149854@business.example.com
// v{..;y8H

// PARA VENDE
// sb-yt2jg7149854@business.example.com
// v{..;y8H

// PARA PAGAR
// sb-fug2e7147764@personal.example.com
// 1dV"g#p)

// ngOnInit(): void {

//     window.paypal

//     .Buttons({
//         style: {
//           layout:  'vertical',
//           color:   'blue',
//           shape:   'rect',
//           label:   'paypal'
//         },
//       createOrder: (data, actions) => {
//           return actions.order.create({
//             purchase_units: [
//                 {
//                   description: 'test juanca order angular',
//                   amount: {
//                     currency_code: 'USD',
//                     value: '1.00'
//                   },
//                 }
//               ]
//             });
//       },
//       onApprove:  (data, actions) => {
//               return actions.order.capture().then( detail => {
// 						        Swal.fire('PAGO EXITOSO', 'su pago fue procesado correctamente por Paypal', 'success');
//               alert('PAGO EXITOSO');
//           });
//       },
//       onError: error => {
// 				Swal.fire('ERROR!', 'se produjo un error en el proceso de pago - pago no procesado', 'warning');
//       }

//     })
//     .render( this.paypalElment.nativeElement );
//   }
