import { environment } from './../../../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { AccessibilityConfig, Image, ImageEvent } from '@ks89/angular-modal-gallery';
import { DomSanitizer } from '@angular/platform-browser';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';
const URL_BACKEND = environment.urlBackend;

@Component({
  selector: 'app-ofertas-gallery',
  templateUrl: './ofertas-gallery.component.html',
  styleUrls: ['./ofertas-gallery.component.css']
})
export class GalleryComponent implements OnInit  {

  @Input() imagenes: string[];
  // tslint:disable-next-line:variable-name
  @Input() id_usuario: string;

  imageIndex = 1;
  galleryId = 1;
  autoPlay = true;
  showArrows = true;
  showDots = true;

  imagesRect: Image[] = [];
  // tslint:disable-next-line:ban-types
  filesWithName: Object[] = [];
  
  flagLoaded = false;

  constructor(
    private sanitizer: DomSanitizer,
    private usuarioService: UsuarioService,
    private actividadesService: ActividadesService
  ) {}

  async ngOnInit() {
    await this.pushNombreAdjunto(this.imagenes);
    await this.cargarGallery();
    this.flagLoaded = true;
  }

  maximizarImagen( imagen: any) {
  }

  async cargarGallery() {
    let index = 1;
    const url = URL_BACKEND + '/media/imgUserOffer/';

    for (const t of this.imagenes) {
        const control: string = t;
        // Buscar el objeto en filesWithName que coincide con el adjunto actual
        const imagenConNombre: any = this.filesWithName.find((file: any) => {
          return file.adjunto === t;
      });
        let descripcion = ''; // Inicializar la descripción

        if (imagenConNombre) {
            descripcion = imagenConNombre.nombre; // Usar el nombre si se encontró la coincidencia
        } else {
            descripcion = 'Nombre documento desconocido'; // Otra lógica si no se encontró el nombre
        }

        // Crear el objeto de imagen con la descripción
        const img = new Image(
            index,
            {
                img: this.transformTrustUrlr(url + this.id_usuario + '/' + t),
                description: descripcion,
            },
        );

        this.imagesRect.push(img);
        index++;
    }
  }

  transformTrustUrlr(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  addRandomImage() {
    const imageToCopy: Image = this.imagesRect[Math.floor(Math.random() * this.imagesRect.length)];
    const newImage: Image = new Image(this.imagesRect.length - 1 + 1, imageToCopy.modal, imageToCopy.plain);
    this.imagesRect = [...this.imagesRect, newImage];
  }

  onChangeAutoPlay() {
    this.autoPlay = !this.autoPlay;
  }

  onChangeShowArrows() {
    this.showArrows = !this.showArrows;
  }

  onChangeShowDots() {
    this.showDots = !this.showDots;
  }

  // output evets
  onShow(event: ImageEvent) {
  }

  onFirstImage(event: ImageEvent) {
  }

  onLastImage(event: ImageEvent) {
  }

  async pushNombreAdjunto(files: string[]) {
    console.log('files', files);
    try {
        if (files.length > 0) {
            for (const adjunto of files) {
                try {
                    let nombreUsuarios: string | null = null;
                    let nombreAdjuntoActividad: string | null = null;

                    // Capturar errores en cada petición asíncrona por separado
                    try {
                        nombreUsuarios = await this.usuarioService.getNombreAdjunto(adjunto); 
                    } catch (error) {
                        console.error(`Error obteniendo nombre de usuario para el adjunto ${adjunto}:`, error);
                    }

                    try {
                        nombreAdjuntoActividad = await this.actividadesService.getNombreAdjunto(adjunto);
                    } catch (error) {
                        console.error(`Error obteniendo nombre de actividad para el adjunto ${adjunto}:`, error);
                    }

                    // Lógica para agregar al array filesWithName
                    if (nombreAdjuntoActividad) {
                        this.filesWithName.push({ nombre: nombreAdjuntoActividad, adjunto });
                    } else if (nombreUsuarios) {
                        this.filesWithName.push({ nombre: nombreUsuarios, adjunto });
                    } else {
                        this.filesWithName.push({ nombre: 'Nombre documentos desconocido', adjunto });
                    }

                } catch (error) {
                    console.error(`Error procesando el adjunto ${adjunto}:`, error);
                }
            }
        }
    } catch (error) {
        console.error('Error en pushNombreAdjunto:', error);
    }
}

}



// imagesRect: Image[] = [
//     new Image( 0, { img: '/assets/img/alumno.jpg', description: 'Description 1'}),
//     new Image( 0, { img: '/assets/img/alumno.jpg', description: 'Description 1'}),
//     new Image( 0, { img: '/assets/img/alumno.jpg', description: 'Description 1'}),
//     new Image( 0, { img: '/assets/img/alumno.jpg', description: 'Description 1'}),
//     new Image( 0, { img: '/assets/img/alumno.jpg', description: 'Description 1'}),
//     new Image( 0, { img: '/assets/img/app2.png', description: 'Description 1'})
// ];
