import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { MateriasBoletinService } from 'src/app/services/cursos/materias-boletin.service';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';
import { Boletin } from 'src/app/interfaces/interface-bakend/cursos/boletin.interface';
import { BoletinesService } from 'src/app/services/cursos/boletines.service';

@Component({
  selector: 'app-boletines-listado',
  templateUrl: './boletines-listado.component.html',
  styleUrls: ['./boletines-listado.component.scss']
})
export class BoletinesListadoComponent implements OnInit, AfterViewInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
    [
      'nombre', '_id'
    ];
  dataSource: any;
  materiasBoletin: Boletin[];
  /* Fin Data Table*/

  flagLoaded = false;

  constructor(
    private boletinesService: BoletinesService,
    private router: Router,
    private guiMsjService: GuiMsjService
  ) { }

  ngOnInit(): void {
    this.loadDataPage();
    this.getMateriaBoletin();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
  }

  async updateBoletin(boletin: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    await this.boletinesService.getBoletinFindOne(boletin);
    this.router.navigateByUrl('/boletin-nombre-modificacion');
  }

  async getMateriaBoletin() {
    //const materia: MateriaBoletin = this.materiasBoletinService.getMateriaSeleccionada();
  }

  async loadDataTable() {
    this.boletinesService.getBoletines().then((boletines: Boletin[]) => {
      this.dataSource = new MatTableDataSource(boletines);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async loadDataPage() {
    await this.loadDataTable();
    return;
  }
}
