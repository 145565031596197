<h2 *ngIf="!flagAlumnoConCursos && this.flagLoaded" class="animate__animated animate__fadeIn">NO TIENES CURSOS DISPONIBLES</h2>

<div class="animate__animated animate__fadeIn">

    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/dashboard">Inicio</a></li>
                    <!-- <li>Mi Cursos</li> -->
                </ul>
                <!-- <h2>Mi Cursos</h2> -->
            </div>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>

    <div class="profile-area pb-70">
        <div class="container">
            <div class="profile-courses-quizzes">
                <ngx-tabset>
                    <ngx-tab tabTitle="Mis Cursos">
                        <ngx-tabset>
                            <ngx-tab tabTitle="Cursos Disponibles">
                                <ngx-tabset>
                                    <ngx-tab tabTitle="Todos">
                                        <app-loading *ngIf="!flagAlumnoConCursos && !this.flagLoaded" class="m-5"></app-loading>
                                        <!-- PAGINATOR  -->
                                        <mat-paginator [length]="cursosAlumno.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getPaginatorData($event)" aria-label="Select page" showFirstLastButtons="false">
                                        </mat-paginator>

                                        <div class="row">
                                            <div class="col-lg-4 col-md-6" *ngFor="let curso of  (cursosAlumno |
            slice: lowValue : highValue)" [newspaper]="curso">
                                                <app-curso-tarjeta [img]="true" [video]="false" [price]="false" [curso]="curso.curso" [flagCursoAlumno]="true"></app-curso-tarjeta>
                                            </div>
                                        </div>
                                    </ngx-tab>

                                </ngx-tabset>
                            </ngx-tab>
                        </ngx-tabset>
                    </ngx-tab>

                    <!-- EL PROGRESO NO ESTA TERMINADO 
                    <ngx-tab tabTitle="Progreso">
                        <ngx-tabset>
                            <ngx-tab tabTitle="Todos">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Curso</th>
                                                <th>Materia</th>
                                                <th>Fecha</th>
                                                <th>Progreso</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><a routerLink="/curso-habilitado-alumno">Instructor Ski Nivel 1</a></td>
                                                <td><a routerLink="/curso-habilitado-alumno">Metodología 1</a></td>
                                                <td>Mayo 20, 2020</td>
                                                <td><span class="progress">En progreso</span></td>

                                            </tr>
                                            <tr>
                                                <td><a routerLink="/curso-habilitado-alumno">Instructor Ski Nivel 1</a></td>
                                                <td>
                                                    <a routerLink="/curso-habilitado-alumno"></a>Medioambiente y Seguridad 1</td>
                                                <td>Mayo 21, 2020</td>
                                                <td><span class="progress pending">Pendiente</span></td>

                                            </tr>
                                            <tr>
                                                <td><a routerLink="/curso-habilitado-alumno">Instructor Snowboard Nivel 1</a></td>
                                                <td><a routerLink="/single-courses-1">Historia 1</a></td>
                                                <td>Mayo 22, 2020</td>
                                                <td><span class="progress completed">Completado</span></td>

                                            </tr>
                                            <tr>
                                                <td><a routerLink="/curso-habilitado-alumno">Instructor Snowboard Nivel 1</a></td>
                                                <td><a routerLink="/single-courses-1">Snowboard 1</a></td>
                                                <td>Mayo 22, 2020</td>
                                                <td><span class="progress passed">Aprobado</span></td>

                                            </tr>
                                            <tr>
                                                <td><a routerLink="/single-courses-1">Pister Nivel 1</a></td>
                                                <td>Seguridad en pistas</td>
                                                <td>May 20, 2020</td>
                                                <td><span class="progress failed">Desaprobado</span></td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </ngx-tab>
                            <ngx-tab tabTitle="Finalizados">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Curso</th>
                                                <th>Materia</th>
                                                <th>Fecha</th>
                                                <th>Progreso</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <tr>
                                                    <td><a routerLink="/curso-habilitado-alumno">Instructor Snowboard Nivel 1</a></td>
                                                    <td><a routerLink="/single-courses-1">Historia 1</a></td>
                                                    <td>Mayo 22, 2020</td>
                                                    <td><span class="progress completed">Completado</span></td>

                                                </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </ngx-tab>
                            <ngx-tab tabTitle="Aprobados">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Curso</th>
                                                <th>Materia</th>
                                                <th>Fecha</th>
                                                <th>Progreso</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <tr>
                                                    <td><a routerLink="/curso-habilitado-alumno">Instructor Snowboard Nivel 1</a></td>
                                                    <td><a routerLink="/single-courses-1">Snowboard 1</a></td>
                                                    <td>Mayo 22, 2020</td>
                                                    <td><span class="progress passed">Aprobado</span></td>

                                                </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </ngx-tab>
                            <ngx-tab tabTitle="Desaprobados">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Curso</th>
                                                <th>Materia</th>
                                                <th>Fecha</th>
                                                <th>Progreso</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><a routerLink="/single-courses-1">Pister Nivel 1</a></td>
                                                <td>Seguridad en pistas</td>
                                                <td>May 20, 2020</td>
                                                <td><span class="progress failed">Desaprobado</span></td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </ngx-tab>
                        </ngx-tabset>
                    </ngx-tab> -->
                </ngx-tabset>
            </div>
        </div>
    </div>

</div>