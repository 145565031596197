<div class="order-details">
    <h3 class="title">{{ idioma | translate: "oferta-laboral.OFERTA-LABORAL" | async}} {{ this.nombrePais }}</h3>

    <table class="table table-bordered table-responsive">
        <thead>
            <tr>
                <th scope="col">{{ idioma | translate: "oferta-laboral.ACTIVIDAD" | async}} {{this.actividad.nombre}}</th>
                <th scope="col">{{ idioma | translate: "pagos.TOTAL" | async}}</th>
            </tr>
        </thead>
        <tbody>

            <tr>

                <td>
                    <span><b>{{ idioma | translate: "oferta-laboral.COSTO-ADMINISTRATIVO" | async}}</b></span>
                </td>
                <td>
                    <span><b>USD {{ actividad.costoAdministrativo }}</b></span>
                </td>

            </tr>

            <tr>
                <td>
                    <span>{{ idioma | translate: "oferta-laboral.COSTO-POST-APROBACION" | async}}</span>
                </td>

                <td>
                    <span>USD {{ actividad.costoPostAprobacion }}</span>
                </td>
            </tr>

            <tr>
            </tr>

            <tr>
                <td>
                    <span><b>{{ idioma | translate: "oferta-laboral.TOTAL-SOLICITUD" | async}}</b></span>
                </td>
                <td>
                    <span><b>USD $ {{ costoTotal }}</b></span>
                </td>
            </tr>

            <div class="alert alert-warning m-3" width="100%" role="alert">
                {{ idioma | translate: "oferta-laboral.MENSAJE-PAGO-PARCIAL" | async}}
            </div>

        </tbody>
    </table>
</div>