import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Examen } from '../../../../interfaces/interface-bakend/cursos/examen.interface';
import { Router } from '@angular/router';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-evaluacion-alta',
  templateUrl: './evaluacion-alta.component.html',
  styleUrls: ['./evaluacion-alta.component.scss']
})
export class EvaluacionAltaComponent implements OnInit {

  tabAsignarActivated = false;
  tabPreguntasActivated = false;
  tabRespuestasActivated = false;
  moveToTab = observable;

  constructor(
    private router: Router,
    private cursosService: CursosService,
    private guiMsjService: GuiMsjService,
  ) {
  }

  ngOnInit(): void {
  }

  moveToSelectedTab(tabName: string) {
      for (let i = 0; i < document.querySelectorAll('.mat-tab-label-content').length; i++) {
          if ((<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).innerText === tabName)
          {
              (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
          }
      }
  }

}
