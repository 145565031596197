<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li>Pre Inscripciones</li>
            </ul>
            <h2>Pre Inscripciones</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>


<div class="profile-area pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <br>
            <h3>Aquí encontrará el estado de las pre inscripciones</h3>
            <br>
            <app-loading *ngIf="!flagLoaded"> </app-loading>

            <form [formGroup]="forma" *ngIf="flagLoaded">
                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <h4>Pre escripción</h4>
                        <mat-form-field appearance="fill">
                            <mat-label>Seleccione...</mat-label>
                            <mat-select formControlName="actividades" (selectionChange)="ngOnChangesActividad($event.value)">
                                <mat-option *ngFor="let actividad of this.actividades" [value]="actividad._id">{{ actividad.nombre }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <h4>Delegación</h4>
                        <mat-form-field appearance="fill">
                            <mat-label>Seleccione...</mat-label>
                            <mat-select formControlName="delegaciones" multiple>
                                <mat-option *ngFor="let delegacion of this.delegaciones" [value]="delegacion._id">{{ delegacion.concepto }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <h4>Disciplina</h4>
                        <mat-form-field appearance="fill">
                            <mat-label>Seleccione...</mat-label>
                            <mat-select formControlName="disciplinas" multiple>
                                <mat-option *ngFor="let disciplina of this.disciplinas" [value]="disciplina._id">{{ disciplina.concepto }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <h4>Módulo</h4>
                        <mat-form-field appearance="fill">
                            <mat-label>Seleccione...</mat-label>
                            <mat-select formControlName="cursos" multiple>
                                <!-- <mat-select-trigger>
                                    <span *ngIf="(forma.value.cursos?.length || 0) > 1" class="example-additional-selection">
                                  (+{{(forma.value.cursos?.length || 0) - 1}} {{forma.value.cursos?.length === 2 ? 'other' : 'others'}})
                                </span>
                                </mat-select-trigger>                                 -->
                                <mat-option *ngFor="let curso of this.cursos" [value]="curso._id">{{ curso.nombre }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <h4>Estados</h4>
                        <mat-form-field appearance="fill">
                            <mat-label>Seleccione...</mat-label>
                            <mat-select formControlName="estados" multiple>
                                <mat-option *ngFor="let estado of this.estados" [value]="estado.number">{{ estado.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <br>
                <section>
                    <div class="example-button-row">
                        <button mat-raised-button color="warn" routerLink="/">Salir</button>
                        <button mat-raised-button color="primary" [disabled]="!forma.valid" (click)="cargarGrilla(forma)">Aceptar</button>
                    </div>
                </section>
            </form>
        </div>
        <hr>
        <div>
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex..">
            </mat-form-field>
            <app-loading *ngIf="flagLoadedTable" class="m-5"></app-loading><br>
            <app-loading *ngIf="flagLoadedModal" class="m-5"></app-loading><br>

            <div class="mat-elevation-z8" #TABLE>

                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Column -->
                    <ng-container matColumnDef="actividadNombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Pre Incripción </th>
                        <td mat-cell *matCellDef="let element"> {{element.actividadNombre}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="cursoNombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Módulo </th>
                        <td mat-cell *matCellDef="let element"> {{element.cursoNombre}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="delegacionNombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delgación </th>
                        <td mat-cell *matCellDef="let element"> {{element.delegacionNombre }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="disciplinaNombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Disciplina </th>
                        <td mat-cell *matCellDef="let element"> {{element.disciplinaNombre }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="identificacion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Identificación </th>
                        <td mat-cell *matCellDef="let element"> {{element.identificacion}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="solicitante">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Solicitante </th>
                        <td mat-cell *matCellDef="let element"> {{ element.solicitante }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="tramite">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Solicitud Nro.</th>
                        <td mat-cell *matCellDef="let element"> {{ element.tramite }} </td>
                    </ng-container>
                    <!-- Column -->

                    <ng-container matColumnDef="estado">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado</th>
                        <td mat-cell *matCellDef="let element"> {{ element.estado | estadosPipe}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="data">
                        <th width="5%" mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td width="5%" mat-cell *matCellDef="let element">
                            <button id="1" title="eye" *ngIf=!flagLoadedModal (click)="verSolicitud(element)" type="button" class="btn btn-warning"><i class="fas fa-eye"></i></button>
                            <button id="2" title="eye" *ngIf=flagLoadedModal disabled type="button" class="btn btn-warning"><i class="fas fa-eye"></i></button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200, 500, 1000]"></mat-paginator>
            </div>
            <br>
            <div mat-dialog-actions align="end">
                <button mat-raised-button color="primary" (click)="exportTOExcel()">Export as Excel</button>
            </div>
        </div>
    </div>
</div>