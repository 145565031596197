import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {

  idioma: Subscription;

  constructor(private translatorService: Translators, private perfilesService:PerfilesService, private router: Router) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });
  }

  ngOnInit(): void {
    // this.perfilesService.allowedByUserLogon(this.router.url);
  }

}
