import { Component, OnInit } from '@angular/core';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { Curso } from '../../../../interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-cursos-perfil-alumno',
  templateUrl: './cursos-perfil-alumno.component.html',
  styleUrls: ['./cursos-perfil-alumno.component.scss']
})
export class CursosPerfilAlumnoComponent implements OnInit {

  // MatPaginator Output
  pageEvent: any;

  // MatPaginator Inputs
  length = 0;
  pageSize = 3;
  pageSizeOptions: number[] = [3, 6, 9, 12, 24, 44];

  lowValue = 0;
  highValue = 3; // default view

  cursosAlumno: any[] = [];
  flagAlumnoConCursos: boolean;
  flagLoaded = false;

  // Profesores que dan el curso
  cursoProfesores: any[] = [];

  constructor(
    private cursosService: CursosService,
    private authservice: AuthService
  ) { }

  ngOnInit(): void {
    this.controlCursosAlumnos();
  }

  async getCursosAlumno(){

    // 1- controlo que el usuario sea un alumno
    const flagAlumno =  await this.cursosService.getUsuarioEsAlumno();

    // 2- busco cursos, si es alumno
    if (flagAlumno) {
      this.cursosAlumno =  await this.cursosService.getCursosAlumno((await this.cursosService.getByUserLogon())._id);
      await this.eliminarCursosRepetidos();
      console.log(this.cursosAlumno );
      this.flagAlumnoConCursos = true;
      this.flagLoaded = true;
    }else {
      this.flagAlumnoConCursos = false;
      this.flagLoaded = true;
    }
  }

  async eliminarCursosRepetidos() {
    const cursoMap = new Map<string, any>();

    this.cursosAlumno.forEach(element => {
      if (!cursoMap.has(element.curso._id)) {
        cursoMap.set(element.curso._id, element);
      }
    });

    this.cursosAlumno= Array.from(cursoMap.values());
    console.log(this.cursosAlumno);
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  async controlCursosAlumnos() {
    await this.getCursosAlumno();
  }

}
