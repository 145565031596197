<div *ngIf="this.empresa === 'AADIDESS'" >

    <div class="row">
        <div class="container">
            <table style="border: none;width:17.0cm;border-collapse:collapse;">
                <tbody>
                    <tr>
                        <td style="width: 17cm;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; T&eacute;rminos y Condiciones&nbsp;</span></strong></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:windowtext;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:windowtext;">T&Eacute;RMINOS Y CONDICIONES DEL SITIO WEB&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;">En el presente documento se establecen las <strong>&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;&nbsp;</strong>que regulan el funcionamiento de este Sitio web y la compra de productos/servicios a trav&eacute;s de <strong> central@aadidess.com</strong>. El uso del Sitio web constituye que le sea de aplicaci&oacute;n la aceptaci&oacute;n de estos T&eacute;rminos y Condiciones. No obstante, si tiene alguna duda o consulta sobre ellas puede contactar con nuestro servicio de Atenci&oacute;n al Cliente a trav&eacute;s de:</span></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:maroon;">e-mail de contacto:</span></strong><span style="font-size:13px;">&nbsp;</span><a href="mailto:central@aadidess.com"><span style="font-size:13px;">central@aadidess.com</span></a></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
            <ol style="list-style-type: decimal;margin-left:26px;">
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Informaci&oacute;n general de contacto&nbsp;</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Objeto&nbsp;</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Producto y servicio</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Acceso de personas usuarias</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Normas de uso del Sitio web</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Propiedad intelectual e industrial</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Notificaci&oacute;n de infracciones</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Responsabilidad y Seguridad de sus transacciones</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Atenci&oacute;n al cliente / Servicios de mediaci&oacute;n&nbsp;</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Responsabilidad&nbsp;</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Responsabilidad y limitaci&oacute;n por enlaces a otras p&aacute;ginas web</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Publicidad</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Protecci&oacute;n de datos personales&nbsp;</span></strong></li>
                <li><strong><span style="font-size:13px;color:maroon;">Quejas y reclamaciones</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Miscel&aacute;nea&nbsp;</span></strong></li>
            </ol>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:windowtext;">&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:windowtext;">&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:windowtext;">&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:windowtext;">&nbsp;</span></strong></p>
            <ol style="margin-bottom:0cm;list-style-type: decimal;margin-left:7.850000000000001px;">

                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Informaci&oacute;n general de contacto&nbsp;</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;">De conformidad con la legislaci&oacute;n aplicable, se informa que la titularidad de este Sitio web, <strong> central@aadidess.com</strong>, (en adelante Sitio web) la ostenta: <strong>AADIDESS LLC,</strong><strong>&nbsp;[</strong>de ahora en&nbsp;adelante <strong>AADIDESS]</strong>   </span>
                    <span style="font-size:13px;line-height:115%;color:windowtext;"> </span><span style="font-size:13px;line-height:115%;">&nbsp;y cuyos datos de contacto son:</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-left:22.4pt;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:maroon;">Direcci&oacute;n:</span></strong><span style="font-size:13px;line-height:115%;color:maroon;">&nbsp;</span><span style="font-size:13px;line-height:115%;">Sede Central Morales 483 PB (8400) San Carlos de Bariloche<br>&nbsp;</span><strong><span style="font-size:13px;line-height:115%;color:maroon;">Email de contacto:</span></strong>
                    <span style="font-size:13px;line-height:115%;color:maroon;">&nbsp;</span><span style="font-size:13px;line-height:115%;">central@aadidess.com</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-left:22.4pt;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;">&nbsp;</span></p>
                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Objeto&nbsp;</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">En el presente documento se establecen los <strong>&ldquo;T&eacute;rminos y Condiciones&rdquo;</strong> que regulan el acceso, registro y uso del Sitio web de <strong>AADIDESS</strong> y que ser&aacute;n de aplicaci&oacute;n por parte de las personas usuarias del Sitio web, (en adelante &ldquo;el Usuario&rdquo;).&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;line-height:115%;">se reserva en todo momento la posibilidad de modificar unilateralmente los presentes <strong>&ldquo;T&eacute;rminos y Condiciones&rdquo;.&nbsp;</strong>No obstante, ser&aacute;n aplicables a la Compra aquellas que el Usuario hubiere aceptado en el momento de realizarla.</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;">Las presentes <strong>&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;</strong> incluyen las <strong>Condiciones Generales de uso</strong> del Sitio web y se completar&aacute;n con las disposiciones de la <strong>Pol&iacute;tica de Privacidad</strong> y la <strong>Pol&iacute;tica de Cookies</strong>.</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Toda persona usuaria debe leer atentamente estas condiciones y, en cualquier momento, podr&aacute; almacenarlas o imprimir las presentes Condiciones.</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;">&nbsp;</span></p>
                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Producto y Servicio</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">A efectos de estos <strong>&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;</strong> se entiende que la actividad que <strong>AADIDESS&nbsp;</strong>desarrolla a trav&eacute;s del Sitio web comprende:</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Plataforma educativa, tramitaci&oacute;n y evaluaci&oacute;n de pre inscripciones.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Acceso de personas usuarias</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El simple acceso al Sitio web, atribuye a quien lo realiza la condici&oacute;n de persona usuaria.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El uso de servicios ofrecidos en la p&aacute;gina web es libre para las personas usuarias, no conllevando ninguna comisi&oacute;n ni coste de ning&uacute;n tipo en el acceso a la p&aacute;gina web, salvo en lo relativo al coste de la conexi&oacute;n a trav&eacute;s de la red de telecomunicaciones suministrada por el proveedor de acceso contratado por la persona usuaria, que ser&aacute; a cargo de &eacute;ste.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El acceso a trav&eacute;s de aplicaciones de terceros (por ejemplo, Facebook) implica que la seguridad en el acceso por esta v&iacute;a es responsabilidad de dichas entidades. Asimismo, el Sitio web declina cualquier responsabilidad directa o indirecta por la ca&iacute;da de los sistemas o indisponibilidad de la web o cualquier situaci&oacute;n similar.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Normas de uso del Sitio web&nbsp;</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">La persona usuaria ser&aacute; responsable de la totalidad de sus contenidos publicados y de su veracidad.&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Mediante el uso de este Sitio web, la persona usuaria acepta que la mayor parte de las comunicaciones con <strong>AADIDESS&nbsp;</strong>sean electr&oacute;nicas (correo electr&oacute;nico o avisos publicados en el Sitio web).</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">A efectos contractuales, el Usuario consiente en usar este medio electr&oacute;nico de comunicaci&oacute;n y reconoce que todo contrato, notificaci&oacute;n, informaci&oacute;n y dem&aacute;s comunicaciones que <strong>AADIDESS&nbsp;</strong>env&iacute;e de forma electr&oacute;nica cumplen con los requisitos legales de ser por escrito. Esta condici&oacute;n no afectar&aacute; a los derechos reconocidos por ley al Usuario.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El Usuario puede enviar notificaciones y/o comunicarse con <strong>AADIDESS&nbsp;</strong>a trav&eacute;s de los datos de contacto que en estas Condiciones se facilitan y, en su caso, a trav&eacute;s de los espacios de contacto del Sitio web. Igualmente, salvo que se estipule lo contrario, <strong>AADIDESS&nbsp;</strong>puede contactar y/o notificar al Usuario en su correo electr&oacute;nico o en la direcci&oacute;n postal facilitada.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">La persona usuaria se compromete a hacer un uso razonable y razonado de los servicios del Sitio web y a cumplir los &ldquo;<strong>T&Eacute;RMINOS Y CONDICIONES</strong>&rdquo;, reserv&aacute;ndose <strong>AADIDESS&nbsp;</strong>el derecho de limitarlo si as&iacute; lo estimara conveniente.&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Queda terminantemente prohibido:</span></p>
                <ul style="list-style-type: disc;margin-left:26px;">
                    <li><span style="font-size:13px;">Utilizar el nombre de <strong>AADIDESS&nbsp;</strong>con fines profesionales o lucrativos directa o indirectamente.</span></li>
                    <li><span style="font-size:13px;">Utilizar el Sitio web <strong>AADIDESS&nbsp;</strong>para fines que no sean la adquisici&oacute;n de productos y/o servicios</span></li>
                    <li><span style="font-size:13px;">Realizar compras falsas o fraudulentas. Si razonablemente se pudiera considerar que se ha hecho una compra de esta &iacute;ndole, podr&iacute;a ser anulada y se informar&iacute;a a las autoridades competentes.</span></li>
                    <li><span style="font-size:13px;">La publicaci&oacute;n de contenido ofensivo, de cualquier clase, sexista, racista, difamatorio y/o contrario a la legislaci&oacute;n vigente.</span></li>
                    <li><span style="font-size:13px;">Difamar, abusar, acosar, molestar, amenazar o infringir los derechos legales, como los derechos de privacidad o publicidad, de otros.</span></li>
                    <li><span style="font-size:13px;">Utilizar la p&aacute;gina web para fines de promoci&oacute;n no autorizados expresamente por el equipo <strong>AADIDESS&nbsp;</strong>o la difusi&oacute;n de contenido publicitario no autorizado.</span></li>
                    <li><span style="font-size:13px;">Suplantar la identidad, con las consecuencias legales que de ello se derivan.</span></li>
                    <li><span style="font-size:13px;">Difundir, publicar o solicitar informaci&oacute;n que promueva la realizaci&oacute;n de ning&uacute;n acto il&iacute;cito en su jurisdicci&oacute;n, incluyendo, pero no limitando a: fraudes electr&oacute;nicos, SPAM, Phishing, Spoofing, Forgery y cualquier otro tipo de conducta que sea considerada un acto ilegal.</span></li>
                    <li><span style="font-size:13px;">Realizar cualquier uso fraudulento del Sitio web.</span></li>
                </ul>
                <p style='margin-top:12.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:36.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Propiedad intelectual e industrial</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El Sitio web est&aacute; protegido por derechos de propiedad intelectual e industrial. Con car&aacute;cter enunciativo, que no limitativo: los contenidos, dise&ntilde;o gr&aacute;fico, c&oacute;digo fuente, logos, textos, gr&aacute;ficos, ilustraciones, fotograf&iacute;as y dem&aacute;s elementos que aparecen en el Sitio web. Por ello, la persona usuaria reconoce que la reproducci&oacute;n, distribuci&oacute;n, comercializaci&oacute;n, transformaci&oacute;n y en general, cualquier forma de explotaci&oacute;n, por cualquier procedimiento, de todo o parte del Sitio web constituye una infracci&oacute;n de derechos de propiedad intelectual e industrial de <strong>AADIDESS&nbsp;</strong>o del titular de los mismos, salvo que el usuario o la usuaria hubiera obtenido la previa autorizaci&oacute;n expresa.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Cualquier uso ajeno a <strong>AADIDESS</strong>, incluida la reproducci&oacute;n, modificaci&oacute;n, distribuci&oacute;n, transmisi&oacute;n, reedici&oacute;n, arreglos o representaci&oacute;n de cualesquiera elementos del mismo est&aacute; estrictamente prohibido salvo consentimiento expreso escrito de <strong>AADIDESS</strong>.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">La persona usuaria no podr&aacute; modificar o utilizar dicha propiedad intelectual e industrial de forma que su divulgaci&oacute;n suponga un perjuicio para <strong>AADIDESS</strong>.&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;">reclamar&aacute; por los da&ntilde;os y perjuicios derivados del uso indebido por parte de terceras personas de su propiedad intelectual e industrial.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;">no concede ninguna licencia o autorizaci&oacute;n de uso de ninguna clase sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otro derecho o propiedad relacionado en el Sitio web, los servicios o los contenidos.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Notificaci&oacute;n de infracciones</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">En caso de que la persona usuaria infrinja derechos de propiedad intelectual y/o industrial o cualquier otro derecho de terceras personas, ser&aacute; apercibido por <strong>AADIDESS&nbsp;</strong>en el momento en que &eacute;sta tenga conocimiento de tales infracciones para que cese en la publicaci&oacute;n de los contenidos infractores. En tal caso, la persona usuaria responder&aacute; por tales infracciones.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Si cualquier persona o entidad considera que los contenidos publicados en el Sitio web infringen sus derechos al honor, a la intimidad, a la imagen o cualquier derecho podr&aacute; comunicarlo a <strong>AADIDESS&nbsp;</strong>por cualquiera de las v&iacute;as de contacto que figuran en el Sitio web con el asunto &ldquo;Vulneraci&oacute;n de derechos&rdquo;, solicitar su retirada y aportar la siguiente informaci&oacute;n:</span></p>
                <ol style="list-style-type: decimal;margin-left:26px;">
                    <li><span style="font-size:13px;">identificaci&oacute;n de los derechos protegidos por la legislaci&oacute;n vigente al respecto que se consideran violados,</span></li>
                    <li><span style="font-size:13px;">identificaci&oacute;n de los contenidos que presuntamente violan los derechos en cuesti&oacute;n con informaci&oacute;n razonablemente suficiente para permitir a <strong>AADIDESS&nbsp;</strong>ubicar dicho material en el Sitio web,</span></li>
                    <li><span style="font-size:13px;">informaci&oacute;n de los datos de contacto (direcci&oacute;n f&iacute;sica o electr&oacute;nica y n&uacute;mero de tel&eacute;fono) para que <strong>AADIDESS&nbsp;</strong>pueda comunicarse con dicha persona o entidad,</span></li>
                    <li><span style="font-size:13px;">una declaraci&oacute;n en la que dicha persona o entidad afirme que la informaci&oacute;n incluida en la notificaci&oacute;n es veraz y exacta y que es titular de los derechos presuntamente vulnerados o que est&aacute; autorizado o autorizada a actuar en nombre de &eacute;ste y,&nbsp;</span></li>
                    <li><span style="font-size:13px;">copia del del documento que acredite su identidad o pasaporte.</span></li>
                </ol>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;">atender&aacute; la petici&oacute;n y, si considera que la persona o entidad tiene motivos o causas justificadas para solicitar la retirada de tales contenidos, proceder&aacute; a su eliminaci&oacute;n inmediata del Sitio web de forma que no sean accesibles por las personas usuarias ni siquiera desde la memoria cach&eacute;.</span></p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:17.85pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:maroon;">&nbsp;</span></strong></p>
        
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:maroon;">Atenci&oacute;n al cliente / Servicios de mediaci&oacute;n&nbsp;</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:black;">Para cualquier informaci&oacute;n adicional, consulta o reclamaci&oacute;n, puede ponerse en contacto con el Servicio de Atenci&oacute;n al Cliente de&nbsp;</span><strong><span style="font-size:13px;line-height:115%;color:black;">AADIDESS&nbsp;</span></strong>
                        <span style="font-size:13px;line-height:115%;color:black;">a trav&eacute;s de:</span>
                    </p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;"><br>&nbsp;</span><strong><span style="font-size:13px;line-height:115%;color:maroon;">Email de contacto:</span></strong><span style="font-size:13px;line-height:115%;color:maroon;">&nbsp;</span>
                        <span style="font-size:13px;line-height:115%;">central@aadidess.com</span>
                    </p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:black;">&nbsp;</span></p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;color:maroon;">Responsabilidad&nbsp;</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:maroon;">13.1 Capacidad</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:black;">Con anterioridad a la realizaci&oacute;n de su Pedido, el Usuario declara contar con capacidad legal plena (mayor de 18 a&ntilde;os) para obligarse en virtud de los presentes&nbsp;</span><strong><span style="font-size:13px;line-height:115%;color:black;">&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;</span></strong>
                        <span style="font-size:13px;line-height:115%;color:black;">.</span>
                    </p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:maroon;">16.2 Limitaci&oacute;n de responsabilidad</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:black;">Salvo disposici&oacute;n legal en sentido contrario,&nbsp;</span><strong><span style="font-size:13px;color:black;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;color:black;">no aceptar&aacute; ninguna responsabilidad por las siguientes p&eacute;rdidas, con independencia de su origen:</span></p>
                    <ul style="list-style-type: disc;margin-left:26px;">
                        <li><span style="font-size:13px;color:black;">cualesquiera p&eacute;rdidas que no fueran atribuibles a incumplimiento alguno por su parte;&nbsp;&nbsp;</span></li>
                        <li><span style="font-size:13px;color:black;">p&eacute;rdidas empresariales (incluyendo lucro cesante, de ingresos, de contratos, de ahorros previstos, de datos, p&eacute;rdida del fondo de comercio o gastos innecesarios incurridos); o de&nbsp;</span></li>
                        <li><span style="font-size:13px;color:black;">toda otra p&eacute;rdida indirecta que no fuera razonablemente previsible por ambas partes en el momento en que se formaliz&oacute; el contrato de compraventa de los productos entre ambas partes.&nbsp;</span></li>
                    </ul>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:black;">Igualmente,&nbsp;</span><strong><span style="font-size:13px;color:black;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;color:black;">tambi&eacute;n limita su responsabilidad en cuanto a los siguientes casos:</span></p>
                    <ul style="list-style-type: disc;margin-left:26px;">
                        <li><strong><span style="font-size:13px;color:black;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;color:black;">aplica todas las medidas concernientes a proporcionar una visualizaci&oacute;n fiel del producto en el Sitio web, sin embargo, no se responsabiliza por las m&iacute;nimas diferencias o inexactitudes que puedan existir debido a falta de resoluci&oacute;n de la pantalla, o problemas del navegador que se utilice u otros de esta &iacute;ndole.&nbsp; &nbsp; &nbsp; &nbsp;</span></li>
                        <li><span style="font-size:13px;color:black;">Fallos t&eacute;cnicos que por causas fortuitas o de otra &iacute;ndole, impidan un normal funcionamiento del servicio a trav&eacute;s de internet. Falta de disponibilidad del Sitio web por razones de mantenimiento u otras, que impida disponer del servicio.&nbsp;</span><strong><span style="font-size:13px;color:black;">AADIDESS&nbsp;</span></strong>
                            <span style="font-size:13px;color:black;">pone todos los medios a su alcance a efectos de llevar a cabo el proceso de compra y pago, no obstante, se exime de responsabilidad por causas que no le sean imputables, caso fortuito o fuerza mayor. &nbsp; &nbsp;</span>
                        </li>
                        <li><strong><span style="font-size:13px;color:black;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;color:black;">no ser&aacute; responsable de los da&ntilde;os derivados de un mal uso del Sitio web, ni de cualquier actuaci&oacute;n fraudulenta realizada en base a la informaci&oacute;n que en el mismo se facilita.</span></li>
                        <li><strong><span style="font-size:13px;color:black;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;color:black;">no se hace responsable de los posibles errores de seguridad o desconexiones que se puedan producir ni de los posibles da&ntilde;os que puedan causarse al sistema inform&aacute;tico de la persona usuaria (hardware y software), los ficheros o documentos almacenados en el mismo, como consecuencia de la presencia de virus en el ordenador del usuario o de la usuaria, utilizando para la conexi&oacute;n al Sitio web, o de un mal funcionamiento del navegador o del uso de versiones no actualizadas del mismo.</span></li>
                    </ul>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:black;">En general,&nbsp;</span><strong><span style="font-size:13px;color:black;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;color:black;">no se responsabilizar&aacute; por ning&uacute;n incumplimiento o retraso en el cumplimiento de alguna de las obligaciones asumidas, cuando el mismo se deba a acontecimientos que est&aacute;n fuera de su control razonable, es decir, que se deban a causa de fuerza mayor, y &eacute;sta podr&aacute; incluir, a modo enunciativo, pero no exhaustivo:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
                    <ul style="list-style-type: disc;margin-left:26px;">
                        <li><span style="font-size:13px;color:black;">Huelgas, cierres patronales u otras medidas reivindicativas.</span></li>
                        <li><span style="font-size:13px;color:black;">Conmoci&oacute;n civil, revuelta, invasi&oacute;n, amenaza o ataque terrorista, estado de alarma, guerra (declarada o no) o amenaza o preparativos de guerra.</span></li>
                        <li><span style="font-size:13px;color:black;">Incendio, explosi&oacute;n, tormenta, inundaci&oacute;n, terremoto, hundimiento, epidemia o cualquier otro desastre natural.&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></li>
                        <li><span style="font-size:13px;color:black;">Imposibilidad de uso de trenes, barcos, aviones, transportes de motor u otros medios de transporte, p&uacute;blicos o privados.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></li>
                        <li><span style="font-size:13px;color:black;">Imposibilidad de utilizar sistemas p&uacute;blicos o privados de telecomunicaciones.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></li>
                        <li><span style="font-size:13px;color:black;">Actos, decretos, legislaci&oacute;n, normativa o restricciones de cualquier gobierno o autoridad p&uacute;blica. &nbsp;</span></li>
                    </ul>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:black;">De esta forma, las obligaciones quedar&aacute;n suspendidas durante el periodo en que la causa de fuerza mayor contin&uacute;e, y&nbsp;</span><strong><span style="font-size:13px;color:black;">AADIDESS&nbsp;</span></strong>
                        <span style="font-size:13px;color:black;">dispondr&aacute; de una ampliaci&oacute;n en el plazo para cumplirlas por un periodo de tiempo igual al que dure la causa de fuerza mayor.&nbsp;</span><strong><span style="font-size:13px;color:black;">AADIDESS&nbsp;</span></strong>
                        <span style="font-size:13px;color:black;">pondr&aacute; todos los medios razonables para encontrar una soluci&oacute;n que le permita cumplir con sus obligaciones a pesar de la causa de fuerza mayor.</span>
                    </p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:black;">&nbsp;</span></p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;color:maroon;">Responsabilidad y limitaci&oacute;n de responsabilidad por enlaces desde otras p&aacute;ginas web&nbsp;</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">La persona usuaria que desee ofrecer contenidos del Sitio web <strong>AADIDESS&nbsp;</strong>en su propia p&aacute;gina web, podr&aacute; introducir en la misma un enlace que vincule su p&aacute;gina web con la p&aacute;gina de inicio del Sitio web <strong>AADIDESS</strong>.</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Las p&aacute;ginas web que ofrezcan un enlace al Sitio web de <strong>AADIDESS</strong>, podr&aacute;n utilizar la marca, logotipo, nombre comercial y/o cualquier otro signo distintivo de <strong>AADIDESS&nbsp;</strong>&uacute;nica y exclusivamente en los casos autorizados legalmente o permitidos por <strong>AADIDESS&nbsp;</strong>expresamente.</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">La persona usuaria no podr&aacute; reproducir el Sitio web de <strong>AADIDESS&nbsp;</strong> de ning&uacute;n modo en su propia p&aacute;gina web, ni podr&aacute; establecer frames o marcos de cualquier tipo que rodeen el Sitio web o permitan visualizarlo a trav&eacute;s de direcciones de Internet distintas, o conjuntamente con contenidos ajenos al mismo, de manera que produzca o pueda producir error o confusi&oacute;n en el p&uacute;blico sobre la procedencia de los contenidos, implique un acto de comparaci&oacute;n o imitaci&oacute;n desleal, sirva para aprovechar la reputaci&oacute;n y la marca de <strong>AADIDESS ,</strong> o se haga de cualquier otro modo prohibido por la legislaci&oacute;n aplicable.</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El usuario o la usuaria que introduzca el enlace al Sitio web <strong>AADIDESS,</strong> no podr&aacute; efectuar bajo ning&uacute;n concepto manifestaciones falsas o inexactas sobre <strong>AADIDESS&nbsp;</strong>sobre sus integrantes, y/o usuarios o usuarias ni sobre la calidad de los contenidos ofrecidos.</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Las p&aacute;ginas web en las que se ofrezca el enlace al Sitio web <strong>AADIDESS&nbsp;</strong>no ofrecer&aacute;n contenidos il&iacute;citos, nocivos, contrarios a la moral y a las buenas costumbres, que produzcan o puedan producir la falsa idea de que <strong>AADIDESS&nbsp;</strong>respalda o apoya las ideas, manifestaciones o actuaciones de dichas personas usuarias, o que resulten inadecuados en relaci&oacute;n con la actividad desarrollada por <strong>AADIDESS</strong>.</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;color:maroon;">Responsabilidad y limitaci&oacute;n de responsabilidad por enlaces a otras p&aacute;ginas web&nbsp;</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Con el objeto de ayudar a la persona usuaria a buscar informaci&oacute;n o servicios de inter&eacute;s, el Sitio web <strong>AADIDESS&nbsp;</strong>podr&aacute; incluir hiperv&iacute;nculos o enlaces a otros sitios web que no son de su propiedad, ni est&aacute;n controlados por esta. <strong>AADIDESS&nbsp;</strong>no controla los contenidos, las Condiciones legales, las pol&iacute;ticas de privacidad o las pr&aacute;cticas de los sitios web de terceros ni asume responsabilidad alguna por &eacute;stos.&nbsp;</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">La persona usuaria reconoce y acepta, que el acceso a otros sitios web no supone que <strong>AADIDESS</strong> recomiende y apruebe el contenido de los mismos, y que <strong>AADIDESS&nbsp;</strong>no ser&aacute; responsable de las p&eacute;rdidas o da&ntilde;os que el usuario o la usuaria pudiera sufrir, a consecuencia de la disponibilidad de los mencionados sitios web, o a consecuencia de la credibilidad que otorgue a la exhaustividad, precisi&oacute;n o existencia de cualquier informaci&oacute;n ofrecida a trav&eacute;s de dichos sitios web.</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;">retirar&aacute; del Sitio web, o impedir&aacute; el acceso a aquellos enlaces que remitan a actividades il&iacute;citas o lesivas de derechos o bienes de un tercero, siempre que un &oacute;rgano competente as&iacute; lo hubiera declarado, tal y como establece la normativa vigente.</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;color:maroon;">Publicidad</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El Sitio web puede albergar contenidos publicitarios. Los anunciantes son los &uacute;nicos responsables de asegurarse que el material remitido para su inclusi&oacute;n en el Sitio web cumple con las leyes que en cada caso puedan ser de aplicaci&oacute;n.<strong>&nbsp;AADIDESS&nbsp;</strong>no ser&aacute; responsable de cualquier error, inexactitud o irregularidad que puedan contener los contenidos publicitarios o de los patrocinadores.</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:maroon;">&nbsp;</span></strong></p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:maroon;">Protecci&oacute;n de datos personales&nbsp;</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;">tratar&aacute; toda la informaci&oacute;n introducida en el Sitio web, de conformidad con la Pol&iacute;tica de Privacidad identificada en la p&aacute;gina web <strong> central@aadidess.com&nbsp;</strong>y la normativa vigente en el estado de Florida y el Reglamento General de Protecci&oacute;n de Datos UE 2016/679.</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Puede ejercer sus derechos de acceso, rectificaci&oacute;n, supresi&oacute;n, oposici&oacute;n y, en su caso, portabilidad y limitaci&oacute;n, enviando un correo electr&oacute;nico a&nbsp;</span><a href="mailto:central@aadidess.com"><strong><span style="font-size:13px;">central@aadidess.com</span></strong></a><strong><span style="font-size:13px;">&nbsp;</span></strong>
                        <span style="font-size:13px;">Puede consultar la informaci&oacute;n adicional y detallada sobre protecci&oacute;n de datos en: <strong> central@aadidess.com</strong></span>
                    </p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:maroon;">Quejas y reclamaciones</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El Usuario puede hacer llegar a <strong>AADIDESS&nbsp;</strong>las quejas o reclamaciones que desee realizar a trav&eacute;s de los datos de contacto que se facilitan a principio de estas Condiciones (Informaci&oacute;n General de contacto).</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><strong><span style="font-size:13px;">AADIDESS&nbsp;</span></strong><span style="font-size:13px;">dar&aacute; respuesta a las reclamaciones recibidas en la mayor brevedad posible y, en todo caso, en el plazo m&aacute;ximo de un mes desde la presentaci&oacute;n de la reclamaci&oacute;n</span>
                        <span style="font-size:13px;">.</span>
                    </p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:maroon;">Miscel&aacute;nea&nbsp;</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:maroon;">19.1 Invalidez parcial</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:black;">Si una o varias de las disposiciones de los presentes&nbsp;</span><strong><span style="font-size:13px;line-height:115%;color:black;">&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;</span></strong>
                        <span style="font-size:13px;line-height:115%;color:black;">&nbsp;fueran considerados inv&aacute;lidos o declarados inv&aacute;lidos por aplicaci&oacute;n de cualquier ley, reglamento o como consecuencia de decisi&oacute;n definitiva de una autoridad competente, las restantes estipulaciones conservar&aacute;n
                    &iacute;ntegramente su vigencia y alcance.</span>
                    </p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <span style="line-height:115%;color:maroon;">a. Exclusi&oacute;n de renuncia</span>
                    </div>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:black;">El hecho de que cualquiera de las partes no ejerciere los derechos que le asisten en caso de incumplimiento por la otra parte de cualquiera de las obligaciones que le imponen los presentes&nbsp;</span><strong><span style="font-size:13px;line-height:115%;color:black;">&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;,</span></strong>
                        <span style="font-size:13px;line-height:115%;color:black;">&nbsp;no podr&aacute; ser interpretada como la renuncia ante futuros incumplimientos de la obligaci&oacute;n de que se trate.</span>
                    </p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <span style="line-height:115%;color:maroon;">b. Ley aplicable y jurisdicci&oacute;n competente</span>
                    </div>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:windowtext;">Estas condiciones generales se rigen por la legislaci&oacute;n</span><span style="font-size:13px;line-height:115%;color:windowtext;">&nbsp;aplicable a las partes. Someti&eacute;ndose, de mutuo acuerdo, a su elecci&oacute;n, para la resoluci&oacute;n de los conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales del domicilio del usuario, o en su caso, al lugar de cumplimiento de la obligaci&oacute;n.</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:14px;line-height:115%;color:maroon;background:#FAFAFA;">19.2 Vigencia</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:14px;line-height:115%;color:windowtext;background:#FAFAFA;">Los presentes <strong>&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;</strong>&nbsp;</span><span style="font-size:14px;line-height:115%;color:windowtext;background:#FAFAFA;">se mantendr&aacute;n vigentes hasta la finalizaci&oacute;n del contrato de las partes y extinci&oacute;n de todas las obligaciones asumidas por cada una. Estas Condiciones pueden verse modificadas, por lo que es responsabilidad del cliente consultarlas peri&oacute;dicamente y sobre todo cuando va a realizar un pedido ya que las Condiciones vigentes en ese momento, ser&aacute;n las que resulten aplicables.</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:14px;line-height:115%;color:windowtext;background:#FAFAFA;">Fecha de revisi&oacute;n de las condiciones:</span><span style="font-size:14px;line-height:115%;color:windowtext;background:#FAFAFA;">&nbsp;febrero 202</span>
                        <span style="font-size:14px;line-height:115%;color:windowtext;background:#FAFAFA;">2</span>
                    </p>
            </ol>

        </div>
    </div>
</div>

<div *ngIf="this.empresa === 'ISAGM'" >

    <div class="row">
        <div class="container">
            <table style="border: none;width:17.0cm;border-collapse:collapse;">
                <tbody>
                    <tr>
                        <td style="width: 17cm;background: rgb(148, 56, 65);padding: 0cm 5.4pt;vertical-align: top;">
                            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:#990000;background:white;">&nbsp; T&eacute;rminos y Condiciones&nbsp;</span></strong></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:windowtext;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:windowtext;">T&Eacute;RMINOS Y CONDICIONES DEL SITIO WEB&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;">En el presente documento se establecen las <strong>&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;&nbsp;</strong>que regulan el funcionamiento de este Sitio web y la compra de productos/servicios a trav&eacute;s de <strong> central@isagm.com</strong>. El uso del Sitio web constituye que le sea de aplicaci&oacute;n la aceptaci&oacute;n de estos T&eacute;rminos y Condiciones. No obstante, si tiene alguna duda o consulta sobre ellas puede contactar con nuestro servicio de Atenci&oacute;n al Cliente a trav&eacute;s de:</span></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:maroon;">e-mail de contacto:</span></strong><span style="font-size:13px;">&nbsp;</span><a href="mailto:central@isagm.com"><span style="font-size:13px;">central@isagm.com</span></a></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
            <ol style="list-style-type: decimal;margin-left:26px;">
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Informaci&oacute;n general de contacto&nbsp;</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Objeto&nbsp;</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Producto y servicio</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Acceso de personas usuarias</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Normas de uso del Sitio web</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Propiedad intelectual e industrial</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Notificaci&oacute;n de infracciones</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Responsabilidad y Seguridad de sus transacciones</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Atenci&oacute;n al cliente / Servicios de mediaci&oacute;n&nbsp;</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Responsabilidad&nbsp;</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Responsabilidad y limitaci&oacute;n por enlaces a otras p&aacute;ginas web</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Publicidad</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Protecci&oacute;n de datos personales&nbsp;</span></strong></li>
                <li><strong><span style="font-size:13px;color:maroon;">Quejas y reclamaciones</span></strong></li>
                <li><strong><span style="line-height:115%;color:maroon;font-size:13px;">Miscel&aacute;nea&nbsp;</span></strong></li>
            </ol>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:windowtext;">&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:windowtext;">&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:windowtext;">&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:windowtext;">&nbsp;</span></strong></p>
            <ol style="margin-bottom:0cm;list-style-type: decimal;margin-left:7.850000000000001px;">

                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Informaci&oacute;n general de contacto&nbsp;</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;">De conformidad con la legislaci&oacute;n aplicable, se informa que la titularidad de este Sitio web, <strong> central@isagm.com</strong>, (en adelante Sitio web) la ostenta: <strong>ISAGM LLC,</strong><strong>&nbsp;[</strong>de ahora en&nbsp;adelante <strong>ISAGM]</strong>   </span>
                    <span style="font-size:13px;line-height:115%;color:windowtext;"> </span><span style="font-size:13px;line-height:115%;">&nbsp;y cuyos datos de contacto son:</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-left:22.4pt;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:maroon;">Direcci&oacute;n:</span></strong><span style="font-size:13px;line-height:115%;color:maroon;">&nbsp;</span><span style="font-size:13px;line-height:115%;">Sede Central Morales 483 PB (8400) San Carlos de Bariloche<br>&nbsp;</span><strong><span style="font-size:13px;line-height:115%;color:maroon;">Email de contacto:</span></strong>
                    <span style="font-size:13px;line-height:115%;color:maroon;">&nbsp;</span><span style="font-size:13px;line-height:115%;">central@isagm.com</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-left:22.4pt;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;">&nbsp;</span></p>
                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Objeto&nbsp;</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">En el presente documento se establecen los <strong>&ldquo;T&eacute;rminos y Condiciones&rdquo;</strong> que regulan el acceso, registro y uso del Sitio web de <strong>ISAGM</strong> y que ser&aacute;n de aplicaci&oacute;n por parte de las personas usuarias del Sitio web, (en adelante &ldquo;el Usuario&rdquo;).&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;">ISAGM&nbsp;</span></strong><span style="font-size:13px;line-height:115%;">se reserva en todo momento la posibilidad de modificar unilateralmente los presentes <strong>&ldquo;T&eacute;rminos y Condiciones&rdquo;.&nbsp;</strong>No obstante, ser&aacute;n aplicables a la Compra aquellas que el Usuario hubiere aceptado en el momento de realizarla.</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;">Las presentes <strong>&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;</strong> incluyen las <strong>Condiciones Generales de uso</strong> del Sitio web y se completar&aacute;n con las disposiciones de la <strong>Pol&iacute;tica de Privacidad</strong> y la <strong>Pol&iacute;tica de Cookies</strong>.</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Toda persona usuaria debe leer atentamente estas condiciones y, en cualquier momento, podr&aacute; almacenarlas o imprimir las presentes Condiciones.</span></p>
                <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;">&nbsp;</span></p>
                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Producto y Servicio</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">A efectos de estos <strong>&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;</strong> se entiende que la actividad que <strong>ISAGM&nbsp;</strong>desarrolla a trav&eacute;s del Sitio web comprende:</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Plataforma educativa, tramitaci&oacute;n y evaluaci&oacute;n de pre inscripciones.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Acceso de personas usuarias</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El simple acceso al Sitio web, atribuye a quien lo realiza la condici&oacute;n de persona usuaria.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El uso de servicios ofrecidos en la p&aacute;gina web es libre para las personas usuarias, no conllevando ninguna comisi&oacute;n ni coste de ning&uacute;n tipo en el acceso a la p&aacute;gina web, salvo en lo relativo al coste de la conexi&oacute;n a trav&eacute;s de la red de telecomunicaciones suministrada por el proveedor de acceso contratado por la persona usuaria, que ser&aacute; a cargo de &eacute;ste.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El acceso a trav&eacute;s de aplicaciones de terceros (por ejemplo, Facebook) implica que la seguridad en el acceso por esta v&iacute;a es responsabilidad de dichas entidades. Asimismo, el Sitio web declina cualquier responsabilidad directa o indirecta por la ca&iacute;da de los sistemas o indisponibilidad de la web o cualquier situaci&oacute;n similar.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Normas de uso del Sitio web&nbsp;</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">La persona usuaria ser&aacute; responsable de la totalidad de sus contenidos publicados y de su veracidad.&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Mediante el uso de este Sitio web, la persona usuaria acepta que la mayor parte de las comunicaciones con <strong>ISAGM&nbsp;</strong>sean electr&oacute;nicas (correo electr&oacute;nico o avisos publicados en el Sitio web).</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">A efectos contractuales, el Usuario consiente en usar este medio electr&oacute;nico de comunicaci&oacute;n y reconoce que todo contrato, notificaci&oacute;n, informaci&oacute;n y dem&aacute;s comunicaciones que <strong>ISAGM&nbsp;</strong>env&iacute;e de forma electr&oacute;nica cumplen con los requisitos legales de ser por escrito. Esta condici&oacute;n no afectar&aacute; a los derechos reconocidos por ley al Usuario.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El Usuario puede enviar notificaciones y/o comunicarse con <strong>ISAGM&nbsp;</strong>a trav&eacute;s de los datos de contacto que en estas Condiciones se facilitan y, en su caso, a trav&eacute;s de los espacios de contacto del Sitio web. Igualmente, salvo que se estipule lo contrario, <strong>ISAGM&nbsp;</strong>puede contactar y/o notificar al Usuario en su correo electr&oacute;nico o en la direcci&oacute;n postal facilitada.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">La persona usuaria se compromete a hacer un uso razonable y razonado de los servicios del Sitio web y a cumplir los &ldquo;<strong>T&Eacute;RMINOS Y CONDICIONES</strong>&rdquo;, reserv&aacute;ndose <strong>ISAGM&nbsp;</strong>el derecho de limitarlo si as&iacute; lo estimara conveniente.&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Queda terminantemente prohibido:</span></p>
                <ul style="list-style-type: disc;margin-left:26px;">
                    <li><span style="font-size:13px;">Utilizar el nombre de <strong>ISAGM&nbsp;</strong>con fines profesionales o lucrativos directa o indirectamente.</span></li>
                    <li><span style="font-size:13px;">Utilizar el Sitio web <strong>ISAGM&nbsp;</strong>para fines que no sean la adquisici&oacute;n de productos y/o servicios</span></li>
                    <li><span style="font-size:13px;">Realizar compras falsas o fraudulentas. Si razonablemente se pudiera considerar que se ha hecho una compra de esta &iacute;ndole, podr&iacute;a ser anulada y se informar&iacute;a a las autoridades competentes.</span></li>
                    <li><span style="font-size:13px;">La publicaci&oacute;n de contenido ofensivo, de cualquier clase, sexista, racista, difamatorio y/o contrario a la legislaci&oacute;n vigente.</span></li>
                    <li><span style="font-size:13px;">Difamar, abusar, acosar, molestar, amenazar o infringir los derechos legales, como los derechos de privacidad o publicidad, de otros.</span></li>
                    <li><span style="font-size:13px;">Utilizar la p&aacute;gina web para fines de promoci&oacute;n no autorizados expresamente por el equipo <strong>ISAGM&nbsp;</strong>o la difusi&oacute;n de contenido publicitario no autorizado.</span></li>
                    <li><span style="font-size:13px;">Suplantar la identidad, con las consecuencias legales que de ello se derivan.</span></li>
                    <li><span style="font-size:13px;">Difundir, publicar o solicitar informaci&oacute;n que promueva la realizaci&oacute;n de ning&uacute;n acto il&iacute;cito en su jurisdicci&oacute;n, incluyendo, pero no limitando a: fraudes electr&oacute;nicos, SPAM, Phishing, Spoofing, Forgery y cualquier otro tipo de conducta que sea considerada un acto ilegal.</span></li>
                    <li><span style="font-size:13px;">Realizar cualquier uso fraudulento del Sitio web.</span></li>
                </ul>
                <p style='margin-top:12.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:36.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Propiedad intelectual e industrial</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El Sitio web est&aacute; protegido por derechos de propiedad intelectual e industrial. Con car&aacute;cter enunciativo, que no limitativo: los contenidos, dise&ntilde;o gr&aacute;fico, c&oacute;digo fuente, logos, textos, gr&aacute;ficos, ilustraciones, fotograf&iacute;as y dem&aacute;s elementos que aparecen en el Sitio web. Por ello, la persona usuaria reconoce que la reproducci&oacute;n, distribuci&oacute;n, comercializaci&oacute;n, transformaci&oacute;n y en general, cualquier forma de explotaci&oacute;n, por cualquier procedimiento, de todo o parte del Sitio web constituye una infracci&oacute;n de derechos de propiedad intelectual e industrial de <strong>ISAGM&nbsp;</strong>o del titular de los mismos, salvo que el usuario o la usuaria hubiera obtenido la previa autorizaci&oacute;n expresa.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Cualquier uso ajeno a <strong>ISAGM</strong>, incluida la reproducci&oacute;n, modificaci&oacute;n, distribuci&oacute;n, transmisi&oacute;n, reedici&oacute;n, arreglos o representaci&oacute;n de cualesquiera elementos del mismo est&aacute; estrictamente prohibido salvo consentimiento expreso escrito de <strong>ISAGM</strong>.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">La persona usuaria no podr&aacute; modificar o utilizar dicha propiedad intelectual e industrial de forma que su divulgaci&oacute;n suponga un perjuicio para <strong>ISAGM</strong>.&nbsp;</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><strong><span style="font-size:13px;">ISAGM&nbsp;</span></strong><span style="font-size:13px;">reclamar&aacute; por los da&ntilde;os y perjuicios derivados del uso indebido por parte de terceras personas de su propiedad intelectual e industrial.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><strong><span style="font-size:13px;">ISAGM&nbsp;</span></strong><span style="font-size:13px;">no concede ninguna licencia o autorizaci&oacute;n de uso de ninguna clase sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otro derecho o propiedad relacionado en el Sitio web, los servicios o los contenidos.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                    <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;">Notificaci&oacute;n de infracciones</span></strong></li>
                </div>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">En caso de que la persona usuaria infrinja derechos de propiedad intelectual y/o industrial o cualquier otro derecho de terceras personas, ser&aacute; apercibido por <strong>ISAGM&nbsp;</strong>en el momento en que &eacute;sta tenga conocimiento de tales infracciones para que cese en la publicaci&oacute;n de los contenidos infractores. En tal caso, la persona usuaria responder&aacute; por tales infracciones.</span></p>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Si cualquier persona o entidad considera que los contenidos publicados en el Sitio web infringen sus derechos al honor, a la intimidad, a la imagen o cualquier derecho podr&aacute; comunicarlo a <strong>ISAGM&nbsp;</strong>por cualquiera de las v&iacute;as de contacto que figuran en el Sitio web con el asunto &ldquo;Vulneraci&oacute;n de derechos&rdquo;, solicitar su retirada y aportar la siguiente informaci&oacute;n:</span></p>
                <ol style="list-style-type: decimal;margin-left:26px;">
                    <li><span style="font-size:13px;">identificaci&oacute;n de los derechos protegidos por la legislaci&oacute;n vigente al respecto que se consideran violados,</span></li>
                    <li><span style="font-size:13px;">identificaci&oacute;n de los contenidos que presuntamente violan los derechos en cuesti&oacute;n con informaci&oacute;n razonablemente suficiente para permitir a <strong>ISAGM&nbsp;</strong>ubicar dicho material en el Sitio web,</span></li>
                    <li><span style="font-size:13px;">informaci&oacute;n de los datos de contacto (direcci&oacute;n f&iacute;sica o electr&oacute;nica y n&uacute;mero de tel&eacute;fono) para que <strong>ISAGM&nbsp;</strong>pueda comunicarse con dicha persona o entidad,</span></li>
                    <li><span style="font-size:13px;">una declaraci&oacute;n en la que dicha persona o entidad afirme que la informaci&oacute;n incluida en la notificaci&oacute;n es veraz y exacta y que es titular de los derechos presuntamente vulnerados o que est&aacute; autorizado o autorizada a actuar en nombre de &eacute;ste y,&nbsp;</span></li>
                    <li><span style="font-size:13px;">copia del del documento que acredite su identidad o pasaporte.</span></li>
                </ol>
                <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><strong><span style="font-size:13px;">ISAGM&nbsp;</span></strong><span style="font-size:13px;">atender&aacute; la petici&oacute;n y, si considera que la persona o entidad tiene motivos o causas justificadas para solicitar la retirada de tales contenidos, proceder&aacute; a su eliminaci&oacute;n inmediata del Sitio web de forma que no sean accesibles por las personas usuarias ni siquiera desde la memoria cach&eacute;.</span></p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:17.85pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><strong><span style="font-size:13px;line-height:115%;color:maroon;">&nbsp;</span></strong></p>
        
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:maroon;">Atenci&oacute;n al cliente / Servicios de mediaci&oacute;n&nbsp;</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:black;">Para cualquier informaci&oacute;n adicional, consulta o reclamaci&oacute;n, puede ponerse en contacto con el Servicio de Atenci&oacute;n al Cliente de&nbsp;</span><strong><span style="font-size:13px;line-height:115%;color:black;">ISAGM&nbsp;</span></strong>
                        <span style="font-size:13px;line-height:115%;color:black;">a trav&eacute;s de:</span>
                    </p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;"><br>&nbsp;</span><strong><span style="font-size:13px;line-height:115%;color:maroon;">Email de contacto:</span></strong><span style="font-size:13px;line-height:115%;color:maroon;">&nbsp;</span>
                        <span style="font-size:13px;line-height:115%;">central@isagm.com</span>
                    </p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:black;">&nbsp;</span></p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;color:maroon;">Responsabilidad&nbsp;</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:maroon;">13.1 Capacidad</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:black;">Con anterioridad a la realizaci&oacute;n de su Pedido, el Usuario declara contar con capacidad legal plena (mayor de 18 a&ntilde;os) para obligarse en virtud de los presentes&nbsp;</span><strong><span style="font-size:13px;line-height:115%;color:black;">&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;</span></strong>
                        <span style="font-size:13px;line-height:115%;color:black;">.</span>
                    </p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:maroon;">16.2 Limitaci&oacute;n de responsabilidad</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:black;">Salvo disposici&oacute;n legal en sentido contrario,&nbsp;</span><strong><span style="font-size:13px;color:black;">ISAGM&nbsp;</span></strong><span style="font-size:13px;color:black;">no aceptar&aacute; ninguna responsabilidad por las siguientes p&eacute;rdidas, con independencia de su origen:</span></p>
                    <ul style="list-style-type: disc;margin-left:26px;">
                        <li><span style="font-size:13px;color:black;">cualesquiera p&eacute;rdidas que no fueran atribuibles a incumplimiento alguno por su parte;&nbsp;&nbsp;</span></li>
                        <li><span style="font-size:13px;color:black;">p&eacute;rdidas empresariales (incluyendo lucro cesante, de ingresos, de contratos, de ahorros previstos, de datos, p&eacute;rdida del fondo de comercio o gastos innecesarios incurridos); o de&nbsp;</span></li>
                        <li><span style="font-size:13px;color:black;">toda otra p&eacute;rdida indirecta que no fuera razonablemente previsible por ambas partes en el momento en que se formaliz&oacute; el contrato de compraventa de los productos entre ambas partes.&nbsp;</span></li>
                    </ul>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:black;">Igualmente,&nbsp;</span><strong><span style="font-size:13px;color:black;">ISAGM&nbsp;</span></strong><span style="font-size:13px;color:black;">tambi&eacute;n limita su responsabilidad en cuanto a los siguientes casos:</span></p>
                    <ul style="list-style-type: disc;margin-left:26px;">
                        <li><strong><span style="font-size:13px;color:black;">ISAGM&nbsp;</span></strong><span style="font-size:13px;color:black;">aplica todas las medidas concernientes a proporcionar una visualizaci&oacute;n fiel del producto en el Sitio web, sin embargo, no se responsabiliza por las m&iacute;nimas diferencias o inexactitudes que puedan existir debido a falta de resoluci&oacute;n de la pantalla, o problemas del navegador que se utilice u otros de esta &iacute;ndole.&nbsp; &nbsp; &nbsp; &nbsp;</span></li>
                        <li><span style="font-size:13px;color:black;">Fallos t&eacute;cnicos que por causas fortuitas o de otra &iacute;ndole, impidan un normal funcionamiento del servicio a trav&eacute;s de internet. Falta de disponibilidad del Sitio web por razones de mantenimiento u otras, que impida disponer del servicio.&nbsp;</span><strong><span style="font-size:13px;color:black;">ISAGM&nbsp;</span></strong>
                            <span style="font-size:13px;color:black;">pone todos los medios a su alcance a efectos de llevar a cabo el proceso de compra y pago, no obstante, se exime de responsabilidad por causas que no le sean imputables, caso fortuito o fuerza mayor. &nbsp; &nbsp;</span>
                        </li>
                        <li><strong><span style="font-size:13px;color:black;">ISAGM&nbsp;</span></strong><span style="font-size:13px;color:black;">no ser&aacute; responsable de los da&ntilde;os derivados de un mal uso del Sitio web, ni de cualquier actuaci&oacute;n fraudulenta realizada en base a la informaci&oacute;n que en el mismo se facilita.</span></li>
                        <li><strong><span style="font-size:13px;color:black;">ISAGM&nbsp;</span></strong><span style="font-size:13px;color:black;">no se hace responsable de los posibles errores de seguridad o desconexiones que se puedan producir ni de los posibles da&ntilde;os que puedan causarse al sistema inform&aacute;tico de la persona usuaria (hardware y software), los ficheros o documentos almacenados en el mismo, como consecuencia de la presencia de virus en el ordenador del usuario o de la usuaria, utilizando para la conexi&oacute;n al Sitio web, o de un mal funcionamiento del navegador o del uso de versiones no actualizadas del mismo.</span></li>
                    </ul>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:black;">En general,&nbsp;</span><strong><span style="font-size:13px;color:black;">ISAGM&nbsp;</span></strong><span style="font-size:13px;color:black;">no se responsabilizar&aacute; por ning&uacute;n incumplimiento o retraso en el cumplimiento de alguna de las obligaciones asumidas, cuando el mismo se deba a acontecimientos que est&aacute;n fuera de su control razonable, es decir, que se deban a causa de fuerza mayor, y &eacute;sta podr&aacute; incluir, a modo enunciativo, pero no exhaustivo:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
                    <ul style="list-style-type: disc;margin-left:26px;">
                        <li><span style="font-size:13px;color:black;">Huelgas, cierres patronales u otras medidas reivindicativas.</span></li>
                        <li><span style="font-size:13px;color:black;">Conmoci&oacute;n civil, revuelta, invasi&oacute;n, amenaza o ataque terrorista, estado de alarma, guerra (declarada o no) o amenaza o preparativos de guerra.</span></li>
                        <li><span style="font-size:13px;color:black;">Incendio, explosi&oacute;n, tormenta, inundaci&oacute;n, terremoto, hundimiento, epidemia o cualquier otro desastre natural.&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></li>
                        <li><span style="font-size:13px;color:black;">Imposibilidad de uso de trenes, barcos, aviones, transportes de motor u otros medios de transporte, p&uacute;blicos o privados.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></li>
                        <li><span style="font-size:13px;color:black;">Imposibilidad de utilizar sistemas p&uacute;blicos o privados de telecomunicaciones.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></li>
                        <li><span style="font-size:13px;color:black;">Actos, decretos, legislaci&oacute;n, normativa o restricciones de cualquier gobierno o autoridad p&uacute;blica. &nbsp;</span></li>
                    </ul>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:black;">De esta forma, las obligaciones quedar&aacute;n suspendidas durante el periodo en que la causa de fuerza mayor contin&uacute;e, y&nbsp;</span><strong><span style="font-size:13px;color:black;">ISAGM&nbsp;</span></strong>
                        <span style="font-size:13px;color:black;">dispondr&aacute; de una ampliaci&oacute;n en el plazo para cumplirlas por un periodo de tiempo igual al que dure la causa de fuerza mayor.&nbsp;</span><strong><span style="font-size:13px;color:black;">ISAGM&nbsp;</span></strong>
                        <span style="font-size:13px;color:black;">pondr&aacute; todos los medios razonables para encontrar una soluci&oacute;n que le permita cumplir con sus obligaciones a pesar de la causa de fuerza mayor.</span>
                    </p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:black;">&nbsp;</span></p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;color:maroon;">Responsabilidad y limitaci&oacute;n de responsabilidad por enlaces desde otras p&aacute;ginas web&nbsp;</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">La persona usuaria que desee ofrecer contenidos del Sitio web <strong>ISAGM&nbsp;</strong>en su propia p&aacute;gina web, podr&aacute; introducir en la misma un enlace que vincule su p&aacute;gina web con la p&aacute;gina de inicio del Sitio web <strong>ISAGM</strong>.</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Las p&aacute;ginas web que ofrezcan un enlace al Sitio web de <strong>ISAGM</strong>, podr&aacute;n utilizar la marca, logotipo, nombre comercial y/o cualquier otro signo distintivo de <strong>ISAGM&nbsp;</strong>&uacute;nica y exclusivamente en los casos autorizados legalmente o permitidos por <strong>ISAGM&nbsp;</strong>expresamente.</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">La persona usuaria no podr&aacute; reproducir el Sitio web de <strong>ISAGM&nbsp;</strong> de ning&uacute;n modo en su propia p&aacute;gina web, ni podr&aacute; establecer frames o marcos de cualquier tipo que rodeen el Sitio web o permitan visualizarlo a trav&eacute;s de direcciones de Internet distintas, o conjuntamente con contenidos ajenos al mismo, de manera que produzca o pueda producir error o confusi&oacute;n en el p&uacute;blico sobre la procedencia de los contenidos, implique un acto de comparaci&oacute;n o imitaci&oacute;n desleal, sirva para aprovechar la reputaci&oacute;n y la marca de <strong>ISAGM ,</strong> o se haga de cualquier otro modo prohibido por la legislaci&oacute;n aplicable.</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El usuario o la usuaria que introduzca el enlace al Sitio web <strong>ISAGM,</strong> no podr&aacute; efectuar bajo ning&uacute;n concepto manifestaciones falsas o inexactas sobre <strong>ISAGM&nbsp;</strong>sobre sus integrantes, y/o usuarios o usuarias ni sobre la calidad de los contenidos ofrecidos.</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Las p&aacute;ginas web en las que se ofrezca el enlace al Sitio web <strong>ISAGM&nbsp;</strong>no ofrecer&aacute;n contenidos il&iacute;citos, nocivos, contrarios a la moral y a las buenas costumbres, que produzcan o puedan producir la falsa idea de que <strong>ISAGM&nbsp;</strong>respalda o apoya las ideas, manifestaciones o actuaciones de dichas personas usuarias, o que resulten inadecuados en relaci&oacute;n con la actividad desarrollada por <strong>ISAGM</strong>.</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;color:maroon;">Responsabilidad y limitaci&oacute;n de responsabilidad por enlaces a otras p&aacute;ginas web&nbsp;</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">Con el objeto de ayudar a la persona usuaria a buscar informaci&oacute;n o servicios de inter&eacute;s, el Sitio web <strong>ISAGM&nbsp;</strong>podr&aacute; incluir hiperv&iacute;nculos o enlaces a otros sitios web que no son de su propiedad, ni est&aacute;n controlados por esta. <strong>ISAGM&nbsp;</strong>no controla los contenidos, las Condiciones legales, las pol&iacute;ticas de privacidad o las pr&aacute;cticas de los sitios web de terceros ni asume responsabilidad alguna por &eacute;stos.&nbsp;</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">La persona usuaria reconoce y acepta, que el acceso a otros sitios web no supone que <strong>ISAGM</strong> recomiende y apruebe el contenido de los mismos, y que <strong>ISAGM&nbsp;</strong>no ser&aacute; responsable de las p&eacute;rdidas o da&ntilde;os que el usuario o la usuaria pudiera sufrir, a consecuencia de la disponibilidad de los mencionados sitios web, o a consecuencia de la credibilidad que otorgue a la exhaustividad, precisi&oacute;n o existencia de cualquier informaci&oacute;n ofrecida a trav&eacute;s de dichos sitios web.</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;">ISAGM&nbsp;</span></strong><span style="font-size:13px;">retirar&aacute; del Sitio web, o impedir&aacute; el acceso a aquellos enlaces que remitan a actividades il&iacute;citas o lesivas de derechos o bienes de un tercero, siempre que un &oacute;rgano competente as&iacute; lo hubiera declarado, tal y como establece la normativa vigente.</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="line-height:115%;color:maroon;font-size:13px;color:maroon;">Publicidad</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El Sitio web puede albergar contenidos publicitarios. Los anunciantes son los &uacute;nicos responsables de asegurarse que el material remitido para su inclusi&oacute;n en el Sitio web cumple con las leyes que en cada caso puedan ser de aplicaci&oacute;n.<strong>&nbsp;ISAGM&nbsp;</strong>no ser&aacute; responsable de cualquier error, inexactitud o irregularidad que puedan contener los contenidos publicitarios o de los patrocinadores.</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><strong><span style="font-size:13px;color:maroon;">&nbsp;</span></strong></p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:maroon;">Protecci&oacute;n de datos personales&nbsp;</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><strong><span style="font-size:13px;">ISAGM&nbsp;</span></strong><span style="font-size:13px;">tratar&aacute; toda la informaci&oacute;n introducida en el Sitio web, de conformidad con la Pol&iacute;tica de Privacidad identificada en la p&aacute;gina web <strong> central@isagm.com&nbsp;</strong>y la normativa vigente en el estado de Florida y el Reglamento General de Protecci&oacute;n de Datos UE 2016/679.</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">Puede ejercer sus derechos de acceso, rectificaci&oacute;n, supresi&oacute;n, oposici&oacute;n y, en su caso, portabilidad y limitaci&oacute;n, enviando un correo electr&oacute;nico a&nbsp;</span><a href="mailto:central@isagm.com"><strong><span style="font-size:13px;">central@isagm.com</span></strong></a><strong><span style="font-size:13px;">&nbsp;</span></strong>
                        <span style="font-size:13px;">Puede consultar la informaci&oacute;n adicional y detallada sobre protecci&oacute;n de datos en: <strong> central@isagm.com</strong></span>
                    </p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><strong><span style="font-size:13px;">&nbsp;</span></strong></p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:maroon;">Quejas y reclamaciones</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><span style="font-size:13px;">El Usuario puede hacer llegar a <strong>ISAGM&nbsp;</strong>las quejas o reclamaciones que desee realizar a trav&eacute;s de los datos de contacto que se facilitan a principio de estas Condiciones (Informaci&oacute;n General de contacto).</span></p>
                    <p style='margin:0cm;margin-bottom:12.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;margin-top:12.0pt;margin-right:0cm;margin-left:0cm;text-align:justify;'><strong><span style="font-size:13px;">ISAGM&nbsp;</span></strong><span style="font-size:13px;">dar&aacute; respuesta a las reclamaciones recibidas en la mayor brevedad posible y, en todo caso, en el plazo m&aacute;ximo de un mes desde la presentaci&oacute;n de la reclamaci&oacute;n</span>
                        <span style="font-size:13px;">.</span>
                    </p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <li style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'><strong><span style="font-size:13px;color:maroon;">Miscel&aacute;nea&nbsp;</span></strong></li>
                    </div>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;'><span style="font-size:13px;color:maroon;">19.1 Invalidez parcial</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:black;">Si una o varias de las disposiciones de los presentes&nbsp;</span><strong><span style="font-size:13px;line-height:115%;color:black;">&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;</span></strong>
                        <span style="font-size:13px;line-height:115%;color:black;">&nbsp;fueran considerados inv&aacute;lidos o declarados inv&aacute;lidos por aplicaci&oacute;n de cualquier ley, reglamento o como consecuencia de decisi&oacute;n definitiva de una autoridad competente, las restantes estipulaciones conservar&aacute;n
                    &iacute;ntegramente su vigencia y alcance.</span>
                    </p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <span style="line-height:115%;color:maroon;">a. Exclusi&oacute;n de renuncia</span>
                    </div>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:black;">El hecho de que cualquiera de las partes no ejerciere los derechos que le asisten en caso de incumplimiento por la otra parte de cualquiera de las obligaciones que le imponen los presentes&nbsp;</span><strong><span style="font-size:13px;line-height:115%;color:black;">&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;,</span></strong>
                        <span style="font-size:13px;line-height:115%;color:black;">&nbsp;no podr&aacute; ser interpretada como la renuncia ante futuros incumplimientos de la obligaci&oacute;n de que se trate.</span>
                    </p>
                    <div style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Arial","sans-serif";color:black;'>
                        <span style="line-height:115%;color:maroon;">b. Ley aplicable y jurisdicci&oacute;n competente</span>
                    </div>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:13px;line-height:115%;color:windowtext;">Estas condiciones generales se rigen por la legislaci&oacute;n</span><span style="font-size:13px;line-height:115%;color:windowtext;">&nbsp;aplicable a las partes. Someti&eacute;ndose, de mutuo acuerdo, a su elecci&oacute;n, para la resoluci&oacute;n de los conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales del domicilio del usuario, o en su caso, al lugar de cumplimiento de la obligaci&oacute;n.</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:14px;line-height:115%;color:maroon;background:#FAFAFA;">19.2 Vigencia</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:14px;line-height:115%;color:windowtext;background:#FAFAFA;">Los presentes <strong>&ldquo;T&Eacute;RMINOS Y CONDICIONES&rdquo;</strong>&nbsp;</span><span style="font-size:14px;line-height:115%;color:windowtext;background:#FAFAFA;">se mantendr&aacute;n vigentes hasta la finalizaci&oacute;n del contrato de las partes y extinci&oacute;n de todas las obligaciones asumidas por cada una. Estas Condiciones pueden verse modificadas, por lo que es responsabilidad del cliente consultarlas peri&oacute;dicamente y sobre todo cuando va a realizar un pedido ya que las Condiciones vigentes en ese momento, ser&aacute;n las que resulten aplicables.</span></p>
                    <p style='margin:0cm;margin-bottom:10.0pt;font-size:16px;font-family:"Arial","sans-serif";color:black;text-align:justify;line-height:115%;'><span style="font-size:14px;line-height:115%;color:windowtext;background:#FAFAFA;">Fecha de revisi&oacute;n de las condiciones:</span><span style="font-size:14px;line-height:115%;color:windowtext;background:#FAFAFA;">&nbsp;febrero 202</span>
                        <span style="font-size:14px;line-height:115%;color:windowtext;background:#FAFAFA;">2</span>
                    </p>
            </ol>

        </div>
    </div>
</div>