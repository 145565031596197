<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/dashboard">Inicio</a></li>
        <li><a routerLink="/mesas-listado">Listado de Mesas</a></li>
        <li>Nueva Mesa de Examen</li>
      </ul>
      <h2>Nueva Mesa de Examen</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
  <div class="container">
    <mat-horizontal-stepper linear #stepper>
      <mat-step [stepControl]="form">
        <form [formGroup]="form">
          <ng-template matStepLabel>Datos Mesa</ng-template>
          <mat-form-field class="example-full-width">
            <mat-label>Título de la Mesa</mat-label>
            <input matInput formControlName="titulo" placeholder="Titulo de la mesa">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Descripción corta</mat-label>
            <textarea matInput formControlName="descripcionCorta"
              placeholder="Descripción corta de la mesa..."></textarea>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Descripción larga</mat-label>
            <textarea matInput formControlName="descripcionLarga"
              placeholder="Descripción larga de la mesa..."></textarea>
          </mat-form-field>

          <label id="example-radio-group-label">Tipo de mesa</label><br>
          <mat-radio-group aria-label="Seleccione tipo de mesa" [(ngModel)]="tipo" formControlName="tipoMesa">
            <mat-radio-button value="INGRESO">Ingreso</mat-radio-button>
            <mat-radio-button value="FINAL">Final</mat-radio-button>
          </mat-radio-group>
          <br>
          <hr>
          <div class="example-container">

            <mat-form-field color="accent" [style.width.%]=50>
              <mat-label>Fecha examen</mat-label>
              <input matInput formControlName="fechaDesdeExamen" [matDatepicker]="picker" autocomplete="off" disabled>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>

            <mat-form-field color="accent" [style.width.%]=50>
              <mat-label>Fecha recuperatorio mesa</mat-label>
              <input matInput formControlName="fechaHastaExamen" [matDatepicker]="picker1" autocomplete="off" disabled>
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1 disabled="false"></mat-datepicker>
            </mat-form-field>

          </div>

          <mat-slide-toggle (change)="onChange($event.checked)" [checked]="mesaPublicada">Mesa
            publicada?</mat-slide-toggle>

          <br>


          <div class="example-container" *ngIf="mesaPublicada">

            <p> <strong>Seleccione las fechas de vigencia de la mesa, estos datos permiten la preinscripción luego desde
                la web.</strong></p>

            <mat-form-field color="accent" [style.width.%]=50>
              <mat-label>Fecha vigencia desde</mat-label>
              <input matInput formControlName="fechaPublicacionDesde" [matDatepicker]="picker3" autocomplete="off"
                disabled>
              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-datepicker #picker3 disabled="false"></mat-datepicker>
            </mat-form-field>

            <mat-form-field color="accent" [style.width.%]=50>
              <mat-label>Fecha vigencia hasta</mat-label>
              <input matInput formControlName="fechaPublicacionHasta" [matDatepicker]="picker4" autocomplete="off"
                disabled>
              <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
              <mat-datepicker #picker4 disabled="false"></mat-datepicker>
            </mat-form-field>

          </div>

          <div class="row">
            <div class="col-lg-3"><mat-form-field class="example-full-width" style="display: none;">

                <mat-form-field appearance="fill">
                  <p>(Puede seleccionar más de uno o ninguno, no es requerido)</p>
                  <mat-label>Seleccionar Cursos</mat-label>
                  <mat-select formControlName="curso" multiple>
                    <mat-option *ngFor="let curso of cursos"
                      [value]="curso._id">{{curso.descripcionGeneral}}</mat-option>
                  </mat-select>
                </mat-form-field>

              </mat-form-field></div>
            <div class="col-lg-3"><mat-form-field class="example-full-width">
                <mat-label>Seleccionar Delegación</mat-label>
                <mat-select formControlName="delegacion">
                  <mat-option *ngFor="let delegacion of delegaciones" [value]="delegacion._id">
                    {{delegacion.concepto}}
                  </mat-option>
                </mat-select>
              </mat-form-field></div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Seleccionar Disciplina</mat-label>
                <mat-select formControlName="disciplina">
                  <mat-option *ngFor="let disciplina of disciplinas" [value]="disciplina._id">
                    {{disciplina.nombre}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <p>(Es necesario para luego el filtro desde la web, por defecto se selecciona el Nivel 1)</p>
                <mat-label>Seleccionar Nivel</mat-label>
                <mat-select formControlName="nivel" [(ngModel)]="selectedNivel">
                  <mat-option *ngFor="let nivel of niveles" [value]=nivel [selected]="nivel === 1">
                    Nivel {{nivel}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
          </div>
        </form>
      </mat-step>

      <mat-step>
        <form>
          <mat-form-field>
            <mat-label>Filtrar</mat-label>
            <input matInput (keyup)="applyFilter($event)" [disabled]="!flagLoaded"
              placeholder="Buscar por nombre, email..." autocomplete="off">
          </mat-form-field>
          <ng-template matStepLabel>Seleccionar Usuarios</ng-template>
          <strong>Total seleccionados: </strong>{{usuariosNombres?.length}}
          <div class="contenedor-spinner" *ngIf="!flagLoaded">
            <mat-progress-spinner mode="indeterminate" *ngIf="!flagLoaded"></mat-progress-spinner>
          </div>
          <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="nombre">
              <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
              <mat-cell *matCellDef="let row">{{ row.nombrePersona }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="apellido">
              <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
              <mat-cell *matCellDef="let row">{{ row.apellidoPersona }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="identificacion">
              <mat-header-cell *matHeaderCellDef>Identificación</mat-header-cell>
              <mat-cell *matCellDef="let row">{{ row.identificacion }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
              <mat-cell *matCellDef="let row">{{ row.email }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? seleccionarMasivo() : null"
                  [checked]="seleccion.hasValue() && allUsuariosSeleccionados()"
                  [indeterminate]="seleccion.hasValue() && !allUsuariosSeleccionados()">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? seleccionarIndividual(row) : null"
                  [checked]="seleccion.isSelected(row)">
                </mat-checkbox>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <mat-paginator [pageSizeOptions]="[25, 100, 400]"></mat-paginator>
          <div class="d-flex justify-content-end">
            <button mat-raised-button color="accent" type="button" routerLink="/mesas-listado" style="margin-right: 5px; background-color: #eeeeee;">Cancelar</button>
            <button mat-button matStepperPrevious>Anterior</button>
            <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Guardar Mesa</ng-template>
        <h5>Usuarios seleccionados a esta mesa: </h5>
        <p><strong>{{this.form.value.titulo}}</strong></p>
        <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="example-viewport" style="width: 100% !important;">
          <mat-list *ngFor="let usuario of usuariosNombres">
            <mat-list-item>
              <span matListItemTitle>{{usuario}}</span>
            </mat-list-item>
          </mat-list>
        </cdk-virtual-scroll-viewport>
        <br>
        <strong>Total seleccionados: </strong>{{usuariosNombres?.length}}
        <br><br>
        <div class="d-flex justify-content-end">
          <button mat-button matStepperPrevious>Atrás</button>
          <button mat-raised-button (click)="post()" color="primary" matStepperNext>Guardar</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>