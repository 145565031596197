<div class="container">
    <div class="row">
        <div class="col-lg-10 col-md-12">
            <h3>Comprobante</h3>
            <p>Formatos admitidos son JPG, PNG o PDF.</p>
            <div *ngIf="flagLoaded" class="animate__animated animate__fadeInUp">
                <form>
                    <div class="form-group">
                        <label class="form-label" [ngStyle]="{'background': flagLoading ? '#28a745' : '#ffffff'}"></label>
                        <input [disabled]="flagCargado" type="file" accept="application/pdf, image/jpeg, image/jpg, image/png" (change)="onFileSelected($event);">
                    </div>
                    <mat-progress-bar *ngIf="flagLoading" mode="indeterminate"></mat-progress-bar>
                </form>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <app-loading *ngIf="!flagLoaded"></app-loading>
            <mat-divider></mat-divider>
            <div *ngIf="flagLoaded" class="row animate__animated animate__fadeInUp">
                <div class="col-md-6 mt-3">
                    <button *ngIf="!flagLoading" (click)="closeModal()" mat-raised-button color="warn" class="w-100">{{ idioma | translate: "form.SALIR-BTN" | async}}</button>
                </div>
                <div class="col-md-6 mt-3">
                    <button *ngIf="!flagDisableButton && !flagCargado" (click)="cargar()" mat-raised-button color="accent" class="w-100">{{ idioma | translate: "form.BTN-CARGAR" | async}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
