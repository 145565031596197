import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Solicitud } from '../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { IAppOfertas, APP_CONFIG_OFERTAS } from '../../app.config/app.config-ofertas';
import { Response } from '../../interfaces/interface-bakend/response';
import { InfoSolicitud } from '../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { AuthService } from '../auth/auth.service';
import { Observable, Subject } from 'rxjs';

const URL_BACKEND = environment.urlBackend;


@Injectable({
  providedIn: 'root'
})
export class EstadisticasService {

    private flagRefresh = new Subject<any>();

    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        private httpClient: HttpClient,
        private authService: AuthService,
    ) { }

    async getFindAll(): Promise<Solicitud[]>{

        const headers =  new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise( resolve => {
            this.httpClient.get<Response>(`${ URL_BACKEND }/solicitudes/findAll/`, { headers })
                .subscribe( resp => {
                    resolve(resp.response);
                });
            });
    }

    async getFindByStatus(estado: number): Promise<Solicitud[]>{

        const headers =  new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise( resolve => {
            this.httpClient.get<Response>(`${ URL_BACKEND }/solicitudes/findByStatus/${estado}`, { headers })
                .subscribe( resp => {
                    resolve(resp.response);
                });
            });
    }

    async getInfoDashBoard(): Promise<InfoSolicitud[]>{

        const headers =  new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise( resolve => {
            this.httpClient.get<Response>(`${ URL_BACKEND }/solicitudes/getInfoDashBoard`, {headers})
                .subscribe( resp => {
                    resolve(resp.response);
                });
            });
    }


  refresh(): Observable<any> {
    return this.flagRefresh.asObservable();
  }

  setFlagRefresh(flag: boolean) {
    this.flagRefresh.next({ value: flag });
  }


}
