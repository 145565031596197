<div class="banner-wrapper" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <img [src]="logoUrl" alt="Campus" width="30%">
                <div class="banner-wrapper-text">

                    <h1>{{ idioma | translate: "oferta-laboral.BUSCADOR-TITULO" | async}}</h1>

                    <p>{{ idioma | translate: "oferta-laboral.BUSCADOR-SUBTITULO-1" | async}}</p>
                    <p>{{ idioma | translate: "oferta-laboral.BUSCADOR-SUBTITULO-2" | async}}</p>

                    <input class="form-control" [ngModel]="selectedItem" (ngModelChange)="onChange($event)" list="paisesDisponibles" id="datalistPaisesDisponibles" [placeholder]=" idioma | translate: 'form.BUSCADOR-TEXT-INPUT' | async">
                    <datalist id="paisesDisponibles">
                        <option *ngFor="let pais of paisesDisponibles" [value]="pais.pais.name_es" >{{pais.pais.name_es}}</option>
                     </datalist>
                    <div *ngIf="selectedItem" class="animate__animated animate__fadeIn">
                        <a routerLink="/actividades-disponibles-pais" class="default-btn">{{ idioma | translate: "form.CONTINUAR-BTN" | async}}<span ></span></a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>