<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li><a routerLink="/boletines-listado">Boletines</a></li>
                <li>Modificar Boletin</li>
            </ul>
            <h2>Modificar Boletin</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="andorra-apply-content animate__animated animate__fadeIn">
    <div class="container-boletin">
        <div class="row mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="form" (ngSubmit)="put()">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-xs-12">
                                <mat-form-field appearance="fill" style="width: 100%;">
                                    <mat-label>Nombre Boletin</mat-label>
                                    <input matInput formControlName="nombre"
                                        placeholder="Ingrese el nombre del boletín">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <div class="row">
                                <div class="col-lg-12">
                                    <button mat-raised-button color="primary" type="button" (click)="addMateria()"
                                        style="background-color: #4ad0fe;">
                                        <mat-icon>plus_one</mat-icon> Agregar Materia
                                    </button>
                                </div>
                            </div>
                            <br>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Materia</th>
                                        <th>Modalidad Cursada</th>
                                        <th>Modalidad Examen</th>
                                        <!--<th>Fecha Examen</th>
                                        <th>Fecha Nota</th>
                                        <th>Nota Examen</th>-->
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="materias">
                                    <tr *ngFor="let materia of materias.controls; let i = index" [formGroupName]="i">
                                        <td>
                                            <mat-form-field appearance="fill" style="width: 100%;">
                                                <mat-label>Materia</mat-label>
                                                <mat-select formControlName="materia">
                                                    <mat-option *ngFor="let materia of materiasBoletines"
                                                        [value]="materia._id">
                                                        {{ materia.nombre }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <mat-form-field appearance="fill" style="width: 100%;">
                                                <mat-label>Modalidad Cursada</mat-label>
                                                <mat-select formControlName="modalidadCursada">
                                                    <mat-option *ngFor="let modalidad of modalidadCursadas"
                                                        [value]="modalidad._id">
                                                        {{ modalidad.concepto }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <mat-form-field appearance="fill" style="width: 100%;">
                                                <mat-label>Modalidad Examen</mat-label>
                                                <mat-select formControlName="modalidadExamen">
                                                    <mat-option *ngFor="let modalidad of modalidadExamenes"
                                                        [value]="modalidad._id">
                                                        {{ modalidad.concepto }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                        <td style="display: none;">
                                            <mat-form-field color="accent" appearance="fill" [style.width.%]=100>
                                                <mat-label>Fecha Examen</mat-label>
                                                <input matInput formControlName="fechaExamen" [matDatepicker]="picker2"
                                                    autocomplete="off">

                                                <mat-datepicker-toggle matSuffix
                                                    [for]="picker2"></mat-datepicker-toggle>
                                                <mat-datepicker #picker2 color="primary"></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                        <td style="display: none;">
                                            <mat-form-field color="accent" appearance="fill" [style.width.%]=100>
                                                <mat-label>Fecha Nota</mat-label>
                                                <input matInput formControlName="fechaNota" [matDatepicker]="picker3"
                                                    autocomplete="off">

                                                <mat-datepicker-toggle matSuffix
                                                    [for]="picker3"></mat-datepicker-toggle>
                                                <mat-datepicker #picker3 color="primary"></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                        <td style="display: none;">
                                            <mat-form-field appearance="fill" style="width: 100%;">
                                                <mat-label>Nota Examen</mat-label>
                                                <input formControlName="notaExamen" min="0" type="number" matInput
                                                    placeholder="Ingrese una nota" autocomplete="off">
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <button type="button" mat-icon-button class="centered-icon-button"
                                                color="warn" (click)="quitarMateria(i)">
                                                <mat-icon>delete</mat-icon>
                                            </button>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <button type="button" routerLink="/boletines-listado"
                                    class="btn btn-warning">Cancelar</button>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <button [ngClass]="{'disabled-button': !form.valid}" [disabled]="!form.valid"
                                    type="submit">Guardar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>