import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '../../interfaces/interface-bakend/response';
import { GuiMsjService } from './gui-msj.service';
import { Invoice } from '../../interfaces/interface-bakend/invoices/invoices.interfaces';
import { PaypalPurchaseUnit } from '../../interfaces/interface-frontend/shared/paypalPurchaseUnit.nterface';
import { AuthService } from '../auth/auth.service';
import { StorageService } from './storage.service';
const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  invoice: Invoice;
  token: string;

  constructor(
      private httpClient: HttpClient,
      private guiMsjService: GuiMsjService,
      private authService: AuthService,
      private storageService: StorageService,
  ) { }

  async loadJwToken(): Promise<string> {
    this.token = await localStorage.getItem('token') || null;
    return this.token ;
  }

  async procesarEnvioSolicitudPagoTotal(data: Invoice): Promise<Invoice> {
    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
            'x-token': await this.loadJwToken(),
        });

        this.httpClient.post<Response>(`${URL_BACKEND}/invoices/`, data,  { headers })
        .subscribe( (resp: Response)  => {
            resolve(resp.response);
            this.guiMsjService.msjFormSubmit('envioEmailSolicitarPagoOK');
        }, error => {
            this.guiMsjService.msjFormSubmit('envioEmailSolicitarPagoError');
            console.log('error', error);
        });
    });
  }

  async updateInvoice(data: Invoice): Promise<Invoice> {
    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
            'x-token': await this.loadJwToken(),
        });

        this.httpClient.put<Response>(`${URL_BACKEND}/invoices/updateOne/${data._id}`, data,  { headers })
        .subscribe( (resp: Response)  => {
            resolve(resp.response);
        }, error => {
            console.log('error', error);
        });
    });
  }



  async createInvoiceByPaypalPurchuseUnit(data: PaypalPurchaseUnit, notify: boolean, type: string) {

    let invoice: Invoice;

    invoice = {
        tipo: type,
        invoice : {
            usuario: data.data.solicitud.usuario._id,
            observaciones: data.description,
            notify,
            data: data.data,
            importeNeto: data.importeNeto,
            importeDescuento: data.importeDecuento,
            importeRecargo: data.importeRecargo,
            solicitud: data.data.solicitud._id,
        },
    };

    return invoice;
  }

  async getInvoices(type: string): Promise<Invoice[]>{

    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    return new Promise( resolve => {
      this.httpClient.get<Response>(`${ URL_BACKEND }/invoices/findAllByType/${type}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getInvoiceById(id: string): Promise<Invoice>{
    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient.get<Response>(`${ URL_BACKEND }/invoices/findOne/${id}`, { headers })
            .subscribe( resp => {
                resolve(resp.response);
            });
    });
  }

  async findAllByTypeAndFecha(data: object): Promise<Invoice> {
    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
            'x-token': await this.loadJwToken(),
        });

        this.httpClient.post<Response>(`${URL_BACKEND}/invoices/findAllByTypeAndFecha`, data,  { headers })
          .subscribe((resp: Response) => {
            console.log(resp);
            resolve(resp.response);
        }, error => {
            console.log('error', error);
        });
    });
  }

  setInvoiceSeleccionado(invoice: any) {
    this.invoice = invoice;
    this.storageService.setInvoice(invoice);
  }

  getInvoiceSeleccionado() {
    return this.invoice;
  }

}


