
<div *ngIf="documentosEstadosSolicitar.length <=0" class="alert alert-success" role="alert">
    AVISO: Los documentos requeridos para su solicitud ya están en nuestro sistema. Por favor, espere mientras su solicitud se encuentra en proceso de PRE-APROBACIÓN. Le informaremos una vez que haya sido revisada por el departamento administrativo.    
</div>
<div *ngIf="documentosEstadosSolicitar.length >0"  class="alert alert-warning" role="alert" role="alert">
    Documentos Pendientes de entrega.    
</div>

<div *ngIf="activarCargaDocumentosOpcionales && documentosEstadosSolicitar.length >=0">
    <div class="form-group" *ngFor="let doc of documentosEstadosSolicitar; let i = index">
        <button (click)="openDialogDocUpload('cargue su archivo...',doc.documento._id, i)" *ngIf="!doc.entregado">{{doc.documento.nombre}}<span></span></button>
        <button *ngIf="doc.entregado" disabled>{{doc.documento.nombre}}<span></span></button>
    </div>
</div>

<!-- <div *ngIf="!activarCargaDocumentosOpcionales">
    <div *ngFor="let doc of documentosEstadosSolicitar; let i = index">
        <button disabled>{{doc.documento.nombre}}<span></span></button>
    </div>
</div> -->
<app-loading *ngIf="flaProcesando" class="m-5"></app-loading><br>