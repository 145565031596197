<div class="order-details">
    <h3 class="title">Curso {{ this.curso.nombre }}</h3>
    <div class="order-table table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">Curso</th>
                    <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody>

                <tr>

                    <td class="product-name">
                        <span>Costo No reembolsable</span>
                    </td>
                    <td class="product-total">
                        <span class="order-subtotal-amount">USD $ {{ curso.precio }}</span>
                    </td>

                </tr>

                <tr>
                </tr>

                <tr>
                    <td class="total-price">
                        <span>Total Curso</span>
                    </td>
                    <td class="product-subtotal">
                        <span class="subtotal-amount">USD $ {{ curso.precio }}</span>
                    </td>
                </tr>

                <div class="alert alert-warning m-3" width="100%" role="alert">
                    Una vez finalizado el pago, tendras acceso al curso.
                </div>

            </tbody>
        </table>
    </div>
</div>