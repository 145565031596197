<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{ idioma | translate: "navbar.UL-INICIO" | async}}</a></li>
                <li>{{ idioma | translate: "home.PLATAFORMA-GUIA" | async}}</li>
            </ul>
            <h2>{{ idioma | translate: "home.PLATAFORMA-GUIA" | async}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="purchase-guide-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-6">
                <h1>{{ idioma | translate: "guias.plataforma.TITULO" | async}}</h1>
                <img src="assets/img/plataforma-guia/plataforma-guia.jpg" alt="aadidess.cipbyte.io">
            </div>
            <div class="col-lg-3"></div>
        </div>
        <div class="row">
            <div class="col-lg-5">
                <div class="purchase-guide-content">
                    <h1>{{ idioma | translate: "oferta-laboral.BUSCADOR-TITULO" | async}}</h1>
                    <h3>{{ idioma | translate: "guias.plataforma.OFERTAS-TITULO-1" | async}}</h3>
                    <p>{{ idioma | translate: "guias.plataforma.OFERTAS-PARRAFO-1" | async}}</p>
                    <h3>{{ idioma | translate: "guias.plataforma.OFERTAS-TITULO-2" | async}}</h3>
                    <p>{{ idioma | translate: "guias.plataforma.OFERTAS-PARRAFO-2" | async}}</p>
                    <p>{{ idioma | translate: "guias.plataforma.OFERTAS-PARRAFO-2.1" | async}}</p>
                    <ul>
                        <li>{{ idioma | translate: "guias.plataforma.OFERTAS-PARRAFO-2.1.1" | async}}</li>
                        <li>{{ idioma | translate: "guias.plataforma.OFERTAS-PARRAFO-2.1.2" | async}}</li>
                        <li>{{ idioma | translate: "guias.plataforma.OFERTAS-PARRAFO-2.1.3" | async}}</li>
                        <li>{{ idioma | translate: "guias.plataforma.OFERTAS-PARRAFO-2.1.4" | async}}</li>
                        <li>{{ idioma | translate: "guias.plataforma.OFERTAS-PARRAFO-2.1.5" | async}}</li>
                    </ul>
                    <h3>{{ idioma | translate: "guias.plataforma.OFERTAS-TITULO-3" | async}}</h3>
                    <p>{{ idioma | translate: "guias.plataforma.OFERTAS-PARRAFO-3" | async}}</p>

                </div>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-6 col-md-12">
                <div class="purchase-guide-content">
                    <br><br>
                    <h3>{{ idioma | translate: "guias.plataforma.OFERTAS-TITULO-4" | async}}</h3>
                    <p>{{ idioma | translate: "guias.plataforma.OFERTAS-PARRAFO-4" | async}}</p>

                    <h3>{{ idioma | translate: "guias.plataforma.OFERTAS-TITULO-5" | async}}</h3>
                    <p>{{ idioma | translate: "guias.plataforma.OFERTAS-PARRAFO-5" | async}}</p>

                    <h3>{{ idioma | translate: "guias.plataforma.OFERTAS-TITULO-6" | async}}</h3>
                    <p>{{ idioma | translate: "guias.plataforma.OFERTAS-PARRAFO-6" | async}}</p>

                    <blockquote class="blockquote">
                        <p style="text-align: center !important;"><strong>{{ idioma | translate: "login.TITULO-BIENVENIDA" | async}}</strong></p>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
</div>