<h1 mat-dialog-title>Agregar el examen <strong>{{this.data.nombreExamen}}</strong> a Boletines</h1>
<h5>Se agregará la siguiente materia: <strong>{{this.data.materiaNombre}}</strong> al Boletin seleccionado.</h5>
<p>Seleccione un Boletin a continuación.</p>
<div mat-dialog-content class="mat-elevation-z8">
    <div class="table-container" *ngIf="!isLoading; else loading">
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event)" placeholder="Filtrar">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort class="mat-table">

            <!-- Nombre Boletin Column -->
            <ng-container matColumnDef="nombreBoletin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Boletín </th>
                <td mat-cell *matCellDef="let element"> {{element.boletin.nombre}} </td>
            </ng-container>

            <!-- Acciones Column -->
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef> Acciones </th>
                <td mat-cell *matCellDef="let element">
                    <button style="background-color: #fbc02d; height: 36px;" mat-button (click)="agregarMateria(element)">Agregar a Boletin</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
    <ng-template #loading>
        <div class="loading-container">
            <mat-spinner></mat-spinner>
            <p>Cargando datos...</p>
        </div>
    </ng-template>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" style="margin-left: auto;">Cerrar</button>
</div>