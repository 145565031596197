import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Actividad } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Pais } from '../../../../interfaces/interface-bakend/shared/paises/pais.interfaces';
import { Catalogo } from '../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { APP_CONFIG_SHARED, IAppShared } from '../../../../app.config/app.config-shared';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { CatalogoService } from '../../../../services/shared/catalogo.service';
import { GuiToolsService } from '../../../../services/shared/gui-tools.service';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { UserList } from '../../../../interfaces/interface-bakend/seguridad/users/personas.interface';
import { Translators } from '../../../../services/shared/translators.service';
import { Observable, Subscription } from 'rxjs';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../../../app.config/app.config-ofertas';
import { PaisesService } from '../../../../services/shared/paises.service';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PersonasService } from 'src/app/services/shared/personas.service';
import { ModalConfirmarComponent } from '../../../ofertas-laborales/pages/solicitud-postular/modal-confirmar/modal-confirmar.component';
import { ModalConfirmarModificacionComponent } from './modal-confirmar/modal-confirmar-mofificacion.component';
import { Perfil } from 'src/app/interfaces/interface-bakend/seguridad/acl/perfil.interface';
import { AlumnoTitulo, Disciplina, Titulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { MatPaginator } from '@angular/material/paginator';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import Swal from 'sweetalert2'
import { MatTableDataSource } from '@angular/material/table';
import { Alumno } from 'src/app/interfaces/interface-bakend/cursos/alumno.interface';
import { MatSort } from '@angular/material/sort';
import { PersonasListadoComponent } from '../personas-listado/personas-listado.component';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-persona-modificacion',
  templateUrl: './persona-modificacion.component.html',
  styleUrls: ['./persona-modificacion.component.scss']
})
export class PersonaModificacionComponent implements OnInit {

  usuario: User;
  forma: FormGroup;

  actividad: Actividad;
  pais: PaisDisponible;
  nombrePais: string;
  nombreActividad: string;
  paises: Pais[] = [];
  paisCodeAreas: Pais[] = [];
  estadosCiviles: Catalogo[] = [];
  tiposIdentificaciones: Catalogo[] = [];
  paisDondeAplicaFormas: Catalogo[] = [];

  selectedEstadoCivil: Catalogo;
  selectedTipoIdentificacion: Catalogo;
  selectedCondiMigra: Catalogo;
  selectedPais: Pais;

  flagLoaded = false;
  flagProcesando = false;
  flagFormaValido = false;
  flagExisteUsuarioConIdentificacion = false;
  flagExisteIdentificacion = false;
  flagExisteEmail = false;
  flagPreCarga = false;
  flagoConfirmData = false;
  flagCrear = false;
  showSpinner = false;
  flagFormCompleto = false;
  flagEmailCorrecto = false;
  flagCargandoTitulos = false;
  flagDocumentosUsuariosCargados = false;

  idiomas: Catalogo[] = [];

  /* Imagenes y Archivos del Usuario*/
  imgs: string[] = [];
  files: string[] = [];

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
    [
      'nombre', 'disciplina', 'otorgamiento', 'vencimiento', 'certifica', 'observaciones', '_id'
    ];
  dataSource: any;
  formTitulos: FormGroup;
  selectPerfiles: Perfil[];
  flagDisableButton = false;
  flagValidForma = false;
  estado = true;
  isChecked = true;
  emailChecked: boolean = false;
  titulos: Titulo[] = [];
  disciplinas: Disciplina[] = [];
  titulosDelAlumno: AlumnoTitulo[] = [];
  subscriptions: Subscription;

  data$: Observable<AlumnoTitulo[]>;
  setTitulo: any;
  editaTitulo: boolean = false;
  tituloSeleccionado: Titulo;


  idioma: Subscription;

  // Observable para los títulos filtrados
  filteredTitulos: Observable<any[]>;
  formControlTitulo = new FormControl();

  constructor(
    @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
    @Inject(APP_CONFIG_SHARED) private iAppShared: IAppShared,
    private paisesService: PaisesService,
    private actividadesService: ActividadesService,
    private catalogoService: CatalogoService,
    private usarioService: UsuarioService,
    private authservice: AuthService,
    private guiMsjService: GuiMsjService,
    private guiToolsService: GuiToolsService,
    private router: Router,
    private usuariosService: UsuarioService,
    public dialog: MatDialog,
    private translatorService: Translators,
    private personasService: PersonasService,
    private cursosService: CursosService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PersonasListadoComponent>,
  ) {

    // Inicializamos los títulos filtrados con todos los títulos al principio
    // this.filteredTitulos = this.formControlTitulo.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this.filterTitulos(value))
    // );

    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
      this.guiToolsService.refreshComponent('/solicitud-postular');
    });

    this.createForm();
  }

  ngOnInit(): void {
    this.getData();
  }


  // Método para filtrar los títulos en función de lo que el usuario escriba
  filterTitulos(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.titulos.filter(titulo => titulo.nombre.toLowerCase().includes(filterValue));
  }

  async loadComponentTitulo() {
    this.flagCargandoTitulos = true;
    await this.getDisciplinas();
    await this.getTitulos();
    await this.loadDataTableTitulo();
    this.flagCargandoTitulos = false;
  }

  async getArrayDocumentUserImgsAndFile(adjuntos: string[]) {
    await adjuntos.forEach(element => {
      if (element.search('.pdf') !== -1) {
        this.files.push(element);
      } else {
        this.imgs.push(element);
      }
    });
  }

  async getDocumentosUser() {
    try {
      await this.getArrayDocumentUserImgsAndFile(this.usuario?.adjuntos)
    } catch (error) {
      console.error(`No se encontraron los adjuntos del usuario con identificacion${this.usuario?._id}`);
    }
  }

  async getData() {

    await this.getPaises();
    await this.getEstadosCiviles();
    await this.getTipoIdentificaciones();
    await this.getPaisDondeAplicaFormas();

    if (this.personasService.getPersonaSeleccionada() != undefined) {
      this.usuario = this.personasService.getPersonaSeleccionada();
      await this.cargarForm(this.usuario);
      await this.loadComponentTitulo();
      await this.getDocumentosUser();
      this.flagDocumentosUsuariosCargados = true;
    } else {
      this.flagCrear = true;
    }


    this.flagLoaded = true;
  }

  /* Métodos form */

  async onSubmit() {

    if (await this.validarEmailIdentificacionModificar()) {

      /* 0- confirmo datos
      /* 1- update user */
      /* 2- update solicitud estado */
      /* 3- refreshing state solicitud */

      const usuarioUpdated: User = await this.setDataForma();

      this.flagoConfirmData = await this.openDialogConfirmData(usuarioUpdated);

      if (this.flagoConfirmData) {
        this.flagFormaValido = false;
        this.flagProcesando = true;
        this.usuarioUpdate(usuarioUpdated);
      } else {
        return;
      }

    }

  }

  createForm() {
    this.forma = new FormGroup({
      nombre: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      apellido: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      tipoIdentificacion: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      identificacion: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      paisNacimiento: new FormControl('',),
      estadoCivil: new FormControl('',),
      paisCodeArea: new FormControl('',),
      telefono: new FormControl('',),
      email: new FormControl('', [Validators.required, Validators.email]),
      ca: new FormControl('',),
      es: new FormControl('',),
      fr: new FormControl('',),
      en: new FormControl('',),
      birth: new FormControl(
        // this.guiToolsService.datePipeTransform
        // (
        //    new Date().setFullYear(new Date().getFullYear() - 21
        // ), 'yyyy-MM-dd'),
      ),
    });

    this.formTitulos = this.formBuilder.group({
      titulo: ['', Validators.required],
      fechaOtorgamiento: [new Date(), Validators.required],
      fechaVencimiento: [new Date(), Validators.required],
      certifica: [true],
      disciplina: ['', Validators.required], // Agregamos el validador aquí
      observaciones: ['']
    });

  }

  async cargarForm(usuario: User) {
    let setDate;
    if (usuario.birth !== undefined) {
      const formattedBirthDate = this.guiToolsService.datePipeTransform(usuario.birth, 'yyyy-MM-dd');
      const birthDate = formattedBirthDate ? new Date(formattedBirthDate) : new Date();
      setDate = this.guiToolsService.datePipeTransform(birthDate.setDate(birthDate.getDate() + 2), 'yyyy-MM-dd')
    } else {
      setDate = new Date();
    }

    if (usuario) {
      this.forma.setValue({
        nombre: usuario.nombrePersona || '',
        apellido: usuario.apellidoPersona || '',
        tipoIdentificacion: usuario.tipoIdentificacion?.concepto || null,
        identificacion: usuario.identificacion || '',
        birth: setDate,
        paisNacimiento: usuario.paisNacimiento?.name_es || null,
        estadoCivil: usuario.estadoCivil?.concepto || null,
        paisCodeArea: usuario.paisCodeArea || null,
        telefono: usuario.telefono || '',
        email: usuario.email || '',
        ca: usuario.idiomaCa || false,
        es: usuario.idiomaEs || false,
        fr: usuario.idiomaFr || false,
        en: usuario.idiomaEn || false,
        // ...otros campos...
      });
    } else {
      // Si usuario es nulo o no contiene información, proporciona valores predeterminados o asigna null a todos los campos.
      this.forma.setValue({
        nombre: '',
        apellido: '',
        tipoIdentificacion: null,
        identificacion: '',
        birth: new Date(),
        paisNacimiento: null,
        estadoCivil: null,
        paisCodeArea: null,
        telefono: '',
        email: '',
        ca: false,
        es: false,
        fr: false,
        en: false,
        // ...otros campos...
      });
    }

    // cargo los selectores
    this.selectedTipoIdentificacion = usuario.tipoIdentificacion;
    this.selectedEstadoCivil = usuario.estadoCivil;
    this.selectedPais = usuario.paisNacimiento;

    this.flagFormaValido = true;
  }

  async setDataForma(): Promise<User> {

    const dataForma: User = {
      nombrePersona: this.forma.value.nombre.toUpperCase(),
      apellidoPersona: this.forma.value.apellido.toUpperCase(),

      tipoIdentificacion: this.selectedTipoIdentificacion,
      email: this.forma.value.email,

      identificacion: this.forma.value.identificacion,

      birth: new Date(this.forma.value.birth).toISOString(),

      paisNacimiento: this.selectedPais,

      estadoCivil: this.selectedEstadoCivil,
      paisCodeArea: this.forma.value.paisCodeArea,
      telefono: this.forma.value.telefono,

      idiomaCa: this.forma.value.ca || false,
      idiomaEs: this.forma.value.es || false,
      idiomaFr: this.forma.value.fr || false,
      idiomaEn: this.forma.value.en || false,
      fromApp: false,
      fromWeb: true,

      esPersona: true,
    };

    return dataForma;

  }

  /* FIN Métodos form */

  usuarioUpdate(usuarioUpdated: User) {
    this.usarioService.updateUser(
      this.personasService.getPersonaSeleccionada()._id,
      usuarioUpdated
    ).then((usuario: User) => {
      if (usuario) {
        this.flagProcesando = false;
        this.guiMsjService.msjFormSubmit('personaModificadaOK');
      } else {
        this.guiMsjService.msjFormSubmit('personaModificadaError');
      }
    });
  }

  async validarIdentificacionModificada() {
    return new Promise(async resolve => {
      this.flagExisteIdentificacion = await this.usuariosService.getExistsTipoAndIdentificacion(this.selectedTipoIdentificacion._id, this.forma.value.identificacion);
      resolve(this.flagExisteIdentificacion);
    });
  }

  async validarEmail(): Promise<boolean> {
    return new Promise(resolve => {
      this.usarioService.getFindOneByEmail(this.forma.value.email).then((resp: User) => {
        if (resp !== null && resp.identificacion !== undefined) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  onChangePais(event): void {
    this.selectedPais = this.paises.filter(x => x.name_es === event)[0];
  }

  onChangeTipoIdentificacion(event): void {
    this.selectedTipoIdentificacion = this.tiposIdentificaciones.filter(x => x.concepto === event)[0];
  }

  onChangeCondiMigra(event): void {
    this.selectedCondiMigra = this.paisDondeAplicaFormas.filter(x => x.concepto === event)[0];
  }

  onChangeEstadoCivil(event): void {
    this.selectedEstadoCivil = this.estadosCiviles.filter(x => x.concepto === event)[0];
  }

  onChangeEmail(event): void {
    this.flagExisteEmail = false;
  }

  onChangeIdentificacion(event): void {
    this.flagExisteIdentificacion = false;
  }

  async getPaises() {
    await this.paisesService.getPaises().then((resp: Pais[]) => {
      this.paises = resp;
    });
  }

  getEstadosCiviles() {
    this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.estadoCivil,
      this.translatorService.getIdioma()).
      then((resp: Catalogo[]) => {
        return this.estadosCiviles = resp;
      });
  }

  getTipoIdentificaciones() {
    this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.tiposIdentificaciones, this.translatorService.getIdioma())
      .then((resp: Catalogo[]) => {
        this.tiposIdentificaciones = resp;
        // this.tiposIdentificaciones = this.tiposIdentificaciones.filter(item => item._id === '6272eb83577d4b3218ec6ba6');
      });
  }

  getPaisDondeAplicaFormas() {
    this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.paisDondeAplica,
      this.translatorService.getIdioma()).then((resp: Catalogo[]) => {
        return this.paisDondeAplicaFormas = resp;
      });
  }

  getIdiomas() {
    this.catalogoService.findDescendantsByKey(this.iAppShared.tiposIdioma).then((resp: Catalogo[]) => {
      return this.idiomas = resp;
    });
  }

  reset() {
    this.formTitulos.reset();
  }

  openDialogConfirmData(data: User): Promise<boolean> {

    return new Promise<boolean>(resolve => {
      const dialogRef = this.dialog.open(ModalConfirmarModificacionComponent, {
        width: '800px',
        height: '800px',
        disableClose: true,
        data
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        resolve(result);
      });
    });

  }

  async crearNuevaPersona() {

    if (await this.validarEmailIdentificacionCrear()) {

      const nuevaPersona: User = await this.setDataForma();
      this.flagoConfirmData = await this.openDialogConfirmData(nuevaPersona);

      if (this.flagoConfirmData) {
        this.flagFormaValido = false;
        this.flagProcesando = true;
        // Llama al servicio para crear la nueva persona
        this.usarioService.createUser(await this.setDataForma())
          .then((usuarioCreado: User) => {
            if (usuarioCreado?._id) {
              this.flagProcesando = false;
              this.showSpinner = false;
              this.dialog.closeAll()
              this.guiMsjService.msjFormSubmit('personaCreadaOK');
            } else {
              this.flagProcesando = false;
              this.showSpinner = false;
              this.guiMsjService.msjFormSubmit('personaCreadaError');
              this.router.navigateByUrl('/personas-listado');
            }
            this.showSpinner = false;
          });
      } else {
        return;
      }
    };
  }

  async validarEmailIdentificacionModificar() {
    const flagEmail = await this.validarEmail();
    const flagIdentificacion = await this.validarIdentificacionModificada();

    const identificacion = this.forma.value.identificacion;
    const email = this.forma.value.email;

    let flag = false;


    if (flagIdentificacion && this.personasService.getPersonaSeleccionada().identificacion !== identificacion) {
      alert('ATENCIÓN: El NRO DE INDENTIFIACIÓN INGRESADO EXISTE');
      this.flagExisteIdentificacion = true;
      return false;
    } else {
      this.flagExisteIdentificacion = false;
      flag = true;
    }


    if (flagEmail && this.personasService.getPersonaSeleccionada().email !== email) {
      alert('ATENCIÓN: EL EMAIL INGRESADO EXISTE');
      this.flagExisteEmail = true;
      return false;
    } else {
      this.flagExisteEmail = false;
      flag = true;
    }

    return flag;

  }

  async validarEmailIdentificacionCrear() {
    const flagEmail = await this.validarEmail();
    const flagIdentificacion = await this.validarIdentificacionModificada();

    let flag = false;

    if (flagIdentificacion) {
      alert('ATENCIÓN: El NRO DE INDENTIFIACIÓN INGRESADO EXISTE');
      this.flagExisteIdentificacion = true;
      return false;
    } else {
      this.flagExisteIdentificacion = false;
      flag = true;
    }


    if (flagEmail) {
      alert('ATENCIÓN: EL EMAIL INGRESADO EXISTE');
      this.flagExisteEmail = true;
      return false;
    } else {
      this.flagExisteEmail = false;
      flag = true;
    }

    return flag;
  }

  /* TITULO */

  async seleccionarTitulo(titulo: string) {
    if (titulo != null) {
      await this.cursosService.tituloGetOneById(titulo).then((titulo: Titulo) => {
        this.tituloSeleccionado = titulo;
        if (this.editaTitulo === false) {
          const fechaVencimiento = new Date();
          if (titulo.mesesVigencia) {
            fechaVencimiento.setMonth(fechaVencimiento.getMonth() + titulo.mesesVigencia)
            this.formTitulos.controls['fechaVencimiento'].setValue(fechaVencimiento);
          } else {
            this.formTitulos.controls['fechaVencimiento'].setValue(new Date());
          }
        }
      })
    }
  }

  async editarTituloAlumno(data: AlumnoTitulo): Promise<void> {
    this.editaTitulo = true;
    this.setTitulo = data;
    this.formTitulos.setValue({
      titulo: data.titulo._id,
      disciplina: data.disciplina._id,
      fechaOtorgamiento: data.fechaOtorgamiento,
      fechaVencimiento: data.fechaVencimiento,
      observaciones: data.observaciones,
      certifica: data.certifica
    })
  }

  async borrarTitulo(id: string) {
    Swal.fire({
      title: 'Confirma borrar el titulo al alumno?',
      showDenyButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `No borrar`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await this.guiMsjService.msjFormSubmit('Guardando');
        await this.cursosService.borarTituloAlumno(id).then(async () => {
          await this.loadComponentTitulo();
          await this.guiMsjService.msjFormSubmit('DatosGuardados');
        }).catch((error) => {
          console.warn(error)
        })
      }
    })
  }

  async agregarTitulo() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    const alumno: Alumno = await this.cursosService.findAlumnoByUsuario(this.personasService.getPersonaSeleccionada()._id);

    if (this.formTitulos.valid) {

      const alumnoTitulo: AlumnoTitulo = {
        titulo: this.formTitulos.value.titulo,
        alumno: alumno._id,
        fechaOtorgamiento: this.formTitulos.value.fechaOtorgamiento,
        fechaVencimiento: this.formTitulos.value.fechaVencimiento,
        certifica: this.formTitulos.value.certifica ? true : false,
        disciplina: this.formTitulos.value.disciplina,
        observaciones: this.formTitulos.value.observaciones
      };


      if (!this.editaTitulo) {
        await this.cursosService.postTituloAlumno(alumnoTitulo).then(async () => {
          await this.loadDataTableTitulo();
          await this.guiMsjService.msjFormSubmit('DatosGuardados');
          this.formTitulos.reset();
        }).catch(error => {
          console.log(error);
        })
      } else {
        await this.cursosService.updateTituloAlumno(this.setTitulo._id, alumnoTitulo).then(async () => {
          await this.loadDataTableTitulo();
          await this.guiMsjService.msjFormSubmit('DatosGuardados');
          this.formTitulos.reset();
          this.editaTitulo = false;
        }).catch(error => {
          console.log(error);
        })
      }

    } else {
      alert('Le falta cargar algúnn campo')
    }
  }

  async getTitulos() {
    await this.cursosService.getTitulos().then((titulos: Titulo[]) => {
      this.titulos = titulos.sort((a, b) => a.nombre.localeCompare(b.nombre));
    });
  }

  async getDisciplinas() {
    await this.cursosService.getDisciplinas().then((disciplinas: Disciplina[]) => {
      this.disciplinas = disciplinas;
    });
  }

  async loadDataTableTitulo() {
    await this.cursosService.getTitulosAlumnosFindAllByAlumno(await (await this.cursosService.findAlumnoByUsuario(this.personasService.getPersonaSeleccionada()._id))._id).then((titulos: AlumnoTitulo[]) => {
      this.dataSource = new MatTableDataSource(titulos);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
  }

  onClose(): void {
    this.dialogRef.close();
  }


  /* Validaciones */

  get nombre() {
    return this.forma.get('nombre').invalid && this.forma.get('nombre').touched;
  }

  get apellido() {
    return this.forma.get('apellido').invalid && this.forma.get('apellido').touched;
  }

  get tipoIdentificacion() {
    return this.forma.get('tipoIdentificacion').invalid && this.forma.get('tipoIdentificacion').touched;
  }

  get identificacion() {
    return this.forma.get('identificacion').invalid && this.forma.get('identificacion').touched;
  }

  get birth() {
    return this.forma.get('birth').invalid && this.forma.get('birth').touched;
  }

  get paisNacimiento() {
    return this.forma.get('paisNacimiento').invalid && this.forma.get('paisNacimiento').touched;
  }

  get estadoCivil() {
    return this.forma.get('estadoCivil').invalid && this.forma.get('estadoCivil').touched;
  }

  get paisCodeArea() {
    return this.forma.get('paisCodeArea').invalid && this.forma.get('paisCodeArea').touched;
  }

  get condiMigra() {
    return this.forma.get('condiMigra').invalid && this.forma.get('condiMigra').touched;
  }

  get telefono() {
    return this.forma.get('telefono').invalid && this.forma.get('telefono').touched;
  }


}
