import { Component, OnInit, Output, EventEmitter ,ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { Curso } from '../../../../interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { ArchivoLeccion } from '../../../../interfaces/interface-bakend/cursos/archivoLeccion.interface';
import { Examen } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import Swal from 'sweetalert2';
import { AppConfigService } from 'src/app/services/shared/app-config.service';
import { CursoAlumno } from 'src/app/interfaces/interface-bakend/cursos/cursoAlumno.interface';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-curso-habilitado-alumno',
  templateUrl: './curso-habilitado-alumno.component.html',
  styleUrls: ['./curso-habilitado-alumno.component.scss']
})
export class CursoHabilitadoAlumnoComponent implements OnInit {

  curso: Curso;
  examenes: any[];
  examenesRecuperables: any[];
  flagCargado = false;
  flagEmpty = false;
  tipoArchivo: string;
  pdf: string;
  mp4: string;
  img: string;
  horaServer: Date;
  vacio: string;
  leccionActual: string;
  materialActual: string;
  descripcionlActual: string;
  fechaAprobacion: Date;
  cursoFinalizado: boolean = false;
  asignacion: string = null;
  asignacionRecuperable: string = null;
  selectedPanelIndex: number = -1; // Inicialmente no hay ningún panel seleccionado
  // @ViewChild('scrollToTop') scrollToTop: ElementRef;

  constructor(
    private cursosService: CursosService,
    private router: Router,
    private guiMsjService: GuiMsjService,
    private examenesService: ExamenesService,
    private appConfigService: AppConfigService,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
    this.getEstadoVariables();
    this.appConfigService.getServerTime().then((hora:Date)=>{
      this.horaServer = new Date(hora);
    });
  }

  async getEstadoVariables(){
    if (this.cursosService.getCursoSeleccionado() === undefined) {
        // Aca poner la pagina de "Mis Cursos"
      this.router.navigateByUrl('/cursos-perfil-alumno');
      return;
    }else {    
      await this.getCursoFindOne();
      await this.getExamenesCurso();
      await this.getExamenesRecuperables();
      await this.getCursoFinalizado();    
      this.flagCargado = true;

    }
  }

  async getCursoFindOne(){
    await this.cursosService.getCursoFindOne(this.cursosService.getCursoSeleccionado().id)
    .then((cursoSeleccionado: Curso) => {
      this.curso = cursoSeleccionado;
      this.vacio = cursoSeleccionado.imagen;
      this.flagEmpty = true;
    });
  }

  async getExamenesCurso(){
    await this.cursosService.getExamenesByCurso(this.cursosService.getCursoSeleccionado().id).then((data: Examen[]) => {
      console.log('examenes', data);
      this.examenes = data;
      let asignacion: string = null;
      for (let i = 0; i < data.length; i++) {      
        this.asignacion = data[i]._id;    
      }
    })
  }

  async getCursoFinalizado(){
    const _idUsuario = (await this.cursosService.getByUserLogon())?._id
    if (_idUsuario) {
      await this.cursosService.getCursoAlumnoEstado(_idUsuario,this.cursosService.getCursoSeleccionado().id).then((resp)=>{
        console.log('resp =>', resp);
        resp.forEach(x => {
          if(x.aprobado){ //          if(x.aprobado && this.curso._id ===  x.curso){
            this.cursoFinalizado = true;
            this.fechaAprobacion = x.fechaAprobacion;
          }
        })
      })
    } else {
      console.log('sin id - getCursoAlumnoEstado');
    }
  }

  async getExamenesRecuperables(){
    await this.cursosService.getExamenesByCursoRecuperables(this.cursosService.getCursoSeleccionado().id).then((data: Examen[])=>{
      this.examenesRecuperables = data;
      let asignacionRecuperable : string = null;
      for (let i = 0; i < data.length; i++) {      
        this.asignacionRecuperable = data[i]._id;    
      }
    })
  }

  async verExamen(examen: string, asignacion: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const examenIntroduccion: Examen = await this.examenesService.getExamenFindOne(examen);
    examenIntroduccion.curso = this.cursosService.getCursoSeleccionado().id;
    examenIntroduccion.asignacion = asignacion;
    this.examenesService.setExamenSeleccionado(examenIntroduccion);
    this.router.navigateByUrl('/examen-introduccion');
  }

  async verRecuperatorio(examen: string, asignacionRecuperable: string){
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const examenRecuperatorio: Examen = await this.examenesService.getExamenFindOne(examen);
    examenRecuperatorio.curso = this.cursosService.getCursoSeleccionado().id;
    examenRecuperatorio.asignacion = asignacionRecuperable;
    if(examenRecuperatorio != undefined){
      await this.examenesService.setExamenSeleccionado(examenRecuperatorio).then(()=>{
        this.router.navigateByUrl('/examen-recuperatorio');
      });
      
    }
  }

  mostrarContenido(archivo: ArchivoLeccion, materia, leccion) {
    // this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.scrollToTop();
    
    this.leccionActual = archivo.nombreLeccion;
    this.materialActual  = materia.nombre;
    this.descripcionlActual  = leccion.descripcion;
    this.tipoArchivo = archivo.type;
    this.flagEmpty = false;

    switch (archivo.type) {
      case 'pdf':
        this.pdf = archivo.source;
        break;
      case 'mp4':
        this.mp4 = archivo.source;
        break;
      case 'img':
        this.img = archivo.source;
        break;
      default:
        this.flagEmpty = true;
        break;
    }
  }

  scrollToTop(): void {
    // Verifica si el ancho de la ventana es menor que 768px (tamaño típico de dispositivos móviles)
    if (window.innerWidth < 768) {
      // Desplaza hasta la parte superior de la página
      this.viewportScroller.scrollToPosition([0, 0]);
    }
  }

  isExamenVencido(fechaVencimiento: string): boolean {
    const fechaVencimientoExamen = new Date(fechaVencimiento);
    const fechaHoy = new Date();
    return fechaVencimientoExamen < fechaHoy;
  }

  isExamenRecuperableVencido(fechaVencimiento: string): boolean {
    if (!fechaVencimiento) {
        return false; // Manejar caso donde la fecha de vencimiento es null o undefined
    }
    const fechaVencimientoExamen = new Date(fechaVencimiento);
    const fechaHoy = new Date();
    return fechaVencimientoExamen < fechaHoy;
 }

 
}
