<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Profesores</a></li>
                <li>Configurar Profesores</li>
            </ul>
            <h2>Configurar Profesores</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="features-area pb-70 animate__animated animate__fadeIn">
    <div class="container">

        <div class="row m-4">

            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-list-ul"></i>
                    </div>
                    <h3>Listado de Profesores</h3>
                    <p>Acceda al listado de Profesores</p>
                    <a routerLink="/profesores-listado" class="btn btn-info">Ver Profesores</a>
                </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-plus-square"></i>
                    </div>
                    <h3>Nuevo Profesor</h3>
                    <p>Dar de alta un Profesor</p>
                    <a routerLink="/profesor-alta" class="btn btn-success">Dar de Alta Profesor</a>
                </div>
            </div>

        </div>
    </div>