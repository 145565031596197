<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li>Configurar Cátedras</li>
            </ul>
            <h2>Configurar Cátedras</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="features-area pb-70 animate__animated animate__fadeIn">
    <div class="container">

        <div class="row m-4">

            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-list-ul"></i>
                    </div>
                    <h3>Listado de Cátedras</h3>
                    <p>Acceda al listado de Cátedras</p>
                    <a routerLink="/lecciones-listado" class="btn btn-primary">Ver Cátedras</a>
                </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-plus-square"></i>
                    </div>
                    <h3>Nueva Cátedra</h3>
                    <p>Cree una nueva configuración de Cátedras</p>
                    <a routerLink="/catedra-alta" class="btn btn-success">Crear Cátedra</a>
                </div>
            </div>
    
        </div>
    </div>