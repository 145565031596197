import { Curso } from './../../../../interfaces/interface-bakend/cursos/curso.interface';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cursos-detalle-items',
  templateUrl: './cursos-detalle-items.component.html',
  styleUrls: ['./cursos-detalle-items.component.scss']
})
export class CursosDetalleItemsComponent implements OnInit {

    @Input() curso: Curso;
    @Output() EcostoPagar = new EventEmitter<number>();

    nombreCurso: string;

    constructor() { }

    ngOnInit(): void {
       this.loadDataInput();
    }

    async loadDataInput(){
        await this.emitCotoPagar(this.curso);
        this.getNombreCurso();
    }

    emitCotoPagar(curso: Curso): void {
        return this.EcostoPagar.emit(curso.precio);
    }

    getNombreCurso(){
        this.nombreCurso = this.curso.nombre;
    }

}
