<div class="animate__animated animate__fadeIn">

    <div class="page-title-area-modificado">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/dashboard">Inicio</a></li>
                    <li><a routerLink="/cursos-perfil-alumno">Mis Cursos</a></li>                                    
                </ul>
            </div>
        </div>
    </div>

    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="!flagCargado"></mat-progress-bar>
    <ng-container *ngIf="!flagCargado">
        <div class="mt-2">
          Espere por favor... Cargando contenido.
        </div>
    </ng-container>    
  
    <div *ngIf="flagCargado" class="courses-details-area pb-100">
        <div class="courses-details-image">
            <img src="assets/img/courses/banner-curso.png" alt="image">
        </div>
        <div class="container-fluid">

            <div class="row contenedor">

                <!-- COLUMNA CONTENIDO -->
                <div class="col-lg-8 col-md-8 col-12">
                    <div class="courses-details-desc">

                        <!-- ****** ACA VA EL COMPONENTE QUE TRAE VIEDO / PDF / EXAMEN ****** -->                       
                        <ng-container *ngIf="leccionActual; else elseTemplate">
                            <div class="section-title align-left">
                                <h3>                                   
                                    <div>
                                        Materia: 
                                        <span class="highlight">{{ materialActual }}</span>
                                    </div>
                                    <div>
                                        Lección: 
                                        <span class="highlight">{{ leccionActual }}</span>
                                    </div>
                                    <div>
                                        Descripción: 
                                        <span class="highlight">{{ descripcionlActual }}</span>
                                    </div>
                                </h3>                                  
                            </div>                            
                        </ng-container>
                        
                        <ng-template #elseTemplate>

                            <div class="section-title align-left">
                                <h3>Curso: {{ curso.nombre }}</h3>
                            </div>                            
                            
                            <div class="alert alert-info">
                                <p>ATENCIÓN! Te sugerimos chequear con frecuencia el calendario académico (Calendario de Ciclo Lectivo) para estar al tanto en caso de que haya modificaciones. </p>
                            </div>
                            
                        </ng-template>

                        <div *ngIf="flagEmpty" class="animate__animated animate__fadeIn">
                            <img [src]=" 1 | mediaCursosPipe: this.vacio  | safe" alt="image">
                        </div>

                        <div *ngIf="tipoArchivo == 'img'" class="animate__animated animate__fadeIn">
                            <img [src]=" 1 | mediaCursosPipe: this.img  | safe" alt="image">
                        </div>

                        <div *ngIf="tipoArchivo == 'pdf'" class="animate__animated animate__fadeIn">
                            <iframe [src]="( 3 | mediaCursosPipe: this.pdf | safeResourceUrl)" type="application/pdf" style="width: 100%; height: 100vh;"></iframe>
                        </div>

                        <div *ngIf="tipoArchivo == 'mp4'" class="animate__animated animate__fadeIn">
                            <iframe [src]="( 2 | mediaCursosPipe: this.mp4 | safeResourceUrl)" type="video/mp4" style="width: 100%; height: 50vh;"></iframe>
                        </div>

                    </div>
               
                    <br>

                    <div class="courses-details-desc">
                        <ngx-tabset>
                            <ngx-tab tabTitle="Descripción general">
                                <div class="courses-overview">
                                    <h4>Descripción del curso</h4>          
                                    <div *ngIf="cursoFinalizado" class="alert alert-warning" role="alert">
                                        Curso finalizado el {{fechaAprobacion | date:'short'}}
                                    </div>             
                                    <p>{{ curso.descripcionGeneral }}</p>
                                </div>
                            </ngx-tab>
                            <ngx-tab tabTitle="Profesores">
                                <div class="courses-instructor">
                                    <div class="single-advisor-box" *ngFor="let profesor of curso.profesores">
                                        <app-profesor-tarjeta-presentacion [profesor]="profesor"></app-profesor-tarjeta-presentacion>
                                    </div>
                                </div>
                                <div *ngIf="curso.profesores.length === 0" class="alert alert-warning" role="alert">
                                    Sin profesores asociados al curso
                                </div>       
                            </ngx-tab>
                            
                            <ngx-tab tabTitle="Examenes">
                                <ul class="list-group" *ngIf="examenes">
                                    <h4>Examenes del curso</h4>
                                    <!-- <li *ngFor="let examen of examenes; let i = index" class="list-group-item d-flex justify-content-between align-items-center">
                                        {{i+1}} {{examen.examen.titulo}} - {{examen.examen.descripcion}} - Fecha Publicación: {{examen.examen.fechaPublicacion | date:'short'}}
                                        <button mat-raised-button mat-button color="primary" (click)="verExamen(examen.examen._id, asignacion)">Ir a examen</button>
                                    </li> -->
                                    <li *ngFor="let examen of examenes; let i = index"
                                        class="list-group-item d-flex justify-content-between align-items-center"
                                        [ngClass]="{'text-danger': isExamenVencido(examen.examen.fechaVencimiento)}">
                                        {{i+1}} {{examen.examen.titulo}} - {{examen.examen.descripcion}} - Fecha Publicación:
                                        {{examen.examen.fechaPublicacion | date:'short'}} / Fecha Vencimiento:
                                        {{examen.examen.fechaVencimiento | date:'short'}}
                                        <button mat-raised-button mat-button color="primary" (click)="verExamen(examen.examen._id, asignacion)">Ir a
                                            examen</button>
                                    </li>
                                </ul>
                                <div *ngIf="examenes && examenes.length === 0" class="alert alert-warning" role="alert">
                                    Sin examenes asociados al curso
                                </div>                             
                            </ngx-tab>
                            <ngx-tab tabTitle="Recuperatorios">
                                <ul class="list-group" *ngIf="examenesRecuperables">
                                    <h4>Recuperatorios de examenes del curso</h4>
                                    <li *ngFor="let examen of examenesRecuperables; let i = index"
                                        class="list-group-item d-flex justify-content-between align-items-center"
                                        [ngClass]="{'text-danger': isExamenRecuperableVencido(examen.examen.fechaVencimiento)}">
                                        {{i+1}} {{examen.examen.titulo}} - {{examen.examen.descripcion}} - Fecha Publicación Recuperatorio:
                                        {{examen.examen.fechaPublicaR1 | date:'short'}} / Fecha Vencimiento:
                                        {{examen.examen.fechaVenceR1 | date:'short'}}
                                        <button mat-raised-button mat-button color="primary"
                                            (click)="verRecuperatorio(examen.examen._id, asignacionRecuperable)">Ir a recuperatorio</button>
                                    </li>
                                    <!-- <li *ngFor="let examen of examenesRecuperables; let i = index" class="list-group-item d-flex justify-content-between align-items-center">
                                        {{i+1}} {{examen.examen.titulo}} - {{examen.examen.descripcion}} - Fecha Publicación Recuperatorio: {{examen.examen.fechaPublicaR1 | date:'short'}}
                                        <button mat-raised-button mat-button color="primary" (click)="verRecuperatorio(examen.examen._id, asignacionRecuperable)">Ir a recuperatorio</button>
                                    </li> -->
                                </ul>
                                <div *ngIf="this.examenesRecuperables.length === 0" class="alert alert-warning" role="alert">
                                    Sin recuperatorios posibles
                                </div>                             
                            </ngx-tab>
                            <ngx-tab *ngIf="!cursoFinalizado" tabTitle="Zoom - Clases En Línea">
                                <div class="courses-instructor">
                                    <div class="single-advisor-box">
                                        <app-actividades-cursos [cursoActividad]="this.curso.actividades"></app-actividades-cursos>
                                    </div>
                                </div>
                            </ngx-tab>

                            <ngx-tab *ngIf="!cursoFinalizado" tabTitle="Calendario de Ciclo Lectivo">
                                <div class="courses-instructor">
                                    <div class="single-advisor-box">
                                        <app-calendario-curso-on-line [nombreCurso]="this.curso.nombre"></app-calendario-curso-on-line>
                                    </div>
                                </div>
                            </ngx-tab>
                        </ngx-tabset>
                    </div>
                </div>

                <!-- COLUMNA ITEM CUROS -->
                <div class="col-lg-4 col-md-4 col-12">

                    <div class="section-title align-left">
                        <h3>Contenido del curso - Materias</h3>
                    </div>

                    <mat-accordion *ngIf="curso.materias && curso.materias.length > 0">
                        <mat-expansion-panel *ngFor="let materia of curso.materias; let i = index">
                            <mat-expansion-panel-header>
                                <mat-panel-title>                              
                                  <strong>Materia: </strong>   {{ materia.nombre }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="list-group">
                                <app-item-leccion *ngFor="let leccion of materia.lecciones; let j = index"
                                                   (leccionSeleccionada)="mostrarContenido($event, materia, leccion)"
                                                   [typeContent]="tipoArchivo"
                                                   [orden]="j+1"
                                                   [leccion]="leccion"
                                                   [materia]="materia">
                                </app-item-leccion>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    
                    <div *ngIf="!curso.materias || curso.materias.length === 0" class="alert alert-warning" role="alert">
                        Este curso no tiene materias disponibles.
                    </div>

                </div>

            </div>
        </div>

        <div class="mt-5">

            <div class="row">

                <div class="col-lg-12 col-md-12">
                </div>
                <!--<div class="col-lg-4 col-md-12">
                    <div class="courses-details-info">
                        <img [src]=" 1 | mediaCursosPipe: curso.imagen" [alt]="curso.imagen" width="100%" height="auto">
                    </div>
                </div>-->
            </div>
        </div>

    </div>
</div>


<!--<h3>Foro del Curso</h3>
                    <div class="courses-forum">
                        <div class="row align-items-center">
                            <div class="col-sm-8 col-md-12">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Ingresa el texto a buscar..." aria-describedby="button-addon2">
                                    <button class="default-btn" type="button" id="button-addon2"><i class="flaticon-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-md-12">
                                <div class="dropdown">
                                    <a class="btn btn-secondary dropdown-toggle me-3" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                  Materia
                                </a>
                                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuLink">
                                        <li><a class="dropdown-item">Metodología 1</a></li>
                                        <li><a class="dropdown-item">Historia 1</a></li>
                                        <li><a class="dropdown-item">Medioambiente y Seguridad 1</a></li>
                                    </ul>
                                    <a class="btn btn-secondary dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Lección
                                  </a>
                                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuLink">
                                        <li><a class="dropdown-item">Introducción a la Metodología</a></li>
                                        <li><a class="dropdown-item">Inicios del Ski</a></li>
                                        <li><a class="dropdown-item">Prácticas Medioambientales</a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <hr>
                        <h4>Resultados</h4>
                        <hr>
                        <div class="courses-review-comments">
                            <div class="row question-box align-items-center">
                                <div class="col-md-12">
                                    <h3><i class="far fa-question-circle"></i> Consulta sobre historia del ski</h3>
                                    <h6><b>Materia: Historia 1</b> - Lección: Introducción a la Historia del Ski</h6>
                                    *Consulta realizada por Paula Benítez*
                                    <p><br>Mi duda es en qué año comenzaron a investigar sus orígenes.</p>
                                    <br>
                                    <b><i class="fas fa-reply"></i>Respuesta del Profesor</b>
                                    <p>Hola Paula, no hay una fecha exacta.</p>
                                </div>
                            </div>
                            <div class="row question-box align-items-center">
                                <div class="col-md-12">
                                    <h3><i class="far fa-question-circle"></i> Materiales</h3>
                                    <h6><b>Materia: Metodología 1</b> - Lección: Introducción a la Metodología</h6>
                                    *Consulta realizada por Exequiel Bon*
                                    <p><br>¿Cómo puedo saber el talle del par de skies?</p>
                                    <br>
                                    <b><i class="fas fa-reply"></i>Respuesta del Profesor</b>
                                    <p>Se relaciona con tu altura.</p>
                                </div>
                            </div>



                        </div>

                    </div>-->


<!--<div class="row">
                        <div class="col-md-12">
                            <h4>Formulario de consultas</h4>
                            <div class="mb-3">
                                <div class="dropdown">
                                    <a class="btn btn-outline-secondary dropdown-toggle me-3" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                  Materia
                                </a>
                                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuLink">
                                        <li><a class="dropdown-item">Metodología 1</a></li>
                                        <li><a class="dropdown-item">Historia 1</a></li>
                                        <li><a class="dropdown-item">Medioambiente y Seguridad 1</a></li>
                                    </ul>
                                    <a class="btn btn-outline-secondary dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Lección
                                  </a>
                                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuLink">
                                        <li><a class="dropdown-item">Introducción a la Metodología</a></li>
                                        <li><a class="dropdown-item">Inicios del Ski</a></li>
                                        <li><a class="dropdown-item">Prácticas Medioambientales</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Asunto</label>
                                <input type="text" class="form-control" id="exampleFormControlInput1">
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Consulta</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="10"></textarea>
                            </div>

                            <a routerLink="/" class="default-btn animate__animated animate__bounceInUp"><i class="far fa-paper-plane"></i>Enviar<span></span></a>

                        </div>
                    </div>-->



                    <!-- <div class="accordion-item" *ngFor="let materia of curso.materias; let i = index">

                                        <h2 class="accordion-header" [id]="'heading' + i">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
                                        Materia: {{ materia.nombre}}
                                        </button>
                                        </h2>
                                        <div [id]="'collapse' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i" data-bs-parent="#accordionFlushExample">


                                            <div class="list-group" *ngFor="let leccion of materia.lecciones; let i = index">

                                                <app-item-leccion (leccionSeleccionada)="mostrarContenido( $event )" [typeContent]="this.tipoArchivo" [orden]="i+1" [leccion]="leccion" [materia]="materia"></app-item-leccion>


                                            </div>

                                        </div>
                                    </div> -->