<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/dashboard">Inicio</a></li>
        <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
        <li><a routerLink="/mesas-listado">Listado Mesas</a></li>
      </ul>
      <h2>Mesas de examenes</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div *ngIf="flagLoaded" class="profile-area pb-70">
  <div class="container" style="max-width: 95% !important;">

    <div class="profile-courses-quizzes">
      <h3>Listado de Mesas de examenes</h3>

      <a routerLink="/mesa-alta"><button mat-raised-button mat-button color="primary"
          style="margin-right: 5px;"><mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon> Nueva
          Mesa</button></a>
      <a routerLink="/gestos-listado"><button mat-raised-button mat-button color="primary"><mat-icon aria-hidden="false"
            aria-label="Example home icon">list</mat-icon> Ir a Gestos</button></a>
      <br>
      <hr>
      <h5>Para cargar puntajes de manera masiva a usuarios de una mesa, seleccione el botón verde de cada mesa.</h5>
      <div *ngIf="flagLoaded">

        <mat-form-field>
          <mat-label>Filtrar</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Mesa de examen..." autocomplete="off">
        </mat-form-field>

        <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

            <!-- Column -->
            <ng-container matColumnDef="titulo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Titulo </th>
              <td mat-cell *matCellDef="let element"> {{element.titulo}} </td>
            </ng-container>


            <!-- Column -->
            <ng-container matColumnDef="descripcion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción corta </th>
              <td mat-cell *matCellDef="let element" class="td-nombre" mat-column="nombre"> {{element.descriptionCorta
                }} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="fechaInicio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha examen </th>
              <td mat-cell *matCellDef="let element"> {{element.fechaExamenDesde | date:"dd/MM/yy"}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="fechaFin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha recuperatorio </th>
              <td mat-cell *matCellDef="let element"> {{element.fechaExamenHasta | date:"dd/MM/yy"}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="delegacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Delegación </th>
              <td mat-cell *matCellDef="let element"> {{element.delegacion.concepto}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="disciplina">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Disciplina </th>
              <td mat-cell *matCellDef="let element"> {{element.disciplina.nombre}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="nivel">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nivel </th>
              <td mat-cell *matCellDef="let element"> {{element.nivel}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef="_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ver usuarios / Editar / Cargar puntaje / Duplicar
              </th>
              <td mat-cell *matCellDef="let element">
                <button (click)="verUsuariosMesa(element._id)" type="button" style="margin-right: 2%;"
                  title="Ver usuarios asociados" mat-raised-button color="primary"><i class="fas fa-eye"></i></button>
                <button (click)="verMesa(element._id)" type="button" style="margin-right: 2%;" title="Editar mesa"
                  mat-raised-button color="accent"><i class="fas fa-edit"></i></button>
                <button (click)="configurarGestos(element._id)" type="button"
                  style="background-color: #00800087 !important; margin-right: 2%;" mat-raised-button color="warn"
                  title="Cargar puntaje de gestos"><i class="fa fa-upload" aria-hidden="true"></i></button>

                <button (click)="duplicarMesa(element._id, element.titulo)" type="button" mat-raised-button color="warn"
                  title="Duplicar mesa"><i class="fa fa-clone" aria-hidden="true"></i></button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>