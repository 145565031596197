export const environment = {
  production: true,

  /* PRODUCCION AADIDESS*/
  urlBackend: 'https://aadidess.cipbyte.io/api/',
  urlOpenBlank: 'https://aadidess.cipbyte.io/web/#/',
  urlOpenPagesHtml: 'https://aadidess.cipbyte.io/web/assets/pages-html/',
  diccionario: 'general-service-aadidess',
  recaptchaSiteKey: '6LceWEwcAAAAAJIV2GCis8C_Rv_09LaZLVgfP4ZX',
  urlSitio: 'https://aadidess.cipbyte.io/',
  mercadoPagoActivo: true,
  macroPagosActivo: true


  // /* PRODUCCION AAGM*/
  // urlBackend: 'https://aagm.cipbyte.io/api/',
  // urlOpenBlank: 'https://aagm.cipbyte.io/web/#/',
  // urlOpenPagesHtml: 'https://aagm.cipbyte.io/web/assets/pages-html/',
  // diccionario: 'general-service-montania',
  // recaptchaSiteKey: '6LcTcIUpAAAAADd7Q1eorFmwxLqbpVqJj03sH2rj', 
  // urlSitio: 'https://aagm.cipbyte.io/',
  // mercadoPagoActivo: false,

  // urlBackend: 'http://localhost:3002',
  // diccionario: 'general-service-aadidess',
  // recaptchaSiteKey: '6LceWEwcAAAAAJIV2GCis8C_Rv_09LaZLVgfP4ZX',
  // urlOpenBlank: 'http://localhost:4200/#/',
  // urlOpenPagesHtml: 'http://localhost/assets/pages-html/',
  // urlSitio: 'http://localhost:4200',

};
