<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/home">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li>Modificar Cursos - Alumnos</li>
            </ul>
            <h2>Modificar Cursos - Alumnos</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <h3>Listado de Cursos - Alumnos</h3>
            <hr>
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Column" autocomplete="off" #input>
            </mat-form-field>

            <div class="mat-elevation-z8">

                <table mat-table [dataSource]="dataSource" matSort>
                    cursos-configuracion
                    <!-- Column -->
                    <ng-container matColumnDef="apellidoPersona">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido </th>
                        <td mat-cell *matCellDef="let element"> {{ element.apellidoPersona }} </td>
                    </ng-container>

                    <ng-container matColumnDef="nombrePersona">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{ element.nombrePersona }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="verCursosAlumno(element)" type="button" class="btn btn-warning"><i class="fas fa-edit"></i></button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200, 400, 800, 1000]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>
</div>