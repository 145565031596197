import { Component, OnInit, Input } from '@angular/core';
import { Translators } from 'src/app/services/shared/translators.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-formulario-ayuda',
  templateUrl: './formulario-ayuda.component.html',
  styleUrls: ['./formulario-ayuda.component.scss']
})
export class FormularioAyudaComponent implements OnInit {

  lenguaje = 'es';
  idioma: Subscription;
  
  constructor(
    private translatorService: Translators,
  ) { 
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      switch (resp.value){
        case 'es':
          this.lenguaje = 'es';
          break;
        case 'en':
          this.lenguaje = 'en';
          break;
        default:
          this.lenguaje = 'es';
          break;
        }
    });
  }

  ngOnInit(): void {
  }

  useLanguage(idioma: string){
    this.translatorService.setIdiomaSeleccionado(idioma);
  }

}
