<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li>Titulos</li>
            </ul>
            <h2>Titulos</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70 contenido-tabla">
    <div class="container">

        <div class="profile-courses-quizzes">
            <h3>Listado de Titulos Disponibles</h3>

            <a routerLink="/titulo-alta"><button mat-raised-button mat-button color="primary"><mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon> Nuevo Titulo</button></a>
            <br>
            <hr>
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" [disabled]="!flagLoaded" placeholder="Instructor...">
            </mat-form-field>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <!-- Column -->
                <ng-container matColumnDef="nombre">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                    <td mat-cell *matCellDef="let elemento"> {{elemento.nombre}} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="disciplina">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Disciplina </th>
                    <td mat-cell *matCellDef="let elemento"> {{elemento.nombre}} </td>
                </ng-container>


                <!-- Column -->
                <ng-container matColumnDef="observaciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Observaciones </th>
                    <td mat-cell *matCellDef="let elemento"> {{elemento.nombre}} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="nivel">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nivel </th>
                    <td mat-cell *matCellDef="let elemento"> {{elemento.nivel }} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="mesesVigencia">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Meses Vigencia </th>
                    <td mat-cell *matCellDef="let elemento"> {{elemento.mesesVigencia }} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="estado">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                    <td mat-cell *matCellDef="let elemento"> {{elemento.activo }} </td>
                </ng-container>


                <!-- Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Editar </th>
                    <td mat-cell *matCellDef="let elemento">
                        <button (click)="editarTitulo(elemento.titulo)" type="button" class="btn btn-warning btn-small"><i class="fas fa-edit"></i></button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-elemento-row"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>

        </div>
    </div>
</div>