import { NgModule } from '@angular/core';

// Imports
import { ChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from './material.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { AccordionModule } from 'ngx-accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { HomeComponent } from '../components/pages/home/home.component';
import { PartnerComponent } from '../components/common/partner/partner.component';
import { VendorAboutComponent } from '../components/pages/vendor-certification-etraining/vendor-about/vendor-about.component';
import { VendorCoursesComponent } from '../components/pages/vendor-certification-etraining/vendor-courses/vendor-courses.component';
import { OverviewComponent } from '../components/common/overview/overview.component';
import { SubscribeComponent } from '../components/common/subscribe/subscribe.component';
import { PipesModule } from './pipes.module';

import { CursosModule } from './cursos.module';
import { OfertasLaboralesModule } from './ofertas-laborales.module';
import { BuscadorAereosComponent } from '../components/pages/home/buscador-aereos/buscador-aereos.component';
import { CookiebotComponent } from '../components/pages/home/cookiebot/cookiebot.component';


@NgModule({
    declarations: [
        HomeComponent,
        PartnerComponent,
        VendorAboutComponent,
        VendorCoursesComponent,
        OverviewComponent,
        SubscribeComponent,
        BuscadorAereosComponent,
        CookiebotComponent,
    ],

    imports: [
      ChartsModule,
      CommonModule,
      AppRoutingModule,
      MaterialModule,
      CarouselModule,
      BrowserAnimationsModule,
      CountUpModule,
      StickyNavModule,
      TabsModule,
      NgxScrollTopModule,
      LightboxModule,
      AccordionModule,
      FormsModule,
      ReactiveFormsModule,
      SharedModule,
      GalleryModule,
      CursosModule,
      PipesModule,
      OfertasLaboralesModule,
    ],

    exports: [
        HomeComponent,
        PartnerComponent,
        VendorAboutComponent,
        VendorCoursesComponent,
        OverviewComponent,
        SubscribeComponent,
        BuscadorAereosComponent,
        CookiebotComponent,

    ],
  })

  export class HomeModule { }
