import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { ActividadDAdjunto } from '../../../../../interfaces/interface-bakend/ofertas-laborales/actividad-adjuntos.interface';
import { ActividadesService } from '../../../../../services/ofertas-laborales/actividades.service';

@Component({
  selector: 'app-adjunto-modificacion',
  templateUrl: './adjunto-modificacion.component.html',
  styleUrls: ['./adjunto-modificacion.component.scss']
})
export class AdjuntoModificacionComponent implements OnInit {

    myModel = true;
    form: FormGroup;
    estado = true;
    flagLoaded = false;

    adjunto: ActividadDAdjunto;
    constructor(
        private router: Router ,
        private actividadesService: ActividadesService,
        public guiMsjService: GuiMsjService
        ) {

        this.getAdjunto();
        this.getEstadoVariables();
    }

    ngOnInit(): void {
      this.getEstadoVariables();
      this.createForm();
    }

    get nombreNoValido(){
      return this.form.get('nombre').invalid && this.form.get('nombre').touched;
    }

    get descripcionNoValida(){
      return this.form.get('descripcion').invalid && this.form.get('descripcion').touched;
    }

    createForm() {
      this.form = new FormGroup({
        nombre: new FormControl(this.adjunto.nombre, [ Validators.required]),
        descripcion: new FormControl(this.adjunto.descripcion, [ Validators.required]),
      });
    }

    async put(){
      await this.guiMsjService.msjFormSubmit('Guardando');
      this.actividadesService.putAdjunto(this.adjunto._id, await this.setDataForma());
      await this.guiMsjService.msjFormSubmit('DatosGuardados');
      this.router.navigateByUrl('/adjuntos-listado');
    }

    async setDataForma(): Promise<ActividadDAdjunto>{

      const data: ActividadDAdjunto = {
        nombre: this.form.value.nombre,
        descripcion: this.form.value.descripcion,
        tipo: 'prueba',
        icon: '',
        extension: '.pdf',
        pesoMaximo: 0,
        situacionMigratoria: 1,
        costoAdministrativo: 0,
        costoPostAprobacion: 0,
      };

      return data;

    }

    getAdjunto(){
      return this.adjunto = this.actividadesService.getAdjuntoSeleccionado();
    }

    async getEstadoVariables(){
      if (this.getAdjunto() === undefined) {
        this.router.navigateByUrl('/');
        return;
      } else {
        await this.getAdjunto();
        this.flagLoaded = true;
      }
    }

}

