import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-materias-configuracion',
  templateUrl: './materias-configuracion.component.html',
  styleUrls: ['./materias-configuracion.component.scss']
})
export class MateriasConfiguracionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
