import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Examen, Pregunta, Respuesta } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import Swal from 'sweetalert2';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ExamenEvaluacion } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { OnExit } from 'src/app/guards/exit.guard';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    selector: 'app-examen-cursar',
    templateUrl: './examen-cursar.component.html',
    styleUrls: ['./examen-cursar.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
    }]
})
export class ExamenCursarComponent implements OnInit, OnExit {

  panelOpenState = false;

  // Variables temporizador del examen
  public hours = 0;
  public minutes = 0;
  public seconds = 0;
  private timer: any;
  private date = new Date();

  stepDisabled = true;

  public show = true;
  public disabled = false;
  public animate = false;

  isLinear = false;
  isLoadingCategory: boolean;
  form: FormGroup;
  estado = false;

  // Datos examen
  fechaVencimiento: Date;
  fechaActual: Date = new Date();
  cantPreguntas: number = 0;

  examen: Examen;
  preguntas: Pregunta[] = [];
  preguntasAleatorias: any[] = [];
  respuestas: any[] = [];
  flagLoaded = false;
  examenParcial: Examen;
  cantIntentosPosibles: number;
  mostrarRespuestasFinalizar: boolean;

  btnFinalizar: boolean;
  examenEvaluacion: ExamenEvaluacion;
  tipoPregunta: string;
  respuestasRecibidas: Respuesta[];
  id: string;

  public indexPregunta: number;
  public longitud: number;
  public statusForm: boolean;
  onExitExamen: Boolean
  finalizarExamenCompleto: boolean;
  procesandoEvaluacion: boolean = false;

  constructor(private examenServices: ExamenesService,
            private authService: AuthService,
            private router: Router, private formBuilder: FormBuilder,
            private snackBar: MatSnackBar) {
            this.getExamenParcial();
            /* SETEO EN TRUE EL CHEQUEO ON EXIT (PARA QUE CONFIRME LA SALIDA DEL COMPONENTE) */
            this.examenServices.onExitExamenStatus = true;
              
              
              
  }

  getExamenParcial(){
    this.examenServices.getCursarExamen().subscribe((examenParcial: Examen) => {
      this.examenParcial = examenParcial;
    });
  }

  async ngOnInit(): Promise<void> {

    // OBTENER OBSERVABLE PARA FINALIZAR EL EXAMEN
    this.examenServices.finalizar.subscribe(event => {
      this.finalizarExamenCompleto = event;
    });

    this.examenServices.onExitExamen.subscribe((data: Boolean) => this.onExitExamen = data);

    this.examenServices.formStatus.subscribe(event => {
        this.statusForm = event;
    });
    this.examenServices.indexPregunta.subscribe(event => {
        this.indexPregunta = event;
    });
    this.examenServices.longitudPreguntas.subscribe(event => {
        this.longitud = event;
    });

    this.getEstadoVariables();
    //await this.getExamen();
    this.getPreguntas();
    

    (await this.getPreguntas()).subscribe(pregunta => {
    this.isLoadingCategory = false;
    this.preguntas = pregunta;
    });

    this.form = this.formBuilder.group({
        firstCtrl: ['', Validators.required],
    });

    
  }

  // Recibir respuestas de los distintos component segun tipo de pregunta
  recibirRespuestas(e) {
    this.respuestasRecibidas = e;
  }

  /* VERIFICAR EXAMEN A CURSAR */
  async getEstadoVariables(){
    if (this.examenServices.getExamenEvaluacionRespuesta() === undefined) {
      location.href = '/';
      return;
    } else {
      await this.getExamen();
      await this.startTemporizador();
      this.flagLoaded = true;
    }
  }

  /* CARGAR EXAMEN SETEADO */
  async getExamen(){
    if(this.examenServices.getExamenEvaluacionRespuesta() != undefined){
      this.examen = this.examenServices.getExamenEvaluacionRespuesta();
      this.cantPreguntas = this.examenServices.getExamenEvaluacionRespuesta().preguntas.length;
      this.hours = this.examenServices.getExamenEvaluacionRespuesta().limiteTiempo.hours;
      const minutes = this.examenServices.getExamenEvaluacionRespuesta().limiteTiempo.minutes;
      this.cantIntentosPosibles = this.examenServices.getExamenEvaluacionRespuesta().intentos;
      this.mostrarRespuestasFinalizar = this.examenServices.getExamenEvaluacionRespuesta().mostrarRespuesta;
      this.fechaVencimiento = this.examenServices.getExamenEvaluacionRespuesta().fechaVencimiento;
      if (minutes === 1){
        this.minutes = 0;
        this.seconds = 59;
      }else{
        this.minutes = minutes;
      }
    }else{
      this.snackBar.open("Error al cargar Examen","Cerrar",{
        duration: 3000
      });
      location.href = '/';
      return;
    }
    
    return this.examen;
  }

  mostrarRespuestas(respuestas: Respuesta[]){
    this.respuestasRecibidas = respuestas;
  }

  /* CARGO PREGUNTAS PARA MOSTRAR EN EL STEPPER */
  async getPreguntas(): Promise<Observable<any[]>> {
    const preguntas = this.examenServices.getExamenEvaluacionRespuesta().preguntas;
    return of(preguntas).pipe(delay(500));
  }

  // Temporizador tiempo limite examen
  async startTemporizador() {
    if (this.hours > 0 || this.minutes > 0 || this.seconds > 0) {
      this.disabled = true;
      this.show = false;  // hide btn + and -
      this.updateTimer();
      if (this.seconds > 0){
        this.timer = setInterval(() => {
          this.updateTimer();
        }, 1000);
      }
    }
  }

  async updateTimer() {
    this.date.setHours(this.hours);
    this.date.setMinutes(this.minutes);
    this.date.setSeconds(this.seconds);
    this.date.setMilliseconds(0);
    const time = this.date.getTime();
    this.date.setTime(time - 1000);

    this.hours = this.date.getHours();
    this.minutes = this.date.getMinutes();
    this.seconds = this.date.getSeconds();

    if (this.date.getHours() === 0 &&
      this.date.getMinutes() === 0 &&
      this.date.getSeconds() === 0 &&
      this.router.url === '/examen-cursar') {

      /* SETEO EN FALSE EL CHEQUEO ON EXIT (PARA QUE NO CONFIRME LA SALIDA DEL COMPONENTE) */
      this.examenServices.onExitExamenStatus = false;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Se ha quedado sin tiempo para realizar el examen!',
      });
      
      clearInterval(this.timer);
      this.animate = true;  
      // ENVIAR EXAMEN A EVALUAR
      const examenEvaluarSinTiempo: ExamenEvaluacion = {
        usuario: (await this.authService.getUserLogon())._id,
        examen: this.examenServices.getExamenSeleccionado()._id, 
        respuesta: this.examenParcial === undefined ? this.examenServices.getExamenSeleccionado() : this.examenParcial,
        asignacion: '62f4541817b2670e94ab65c2',
        examenTimeOut: true
      }
      // EVALUAR EXAMEN
      await this.examenServices.evaluacionUpdateOne(this.examenServices.getExamenEvaluacionId(),examenEvaluarSinTiempo).then((evaluacion: ExamenEvaluacion)=>{
        this.examenEvaluacion = evaluacion;
        this.router.navigateByUrl('/examenes-alumno');
      })

      setTimeout(() => {
        this.stopTemporizador();
      }, 1000);
        
    }
  }

  stopTemporizador() {
    this.disabled = false;
    this.show = true;
    this.animate = false;
    clearInterval(this.timer);
  }

  async finalizarExamen(){
    Swal.fire({
      title: '¿Estás seguro de finalizar el Examen?',
      text: 'Si no lo está, continue.',
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if(result.value){
        sessionStorage.removeItem('EXAMEN');
        this.stopTemporizador();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Examen finalizado correctamente',
          showConfirmButton: false,
          timer: 1500
        });

        this.procesandoEvaluacion = true;

        const examenEvaluacionUpdateOne: ExamenEvaluacion = {
          usuario: (await this.authService.getUserLogon())._id,
          examen: this.examenServices.getExamenSeleccionado()._id, 
          respuesta: this.examenParcial,
          asignacion: this.examenServices.getExamenSeleccionado().asignacion, 
          examenFinalizado: true
        }
        /* UPDATE EXAMEN FINALIZADO */
        await this.examenServices.evaluacionUpdateOne(this.examenServices.getExamenEvaluacionId(),examenEvaluacionUpdateOne).then((ExamenEvaluacionUpdate: ExamenEvaluacion)=>{
          this.examenEvaluacion = ExamenEvaluacionUpdate;
        })

        /* UPDATE OBSERVABLE EN FALSE PARA NO PREGUNTAR SI QUIERO SALIR */
        this.examenServices.onExitExamenStatus = false;

        /* SI EL EXAMEN ESTÁ CONFIGURADO PARA SER VISTO LUEGO DE REALIZARLO, PREGUNTA SI QUIERE VER EL RESULTADO */
        if (this.mostrarRespuestasFinalizar){
          Swal.fire({
            title: `Su puntaje ha sido de: ${this.examenEvaluacion.puntajeObtenido} puntos. ¿Ver resultado del examen?`,
            icon: 'warning',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
          }).then(async (verResultado) => {
            if (verResultado.value) {

              /* VER RESULTADO DE MI EXAMEN COMO ALUMNO */
              this.examenServices.setExamenEvaluacionId(this.examenEvaluacion._id);
              this.router.navigateByUrl('/examen-resultado');
            }else{
              this.router.navigateByUrl('/examenes-alumno');     
            }
          });
        }else{
          this.router.navigateByUrl('/examenes-alumno'); 
        }
      }
    });
  }

  /* FUNCION QUE CHEQUEA SI QUIERO SALIR DEL COMPONENT EXAMEN CURSAR  */
  async onExit() {
    if(this.onExitExamen === false){
      return true;
    }else{
      const respuesta = confirm('¿Está seguro de salir?, se perderán los cambios realizados y el examen quedará incompleto.');
      if(respuesta){
        return true;
      }else{
        return false;
      }
    } 
  }
}
