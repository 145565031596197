import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Actividad } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Router } from '@angular/router';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { PaisesService } from '../../../../services/shared/paises.service';
import { CardData } from '../../../../interfaces/interface-frontend/shared/cardData.interface';
import { AuthService } from '../../../../services/auth/auth.service';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { IAppOfertas, APP_CONFIG_OFERTAS } from '../../../../app.config/app.config-ofertas';
import { SolicitudesService } from '../../../../services/ofertas-laborales/solicitudes.service';
import { Solicitud, SolicitudCreate, State } from '../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { PaypalService } from '../../../../services/shared/paypal.service';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { Translators } from '../../../../services/shared/translators.service';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';
import { MatDialog } from '@angular/material/dialog';
import { FormularioInscripcionComponent } from './formulario-inscripcion/formulario-inscripcion.component';
import { ConfiguracionApp } from 'src/app/interfaces/interface-bakend/app-web-config/app-web-config.interfaces';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-actividad-postular',
  templateUrl: './actividad-postular.component.html',
  styleUrls: ['./actividad-postular.component.scss']
})
export class ActividadPostularComponent implements OnInit, OnDestroy {

    /* Paypal */
    valor: string;
    description: string;
    // tslint:disable-next-line:variable-name
    currency_code = 'USD';
    detailPago: any;
    estadoPago: Subscription;
    /* FIN Paypal */

    /* Card */
    cardDataUser: CardData;
    cardDataFlag: boolean;
    loaded = false;
    /* Fin Card */

    actividad: Actividad;
    pais: PaisDisponible;
    costoPagar: number;

    usuario: User;
    state: State ;
    solicitudCreate: SolicitudCreate;

    modulos: Curso[];
    modulosId: string[]=[];
    totalModulosCosto = 0;
    flagProcesandoPago = false;
    urlImage: string;
    empresa: string;

    idioma: Subscription;

    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        private router: Router,
        private actividadesService: ActividadesService,
        private paisesService: PaisesService,
        private authService: AuthService,
        private solicitudesService: SolicitudesService,
        private guiMsjService: GuiMsjService,
        private paypalService: PaypalService,
        private translatorService: Translators,
        private cursosService: CursosService,
        private configBeginService: ConfigBeginService,
        public dialog: MatDialog,

    ) {
        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
          });

        this.statusChangesEstadoPagoPaypal();
     }

    ngOnInit(): void {
        this.prepareValues();
        this.getConfiguracionGral();
    }

    async getConfiguracionGral() {
        let conf: ConfiguracionApp;
        conf = await this.configBeginService.getConfiguracionGral();
        this.urlImage = conf.sliders[0];
        this.empresa = conf.empresa;
    }

    async prepareValues() {
        await this.getEstadoVariables();
        await this.setDescripcionPago();
    }

    statusChangesEstadoPagoPaypal() {
        this.estadoPago = this.paypalService.getEstadoPago().subscribe(async (estadoPago: any) => {
            if (estadoPago.value) {
                await this.procesarPago(await this.paypalService.getDetail());
                this.paypalService.setEstadoPago(false);
                this.estadoPago.unsubscribe();
            } else {
                return;
            }
        });
    }

    ngOnDestroy(): void  {
        this.estadoPago.unsubscribe();
    }

    async procesarPago(data: any){

        this.flagProcesandoPago = true;

        this.solicitudCreate = {
            pais: await this.paisesService.getPaisSeleccionado().pais._id,
            actividad: await this.actividadesService.getActividadSeleccionada()._id,
            state: this.state = { numberState: this.iAppOfertas.estado_0.number},
            type: this.iAppOfertas.tipo_constantes.name,
            payment: await this.loadPayment('PAYPAL', data),
            cursos: await this.obtenerIdCusosModulos(this.modulos),
            documentosEstadosSolicitar: await this.actividadesService.getActividadSeleccionada().documentosEstadosSolicitar,
        };

        this.solicitudesService.postComprarSolicitud(this.solicitudCreate).then( resp => {
            this.flagProcesandoPago = false;
            this.router.navigateByUrl('/usuario-solicitudes');
        });
    }

    async obtenerIdCusosModulos(cursosModulos: Curso[]): Promise<string[]> {
        const ids = cursosModulos.map(objeto => objeto._id)
        return ids;
    }



    async procesarSolicitudSinPagiInicial(){
        
        const flag = await this.guiMsjService.msjConfirmCancel();

        if (flag) {
            this.flagProcesandoPago = true;

            this.solicitudCreate = {
                pais: await this.paisesService.getPaisSeleccionado().pais._id,
                actividad: await this.actividadesService.getActividadSeleccionada()._id,
                state: this.state = { numberState: this.iAppOfertas.estado_0.number},
                type: this.iAppOfertas.tipo_constantes.name,
                usuario: (await this.authService.getUserLogon())._id,
                cursos: await this.obtenerIdCusosModulos(this.modulos),
                documentosEstadosSolicitar: await this.actividadesService.getActividadSeleccionada().documentosEstadosSolicitar,
            };

            // Creo solicitud
            this.solicitudesService.create(this.solicitudCreate).then( resp => {
                this.flagProcesandoPago = false;
                this.router.navigateByUrl('/usuario-solicitudes');
            });

        } else {
            return;
        }
    }

    async controlSolicituModulo(idUsuario: string, solicitud: SolicitudCreate): Promise<Boolean> {
        const objetResultado = await this.solicitudesService.controlSolicitudModulosUsuarioGral(idUsuario, solicitud);    
        if (objetResultado.existeCurso) {
            // Obtener los nombres de los cursos realizados
            const nombresCursos: string[] = [];
            objetResultado.cursosRealizados.forEach(element => {            
                    nombresCursos.push(element.nombre);
            });
    
            // Crear el mensaje de alerta con los nombres de los cursos
            const mensajeAlerta = `Ya tiene una solicitud con al menos un curso de los siguientes seleccionados: ${nombresCursos.join(', ')}`;
            
             // Mostrar la alerta con Swal
            await Swal.fire({
                title: 'Cursos Realizados',
                text: mensajeAlerta,
                icon: 'warning',
                confirmButtonText: 'Aceptar'
            });

        }

        if (!objetResultado.estadoPermitido && !objetResultado.existeCurso) {
            return true;
        } else {
            if (objetResultado.estadoPermitido && objetResultado.existeCurso) {
                return true;
            } else  {
                return false;
            }
        }
    
        
    }
    

    async abrirFormularioPreincripcion() {
       
            // ARMO SOLICITUD
            this.solicitudCreate = {
                pais: await this.paisesService.getPaisSeleccionado().pais._id,
                actividad: await this.actividadesService.getActividadSeleccionada()._id,
                state: this.state = { numberState: this.iAppOfertas.estado_0.number},
                type: this.iAppOfertas.tipo_constantes.name,
                usuario: (await this.authService.getUserLogon())._id,
                cursos: await this.obtenerIdCusosModulos(this.modulos),
                documentosEstadosSolicitar: await this.actividadesService.getActividadSeleccionada().documentosEstadosSolicitar,
            };

            // CONTROLO QUE NO HAY CURSADO EL MODULO
            if (!await this.controlSolicituModulo( (await this.authService.getUserLogon())._id, this.solicitudCreate )) {
                return;
            } else {
                // LLAMO FORMULARIO DE CARGA DE DATOS
                this.openDialogModaFormulario(await this.armarSolicitud(), this.empresa);
            }

    }

    async armarSolicitud(): Promise<any> {
        return this.solicitudCreate = {
            pais: await this.paisesService.getPaisSeleccionado().pais._id,
            actividad: await this.actividadesService.getActividadSeleccionada()._id,
            state: this.state = { numberState: this.iAppOfertas.estado_1.number},
            type: this.iAppOfertas.tipo_constantes.name,
            usuario: (await this.authService.getUserLogon())._id,
            cursos: await this.obtenerIdCusosModulos(this.modulos),
            documentosEstadosSolicitar: await this.actividadesService.getActividadSeleccionada().documentosEstadosSolicitar,
        };
    }

    openDialogModaFormulario(solicitud: Solicitud, empresa: string): void {
        const dialogRef = this.dialog.open(FormularioInscripcionComponent, {
            width: '80%', // Ajusta el ancho de la modal según sea necesario
            height: '80%', // Ajusta la altura de la modal según sea necesario
            disableClose: true,
            data: {solicitud, empresa}
        });
        dialogRef.afterClosed().subscribe(async result => {
        });
        return;
    }

    setDescripcionPago() {
        this.description = 'Costo Administrativo $USD ' + this.actividad.costoAdministrativo
        + ' - Pre Inscripción: '
        + this.actividad.nombre;
    }

    getActividad(): Actividad {
        return this.actividad = this.actividadesService.getActividadSeleccionada();
    }


    getModulos(): Curso[] {
        this.modulos = this.cursosService.getModulosSeleccionados();
        this.modulos.forEach(m => this.totalModulosCosto = this.totalModulosCosto + m.precio);      
        return this.modulos;
    }

    getPais() {
        return this.pais =  this.paisesService.getPaisSeleccionado();
    }

    async getEstadoVariables(){
        if (this.getActividad() === undefined) {
            this.router.navigateByUrl('/');
            return;
        } else {
            this.getActividad();
            this.getModulos();
            this.getPais();
            this.loaded = true;
        }
    }

    loadPayment(tipo: string, data: object): any {
        return  {
            tipo,
            payment: {
            result: data
            //   result: { data, costoPagar }
            },
        };
    }




}

