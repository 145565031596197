import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Response } from './../../interfaces/interface-bakend/response';
import { AuthService } from '../auth/auth.service';
import { ConfiguracionApp } from 'src/app/interfaces/interface-bakend/app-web-config/app-web-config.interfaces';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config/app.config';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class ConfigBeginService {

  configuracionApp: ConfiguracionApp;

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private iAppConfig: IAppConfig,

  ) { }

  async getConfiguracionGral(): Promise<ConfiguracionApp>{
    return new Promise(async resolve => {
      const headers =  new HttpHeaders({
        clientid: this.iAppConfig.clientid,
        clientsecret: this.iAppConfig.clientsecret,
       });

      this.httpClient.get(`${ URL_BACKEND}/app-config/findAll`,{ headers }).subscribe((result: Response) =>{
        resolve(result.response[0]);
      });
   })
  }

  async setConfiguracionApp(data: ConfiguracionApp) {
    this.configuracionApp = data;
  }
  
  async getConfiguracionApp(): Promise<ConfiguracionApp> {
    return new Promise(async resolve => {
      resolve(this.configuracionApp);
    });
  }



}
