import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Examen, Pregunta } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-evaluacion-config-preguntas',
  templateUrl: './evaluacion-config-preguntas.component.html',
  styleUrls: ['./evaluacion-config-preguntas.component.scss']
})
export class EvaluacionConfigPreguntasComponent implements OnInit {

  examen: Examen;
  preguntas: Pregunta[];
  form: FormGroup;
  flagLoaded: boolean = true;
  pregunta: string = '';
  examenID: string;
  subscriptionExamenValues: Subscription;
  flagExamen = false;
  cantidadPreguntas: number;
  constructor(private examenesService: ExamenesService) { 
    this.subscriptionPreguntas();
  }

  async ngOnInit(): Promise<void> {
    this.subscriptionExamen();
    await this.getExamen();
    this.createForm();
    
  }

   /* observable al examen view*/
  subscriptionExamen() {
    this.subscriptionExamenValues = this.examenesService._getExamenView$().subscribe( async resp => {
        this.examen = resp.value;
        await this.examenesService.setExamenSeleccionado(this.examen);
        await this.getExamen();
        this.preguntas = this.examen.preguntas;
        this.cantidadPreguntas = this.preguntas.length;
    });
  }

  /* observable al examen view*/
  subscriptionPreguntas() {
    this.examenesService.getPreguntas$().subscribe( async preguntas => {
      this.preguntas = preguntas;
      this.cantidadPreguntas = preguntas.length;
    });
  }

  async getExamen() {
    this.flagLoaded = false;
    this.examen = await this.examenesService.getExamenSeleccionado();
    if(this.examen) {
      this.examenID = this.examen._id;     
      this.flagExamen = true; 
    } else {
      this.flagExamen = false;
    }
    this.flagLoaded = true;
  }

  createForm(){
    this.form = new FormGroup({
      enunciado: new FormArray([],  [ Validators.required ]),
    });
  }

  updatePregunta(posicion: number){
    /*this.preguntas[posicion].enunciado = this.form.value.enunciado[posicion];*/
  }

  onSubmit(): void {
    // console.log(this.form.value);  // {first: 'Nancy', last: 'Drew'}
  }


}
