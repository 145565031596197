


/* App. Setting */
import { InjectionToken } from '@angular/core';

export let APP_CONFIG_CURSOS = new InjectionToken('app.config');

/* CONSTANTES CURSOS  */
export interface IAppCursos {
    tipo_constantes: {name: string};
    tipo_examen_MC: { tipo: string, descripcion: string };
    tipo_examen_VF: { tipo: string, descripcion: string };
    tipo_examen_OC: { tipo: string, descripcion: string };
    tipo_examen_CO: { tipo: string, descripcion: string };
    estado_comprado: { number: number, name: string, detail?: string };
}

export const AppCursos: IAppCursos = {
    tipo_constantes: {name: 'CURSOS'},
    tipo_examen_MC: { tipo: 'MC', descripcion: 'Multiple choice' },
    tipo_examen_VF: { tipo: 'VF', descripcion: 'Verdadero - Falso' },
    tipo_examen_OC: { tipo: 'OC', descripcion: 'Orden de conceptos'},
    tipo_examen_CO: { tipo: 'CO', descripcion: 'Cuadricula de opciones'},
    estado_comprado: {number: 0, name: 'COMPRADO', detail: 'Curso comprado correctamente'},
};
