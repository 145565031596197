<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Materia</a></li>
                <li>Modificar Materia</li>
                <li>{{materia?.nombre}}</li>
            </ul>
            <h2>Modificar Materia</h2>
        </div>
    </div>
    <!--<div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="form" (ngSubmit)="put()">
                        <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                            <mat-label>Nombre</mat-label>
                            <input formControlName="nombre" matInput placeholder="Nombre" [class.is-invalid]="nombreNoValido" autocomplete="off">
                            <small *ngIf="nombreNoValido" class="text-danger">Ingrese un nombre</small>
                        </mat-form-field>

                        <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                            <mat-label>Descripción</mat-label>
                            <textarea formControlName="descripcion" [class.is-invalid]="descripcionNoValida" matInput placeholder="Ingrese una descripción"></textarea>
                            <small *ngIf="descripcionNoValida" class="text-danger">Ingrese una descripción</small>
                        </mat-form-field>

                        <div class="form-group">
                            <mat-form-field color="accent" appearance="fill" [style.width.%]=100>
                                <mat-label>Fecha Inicio</mat-label>
                                <input formControlName="fechaInicio" matInput [matDatepicker]="picker2" [class.is-invalid]="fechaNoValida" autocomplete="off">
                                <small *ngIf="fechaNoValida" class="text-danger">Ingrese una fecha de inicio</small>
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2 color="primary"></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field color="accent" appearance="fill" [style.width.%]=100>
                                <mat-label>Fecha Finalización</mat-label>
                                <input formControlName="fechaFin" matInput [matDatepicker]="picker3" [class.is-invalid]="fechaFinNoValida" autocomplete="off">
                                <small *ngIf="fechaFinNoValida" class="text-danger">Ingrese una fecha de finalización</small>
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3 color="primary"></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Enlace</mat-label>
                                <input formControlName="enlace" matInput placeholder="Enlace URL" autocomplete="off">
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <button type="button" routerLink="/materias-listado" class="btn btn-warning">Cancelar</button>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <button type="submit">Guardar</button></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>