import { AppWebConfig } from '../../../interfaces/interface-bakend/app-web-config/app-web-config.interfaces';
import { MatDialog } from '@angular/material/dialog';
import { Component, Inject, Input, OnInit, AfterContentChecked } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Translators } from '../../../services/shared/translators.service';
import { TransferenciaModalComponent } from '../transferencia-modal/transferencia-modal.component';
import { BankTransferService } from 'src/app/services/shared/bank-transfer.service';
import { BancoCuentaDto, BankTransfer, CalculoPagoTransferencia } from '../../../interfaces/interface-bakend/invoices/bankTransfer.interfaces';
import { Actividad } from '../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { ActividadesService } from 'src/app/services/ofertas-laborales/actividades.service';
import { CursosService } from '../../../services/cursos/cursos.service';
import { Curso } from '../../../interfaces/interface-bakend/cursos/curso.interface';
import { APP_CONFIG_OFERTAS, IAppOfertas } from 'src/app/app.config/app.config-ofertas';
import { APP_CONFIG_CURSOS } from 'src/app/app.config/app.config-cursos';
import { IAppCursos } from '../../../app.config/app.config-cursos';
import { UploadComprobanteSolicitudComponent } from '../../ofertas-laborales/common/upload-comprobante-solicitud/upload-comprobante-solicitud.component';
import { SolicitudesService } from '../../../services/ofertas-laborales/solicitudes.service';

@Component({
  selector: 'app-transferencia-button',
  templateUrl: './transferencia-button.component.html',
  styleUrls: ['./transferencia-button.component.scss']
})

export class TransferenciaButtonComponent implements OnInit, AfterContentChecked {

  @Input() componenteCall: string;
  @Input() valorCobrar: number;
  idioma: Subscription;
  cuentasBanco: BankTransfer[];
  actividad: Actividad;
  curso: Curso;
  flagLoaded = false;

  constructor(
    private translatorService: Translators,
    public dialog: MatDialog,
    private bankTransferService: BankTransferService,
    private actividadesService: ActividadesService,
    private cursoService: CursosService,
    private solicitudesService: SolicitudesService,
    @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
    @Inject(APP_CONFIG_CURSOS) private iAppCursos: IAppCursos,
  ) {
  this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
    this.idioma = resp;
    });
  }

  async ngOnInit() {
      await this.controlComponenteCall();
  }

  ngAfterContentChecked() {
    this.flagLoaded = true;
  }

  async controlComponenteCall() {

    switch (this.componenteCall) {
        case 'PAGO_TRANSFERENCIA':
            this.actividadesService.setPagoConComprobante(true);
            await this.getActividad();
            await this.cargarDatosCuentaBanco();
            break;
        default:
            break;
    }
  }

  cargarDatosCuentaBanco() {
    this.bankTransferService.getCuentaBancoTransferencias('AADIDESS').then( (cuentasBanco: BankTransfer[]) => {
        this.cuentasBanco = cuentasBanco;
    });
  }

  /* Modal Documento  - Carga culquiet ritpo de doc */
  openDialogComprobante(label: string, estado: number, idAdjunto: string): Promise<boolean>  {
      return new Promise<boolean>( resolve => {
        const dialogRef = this.dialog.open(UploadComprobanteSolicitudComponent, {
          width: '400px',
          height: '300px',
          disableClose: true,
          data: {cuentas :this.cuentasBanco, valorCobrar: this.valorCobrar }

      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('modal cerrada', result);
        resolve(result);
      });

    });
  }

  async getActividad() {
    this.actividad = await this.actividadesService.getActividadSeleccionada();
  }

  async getCurso() {
    this.curso = await this.cursoService.getCursoSeleccionado();
  }

 async calculoCotizacionValorActividad(cotizacionUSD: number, valorActividadUsd: number) {
    return cotizacionUSD * valorActividadUsd;
 }

 getMercadoPago():Boolean {
  return this.solicitudesService.getMercadoPagoActuvo();
}

}
