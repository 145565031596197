import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { Router } from '@angular/router';
import { BankTransferService } from 'src/app/services/shared/bank-transfer.service';
import { BankTransfer } from 'src/app/interfaces/interface-bakend/invoices/bankTransfer.interfaces';
import { ComprobanteTransfDetalleComponent } from './comprobante-transf-detalle/comprobante-transf-detalle.component';
import { MatTableDataSource } from '@angular/material/table';
import { InvoicesService } from 'src/app/services/shared/invoices.service';
import { Invoice } from 'src/app/interfaces/interface-bakend/invoices/invoices.interfaces';

@Component({
  selector: 'app-comprobantes-transf-listado',
  templateUrl: './comprobantes-transf-listado.component.html',
  styleUrls: ['./comprobantes-transf-listado.component.scss']
})
export class ComprobantesTransfListadoComponent implements OnInit {

    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] =
    [
        'fecha', 'usuario', 'actividad', 'importe', 'comprobanteID'
    ];
    dataSource: any;
    invoices: any[];
    /* Fin Data Table*/

    flagLoaded = false;
    flagLoadedModal = true;

    constructor(
        public dialog: MatDialog,
        private perfilesService: PerfilesService,
        private bankTransferService: BankTransferService,
        private router: Router,
        private invoicesService: InvoicesService,
 
    ) {
    }

    ngOnInit(): void {
        this.perfilesService.allowedByUserLogon(this.router.url);
        this.listarBankTransfers();
    }

    async verComprobante(comprobanteID) {
        this.flagLoadedModal = false;
        this.bankTransferService.getBankTransferById(comprobanteID).then(( (bankTransfer: BankTransfer) => {
              this.bankTransferService.setBankTransferSeleccionado(bankTransfer);
              this.openDialogModaSolicitud();
        }));
    }

    openDialogModaSolicitud(): void {
        const dialogRef = this.dialog.open(ComprobanteTransfDetalleComponent, {
            width: '900px',
            height: '550px',
            disableClose: true,
        });
        this.flagLoadedModal = true;
        dialogRef.afterClosed().subscribe(async result => {});
        return;
    }

    async listarBankTransfers() {
        this.flagLoaded = false;
        this.loadDataTable();
    }

    async loadDataTable(){
        await this.bankTransferService.getBankTransferAll().then( (bankTransfer: any[]) => {
            this.formatDataTable(bankTransfer).then( ( data: any[] ) => {
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.flagLoaded = true;
            });
        });
    }

    formatDataTable( bankTransfers: any[]) {
        console.log('bankTransfers', bankTransfers);
        return new Promise( resolve => {
          const format: any[] = [];
          let elemento: {
            fecha: Date,
            usuario: string,
            actividad: string,
            importe: boolean,
            comprobanteID: string,
          };

           // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < bankTransfers.length; i++) {
            elemento = {
                fecha: bankTransfers[i].fecha,
                usuario: bankTransfers[i].usuario.email,
                actividad: bankTransfers[i].actividad.nombre,
                importe: bankTransfers[i].importe,
                comprobanteID: bankTransfers[i]._id,
          };
            format.push(elemento);
          }
          resolve(format);
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

}
