<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li>Gestión de Examenes</li>
            </ul>
            <h2>Gestión de Examenes</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70">
    <div class="container">

        <div class="profile-courses-quizzes">
            <h3>Gestión de Examenes</h3>

            <a routerLink="/evaluacion-alta"><button mat-raised-button mat-button color="primary"><mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon> Nuevo Examen</button></a>
            <br>
            <hr>
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" autocomplete="off">
            </mat-form-field>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <!-- Column -->
                <ng-container matColumnDef="nombre">
                    <th width="50%" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                    <td width="50%" mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="preguntas">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Preguntas </th>
                    <td mat-cell *matCellDef="let element"> {{element.preguntas }} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="aprobacion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Aprobación </th>
                    <td mat-cell *matCellDef="let element">{{element.aprobacion}}%</td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="vigencia">
                    <th width="15%" mat-header-cell *matHeaderCellDef mat-sort-header> Vigencia </th>
                    <td width="15%" mat-cell *matCellDef="let element"> {{element.publicacion | date:"dd/MM/YY"}} - {{element.vencimiento | date:"dd/MM/YY"}}</td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="estado">
                    <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> Activo </th>
                    <td width="10%" mat-cell *matCellDef="let element">
                        <mat-slide-toggle (click)="onClick(!element.estado,element._id)" [(ngModel)]="element.estado" [checked]="element.estado"></mat-slide-toggle>
                    </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="_id">
                    <th width="25%" mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td width="25%" mat-cell *matCellDef="let element">
                        <!-- <button style="margin-right: 5px;" mat-raised-button mat-button type="button"><mat-icon aria-hidden="false" aria-label="Example home icon">visibility</mat-icon></button> -->
                        <button (click)="modificarExamen(element._id)" style="margin-right: 5px;" mat-raised-button mat-button color="accent" type="button"><mat-icon aria-hidden="false" aria-label="Example home icon">create</mat-icon></button>
                        <!--<button mat-raised-button mat-button color="primary" type="button" (click)="verExamen(element._id)"><mat-icon aria-hidden="false" aria-label="Example home icon">not_started</mat-icon></button>-->
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

        </div>
    </div>
</div>