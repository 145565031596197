import { Component, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { Respuesta } from '../../../../../../interfaces/interface-bakend/cursos/examen.interface';
import { GuiMsjService } from '../../../../../../services/shared/gui-msj.service';

@Component({
  selector: 'app-cuadricula-opciones',
  templateUrl: './cuadricula-opciones.component.html',
  styleUrls: ['./cuadricula-opciones.component.scss']
})
export class CuadriculaOpcionesComponent implements OnInit {

  respuestas: Respuesta[] = [];

  @Output() conceptosOrdenados = new EventEmitter<string[]>();
  @Output() matrix = new EventEmitter<[]>();
  @Output() flagFormaValido = new EventEmitter<boolean>();

  form = new FormArray([]);
  cont = 0;
  vacio = '';
  flagActivarOpciones = false;
  flagActivarConceptos = false;

  constructor(
      private guiMsjService: GuiMsjService,
  ) {
    this.createListenersStateForm();
  }

  ngOnInit(): void {
  }

  limpiarCuadricula() {
    this.flagActivarOpciones = false;
    this.flagActivarConceptos = false;
    this.form = null;
    this.form = new FormArray([]);
    this.cont = 0;
  }

  createListenersStateForm() {
    return this.form.statusChanges.subscribe( async status => {
      this.controlMatrix();
    });
  }

  controlMatrix() {
      let cont = 0;
      this.form.value.forEach(f => {
        f.forEach(c => {
        //   console.log('fila columna', f, c);
          if ((f !== '' && f !== null && c !== '' && c !== null ) ) {
            cont ++;
          } else {
            cont = 0;
          }
        });
      });
      console.log('matrix', this.form.value.length, this.form.value[0].length   , cont);
      if ( cont  ===  ( (this.form.value.length * this.form.value[0].length) - 1 )) {
         this.flagFormaValido.emit(true);
         this.matrix.emit(this.form.value);
      } else {
        this.flagFormaValido.emit(false);

      }
  }

  agregarConcepto() {

    if (this.cont === 0) {
        // agrego dos row para evitar el vertice
        this.form.push(new FormArray([]));
        (this.form.at(0) as FormArray).push(new FormControl());
        this.cont++;

        this.form.push(new FormArray([]));
        (this.form.at(1) as FormArray).push(new FormControl());
        this.cont++;

    }else {
        this.form.push(new FormArray([]));
        (this.form.at(this.cont) as FormArray).push(new FormControl());
        this.cont++;
    }

  }


  agregarOpcionesEncabezado() {

    this.flagActivarConceptos =  true;

    if ( this.form.length > 0 && this.form.value[0].length < 6) {

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i <  this.form.length; i++) {
            (this.form.at(i) as FormArray).push(new FormControl());
        }

    } else  {

        if ( this.form.value[0].length === 6 ) {
            this.flagActivarOpciones = true;
            this.guiMsjService.msjFormSubmit('maxOpciones');
        } else {
            this.guiMsjService.msjFormSubmit('agregarConcepto');
        }

    }
  }

}
