<div class="page-title-area">
    <div class="container">
        <button mat-button
                (click)="onClose()">Close</button>
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/personas-listado">Configuración Persona</a></li>
                <li>Persona</li>
            </ul>
            <h2>Persona</h2>
        </div>
    </div>
</div>

<app-loading *ngIf="!flagLoaded"
             class="m-5"></app-loading><br>


<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">

    <div class="container"
         *ngIf="flagLoaded">
        <div class="row  mx-auto">
            <div class="col-lg-6 col-md-6 mx-auto">

                <div class="apply-form">

                    <div class="card-header ">
                        <!-- Agregamos el encabezado de la tarjeta -->
                        <h3 class="text-center">Persona</h3>
                        <!-- Centramos el título dentro del encabezado -->
                    </div>
                    <br>

                    <form [formGroup]="forma"
                          (ngSubmit)="onSubmit()">
                        <div class="row">

                            <div class="col-lg-7 col-md-6">

                                <div class="form-group">
                                    <label>{{ idioma | translate: "form.NOMBRE" | async}}</label>
                                    <input formControlName="nombre"
                                           type="text"
                                           class="form-control uppercase"
                                           [class.is-invalid]="nombre">
                                    <small *ngIf="nombre"
                                           class="text-danger">*</small>
                                </div>

                                <div class="form-group">
                                    <label>{{ idioma | translate: "form.APELLIDO" | async}}</label>
                                    <input formControlName="apellido"
                                           type="text"
                                           class="form-control uppercase"
                                           [class.is-invalid]="apellido">
                                    <small *ngIf="apellido"
                                           class="text-danger">*</small>
                                </div>

                                <div class="form-group">
                                    <mat-form-field appearance="fill">
                                        <label for="tipoIdentificacion">{{ idioma | translate:
                                            "form.TIPO-ID" | async}}</label>
                                        <mat-select formControlName="tipoIdentificacion"
                                                    (ngModelChange)="onChangeTipoIdentificacion($event);">
                                            <mat-option *ngFor="let ti of tiposIdentificaciones"
                                                        value="{{ti.concepto}}"
                                                        [class.is-invalid]="tipoIdentificacion">{{ti.concepto}}
                                            </mat-option>
                                            <small *ngIf="tipoIdentificacion"
                                                   class="text-danger">*</small>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <label>Número de Identificación</label>
                                    <input formControlName="identificacion"
                                           type="text"
                                           class="form-control"
                                           [class.is-invalid]="identificacion">
                                    <small *ngIf="identificacion"
                                           class="text-danger">*</small>
                                    <div *ngIf="this.flagExisteIdentificacion">
                                        <small class="text-danger">{{ idioma | translate:
                                            "form.MSJ-ID-EXISTE" | async}}</small>
                                        <div class="p-3 mb-2 bg-primary text-white">{{ idioma | translate:
                                            "form.MSJ-EMAIL-EXISTE" | async}}</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Email</label>
                                    <input formControlName="email"
                                           type="email"
                                           class="form-control"
                                           [class.is-invalid]="email">
                                    <small *ngIf="email"
                                           class="text-danger">*</small>
                                    <div *ngIf="this.flagExisteEmail">
                                        <small class="text-danger">el email existe...</small>
                                        <div class="p-3 mb-2 bg-primary text-white">{{ idioma | translate:
                                            "form.MSJ-EMAIL-EXISTE" | async}}</div>
                                    </div>
                                </div>

                                <div class="form-group"
                                     *ngIf="!flagCrear">
                                    <label>{{ idioma | translate: "form.FECHA-NACIMIENTO" | async}}</label>
                                    <input formControlName="birth"
                                           type="date"
                                           class="form-control"
                                           [class.is-invalid]="birth">
                                    <small *ngIf="birth"
                                           class="text-danger">*</small>
                                </div>

                                <div class="form-group"
                                     *ngIf="!flagCrear">
                                    <label for="paisesDataList"
                                           class="form-label">{{ idioma | translate: "form.PAIS-NACIMIENTO"
                                        | async}}</label>
                                    <input formControlName="paisNacimiento"
                                           class="form-control"
                                           (ngModelChange)="onChangePais($event)"
                                           list="paises"
                                           id="datalistPaisesDisponibles"
                                           [class.is-invalid]="paisNacimiento">
                                    <small *ngIf="paisNacimiento"
                                           class="text-danger">*</small>
                                    <datalist id="paises">
                                        <option *ngFor="let pais of paises"
                                                [value]="pais.name_es">{{pais.name_es}}</option>
                                    </datalist>
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-6"
                                 *ngIf="!flagCrear">
                                <div class="form-group">
                                    <label class="form-label">{{ idioma | translate: "form.ESTADO-CIVIL" |
                                        async}}i</label>
                                    <mat-form-field appearance="fill">
                                        <mat-select formControlName="estadoCivil"
                                                    (ngModelChange)="onChangeEstadoCivil($event)">
                                            <mat-option *ngFor="let ec of estadosCiviles"
                                                        value="{{ec.concepto}}"
                                                        [class.is-invalid]="estadoCivil">{{ec.concepto}}</mat-option>
                                            <small *ngIf="estadoCivil"
                                                   class="text-danger">*</small>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="form-group"
                                     *ngIf="!flagCrear">
                                    <label class="form-label">{{ idioma | translate: "form.CODIGO-AREA" |
                                        async}}</label>
                                    <input formControlName="paisCodeArea"
                                           class="form-control"
                                           [class.is-invalid]="paisCodeArea">
                                    <small *ngIf="paisCodeArea"
                                           class="text-danger">*</small>
                                </div>

                                <div class="form-group"
                                     *ngIf="!flagCrear">
                                    <label>{{ idioma | translate: "form.NUMERO-TELEFONO" | async}}</label>
                                    <div class="input-group">
                                        <input formControlName="telefono"
                                               type="text"
                                               class="form-control"
                                               [class.is-invalid]="telefono">
                                        <small *ngIf="telefono"
                                               class="text-danger">*</small>
                                    </div>
                                </div>

                                <div class="form-group"
                                     *ngIf="!flagCrear">

                                    <label>{{ idioma | translate: "form.IDIOMAS" | async}}</label>


                                    <mat-checkbox formControlName="ca">{{ idioma | translate: "form.CATALAN"
                                        | async}}</mat-checkbox><br>
                                    <mat-checkbox formControlName="es">{{ idioma | translate: "form.ESPANOL"
                                        | async}}</mat-checkbox><br>
                                    <mat-checkbox formControlName="fr">{{ idioma | translate: "form.FRANCES"
                                        | async}}</mat-checkbox><br>
                                    <mat-checkbox formControlName="en">{{ idioma | translate: "form.INGLES"
                                        | async}}</mat-checkbox>


                                </div>
                            </div>

                        </div>
                        <mat-progress-spinner *ngIf="showSpinner"
                                              diameter="40"
                                              mode="indeterminate"
                                              text="validando datos"></mat-progress-spinner>
                        <div *ngIf="showSpinner"
                             class="spinner-container">
                            <span class="spinner-text">Validando datos...</span>
                            <mat-progress-bar *ngIf='flagProcesando'
                                              mode="indeterminate"></mat-progress-bar>
                        </div>
                        <button *ngIf="flagCrear"
                                [disabled]="!this.forma.valid"
                                type="button"
                                (click)="crearNuevaPersona()">CREAR PERSONA</button>
                        <button *ngIf="!flagCrear"
                                [disabled]="!this.forma.valid"
                                type="submit"
                                class="animate__animated animate__fadeIn">GUARDAR PERSONA</button>
                        <!-- <button routerLink="/personas-listado" class="animate__animated animate__fadeIn">CANCELAR</button> -->
                        <br>
                        <br>
                        <mat-progress-bar *ngIf='flagProcesando'
                                          mode="indeterminate"></mat-progress-bar>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 mx-auto text-center"
                 *ngIf="!flagCrear">


                <div class="apply-form">

                    <div class="card-header"
                         style=" background-color:#66ccff9c">
                        <!-- Agregamos el encabezado de la tarjeta -->
                        <h3 class="text-center">Cargar Nuevo Titulo</h3>
                        <!-- Centramos el título dentro del encabezado -->
                    </div>

                    <br>

                    <div *ngIf="tituloSeleccionado">
                        <strong>Título seleccionado: </strong>{{tituloSeleccionado.nombre}}
                        <strong>Meses Vigencia: </strong>{{tituloSeleccionado.mesesVigencia}}
                    </div>


                    <form [formGroup]="formTitulos"
                          (ngSubmit)="agregarTitulo()">

                        <mat-form-field appearance="fill"
                                        [style.width.%]=100>
                            <mat-label>Titulo</mat-label>
                            <mat-select formControlName="titulo"
                                        (ngModelChange)="seleccionarTitulo($event)">
                                <mat-option *ngFor="let titulo of titulos"
                                            value="{{ titulo._id }}">{{ titulo.nombre }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- 
                        <mat-form-field appearance="fill" [style.width.%]="100">
                            <mat-label>Título</mat-label>
                            <input
                                type="text"
                                matInput
                                placeholder="Buscar titulo"
                                [matAutocomplete]="auto"
                                [formControl]="formControlTitulo"
                            />
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let titulo of filteredTitulos | async" [value]="titulo.nombre">
                                {{ titulo.nombre }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field> -->

                        <mat-form-field appearance="fill"
                                        [style.width.%]=100>
                            <mat-label>Disciplina</mat-label>
                            <mat-select formControlName="disciplina">
                                <mat-option *ngFor="let disciplina of disciplinas"
                                            value="{{ disciplina._id }}">{{ disciplina.nombre
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="form-group">
                            <mat-form-field color="accent"
                                            appearance="fill"
                                            [style.width.%]=100>
                                <mat-label>Fecha Otorgamiento</mat-label>
                                <input formControlName="fechaOtorgamiento"
                                       matInput
                                       [matDatepicker]="picker1"
                                       [class.is-invalid]="fechaOtorgamientoNoValida"
                                       autocomplete="off">
                                <small *ngIf="fechaOtorgamientoNoValida"
                                       class="text-danger">Ingrese una fecha de otorgamiento</small>
                                <mat-datepicker-toggle matSuffix
                                                       [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1
                                                color="primary"></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field color="accent"
                                            appearance="fill"
                                            [style.width.%]=100>
                                <mat-label>Fecha Vencimiento</mat-label>
                                <input formControlName="fechaVencimiento"
                                       matInput
                                       [matDatepicker]="picker2"
                                       [class.is-invalid]="fechaVencimientoNoValida"
                                       autocomplete="off">
                                <mat-hint>La fecha de vencimiento se cargará automáticamente al seleccionar
                                    el título, de lo contrario podrá seleccionar una fecha
                                    manualmente</mat-hint>
                                <small *ngIf="fechaVencimientoNoValida"
                                       class="text-danger">Ingrese una fecha de vencimiento</small>
                                <mat-datepicker-toggle matSuffix
                                                       [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2
                                                color="primary"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <br>

                        <!-- <div class="form-group">
                            <mat-form-field hidden  class="example-full-width"
                                            appearance="fill"
                                            [style.width.%]=100>
                                <mat-label>Nivel</mat-label>
                                <input disabled
                                       formControlName="nivel"
                                       type="number"
                                       matInput
                                       placeholder="Ingrese nivel"
                                       autocomplete="off">
                            </mat-form-field>
                        </div> -->

                        <div class="form-group">
                            <mat-form-field class="example-full-width"
                                            appearance="fill"
                                            [style.width.%]=100>
                                <mat-label>Observaciones</mat-label>
                                <textarea formControlName="observaciones"
                                          matInput
                                          style="resize: vertical; height: auto;"
                                          autocomplete="off"></textarea>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-slide-toggle formControlName="certifica">Certifica</mat-slide-toggle>
                        </div>

                        <button type="button"
                                (click)="reset()">Limpiar</button>
                        <ng-container *ngIf="!editaTitulo; else elseTemplate">
                            <button [disabled]="!formTitulos.valid"
                                    type="submit">Guardar Título</button>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <button [disabled]="!formTitulos.valid"
                                    type="submit">Modificar</button>
                        </ng-template>

                    </form>
                </div>
            </div>
        </div>

        <br>
        <br>

        <!-- DOCUMENTOS DEL USUARIO  -->

        <div class="col-lg-12"
             *ngIf="this.usuario && this.usuario?._id">
            <div class="card-header"
                 style="background-color: #9abbcc ;">
                <h3 class="text-center">Mis Documentos</h3>
            </div>

            <br>
            <br>

            <!-- IMAGENES -->
            <div class="row"
                 *ngIf='this.imgs.length > 0'>
                <div class="col-lg-18 col-md-18">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header class="fotos" style="background-color: #98fb98; color: white;">
                                <mat-panel-title>
                                    Documentación - Fotos
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <app-documentos-usuarios-gallery [imagenes]="this.imgs"
                                                 [id_usuario]="this.usuario._id"></app-documentos-usuarios-gallery>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
          
            <div class="row"
                 *ngIf='!this.imgs.length > 0'>
                <div class="col-lg-18 col-md-18">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    SIN Documentación - Formato Fotos
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="form-group">
                                <div class="col-sm-12">
                                    <mat-list>
                                        <mat-list-item>
                                            <i class="fa fa-file-archive-o fa-2x"
                                               aria-hidden="true"></i>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>

            <br>
            <br>

            <!-- ARCHIVOS -->
            <div class="row"
                 *ngIf='this.files.length > 0'>
                <div class="col-lg-18 col-md-18">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header class="archivos" style="background-color: #98fb98; color: white;">
                                <mat-panel-title>
                                    Documentación - Archivos
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <app-documentos-usuarios-files [files]="this.files"
                                               [usuario]="this.usuario"></app-documentos-usuarios-files>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>

            <div class="row"
                 *ngIf='!this.files.length > 0'>
                <div class="col-lg-18 col-md-18">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    SIN Documentación - Formato Archivos
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="form-group">
                                <div class="col-sm-12">
                                    <mat-list>
                                        <mat-list-item>
                                            <i class="fa fa-file-archive-o fa-2x"
                                               aria-hidden="true"></i>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>

            <br>
            <br>

            <div class="row">
                <div class="col-lg-18 col-md-18">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Subir documentación
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <app-documentos-usuarios-files-upload [usuario]="this.usuario"></app-documentos-usuarios-files-upload>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            
      
        </div>

        <br>
        <br>

        <div class="row mx-auto"
             *ngIf="!flagCrear">
            <div class="col-lg-12">
                <div class="card-header"
                     style=" background-color: #44bbf7bb"> <!-- Agregamos el encabezado de la tarjeta -->
                    <h3 class="text-center">TÍtulos del Alumno</h3>
                    <!-- Centramos el título dentro del encabezado -->
                </div>

                <app-loading *ngIf="flagCargandoTitulos"
                             class="m-5"></app-loading><br>


                <!-- <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="example-viewport"> -->

                <table mat-table
                       [dataSource]="dataSource"
                       matSort
                       class="w-100">

                    <!-- Column -->
                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Nombre </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.titulo.nombre}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="disciplina">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Disciplina </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.disciplina.nombre}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="otorgamiento">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Otorgamiento </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.fechaOtorgamiento | date:"dd/MM/yy"}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="vencimiento">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Vencimiento </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.fechaVencimiento | date:"dd/MM/yy"}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="certifica">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Certifica </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.certifica}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="observaciones">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Observaciones </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.observaciones}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="_id">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Acciones </th>
                        <td mat-cell
                            *matCellDef="let element">
                            <button style="margin-right: 5px;"
                                    (click)="editarTituloAlumno(element)"
                                    type="button"
                                    title="Modificar titulo"
                                    class="btn btn-warning"><i class="fas fa-edit"></i></button>
                            <button type="button"
                                    (click)="borrarTitulo(element._id)"
                                    style="margin-right: 2%;"
                                    title="Borar titulo"
                                    class="btn btn-danger">
                                <i class="fa fa-trash fa-fw"
                                   aria-hidden="true"></i>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row
                        *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row
                        *matRowDef="let row; columns: displayedColumns;"
                        class="example-element-row"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                <!-- </cdk-virtual-scroll-viewport> -->
            </div>
        </div>
    </div>
</div>