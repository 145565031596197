import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { GestosService } from 'src/app/services/cursos/gestos.service';
import { Gesto } from 'src/app/interfaces/interface-bakend/cursos/gesto.interface';

@Component({
  selector: 'app-gestos-listado',
  templateUrl: './gestos-listado.component.html',
  styleUrls: ['./gestos-listado.component.scss']
})
export class GestosListadoComponent implements OnInit, AfterViewInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
    [
      'nombre', 'disciplina','valorAprobacion', 'mesesVigenciaAprobacion','nivel', 'activo', '_id'
    ];
  dataSource: any;
  /* Fin Data Table*/

  flagLoaded = false;


  constructor(
    private router: Router,
    private guiMsjService: GuiMsjService,
    private gestosService: GestosService
  ) { }

  ngOnInit(): void {
    this.loadDataPage();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
  }

  async editarGesto(id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    await this.gestosService.getGestoById(id);
    this.router.navigateByUrl('/gesto-modificacion');
  }

  async loadDataTable() {
    this.gestosService.getGestos().then((gestos: Gesto[]) => {
      this.dataSource = new MatTableDataSource(gestos);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.dataSource.filter = filterValue;
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        if (key === 'disciplina') {
          // Filtrar por la propiedad 'concepto' dentro de 'delegacion'
          return currentTerm + (data[key] && data[key].nombre && data[key].nombre.toString().toLowerCase());
        } else {
          // Concatenar los valores de las demás propiedades en una cadena de texto
          return currentTerm + (data[key] && data[key].toString().toLowerCase());
        }
      }, '');
      // Devolver verdadero si la cadena de texto contiene el filtro, de lo contrario, devolver falso
      return dataStr.indexOf(filter) !== -1;
    };
  }

  async loadDataPage() {
    await this.loadDataTable();
    return;
  }
}
