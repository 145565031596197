import { AfterViewInit, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Examen } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { AppConfigService } from 'src/app/services/shared/app-config.service';
import { ExamenEvaluacion } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';

@Component({
  selector: 'app-examenes-alumno',
  templateUrl: './examenes-alumno.component.html',
  styleUrls: ['./examenes-alumno.component.scss']
})
export class ExamenesAlumnoComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [
    'nombreExamen', 'preguntas', 'aprobacion', 'vigencia', 'estado', '_id'
  ];

  displayedColumnsWithObject: string[] = [
    'nombreExamen', 'cantidadRecuperable', 'aprobacion', 'vigencia', 'estado', '_id'
  ];

  displayedColumnsAprobados: string[] = [
    'nombreExamen', 'estado', 'fechaEvaluacion', 'puntajeObtenido', 'esRecuperatorio', 'id'
  ];

  displayedColumnsDesaprobados: string[] = [
    'nombreExamen', 'estado', 'fechaEvaluacion', 'puntajeObtenido', 'esRecuperatorio', 'id'
  ];

  dataSource: any;
  dataSourceWithObjectColumn: any;
  dataSourceAprobados: any;
  dataSourceDesaprobados: any;

  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild('empTbSortWithObject') empTbSortWithObject = new MatSort();
  @ViewChild('sortAprobados') sortAprobados = new MatSort();
  @ViewChild('sortAprobados') sortDesaprobados = new MatSort();

  @ViewChild('paginatorFirst') paginatorFirst: MatPaginator;
  @ViewChild('paginatorSecond') paginatorSecond: MatPaginator;
  @ViewChild('paginatorThird') paginatorThird: MatPaginator;
  @ViewChild('paginatorFourth') paginatorFourth: MatPaginator;

  /* Fin Data Table*/
  examenes: Examen [];
  estadoExamen: boolean;
  flagLoadedAsignados: boolean = false;
  flagLoadedCursos: boolean = false;
  flagLoadedRecuperatorios: boolean = false;
  flagLoadedEvaluacionesAprobados: boolean = false;
  flagLoadedEvaluacionesDesaprobados: boolean = false;
  alumnoSinExamenes: boolean = false;
  sinDesaprobados: boolean = false;
  sinAprobados: boolean = false;
  sinRecuperatorios: boolean = false;
  activo: boolean = false;
  habilitado: Boolean = false;
  horaServer: Date;
  evaluacionesByUser: ExamenEvaluacion[];
  aprobado: boolean;
  puntaje: number;
  intento: boolean = false;

  constructor(
      private examenesService: ExamenesService,
      private authService: AuthService,
      private router: Router,
      private guiMsjService: GuiMsjService,
      private cursosService: CursosService,
      private appConfigService: AppConfigService
  ) { }

  async ngAfterViewInit() {
    await this.loadDataPage();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}

  applyFilter2(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceWithObjectColumn.filter = filterValue.trim().toLowerCase();
  }

  applyFilter3(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceAprobados.filter = filterValue.trim().toLowerCase();
  }

  applyFilter4(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceDesaprobados.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    sessionStorage.removeItem('EXAMEN');
    sessionStorage.removeItem('EXAMEN_FINALIZADO');  
    this.appConfigService.getServerTime().then((hora:Date)=>{
      this.horaServer = new Date(hora);
    })
    this.examenesService.formStatusExamen = false;  
    this.estadoExamen = false;
    // Seteo en false el observable del form valid para finalizar examen
    this.examenesService.formStatusExamen = false;
    this.examenesService.indexPreguntaExamen = null;
    this.examenesService.longitudPreguntasExamen = null;
  }

  async loadDataPage() {
    await this.loadDataAsignadosTable();
    await this.loadDataRecuperatoriosTable();
    await this.loadDataExamenesAprobados();
    await this.loadDataExamenesDesaprobados();
  }

  async loadDataAsignadosTable(){
    await this.examenesService.getExamenesByUser((await this.authService.getUserLogon())._id).then( (examenes: Examen[]) => {
      if(examenes.length > 0) {
        this.formatDataTable(examenes).then( ( data: any[] ) => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.sort = this.empTbSort;
          this.dataSource.paginator = this.paginatorFirst;
          this.flagLoadedAsignados = true;
        });
      }else{
        this.flagLoadedAsignados = true;
        this.alumnoSinExamenes = true;
      }
    });
  }

  async loadDataRecuperatoriosTable(){
    await this.examenesService.getExamenesByUser((await this.authService.getUserLogon())._id).then(async (examenes: any[]) => {
      /* FILTRO LOS EXAMENES QUE SE PUEDEN RECUPERAR */
      let recuperables: Examen[] = examenes.filter(data => data.examen.recuperable === true);
      if(examenes.length > 0) {
        this.formatDataTableRecuperatorios(recuperables).then((data: any[]) => {
          this.dataSourceWithObjectColumn = new MatTableDataSource(data);
          this.dataSourceWithObjectColumn.sort = this.empTbSortWithObject;
          this.dataSourceWithObjectColumn.paginator = this.paginatorSecond;
          this.flagLoadedRecuperatorios = true;
        });
      }else{
        this.sinRecuperatorios = true;
        this.flagLoadedRecuperatorios = true;
      }
    });
  }

  async loadDataExamenesAprobados(){
    await this.examenesService.getEvaluacionesFindAllByUserAprobados((await this.authService.getUserLogon())._id).then(async (evaluacionesAprobados: any[]) => {
      if(evaluacionesAprobados.length > 0) {
        this.formatDataTableEvaluaciones(evaluacionesAprobados).then((data: any[]) => {
          this.dataSourceAprobados = new MatTableDataSource(data);
          this.dataSourceAprobados.sort = this.sortAprobados;
          this.dataSourceAprobados.paginator = this.paginatorThird;
          this.flagLoadedEvaluacionesAprobados = true;
        });
      }else{
        this.sinAprobados = true;
        this.flagLoadedEvaluacionesAprobados = true;
      }
    });
  }

  async loadDataExamenesDesaprobados(){
    await this.examenesService.getEvaluacionesFindAllByUserDesaprobados((await this.authService.getUserLogon())._id).then(async (evaluacionesDesaprobados: any[]) => {
      if(evaluacionesDesaprobados.length > 0) {
        this.formatDataTableEvaluacionesDesaprobados(evaluacionesDesaprobados).then((data: any[]) => {
          this.dataSourceDesaprobados = new MatTableDataSource(data);
          this.dataSourceDesaprobados.sort = this.sortDesaprobados;
          this.dataSourceDesaprobados.paginator = this.paginatorFourth;
          this.flagLoadedEvaluacionesDesaprobados = true;
        });
      }else{
        this.sinDesaprobados = true;
        this.flagLoadedEvaluacionesDesaprobados = true;
      }
    });
  }

  formatDataTableRecuperatorios( examenes: any[]) {
    return new Promise( resolve => {
      const format: any[] = [];
      const misExamenes: any[] = [];

      let elemento: {
        nombre: string,
        cantidadRecuperable: number,
        aprobacion: number,
        r1: {fechaPublicacionR1: Date, fechaVencimiento1: Date},
        r2: {fechaPublicacionR2: Date, fechaVencimiento2: Date},
        estado: boolean,
        _id: string,
        disabled: boolean,
        asignacion: string,
        individual: boolean
      };
        // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < examenes.length; i++) {
        misExamenes.push(examenes[i].examen);
        misExamenes[i]['asignacion'] = examenes[i]._id;
      }
      
      // tslint:disable-next-line:prefer-for-of
      for (let e = 0; e < misExamenes.length; e++) {
        const fechaPublicacion = new Date(misExamenes[e].fechaPublicaR1);
        elemento = {
          nombre: misExamenes[e].titulo,
          cantidadRecuperable: misExamenes[e].cantidadRecuperable,
          aprobacion: misExamenes[e].porcentajeR,
          r1: {fechaPublicacionR1: misExamenes[e].fechaPublicaR1, fechaVencimiento1: misExamenes[e].fechaVenceR1},
          r2: {fechaPublicacionR2: misExamenes[e].fechaPublicaR2, fechaVencimiento2: misExamenes[e].fechaVenceR2},
          estado: misExamenes[e].activo,
          _id: misExamenes[e]._id,
          disabled: fechaPublicacion.getTime() > this.horaServer.getTime() ? true : false,
          asignacion: misExamenes[e].asignacion,
          individual: true
        };
        format.push(elemento);

      }
      resolve(format);
    });
  }

  formatDataTable(examenes: any[]) {
    return new Promise( async resolve => {
      const format: any[] = [];
      const misExamenes: any[] = [];

      let elemento: {
        nombre: string,
        preguntas: number,
        aprobacion: number,
        publicacion: Date,
        vencimiento: Date,
        estado: boolean,
        _id: string,
        disabled: boolean,
        puntaje: number,
        asignacion: string
        individual: boolean
      };
        // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < examenes.length; i++) {      
        misExamenes.push(examenes[i].examen);    
        misExamenes[i]['asignacion'] = examenes[i]._id;
      }

      this.evaluacionesByUser = await this.examenesService.getEvaluacionesByUser();
      
      // tslint:disable-next-line:prefer-for-of
      for (let e = 0; e < misExamenes.length; e++) {
        if(this.evaluacionesByUser.length > 0){
          this.evaluacionesByUser.find(evaluacion =>{
            if(evaluacion.examen === misExamenes[e]._id){
              if(evaluacion.aprobado === true){
                this.aprobado = true;       
              }
              this.intento = true;
              this.puntaje = evaluacion.puntajeObtenido;
              this.examenesService.setExamenEvaluacionId(evaluacion._id);
            } 
          })
        }     
        const fechaPublicacion = new Date(misExamenes[e].fechaPublicacion);
        elemento = {
          nombre: misExamenes[e].titulo,
          preguntas: misExamenes[e].preguntas.length,
          aprobacion: misExamenes[e].porcentajeAprobacionMinimo,
          publicacion: misExamenes[e].fechaPublicacion,
          vencimiento: misExamenes[e].fechaVencimiento,
          estado: misExamenes[e].activo,
          _id: misExamenes[e]._id,
          disabled: fechaPublicacion.getTime() > this.horaServer.getTime() ? true : false,
          puntaje: this.puntaje,
          asignacion: misExamenes[e].asignacion,
          individual: true
        };
        format.push(elemento);

      }
      resolve(format);
    });
  }

  formatDataTableEvaluaciones(evaluaciones: any[]) {
    return new Promise( async resolve => {
      const format: any[] = [];
      const misEvaluaciones: any[] = [];
      let elemento: {
        nombre: string,
        estado: boolean,
        fechaEvaluacion: Date,
        puntajeObtenido: number,
        esRecuperatorio: boolean,
        id: string,
      };
        // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < evaluaciones.length; i++) {
        misEvaluaciones.push(evaluaciones[i]);
      }

      // tslint:disable-next-line:prefer-for-of
      for (let e = 0; e < misEvaluaciones.length; e++) {   
        elemento = {
          nombre: (await (await this.examenesService.getExamenFindOne(misEvaluaciones[e].examen)).titulo),
          estado: misEvaluaciones[e].aprobado ? misEvaluaciones[e].aprobado : null,
          fechaEvaluacion: misEvaluaciones[e].fechaEvaluacion,
          puntajeObtenido: misEvaluaciones[e].puntajeObtenido,
          esRecuperatorio: misEvaluaciones[e].recuperatorio,
          id: misEvaluaciones[e].id,

        };
        format.push(elemento);

      }
      resolve(format);
    });
  }

  formatDataTableEvaluacionesDesaprobados(evaluaciones: any[]) {
    return new Promise( async resolve => {
      const format: any[] = [];
      const misEvaluaciones: any[] = [];
      let elemento: {
        nombre: string,
        estado: boolean,
        fechaEvaluacion: Date,
        puntajeObtenido: number,
        esRecuperatorio: boolean,
        id: string,
      };
        // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < evaluaciones.length; i++) {
        misEvaluaciones.push(evaluaciones[i]);
      }

      // tslint:disable-next-line:prefer-for-of
      for (let e = 0; e < misEvaluaciones.length; e++) {   
        elemento = {
          nombre: (await (await this.examenesService.getExamenFindOne(misEvaluaciones[e].examen)).titulo),
          estado: misEvaluaciones[e].aprobado ? misEvaluaciones[e].aprobado : null,
          fechaEvaluacion: misEvaluaciones[e].fechaEvaluacion,
          puntajeObtenido: misEvaluaciones[e].puntajeObtenido,
          esRecuperatorio: misEvaluaciones[e].recuperatorio,
          id: misEvaluaciones[e].id,

        };
        format.push(elemento);

      }
      resolve(format);
    });
  }

  /* El tipo === 1 es cursar examen, distinto a 1 es un recuperatorio */
  async verExamen(tipo: number, examen: string, asignacion: string, individual: boolean) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const examenCursar: Examen = await this.examenesService.getExamenFindOne(examen);
    examenCursar.asignacion = asignacion;
    examenCursar.individual = individual;
    await this.examenesService.setExamenSeleccionado(examenCursar).then(()=>{
      if(tipo === 1){
        this.router.navigateByUrl('/examen-introduccion');
      }else{
        this.router.navigateByUrl('/examen-recuperatorio');
      }
    })
  }

  verResultado(evaluacion: string){
    this.examenesService.setExamenEvaluacionId(evaluacion);
    this.router.navigateByUrl('/examen-resultado');
  }

  isVencido(fechaVencimiento: Date): boolean {
    const fechaHoy = new Date();
    return fechaVencimiento < fechaHoy;
  }


}
