<div class="navbar-area sticky-box-shadow" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>

    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">

            <a class="navbar-brand" routerLink="/"><img [src]="logoUrl" width="30%" alt="Campus"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>


            <div class="collapse navbar-collapse" id="navbarSupportedContent">

                <h4 *ngIf="!flagMenuLoaded">loading menu...</h4>

                <mat-progress-bar mode="indeterminate" color="primary" *ngIf="!flagMenuLoaded"></mat-progress-bar>

                <!-- MENU NO RESPONSIVE -->
                <ul class="navbar-nav" *ngIf='this.flagMenuLoaded'>

                    <div *ngFor="let item of this.buildMenu">
                        <li *ngIf="item.descendants.length === 0" class="nav-item megamenu"><a [routerLink]="item.menu.redirectTo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()"> {{ idioma | translate: item.menu.name | async }} </a></li>

                        <ng-container *ngIf="item.menu.name == 'navbar.UL-ADMINISTRACION'; else elseTemplate">

                            <li *ngIf="item.descendants.length > 0" class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link"> {{ idioma | translate: item.menu.name| async }} <i class='bx bx-chevron-down'></i></a>

                                <!--<ul class="dropdown-menu">
                                    <li *ngFor="let item of item.descendants" class="nav-item"><a [routerLink]="item.redirectTo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()"> {{ idioma | translate: item.name| async }} </a></li>
                                </ul>-->

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-2 col-sm-4 col-md-3 col-6" *ngFor="let item of item.descendants">
                                                    <div class="single-category-widget">
                                                        <div class="icon">
                                                            <i [class]='item.icon'></i>
                                                        </div>
                                                        <h3><a [routerLink]="item.redirectTo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()"> {{ idioma | translate: item.name| async }} </a></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>

                        </ng-container>
                        <ng-template #elseTemplate>

                            <li *ngIf="item.descendants.length > 0" class="nav-item"><a href="javascript:void(0)" class="nav-link"> {{ idioma | translate: item.menu.name| async }} <i class='bx bx-chevron-down'></i></a>

                                <ul class="dropdown-menu">
                                    <li *ngFor="let item of item.descendants" class="nav-item"><a [routerLink]="item.redirectTo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()"> {{ idioma | translate: item.name| async }} </a></li>
                                </ul>

                            </li>

                        </ng-template>



                        <br>
                    </div>

                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"> <i class="fas fa-globe"></i>&nbsp; <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a (click)="useLanguage('en')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/usa.png" alt="English" width="20"> EN</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="useLanguage('es')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/espana.png" alt="Spanish" width="20"> ES</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a (click)="useLanguage('ca')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/catalunya.png" alt="Catala" width="20"> CA</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="useLanguage('it')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/italia.png" alt="Italian" width="20"> IT</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="useLanguage('ar')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/arabia-saudita.png" alt="Arabia" width="20"> AR</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="useLanguage('fr')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/francia.png" alt="French" width="20"> FR</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="useLanguage('de')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/alemania.png" alt="French" width="20"> DE</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="useLanguage('br')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/brasil.png" alt="Portugués" width="20"> PT</a>
                            </li> -->
                        </ul>
                    </li>

                    <!--<li class="nav-item megamenu"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i class="fas fa-bell fa-2x"></i></a></li>-->

                </ul>

                <!-- MENU RESPONSIVE -->
                <ul class="navbar-nav for-responsive" *ngIf='this.flagMenuLoaded'>

                    <div *ngFor="let item of this.buildMenu">
                        <li *ngIf="item.descendants.length === 0" class="nav-item megamenu"><a [routerLink]="item.menu.redirectTo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()"> {{ idioma | translate: item.menu.name | async }} </a></li>

                        <li *ngIf="item.descendants.length > 0" class="nav-item"><a href="javascript:void(0)" class="nav-link"> {{ idioma | translate: item.menu.name| async }} <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">

                                <li *ngFor="let item of item.descendants" class="nav-item"><a [routerLink]="item.redirectTo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()"> {{ idioma | translate: item.name| async }} </a></li>

                            </ul>
                        </li>

                    </div>

                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><i class="fas fa-globe"></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a (click)="useLanguage('en')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/usa.png" alt="English" width="20"> EN</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="useLanguage('es')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/espana.png" alt="Spanish" width="20"> ES</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a (click)="useLanguage('ca')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/catalunya.png" alt="Catala" width="20"> CA</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="useLanguage('it')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/italia.png" alt="Italian" width="20"> IT</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="useLanguage('ar')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/arabia-saudita.png" alt="Arabia" width="20"> AR</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="useLanguage('fr')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/francia.png" alt="French" width="20"> FR</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="useLanguage('de')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/alemania.png" alt="French" width="20"> DE</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="useLanguage('br')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    <img src="assets/img/flags/brasil.png" alt="Portugués" width="20"> PT</a>
                            </li> -->
                        </ul>
                    </li>

                    <li class="nav-item megamenu"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i class="fas fa-bell fa-2x"></i></a></li>

                </ul>
            </div>

            <!-- AADDIDESS -->
            <div class="others-option d-flex align-items-center" *ngIf="this.tipoSolicitud === 3"> 
                <div class="option-item">
                    <button class="aadidess-btn" *ngIf="this.flagMenuLoaded" (click)="logout()"><i class="fas fa-power-off"></i> {{ idioma | translate: "form.SALIR-BTN" | async }}</button>
                </div>                
            </div>

            <!-- OTROS -->
            <div class="others-option d-flex align-items-center" *ngIf="this.tipoSolicitud !== 3">
                <div class="option-item">
                    <button  class="default-btn" *ngIf="this.flagMenuLoaded" (click)="logout()"><i class="fas fa-power-off"></i> {{ idioma | translate: "form.SALIR-BTN" | async }}</button>
                </div>                
            </div>

        </nav>
    </div>
</div>