import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-evaluacion-asociar',
  templateUrl: './evaluacion-asociar.component.html',
  styleUrls: ['./evaluacion-asociar.component.scss']
})
export class EvaluacionAsociarComponent implements OnInit {

  @Output() guardado = new EventEmitter();
  flagLoaded = false;
  procesando = false;
  isEditable = true;
  flagFormaValido = false;
  cursoAsignado = false;
  grupoAsignado = false;
  usuarioAsignado = false;

  constructor(
      private guiMsjService: GuiMsjService,
      private router: Router,
  ) { }

  ngOnInit(): void {
    this.flagLoaded = true;
  }

  finalizar() {
    this.guardado.emit(true);
    this.guiMsjService.msjFormSubmit('finalizarAsignacionExamen');
    this.router.navigateByUrl('/examenes-listado');
  }

}
