import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
  selector: 'app-vendor-about',
  templateUrl: './vendor-about.component.html',
  styleUrls: ['./vendor-about.component.scss']
})
export class VendorAboutComponent implements OnInit {

  idioma: Subscription;
  videoHome: string;
  tipoSolicitud = 0;
  flagCargado = false;

  constructor(
      private translatorService: Translators,
      private configBeginService: ConfigBeginService,
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        this.idioma = resp;
    });
  }


  async ngOnInit() {
    await this.getConfiguracionGral();
    this.flagCargado = true;
  }

  async getConfiguracionGral() {
    const configApp = await this.configBeginService.getConfiguracionGral();
    this.tipoSolicitud = +configApp.tipoSolicitud.numero;
    this.videoHome = configApp.videoHome;
  }

}
