import { Component, OnInit } from '@angular/core';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';

@Component({
  selector: 'app-terminos-condiciones',
  templateUrl: './terminos-condiciones.component.html',
  styleUrls: ['./terminos-condiciones.component.scss']
})
export class TerminosCondicionesComponent implements OnInit {

  empresa: string;
  urlLogo: string;
  constructor(
    private configBeginService: ConfigBeginService
  ) { }

  async ngOnInit() {
    this.empresa = (await this.configBeginService.getConfiguracionGral()).empresa;
    this.urlLogo = (await this.configBeginService.getConfiguracionGral()).urlLogo;
  }

}
