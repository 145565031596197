import { Response } from './../../interfaces/interface-bakend/response';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BankTransfer } from '../../interfaces/interface-bakend/invoices/bankTransfer.interfaces';
import { GuiMsjService } from './gui-msj.service';
import { AppWebConfig } from 'src/app/interfaces/interface-bakend/app-web-config/app-web-config.interfaces';
import { Observable, Subject } from 'rxjs';

const URL_BACKEND = environment.urlBackend;


@Injectable({
  providedIn: 'root'
})
export class BankTransferService {

  private estadoPago = new Subject<any>();
  private bankTransfer: BankTransfer;

  constructor(
      private httpClient: HttpClient,
      private authService: AuthService,
      private guiMsjService: GuiMsjService,
  ) { }

  async createBankTransfer(bankTransfer: BankTransfer): Promise<BankTransfer> {

    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient.post<Response>(`${ URL_BACKEND }/payments/bank-transfer/createOne`, bankTransfer , { headers }).subscribe( resp => {
            resolve(resp.response);
        }, error => {
            this.guiMsjService.msjFormSubmit('comprobanteCreadoERROR');
        });
    });
  }

  async getCuentaBancoTransferencias(empresa: string): Promise<BankTransfer[]> {

    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient.get<Response>(`${ URL_BACKEND }/app-web-config/findOneByEmpresa/${empresa}`, { headers }).subscribe( resp => {
          console.log('findOneByEmpresa', resp.response); 
          resolve(resp.response);
        }, error => {
            console.log('error al llamar a findOneByEmpresa.. ');
        });
    });
  }

  async getBankTransferAll(): Promise<BankTransfer[]> {

    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient.get<Response>(`${ URL_BACKEND }/payments/bank-transfer/findAll/`, { headers }).subscribe( resp => {
            resolve(resp.response);
        }, error => {
            console.log('error al llamar a bank-transfer/findAll.. ');
        });
    });
  }

  async getBankTransferById(id: string): Promise<BankTransfer> {

    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient.get<Response>(`${ URL_BACKEND }/payments/bank-transfer/getOne/${id}`, { headers }).subscribe( resp => {
            resolve(resp.response);
        }, error => {
            console.log('error al llamar a bank-transfer/getOneresa.. ');
        });
    });
  }

  setBankTransferSeleccionado(bankTransfer: BankTransfer) {
    this.bankTransfer = bankTransfer;
  }

  getBankTransferSeleccionado(): BankTransfer {
    return this.bankTransfer;
  }

  getEstadoPago(): Observable<any> {
    return this.estadoPago.asObservable();
  }

  setEstadoPago(estado) {
      this.estadoPago.next({ value: estado });
  }
}
