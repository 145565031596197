import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { Catalogo } from '../../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from '../../../../../../services/shared/catalogo.service';

@Component({
  selector: 'app-modalidad-modificacion',
  templateUrl: './modalidad-modificacion.component.html',
  styleUrls: ['./modalidad-modificacion.component.scss']
})
export class ModalidadModificacionComponent implements OnInit {

  form: FormGroup;
  flagLoaded = false;
  concepto: Catalogo;

  constructor(
      private router: Router ,
      private catalogoService: CatalogoService,
      public guiMsjService: GuiMsjService
      ) {

      this.getConcepto();
      this.getEstadoVariables();
  }

  ngOnInit(): void {
    this.getEstadoVariables();
    this.createForm();
  }

  get conceptoNoValido(){
    return this.form.get('concepto').invalid && this.form.get('concepto').touched;
  }

  createForm() {
    this.form = new FormGroup({
        concepto: new FormControl(this.concepto.concepto, [ Validators.required, Validators.min(3)]),
    });
  }

  async put(){
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.catalogoService.put(this.concepto._id, await this.setDataForma());
    await this.guiMsjService.msjFormSubmit('DatosGuardados');
    this.router.navigateByUrl('/modalidades-listado');
  }
  
  async setDataForma(): Promise<Catalogo>{

    const data: Catalogo = {
      key: null,
      concepto: this.form.value.concepto,
      parent: this.concepto.parent,
      properties: {},
  };

    return data;

  }

  getConcepto(){
    return this.concepto = this.catalogoService.getConceptoSeleccionado();
  }

  async getEstadoVariables(){
    if (this.getConcepto() === undefined) {
      this.router.navigateByUrl('/');
      return;
    } else {
      await this.getConcepto();
      this.flagLoaded = true;
    }
  }

}

