<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70">
  <div class="container" style="max-width: 100%">
    <div class="profile-courses-quizzes">
      <h3>Listado de Boletines del Alumno {{alumno?.usuario?.nombrePersona}} {{alumno?.usuario?.apellidoPersona}}</h3>
      <button class="btn btn-success" (click)="altaBoletin()"><i class="fas fa-plus"></i> Agregar Nuevo Boletin</button>
      <hr>
      <mat-form-field>
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Boletin nro..." autocomplete="off" #input>
      </mat-form-field>

      <div class="mat-elevation-z8">

        <br>
        <div *ngIf="loading; else dataTable" class="spinner-container">
          <mat-spinner></mat-spinner>
        </div>        
        
        <ng-template #dataTable>
          <table mat-table [dataSource]="dataSource" matSort class="custom-table">
        
            <!-- Column -->
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="nombre-col"> Boletin </th>
              <td mat-cell *matCellDef="let element" class="nombre-col"> {{element.boletin.nombre || 'SIN DATOS'}} {{element.apellidoPersona}} </td>
            </ng-container>
        
            <!-- Column -->
            <ng-container matColumnDef="_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="acciones-col"> Acciones </th>
              <td mat-cell *matCellDef="let element" class="acciones-col">
                <button style="margin-right: 5px;" (click)="openDialogVerDetalleBoletin(element._id)" type="button"
                  title="Ver detalle boletin" class="btn btn-primary"><i class="fas fa-eye"></i>Ver Detalle</button>
                <button (click)="updateBoletin(element._id)" title="Modificar boletin" type="button" class="btn btn-warning"><i
                    class="fas fa-edit"></i>Modificar</button>
              </td>
            </ng-container>
        
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No hay registros para mostrar "{{input.value}}"</td>
            </tr>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
          </table>
        </ng-template>   

        <mat-paginator [pageSizeOptions]="[50, 100, 150, 200, 500, 800]"
          aria-label="Select page of users"></mat-paginator>
      </div>
    </div>
  </div>
</div>