<div class="">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>Usuarios asociados a la mesa</li>
      </ul>
      <h2>Usuarios asociados a la mesa</h2>
      <h5>En la siguiente tabla se visualizan los usuarios que están asociados a esta mesa y se pueden acceder a los puntajes cargados de cada uno haciendo click en el botón de Ver puntaje</h5>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>
<div class="mat-elevation-z8">

  <table mat-table [dataSource]="dataSource">

    <!-- Position Column -->
    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef> Usuario </th>
      <td mat-cell *matCellDef="let element"> {{element.usuario?.fullName}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="identificacion">
      <th mat-header-cell *matHeaderCellDef> Identificación </th>
      <td mat-cell *matCellDef="let element"> {{element.usuario?.identificacion}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="fechaInscripcion">
      <th mat-header-cell *matHeaderCellDef> Fecha inscripción </th>
      <td mat-cell *matCellDef="let element">{{element.fechaInscripcion | date}}</td>
    </ng-container>

    <ng-container matColumnDef="puntaje">
      <th mat-header-cell *matHeaderCellDef> Ver Puntajes </th>
      <td mat-cell *matCellDef="let element" style="width: 16%;">
        <button (click)="verPuntajes(element.usuario._id)" type="button" style="margin-right: 2%;" title="Ver puntajes cargados" mat-raised-button color="primary"><i
          class="fas fa-eye"></i></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
</div>