import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { PaisDisponible } from '../../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Solicitud } from '../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { Actividad } from '../../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { User } from '../../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { GuiToolsService } from '../../../../../services/shared/gui-tools.service';
import { PersonasService } from 'src/app/services/shared/personas.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { OfertasSolicitudComponent } from '../ofertas-solicitud/ofertas-solicitud.component';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';


@Component({
  selector: 'app-ofertas-formulario',
  templateUrl: './ofertas-formulario.component.html',
  styleUrls: ['./ofertas-formulario.component.scss']
})
export class OfertasFormularioComponent implements OnInit {

    @Input() solicitud: Solicitud;
    @Input() usuario: User;
    actividad: Actividad;
    pais: PaisDisponible;
    forma: FormGroup;
    flagLoaded = false;
    flagTitulo = false;
    nombreActividad: string;
    nombrePais: string;
    constructor(
        private guiToolsService: GuiToolsService,
        private personasService: PersonasService,
        private router: Router,
        public dialogRef: MatDialogRef<OfertasSolicitudComponent>,
        private catalogoService : CatalogoService,

    ) {
    }

    ngOnInit(): void {
        console.log('datos usuario', this.usuario);
        this.getData();
    }

    async getData() {
        this.flagTitulo = this.usuario.tituloSecundario;
        await this.createForm();
        this.flagLoaded = true;

    }

    closeModalPadre(): void {
        this.dialogRef.close();
    }

    async modificarPersona() {
        await this.personasService.setPersonaSeleccionada( await this.personasService.getById(this.usuario._id));
        this.closeModalPadre();
        this.router.navigateByUrl('/persona-modificacion');
    }

    async createForm() {      
        
         this.forma = new FormGroup({
            nombre: new FormControl(this.usuario.nombrePersona,  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            apellido: new FormControl(this.usuario.apellidoPersona,  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            tipoIdentificacion: new FormControl(this.usuario.tipoIdentificacion.concepto,  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            identificacion: new FormControl(this.usuario.identificacion,  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            birth: new FormControl(this.guiToolsService.dateTransform(this.usuario.birth),  [ Validators.required]),         
            paisNacimiento: new FormControl(this.usuario?.paisNacimiento?.name_es,  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            // estadoCivil: new FormControl(this.usuario.estadoCivil.concepto,  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            paisCodeArea: new FormControl(this.usuario?.paisCodeArea, [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            telefono: new FormControl(this.usuario.telefono, [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            // condiMigra: new FormControl(this.usuario.condiMigra.concepto, [ Validators.required ]),
            // pasaporteValido: new FormControl(this.usuario.pasaporteValido, [ Validators.required]),
            // ca: new FormControl(this.usuario.idiomaCa, ),
            // es: new FormControl(this.usuario.idiomaEs, ),
            // fr: new FormControl(this.usuario.idiomaFr, ),
            // en: new FormControl(this.usuario.idiomaEn, ),

            // AADIDESS
            delegacion: new FormControl(await (await this.catalogoService.getFindOne(this.usuario?.delegacion || '')).concepto,  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            disciplina: new FormControl(await (await this.catalogoService.getFindOne(this.usuario?.disciplina || '')).concepto,  [ Validators.required, Validators.minLength(3), Validators.maxLength(50)]), 
            enfermedad: new FormControl('', [ Validators.required ] ),
            hospitalizado: new FormControl('',[ Validators.required] ),
            otraFormacion: new FormControl(this.usuario.otraFormacion,[ Validators.required] ),

            // declaracion: new FormControl('',[ Validators.requiredTrue] ), // la acepto para llegar a este estado
            cp: new FormControl(this.usuario.cp, [ Validators.required] ),
            tituloSecundario: new FormControl('', [ Validators.required] ),
            tituloDe: new FormControl(this.usuario.tituloDe, ),
            tituloExpedido: new FormControl(this.usuario.tituloExpedido, )
        });
    }

}
