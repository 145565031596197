import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
  selector: 'app-ofertas-disponibles',
  templateUrl: './ofertas-disponibles.component.html',
  styleUrls: ['./ofertas-disponibles.component.scss']
})
export class OfertasDisponiblesComponent implements OnInit {

    idioma: Subscription;
    tipoSolicitud = 0;

    constructor(
        private translatorService: Translators,
        private configBeginService: ConfigBeginService,
        
        ) {
        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
        });
    }
    ngOnInit(): void {
        this.getConfiguracionGral();
    }

    async getConfiguracionGral() {
        this.tipoSolicitud = +(await this.configBeginService.getConfiguracionGral()).tipoSolicitud.numero;
    }

}
