<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{ idioma | translate: "navbar.UL-INICIO" | async}}</a></li>
                <li>{{ idioma | translate: "home.QUIENES-SOMOS-TITULO" | async}}</li>
            </ul>
            <h2>{{ idioma | translate: "home.QUIENES-SOMOS-TITULO" | async}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="about-area-two pb-100">
    <div class="container">
        <div class="row align-items-center mt-5">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">{{ idioma | translate: "home.QUIENES-SOMOS-TITULO" | async}}</span>
                    <!-- <h2>{{ idioma | translate: "home.PROCESO-TITULO" | async}}</h2> -->
                    <!--<p><strong>{{ idioma | translate: "home.QUIENES-SOMOS" | async}}</strong></p>-->
                    <p style="text-align: justify;"> <strong>{{ idioma | translate: "home.QUIENES-SOMOS" | async}}</strong> </p>
                </div>

            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <div class="image">
                        <img [src]="slide" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
