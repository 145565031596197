<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/areas-configuracion">Configuración Áreas</a></li>
                <li>Listado de Áreas</li>
            </ul>
            <h2>Listado de Áreas</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div *ngIf="flagLoaded" class="profile-area pb-70 contenido-tabla">
    <div class="container">

        <div class="profile-courses-quizzes">
            <h3>Listado de Áreas</h3>
            <hr>

            <div *ngIf="flagLoaded">

                <mat-form-field>
                    <mat-label>Filtrar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Camareros..." autocomplete="off">
                </mat-form-field>

                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                    <!-- Column -->
                    <ng-container matColumnDef="concepto">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{element.concepto}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="verConcepto(element._id)" type="button" class="btn btn-warning"><i class="fas fa-edit"></i></button>
                        </td>
                    </ng-container>          

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"></mat-paginator>
            </div>
        </div>
    </div>
</div>
