<div class="container">
    <div class="row justify-content-center">

            <div class="col-lg-4 col-md-6 animate__animated animate__fadeIn">
                <div class="single-courses-box"  (click)="navegarConParametro(true)" >
                    <div class="courses-image">
                        <a routerLink="inscripcion-pista"  class="d-block image">
                            <img src="assets/configuracion-app-aadidess/img/pista.jpg"  alt="clic para navegar..."> 
                            <a class="link-btn"></a>
                        </a>        
                    </div>
                
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user9.png" class="rounded-circle" alt="image">
                            <span>Pista</span>
                        </div>
                        <h3>
                            <a (click)="navegarConParametro(true)" class="link-btn">Pre inscripción cursado Presencial</a>
                        </h3>
                        <p>Cursos pista</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-people'></i>Pre inscripción</li>
                        </ul>                 
                    </div>
                </div>    
            </div>
            <div class="col-lg-4 col-md-6 animate__animated animate__fadeIn">
                <div class="single-courses-box"  (click)="navegarConParametro(false)">
                    <div class="courses-image">
                        <a [routerLink]='routerLink' class="d-block image">
                            <img src="assets/configuracion-app-aadidess/img/online.jpg"   alt="clic para navegar..."> 
                            <a class="link-btn"></a>
                        </a>        
                    </div>
                
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user9.png" class="rounded-circle" alt="image">
                            <span>OnLine</span>                            
                        </div>
                        <h3>
                            <a (click)="navegarConParametro(false)" class="link-btn">Pre inscripción cursado On Line</a>
                        </h3>
                        <p>Cursos online</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-people'></i>Pre inscripción</li>
                        </ul>                 
                    </div>
                </div>    
            </div>

    </div>

 
    <!-- Nueva fila para los botones dentro de un card -->
    <div class="row justify-content-center mt-3">
        <div class="col-lg-8 col-md-10">
            <div class="card text-center">
                <div class="card-header">
                    Conoce nuestros calendarios antes de inscribirte para planificar tu curso de la mejor manera
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-3 mb-md-0">
                            <a href="https://aadidess.com/calendario-de-cursada-en-pista/" target="_blank">
                                <button mat-raised-button color="warn" class="custom-button">Calendario de Cursada en
                                    Pista</button>
                            </a>
                        </div>
                        <div class="col-12 col-md-6">
                            <a href="https://aadidess.com/calendario-de-cursada-online/" target="_blank">
                                <button mat-raised-button color="warn" class="custom-button">Calendario de Cursada
                                    Online</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>


