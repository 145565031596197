<div class="page-title-area" *ngIf="flagLoaded">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a class="text-white" routerLink="/home">{{ idioma | translate: "navbar.UL-INICIO" | async}}</a></li>
                <li><a class="text-white" routerLink="/oferta-laborar-pais">{{ idioma | translate: "oferta-laboral.BUSCADOR-TITULO" | async}}</a></li>
                <li>{{ actividad.nombre }}</li>
            </ul>
            <h2>{{ actividad.nombre }}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100"  *ngIf="flagLoaded">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <app-detalle [actividad]=actividad [nombrePais]=nombrePais></app-detalle>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="courses-details-info">
                    <ul class="info">
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> {{ idioma | translate: "oferta-laboral.INFO-LUGAR" | async}}</span> {{ this.nombrePais }}
                            </div>
                        </li>
                        <!-- <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> {{ idioma | translate: "oferta-laboral.INFO-SUELDO" | async}}</span> ${{ actividad.sueldo }}
                            </div>
                        </li> -->
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> {{ idioma | translate: "oferta-laboral.INFO-PUBLICADO" | async}}</span> {{ actividad.createdAt| date:"dd/MM/yy" }}
                            </div>
                        </li>
                        <!-- <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> {{ idioma | translate: "oferta-laboral.INFO-PUESTO" | async}}</span> {{ actividad.tipoPuesto.concepto }}
                            </div>
                        </li> -->
                        <!-- <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> {{ idioma | translate: "oferta-laboral.INFO-AREA" | async}}</span> {{ actividad.area.concepto}}
                            </div>
                        </li> -->
                        <!-- <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> {{ idioma | translate: "oferta-laboral.INFO-MODALIDAD" | async}}</span> {{ actividad.modalidad.concepto }}
                            </div>
                        </li> -->
                        <!-- <section class="example-section">
                            <h4>Sleccione el o los Módulos que desea cursar:</h4>
                            <p *ngFor="let item of this.actividad.cursos">
                                <mat-checkbox
                                [(ngModel)]="item._id" 
                                (ngModelChange)="updateAllComplete()">
                                >{{item.nombre}}</mat-checkbox>
                         </p>
                          </section> -->
                        <br>
                        <h4>Seleccione Módulo/s que desea cursar:</h4>
                        <mat-checkbox [checked]="allChecked" [indeterminate]="isFewSelected()" (change)="setAll($event.checked)">
                            {{this.modulos.name}}
                        </mat-checkbox>

                        <li *ngFor="let item of this.actividad.cursos">
                            <mat-checkbox [(ngModel)]="item.selected" (ngModelChange)="updateAllComplete()">
                                <div class="d-flex justify-content-between align-items-center">
                                    <!-- <span><i class="flaticon-lock"></i>{{item.nombre}} </span> - ${{item.precio}} -->
                                    <span style="white-space: normal;text-align: justify;"><i class="flaticon-lock"></i>{{item.nombre}} - ${{item.precio}}</span>
                                </div>
                            </mat-checkbox>
                        </li>

                        <!-- <h3>Total: ${{this.totalCursos}}</h3> -->
                    </ul>
                    <app-loading *ngIf="flagProcesando" class="m-5"></app-loading><br>
                    <div class="courses-info">
                        <!-- <a (click)="actividadPostular()" class="default-btn"><i class="fas fa-mouse-pointer"></i>{{ idioma | translate: "oferta-laboral.POSTULAR-BTN" | async}}<span></span></a> -->                    
                        <a (click)="actividadPostular()" class="default-btn" [ngClass]="{'disabled': !this.flagSelected }">
                            <i class="fas fa-mouse-pointer"></i>{{ idioma | translate: "oferta-laboral.POSTULAR-BTN" | async}}
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="row">
    <app-btn-ofertas-listar></app-btn-ofertas-listar>
</div>