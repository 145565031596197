import { PerfilesService } from './../../../../services/perfiles/perfiles.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';

@Component({
  selector: 'app-usuarios-administracion',
  templateUrl: './usuarios-administracion.component.html',
  styleUrls: ['./usuarios-administracion.component.scss']
})
export class UsuariosAdministracionComponent implements OnInit {

  countProfesores = 0;
  countUsuarios = 0;
  countAlumnos = 0;

  constructor(
      private router: Router ,
      private perfilesService: PerfilesService,
      private cursosService: CursosService,
      private usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.perfilesService.allowedByUserLogon(this.router.url);
    this.getCountProfesores();
    this.getCountUsuarios();
    this.getCountAlumnos();
  }

  async getCountProfesores(){
    await this.cursosService.getProfesores().then((profesores: any) => {
      this.countProfesores = profesores.length;
    });
  }
  async getCountUsuarios(){
    await this.usuarioService.getCountUsers().then((users: any) => {
      this.countUsuarios = users;
    });
  }
  async getCountAlumnos(){
    await this.cursosService.getCountAlumnos().then((alumnos: any) => {
      this.countAlumnos = alumnos;
    });
  }


}
