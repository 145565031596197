import { MailerService } from './../../../../../services/shared/mailer.service';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Solicitud } from '../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { SolicitudesService } from '../../../../../services/ofertas-laborales/solicitudes.service';
import { User } from '../../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadFileSolicitudComponent } from '../../../common/upload-file-solicitud/upload-file-solicitud.component';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';
import { GuiMsjService } from '../../../../../services/shared/gui-msj.service';
import { ModalSolicitarPagoComponent } from './modal-solicitar-pago/modal-solicitar-pago.component';
import { Invoice } from '../../../../../interfaces/interface-bakend/invoices/invoices.interfaces';
import { Actividad } from '../../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../../../../app.config/app.config-ofertas';
import { InvoicesService } from '../../../../../services/shared/invoices.service';
import { PaypalPurchaseUnit } from '../../../../../interfaces/interface-frontend/shared/paypalPurchaseUnit.nterface';
import { Translators } from '../../../../../services/shared/translators.service';
import { Subscription } from 'rxjs';
import { CursosService } from '../../../../../services/cursos/cursos.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { ConfiguracionApp } from 'src/app/interfaces/interface-bakend/app-web-config/app-web-config.interfaces';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-ofertas-solicitud',
  templateUrl: './ofertas-solicitud.component.html',
  styleUrls: ['./ofertas-solicitud.component.scss']
})
export class OfertasSolicitudComponent implements OnInit, OnDestroy {

    /* Estados */
    activarFormulario = false;
    activarPreAprobar = false;
    activarAprobar = false;
    activarEspera = false;
    activaAnulada = false;
    activarSolicitarPagoTotal = false;
    activarRechazar = true;
    activarCerrarModal = true;


    nombrePais: string;
    actividad: Actividad;
    solicitud: Solicitud;
    usuario: User;

    flagLoaded: boolean;
    flagSolicitudCompleta = false;
    flagSpinner = false ;
    flagLabelAprobado = false;
    flagLabelRechazado = false;
    isCheckedAprobar = false;
    isCheckedEspera = false;
    isCheckedRechazar = false;
    isCheckedAnular = false;

    dataModal: any;

    estadoFormulario: string;
    estadoDocumentacion: string;
    estadoDniFrente: string;
    estadoDniDorso: string;
    estadoPagoTotal: string;
    estadoContrato: string;
    estadoFichaMedica: string;
    estadoTituloSecundario: string;

    imgs: string[] = [];
    files: string[] = [];

    labelContrato = 'Cargar y enviar contrato';
    adjuntoCatalogo: any;
    idioma: Subscription;
    newState: Subscription;

    motivoRechazo = '';
    habilitarEnviarObservacion = false;


    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        private router: Router,
        private solicitudesService: SolicitudesService,
        public dialog: MatDialog,
        private mailerService: MailerService,
        private catalogoService: CatalogoService,
        private guiMsjService: GuiMsjService,
        private invoicesService: InvoicesService,
        private translatorService: Translators,
        private cursosService: CursosService,
        private usuarioService: UsuarioService,
        private configBeginService: ConfigBeginService,
        private snackBar: MatSnackBar,

        /* Modal Ref. */
        public dialogRef: MatDialogRef<OfertasSolicitudComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string // los datos los recibo por servicio
        /* Fin Modal Ref. */
    ) {
        // this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        //     this.idioma = resp;
        // });
        this.newState = this.solicitudesService.getsetNewStater().subscribe(resp => {
            console.log('change');
            this.initPostChangeStatus();
            this.newState = resp;
         });

    }

    ngOnDestroy(): void {
        // this.idioma.unsubscribe();
        // this.newState.unsubscribe();
    }

    ngOnInit(): void {
        this.getDataSolicitud();
        this.cargarAdjuntoContrato();
    }

    initPostChangeStatus(): void { // solo cargo estado
        this.getDataSolicitud();
        this.activarCerrarModal = true;
    }


    /* CARGO FORM */
    async cargarAdjuntoContrato() {
         this.adjuntoCatalogo = await this.catalogoService.getFindByKey('ADJUNTO_CONTRATO');
    }

    async getDataSolicitud(){
        this.flagLoaded = false;
        console.log(this.getSolicitud());
        await this.getEstadoSolicitud(await this.getSolicitud());
        await this.getArrayImgsAndFile((await this.getSolicitud()).adjuntos);
        await this.getDocumentosUser();
        this.files = await this.quitarDuplicados(this.files); // se duplican a causa de estar en solicitud y en usuario.
        this.flagLoaded = true;
    }

    async getSolicitud(): Promise<Solicitud> {
        this.solicitud = await this.solicitudesService.getSolicitudSeleccionada();
        this.actividad = this.solicitud.actividad;
        this.usuario = this.solicitud.usuario;
        this.nombrePais = this.solicitud.pais.name_es;
        return this.solicitud;
    }

    async getEstadoSolicitud(solicitud: Solicitud) {
        switch (solicitud.lastState.numberState) {
            case 0: // PRE INSCRIPCION

                this.activarPreAprobar = false;
                this.activarSolicitarPagoTotal = false;
                this.activarRechazar = false;

                this.estadoFormulario = this.iAppOfertas.formulario.pendiente;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.pendiente;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                this.activarEspera = false;


                break;

            case 1: // FORMULARIO CARGADO
                this.activarPreAprobar = true;
                this.activarSolicitarPagoTotal = false;
                this.activarRechazar = true;
                this.activarFormulario = true;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.pendiente;

                this.activarEspera = true;


                break;

            case 2: // DOCUMENTOS PARCIALES
                this.activarPreAprobar = true;
                this.activarSolicitarPagoTotal = false;
                this.activarRechazar = true;
                this.activarFormulario = true;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.pendiente;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                this.activarEspera = true;

                break;

            case 3:  // DOCUMENTOS COMPLETOS
                this.activarPreAprobar = true;
                this.activarSolicitarPagoTotal = false;
                this.activarRechazar = true;
                this.activarFormulario = true;
                this.activarAprobar = true;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                this.activarEspera = true;


                break;

            case 4: // PUEDO SOLICITAR PAGO
                this.activarPreAprobar = false;
                this.activarSolicitarPagoTotal = true;
                this.activarRechazar = true;
                this.activarFormulario = true;
                this.activarAprobar = true;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                this.activarEspera = true;


                break;

            case 5: // PAGO SOLICITADO O
                this.activarPreAprobar = false;
                this.activarSolicitarPagoTotal = false;
                this.activarRechazar = false;
                this.activarFormulario = true;
                this.activarAprobar = true;

                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;

                this.activarEspera = true;


                break;

            case 6: // CONTROLAR PAGO
                this.activarRechazar = true;
                this.activarFormulario = true;
                this.activarAprobar = true;
                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.pendiente;

                this.activarEspera = true;


                break;

            case 7: // APROBADA
                this.activarAprobar = false;
                this.activarFormulario = true;
                this.flagLabelAprobado = true;
                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.completo;

                // puede rechazar la solicitud
                this.activarRechazar = true;

                this.activarEspera = true;

                break;

            case 8: // RECHAZADO

                this.activarFormulario = true;
                this.flagLabelRechazado = false;
                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                break;

            case 9: // ANULADA

                this.activarFormulario = true;
                this.activarRechazar = false;

                this.flagLabelRechazado = false;
                this.activaAnulada = true;
                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                break;

            case 10: // EN ESPERA

                this.activarAprobar = true;
                this.activarFormulario = true;
                this.flagLabelAprobado = false;
                this.estadoFormulario = this.iAppOfertas.formulario.completo;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;
                this.estadoPagoTotal = this.iAppOfertas.estadoPagoTotal.completo;

                    // puede rechazar la solicitud
                this.activarRechazar = true;

                this.activarEspera = false;

                break;

            default:
                this.estadoFormulario = this.iAppOfertas.formulario.pendiente;
                this.estadoDocumentacion = this.iAppOfertas.documentacion.pendiente;
                break;
        }

    }

    async getArrayImgsAndFile(adjuntos: string[]){
        await adjuntos.forEach(element => {
            if (element.search('.pdf') !== -1) {
                this.files.push(element);
            } else {
                this.imgs.push(element);
            }
        });
    }


    /* Modal Documento  - Carga Contrato */
    openDialog(label: string, estado: string, idAdjunto: string): void {
        const dialogRef = this.dialog.open(UploadFileSolicitudComponent, {
          width: '800px',
          height: '800px',
          disableClose: true,
          data: {
                 solicitud: this.solicitud,
                 adjunto: idAdjunto,
                 label,
                 numberState: estado,
                 labelButton: 'ENVIAR CONTRATO',
                 accept : 'application/pdf' // image/jpeg, image/jpg, image/png,application/pdf
                }
        });

        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                 //   await this.refreshComponent('ofertas-solicitud');
                 await this.refreshComponent('ofertas-administracion-listado');

                 const messageDto = await this.mailerService.prepareMailToSend
                 (this.solicitud.usuario,  await this.getSolicitud(), true, this.adjuntoCatalogo.properties._idAdjunto, 'TEMPLATE_MAILER_CONTRATO');

                 await this.mailerService.sendMail(messageDto);

                 this.dataModal = result;
                } else {
                    return;
            }

        });
    }

    /* Modal Solicitar Pago  */
    openDialogSolicitarPago(data: Solicitud): void {
    const dialogRef = this.dialog.open(ModalSolicitarPagoComponent , {
        width: '900px',
        height: '800px',
        disableClose: true,
        data,
    });

    dialogRef.afterClosed().subscribe(async (resp: PaypalPurchaseUnit) => {
        if (resp) {
           this.procesarEnvioSolicitudPagoTotal(resp);
        } else {
            return;
        }
    });
}

    /* REFRESH FORM */
    refreshComponent(url) {
        this.router.navigateByUrl(url, { skipLocationChange: true }).then(async () => {
            await this.closeModal();
            await this.openDialogModaSolicitud();
            await this.router.navigate([url]);
        });
    }

    /* Modal - Carga Solicitud */
    openDialogModaSolicitud(): void {
        const dialogRef = this.dialog.open(OfertasSolicitudComponent, {
            width: '900px',
            height: '1000px',
            disableClose: true,
        });
        // this.flagLoadedModal = true;
        dialogRef.afterClosed().subscribe(async result => {
        });
        return;
    }

    /* DESACTIVAR BOTONES */
    async resetAndDisableButtons() {
        this.activarFormulario = false;
        this.activarPreAprobar = false;
        this.activarRechazar = false;
        this.activarSolicitarPagoTotal = false;
        this.activarCerrarModal = false;
        this.motivoRechazo = '';
    }

    /* ACCIONES solicitud */
    // tslint:disable-next-line:max-line-length
    async actualizarEstadoSolicitud(_nroSolicitud: number|string, moverDocumentoSolicitudAusuario: boolean, _id: string, numberState: number, state?: string, notificar?: boolean, observaciones?: string) {

        await this.resetAndDisableButtons();
        this.flagSpinner = true;

        // tslint:disable-next-line:max-line-length
        this.solicitudesService.updateSolicitudState(_id, numberState, false, state, notificar, observaciones).then( async (solicitudUpdated: Solicitud) => {
              await this.solicitudesService.setSolicitudSeleccionada(solicitudUpdated);

            /*  ############### MANEJO ESPECIAL DE ESTADOS ############### */
              // CUANDO APRUEBO -> ASIGNO MODULOS DE  INSCCRIPCION SI ES APROBADA
             // tslint:disable-next-line:max-line-length
              if (numberState === this.iAppOfertas.estado_7.number) {this.cursosService.setCursosAlumnoAdministrativo(this.solicitud.cursos, this.solicitud.usuario._id, _nroSolicitud);}

              // CUANDO RECHAZO -> QUITO CURSOS/MODULOS ASIGNADOS AL RECHAZAR- CUANDO SE APROBO POR ERROR EN PRIMERA INSTANCIA
              if (numberState === this.iAppOfertas.estado_8.number) {
                  this.solicitud.cursos.forEach(async element => {
                    this.cursosService.deleteByAlumnoCurso(await
                        (await this.cursosService.findAlumnoByUsuario(this.solicitud.usuario._id))._id,
                         element._id);
                  });
               }
             /*  ############### FIN MANEJO ESPECIAL DE ESTADOS ############### */

              this.flagSpinner = false;

              this.solicitudesService.setNewState(solicitudUpdated);

              this.guiMsjService.msjFormSubmit('solicitudEstadoOK');
        });

        /*  ############### MOVER DOCUMENTOS SOLICITUD xA USUARIO ############### */
        if (moverDocumentoSolicitudAusuario) {
            // MUEVO LOS DOCUMENTOS DE LA SOLICITUD QUE NO ESTAN EN SU LEGAJO - SIEMPRE A PENAS SE VE LA SOLICITUD LOS CONTROLA.
            // tslint:disable-next-line:max-line-length
            this.usuario.adjuntos = await this.solicitudesService.moverDocumentosFileSolicitudAusuario(this.usuario, (await this.getSolicitud()).adjuntos);
        } else {
            console.log('sin movimientos de docuementos a personas');
        }

        /*  ############### CREO PERSONA EN FACTURACION SI EL ESTADO ES APROBADO Y EL CLIENTE AADIDESS ############### */
        this.crearUsuarioFacturacion(numberState);


    }

    async getConfiguracionGral() {
        const configApp: ConfiguracionApp = await this.configBeginService.getConfiguracionGral();
    }

    async crearUsuarioFacturacion(numberState: number) {
        const empresa: string = (await this.configBeginService.getConfiguracionGral()).empresa;

        if (empresa === 'AADIDESS' && numberState === this.iAppOfertas.estado_4.number) {

          const usuario: User = {
            nombrePersona: this.usuario.nombrePersona,
            apellidoPersona: this.usuario.apellidoPersona,
            identificacion: this.usuario.identificacion,
            email: this.usuario.email
          };

          const flag = await this.usuarioService.crearUsuarioEnFacturacion(usuario);
          const message = flag ? 'Persona ya disponible en sistema de Gestión/Facturación.' : 'Persona NO creado en  Gestión/Facturación.';

          this.mostrarToast(message);
        } else {
          console.log('No creo persona en facturación...');
        }
    }

    mostrarToast(message: string) {
        this.snackBar.open(message, 'Cerrar', {
          duration: 5000, // Duración del toast en milisegundos
          horizontalPosition: 'center', // Posición horizontal del toast
          verticalPosition: 'bottom', // Posición vertical del toast
        });
    }

    async solicitarPagoTotal(solicitud: Solicitud, numberState: number){
        // modal para administrar el pago total
        this.openDialogSolicitarPago(solicitud);
    }

    async procesarEnvioSolicitudPagoTotal(data: PaypalPurchaseUnit) {

        await this.resetAndDisableButtons();
        this.flagSpinner = true;

        // Creo invoice
        const invoice: Invoice = await this.invoicesService.createInvoiceByPaypalPurchuseUnit(data, true, 'OFERTA_LABORAL');
        console.log('pago:', invoice);
        const invoiceGenerated: Invoice = await this.invoicesService.procesarEnvioSolicitudPagoTotal(invoice);
        this.flagSpinner = false;

        // Actualizo estado
        // tslint:disable-next-line:max-line-length
        this.actualizarEstadoSolicitud(this.solicitud._nro, false, invoiceGenerated.data.solicitud._id, this.iAppOfertas.estado_5.number, this.iAppOfertas.estado_5.name, false);

    }

    // MODAL CLOSE
    closeModal(): void {
        this.dialogRef.close();
    }

    async getArrayDocumentUserImgsAndFile(adjuntos: string[]) {
        await adjuntos.forEach(element => {
          if (element.search('.pdf') !== -1) {
            this.files.push(element);
          } else {
            this.imgs.push(element);
          }
        });
      }

    async getDocumentosUser() {
        console.log('CARGO DOC USUARIOS', this.usuario?.adjuntos);
        try {
            await this.getArrayDocumentUserImgsAndFile(this.usuario?.adjuntos);
        } catch (error) {
            console.error(`No se encontraron los adjuntos del usuario con identificacion${this.usuario?._id}`);
        }
    }

    quitarDuplicados(file: string[]): string[] {
        return Array.from(new Set(file));
    }

    async habilitarBotonObservaciones(motivo: string) {
        this.habilitarEnviarObservacion = motivo.trim() !== '';
    }


}
