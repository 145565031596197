import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { observable } from 'rxjs';
import { Examen } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';

@Component({
  selector: 'app-evaluacion-modificar',
  templateUrl: './evaluacion-modificar.component.html',
  styleUrls: ['./evaluacion-modificar.component.scss']
})
export class EvaluacionModificarComponent implements OnInit {

  tabAsignarActivated = false;
  tabPreguntasActivated = false;
  tabRespuestasActivated = false;
  moveToTab = observable;
  examen: Examen;

  constructor(
    private router: Router,
    private cursosService: CursosService,
    private guiMsjService: GuiMsjService,
    private examenesService: ExamenesService
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.getExamen().then(()=>{
      this.examenesService.setPreguntas$(this.examen.preguntas);
    })

  }

  moveToSelectedTab(tabName: string) {
      for (let i = 0; i < document.querySelectorAll('.mat-tab-label-content').length; i++) {
          if ((<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).innerText === tabName)
          {
              (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
          }
      }
  }
  async getExamen() {
    this.examen = this.examenesService.getExamenSeleccionado();
  }
}
