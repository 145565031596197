<div class="coming-soon-area" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="coming-soon-content">
                <a routerLink="/" class="logo"><img [src]="logoUrl" alt="image"></a>
                <h2>Próximamente Campus Virtual</h2> <br>
                <p> <strong>Plataforma educativa y de ofertas laborales</strong> </p>
                <!--<div id="timer" class="flex-wrap d-flex justify-content-center">
                    <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}} <span>Days</span></div>
                    <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}} <span>Hours</span></div>
                    <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}} <span>Minutes</span></div>
                    <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}} <span>Seconds</span></div>
                </div>-->
            </div>
            <app-buscador-aereos></app-buscador-aereos>
        </div>
    </div>
</div>