<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/adjuntos-configuracion">Configuración Adjuntos</a></li>
                <li>Modificar Adjunto</li>
            </ul>
            <h2>Modificar Adjunto</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-2 col-md-12"></div>
            <div class="col-lg-8 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="form" (ngSubmit)="put()">


                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Nombre</mat-label>
                                <input formControlName="nombre" matInput placeholder="Ingrese nombre de ajunto" [class.is-invalid]="nombreNoValido" autocomplete="off">
                                <small *ngIf="nombreNoValido" class="text-danger">Ingrese nombre de ajunto</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Descripción</mat-label>
                                <textarea formControlName="descripcion" [class.is-invalid]="descripcionNoValida" matInput placeholder="Ingrese una descripción"></textarea>
                                <small *ngIf="descripcionNoValida" class="text-danger">Ingrese una descripción</small>
                            </mat-form-field>
                        </div>

                        <button [disabled]="!form.valid" type="submit" disabled>Guardar</button>
                        <a routerLink="/adjuntos-configuracion"> <button type="button">Cancelar</button></a>
                        <br>
                        <br>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>
