<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ idioma | translate: "form.MODIFICAR-CLAVE" | async}}</h2>
        </div>
    </div>
</div>
<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="clearfix">
            <p>
                {{ idioma | translate: "login.MSJ-CONTRASENA" | async}}
            </p>
        </div>
        <div class="apply-form">
            <form [formGroup]="form" (ngSubmit)="actualizarClave()">

                <div class="col-lg-12 col-md-12">

                    <div class="form-group inner-addon right-addon">
                        <label class="form-label">Nueva contraseña</label>
                        <div class="input-group col-6 mx-auto">
                            
                            <input class="form-control" [type]="showPassword ? 'text' : 'password'" formControlName="password" [class.is-invalid]="passwordNoValido" [placeholder]=" idioma | translate: 'login.placeholders.INGRESE-CONTRASENA' | async" autocomplete="off">
                            <i alt="show" class="far fa-eye eye-show"
                               (click)="showPassword = !showPassword"
                               [class.hide]="showPassword"></i>
                            <i alt="hide" class="far fa-eye-slash eye-hide"
                               (click)="showPassword = !showPassword"
                               [class.hide]="!showPassword"></i>

                        </div>
                        <small *ngIf="passwordNoValido" class="text-danger">{{ idioma | translate: "login.MSJ-CONTRASENA-INVALIDA" | async}}</small>
                    </div>

                    <div class="form-group inner-addon right-addon">
                        <label class="form-label">Confirme nueva contraseña</label>
                        <div class="input-group col-6 mx-auto">
                            
                            <input class="form-control" [type]="showPasswordConfirm ? 'text' : 'password'" formControlName="confirmPassword" [placeholder]=" idioma | translate: 'login.CONFIRMAR-CONTRASENA' | async" [class.is-invalid]="confirmPasswordNoValido" autocomplete="off">
                            <i alt="show" class="far fa-eye eye-show"
                               (click)="showPasswordConfirm = !showPasswordConfirm"
                               [class.hide]="showPasswordConfirm"></i>
                            <i alt="hide" class="far fa-eye-slash eye-hide"
                               (click)="showPasswordConfirm = !showPasswordConfirm"
                               [class.hide]="!showPasswordConfirm"></i>

                        </div>
                        <small *ngIf="confirmPasswordNoValido" class="text-danger">{{ idioma | translate: "login.CONTRASENA-INVALIDA" | async}}</small>
                    </div>

                </div>

                <button *ngIf="!confirmPasswordNoValido" [disabled]="!form.valid" class="btn btn-primary" type="submit" class="button" disabled>{{ idioma | translate: "form.BTN-ACEPTAR" | async}}</button>
                <button (click)="this.closeModal()" class="btn btn-secondary">{{ idioma | translate: "form.BTN-CANCELAR" | async}}</button>

            </form>
        </div>
    </div>
</div>