import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-colors',
  templateUrl: './spinner-colors.component.html',
  styleUrls: ['./spinner-colors.component.scss']
})
export class SpinnerColorsComponent implements OnInit {

  @Input() message = 'procesando, espere porfavor...';

  constructor() { }

  ngOnInit(): void {
  }

}
