import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Inject, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { APP_CONFIG_SHARED, IAppShared } from 'src/app/app.config/app.config-shared';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { Mesa, MesaUsuario } from 'src/app/interfaces/interface-bakend/cursos/mesa.interface';
import { Disciplina } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { MesasService } from 'src/app/services/cursos/mesas.service';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { element } from 'protractor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mesa-modificacion',
  templateUrl: './mesa-modificacion.component.html',
  styleUrls: ['./mesa-modificacion.component.scss']
})
export class MesaModificacionComponent implements OnInit {
  data: any[];
  usuarios: User[] = [];
  usuariosNombres: any[];
  mesaPublicada: boolean = true;
  cursos: Curso[];
  disciplinas: Disciplina[];
  delegaciones: Catalogo[];
  mesa: Mesa;
  tipo: string;
  delegacion: string;
  disciplina: any;
  niveles: number[] = [1, 2, 3, 4, 5, 6, 7];
  selectedNivel: number = 1;

  // Debería usar la interfaz MesaUsuario (Ahora funcionando así sin esa interfaz)
  usuariosSeleccionadosPush: MesaUsuario[] = [];

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns = ['select', 'nombre', 'apellido', 'email', 'identificacion'];
  dataSource: any;

  seleccion = new SelectionModel<any>(true, []);
  flagLoaded: boolean;

  seleccionados: string[] = [];
  cursosSeleccionados = [];

  form = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });

  constructor(private cursoService: CursosService,
    private mesaService: MesasService,
    private catalogoService: CatalogoService, @Inject(APP_CONFIG_SHARED)
    private iAppShared: IAppShared,
    private _formBuilder: FormBuilder,
    private guiMsjService: GuiMsjService,
    private userService: UsuarioService,
    private router: Router) { }

  ngOnInit(): void {
    this.getEstadoVariables();
    this.getCursos();
    this.getDelegaciones();
    this.getDisciplinas();
    this.loadDataTable();
    this.createForm();
  }

  onChange(estado: boolean) {
    this.mesaPublicada = estado;
  }

  getMesaSeleccionada() {
    return this.mesa = this.mesaService.getMesaSeleccion();
  }

  async getEstadoVariables() {
    if (this.getMesaSeleccionada() === undefined) {
      this.router.navigateByUrl('/mesas-listado');
      return;
    } else {
      this.getMesaSeleccionada();
      this.seleccionados = this.mesa?.usuarios;
      this.mesaPublicada = this.mesa?.publica;
      this.tipo = this.mesa?.tipoMesa;
      this.delegacion = this.mesa?.delegacion;
      this.disciplina = this.mesa?.disciplina._id;
      this.cursosSeleccionados = this.mesa?.cursos;
      this.selectedNivel = this.mesa?.nivel;
      this.seleccionados = this.mesa?.usuarios.map(usuario => usuario.usuario._id) ?? [];
    }
  }

  createForm() {
    this.form = new FormGroup({
      titulo: new FormControl(this.mesa?.titulo, [Validators.required]),
      descripcionCorta: new FormControl(this.mesa?.descriptionCorta, [Validators.required]),
      descripcionLarga: new FormControl(this.mesa?.descriptionLarga, [Validators.required]),
      fechaDesdeExamen: new FormControl(this.mesa?.fechaExamenDesde, [Validators.required]),
      fechaHastaExamen: new FormControl(this.mesa?.fechaExamenHasta, [Validators.required]),
      fechaPublicacionDesde: new FormControl((this.mesa?.fechaPublicaDesde != null ? this.mesa.fechaPublicaDesde : new Date())),
      fechaPublicacionHasta: new FormControl((this.mesa?.fechaPublicahasta != null ? this.mesa.fechaPublicahasta : new Date())),
      curso: new FormControl(''),
      delegacion: new FormControl('', [Validators.required]),
      disciplina: new FormControl('', [Validators.required]),
      tipoMesa: new FormControl(Validators.required),
      nivel: new FormControl(this.mesa?.nivel, [Validators.required]),
      mesaPublicada: new FormControl(this.mesa?.publica)
    });
  }

  async getCursos() {
    this.cursos = await this.cursoService.getCursos();
  }

  async getDisciplinas() {
    await this.cursoService.getDisciplinas().then((disciplinas: Disciplina[]) => {
      this.disciplinas = disciplinas;
    });
  }

  async getDelegaciones() {
    this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.delegaciones, null).
      then((resp: Catalogo[]) => {
        return this.delegaciones = resp;
      });
  }

  async loadDataTable() {
    this.userService.getAllUsers().then((usuarios: User[]) => {
      this.data = usuarios;
      this.dataSource = new MatTableDataSource(usuarios);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    });
  }

  isAllSelected() {
    if (!this.dataSource) {
      return false;
    }

    const numSelected = this.seleccionados.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (!this.dataSource) {
      return;
    }

    const allSelected = this.isAllSelected();
    this.dataSource.data.forEach((row: any) => {
      const index = this.seleccionados.indexOf(row._id);

      if (allSelected) {
        if (index !== -1) {
          this.seleccionados.splice(index, 1);
        }
      } else {
        if (index === -1) {
          this.seleccionados.push(row._id);
        }
      }
    });
  }

  toggleSelectedId(id: string) {
    const index = this.seleccionados.indexOf(id);
    if (index >= 0) {
      this.seleccionados.splice(index, 1);
    } else {
      this.seleccionados.push(id);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async setDataForm() {

    this.usuariosSeleccionadosPush.splice(0)
    const nuevosUsuarios: MesaUsuario[] = [];

    // Verificar si cada usuario seleccionado ya existe en usuariosSeleccionadosPush
    for (const user of this.seleccionados) {
      const index = this.usuariosSeleccionadosPush.findIndex(u => u.usuario === user);
      if (index === -1) {
        const nuevoUsuario: MesaUsuario = {
          usuario: user,
          fechaInscripcion: new Date()
        };
        nuevosUsuarios.push(nuevoUsuario);
      }
    }

    // Agregar los nuevos usuarios al array usuariosSeleccionadosPush
    this.usuariosSeleccionadosPush.push(...nuevosUsuarios);

    const data: Mesa = {
      _id: this.mesa._id,
      titulo: this.form.value.titulo,
      descriptionCorta: this.form.value.descripcionCorta,
      descriptionLarga: this.form.value.descripcionLarga,
      fechaExamenDesde: this.form.value.fechaDesdeExamen,
      fechaExamenHasta: this.form.value.fechaHastaExamen,
      publica: this.mesaPublicada,
      cursos: this.form.value.curso,
      fechaPublicaDesde: this.form.value.fechaPublicacionDesde,
      fechaPublicahasta: this.form.value.fechaPublicacionHasta,
      delegacion: this.form.value.delegacion,
      disciplina: this.form.value.disciplina,
      nivel: Number(this.form.value.nivel),
      tipoMesa: this.form.value.tipoMesa,
      usuarios: this.usuariosSeleccionadosPush
    };
    if (!this.form.value.curso) {
      delete data.cursos
    }
    return data;
  }

  async post() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.mesaService.put(this.mesa._id, await this.setDataForm()).then(async (mesa: Mesa) => {
    }).catch((error) => {
      console.warn(error);
      Swal.close();
    })
  }
}
