import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Translators } from 'src/app/services/shared/translators.service';
import { Curso } from '../../../../interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';

@Component({
  selector: 'app-curso-descripcion-compra',
  templateUrl: './curso-descripcion-compra.component.html',
  styleUrls: ['./curso-descripcion-compra.component.scss']
})
export class CursoDescripcionCompraComponent implements OnInit {

  curso: Curso ;
  flagCargado = false;
  cursosDisponibles: Curso[] = [];
  idioma: Subscription;


  constructor(
    private cursosService: CursosService,
    private router: Router,
    private translatorService: Translators,
    private authService: AuthService,
    private guiMsjService: GuiMsjService, 
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        this.idioma = resp;
    });
  }

  ngOnInit(): void {
    this.getEstadoVariables();
    this.getCursosDisponibles();
  }

  /*getCurso(){
    return this.curso = this.cursosService.getCursoSeleccionado();
  }*/

  getEstadoVariables(){
    if (this.cursosService.getCursoSeleccionado() === undefined) {

      this.router.navigateByUrl('/cursos-disponibles-compra');
      return;
    } else {
        this.getCursoFindOne();
    }
  }

  async getCursoFindOne(){
    await this.cursosService.getCursoFindOne(this.cursosService.getCursoSeleccionado().id)
    .then((cursoSeleccionado: Curso) => {

      this.curso = cursoSeleccionado;
      this.flagCargado = true;

    });

 }

  getCursosDisponibles() {
    this.cursosService.getCursosDisponibles().then((cursos: Curso[]) => {
      this.cursosDisponibles = cursos;
    });
  }

  async cursoComprar() {
    const flagInvitado  = await this.authService.esUsuarioInvitado();
    if (flagInvitado) {
        this.guiMsjService.msjFormSubmit('invitado');
        this.router.navigateByUrl('/profile-authentication');
    } else {
        this.router.navigateByUrl('/curso-compra');
    }
  }

}
