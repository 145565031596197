import { Component, OnInit } from '@angular/core';
import { PerfilesService } from '../../../../services/perfiles/perfiles.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ofertas-administracion',
  templateUrl: './ofertas-administracion.component.html',
  styleUrls: ['./ofertas-administracion.component.scss']
})
export class OfertasAdministracionComponent implements OnInit {

  constructor(
      private perfilesService: PerfilesService,
      private router: Router,
  ) { }

  ngOnInit(): void {
    this.perfilesService.allowedByUserLogon(this.router.url);
  }

}
