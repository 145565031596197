<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>
<app-loading *ngIf="flagProcesando" class="m-5"></app-loading><br>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ idioma | translate: "solicitudes.formulario.TITULO" | async}} {{this.nombrePais}} - {{nombreActividad}}</h2>
        </div>
    </div>
</div>

<div *ngIf="flagLoaded" class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">

    <div class="container">
        <div class="clearfix">
            <img src="assets/img/apply.jpg" class="float-md-start ms-5 me-3" alt="solicitud" width="25%">
            <h3>{{ idioma | translate: "solicitudes.formulario.SUBTITULO" | async}}</h3>
            <p>
                {{ idioma | translate: "solicitudes.formulario.PARRAFO-1" | async}} {{this.nombrePais}}{{ idioma | translate: "solicitudes.formulario.PARRAFO-2" | async}}
            </p>

        </div>
        <hr>
        <div class="row  mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="apply-form">
                    <div *ngIf="this.flagPreCarga" class="p-3 mb-2 bg-primary text-white">
                        {{ idioma | translate: "form.MSJ-REGISTRADO" | async}}
                    </div>
                    <h2>{{ idioma | translate: "form.FORMULARIO" | async}}</h2>
                    <form [formGroup]="forma" (ngSubmit)="onSubmit()">
                        <div class="row">

                            <div class="col-lg-6 col-md-6">

                                <div class="form-group">
                                    <label>{{ idioma | translate: "form.PAIS" | async}}</label>
                                    <input type="text" class="form-control" placeholder="" value="{{ nombrePais }}" readonly>
                                </div>
                                <div class="form-group">
                                    <label for="exampleDataList" class="form-label">{{ idioma | translate: "solicitudes.ACTIVIDAD" | async}}</label>
                                    <input type="text" class="form-control" placeholder="" value="{{ nombreActividad }}" readonly><br>
                                </div>

                                <div class="form-group">
                                    <label>{{ idioma | translate: "form.NOMBRE" | async}}</label>
                                    <input [readonly]="flagPreCarga" formControlName="nombre" type="text" [placeholder]=" idioma | translate: 'form.placeholders.NOMBRE' | async" class="form-control uppercase" [class.is-invalid]="nombre">
                                </div>

                                <div class="form-group">
                                    <label>{{ idioma | translate: "form.APELLIDO" | async}}</label>
                                    <input [readonly]="flagPreCarga" formControlName="apellido" type="text" [placeholder]=" idioma | translate: 'form.placeholders.APELLIDO' | async" class="form-control uppercase" [class.is-invalid]="apellido">
                                </div>


                                <div class="form-group">
                                    <mat-form-field appearance="fill">
                                        <label for="tipoIdentificacion">{{ idioma | translate: "form.TIPO-ID" | async}}</label>
                                        <mat-select [disabled]="flagPreCarga" formControlName="tipoIdentificacion" (ngModelChange)="onChangeTipoIdentificacion($event);">
                                            <mat-option *ngFor="let ti of tiposIdentificaciones" value="{{ti.concepto}}" [class.is-invalid]="tipoIdentificacion">{{ti.concepto}} </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <label>{{ idioma | translate: "form.NRO-ID" | async}}</label>
                                    <input formControlName="identificacion" type="text" [placeholder]=" idioma | translate: 'form.placeholders.ID-NO' | async" class="form-control" [class.is-invalid]="identificacion">
                                    <div *ngIf="this.flagExisteIdentificacion">
                                        <small class="text-danger text-align-right">{{ idioma | translate: "form.MSJ-ID-EXISTE" | async}}</small>
                                        <div class="p-3 mb-2 bg-primary text-white">{{ idioma | translate: "form.MSJ-EMAIL-EXISTE" | async}}</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <label>{{ idioma | translate: "form.FECHA-NACIMIENTO" | async}}</label>
                                        <input formControlName="birth" matInput [matDatepicker]="picker">
                                        <mat-hint>DD/MM/YYYY</mat-hint>
                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <label for="paisesDataList" class="form-label">{{ idioma | translate: "form.NACIONALIDAD" | async}}</label>
                                    <input formControlName="paisNacimiento" class="form-control" (ngModelChange)="onChangePais($event)" list="paises" id="datalistPaisesDisponibles" [placeholder]=" idioma | translate: 'form.BUSCADOR-TEXT-INPUT' | async" [class.is-invalid]="paisNacimiento">
                                    <!-- <input [readonly]="flagPreCarga" formControlName="paisNacimiento" class="form-control" (ngModelChange)="onChangePais($event)" list="paises" id="datalistPaisesDisponibles" [placeholder]=" idioma | translate: 'form.BUSCADOR-TEXT-INPUT' | async" [class.is-invalid]="paisNacimiento"> -->
                                    <datalist id="paises">
                                    <option *ngFor="let pais of paises" [value]="pais.name_es" >{{pais.name_es}}</option>
                                    </datalist>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">

                                <!-- AADIDESS NO LO USA -->
                                <div class="form-group" hidden>
                                    <mat-form-field appearance="fill">
                                        <label for="estadoCivilDataList" class="form-label">{{ idioma | translate: "form.ESTADO-CIVIL" | async}}</label>
                                        <mat-select formControlName="estadoCivil" (ngModelChange)="onChangeEstadoCivil($event)">
                                            <mat-option *ngFor="let ec of estadosCiviles" value="{{ec._id}}" [class.is-invalid]="estadoCivil">{{ec.concepto}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <label class="form-label">{{ idioma | translate: "form.CODIGO-AREA" | async}}</label>
                                    <input formControlName="paisCodeArea" class="form-control" [placeholder]=" idioma | translate: 'form.placeholders.CODIGO-AREA' | async" [class.is-invalid]="paisCodeArea">
                                </div>

                                <div class="form-group">
                                    <label>{{ idioma | translate: "form.NUMERO-TELEFONO" | async}}</label>
                                    <input formControlName="telefono" type="text" [placeholder]=" idioma | translate: 'form.placeholders.TELEFONO' | async" class="form-control" [class.is-invalid]="telefono">
                                </div>

                                <!-- AADIDESS DISCIPLINA CATALOGO-->
                                <div class="form-group">
                                    <label for="paisesDataList" class="form-label">{{ idioma | translate: "form.DESCIPLINA" | async}}</label>
                                    <input formControlName="disciplina" class="form-control" (ngModelChange)="onChangeDisciplina($event)" list="disciplinas" id="datalistDisciplinasDisponibles" [placeholder]=" idioma | translate: 'form.BUSCADOR-TEXT-INPUT' | async" [class.is-invalid]="disciplina">
                                    <datalist id="disciplinas">
                                    <option *ngFor="let disciplina of disciplinas" [value]="disciplina.concepto" >{{disciplina.concepto}}</option>
                                    </datalist>
                                </div>

                                <!-- AADIDESS DELEGACION CATALOGO -->
                                <div class="form-group">
                                    <label for="paisesDataList" class="form-label">{{ idioma | translate: "form.DELEGACION" | async}}</label>
                                    <input formControlName="delegacion" class="form-control" (ngModelChange)="onChangeDelegacion($event)" list="delegaciones" id="datalistDelegacionesDisponibles" [placeholder]=" idioma | translate: 'form.BUSCADOR-TEXT-INPUT' | async" [class.is-invalid]="delegacion">
                                    <datalist id="delegaciones">
                                    <option *ngFor="let delegacion of delegaciones" [value]="delegacion.concepto" >{{delegacion.concepto}}</option>
                                    </datalist>
                                </div>

                                <!-- AADIDESS NO LO USA -->
                                <div class="form-group" hidden>
                                    <label>{{ idioma | translate: "form.COMUNITARIO" | async}}</label>
                                    <mat-form-field appearance="fill">
                                        <mat-select formControlName="condiMigra" (ngModelChange)="onChangeCondiMigra($event)">
                                            <mat-option *ngFor="let f of paisDondeAplicaFormas" value="{{f.concepto}}" [class.is-invalid]="condiMigra">{{f.concepto}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <label for="exampleDataList" class="form-label">{{ idioma | translate: "form.CODIGO_POSTAL" | async}}</label>
                                    <input formControlName="cp" type="text" [placeholder]=" idioma | translate: 'form.placeholders.CODIGO-POSTAL' | async" class="form-control" [class.is-invalid]="cp">
                                </div>

                                <!-- AADIDESS NO LO USA -->
                                <div class="form-group" hidden>
                                    <label>{{ idioma | translate: "form.PASAPORTE-VALIDO" | async}}</label>
                                    <mat-radio-group formControlName="pasaporteValido" aria-label="Select an option" [class.is-invalid]="pasaporteValido">
                                        <mat-radio-button value="true">{{ idioma | translate: "form.SI" | async}}</mat-radio-button>
                                        <mat-radio-button value="false">{{ idioma | translate: "form.NO" | async}}</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <!-- AADIDESS NO LO USA -->
                                <div class="form-group" hidden>

                                    <label>{{ idioma | translate: "form.IDIOMAS" | async}}</label>

                                    <mat-checkbox formControlName="ca">{{ idioma | translate: "form.CATALAN" | async}}</mat-checkbox><br>
                                    <mat-checkbox formControlName="es">{{ idioma | translate: "form.ESPANOL" | async}}</mat-checkbox><br>
                                    <mat-checkbox formControlName="fr">{{ idioma | translate: "form.FRANCES" | async}}</mat-checkbox><br>
                                    <mat-checkbox formControlName="en">{{ idioma | translate: "form.INGLES" | async}}</mat-checkbox>
                                </div>

                                <!-- AADIDESS -->
                                <div class="form-group">
                                    <label>{{ idioma | translate: "form.TITULO-SECUNDARIO" | async}}</label>
                                    <mat-radio-group [(ngModel)]="this.flagTitulo" formControlName="tituloSecundario" aria-label="Select an option">
                                        <mat-radio-button value="true" [checked]= "this.usuario.tituloSecundario">{{ idioma | translate: "form.TITULO-SECUNDARIO-SI" | async}}</mat-radio-button>
                                        <mat-radio-button value="false" [checked]= "!this.usuario.tituloSecundario">{{ idioma | translate: "form.TITULO-SECUNDARIO-NO" | async}}</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div *ngIf="this.flagTitulo.toString() == 'true'" class="form-group">
                                    <label for="exampleDataList" class="form-label">{{ idioma | translate: "form.TITULO-SECUNDARIO-DE" | async}}</label>
                                    <input formControlName="tituloDe" class="form-control" type="text" [placeholder]="idioma | translate: 'form.placeholders.TITULO-SECUNDARIO-DE' | async" [class.is-invalid]="tituloDe">
                                </div>

                                <div *ngIf="this.flagTitulo.toString() == 'true'" class="form-group">
                                    <label for="exampleDataList" class="form-label">{{ idioma | translate: "form.TITULO-SECUNDARIO-EXPEDIDO" | async}}</label>
                                    <input formControlName="tituloExpedido" class="form-control" type="text" [placeholder]="idioma | translate: 'form.placeholders.TITULO-SECUNDARIO-EXPEDIDO' | async" [class.is-invalid]="tituloExpedido">
                                </div>

                                <!-- AADIDESS -->
                                <div class="form-group">
                                    <label>{{ idioma | translate: "form.ENFERMEDAD-PREGUNTA" | async}}</label>
                                    <mat-radio-group 
                                    formControlName="enfermedad"  
                                    aria-label="Select an option">
                                        <mat-radio-button value="true" [checked]= "this.usuario.enfermedad">{{ idioma | translate: "form.ENFERMEDAD-SI" | async}}</mat-radio-button>
                                        <mat-radio-button value="false" [checked]= "!this.usuario.enfermedad">{{ idioma | translate: "form.ENFERMEDAD-NO" | async}}</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <!-- AADIDESS -->
                                <div class="form-group">
                                    <label>{{ idioma | translate: "form.HOSPITALIZADO-PREGUNTA" | async}}</label>
                                    <mat-radio-group formControlName="hospitalizado" aria-label="Select an option">
                                        <mat-radio-button value="true" [checked]= "this.usuario.hospitalizado">{{ idioma | translate: "form.HOSPITALIZADO-SI" | async}}</mat-radio-button>
                                        <mat-radio-button value="false" [checked]= "!this.usuario.hospitalizado">{{ idioma | translate: "form.HOSPITALIZADO-NO" | async}}</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <!-- AADIDESS -->
                                <div class="form-group">
                                    <input type="checkbox" formControlName="declaracion" style="margin-right: 5px !important;"> <em>{{ idioma | translate: "legales.LEGAL-CTF-5" | async}}
                                        - <a (click)="openBlank('CAADIDESS-I.S.E.S')" class="link" style="text-decoration: none;">{{ idioma | translate:"legales.LEGAL-CTF-1" | async}}</a></em>
                                </div>

                            </div>

                        </div>
                        <button [disabled]="!this.flagFormaValido" type="submit" class="animate__animated animate__fadeIn">{{ idioma | translate: "form.ENVIAR-FORM" | async}}</button>
                        <!-- <button [disabled]="!forma.valid" type="submit" class="animate__animated animate__fadeIn">Enviar Formulario</button> -->
                        <br>
                        <br>
                        <mat-progress-bar *ngIf='flagProcesando' mode="indeterminate"></mat-progress-bar>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>