import { Component, OnInit, Inject, Output } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../../../../app.config/app.config-ofertas';
import { Solicitud } from '../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { SolicitudesService } from '../../../../../services/ofertas-laborales/solicitudes.service';
import { InfoSolicitud } from '../../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { EstadisticasService } from '../../../../../services/ofertas-laborales/estadisticas.service';
import { GuiToolsService } from '../../../../../services/shared/gui-tools.service';
import { PerfilesService } from '../../../../../services/perfiles/perfiles.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';

@Component({
  selector: 'app-ofertas-estadisticas',
  templateUrl: './ofertas-estadisticas.component.html',
  styleUrls: ['./ofertas-estadisticas.component.scss']
})
export class OfertasEstadisticasComponent implements OnInit {

    constructor(
        @Inject(APP_CONFIG_OFERTAS) public iAppOfertas: IAppOfertas,
        private estadisticasService: EstadisticasService,
        private solicitudesService: SolicitudesService,
        private router: Router,
        private guitoolsService: GuiToolsService,
        private perfilesService: PerfilesService,
        private usuariosService: UsuarioService,
    ) {
        const hoy = new Date();
        this.fechaDesde = hoy;
        this.fechaHasta = hoy;
    }

    solicitudes: Solicitud[] = [];

    cantFormularioCargado = 0;
    cantPreInscripcion = 0;
    cantDocumentosCargadosParciales = 0;
    cantDocumentosCargadosCompleto = 0;
    cantPreaprobado = 0;
    cantPagoSolicitado = 0;
    cantControlarPago = 0;
    cantAprobado = 0;
    cantRechazado = 0;
    cantAnulada = 0;
    cantEnEspera = 0;
    cantTotalSolicitudes = 0;
    cantTotalUsuarios = 0;

    porcEspera = 0;
    porcAnulada = 0;
    porcRechazado = 0;
    porcAprobado = 0;
    porcControlarPago = 0;
    porcPagoSolicitado = 0;
    porcPreaprobado = 0;
    porcDocumentosCargadosCompleto = 0;
    porcDocumentosCargadosParciales = 0;
    porcFormularioCargado = 0;
    porPreInscripcion = 0;

    flagCargado = true;

    fechaDesde: Date;
    fechaHasta: Date;

    showCards = false; // Nueva variable para el toggle de visibilidad

    filtroPresenciales = false; // Inicialmente, no filtra por presenciales

    async ngOnInit(): Promise<void> {
        await this.getInfoDashBoard();
        this.perfilesService.allowedByUserLogon(this.router.url);
    }

    toggleCards() {
        this.showCards = !this.showCards;
    }

    async buscarPorFechas() {
        if (this.fechaDesde && this.fechaHasta) {
            this.flagCargado = false;
            console.log('envio pista en', this.filtroPresenciales);
            const data = {
                pista: this.filtroPresenciales,
                fechaDesde: this.formatDate(this.fechaDesde),
                fechaHasta: this.formatDate(this.fechaHasta)
             };

            await this.solicitudesService.getActividadesByFechaPistaOnline(data).then(async (result: any) => {
                console.log('result', result);
                this.solicitudes = result;
                this.flagCargado = true;
            });
        } else {

        }
    }

    filtroActividades(presenciales: boolean) {
        this.filtroPresenciales = presenciales;
        // Aquí puedes implementar lógica adicional si necesitas filtrar por presenciales u online
    }

    formatDate(date: Date): string {
        return date.toISOString().split('T')[0];
    }

    async setDataFormarefreshComponents() {
        this.estadisticasService.setFlagRefresh(true);
    }

    async getInfoDashBoard() {
        await this.estadisticasService.getInfoDashBoard().then( async (resp: InfoSolicitud[]) => {
            /* CANTIDADES */
            await this.cargarCantidades(resp);

            /* PORCENTAJE */
            await this.cargarPorcentajes(resp);

            /* TOTAL SOLICITUDES */
            await this.cargarTotalCantidad(resp);

            /* TOTAL USUARIOS */
            await this.cargarCantTotalUsuarios();

        });
    }

    async cargarPorcentajes( info ) {

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_0.number) !== undefined) {
        this.porPreInscripcion = info.find(x => x.numberState === this.iAppOfertas.estado_0.number).porcentaje;
        }

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_1.number) !== undefined) {
            this.porcFormularioCargado = info.find(x => x.numberState === this.iAppOfertas.estado_1.number).porcentaje;
         }

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_2.number) !== undefined) {
            this.porcDocumentosCargadosParciales  = info.find(x => x.numberState === this.iAppOfertas.estado_2.number).porcentaje;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_3.number) !== undefined) {
            this.porcDocumentosCargadosCompleto =  info.find(x => x.numberState === this.iAppOfertas.estado_3.number).porcentaje;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_4.number) !== undefined) {
            this.porcPreaprobado =  info.find(x => x.numberState === this.iAppOfertas.estado_4.number).porcentaje;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_5.number) !== undefined) {
            this.porcPagoSolicitado = info.find(x => x.numberState === this.iAppOfertas.estado_5.number).porcentaje;
         }

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_6.number) !== undefined) {
            this.porcControlarPago  = info.find(x => x.numberState === this.iAppOfertas.estado_6.number).porcentaje;
         }

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_7.number) !== undefined) {
            this.porcAprobado  = info.find(x => x.numberState === this.iAppOfertas.estado_7.number).porcentaje;
         }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_8.number) !== undefined) {
            this.porcRechazado = info.find(x => x.numberState === this.iAppOfertas.estado_8.number).porcentaje;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_9.number) !== undefined) {
            this.porcAnulada = info.find(x => x.numberState === this.iAppOfertas.estado_9.number).porcentaje;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_10.number) !== undefined) {
            this.porcEspera = info.find(x => x.numberState === this.iAppOfertas.estado_10.number).porcentaje;
        }
    }

    async cargarCantidades( info ) {

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_0.number) !== undefined) {
            this.cantPreInscripcion = info.find(x => x.numberState === this.iAppOfertas.estado_0.number).cantidad;
         }

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_1.number) !== undefined) {
            this.cantFormularioCargado = info.find(x => x.numberState === this.iAppOfertas.estado_1.number).cantidad;
         }

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_2.number) !== undefined) {
            this.cantDocumentosCargadosParciales  = info.find(x => x.numberState === this.iAppOfertas.estado_2.number).cantidad;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_3.number) !== undefined) {
            this.cantDocumentosCargadosCompleto =  info.find(x => x.numberState === this.iAppOfertas.estado_3.number).cantidad;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_4.number) !== undefined) {
            this.cantPreaprobado =  info.find(x => x.numberState === this.iAppOfertas.estado_4.number).cantidad;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_5.number) !== undefined) {
            this.cantPagoSolicitado = info.find(x => x.numberState === this.iAppOfertas.estado_5.number).cantidad;
         }

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_6.number) !== undefined) {
            this.cantControlarPago  = info.find(x => x.numberState === this.iAppOfertas.estado_6.number).cantidad;
         }

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_7.number) !== undefined) {
            this.cantAprobado  = info.find(x => x.numberState === this.iAppOfertas.estado_7.number).cantidad;
         }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_8.number) !== undefined) {
            this.cantRechazado = info.find(x => x.numberState === this.iAppOfertas.estado_8.number).cantidad;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_9.number) !== undefined) {
            this.cantAnulada = info.find(x => x.numberState === this.iAppOfertas.estado_9.number).cantidad;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_10.number) !== undefined) {
            this.cantEnEspera = info.find(x => x.numberState === this.iAppOfertas.estado_10.number).cantidad;
        }

    }

    async cargarTotalCantidad( info ) {
        this.cantTotalSolicitudes = 0;
        if (info.length > 0) {
            info.forEach( element => {
                this.cantTotalSolicitudes += element.cantidad;
            });
        }
    }

    async navigateToSolicitudes(estado: number) {
        await this.solicitudesService.setEstadoSolicitud(estado);
        this.router.navigateByUrl('/ofertas-administracion-listado');
    }

    async cargarCantTotalUsuarios() {
        this.cantTotalUsuarios =  await this.usuariosService.getCountUsers();
    }


}

