import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Materia } from '../../../../interfaces/interface-bakend/cursos/materia.interface';
import { MateriasService } from '../../../../services/cursos/materias.service';
import Swal from 'sweetalert2';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';

@Component({
  selector: 'app-materias-listado',
  templateUrl: './materias-listado.component.html',
  styleUrls: ['./materias-listado.component.scss']
})
export class MateriasListadoComponent implements OnInit, AfterViewInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
    [
      'nombre', 'description', 'fechaInicio', 'fechaFin', 'enlace', '_id'
    ];
  dataSource: any;
  materias: Materia[];
  /* Fin Data Table*/

  flagLoaded = false;


  constructor(
    private materiasService: MateriasService,
    private router: Router,
    private guiMsjService: GuiMsjService
  ) { }

  ngOnInit(): void {
    this.loadDataPage();
    this.getMateria();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
  }

  async verMateria(event: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    await this.materiasService.getMateriaFindOne(event);
    this.router.navigateByUrl('/materia-modificacion');
  }

  async getMateria() {
    const materia: Materia = this.materiasService.getMateriaSeleccionada();
  }

  async loadDataTable() {
    this.materiasService.getMaterias().then((materias: Materia[]) => {
      this.dataSource = new MatTableDataSource(materias);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  async loadDataPage() {
    await this.loadDataTable();
    return;
  }

}
