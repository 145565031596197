<div class="form-group">
    <div class="col-sm-12">
        <mat-list *ngFor="let userDocument of userDocuments; let $index = index"
                  role="list">
            <mat-list-item>
                <section>
                    <div class="material-button-row">
                        <button (click)="download(usuario._id, userDocument.adjunto, userDocument.nombre)"
                                mat-raised-button
                                color="warn">Descargar Documento: <strong>{{ userDocument.nombre
                                }}</strong></button>
                        <button (click)="openDialogPdf(usuario._id, userDocument.adjunto, userDocument.nombre)"
                                mat-raised-button
                                color='primary'>Ver</button>
                    </div>
                </section>
            </mat-list-item>
            <mat-divider></mat-divider>
        </mat-list>
    </div>
</div>