<form [formGroup]="form">
    <mat-form-field class="example-user-input" appearance="fill" [style.width.%]=80>
        <mat-label>Pregunta N° {{index+1}}</mat-label>
        <input formControlName="enunciado" matInput autocomplete="off">
    </mat-form-field>
    <button style="margin-right: 5px;" mat-flat-button color="accent" mat-raised-button [disabled]="!form.valid" (click)="updatePregunta(index)"> Modificar</button>
    <button (click)="borrarPregunta(index, preguntaID)" mat-mini-fab color="warn" aria-label="Example icon button with a filter list icon">
        <mat-icon>delete</mat-icon>
    </button>
    <mat-accordion *ngIf="this.preguntaConMultimedia.contenido.contiene">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Ver Multimedia de Pregunta
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <!-- ****** DEFINO TIPO DE ARCHIVO A MOSTRAR ****** -->
            <div *ngIf="this.getTipoArchivo(this.preguntaConMultimedia.contenido.type) == 'pdf'" class="animate__animated animate__fadeIn">
                <iframe [src]="( 3 | mediaCursosPipe: this.preguntaConMultimedia.contenido.source | safeResourceUrl)" type="application/pdf" style="width: 50%; height: 50vh;"></iframe>
            </div>

            <div *ngIf="this.getTipoArchivo(this.preguntaConMultimedia.contenido.type) == 'mp4'" class="animate__animated animate__fadeIn">
                <iframe [src]="( 2 | mediaCursosPipe: this.preguntaConMultimedia.contenido.source | safeResourceUrl)" type="video/mp4" style="width: 50%; height: 50vh;"></iframe>
            </div>

            <div *ngIf="this.getTipoArchivo(this.preguntaConMultimedia.contenido.type) == 'img'" class="animate__animated animate__fadeIn">
                <img [src]="( 1 | mediaCursosPipe: this.preguntaConMultimedia.contenido.source | safe)" [alt]=" this.preguntaConMultimedia.contenido.nombreArchivo" style="width: 50%; width: 50%;">
            </div>
            <!-- ****** FIN DEFINO TIPO DE ARCHIVO A MOSTRAR ****** -->


        </mat-expansion-panel>
    </mat-accordion>
</form>