import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Translators } from '../../../services/shared/translators.service';

@Component({
  selector: 'app-nota-legal-correo-electronico',
  templateUrl: './nota-legal-correo-electronico.component.html',
  styleUrls: ['./nota-legal-correo-electronico.component.scss']
})
export class NotaLegalCorreoElectronicoComponent implements OnInit {

  lenguaje = 'es';
  idioma: Subscription;

  constructor(private translatorService: Translators) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      switch (resp.value){
        case 'es':
          this.lenguaje = 'es';
          break;
        case 'en':
          this.lenguaje = 'en';
          break;
        default:
          this.lenguaje = 'es';
          break;
        }
    });
  }

  ngOnInit(): void {
  }

  useLanguage(idioma: string){
    this.translatorService.setIdiomaSeleccionado(idioma);
  }


}
