import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_CONFIG_OFERTAS, IAppOfertas } from 'src/app/app.config/app.config-ofertas';
import { MercadopagoResults } from 'src/app/interfaces/interface-frontend/shared/mercadoPagoResults.interface';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config/app.config';
import { environment } from 'src/environments/environment';
const URL_SITIO = environment.urlSitio;

@Component({
  selector: 'app-mercado-pago-resultado',
  templateUrl: './mercado-pago-resultado.component.html',
  styleUrls: ['./mercado-pago-resultado.component.scss']
})
export class MercadoPagoResultadoComponent implements OnInit {
  
  pagoResults: MercadopagoResults;
  flagProcesado  = false;
  usuario: User;
  tipoMembresia: string;
  descripCionMembresia: string;
  valorMembresia: number;
  flagEmpleadoSaete = false;
  
  constructor(
    @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
    @Inject(APP_CONFIG) public iAppConfig: IAppConfig,
    private route: ActivatedRoute,
    private router: Router,
  ) { 
  }

  async ngOnInit(): Promise<void> {   
    await this.pagoResult();
  }

  async pagoResult() {
    try {
      await this.mercadoPagoResult();
      setTimeout(() => {
        this.continuar();
      }, 10000); // 3000 milisegundos = 3 segundos
   
    } catch (error) {
      console.error('Error en mercadoPagoResult:', error);
      this.continuar();
    }
  }

  async mercadoPagoResult() {
      this.route.queryParamMap.subscribe(async (params) => {
        this.pagoResults = {
          collection_id: params.get('collection_id'),
          collection_status: params.get('collection_status'),
          merchant_order_id: params.get('merchant_order_id'),
          preference_id: params.get('preference_id'),
          payment_id: params.get('payment_id'),
          payment_type: params.get('payment_type'),
          status: params.get('status'),
          external_reference: params.get('external_reference'),
        };      
        console.log('mercadoPagoResult', this.pagoResult);
      });
  }

   
  async continuar() {
    this.router.navigateByUrl(URL_SITIO);
  }
  
}



