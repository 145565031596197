import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '../../interfaces/interface-bakend/response';
import { environment } from '../../../environments/environment';
import { StorageService } from '../shared/storage.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Materia } from '../../interfaces/interface-bakend/cursos/materia.interface';
import { AuthService } from '../auth/auth.service';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class MateriasService {

  materiaSeleccion: Materia;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private authService: AuthService,
  ) { }

  getMaterias(): Promise<Materia[]>{
  return new Promise( async resolve => {

      const headers =  new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });

  });
  }

  getMateriaFindOne(id: string): Promise<Materia> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
        });

      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/findOne/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
        this.setMateriaSeleccionada(resp.response);
        Swal.close();
      });
    });
  }

  setMateriaSeleccionada(materia: Materia) {
    this.materiaSeleccion = materia;
    this.storageService.setMateria(materia);
  }

  getMateriaSeleccionada(): Materia {
    return this.materiaSeleccion;
  }

  put(id: string, data: Materia){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
        });

      this.httpClient.put(`${ URL_BACKEND }/materias/updateOne/${id}`, data, { headers }).subscribe( resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Materia modificada satisfactoriamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/materias-listado']);
        resolve(true)
        ,

        // tslint:disable-next-line:no-unused-expression
        (error) => {
          Swal.close();
          Swal.fire('Error al modificar', '', 'error');
          console.log(error);
          resolve(false);
        };
      });
    });

  }

}
