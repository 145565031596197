import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { ContactoDto, Mailer, MessageAttachDto } from '../../interfaces/interface-bakend/shared/mailer/mailer.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GuiMsjService } from './gui-msj.service';
import { Response } from '../../interfaces/interface-bakend/response';
import { User } from '../../interfaces/interface-bakend/seguridad/users/user.interface';
import { Solicitud } from '../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { ActividadesService } from '../ofertas-laborales/actividades.service';
import { CatalogoService } from './catalogo.service';
import { AuthService } from '../auth/auth.service';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class MailerService {

  constructor(
    private httpClient: HttpClient,
    private guiMsjService: GuiMsjService,
    private actividadesService: ActividadesService,
    private catalogoService: CatalogoService,
    private authService: AuthService,
  ) { }

    async sendMail(messageDto: Mailer): Promise<Mailer> {
        return new Promise( async resolve => {

            const headers =  new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.post<Response>(`${URL_BACKEND}/mailer/sendOne/`, messageDto, { headers })
            .subscribe( (resp: Response)  => {
                resolve(resp.response);
                this.guiMsjService.msjFormSubmit('documentosEnviadosOK');
            }, error => {
                this.guiMsjService.msjFormSubmit('documentosEnviadosERROR');
                console.log('error', error);
            });
        });

    }

    async sendFormContact(contacto: ContactoDto) {
        return new Promise( async resolve => {

            const headers =  new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.post<Response>(`${URL_BACKEND}/contacto/`, contacto, { headers})
            .subscribe( (resp: Response)  => {
                resolve(resp.response);
                this.guiMsjService.msjFormSubmit('ContactoEnviado');
            }, error => {
                this.guiMsjService.msjFormSubmit('ErrorContacto');
                console.log('error', error);
            });
        });

    }
    async senEmailActividades(idsSolicitudes: string[]) {
        return new Promise( async resolve => {

            const headers =  new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.post<Response>(`${URL_BACKEND}/solicitudes/notificarActividad/`, idsSolicitudes, { headers})
            .subscribe( (resp: Response)  => {
                resolve(resp.response);
            }, error => {
                console.log('error', error);
            });
        });

    }

    async prepareMailToSend(
        usuario: User,
        solicitud: Solicitud,
        enviarAdjunto: boolean,
        adjunto: string,
        template: string): Promise<Mailer>{

        let adjuntar: string;
        let mailerData: any;
        let filename: string;
        let contentType: string;
        let path: string;

        if (enviarAdjunto) {
            // tslint:disable-next-line:prefer-for-of
            for (let index = 0; index < solicitud.adjuntos.length; index++) {
                const posInicial = 0;
                const longitud =  solicitud.adjuntos[index].indexOf('.');
                const idAdjunto =  solicitud.adjuntos[index].substring(posInicial, longitud);
                if (idAdjunto === adjunto) {
                    adjuntar =  solicitud.adjuntos[index];
                    index = solicitud.adjuntos.length;
                }
            }

            path = await this.actividadesService.getPathAdjunto(usuario._id, adjuntar);

            mailerData = await this.catalogoService.getFindByKey(template);
            filename = mailerData.properties.attachments.filename;
            contentType = mailerData.properties.attachments.contentType;

        } else {

            mailerData = await this.catalogoService.getFindByKey(template);
            filename = null;
            path = null;
            contentType = null;

        }


        const messageDto: Mailer = {
                to: usuario.email,
                subject: mailerData.properties.subject,
                html: mailerData.properties.html,
                attachments: [
                    {
                      filename,
                      path,
                      contentType
                    }
                  ]
                };

        return messageDto;
    }

}
