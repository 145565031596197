<div class="form-group">
    <div class="col-sm-12">
        <mat-list *ngFor="let item of filesWithName; let $index = index" role="list">
            <mat-list-item>
                <section>
                    <div class="material-button-row">
                        <button (click)="download(usuario._id, item.adjunto, item.nombre)" mat-raised-button color="warn">Descargar Documento: {{ item.nombre }}</button>
                        <button (click)="openDialogPdf(usuario._id, item.adjunto, item.nombre)" mat-raised-button color='primary'>Ver</button>
                        <!-- <button (click)="download(usuario._id, item.adjunto, item.nombre)" class="btn default-btn-style-two"><i class="fas fa-file-download"></i> Descargar Doc.: {{ item.nombre }}</button>
                <button (click)="openDialogPdf(usuario._id, item.adjunto, item.nombre)" class="btn default-btn-style-two"><i class="fas fa-eye"></i>Ver</button> -->
                    </div>
                </section>
            </mat-list-item>
            <mat-divider></mat-divider>
        </mat-list>
    </div>
</div>