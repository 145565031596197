import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActividadDAdjunto } from '../../../../../interfaces/interface-bakend/ofertas-laborales/actividad-adjuntos.interface';
import { ActividadesService } from '../../../../../services/ofertas-laborales/actividades.service';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';

@Component({
  selector: 'app-adjuntos-listado',
  templateUrl: './adjuntos-listado.component.html',
  styleUrls: ['./adjuntos-listado.component.scss']
})
export class AdjuntosListadoComponent implements OnInit {

    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] =
    [
      'nombre', 'descripcion', '_id'
    ];
    dataSource: any;
    adjuntos: ActividadDAdjunto[] = [];
    /* Fin Data Table*/

    flagLoaded = false;


    constructor(
        private actividadesService: ActividadesService,
        private router: Router,
        private perfilesService: PerfilesService,
    ) {}

    ngOnInit(): void {
      // this.perfilesService.allowedByUserLogon(this.router.url);
      this.loadDataPage();
    }

    async verAdjunto(event) {
      const adjunto: ActividadDAdjunto = await this.actividadesService.getAdjuntosFindOne(event);
      this.router.navigateByUrl('/adjunto-modificacion');
      await this.actividadesService.setAdjuntoSeleccionado(adjunto);
    }

    async loadDataTable(){
      await this.actividadesService.getAdjuntos().then( (adjuntos: ActividadDAdjunto[]) => {
        this.dataSource = new MatTableDataSource(adjuntos);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    async loadDataPage() {
      await this.loadDataTable();
      this.flagLoaded = true;
      return;
    }

}
