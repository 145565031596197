import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-procesando',
  templateUrl: './formulario-inscripcion-procensando.component.html',
  styleUrls: ['./formulario-inscripcion-procensando.component.css']
})
export class FormularioInscripcionProcensandoComponent {
    @Input() titulo: string;
    @Input() mensaje: string;
}
