import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '../../interfaces/interface-bakend/response';
import { environment } from '../../../environments/environment';
import { StorageService } from '../shared/storage.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';
import { Boletin } from 'src/app/interfaces/interface-bakend/cursos/boletin.interface';
import { AlumnoBoletin } from 'src/app/interfaces/interface-bakend/cursos/alumno-boletin.interface';
import { Alumno } from 'src/app/interfaces/interface-bakend/cursos/alumno.interface';
import { MateriaBoletinDetalle } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin-detalle.interface';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class BoletinesService {

  materiaSeleccion: MateriaBoletin;
  boletinSeleccion: Boletin;
  alumnoBoletinSeleccion: AlumnoBoletin;
  materiasBoletinSeleccion: MateriaBoletinDetalle[];

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private authService: AuthService,
  ) { }

  async addMateriaToAlumnoBoletin(boletinId: string, alumnoId: string, materia: MateriaBoletinDetalle): Promise<AlumnoBoletin> {
    const headers = new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
    });
  
    return new Promise((resolve, reject) => {
      this.httpClient.put<Response>(`${URL_BACKEND}/alumnos/boletines/addMateria/${boletinId}/${alumnoId}`, materia, { headers }).subscribe(
        resp => {
          resolve(resp.response);
        },
        (error) => {
          if (error.status === 409) {
            reject({ message: 'La materia ya existe en el boletín del alumno', status: 409 });
          } else if (error.status === 404) {
            reject({ message: 'AlumnoBoletin no encontrado', status: 404 });
          } else {
            reject({ message: 'Ocurrió un error inesperado', status: error.status || 500 });
          }
        }
      );
    });
  }  

  getBoletines(): Promise<Boletin[]> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/boletines/findAll`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });

    });
  }

  getAlumnoBoletinFindOne(id: string): Promise<AlumnoBoletin> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/alumnos/boletines/findOne/${id}`, { headers }).subscribe(resp => {
        resolve(resp.response);
        this.setAlumnoBoletinSeleccionado(resp.response);
        Swal.close();
      });
    });
  }

  getBoletinFindOne(id: string): Promise<Boletin> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/boletines/findOne/${id}`, { headers }).subscribe(resp => {
        resolve(resp.response);
        this.setBoletinSeleccionado(resp.response);
        Swal.close();
      });
    });
  }

  setBoletinSeleccionado(boletin: Boletin) {
    this.boletinSeleccion = boletin;
    this.storageService.setBoletin(boletin);
  }

  getBoletinSeleccionado(): Boletin {
    return this.boletinSeleccion;
  }

  setAlumnoBoletinSeleccionado(boletin: AlumnoBoletin) {
    this.alumnoBoletinSeleccion = boletin;
    this.storageService.setAlumnoBoletin(boletin);
  }

  setMateriasBoletin(materias: MateriaBoletinDetalle[]) {
    this.materiasBoletinSeleccion = materias;
    this.storageService.setMateriasBoletin(materias);
  }

  getAlumnoBoletinSeleccionado(): AlumnoBoletin {
    return this.alumnoBoletinSeleccion;
  }

  put(id: string, data: AlumnoBoletin) {

    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put(`${URL_BACKEND}/alumnos/boletines/updateOne/${id}`, data, { headers }).subscribe(resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Boletin modificado correctamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/alumnos-boletines-listado']);
        resolve(true)
          ,

          // tslint:disable-next-line:no-unused-expression
          (error) => {
            Swal.close();
            Swal.fire('Error al modificar', '', 'error');
            console.log(error);
            resolve(false);
          };
      });
    });
  }

  putBoletin(id: string, data: Boletin) {

    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put(`${URL_BACKEND}/boletines/updateOne/${id}`, data, { headers }).subscribe(resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Boletin modificado correctamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/boletines-listado']);
        resolve(true)
          ,

          // tslint:disable-next-line:no-unused-expression
          (error) => {
            Swal.close();
            Swal.fire('Error al modificar', '', 'error');
            console.log(error);
            resolve(false);
          };
      });
    });
  }

  post(data: AlumnoBoletin) {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post(`${URL_BACKEND}/alumnos/boletines`, data, { headers }).subscribe(
        resp => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: 'Boletin guardado correctamente',
            showConfirmButton: false,
            timer: 1100
          });

          this.router.navigate(['/alumnos-boletines-listado']);
          resolve(true);
        },
        (error) => {
          Swal.close();
          // Verificar si el error es una ConflictException (409)
          if (error.status === 409) {
            Swal.fire('Error', 'El boletín ya existe para este alumno.', 'error');
          } else {
            Swal.fire('Error al guardar', '', 'error');
          }
  
          resolve(false);
        }
      );
    });
  }

  postBoletin(data: Boletin) {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
  
      this.httpClient.post(`${URL_BACKEND}/boletines`, data, { headers }).subscribe(
        resp => {
          Swal.close();
  
          Swal.fire({
            icon: 'success',
            title: 'Boletín guardado correctamente',
            showConfirmButton: false,
            timer: 1100
          });
  
          this.router.navigate(['/boletines-listado']);
          resolve(true);
        },
        (error) => {
          Swal.close();
          console.log("error.status:: ",error.status)
          // Verificar si el error es una ConflictException (409)
          if (error.status === 409) {
            Swal.fire('Error', 'El boletín ya existe para este alumno.', 'error');
          } else {
            Swal.fire('Error al guardar', '', 'error');
          }
  
          //resolve(false);
        }
      );
    });
  }  

  async getBoletinesAlumnosFindAllByAlumno(id: string): Promise<AlumnoBoletin[]> {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${URL_BACKEND}/alumnos/boletines/findAllByAlumno/${id}`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });
    });
  }

  async getAlumnoBoletines(): Promise<AlumnoBoletin[]> {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${URL_BACKEND}/alumnos/boletines/findAll`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });
    });
  }

  deleteBoletin(id: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.delete(`${URL_BACKEND}/alumnos/boletines/${id}`, { headers }).subscribe(
        () => {
          Swal.fire(
            'Eliminado!',
            'El boletín ha sido eliminado.',
            'success'
          );
          this.router.navigate(['/alumnos-boletines-listado']);
          resolve(true);
        },
        (error) => {
          Swal.fire(
            'Error',
            'Hubo un problema al eliminar el boletín.',
            'error'
          );
          console.log('Error al eliminar:', error);
          resolve(false);
        }
      );
    });
  }
}