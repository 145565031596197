
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { Translators } from './../../../../services/shared/translators.service';

@Component({
  selector: 'app-modal-modificar-clave',
  templateUrl: './modal-modificar-clave.component.html',
  styleUrls: ['./modal-modificar-clave.component.scss']
})
export class ModalModificarClaveUsersComponent implements OnInit {

    form: FormGroup;
    idioma: Subscription;
    showPassword: boolean;
    showPasswordConfirm: boolean;
    btnActualizar: boolean = false;

    constructor(
        private usuarioService: UsuarioService,
        private translatorService: Translators,
        /* Modal Ref. */
        public dialogRef: MatDialogRef<ModalModificarClaveUsersComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string // recibo id de usuario para cambiar password
        /* Fin Modal Ref. */
    ) {
        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
          });
          
        this.createForm();
     }

    ngOnInit(): void {
    }

    /* Creacion de formulario */
    createForm() {
        this.form = new FormGroup({
        password: new FormControl('', [ Validators.required, Validators.minLength(8), Validators.pattern(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)]),
        confirmPassword: new FormControl('', [ Validators.required, Validators.minLength(8) ])
    });
    }

    /* Cierro modal */
    closeModal(): void {
        this.dialogRef.close(this.data);
    }

    /* Modifico password*/
    async actualizarClave(){
        this.btnActualizar = true;
        if (this.form.invalid){

            return Object.values( this.form.controls ).forEach( control => {
                control.markAsTouched();
            });
        }
        
        await this.usuarioService.updatePasswordUser(
            this.data,
            this.form.value.confirmPassword
        ).then( () => {
            this.btnActualizar = false;
            this.closeModal();
        });

    }

    /* Validaciones */
    get passwordNoValido(){
        return this.form.get('password').invalid && this.form.get('password').touched;
    }

    get confirmPasswordNoValido(){
        const pass1 = this.form.get('password').value;
        const pass2 = this.form.get('confirmPassword').value;
        return ( pass1 === pass2 ) ? false : true;
    }

}
