


<button class="close" mat-button [mat-dialog-close]="true">X</button>
<div class="p-3 mb-2 bg-primary text-white rounded-top">Ventana de cobro</div>
<div class="p-1 mb-2 bg-secondary text-white rounded-top">Datos cliente</div>
<div class="row">
    <div class="col-lg-6 col-md-44">
        <div class="products-details-desc">
            <div class="products-meta">
                <span><a>Nombre: </a> {{ this.data.usuario.nombrePersona }}</span>
                <span><a>Apellido : </a> {{ this.data.usuario.apellidoPersona }}</span>
                <span><a>País de nacimiento:  </a> {{ this.data?.usuario?.paisNacimiento?.name_es }}</span>
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-md-44">
        <div class="products-details-desc">
            <div class="products-meta">
                <span><a>Tipo de identificación: </a> {{ this.data.usuario.tipoIdentificacion.concepto }}</span>
                <span><a>Número de Identificación: </a> {{ this.data.usuario.identificacion }}</span>
                <div *ngIf="flagTrabajoAniosAnteriores" class="p-1 mb-2 bg-info text-white">
                    <span><a>ATENCIÓN: </a> ya participo como apto en PRE INSCRIPCIÓN - con solicitud aprobada</span>
                </div>
                <div *ngIf="!flagTrabajoAniosAnteriores">
                    <span><a>ATENCIÓN: </a> NO participo nunca en PRE INSCRIPCIÓN - hasta el momento</span>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<div class="row">
    <div class="col-lg-6 col-md-44">
        <div class="p-1 mb-2 bg-secondary text-white rounded-top">Detalle de solicitud</div>
        <div class="products-details-desc">
            <div class="products-meta">
                <div class="courses-details-info">
                    <ul class="info">
                        <div *ngFor="let item of this.modulos">
                            <li class="price">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><i></i>{{item.nombre}}  </span> ${{item.precio}}
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-md-44">
        <div class="p-1 mb-2 bg-secondary text-white rounded-top">Detalle de Solicitud</div>
        <div class="products-details-desc">
            <div class="products-meta">
                <span><a>Numero de solicitud: </a> {{ this.data._nro }}</span>
                <span><a>Fecha solicitud: </a> {{ this.data.createdAt | date: "dd/mm/yyyy" }}</span>
                <span><a>Pre Inscripción: </a> {{ this.data.actividad.nombre }}</span>
                <span><a>Pais: </a> {{ this.data.pais.name_es }}</span>
            </div>
        </div>
    </div>
</div>

<div class="row">

    <!-- <br>
    <div class="col-md-6">
        <div class="form-group">
            <div class="p-1 mb-2 bg-warning text-white">Pago Pendiente</div>
            <span><a>Costo Módulo/s : </a> $ {{ this.totalModulosCosto }}</span>
            <br>
        </div>
    </div> -->

    <br/>
    <mat-divider></mat-divider>
    <br/>
    <div class="col-md-12">
        <div class="form-group">
            <div class="p-1 mb-2 bg-secondary text-white rounded-top">Cobrar</div>
        </div>
    </div>
    <br/>
    <mat-divider></mat-divider><br>

    <div class="form-group">

        <form class="row g-3" [formGroup]="forma" (ngSubmit)="onSubmit()">

            <div class="col-md-6">
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Total</mat-label>
                    <input required readonly formControlName="totalPagar" matInput type="number" class="example-right-align" placeholder="0">
                    <span matPrefix>$&nbsp;</span>
                    <span matSuffix>.00</span>
                    <small *ngIf="totalPagar" class="text-danger">el valor deber ser mayor o igual a 0</small>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="fill" [style.width.%]=100>
                    <mat-label>Seleccione un tipo de moneda...</mat-label>
                    <mat-select formControlName="moneda" [(ngModel)]="selectedCurrency" (ngModelChange)="calculoTotalSolicitarCobro()">
                        <mat-option *ngFor="let item of tipoMonedas" [value]="item._id">{{ item.concepto }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            
            <div class="col-md-6">
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Descuento</mat-label>
                    <input (ngModelChange)="this.calculoTotalSolicitarCobro()" formControlName="descuento" matInput type="number" class="example-right-align" placeholder="0">
                    <span matPrefix>$&nbsp;</span>
                    <span matSuffix>.00</span>
                    <small *ngIf="descuento" class="text-danger">el valor deber ser igual o mayor a 0</small>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Recargo</mat-label>
                    <input (ngModelChange)="this.calculoTotalSolicitarCobro()" formControlName="recargo" matInput type="number" class="example-right-align" placeholder="0">
                    <span matPrefix>$&nbsp;</span>
                    <span matSuffix>.00</span>
                    <small *ngIf="recargo" class="text-danger">el valor deber ser igual o mayor a 0</small>
                </mat-form-field>
            </div>      

            <div class="col-md-6">
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Total envío cobro</mat-label>
                    <input required readonly formControlName="totalEnvioCobro" matInput type="number" class="example-right-align" placeholder="0">
                    <span matPrefix>$&nbsp;</span>
                    <span matSuffix>.00</span> 
                    <small *ngIf="totalEnvioCobro" class="text-danger">El valor debe ser mayor o igual a 0</small>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <div class="form-group">
                    <mat-label>Fecha reportada de envio de cobro</mat-label>
                    <input disabled formControlName="fechaCobro" type="date" class="form-control" placeholder="DD/MM/YYYY" [class.is-invalid]="fechaCobro">
                    <small *ngIf="fechaCobro" class="text-danger">*</small>
                </div>
            </div>

            <div class="col-md-8">
                <mat-form-field appearance="fill" class="w-100"> <!-- Utilizamos la clase w-100 para ocupar todo el ancho -->
                    <mat-label>Observación</mat-label>
                    <textarea formControlName="observacion" matInput></textarea>
                </mat-form-field>
            </div>
            
            
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button [disabled]="!forma.valid" class="btn btn-primary me-md-2" type="submit">Solicitar Cobro</button>
                <button (click)="cancelar(false)" class="btn btn-secondary" type="button">Cancelar</button>
            </div>
        </form>
    </div> 
