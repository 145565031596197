import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Response } from '../../interfaces/interface-bakend/response';
import { environment } from '../../../environments/environment';
import { StorageService } from '../shared/storage.service';
import { Leccion } from '../../interfaces/interface-bakend/cursos/leccion.interface';
import { Examen } from '../../interfaces/interface-bakend/cursos/examen.interface';
import { AuthService } from '../auth/auth.service';


const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class LeccionesService {

  leccionSeleccion: Leccion;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private authService: AuthService,
  ) { }

  getLecciones(): Promise<Leccion[]>{
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/lecciones/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  getLeccionFindOne(id: string): Promise<Leccion> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/lecciones/findOne/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
        this.setLeccionSeleccionada(resp.response);
        Swal.close();
      });
    });
  }

  setLeccionSeleccionada(leccion: Leccion) {
    this.leccionSeleccion = leccion;
    this.storageService.setLeccion(leccion);
  }

  getLeccionSeleccionada(): Leccion {
    return this.leccionSeleccion;
  }

  post(data: Leccion){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post<Response>(`${ URL_BACKEND }/materias/lecciones`, data, { headers }).subscribe( resp => {

        resolve(resp.response);

        // tslint:disable-next-line:no-unused-expression
        (error) => {
          Swal.close();
          Swal.fire('Error al guardar', '', 'error');
          console.log(error);
          resolve(false);
        };
      });
    });

  }

  put(id:string,data: Leccion){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put<Response>(`${ URL_BACKEND }/materias/lecciones/updateOne/${id}`, data, { headers }).subscribe( resp =>{

        resolve(resp.response);

        (error) =>{
          Swal.close();
          Swal.fire('Error al modificar','', 'error');
          console.log(error);
          resolve(false)
        }
      });
    });

  }

  postExamen(data: Examen){
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post<Response>(`${ URL_BACKEND }/materias/lecciones`, data, { headers }).subscribe( resp => {

        resolve(resp.response);

        // tslint:disable-next-line:no-unused-expression
        (error) => {
          Swal.close();
          Swal.fire('Error al guardar', '', 'error');
          console.log(error);
          resolve(false);
        };
      });
    });
  }

}
