import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-adjuntos-configuracion',
  templateUrl: './adjuntos-configuracion.component.html',
  styleUrls: ['./adjuntos-configuracion.component.scss']
})
export class AdjuntosConfiguracionComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
