import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';
import { Translators } from '../services/shared/translators.service';
import { HttpClient } from '@angular/common/http';

const URL_BACKEND = environment.urlBackend;

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private translatorService: Translators
  ){}

  async transform(idioma: string, param: string): Promise<string>{
     return await this.translatorService.getTranslationGeneral(idioma, param);
  }

}
