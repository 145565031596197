<app-loading *ngIf="!this.flagLoaded" class="m-5"></app-loading>
<div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
<div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
<div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>

<!-- <div *ngIf="this.flagLoaded" class="andorra-apply-content ptb-70 animate__animated animate__fadeIn"> -->
<div *ngIf="this.flagLoaded" class="premium-access-area bg-f9f9f9 ptb-70 animate__animated animate__fadeIn">
    <div class="container">
        <div class="premium-access-content-style-two">
            <div class="row mx-auto">

                <div class="col-lg-10 col-md-12">
                    <div class="apply-form">
                        <mat-card class="material-card">

                            <mat-card-header *ngIf="this.isEditable">
                                <mat-card-title>Título Examen: {{ this.examen.titulo }}</mat-card-title>
                            </mat-card-header>

                            <mat-divider *ngIf="this.isEditable"></mat-divider>
                            <br>
                            <br>

                            <app-loading *ngIf="this.procesando" class="m-5"></app-loading>
                            <mat-label *ngIf="this.procesando">procesando...</mat-label>

                            <mat-card-content *ngIf="this.isEditable">
                                <form [formGroup]="forma">
                                    <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="scroll-viewport">

                                        <table>
                                            <thead>
                                                <th>N°</th>
                                                <th>Tipo</th>
                                                <th>Pregunta</th>
                                                <th>Obligatoria</th>
                                                <th>Multimedia</th>
                                                <th>Acción</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let control of preguntas.controls; let i=index">
                                                    <td width="5%">{{ i+1 }}
                                                    </td>
                                                    <td width="5%">
                                                        <input disabled [formControl]="tipos.controls[i]" matInput type="text">
                                                    </td>
                                                    <td width="20%">
                                                        <mat-form-field appearance="fill" style="width: 300px;">
                                                            <mat-label>Enunciado</mat-label>
                                                            <textarea disabled [value]=preguntas.controls[i].value.enunciado matInput></textarea>
                                                        </mat-form-field>
                                                    </td>
                                                    <td width="10%">
                                                        <mat-label *ngIf="preguntas.controls[i].value.obligatoria">SI</mat-label>
                                                        <mat-label *ngIf="!preguntas.controls[i].value.obligatoria">NO</mat-label>
                                                    </td>
                                                    <td width="10%">
                                                        <mat-label *ngIf="preguntas.controls[i].value.contenido.contiene">SI</mat-label>
                                                        <mat-label *ngIf="!preguntas.controls[i].value.contenido.contiene">NO</mat-label>
                                                    </td>
                                                    <td width="10%">
                                                        <button *ngIf="!preguntas.controls[i].value.respuestas.length > 0" (click)="openDialogRespuesta(i, preguntas.controls[i].value, false)" mat-raised-button color="accent">Agregar respuestas</button>
                                                        <button disabled *ngIf="preguntas.controls[i].value.respuestas.length > 0" mat-raised-button color="accent">Agregar respuestas</button>
                                                    </td>
                                                    <td width="10%">
                                                        <button *ngIf="preguntas.controls[i].value.respuestas.length > 0" (click)="openDialogRespuesta(i, preguntas.controls[i].value, true)" mat-raised-button color="accent">Modificar Respuesta</button>
                                                        <button disabled *ngIf="!preguntas.controls[i].value.respuestas.length > 0" mat-raised-button color="accent">Modificar respuesta</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </cdk-virtual-scroll-viewport>


                                </form>

                                <mat-card-subtitle class="red-text">
                                    "IMPORTANTE": El boton"Guardar Respuestas" se activara cuando al menos, cargue una respuesta. Debe presionarlo para activar las pestaña de asignación.
                                </mat-card-subtitle>
                            </mat-card-content>

                            <mat-card-actions>

                                <button *ngIf="flagFormaValido" (click)="guardarRespuestas()" class="default-btn-style-two animate__animated animate__fadeIn margin-left" type="submit"><i class="fas fa-file"></i>Guardar Respuestas</button>
                                <button *ngIf="flagFormaValido" routerLink="/cursos-configuracion" class="default-btn-style-two animate__animated animate__fadeIn margin-left" type="button"><i class="fas fa-file"></i>Cancelar</button>

                            </mat-card-actions>


                        </mat-card>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>