import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Examen } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';
import Swal from 'sweetalert2';
import { ExamenEvaluacion, ExamenEvaluacionLista } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';
import * as XLSX from 'xlsx';
import { ExamenEvaluacionDetalleComponent } from '../examen-resultado-detalle/examen-evaluacion-detalle.component';
import { MatDialog } from '@angular/material/dialog';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { BoletinesService } from 'src/app/services/cursos/boletines.service';
import { ExamenesBoletinesModalComponent } from '../examenes-boletines-modal/examenes-boletines-modal.component';
import { MateriasBoletinService } from 'src/app/services/cursos/materias-boletin.service';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';
@Component({
  selector: 'app-examenes-evaluaciones-resultados',
  templateUrl: './examenes-evaluaciones-resultados.component.html',
  styleUrls: ['./examenes-evaluaciones-resultados.component.scss']
})
export class ExamenesEvaluacionesResultadosComponent implements OnInit {
  /* EXPORT TO EXCEL */
  @ViewChild('todos') table: ElementRef;
  @ViewChild('tableaprobados') tableaprobados: ElementRef;
  @ViewChild('tabledesaprobados') tabledesaprobados: ElementRef;
  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
    [
      'nombre', 'alumno', 'disciplina', 'estado', 'recuperatorio', 'fecha', 'puntaje', 'fechaMateriaEnviada', '_id',
    ];
  dataSource: any;

  displayedColumnsAprobados: string[] =
    [
      'nombre', 'alumno', 'disciplina', 'estado', 'recuperatorio', 'fecha', 'puntaje', '_id'
    ];
  dataSourceAprobados: any;

  displayedColumnsDesaprobados: string[] =
    [
      'nombre', 'alumno', 'disciplina', 'estado', 'recuperatorio', 'fecha', 'puntaje', '_id'
    ];
  dataSourceDesaprobados: any;


  @ViewChild('sortAprobados') sortAprobados = new MatSort();
  @ViewChild('sortAprobados') sortDesaprobados = new MatSort();

  @ViewChild('paginatorFirst') paginatorFirst: MatPaginator;
  @ViewChild('paginatorSecond') paginatorSecond: MatPaginator;


  examenes: ExamenEvaluacion[];
  estadoExamen: boolean;
  alumno: string;
  /* Fin Data Table*/

  flagLoaded = true;
  flagLoadedAprobados = false;
  flagLoadedDesaprobados = false;
  activo = false;
  flagSinEvaluaciones: boolean = false;
  date = new Date();
  fechaDesde: Date;
  fechaHasta: Date;
  aprobadoFiltro = ''; // Inicializado como ''
  dataSourceLength: number;
  materiaBoletin: MateriaBoletin;
  loading: boolean = false;

  constructor(
    private examenesService: ExamenesService,
    private dialog: MatDialog, // Agregar MatDialog aquí,
    private cursosService: CursosService,
    private boletinesService: BoletinesService,
    private materiasBoletinService: MateriasBoletinService,
    private router: Router,

  ) {
    const hoy = new Date();
    this.fechaDesde = hoy;
    this.fechaHasta = hoy;
  }

  ngOnInit(): void {
    this.estadoExamen = false;

    this.examenesService.refreshNeeded$.subscribe(() => {
      this.buscarPorFechas();
    });
  }

  async buscarPorFechas() {
    if (this.fechaDesde && this.fechaHasta) {
      this.flagLoaded = false;

      const data = {
        aprobado: this.aprobadoFiltro === '' ? null : (this.aprobadoFiltro === 'true'),
        fechaDesde: this.formatDate(this.fechaDesde),
        fechaHasta: this.formatDate(this.fechaHasta)
      };

      // tslint:disable-next-line:align
      await this.examenesService.getExamenesByFecha(data).then((examenes: Examen[]) => {
        // tslint:disable-next-line:no-shadowed-variable
        this.formatDataTable(examenes).then((data: any[]) => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.dataSourceLength = this.dataSource.data.length; // Guarda la longitud del dataSource
          this.flagLoaded = true;
        });
      });

    } else {
      this.flagLoaded = false;
      console.log('Por favor, seleccione ambas fechas.');


    }
  }

  formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  formatDataTable(examenes: any[]) {
    return new Promise(resolve => {
      const format: any[] = [];
      let elemento: {
        nombre: string,
        alumno: string,
        disciplina: string,
        recuperatorio: boolean;
        estado: boolean,
        fechaEvaluacion: Date,
        puntajeObtenido: number,
        _id: string,
        usuarioId: string,
        examenId: string,
        materiaBoletinEnviada: boolean,
        fechaMateriaEnviada: Date
      };
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < examenes.length; i++) {
        elemento = {
          nombre: examenes[i].examenTitulo,
          alumno: examenes[i].usuarioNombre != null && examenes[i].usuarioNombre != undefined ? examenes[i].usuarioNombre : 'SIN DATOS',
          disciplina: examenes[i].disciplina != undefined ? examenes[i].disciplina : '-',
          recuperatorio: examenes[i].esRecuperatorio,
          estado: examenes[i].aprobado,
          fechaEvaluacion: examenes[i].fechaEvaluacion,
          puntajeObtenido: examenes[i].puntajeObtenido,
          _id: examenes[i]._id,
          usuarioId: examenes[i].usuarioId,
          examenId: examenes[i].examenId,
          materiaBoletinEnviada: examenes[i].materiaBoletinEnviada,
          fechaMateriaEnviada: examenes[i].fechaMateriaEnviada
        };
        format.push(elemento);
      }
      resolve(format);
    });
  }

  async loadDataTableAprobados() {
    await this.examenesService.getEvaluacionesFindAllAprobados().then((examenes: ExamenEvaluacionLista[]) => {

      if (examenes.length > 0) {
        this.formatDataTableAprobados(examenes).then((data: any[]) => {
          this.dataSourceAprobados = new MatTableDataSource(data);
          this.dataSourceAprobados.sort = this.sortAprobados;
          this.dataSourceAprobados.paginator = this.paginatorFirst;
          this.flagLoadedAprobados = true;
        });
      } else {
        this.flagLoadedAprobados = true;
      }

    });
  }

  formatDataTableAprobados(examenes: any[]) {
    return new Promise(resolve => {
      const format: any[] = [];
      let elemento: {
        nombre: string,
        alumno: string,
        disciplina: string,
        recuperatorio: boolean;
        estado: boolean,
        fechaEvaluacion: Date,
        puntajeObtenido: number,
        id: string,
        usuarioId: string
      };
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < examenes.length; i++) {
        elemento = {
          nombre: examenes[i].examenTitulo,
          alumno: examenes[i].usuarioNombre != null && examenes[i].usuarioNombre != undefined ? examenes[i].usuarioNombre : 'SIN DATOS',
          disciplina: examenes[i].disciplina != undefined ? examenes[i].disciplina : '-',
          recuperatorio: examenes[i].esRecuperatorio,
          estado: examenes[i].aprobado,
          fechaEvaluacion: examenes[i].fechaEvaluacion,
          puntajeObtenido: examenes[i].puntajeObtenido,
          id: examenes[i].id,
          usuarioId: examenes[i].usuarioId
        };
        format.push(elemento);
      }
      resolve(format);
    });
  }

  async loadDataTableDesaprobados() {
    await this.examenesService.getEvaluacionesFindAllDesaprobados().then((examenes: ExamenEvaluacionLista[]) => {

      if (examenes.length > 0) {
        this.formatDataTableDesaprobados(examenes).then((data: any[]) => {
          this.dataSourceDesaprobados = new MatTableDataSource(data);
          this.dataSourceDesaprobados.sort = this.sortDesaprobados;
          this.dataSourceDesaprobados.paginator = this.paginatorSecond;
          this.flagLoadedDesaprobados = true;
        });
      } else {
        this.flagLoadedDesaprobados = true;
      }

    });
  }

  formatDataTableDesaprobados(examenes: any[]) {
    return new Promise(resolve => {
      const format: any[] = [];
      let elemento: {
        nombre: string,
        alumno: string,
        disciplina: string,
        recuperatorio: boolean;
        estado: boolean,
        fechaEvaluacion: Date,
        puntajeObtenido: number,
        id: string,
        usuarioId: string
      };
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < examenes.length; i++) {
        elemento = {
          nombre: examenes[i].examenTitulo,
          alumno: examenes[i].usuarioNombre != null && examenes[i].usuarioNombre != undefined ? examenes[i].usuarioNombre : 'SIN DATOS',
          disciplina: examenes[i].disciplina != undefined ? examenes[i].disciplina : '-',
          recuperatorio: examenes[i].esRecuperatorio,
          estado: examenes[i].aprobado,
          fechaEvaluacion: examenes[i].fechaEvaluacion,
          puntajeObtenido: examenes[i].puntajeObtenido,
          id: examenes[i].id,
          usuarioId: examenes[i].usuarioId
        };
        format.push(elemento);
      }
      resolve(format);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceAprobados.filter = filterValue.trim().toLowerCase();
  }
  applyFilter3(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceDesaprobados.filter = filterValue.trim().toLowerCase();
  }

  onClick(estado: boolean, id: string) {
    this.examenesService.setEstadoExamen(estado, id);
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Evaluaciones_todos_' + this.date.getHours() + '_' + this.date.getHours() + '.xlsx');
  }

  exportTOExcelAprobados() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.tableaprobados.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Evaluaciones_aprobados_' + this.date.getHours() + '_' + this.date.getHours() + '.xlsx');
  }

  exportTOExcelDesaprobados() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.tabledesaprobados.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Evaluaciones_desaprobados_' + this.date.getHours() + '_' + this.date.getHours() + '.xlsx');
  }

  getTabLabel(): string {
    switch (this.aprobadoFiltro) {
      case 'true':
        return 'Aprobado';
      case 'false':
        return 'Desaprobado';
      case '':
      default:
        return 'Todos';
    }
  }

  async verExamen(examenEvaluacion: string) {
    await this.examenesService.setExamenEvaluacionId(examenEvaluacion);
    const dialogRef = this.dialog.open(ExamenEvaluacionDetalleComponent, {
      width: '800px',
      height: '800px',
      data: { examenEvaluacionId: examenEvaluacion }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  async enviarBoletin(id: string, usuarioId: string, nombreExamen: string, evaluacion: ExamenEvaluacion) {
    this.loading = true; // Activar el estado de carga
  
    try {
      const alumno = await this.cursosService.crearAlumno(usuarioId);
      const boletines = await this.boletinesService.getBoletinesAlumnosFindAllByAlumno(alumno._id);
      const examen = await this.examenesService.getExamenFindOne(id);
  
      if (boletines.length == 0) {
        Swal.fire({
          icon: 'info',
          title: 'Info',
          text: 'El alumno no tiene un boletín asociado, primero asígnele uno para continuar.',
          showCancelButton: true,
          confirmButtonText: 'Agregar Boletín',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.cursosService.setAlumnoSeleccionado(alumno);
            this.examenesService.setExamenSeleccionado(examen);
            this.router.navigate(['/boletin-alta']);
          }
        });
        return;
      }
  
      if (examen.materiaBoletin == null) {
        Swal.fire({
          icon: 'info',
          title: 'Info',
          text: 'El Examen no tiene una materia de boletin asociada, modifique el examen asignándole una.',
          showCancelButton: true,
          confirmButtonText: 'Modificar Examen',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.examenesService.setExamenSeleccionado(examen);
            this.router.navigate(['/evaluacion-modificar']);
          }
        });
        return;
      } else {
        this.materiaBoletin = await this.materiasBoletinService.getMateriaFindOne(examen.materiaBoletin.toString());
      }
  
      const dialogRef = this.dialog.open(ExamenesBoletinesModalComponent, {
        width: '800px',
        height: '500px',
        data: { boletines, nombreExamen, examen, evaluacion, materiaNombre: this.materiaBoletin.nombre != null ? this.materiaBoletin.nombre : null} 
      });
  
      dialogRef.afterClosed().subscribe(result => {
        // Cualquier acción posterior al cierre del diálogo
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false; // Desactivar el estado de carga
    }
  }  

}