import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
  selector: 'app-clausula-aadidess-ises',
  templateUrl: './clausula-aadidess-ises.component.html',
  styleUrls: ['./clausula-aadidess-ises.component.scss']
})
export class ClausulaAadidessIsesComponent implements OnInit {

  idioma: Subscription;

  constructor(   
    private translatorService: Translators,
  ) { 
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });
  }

  ngOnInit(): void {
  }

}
