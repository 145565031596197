import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { UserDocumentoTipo } from 'src/app/interfaces/interface-bakend/seguridad/users/user-documento-tipos.interface';
import { UserDocumentoTiposService } from 'src/app/services/shared/user-documento-tipos.service';

@Component({
  selector: 'app-documento-usuario-modificacion',
  templateUrl: './documento-usuario-modificacion.component.html',
  styleUrls: ['./documento-usuario-modificacion.component.scss']
})
export class DocumentoUsuarioModificacionComponent implements OnInit {

  form: FormGroup;
  documentoUsuario: UserDocumentoTipo;

  constructor(
    private fb: FormBuilder,
    private userDocumentoTiposService: UserDocumentoTiposService,
    private guiMsjService: GuiMsjService,
    private router: Router) {
    this.getEstadoDocumentosUsuario();
    this.createForm();
  }

  ngOnInit(): void {
  }

  async getEstadoDocumentosUsuario() {
    if (this.getDocumentoUsuarioTipo() === undefined) {
      this.router.navigateByUrl('/documentos-usuarios-listado');
      return;
    } else {
      this.getDocumentoUsuarioTipo();
    }
  }

  getDocumentoUsuarioTipo() {
    return this.documentoUsuario = this.userDocumentoTiposService.getUserDocumentoTipoSeleccionado();
  }

  get nombreNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  get descripcionNoValida() {
    return this.form.get('descripcion').invalid && this.form.get('descripcion').touched;
  }

  async createForm() {
    this.form = this.fb.group({
      nombre: new FormControl(this.documentoUsuario.nombre, Validators.required),
      descripcion: new FormControl(this.documentoUsuario.descripcion, Validators.required),
      activo: new FormControl(this.documentoUsuario?.activo),
    });
  }

  async setDataForma(): Promise<UserDocumentoTipo> {
    const data: UserDocumentoTipo = {
      nombre: this.form.value.nombre,
      descripcion: this.form.value.descripcion,
      activo: this.form.value.activo,
    };
    return data;
  }

  async update() {
    try {
      await this.guiMsjService.msjFormSubmit('Guardando');
      await this.userDocumentoTiposService.updateUserDocumentosTipo(this.documentoUsuario?._id, await this.setDataForma());
      await this.guiMsjService.msjFormSubmit('DatosGuardados');
      this.router.navigateByUrl('/documentos-usuarios-listado');
    } catch (error) {
      console.error(`Error al actualizar el documento: ${this.documentoUsuario?._id}`, error);
    }
  }

}
