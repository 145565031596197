import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '../../interfaces/interface-bakend/response';
import { environment } from '../../../environments/environment';
import { StorageService } from '../shared/storage.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MateriaBoletin } from 'src/app/interfaces/interface-bakend/cursos/materia-boletin.interface';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class MateriasBoletinService {

  materiaSeleccion: MateriaBoletin;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private authService: AuthService,
  ) { }

  getMaterias(): Promise<MateriaBoletin[]>{
  return new Promise( async resolve => {

      const headers =  new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/materiasBoletin/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });

  });
  }

  getMateriaFindOne(id: string): Promise<MateriaBoletin> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
        });

      this.httpClient.get<Response>(`${ URL_BACKEND }/materiasBoletin/findOne/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
        this.setMateriaSeleccionada(resp.response);
        Swal.close();
      });
    });
  }

  setMateriaSeleccionada(materia: MateriaBoletin) {
    this.materiaSeleccion = materia;
    this.storageService.setMateriaBoletin(materia);
  }

  getMateriaSeleccionada(): MateriaBoletin {
    return this.materiaSeleccion;
  }

  put(id: string, data: MateriaBoletin){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
        });

      this.httpClient.put(`${ URL_BACKEND }/materiasBoletin/updateOne/${id}`, data, { headers }).subscribe( resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Materia modificada satisfactoriamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/materias-boletin-listado']);
        resolve(true)
        ,

        // tslint:disable-next-line:no-unused-expression
        (error) => {
          Swal.close();
          Swal.fire('Error al modificar', '', 'error');
          console.log(error);
          resolve(false);
        };
      });
    });
  }

  post(data: MateriaBoletin) {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post(`${URL_BACKEND}/materiasBoletin/`, data, { headers }).subscribe(
        resp => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: 'Materia guardada correctamente',
            showConfirmButton: false,
            timer: 1100
          });

          this.router.navigate(['/materias-boletin-listado']);
          resolve(true);
        },
        (error) => {
          Swal.close();
          Swal.fire('Error al guardar', '', 'error');
          resolve(false);
        }
      );
    });
  }
}