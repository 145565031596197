<div class="container">
    <div *ngIf="solicitudId">
        <div class="alert alert-warning">
            <img src="https://pagos.macroclickpago.com.ar/images/MACROLogo.png" style="width: 20%;">
            <h4 class="alert-heading">Macro Pagos</h4>
            <p>Abonar mediante Macro Pagos. No es necesario enviar el comprobante si abona por este medio. Su enlace de pago también ha sido enviado a su correo electrónico.</p>
            <button style="display: none;" mat-button (click)="createPaymentMacro()" [disabled]="loadingMacroPagos || isPaymentProcessed">Procesar Pago</button>
            
            <!-- Spinner y mensaje mientras se carga el formulario -->
            <div *ngIf="!loadingMacroPagos" class="loading-container">
                <mat-spinner></mat-spinner>
                <p style="font-size: 30px;">Por favor, no cierre esta ventana. Será redirigido a la plataforma de Macro Pagos.</p>
            </div>
            
            <!-- Contenedor donde se embebará el formulario -->
            <div id="payment-container-macro" class="form-container"></div>
        </div>
    </div>
</div>