import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Response } from '../../interfaces/interface-bakend/response';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class MenuesService {

  constructor(
      private authService: AuthService,
      private httpClient: HttpClient,
  ) { }

  getFindInmediateDescendants(idMenu: string): Promise<[]> {
    return new Promise( async resolve => {
        const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
        });
        this.httpClient.get<Response>(`${ URL_BACKEND }/menues/findInmediateDescendants/${idMenu}`, { headers }).subscribe( resp => {
            resolve(resp.response);
        });
      });
  }

  getItemMenue(idMenu: string) {
    return new Promise( async resolve => {
        const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
        });
        this.httpClient.get<Response>(`${ URL_BACKEND }/menues/findOne/${idMenu}`, { headers }).subscribe( resp => {
            resolve(resp.response);
        });
      });
  }

}
