import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Mesa } from 'src/app/interfaces/interface-bakend/cursos/mesa.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { MesasService } from 'src/app/services/cursos/mesas.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import Swal from 'sweetalert2';
import { MesasListadoComponent } from '../mesas-listado/mesas-listado.component';

@Component({
  selector: 'app-mesas-usuarios-asociados-modal',
  templateUrl: './mesas-usuarios-asociados-modal.component.html',
  styleUrls: ['./mesas-usuarios-asociados-modal.component.scss']
})
export class MesasUsuariosAsociadosModalComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['nombre', 'identificacion', 'fechaInscripcion', 'puntaje'];
  dataSource: any;
  flagLoaded: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private mesasService: MesasService, private cursosService: CursosService
    , private usuariosService: UsuarioService, private guiMsjService: GuiMsjService, private router: Router,
    public dialogRef: MatDialogRef<MesasListadoComponent>) { }

  ngOnInit(): void {
    this.loadDataTable();
  }

  async loadDataTable() {
    await this.mesasService.getMesaFindOne(this.data.mesa).then(async (mesa: Mesa) => {
      this.dataSource = new MatTableDataSource(mesa.usuarios);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    });
  }

  async verPuntajes(_id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    await this.usuariosService.getFindOne(_id).then(() => {
      Swal.close();
      this.dialogRef.close();
      this.router.navigateByUrl('/gestos-evaluaciones-usuarios-listado');
    }).catch((error)=>{
      Swal.close();
      console.warn("Error al consultar usuario");
    })
  }
}
