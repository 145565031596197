import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, of, zip } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ExamenEvaluacion } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';
import { Examen, Pregunta, Respuesta } from 'src/app/interfaces/interface-bakend/cursos/examen.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExamenesService } from 'src/app/services/cursos/examenes.service';

@Component({
  selector: 'app-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['./multiple-choice.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
}]

})
export class MultipleChoiceComponent implements OnInit {
	@Input() respuestas: Respuesta[];
	@Input() index: number;
	@Input() tipoPregunta: string;
	@Input() longitud: number;
	@Input() examen: Examen;
	@Output() respuestasEmitter: EventEmitter<any>;

	isLinear = false;
	estado = false;
	respuestasArray: Respuesta[] = [];
	form: FormGroup;
	preguntas: Pregunta[] = [];
	examenParcial: Examen;

	constructor(private formBuilder: FormBuilder,
							private examenServices: ExamenesService,
							private authService: AuthService) {
							this.respuestasEmitter = new EventEmitter();
							this.form = this.formBuilder.group({
									opciones: new FormArray([], this.minSelectedCheckboxes(1))
							});
	}

	async ngOnInit(): Promise<void> {
		this.respuestasArray = this.respuestas;
		this.addCheckboxes();
		this.ultimaPregunta();
		this.examenServices.getCursarExamen().subscribe((examenParcial: Examen) => {
			this.examenParcial = examenParcial;
		});
	}

	private addCheckboxes() {
		this.respuestasArray.forEach(() => this.ordersFormArray.push(new FormControl(false)));
	}

	async getPreguntas() {
		const respuestas = this.respuestas;
		return of(respuestas).pipe(delay(1000));
	}

	get ordersFormArray() {
		return this.form.controls.opciones as FormArray;
	}

	async seleccionarOpciones(event, i){
		this.respuestas[i].seleccionado = event.checked;
		this.examenServices.setCursarExamen(this.examen);

		// CONTROL PARA VERIFICAR QUE TODAS LAS PREGUNTAS TENGAN AL MENOS UNA RESPUESTA SELECCIONADA PARA FINALIZAR EL EXAMEN
		// TRABAJA CON UN OBSERVABLE
		let preguntasLength = 0;
		this.examenParcial.preguntas.filter(pregunta => {
			if(pregunta.tipo === "MC"){
				preguntasLength++;
			}
		})
		const preguntas = this.examenParcial.preguntas;
		let seleccionados = 0;
		//let preguntasLength = preguntas.length;
		preguntas.forEach(preguntas =>{
			if(preguntas.tipo === "MC"){
				const areSomeTrue = preguntas.respuestas.some(function(select:{
					seleccionado: boolean
				}): boolean{
					return select.seleccionado === true;
				})
				if(areSomeTrue===true){
					seleccionados++;
					if(seleccionados === preguntasLength){
						this.examenServices.finalizarEstado = true;
					}
				}else{					
					this.examenServices.finalizarEstado = false;	
				}
				
				
			}		
		})
		
		// ENVIAR EXAMEN PARCIAL PARA GUARDAR ( HACE UN UPDATE SOBRE EL EXAMEN EN CURSO )
		const examenParcial: ExamenEvaluacion = {
			usuario: (await this.authService.getUserLogon())._id,
			examen: this.examenServices.getExamenSeleccionado()._id, 
			asignacion: this.examenServices.getExamenSeleccionado().asignacion, 
			respuesta: this.examen
		}
		  // EVALUAR EXAMEN
		await this.examenServices.evaluacionUpdateOne(this.examenServices.getExamenEvaluacionId(),examenParcial).then((evaluacion: ExamenEvaluacion)=>{})

	}

	// Funcion para saber si estoy posicionado en la ultima pregunta del examen
	ultimaPregunta(){
		return this.form.statusChanges.subscribe( async status => {
			this.examenServices.indexPreguntaExamen = this.index;
			this.examenServices.longitudPreguntasExamen = this.longitud;
			if (status === 'VALID') {
					this.examenServices.formStatusExamen = true;
			}
		});
	}

	minSelectedCheckboxes(min: number) {
		const validator: ValidatorFn = (formArray: FormArray) => {
		const totalSelected = formArray.controls
				.map(control => control.value)
				.reduce((prev, next) => next ? prev + next : prev, 0);

		return totalSelected >= min ? null : { required: true };
		};

		return validator;
	}
}
