import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { CursoActividad } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';

@Component({
  selector: 'app-actividades-cursos',
  templateUrl: './actividades-cursos.component.html',
  styleUrls: ['./actividades-cursos.component.scss']
})
export class ActividadesCursosComponent implements OnInit {

  @Input() cursoActividad: CursoActividad[];
  flag = false;
  flagLoaded = false; 

  constructor(
  ) { }


  async ngOnInit(): Promise<void> {    
    await this.quitarClasesVencidas();

    if (this.cursoActividad.length > 0) {
      this.cursoActividad = this.cursoActividad.filter(item => item.tipo === "ACTIVIDAD");
      this.flag = true;
    } else {
      this.flag = false;
    }
  }

  link(url) {
      window.open(url, "_blank");
  }

  get sortActividades() {
    return this.cursoActividad.sort((a, b) => {
      return  <any>new Date(a.fecha) - <any>new Date(b.fecha);
    });
  }

  async quitarClasesVencidas() {
   for (let index = 0; index < this.cursoActividad.length; index++) {
    if ( new Date( this.cursoActividad[index].fechaVencimiento).toISOString().slice(0, 10) < new Date().toISOString().slice(0, 10)) {
      this.cursoActividad.splice(index, 1);
      index--;
    }
   }
    this.flagLoaded = true;
  }


}
