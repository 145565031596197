import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { Component, EventEmitter, OnInit, Output, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CursosService } from '../../../../../services/cursos/cursos.service';
import { SelectionModel } from '@angular/cdk/collections';
import Swal from 'sweetalert2';
import { ExamenesService } from '../../../../../services/cursos/examenes.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Examen } from '../../../../../interfaces/interface-bakend/cursos/examen.interface';
import { GuiMsjService } from '../../../../../services/shared/gui-msj.service';

@Component({
  selector: 'app-grilla-cursos',
  templateUrl: './grilla-cursos.component.html',
  styleUrls: ['./grilla-cursos.component.scss']
})
export class GrillaCursosComponent implements OnInit, AfterViewInit {

  @Output() cursoAsignado = new EventEmitter<boolean>();

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
  [
    'select', 'nombre', 'introduccion', 'fechaInicio', 'fechaFin', 'disponible',
  ];
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  /* Fin Data Table*/

  flagLoaded = false;
  flagAsignando = false;
  flagSelected = false;
  disabled = false;
  cursoDisponible: string;

  subscriptionExamenValues: Subscription;

  examen: Examen;


  constructor(
    private cursosService: CursosService,
    private examenesService: ExamenesService,
    private guiMsjService: GuiMsjService,
    private cdRef: ChangeDetectorRef,

  ) {
    this.subscriptionExamen();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
  }

  ngOnInit(): void {
    this.selection = new SelectionModel<any>(true, []);
    this.loadDataPage();
  }

  /* observable al examen */
  subscriptionExamen() {
    this.subscriptionExamenValues = this.examenesService.getExamen().subscribe( async resp => {
        this.examen = resp.value;
    });
  }

  async loadDataPage() {
    await this.loadDataTable();
    return;
  }

  async loadDataTable(){
    await this.cursosService.getCursos().then( async (cursos: Curso[]) => {
      await this.formatDataTable(cursos).then( async ( data: Curso[] ) => {
        this.dataSource = await new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    });
    this.flagLoaded = true;
  }

  formatDataTable( cursos: Curso[]) {
    return new Promise( resolve => {
      const format: any[] = [];
      let elemento: {
        nombre: string,
        introduccion: string,
        fechaInicio: Date,
        fechaFin: Date,
        disponible: string,
        curso: Curso
      };
       // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < cursos.length; i++) {

        if (cursos[i].disponible){
          this.cursoDisponible = 'Sí';
        }else{
          this.cursoDisponible = 'No';
        }

        elemento = {
          nombre: cursos[i].nombre,
          introduccion: cursos[i].introduccion,
          fechaInicio: cursos[i].fechaInicio,
          fechaFin: cursos[i].fechaFin,
          disponible: this.cursoDisponible,
          curso: cursos[i]
      };
        format.push(elemento);
      }
      resolve(format);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async asignarExamen(tipo: string) {
    this.flagAsignando = true;
    this.flagSelected = false;
    this.disabled = true;
    await this.selection.selected.forEach( async element => {
            const cursoSelect = element;
            await this.examenesService.asignacionExamen(this.examen._id, cursoSelect.curso.id, '', '' );
    });
    this.cursoAsignado.emit(true);
    this.guiMsjService.msjFormSubmit('examenAsignado');
    this.flagAsignando = false;
  }

  /*------------------------------------------
  --------------------------------------------
  Defined DATA TABLE
  --------------------------------------------
  --------------------------------------------*/
  toggleCheckbox(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  /** ngAfterViewChecked lo carga automaticamente , evita el error del check **/
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  async masterToggle() {
    if (this.isAllSelected()) {
      //  console.log('masterToggle=> isAllSelected=> this.selection.clear()');
      this.selection.clear();
      this.flagSelected = false;
      return;
    }
    if (this.dataSource.data.length > 0) {
      // console.log('masterToggle=> this.dataSource.data.length=> this.selection.clear()', this.dataSource.data.length);
      this.flagSelected = true;
      this.dataSource.data.forEach( element => {
        this.selection.toggle(element);
      });      
     
    } 

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /** The element selected */
  async onChangeToggled(elemento) {
    this.selection.toggle(elemento);
    this.flagSelected = true;
    // console.log('onChangeComprobanteToggled',elemento);
   
    // Comienzo proceso de asignacion
    if (this.selection.selected.length > 0) {
      // console.log('this.selection.selected.length ',this.selection.selected.length );
    } 
  }
  /** FIN DATA TABLE **/

}
