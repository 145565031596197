<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li>Comprobantes Transferencia</li>
            </ul>
            <h2>Comprobantes Transferencia</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>


<div class="profile-area pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <h3>Listado de Comprobantes</h3>
            <hr>
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Column" autocomplete="off" #input>
            </mat-form-field>

            <div class="mat-elevation-z8">

                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Column -->
                    <ng-container matColumnDef="fecha">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                        <td mat-cell *matCellDef="let element"> <strong> {{ element.fecha | date: 'mediumDate' }}</strong> </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="usuario">
                        <th width="20%;" mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
                        <td width="20%;" mat-cell *matCellDef="let element">{{ element.usuario }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="actividad">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actividad </th>
                        <td mat-cell *matCellDef="let element"> {{ element.actividad }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="importe">
                        <th width="15%;" mat-header-cell *matHeaderCellDef mat-sort-header> Importe (PESOS) </th>
                        <td width="15%;" mat-cell *matCellDef="let element">${{ element.importe }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="comprobanteID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Detalles </th>
                        <td mat-cell *matCellDef="let element">
                            <button *ngIf=flagLoadedModal (click)="verComprobante(element.comprobanteID)" mat-raised-button color="accent">Ver</button>
                            <button *ngIf=!flagLoadedModal disabled mat-raised-button color="accent">Ver</button>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row" [ngClass]="{'green-row': row.facturado}"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>
</div>