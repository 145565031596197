<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li>Solicitudes</li>
            </ul>
            <h2>Solicitudes</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-area pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <h3>Aquí encontrará el estado de los Solicitudes solicitadas</h3>
            <app-loading *ngIf="!flagLoadedModal" class="m-5"></app-loading><br>
            <hr>
            <mat-form-field appearance="fill">
                <mat-label>Selecciona un estado</mat-label>
                <mat-select [(ngModel)]="selectEstado" (ngModelChange)="verSolicitudes($event)" name="estados">
                    <mat-option *ngFor="let estado of this.iAppOfertas.selectEstados" [value]="estado.number">
                        {{estado.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- <div mat-dialog-actions align="end">
                <button (click)="verSolicitudes(this.selectEstado)" mat-raised-button color="accent">Actualizar grilla</button>
            </div> -->
            <div>
                <mat-form-field>
                    <mat-label>Filtrar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
                </mat-form-field>

                <div class="mat-elevation-z8">

                    <table mat-table [dataSource]="dataSource" matSort>

                        <!-- Column -->
                        <ng-container matColumnDef="tramite">
                            <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Solicitud </th>
                            <td width="10%" mat-cell *matCellDef="let element"> {{element.tramite}} </td>
                        </ng-container>

                        <!-- Column -->
                        <ng-container matColumnDef="paisNombre">
                            <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> País </th>
                            <td width="10%" mat-cell *matCellDef="let element"> {{element.paisNombre}} </td>
                        </ng-container>

                        <!-- Column -->
                        <ng-container matColumnDef="actividad">
                            <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> Actividad </th>
                            <td width="10%" mat-cell *matCellDef="let element"> {{element.actividad }} </td>
                        </ng-container>

                        <!-- Column -->
                        <ng-container matColumnDef="fechaEstado">
                            <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Solicitud </th>
                            <td width="10%" mat-cell *matCellDef="let element"> {{element.fechaEstado | date:"dd/MM/yy"}} </td>
                        </ng-container>

                        <!-- Column -->
                        <ng-container matColumnDef="estado">
                            <th width="15%" mat-header-cell *matHeaderCellDef mat-sort-header> Progreso </th>
                            <td width="15%" mat-cell *matCellDef="let element"> {{element.estado | estadosPipe}} </td>
                        </ng-container>

                        <!-- Column -->
                        <ng-container matColumnDef="solicitante">
                            <th width="15%" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                            <td width="15%" mat-cell *matCellDef="let element"> {{element.solicitante | nombreExiste}} </td>
                        </ng-container>

                        <!-- Column -->
                        <ng-container matColumnDef="email">
                            <th width="15%" mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                            <td width="15%" mat-cell *matCellDef="let element"> {{element.emailSolicitante || 'SIN DATOS'}} </td>
                        </ng-container>

                        <!-- Column -->
                        <ng-container matColumnDef="identificacion">
                            <th width="15%" mat-header-cell *matHeaderCellDef mat-sort-header> Identificación </th>
                            <td width="15%" style="padding-left: 8px;" mat-cell *matCellDef="let element"> {{element.identificacion | nombreExiste}} </td>
                        </ng-container>

                        <!-- Column -->
                        <ng-container matColumnDef="id">
                            <th width="5%" mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                            <td width="5%" mat-cell *matCellDef="let element">
                                <button *ngIf=flagLoadedModal (click)="verSolicitud(element)" mat-raised-button color="accent">Ver</button>
                                <button *ngIf=!flagLoadedModal disabled mat-raised-button color="accent">Ver</button>
                                <!-- <button *ngIf=flagLoadedModal (click)="verSolicitud(element)" class="btn default-btn-style-two"><i class="fas fa-file"></i> Ver</button>
                                <button *ngIf=!flagLoadedModal disabled class="btn default-btn-style-two"><i class="fas fa-file"></i> Ver</button>  -->
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                    </table>

                </div>
                <app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
        </div>
    </div>
</div>