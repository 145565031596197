<div class="single-inscripciones-box" (click)="navCtrlDetalleOfertaLaboral(actividad)">
    <div class="inscripciones-image">
        <a (click)="navCtrlDetalleOfertaLaboral(actividad)" routerLink='/actividad-detalle' class="d-block image">
            <img [src]=" 1 | mediaOfertaLaboral: actividad.imagen | safe" alt="image">
            <a class="link-btn"></a>
        </a>
    </div>

    <div class="inscripciones-content">
        <h3>
            <a (click)="navCtrlDetalleOfertaLaboral(actividad)" routerLink='/actividad-detalle' class="link-btn">{{actividad.nombre}}</a>
        </h3>
        <!-- <p>{{ idioma | translate: "solicitudes.PAIS" | async}}: {{actividad.paisDisponible.pais.name_es}} - {{actividad.resumenAvatar}}</p> -->
        <ul class="inscripciones-box-footer d-flex justify-content-between align-items-center">
            <li>{{ idioma | translate: "oferta-laboral.VIGENTE" | async}} <i class='flaticon-calendar'></i> {{ actividad.vigenciaDesde | date:"dd/MM/yy" }} - {{ actividad.vigenciaHasta | date:"dd/MM/yy" }} </li>
            <!-- <li><i class='flaticon-people'></i>145 {{ idioma | translate: "solicitudes.PUESTOS-DE-TRABAJO" | async}}</li> -->
        </ul>
    </div>
</div>