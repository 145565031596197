

import { Component, Input, OnInit } from '@angular/core';
import { CursoActividad } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from 'src/app/services/cursos/cursos.service';

@Component({
  selector: 'app-calendario-curso-on-line',
  templateUrl: './calendario-curso-on-line.component.html',
  styleUrls: ['./calendario-curso-on-line.component.scss']
})
export class CalendarioCursoOnLineComponent implements OnInit {

  @Input() nombreCurso:string;
  flagNivel1 = false;
  flagNivel2 = false;
  flagNivel3 = false;
  flagFondo = false;

  flag: boolean = true; // Variable para controlar la visibilidad del mensaje de alerta
  flagLoaded: boolean = true; // Variable para controlar la visibilidad del componente de carga
  sortActividades: CursoActividad[] = []; // Arreglo de actividades (asegúrate de inicializarlo
  actividadesCalendario: CursoActividad[] = []; // Arreglo de actividades (asegúrate de inicializarlo

  constructor(
    private cursosService: CursosService
  ) { }

  ngOnInit(): void {
    // if (this.nombreCurso === 'Teórico Nivel 1 Esquí' || this.nombreCurso === 'Teórico Nivel 1 Snowboard'){ this.flagNivel1 = true;}
    // if (this.nombreCurso === 'Teórico Nivel 2 Esquí' || this.nombreCurso === 'Teórico Nivel 2 Snowboard'){ this.flagNivel2 = true;}
    // if (this.nombreCurso === 'Teórico Nivel 3 Esquí' || this.nombreCurso === 'Teórico Nivel 3 Snowboard'){ this.flagNivel3 = true;}
    // if (this.nombreCurso === 'Instructor de Esquí de Fondo'){ this.flagFondo = true;}
    this.loadData();
  }

  async loadData() {
     this.sortActividades =  await this.cursosService.getCursoSeleccionado().actividades;
     this.actividadesCalendario = this.sortActividades.filter(actividad => actividad.tipo === 'CALENDARIO');
     await this.quitarClasesVencidas();
     this.flagLoaded = true;
  }

  async quitarClasesVencidas() {
    for (let index = 0; index < this.actividadesCalendario.length; index++) {
     if ( new Date( this.actividadesCalendario[index].fechaVencimiento).toISOString().slice(0, 10) < new Date().toISOString().slice(0, 10)) {
       this.actividadesCalendario.splice(index, 1);
       index--;
     }
    }
     this.flagLoaded = true;
  }
  
  link(url) {
    window.open(url, "_blank");
  }


}
