<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/dashboard">Inicio</a></li>
        <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
        <li><a routerLink="/gestos-evaluaciones-listado">Listado de puntajes cargados</a></li>
        <li>Modificación de puntajes cargados</li>
      </ul>
      <h2>Modificación de puntajes cargados</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
  <div class="container">
    <div class="row mx-auto">
      <div class="col-lg-12 col-md-12">
        <div class="apply-form">
          <form [formGroup]="form" (ngSubmit)="updateGestoEvaluado()">
            <mat-card>
              <strong>
                <p>Mesa:</p>
              </strong>
              <p>{{gestoEvaluado?.mesa.titulo}}</p>

              <strong>
                <p>Gesto evaluado:</p>
              </strong>
              <p>{{gestoEvaluado?.gesto.nombre}}</p>

              <p>Disciplina: {{gestoEvaluado?.gesto.disciplina.nombre}}</p>

              <p>Nivel: {{gestoEvaluado?.gesto.nivel}}</p>

              <strong>
                <p>Nota de aprobación:</p>
              </strong>
              <p>{{gestoEvaluado?.gesto.valorAprobacion}}</p>

              <strong>
                <p>Usuario:</p>
              </strong>
              <p>{{gestoEvaluado?.usuario?.fullName}}</p>

            </mat-card>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-xs-12">

                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                  <mat-label>Nota</mat-label>
                  <input autofocus type="number" min="0" formControlName="nota" matInput placeholder="Nota" autocomplete="off">
                </mat-form-field>

              </div>
            </div>

            <div class="d-flex justify-content-end">
              <button mat-raised-button color="accent" type="button" routerLink="/gestos-evaluaciones-listado"
                style="margin-right: 1%; background-color: #eeeeee;">Cancelar</button>
              <button mat-raised-button [disabled]="!form.valid" color="primary" class="right-button">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>