<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/ofertas-configuracion">Configuración Ofertas</a></li>
                <li>Configuración Catálogo</li>
            </ul>
            <h2>Configurar Catálogo</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="features-area pb-70 animate__animated animate__fadeIn">
    <div class="container">

        <div class="row m-5">
            <div class="col-lg-4 col-sm-4 col-md-4">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-book-open"></i>
                    </div>
                    <h3><b>Áreas</b></h3>
                    <p>Acceda a la configuración áreas, modalidades y tipo de puestos</p>
                    <a routerLink="/areas-configuracion" class="link-btn">
                        Ir a Áreas
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-book-open"></i>
                    </div>
                    <h3><b>Modalidades</b></h3>
                    <p>Acceda a la configuración áreas, modalidades y tipo de puestos</p>
                    <a routerLink="/modalidades-configuracion" class="link-btn">
                        Ir a Modalidades
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-book-open"></i>
                    </div>
                    <h3><b>Tipo de Puestos</b></h3>
                    <p>Acceda a la configuración áreas, modalidades y tipo de puestos</p>
                    <a routerLink="/tipo-puestos-configuracion" class="link-btn">
                        Ir a Tipo de Puestos
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>