<h2>
    {{ idioma | translate: "form.CONFIRMAR-DATOS" | async}}</h2>
<form class="row g-3">

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.NOMBRE" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.nombrePersona">
    </div>

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.APELLIDO" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.apellidoPersona">
    </div>

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.TIPO-ID" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.tipoIdentificacion?.concepto">
    </div>

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.NRO-ID" | async}}
        </label>
        <input type="text" disabled class="form-control" [value]="this.data?.identificacion">
    </div>

    <div class="col-md-6">
        <label class="form-label">{{ idioma | translate: "form.EMAIL" | async}}
        </label>
        <input type="text" disabled class="form-control" [value]="this.data?.email">
    </div>

    <div class="col-md-6"  *ngIf="birth !== '01-01-1970'">
        <label class="form-label">{{ idioma | translate: "form.FECHA-NACIMIENTO" | async}}
        </label>
        <input type="text" disabled class="form-control" [(ngModel)]="birth" name="birth">
    </div>

    <div class="col-md-6"  *ngIf="this.data?.paisNacimiento?.name_es">
        <label class="form-label">{{ idioma | translate: "form.PAIS-NACIMIENTO" | async}}
        </label>
        <input type="text" disabled class="form-control" [value]="this.data?.paisNacimiento?.name_es">
    </div>

    <div class="col-md-6" *ngIf="this.data?.paisCodeArea">
        <label class="form-label">{{ idioma | translate: "form.CODIGO-AREA" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.paisCodeArea">
    </div>

    <div class="col-md-6" *ngIf="this.data?.telefono">
        <label class="form-label">{{ idioma | translate: "form.NUMERO-TELEFONO" | async}}</label>
        <input type="text" disabled class="form-control" [value]="this.data?.telefono">
    </div>

   
    <div class="col-md-6" *ngIf="this.data?.idiomaCa || this.data?.idiomaEs || this.data?.idiomaEs || this.data?.idiomaEn">
        <label class="form-label">{{ idioma | translate: "form.IDIOMAS" | async}}</label>
        <p>
            <mat-checkbox disabled [checked]="this.data?.idiomaCa">{{ idioma | translate: "form.CATALAN" | async}}</mat-checkbox>
        </p>
        <p>
            <mat-checkbox disabled [checked]="this.data?.idiomaEs">{{ idioma | translate: "form.ESPANOL" | async}}</mat-checkbox>
        </p>
        <p>
            <mat-checkbox disabled [checked]="this.data?.idiomaFr">{{ idioma | translate: "form.FRANCES" | async}}</mat-checkbox>
        </p>
        <p>
            <mat-checkbox disabled [checked]="this.data?.idiomaEn">{{ idioma | translate: "form.INGLES" | async}}</mat-checkbox>
        </p>
    </div> 

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button (click)="aceptar()" class="btn btn-primary me-md-2" type="button">{{ idioma | translate: "form.BTN-ACEPTAR" | async}}</button>
        <button (click)="cancelar()" class="btn btn-secondary" type="button">{{ idioma | translate: "form.BTN-CANCELAR" | async}}</button>
    </div>

</form>