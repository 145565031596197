import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormularioInscripcionComponent } from './formulario-inscripcion.component';

@Component({
  selector: 'app-inscripcion-confirmation-dialog',
  templateUrl: './inscripcion-confirmation-dialog.component.html',
  styleUrls: ['./inscripcion-confirmation-dialog.component.css']
})

export class InscripcionConfirmationDialogComponen implements OnInit{
  formData: any; // Objeto para almacenar los datos del formulario
  cursosDelegaciones: object[] = [];
  tipoPista: boolean;
  
  constructor(
    public dialogRef: MatDialogRef<FormularioInscripcionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {
    this.formData = data.formData; // Recibiendo los datos del formulario  
    this.tipoPista = data.tipoPista; // Recibiendo los datos del formulario  

    // // Suponiendo que formData.cursosDelegacionesSeleccionados ya tiene algunos elementos
    // for (let i = 0; i < 20; i++) {
    //   this.formData.cursosDelegacionesSeleccionados.push({
    //       "_id": "65cf66e129046773f0470f46",
    //       "tipo": "DELEGACIÓN",
    //       "nombre": "Catedral",
    //       "descripcion": "panel delevación",
    //       "fecha": "2024-03-30T13:44:52.000Z",
    //       "fechaVencimiento": "2024-06-29T13:44:57.000Z",
    //       "cursoNombre": "Alta Curso Panel Delegaciones 1 Con Pista - usar prueba",
    //       "_idModulo": "657130018f39e91c481d9f62",
    //       "seleccionado": true
    //   });
    // }

}
  ngOnInit(): void {
  }


  confirm(): void {
    this.dialogRef.close('confirmar');
  }

  cancel(): void {
    this.dialogRef.close('cancelar');
  }


}
