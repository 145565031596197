import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment';
import { Response } from '../../interfaces/interface-bakend/response';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class PaypalService {

    detail: any;
    private estadoPago = new Subject<any>();

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
    ) {
    }

    setDetail(detail) {
        this.detail = detail;
    }

    getDetail(){
        return this.detail;
    }

    getEstadoPago(): Observable<any> {
        return this.estadoPago.asObservable();
    }

    setEstadoPago(estado) {
        this.estadoPago.next({ value: estado });
    }

    setInitDefaultEstadoPago(estado) {
        this.estadoPago = estado;
    }

    setErrorProcesarPago(error: any): Promise<boolean> {
        return new Promise( async resolve => {

            const headers =  new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            const data = {
                type: 'PAYPAL',
                result: error,
            };

            this.httpClient.post<Response>(`${ URL_BACKEND }/payments-errors/createByUserLogon/`,  data , { headers })
                .subscribe( resp => {
                    if (!resp.response) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            });
    }


}
