<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{ idioma | translate: "navbar.UL-INICIO" | async}}</a></li>
                <li>{{ idioma | translate: "navbar.UL-FORM-CONTACTO" | async}}</li>
            </ul>
            <h2>{{ idioma | translate: "navbar.UL-FORM-CONTACTO" | async}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">{{ idioma | translate: "home.CONTACTO-DETALLE" | async}}</span>

                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>SEDE CENTRAL</h3>
                            <p>{{ idioma | translate:"footer.FOOTER-DIRECCION-TITULO" | async}}
                                 <br>
                            {{ idioma | translate:"footer.CP" | async}}</p>
                        </li>
                                               
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>TELÉFONOS</h3>
                            <p>(+54) 294 442-8789 <br>/294 452-2990<br>/294 43-6072</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form-email">
                    <h3>{{ idioma | translate: "form.COMPLETE-FORM" | async}}</h3>

                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input formControlName="email" type="text" [class.is-invalid]="emailNoValido" class="form-control-email" id="email" [placeholder]="idioma | translate: 'form.placeholders.INGRESE-EMAIL' | async">
                                    <small *ngIf="emailNoValido" style="color:#1F1F32;">{{ idioma | translate: "form.EMAIL-INVALIDO" | async}}</small>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea formControlName="mensaje" cols="30" rows="5" class="text-area" [placeholder]="idioma | translate: 'form.placeholders.INGRESE-MENSAJE' | async"></textarea>
                                </div>
                            </div>

                            <!-- <div class="form-group">
                                <app-modal-legal [CFA]="true" [TC]="true"></app-modal-legal>
                            </div> -->


                            <div class="form-group">

                                <mat-checkbox formControlName="clausula"><em>{{ idioma | translate: "legales.LEGAL-CTF-5" | async}}
                                     - <a (click)="openBlank('TC')" class="link" style="text-decoration: none;">{{ idioma | translate:"legales.LEGAL-CTF-TC" | async}}</a>
                                     </em>
                                </mat-checkbox>

                            </div>
                            <div class="form-group">

                                <mat-checkbox formControlName="proteccionDeDatos"><em>{{ idioma | translate: "legales.LEGAL-CTF-4" | async}}
                                </em></mat-checkbox>
                                - <a (click)="openBlank('CFA')" class="link" style="text-decoration: none;">{{ idioma | translate:"legales.LEGAL-CTF-1" | async}}</a>

                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" [disabled]="!form.valid" class="default-btn" disabled>{{ idioma | translate: "form.ENVIAR-FORM" | async}}<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>