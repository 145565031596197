<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <br>
                <h2  *ngIf="tipoPista" style="color: #66ccff;">Formulario de Pre inscripción cursado en <span style="font-weight: bold;">PISTA</span></h2>
                <h2  *ngIf="!tipoPista"style="color: #66ccff;">Formulario de Pre inscripción cursado <span style="font-weight: bold;" >ON LINE</span></h2>          

                <div *ngIf="!loaded" class="loading-overlay">
                    <div class="loading-content">
                        <button class="btn btn-outline-primary btn-load" disabled>
                            <span class="d-flex align-items-center">
                                <!-- <span class="spinner-border flex-shrink-0" role="status"></span> -->
                                <span class="flex-grow-1 ms-2">Espera mientras se cargan los datos necesarios...</span>
                            </span>
                        </button>
                        <div class="progress" style="width: 100%; margin-top: 1rem;">
                            <div class="progress-bar custom-progress-bar" role="progressbar" [style.width.%]="progressPercentage"
                                [attr.aria-valuenow]="progressPercentage" aria-valuemin="0" aria-valuemax="100">{{progressPercentage}}%
                            </div>
                        </div>
                    </div>
                </div>
                                
                <form [formGroup]="myForm" *ngIf="loaded" >

                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field style="width: 100%;">
                                <input matInput

                                       placeholder="Nombre y Apellido"
                                       formControlName="nombreApellido">
                            </mat-form-field>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field style="width: 100%;">
                                <input matInput
                                       placeholder="Correo electrónico"
                                       readonly
                                       formControlName="email">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field style="width: 100%;" readonly>
                                <mat-select [ngModel]="tiposIdentificacion" placeholder="Tipo de Documento" redonly
                                            formControlName="tipoIdentificacion" (ngModelChange)="onChangeTipoIdentificacion($event);">
                                    <mat-option *ngFor="let ti of tiposIdentificaciones" value="{{ti.concepto}}" [class.is-invalid]="tipoIdentificacion">{{ti.concepto}} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field style="width: 100%;">
                                <input matInput                                
                                       placeholder="Número de Documento"
                                       readonly
                                       formControlName="numeroDocumento">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <label for="paisesDataList"
                                   class="form-label">{{ idioma | translate: "form.NACIONALIDAD" |
                                async}}</label>
                            <input formControlName="nacionalidad"
                                   class="form-control"
                                   [ngModel]="this.nacionalidad"
                                   (ngModelChange)="onChangePais($event)"
                                   list="paises"
                                   id="datalistPaisesDisponibles"
                                   [placeholder]=" idioma | translate: 'form.BUSCADOR-TEXT-INPUT' | async"
                                   [class.is-invalid]="paisNacimiento">
                            <datalist id="paises">
                                <option *ngFor="let pais of paises"
                                        [value]="pais.name_es">{{pais.name_es}}</option>
                            </datalist>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field style="width: 100%;">
                                <input matInput
                                       type="date"
                                       placeholder="Fecha de Nacimiento"
                                       formControlName="fechaNacimiento">
                            </mat-form-field>
                        </div>
                    </div>

                    <br>

                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="highlighted-input" style="width: 100%;">
                                <input matInput
                                       placeholder="Teléfono (WhatsApp)"
                                       formControlName="telefono"
                                       class="whatsapp-input">
                            </mat-form-field>
                        </div>
                    </div>
                    
                    

                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field appearance="fill"
                                            style="width: 100%;">
                                <mat-label>¿Padece alguna enfermedad que requiere tratamiento?</mat-label>
                                <mat-select formControlName="enfermedad"
                                            [ngModel]="enfermedad">
                                    <mat-option [value]="true">Sí</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <mat-form-field appearance="fill"
                                            style="width: 100%;">
                                <mat-label>¿Tienes título secundario completo?</mat-label>
                                <mat-select formControlName="tituloSecundario"
                                            [ngModel]="tituloSecundario">
                                    <mat-option [value]="true">Sí</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <mat-form-field appearance="fill"
                                            style="width: 100%;">
                                <mat-label>¿Ha estado hospitalizado en los últimos 5 años?</mat-label>
                                <mat-select formControlName="hospitalizado"
                                            [ngModel]="hospitalizado">
                                    <mat-option [value]="true">Sí</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field style="width: 100%;">
                                <mat-select placeholder="Seleccione un Curso"
                                            formControlName="curso"
                                            (selectionChange)="seleccionarCurso($event)">
                                    <mat-option *ngFor="let curso of preescripcionesPista"
                                                [value]="curso._id">{{ curso.nombre
                                        }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field style="width: 100%;">
                                <mat-select placeholder="Seleccione los Módulos"
                                            formControlName="modulos"                                            
                                            [disabled]="!modulos || !selectedCurso"
                                            (selectionChange)="seleccionarModulos($event)">
                                    <mat-option *ngFor="let modulo of modulos"
                                                [value]="modulo">{{ modulo.nombre
                                        }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>               
                       
                    </div>   
                    
                   

                    <div *ngFor="let modulo of modulosSeleccionados; let i = index" class="modulo-container">
                        <div class="card">
                            <div class="card-body">
                          
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3>{{ modulo.nombre }} - <span style="font-weight: bold; color: #66ccff;">Valor del módulo {{ this.precioModulo | precio }}</span></h3>
                                    </div>
                                </div>
                                

                                <div class="alert alert-warning" role="alert">
                                    Solo puedes seleccionar <span style="font-weight: bold;">una actividad!</span> de las lista.
                                </div>                                        
                                                                                                  
                                <div class="table-responsive" *ngIf="tipoPista" > 
                                    <table *ngIf="delegacionesPorModulo[i].length > 0" mat-table [dataSource]="delegacionesPorModulo[i]" class="mat-elevation-z8 custom-table">
                                        <ng-container *ngFor="let column of displayedColumnsPista" [matColumnDef]="column">
                                            <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
                                            <td mat-cell *matCellDef="let element" [ngSwitch]="column" [ngClass]="{'date-out-of-range': isDateOutOfRange(element.fecha) && isDateOutOfRange(element.fechaVencimiento)}">
                                                <ng-container *ngSwitchCase="'Curso'">{{ element.cursoNombre }}</ng-container>
                                                <ng-container *ngSwitchCase="'Delegación'">{{ element.nombre }}</ng-container>
                                                <ng-container *ngSwitchCase="'Descripción'">{{ element.descripcion }}</ng-container>
                                                <ng-container *ngSwitchCase="'Inscripción Desde'">{{ element.fecha | date: 'dd/MM/yyyy' }}</ng-container>
                                                <ng-container *ngSwitchCase="'Inscripción Hasta'">{{ element.fechaVencimiento | date: 'dd/MM/yyyy' }}</ng-container>                                              

                                                <ng-container *ngSwitchCase="'Seleccionar'">
                                                    <ng-container *ngIf="!isRadioButtonDisabled(element)">
                                                      <mat-radio-button [checked]="isSelected(element, i)" (click)="selectItem($event, element, i)"></mat-radio-button>
                                                    </ng-container>
                                                </ng-container>
                                                                                                 
                                            </td>
                                        </ng-container>                                       
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsPista"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsPista;"></tr>
                                    </table>
                                </div>                              

                                <div class="table-responsive" *ngIf="!tipoPista" >
                                    <table *ngIf="delegacionesPorModulo[i].length > 0" mat-table [dataSource]="delegacionesPorModulo[i]" class="mat-elevation-z8 custom-table">
                                        <!-- Columns -->
                                        <ng-container *ngFor="let column of displayedColumnsOnline" [matColumnDef]="column"x>
                                            <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
                                            <td mat-cell *matCellDef="let element" [ngSwitch]="column" [ngClass]="{'date-out-of-range': isDateOutOfRange(element.fecha) && isDateOutOfRange(element.fechaVencimiento)}">
                                                <ng-container *ngSwitchCase="'Curso'">{{ element.cursoNombre }}</ng-container>
                                                <ng-container *ngSwitchCase="'Descripción'">{{ element.descripcion }}</ng-container>
                                                <ng-container *ngSwitchCase="'Fecha'">{{ element.fecha | date: 'dd/MM/yyyy' }}</ng-container>
                                                <ng-container *ngSwitchCase="'Inscripción Desde'">{{ element.fecha | date: 'dd/MM/yyyy' }}</ng-container>
                                                <ng-container *ngSwitchCase="'Inscripción Hasta'">{{ element.fechaVencimiento | date: 'dd/MM/yyyy' }}</ng-container>
                                                
                                                <ng-container *ngSwitchCase="'Seleccionar'">
                                                    <ng-container *ngIf="!isRadioButtonDisabled(element)">
                                                      <mat-radio-button [checked]="isSelected(element, i)" (click)="selectItem($event, element, i)"></mat-radio-button>
                                                    </ng-container>
                                                </ng-container>                                                                                                 
                                            </td>
                                        </ng-container>     
                                
                                        <!-- Rows -->
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsOnline"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsOnline;"></tr>
                                    </table>
                                </div>                                                            
                            </div>
                        </div>
                    </div>           
                                                       
                    <div class="card bg-danger"
                         *ngIf="selectedModulo && this.delegaciones.length === 0 ">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <h3 style="color: #f0f8ff;">No hay delegaciones</h3>
                                </div>
                            </div>
                            <table>
                                <tr>
                                    <td colspan="4"
                                        class="text-center"
                                        style="color: #f0f8ff;"><strong>No existen delegaciones asignadas
                                            para
                                            este curso.</strong>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <br>

                    <div class="col-md-12" *ngIf = "!this.tableDocuments.length <= 0">
                        <h3>Cargue la documentación Personal</h3>
                        <div class="alert alert-info">
                            <strong>La documentación es opcional, de no cargarla se la solicitaremos
                                más
                                adelante</strong>
                        </div>
                    </div>
                    
                    <div class="col-md-12"  *ngIf = "this.tableDocuments.length <= 0">
                        <div class="alert alert-success">
                            <strong>Su documentación ya se encuentra en nuestro sistema, no es necesario que la vuelva a cargar.</strong>
                        </div>
                    </div>


                    <table class="table custom-table">
                        <tr class="file-upload-container">
                            <ng-container *ngFor="let document of tableDocuments; let i = index">

                                <ng-container *ngIf="i %  3 ===  0">
                                    <td colspan="3"
                                        class="text-center"
                                        *ngIf="document.message">
                                        <strong style="font-size:  18px; color: brown;">{{ document.message
                                            }}</strong>
                                    </td>

                                    <td colspan="9"
                                        *ngIf="document.message"></td>
                                </ng-container>


                                <td class="text-center file-upload"
                                    *ngIf="!document.message"
                                    [class.active]="document.id === activeDocumentId"
                                    [style.border-color]="fileSelected && document.id === activeDocumentId ? '#14e970' : '#dce4ec'">
                                    <div class="file-upload">
                                        <div class="file-select"
                                             (click)="openFileInput(document.id)"
                                             >
                                            <div class="file-select-button"
                                                 id="fileName">{{document.name}}
                                            </div>
                                            <div class="file-select-name"
                                                 id="noFile">
                                                {{
                                                selectedFileNames[document.id]
                                                || 'No se selecciono ningún
                                                archivo...'
                                                }}
                                            </div>
                                            <input type="file"
                                            [id]="'i'+ document.id"
                                            [name]="'i'+ document.id"
                                            style="display: none"
                                            accept=".png,.jpg,.jpeg,.pdf" 
                                            (change)="onFilesSelected($event, document.id)">    
                                                   
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                        <br>                       
                    </table>

                    <mat-divider></mat-divider>

                    <br>
                    <br>
            
                    <div class="row">
                        <div class="col-md-12">
                            <p style="text-align: justify;"> <!-- Tu texto -->
                            <p style="text-align: justify;">La AADIDESS y el I.S.E.S aclaran que el
                                presente
                                curso es
                                esencialmente evaluativo y dada la corta duración del mismo,
                                requiere una
                                profunda y previa
                                capacitación en la asimilación del lineamiento técnico de la Técnica
                                de
                                Compensación de
                                esquí ó snowboard. Así se requiere a los aspirantes a instructores
                                una
                                experiencia
                                suficiente dentro de los últimos diez años. El abajo firmante
                                declara bajo
                                juramento que los
                                antecedentes consignados son auténticos y pone a disposición de la
                                AADIDESS
                                y el I.S.E.S los
                                respectivos comprobantes en caso de que sean requeridos. Habiendo
                                sido
                                enterado
                                detalladamente de la actividad que el curso comprende y considerando
                                que la
                                misma es
                                naturalmente riesgosa, dadas sus características propias al ámbito
                                en el
                                cual se desarrolla,
                                el alumno/padre/tutor/encargado firmante asume para si esos riesgos
                                y
                                desliga toda
                                responsabilidad por los hechos que puedan ocurrir, tomando incluso a
                                su
                                entero cargo las
                                eventualidades derivadas del caso fortuito o fuerza mayor (Art. 958,
                                959 y
                                1733 Código Civil
                                y Comercial), tanto en cuanto a su salud, como en la ocurrencia de
                                accidentes de montaña
                                durante el desarrollo de los cursos, sea respecto de la AADIDESS y
                                del
                                I.S.E.S., de sus
                                miembros directivos como de profesores, instructores, colaboradores,
                                etc. Es
                                responsabilidad
                                de cada cursante contar con una cobertura médica o seguro personal
                                contra
                                accidentes.
                                Declaro encontrarme en perfectas condiciones psicofísicas y técnicas
                                para
                                participar del
                                curso. Desarrollándose las actividades en buena parte al aire libre
                                y en
                                zona montañosa, la
                                programación prevista está condicionada por los factores climáticos
                                y del
                                terreno, pudiendo
                                la Asociación o el Consejo Académico del I.S.E.S. modificarla total
                                o
                                parcialmente en cuanto
                                a su duración, fechas, horarios, distribución y características.
                                Declaro
                                aceptar y respetar
                                el Reglamento Académico del I.S.E.S y las normas y reglamentos de
                                AADIDESS.
                                Declaro que mi
                                equipo de esquí o snowboard se encuentra funcionando en perfecto
                                estado. NO
                                se permiten
                                esquíes doble punta o fat. El pase para los medios de elevación
                                corre por
                                cuenta de cada
                                participante. Inscribirse y cursar el módulo técnico no implica la
                                aprobación del módulo
                                pedagógico de 2º, 3º y 4º año. Los cursantes aceptan esta modalidad
                                que, en
                                caso de
                                reprobar, recursar una materia o módulo o solicitar prórroga deberán
                                adaptarse al calendario
                                vigente. Uso de imágenes y video: Quedan expresamente reservados
                                para la
                                AADIDESS todos los
                                derechos de comercialización, uso y reproducción, sobre las
                                imágenes, fotos,
                                videos que se
                                produzcan originen y generen en forma parcial o conjunta durante el
                                curso.
                                Uso obligatorio
                                del casco en todo evento académico sobre nieve.
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-end">
                            <mat-checkbox formControlName="aceptarTerminos"
                                          color="primary"
                                          style="margin-right: 10px;"></mat-checkbox>
                            <label for="aceptarTerminos"
                                   style="margin-top: 6px;">Acepto términos y condiciones</label>
                        </div>
                    </div>
            
                    <!-- En tu formulario, al final, donde envías el formulario -->                                  
                    <br>
                    <div mat-dialog-actions align="end">
                        <button mat-raised-button color="primary"
                            [disabled]="!myForm.valid || !myForm.get('aceptarTerminos').value || this.flagProcesando"
                            (click)="openConfirmationDialog()" style="margin-right: 5px;">Enviar</button>
                        <button mat-raised-button color="accent" [routerLink]="['/']" routerLinkActive="router-link-active"
                            style="margin-left: 5px;">Cancelar</button>
                    </div>                                    
                    <br>                   
                </form>
                <br>
            </div>
        </div>
    </div>
</div>




