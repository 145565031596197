<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/usuarios-administracion">Configuración Usuarios</a></li>
                <li>Alta de Usuario</li>
            </ul>
            <h2>Nuevo Usuario</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<app-loading *ngIf="this.flagRegistrado"></app-loading>
<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-2 col-md-12"></div>
            <div class="col-lg-8 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="forma" (ngSubmit)="ngSubmit()">

                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Nombre Persona</mat-label>
                                <input formControlName="nombrePersona" [class.is-invalid]="nombrePersona" matInput placeholder="Ingrese un nombre" autocomplete="off">
                                <small *ngIf="nombrePersona" class="text-danger">Ingrese el nombre</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Apellido Persona</mat-label>
                                <input formControlName="apellidoPersona" [class.is-invalid]="apellidoPersona" matInput placeholder="Ingrese un apellido" autocomplete="off">
                                <small *ngIf="apellidoPersona" class="text-danger">Ingrese el nombre</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Nickname</mat-label>
                                <input formControlName="nickname" [class.is-invalid]="nickname" matInput placeholder="Ingrese un nickname" autocomplete="off">
                                <small *ngIf="nickname" class="text-danger">Ingrese el nickname</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Email - ATENCIÓN: debe ser una cuenta valida (usuario con el que se ingresa al sistema)</mat-label>
                                <input formControlName="email" [class.is-invalid]="email" matInput placeholder="Ingrese un email" autocomplete="off">
                                <small *ngIf="email" class="text-danger">Ingrese el email</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Repita Email</mat-label>
                                <input formControlName="emailConfirm" [class.is-invalid]="emailConfirm" matInput placeholder="repita el  email" autocomplete="off" (cut)="$event.preventDefault()" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()">
                                <small *ngIf="confirmEmail" class="text-danger">repita el email</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field appearance="fill" [style.width.%]=100>
                                <mat-label>perfil</mat-label>
                                <mat-select formControlName="perfil" [class.is-invalid]="perfil">
                                    <mat-option *ngFor="let p of selectPerfiles" value="{{ p._id }}">{{ p.descripcion }}</mat-option>
                                </mat-select>
                                <small *ngIf="perfil" class="text-danger">Seleccione un perfil</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <label class="form-label">Contraseña</label>
                            <input class="form-control" type="text" formControlName="password" [class.is-invalid]="password" placeholder="ingrese contraseña" autocomplete="off">
                            <small *ngIf="password" class="text-danger">La contraseña debe ser de al menos 8 caracteres. Para hacerla más segura, utilice mayúsculas. minúsculas, números y símbolos como ! \" ? $ % ^ & )</small>
                        </div>

                        <div class="form-group">
                            <label class="form-label">Confirme contraseña</label>
                            <input class="form-control" type="text" formControlName="confirmPassword" [class.is-invalid]="confirmPassworValido" placeholder="repita contraseña" autocomplete="off" autocomplete="off" (cut)="$event.preventDefault()" (copy)="$event.preventDefault()"
                                (paste)="$event.preventDefault()">
                            <small *ngIf="confirmPassword" class="text-danger">La contraseña debe ser de al menos 8 caracteres. Para hacerla más segura, utilice mayúsculas. minúsculas, números y símbolos como ! \" ? $ % ^ & )</small>
                        </div>

                        <button [disabled]="!forma.valid || confirmEmail || confirmPassworValido || flagRegistrado" type="submit">Guardar</button>

                        <a routerLink="/usuarios-administracion"> <button [disabled]="flagRegistrado" type="button">Cancelar</button></a>
                        <br>
                        <br>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>