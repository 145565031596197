import { environment } from './../../../environments/environment';
import { Pipe, PipeTransform } from '@angular/core';

const URL_BACKEND = environment.urlBackend;

@Pipe({
  name: 'mediaCursosPipe'
})
export class MediaCursosPipe implements PipeTransform {

  /**
   * Debe recibir el type: 1 (img), 2 (mp4) o 3 (pdf) y el valor ejemplo nombre_img.png / nombre_vide.mp4 / nombre_pdf.pdf
   * @param type
   * @param value
   * @returns
   */
  transform( type: number, value: string ): string {
    return `${ URL_BACKEND }/media/getUrlMediaCursos/${ type }/${ value }`;
  }

}
// EXAMPLE http://192.168.0.103:3002/post/imagen/5ec6bbcb022e2826b8da7b2e/diakdu54kkailuggy.5ec6bbcb022e2826b8da7b2e.jpg
