<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/home">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li>Listado de Cursos asociados a Alumnos</li>
            </ul>
            <h2>Alumnos inscriptos</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70">
    <div class="container" style="max-width: 1500px !important;">
        <div class="profile-courses-quizzes">
            <h3>Listado de Cursos asociados a Alumnos</h3>
            <hr>
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Nro. Solicitud, Nombre, Apellido..." autocomplete="off" #input>
            </mat-form-field>

            <div class="mat-elevation-z8">

                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Column -->
                    <ng-container matColumnDef="nroSolicitud">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Solicitud </th>
                        <td mat-cell *matCellDef="let element"> {{ element.nroSolicitud }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="apellidoPersona">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido </th>
                        <td mat-cell *matCellDef="let element"> {{ element.apellidoPersona }} </td>
                    </ng-container>

                    <ng-container matColumnDef="nombrePersona">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{ element.nombrePersona }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="curso">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Curso </th>
                        <td mat-cell *matCellDef="let element"> {{element.curso}} </td>
                    </ng-container>
                    <!-- Column -->

                    <ng-container matColumnDef="precio">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
                        <td mat-cell *matCellDef="let element"> {{element.precio}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="fechaInscripcion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Pre Inscripcion </th>
                        <td mat-cell *matCellDef="let element"> {{element.fechaInscripcion | date:"dd/MM/yy"}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="aprobado">
                        <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> Finalizado </th>
                        <td width="10%" mat-cell *matCellDef="let element">
                            <mat-slide-toggle (click)="aprobarCurso(!element.aprobado,element.id)" [(ngModel)]="element.aprobado" [checked]="element.aprobado"></mat-slide-toggle>
                        </td>
                    </ng-container>

                    <!-- Column -->
                    <!-- <ng-container matColumnDef="fechaAprobacion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Aprobación </th>
                        <td mat-cell *matCellDef="let element"> {{element.fechaAprobacion | date:"dd/MM/Y"}} </td>
                    </ng-container> -->

                    <!-- Column -->
                    <!-- <ng-container matColumnDef="desaprobado">
                        <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> Desaprobado </th>
                        <td width="10%" mat-cell *matCellDef="let element">
                            <mat-slide-toggle (click)="desaprobarCurso(!element.desaprobado, element.id)" [(ngModel)]="element.desaprobado" [checked]="element.desaprobado"></mat-slide-toggle>
                        </td>
                    </ng-container> -->

                    <!-- Column -->
                    <!-- <ng-container matColumnDef="fechaDesaprobado">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Desaprobado </th>
                        <td mat-cell *matCellDef="let element"> {{element.fechaDesaprobado | date:"dd/MM/Y"}} </td>
                    </ng-container> -->

                    <!-- Column -->
                    <!-- <ng-container matColumnDef="finalizado">
                        <th width="10%" mat-header-cell *matHeaderCellDef mat-sort-header> Curso Finalizado </th>
                        <td width="10%" mat-cell *matCellDef="let element">
                            <mat-slide-toggle [(ngModel)]="element.finalizado" [checked]="element.finalizado"></mat-slide-toggle>
                        </td>
                    </ng-container> -->

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[50, 100, 200, 400, 800, 1000]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>
</div>