<div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
<div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
<div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>


<app-loading *ngIf="!this.flagLoaded" class="m-5"></app-loading>

<div *ngIf="this.flagLoaded" class="andorra-apply-content ptb-70 animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-10 col-md-12">
                <div class="apply-form">
                    <mat-card class="material-card">

                        <app-loading *ngIf="this.procesando" class="m-5"></app-loading>
                        <mat-label *ngIf="this.procesando">Procesando...</mat-label>

                        <mat-card-content *ngIf="this.isEditable">

                            <mat-card-header>
                                <mat-card-title>Preguntas</mat-card-title>
                                <mat-card-title>Título Examen: {{ tituloExamen }}</mat-card-title>
                            </mat-card-header>

                            <mat-card-content>
                                ATENCIÓN - Campo Puntaje: El valor del campo puntaje por definicion, para cada pregunta es 1(uno). Si se asigna un puntaje distinto, ejemplo: 2, 3, 4...n, el sistema aplicara dicho valor al momento del calculo de aprobación.
                            </mat-card-content>
                            <br>
                            <!-- CATEGORIA -->
                            <table *ngIf="!flagContinuarRespuestas">
                                <tr>
                                    <td width="80%">
                                        <mat-form-field appearance="fill" style="width: 475px;">
                                            <mat-label>Tipo de pregunta</mat-label>
                                            <mat-select [(ngModel)]="selectedType">
                                                <mat-option *ngFor="let pregunta of tiposPregunta" [value]="pregunta.value">
                                                    {{pregunta.viewValue}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <button (click)="agregarPregunta(selectedType)" mat-raised-button color="accent">
                                            <mat-icon>plus_one</mat-icon>
                                            Agregar Pregunta</button>
                                    </td>
                                </tr>
                            </table>

                            <mat-divider></mat-divider>
                            <br>

                            <!-- PREGUNTAS -->
                            <form [formGroup]="forma">
                                <cdk-virtual-scroll-viewport *ngIf="preguntas.controls.length > 0" appendOnly itemSize="50" class="scroll-viewport">
                                    <table>
                                        <thead>
                                            <th>N°</th>
                                            <th>Tipo</th>
                                            <th>Pregunta</th>
                                            <th>JPG/PNG-PDF-MP4 <i class="fas fa-photo-video"></i></th>
                                            <th>Puntaje</th>
                                            <!-- <th>Orden</th> -->
                                            <th>Obl.</th>
                                            <th *ngIf="!this.flagContinuarRespuestas">File</th>
                                            <th *ngIf="!this.flagContinuarRespuestas">Preg.</th>
                                        </thead>
                                        <tbody formArrayName="preguntas">
                                            <tr *ngFor="let control of preguntas.controls; let i=index">
                                                <td>{{ i+1 }}</td>
                                                <td>
                                                    <mat-form-field appearance="fill" style="width: 40px;">
                                                        <input disabled cols="10" [formControl]="tipos.controls[i]" matInput type="text">
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="fill" style="width: 250px;">
                                                        <mat-label>Enunciado</mat-label>
                                                        <textarea rows="1" cols="20" [formControl]="preguntas.controls[i]" matInput></textarea>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <input *ngIf="tipos.controls[i].value === 'MC'" (change)="onFileChange($event,i)" [formControl]="file.controls[i]" type="file" accept="image/jpeg, image/jpg, image/png, application/pdf, video/mp4">
                                                    <mat-label *ngIf="tipos.controls[i].value !== 'MC'">Multimedia NO activa.</mat-label>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="fill" style="width: 60px;">
                                                        <input [formControl]="puntaje.controls[i]" matInput type="number">
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-slide-toggle [formControl]="obligatoria.controls[i]"></mat-slide-toggle>
                                                </td>
                                                <td *ngIf="!this.flagContinuarRespuestas">
                                                    <button (click)="borraraArchivoCargado(i)" mat-mini-fab color="warn" aria-label="Example icon button with a filter list icon">
                                                        <mat-icon>delete</mat-icon>
                                                      </button>
                                                </td>
                                                <td *ngIf="!this.flagContinuarRespuestas">
                                                    <button (click)="borrarPregunta(i)" mat-mini-fab color="warn" aria-label="Example icon button with a filter list icon">
                                                        <mat-icon>delete</mat-icon>
                                                      </button>
                                                </td>
                                                <br>
                                            </tr>
                                        </tbody>
                                    </table>
                                </cdk-virtual-scroll-viewport>

                            </form>
                            <br>

                            <!-- <mat-card-subtitle class="red-text" *ngIf="!flagFormaValido">
                                El boton "Guardar Preguntas" se activa cuando los campos son correctos.
                            </mat-card-subtitle> -->

                            <p *ngIf="this.flagContinuarRespuestas">
                                Preguntas guardadas correctamente. Puede continuar con las respuestas.
                            </p>

                        </mat-card-content>

                        <!-- PROCESOS -->
                        <mat-card-actions>

                            <button *ngIf="flagFormaValido" (click)="cargarPreguntas()" class="default-btn-style-two animate__animated animate__fadeIn" type="submit"><i class="fas fa-file"></i>Cargar Preguntas</button>

                            <div *ngIf="this.flagFin">
                                <blockquote class="blockquote">
                                    Preguntas cargadas...
                                </blockquote>

                                <div class="row justify-content-center">
                                    <div class="col-lg-3 col-sm-6 col-md-6">
                                        <div class="single-features-box">
                                            <div class="icon">
                                                <i class="flaticon-shield-1"></i>
                                            </div>
                                            <h3>PROCESADO</h3>
                                            <p>Sus preguntas se procesaron correctamente.</p>
                                            <a class="link-btn">puede continuar..</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="this.procesandoArchivos">
                                <blockquote class="blockquote">
                                    <div> Procesando archivos...</div>
                                    <div class="progress" style="height: 20px;">
                                        <div class="progress-bar bg-info" role="progressbar" [style.width]="this.value + '%'" aria-valuenow="this.value" aria-valuemin="0" aria-valuemax="100">{{this.value}}%</div>
                                    </div>
                                </blockquote>
                            </div>

                            <div class="row" *ngIf="this.flagFin">
                                <div class="col-lg-2 col-md-2">
                                    <br>
                                    <button (click)="aceptarPreguntas()" type="button" class="default-btn-style-two animate__animated animate__fadeIn margin-left"><i class="fas fa-file"></i>Aceptar</button>
                                </div>
                            </div>

                        </mat-card-actions>

                    </mat-card>


                </div>
            </div>
        </div>
    </div>
</div>