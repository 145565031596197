<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li>Cursos</li>
            </ul>
            <h2>Cursos</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70 contenido-tabla">
    <div class="container">

        <div class="profile-courses-quizzes">
            <h3>Listado de Cursos Disponibles</h3>

            <a routerLink="/curso-alta"><button mat-raised-button mat-button color="primary"><mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>Nuevo Curso</button></a>
            <br>
            <hr>
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Curso..">
            </mat-form-field>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <!-- Column -->
                <ng-container matColumnDef="nombre">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                    <td mat-cell *matCellDef="let elemento"> {{elemento.nombre}} </td>
                </ng-container>


                <!-- Column -->
                <ng-container matColumnDef="introduccion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Introducción Corta </th>
                    <td mat-cell *matCellDef="let elemento"> {{elemento.introduccion }} </td>
                </ng-container>

                <!-- Column 
                <ng-container matColumnDef="descripcion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                    <td mat-cell *matCellDef="let elemento"> {{elemento.descripcionGeneral }} </td>
                </ng-container>-->

                <!-- Column -->
                <ng-container matColumnDef="fechaInicio">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Inicio </th>
                    <td mat-cell *matCellDef="let elemento"> {{elemento.fechaInicio | date:"dd/MM/yy"}} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="fechaFin">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Fin </th>
                    <td mat-cell *matCellDef="let elemento"> {{elemento.fechaFin | date:"dd/MM/yy"}} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="precio">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
                    <td mat-cell *matCellDef="let elemento"> ${{elemento.precio}} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="disponible">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Disponible </th>
                    <td mat-cell *matCellDef="let elemento"> {{elemento.disponible }} </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Editar </th>
                    <td mat-cell *matCellDef="let elemento">
                        <button (click)="editarCurso(elemento.curso)" type="button" class="btn btn-warning btn-small"><i class="fas fa-edit"></i></button>
                    </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="pista">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Presencial </th>
                    <td mat-cell *matCellDef="let elemento">
                        {{ elemento.pista ? 'Si' : 'No' }}
                    </td>
                </ng-container>
                
                <!-- Column -->
                <ng-container matColumnDef="probar">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Probar </th>
                    <td mat-cell *matCellDef="let elemento">
                        <button (click)="probarCurso(elemento.curso)" type="button" class="btn btn-success btn-small"><i class="fas fa-play"></i></button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-elemento-row"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

        </div>
    </div>
</div>