import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { InvoicesService } from 'src/app/services/shared/invoices.service';
import { Invoice } from 'src/app/interfaces/interface-bakend/invoices/invoices.interfaces';
import { InvoiceDetalleComponent } from './invoice-detalle/invoice-detalle.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Translators } from 'src/app/services/shared/translators.service';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { BankTransferService } from 'src/app/services/shared/bank-transfer.service';
import { ComprobanteTransfDetalleComponent } from 'src/app/components/common/comprobantes-transf-listado/comprobante-transf-detalle/comprobante-transf-detalle.component';
import { BankTransfer } from 'src/app/interfaces/interface-bakend/invoices/bankTransfer.interfaces';
import Swal from 'sweetalert2';
import { SolicitudesService } from 'src/app/services/ofertas-laborales/solicitudes.service';
import { APP_CONFIG_OFERTAS, IAppOfertas } from 'src/app/app.config/app.config-ofertas';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { Solicitud } from 'src/app/interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-invoices-listado',
  templateUrl: './invoices-listado.component.html',
  styleUrls: ['./invoices-listado.component.scss']
})
export class InvoicesListadoComponent implements OnInit, AfterViewInit {

    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] =
    [
        // 'nro', 'persona', 'email', 'fecha', 'actividad', 'pagado', 'comprobanteTransferencia', 'importeTotal', 'invoiceIDmp', 'invoiceIDtr', 'link', 'facturado'
        // tslint:disable-next-line:max-line-length
        'nro', 'persona', 'email', 'fecha', 'actividad', 'pagadoMacro', 'pagado', 'comprobanteTransferencia', 'importeTotal', 'invoiceIDmp', 'invoiceIDtr', 'link', 'facturado', 'efectivo', 'cheque', 'aprobar', 'enEspera',
    ];
    dataSource: any;
    invoices: any[] = [];
    /* Fin Data Table*/

    flagLoaded = false;
    flagLoadedModal = true;
    isLoading = false;
    idioma: Subscription;

    fechaDesde: Date;
    fechaHasta: Date;

    solicitud: Solicitud;

    cantidad = 0;

    dataSourceLength = 0;

    applyFilterFlagNoFacturado = false; // Flag para aplicar o desactivar el filtro de Pagado/Comprobante No Facturado
    applyFilterFlagFacturado = false; // Flag para aplicar o desactivar el filtro de Pagado/Comprobante Facturado
    applyNoPagadoFilterFlag = false; // Flag para aplicar o desactivar el filtro de No Pagado
    applyFilterFlagPagadoMP = false; // Flag para aplicar o desactivar el filtro de Pagado MP
    applyFilterFlagPagadoTransferencia = false; // Flag para aplicar o desactivar el filtro de Pagado Transferencia
    applyFilterFlagPagadoMacro = false; // Inicializa la propiedad

    label: string;


    constructor(
        private invoicesService: InvoicesService,
        public dialog: MatDialog,
        private translatorService: Translators,
        private perfilesService: PerfilesService,
        private router: Router,
        private bankTransferService: BankTransferService,
        private solicitudesService: SolicitudesService,
        private cursosService: CursosService,
        private configBeginService: ConfigBeginService,
        private usuarioService: UsuarioService,
        private snackBar: MatSnackBar,
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,

    ) {

        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
        });

        const hoy = new Date();
        this.fechaDesde = hoy;
        this.fechaHasta = hoy;
    }

    ngOnInit(): void {
        this.perfilesService.allowedByUserLogon(this.router.url);
    }

    async ngAfterViewInit() {
        // await this.verInvoices();
    }

    async verInvoiceMp(invoiceID) {
        this.isLoading = true; // Iniciar el estado de carga
        this.flagLoadedModal = false;
        try {
            await this.invoicesService.getInvoiceById(invoiceID).then(((invoice: Invoice) => {            
                this.invoicesService.setInvoiceSeleccionado(invoice);
                // modal
                this.openDialogModaSolicitudMercadoPago();
            }));
        } catch (error) {
            alert('Error al obtener el comprobante de MP');
        } finally {
                this.isLoading = false; // Detener el estado de carga
            }
    }

    openDialogModaSolicitudMercadoPago(): void {
        const dialogRef = this.dialog.open(InvoiceDetalleComponent, {
            width: '550px',
            height: '900px',
            // disableClose: true,
        });
        this.flagLoadedModal = true;
        dialogRef.afterClosed().subscribe(async result => {});
        return;
    }

    async verInvoiceBankTransfer(comprobanteID: string, invoiceID: string) {

        if (comprobanteID && invoiceID) {
            this.isLoading = true; // Iniciar el estado de carga
            this.flagLoadedModal = false;

            try {
                // guardo invoice para ver la observacion luego de adminsitracion
                this.invoicesService.setInvoiceSeleccionado(await this.invoicesService.getInvoiceById(invoiceID));

                // comienzo proceso para ver comprobante de banco
                const bankTransfer = await this.bankTransferService.getBankTransferById(comprobanteID);
                this.bankTransferService.setBankTransferSeleccionado(bankTransfer);
                this.openDialogModaSolicitudBankTransfer();
            } catch (error) {
                alert('Error al obtener el comprobante de transferencia bancaria.');
            } finally {
                this.isLoading = false; // Detener el estado de carga
            }
        } else {
            alert('Este comprobante NO esta disponible es anterior a "12/05/24"- Busque el comprobante en TRANSFERENCIAS BANCARIAS.');
        }
    }


    openDialogModaSolicitudBankTransfer(): void {
        const dialogRef = this.dialog.open(ComprobanteTransfDetalleComponent, {
            width: '900px',
            height: '750px',
            // disableClose: true,
        });
        this.flagLoadedModal = true;
        dialogRef.afterClosed().subscribe(async result => {});
        return;
    }

    // async formatDataTable(invoices: any[], opcion: number): Promise<any[]> {
    //     const format: any[] = [];

    //     // Configurar el filterPredicate para el filtro "No Pagado"
    //     const filterPredicate = (data: any) => {
    //         if (this.pagadoFiltro === false) {
    //         return !data.pagado && !data.comprobanteTransferencia;
    //         }
    //         return true;
    //     };


    //     for (const invoice of invoices) {
    //         const tieneEstado6 = invoice?.solicitud?.states?.some(state => state.numberState === 6);

    //         if (invoice?.solicitud?.lastState) { console.log(invoice?.solicitud?.lastState); }

    //         const invoiceData = {
    //         solicitud: invoice.solicitud,
    //         fecha: invoice.createdAt,
    //         nro: invoice.solicitud._nro,
    //         pagado: invoice.pagado,
    //         importeTotal: invoice.importeTotal,
    //         persona: `${invoice.usuario.nombrePersona} ${invoice.usuario.apellidoPersona}`,
    //         email: invoice.usuario.email,
    //         actividad: invoice.solicitud?.actividad?.nombre,
    //         invoiceID: invoice._id,
    //         link: invoice?.mercadoPagoPreference?.init_point,
    //         facturado: invoice?.facturado,
    //         comprobanteTransferencia: tieneEstado6,
    //         _id: invoice._id,
    //         idComprobanteTr: invoice.solicitud.comprobanteTransferenciaBancaria?._id,
    //         lastState: invoice?.lastState,
    //         idSolicitud: invoice.solicitud._id,
    //         efectivo: invoice?.efectivo,
    //         cheque: invoice?.cheque,
    //         };

    //         if (filterPredicate(invoiceData)) {
    //             format.push(invoiceData);
    //         }

    //     }

    //     return format;
    // }

    async formatDataTable(invoices: any[]): Promise<any[]> {
        const format: any[] = [];


        for (const invoice of invoices) {

            const tieneEstado6 = invoice?.solicitud?.states?.some(state => state.numberState === 6);

            if (invoice?.solicitud?.lastState) {
                console.log(invoice?.solicitud?.lastState);
            }

            const invoiceData = {
                solicitud: invoice.solicitud,
                fecha: invoice.createdAt,
                nro: invoice.solicitud._nro,
                pagado: invoice.pagado,
                importeTotal: invoice.importeTotal,
                persona: `${invoice.usuario.nombrePersona} ${invoice.usuario.apellidoPersona}`,
                email: invoice.usuario.email,
                actividad: invoice.solicitud?.actividad?.nombre,
                invoiceID: invoice._id,
                link: invoice?.mercadoPagoPreference?.init_point,
                facturado: invoice?.facturado,
                comprobanteTransferencia: tieneEstado6,
                _id: invoice._id,
                idComprobanteTr: invoice.solicitud.comprobanteTransferenciaBancaria?._id,
                lastState: invoice?.lastState,
                idSolicitud: invoice.solicitud._id,
                efectivo: invoice?.efectivo,
                cheque: invoice?.cheque,
                tipoPayment: invoice?.tipoPayment
            };

            console.log("invoiceData::",invoiceData)


            format.push(invoiceData);
        }

        return format;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    copiarEnlace(link: string) {
        navigator.clipboard.writeText(link).then(() => {
            console.log('Enlace copiado al portapapeles: ', link);
            // Puedes agregar aquí una notificación o mensaje de confirmación si lo deseas
        }).catch((error) => {
            console.error('Error al copiar enlace: ', error);
            // Puedes manejar aquí el caso de error, como mostrar un mensaje al usuario
        });
    }

    async updateInvoiceFacturado(element: any) {

        const invoice: any  = await this.invoicesService.getInvoiceById(element._id);

        const invoiceUpdate: Invoice = {
            _id: invoice._id,
            tipo: invoice.tipoInvoice ,
            invoice: {
                usuario: invoice.usuario,
                observaciones: invoice.observaciones,
                importeNeto: invoice.importeNeto,
                facturado: element.facturado
             }
        };

        await this.invoicesService.updateInvoice(invoiceUpdate)
        .then(updatedInvoice => {
            console.log('Invoice updated successfully:');
        })
        .catch(error => {
            console.error('Error updating invoice:', error);
        });
    }

    async updateInvoiceEfectivo(element: any) {

        const invoice: any  = await this.invoicesService.getInvoiceById(element._id);

        const invoiceUpdate: Invoice = {
            _id: invoice._id,
            tipo: invoice.tipoInvoice ,
            invoice: {
                usuario: invoice.usuario,
                observaciones: invoice.observaciones,
                importeNeto: invoice.importeNeto,
                efectivo: element.efectivo
             }
        };

        await this.invoicesService.updateInvoice(invoiceUpdate)
        .then(updatedInvoice => {
            console.log('Invoice updated successfully:');
        })
        .catch(error => {
            console.error('Error updating invoice:', error);
        });
    }

    async updateInvoiceCheque(element: any) {

        const invoice: any  = await this.invoicesService.getInvoiceById(element._id);

        const invoiceUpdate: Invoice = {
            _id: invoice._id,
            tipo: invoice.tipoInvoice ,
            invoice: {
                usuario: invoice.usuario,
                observaciones: invoice.observaciones,
                importeNeto: invoice.importeNeto,
                cheque: element.cheque
             }
        };

        await this.invoicesService.updateInvoice(invoiceUpdate)
        .then(updatedInvoice => {
            console.log('Invoice updated successfully:');
        })
        .catch(error => {
            console.error('Error updating invoice:', error);
        });
    }

    async buscarPorFechas() {

        if (this.fechaDesde && this.fechaHasta) {
            this.flagLoaded = true;
            this.invoices = [];
            this.applyFilterFlagNoFacturado = false; // Flag para aplicar o desactivar el filtro de Pagado/Comprobante No Facturado
            this.applyFilterFlagFacturado = false; // Flag para aplicar o desactivar el filtro de Pagado/Comprobante Facturado
            this.applyNoPagadoFilterFlag = false; // Flag para aplicar o desactivar el filtro de No Pagado
            this.applyFilterFlagPagadoMP = false; // Flag para aplicar o desactivar el filtro de Pagado MP
            this.applyFilterFlagPagadoTransferencia = false; // Flag para aplicar o desactivar el filtro de Pagado Transferencia

            const searchParams = {
                type: 'OFERTA_LABORAL',
                fechaDesde: this.formatDate(this.fechaDesde),
                fechaHasta: this.formatDate(this.fechaHasta),
            };

            await this.invoicesService.findAllByTypeAndFecha(searchParams).then((result: any) => {
                if (result.length === 0) {
                    // Mostrar mensaje de que no hay facturas en las fechas indicadas
                    Swal.fire({
                        icon: 'info',
                        title: 'No hay invoices',
                        text: 'No se encontraron invoices en las fechas indicadas.',
                        confirmButtonText: 'Entendido'
                    });
                } else {
                    console.log('Resultados de la búsqueda:', result);
                    this.formatDataTable(result).then((resp: any[]) => {
                        this.invoices = result;
                        this.dataSource = new MatTableDataSource(resp);
                        this.dataSource.sort = this.sort;
                        this.dataSource.paginator = this.paginator;
                        this.dataSourceLength = this.dataSource.data.length; // Guarda la longitud del dataSource

                    });
                }
                this.flagLoaded = false;

            }).catch((error) => {
                this.flagLoaded = false;
                console.error('Error en la búsqueda:', error);
            });
        } else {
            this.flagLoaded = false;
            console.log('Por favor, seleccione ambas fechas.');
        }
    }


    formatDate(date: Date): string {
        return date.toISOString().split('T')[0];
    }

    // En el método de la clase PopupsAltaComponent:

    confirmarAprobar(event: any, element: any) {
        const toggle = event.source;
        Swal.fire({
            title: '¿Está seguro?',
            text: '¿Desea aprobar esta solicitud? se le activara el curso al alumno.  El cambio de estado será comunicado por correo electrónico.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, aprobar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                // tslint:disable-next-line:max-line-length
                this.actualizarEstadoSolicitud(true, element.idSolicitud, this.iAppOfertas.estado_7.number, this.iAppOfertas.estado_7.name, true);
                Swal.fire(
                '¡Aprobado!',
                'La solicitud ha sido aprobada correctamente.',
                'success'
            );
            }  else {
                // Restaura el estado del toggle
                toggle.checked = false;
            }
        }).catch(() => {
            // Restaura el estado del toggle en caso de que haya un error
            toggle.checked = false;
        });
    }

   confirmarEnEspera(event: any, element: any) {
        const toggle = event.source;
        Swal.fire({
            title: '¿Está seguro?',
            text: '¿Desea poner esta solicitud en espera? El cambio de estado será comunicado por correo electrónico.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, en espera',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                // tslint:disable-next-line:max-line-length
                this.actualizarEstadoSolicitud(false, element.idSolicitud, this.iAppOfertas.estado_10.number, this.iAppOfertas.estado_10.name, true);
                Swal.fire(
                    'En espera',
                    'La solicitud ha sido puesta en espera correctamente.',
                    'success'
                );
            } else {
                // Restaura el estado del toggle
                toggle.checked = false;
            }
        }).catch(() => {
            // Restaura el estado del toggle en caso de que haya un error
            toggle.checked = false;
        });
    }


    // tslint:disable-next-line:max-line-length
    async actualizarEstadoSolicitud(moverDocumentoSolicitudAusuario: boolean, idSolicitud: string, numberState: number, state?: string, notificar?: boolean, observaciones?: string) {

        console.log('actualizarEstadoSolicitud', idSolicitud, numberState, false, state, notificar, observaciones);
        // tslint:disable-next-line:max-line-length
        this.solicitudesService.updateSolicitudState(idSolicitud, numberState, false, state, notificar, observaciones).then( async (solicitudUpdated: Solicitud) => {
            // busco solicitud
            this.solicitud = await this.solicitudesService.getSolicitudById(idSolicitud);

            // CUANDO APRUEBO -> ASIGNO MODULOS DE  INSCCRIPCION SI ES APROBADA
            // tslint:disable-next-line:max-line-length
            if (numberState === this.iAppOfertas.estado_7.number) { this.cursosService.setCursosAlumnoAdministrativo(this.solicitud.cursos, this.solicitud.usuario._id); console.log('==> coloco cursos en usuario'); }

        });

        /*  ############### MOVER DOCUMENTOS SOLICITUD A USUARIO ############### */
        if (moverDocumentoSolicitudAusuario) {
            // MUEVO LOS DOCUMENTOS DE LA SOLICITUD QUE NO ESTAN EN SU LEGAJO - SIEMPRE A PENAS SE VE LA SOLICITUD LOS CONTROLA.
            // tslint:disable-next-line:max-line-length
            const usuarioDocumentos = await this.solicitudesService.moverDocumentosFileSolicitudAusuario(this.solicitud.usuario, this.solicitud.adjuntos);
            console.log('====> movimientos de docuementos a personas',  usuarioDocumentos);
        } else {
            console.log('sin movimientos de docuementos a personas');
        }

        /*  ############### CREO PERSONA EN FACTURACION SI EL ESTADO ES APROBADO Y EL CLIENTE AADIDESS ############### */
        this.crearUsuarioFacturacion(numberState);

    }

    async crearUsuarioFacturacion(numberState: number) {
        const empresa: string = (await this.configBeginService.getConfiguracionGral()).empresa;

        if (empresa === 'AADIDESS' && numberState === this.iAppOfertas.estado_4.number) {

          const usuario: User = {
            nombrePersona: this.solicitud.usuario.nombrePersona,
            apellidoPersona: this.solicitud.usuario.apellidoPersona,
            identificacion: this.solicitud.usuario.identificacion,
            email: this.solicitud.usuario.email
          };

          const flag = await this.usuarioService.crearUsuarioEnFacturacion(usuario);
          const message = flag ? 'Persona ya disponible en sistema de Gestión/Facturación.' : 'Persona NO creado en  Gestión/Facturación.';

          this.mostrarToast(message);
        } else {
          console.log('No creo persona en facturación...');
        }
    }

    mostrarToast(message: string) {
        this.snackBar.open(message, 'Cerrar', {
          duration: 5000, // Duración del toast en milisegundos
          horizontalPosition: 'center', // Posición horizontal del toast
          verticalPosition: 'bottom', // Posición vertical del toast
        });
    }


    filtrarPagadoOComprobanteNoFacturado() {
        if (this.applyFilterFlagNoFacturado) {
            // tslint:disable-next-line:max-line-length
            this.dataSource.filterPredicate = (data: any) => (data.pagado || data.comprobanteTransferencia || data.cheque || data.efectivo) && !data.facturado;
            this.dataSource.filter = 'apply'; // Trigger the filter
        } else {
            this.dataSource.filterPredicate = null;
            this.dataSource.filter = ''; // Reset the filter
        }
        this.dataSourceLength = this.dataSource.filteredData.length;
    }

    filtrarPagadoOComprobanteFacturado() {
        if (this.applyFilterFlagFacturado) {
            // tslint:disable-next-line:max-line-length
            this.dataSource.filterPredicate = (data: any) => (data.pagado || data.comprobanteTransferencia || data.cheque || data.efectivo) && data.facturado;
            this.dataSource.filter = 'apply'; // Trigger the filter
        } else {
            this.dataSource.filterPredicate = null;
            this.dataSource.filter = ''; // Reset the filter
        }
        this.dataSourceLength = this.dataSource.filteredData.length;
    }

    filtrarNoPagado() {
        if (this.applyNoPagadoFilterFlag) {
            // tslint:disable-next-line:max-line-length
            this.dataSource.filterPredicate = (data: any) => !data.pagado && !data.comprobanteTransferencia && !data.cheque && !data.efectivo;
            this.dataSource.filter = 'apply'; // Trigger the filter
        } else {
            this.dataSource.filterPredicate = null;
            this.dataSource.filter = ''; // Reset the filter
        }
        this.dataSourceLength = this.dataSource.filteredData.length;
    }

    filtrarPagadoMP() {
        if (this.applyFilterFlagPagadoMP) {
            this.dataSource.filterPredicate = (data: any) => data.pagado;
            this.dataSource.filter = 'apply'; // Trigger the filter
        } else {
            this.dataSource.filterPredicate = null;
            this.dataSource.filter = ''; // Reset the filter
        }
        this.dataSourceLength = this.dataSource.filteredData.length;
    }

    filtrarPagadoTransferencia() {
        if (this.applyFilterFlagPagadoTransferencia) {
            this.dataSource.filterPredicate = (data: any) => data.comprobanteTransferencia && !data.pagado;
            this.dataSource.filter = 'apply'; // Trigger the filter
        } else {
            this.dataSource.filterPredicate = null;
            this.dataSource.filter = ''; // Reset the filter
        }
        this.dataSourceLength = this.dataSource.filteredData.length;
    }


    filtrarPagadoMacro() {
        if (this.applyFilterFlagPagadoMacro) {
            this.dataSource.filterPredicate = (data: any) => data.pagado && data.tipoPayment === 'MACRO_PAGOS';
            this.dataSource.filter = 'apply'; // Activa el filtro
        } else {
            this.dataSource.filter = ''; // Desactiva el filtro si se desmarca el checkbox
        }
        this.dataSourceLength = this.dataSource.filteredData.length; // Actualiza la longitud de datos filtrados mostrada
    }


}
