import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MesasService } from 'src/app/services/cursos/mesas.service';
import { Mesa } from 'src/app/interfaces/interface-bakend/cursos/mesa.interface';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { MesasUsuariosAsociadosModalComponent } from '../mesas-usuarios-asociados-modal/mesas-usuarios-asociados-modal.component';

@Component({
  selector: 'app-mesas-listado',
  templateUrl: './mesas-listado.component.html',
  styleUrls: ['./mesas-listado.component.scss']
})
export class MesasListadoComponent implements OnInit, AfterViewInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
    [
      'titulo', 'descripcion', 'fechaInicio', 'fechaFin', 'delegacion', 'disciplina', 'nivel', '_id'
    ];
  dataSource: any;
  /* Fin Data Table*/

  flagLoaded = false;


  constructor(
    private mesasService: MesasService,
    private router: Router,
    private guiMsjService: GuiMsjService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadDataPage();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
  }

  async verMesa(id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    await this.mesasService.getMesaFindOne(id);
    this.router.navigateByUrl('/mesa-modificacion');
  }

  async verUsuariosMesa(id: string) {
    this.dialog.open(MesasUsuariosAsociadosModalComponent, {
      height: '700px',
      width: '900px',
      data: {
        mesa: id
      }
    });
  }

  async configurarGestos(id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    await this.mesasService.getMesaFindOne(id);
    this.router.navigateByUrl('/gestos-configuracion');
  }

  async loadDataTable() {
    this.mesasService.getMesas().then((mesas: Mesa[]) => {
      this.dataSource = new MatTableDataSource(mesas);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    });
  }

  /**
 * Título: Duplicar mesa
 * Descripción: Duplica la mesa seleccionada, con la fecha de recuperatorio de la inicial como fecha de mesa
 * Autor: Bruno
 * Fecha de creación: 19 de julio de 2023
*/
  async duplicarMesa(id: string, titulo: string) {
    Swal.fire({
      title: 'Duplicar mesa',
      text: '¿Confirma duplicar la mesa ' + titulo + '?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.guiMsjService.msjFormSubmit('Guardando');
        await this.mesasService.duplicarMesa(id).then((mesa: Mesa) => {
          location.reload();
        }).catch((error) => {
          console.warn(error);
          Swal.close();
        });
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.dataSource.filter = filterValue;
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        if (key === 'delegacion') {
          // Filtrar por la propiedad 'concepto' dentro de 'delegacion'
          return currentTerm + (data[key] && data[key].concepto && data[key].concepto.toString().toLowerCase());
        } else if (key === 'disciplina') {
          // Filtrar por la propiedad 'nombre' dentro de 'disciplina'
          return currentTerm + (data[key] && data[key].nombre && data[key].nombre.toString().toLowerCase());
        } else {
          // Concatenar los valores de las demás propiedades en una cadena de texto
          return currentTerm + (data[key] && data[key].toString().toLowerCase());
        }
      }, '');
      // Devolver verdadero si la cadena de texto contiene el filtro, de lo contrario, devolver falso
      return dataStr.indexOf(filter) !== -1;
    };
  }

  async loadDataPage() {
    await this.loadDataTable();
    return;
  }
}
