import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
    selector: 'app-tidio-chat',
    templateUrl: './tidio-chat.component.html',
    styleUrls: ['./tidio-chat.component.scss']
})
export class TidioChatComponent implements OnInit {

    idioma: Subscription;

    constructor(private translatorService: Translators) {
        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
        });
    }

    ngOnInit() {
    }

}
