


/* App. Setting */
import { InjectionToken } from '@angular/core';

export let APP_CONFIG_OFERTAS = new InjectionToken('app.config');

/* CONSTANTES OFERTAS LABORALES  */
export interface IAppOfertas{
    tipo_constantes: {name};
    estado_0: { number: number, name: string, detail?: string };
    estado_1: { number: number, name: string, detail?: string };
    estado_2: { number: number, name: string, detail?: string };
    estado_3: { number: number, name: string, detail?: string };
    estado_4: { number: number, name: string, detail?: string };
    estado_5: { number: number, name: string, detail?: string };
    estado_6: { number: number, name: string, detail?: string };
    estado_7: { number: number, name: string, detail?: string };
    estado_8: { number: number, name: string, detail?: string };
    estado_9: { number: number, name: string, detail?: string };
    estado_10: { number: number, name: string, detail?: string };

    selectEstados: { number: number, name: string, detail?: string }[];

    formulario: { pendiente: string, completo: string };
    documentacion: { pendiente: string, completo: string };
    estadoPagoTotal: { pendiente: string, completo: string };
    nombrePendienteCarga: { detalle: string };
    paisDefault: string;

}

export const AppOfertas: IAppOfertas = {

    tipo_constantes: {name: 'OFERTA-LABORAL'},
    estado_0: {number: 0, name: '1/8-PRE INCRIPCION', detail: 'progreso de su solicitud...'},
    estado_1: {number: 1, name: '2/8-FORMULARIO CARGADO', detail: 'progreso de su solicitud...'},
    estado_2: {number: 2, name: '3/8-DOCUMENTACIÓN PARCIAL', detail: 'progreso de su solicitud...'},
    estado_3: {number: 3, name: '4/8-DOCUMENTACIÓN COMPLETA', detail: 'progreso de su solicitud...'},
    estado_4: {number: 4, name: '5/8-PRE-APROBADO', detail: 'progreso de su solicitud...'},
    estado_5: {number: 5, name: '6/8-PAGO SOLICITADO', detail: 'progreso de su solicitud...'},
    estado_6: {number: 6, name: '7/8-CONTROLAR PAGO', detail: 'progreso de su solicitud...'},
    estado_7: {number: 7, name: '8/8-APROBADO', detail: 'progreso de su solicitud...'},
    estado_8: {number: 8, name: 'RECHAZADO', detail: 'progreso de su solicitud...'},
    estado_9: {number: 9, name: 'ANULADA', detail: 'progreso de su solicitud...'},
    estado_10: {number: 10, name: 'EN ESPERA INCIO DE CURSO', detail: 'progreso de su solicitud...'},

    selectEstados: [
        {number: 6, name: '7/8-CONTROLAR PAGO', detail: 'progreso de su solicitud...'},
        {number: 5, name: '6/8-PAGO SOLICITADO', detail: 'progreso de su solicitud...'},
        {number: 4, name: '5/8-PRE-APROBADO', detail: 'progreso de su solicitud...'},
        {number: 3, name: '4/8-DOCUMENTACIÓN COMPLETA', detail: 'progreso de su solicitud...'},
        {number: 2, name: '3/8-DOCUMENTACIÓN PARCIAL', detail: 'progreso de su solicitud...'},
        {number: 1, name: '2/8-FORMULARIO CARGADO', detail: 'progreso de su solicitud...'},
        {number: 0, name: '1/8-PRE INCRIPCION', detail: 'progreso de su solicitud...'},
        {number: 7, name: '8/8-APROBADO', detail: 'progreso de su solicitud...'},
        {number: 8, name: 'RECHAZADO', detail: 'progreso de su solicitud...'},
        {number: 9, name: 'ANULADA', detail: 'progreso de su solicitud...'},
        {number: 10, name: 'EN ESPERA INCIO DE CURSO', detail: 'progreso de su solicitud...'},
    ],

    formulario: { pendiente: 'Pendiente', completo: 'Completo' },
    documentacion: { pendiente: 'Pendiente', completo: 'Completo' },
    estadoPagoTotal: { pendiente: 'Pendiente', completo: 'Completo' },
    nombrePendienteCarga: { detalle: 'carga pendiente' },
    paisDefault: '609bd53ef04ad82d91ca2a2e', // ARGENTINA

};

/* CONSTANTES OFERTAS LABORALES  */
