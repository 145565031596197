import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { UserDocumentoTiposService } from 'src/app/services/shared/user-documento-tipos.service';
import { UserDocumentoTipo } from 'src/app/interfaces/interface-bakend/seguridad/users/user-documento-tipos.interface';

@Component({
  selector: 'app-documento-usuario-alta',
  templateUrl: './documento-usuario-alta.component.html',
  styleUrls: ['./documento-usuario-alta.component.scss']
})
export class DocumentoUsuarioAltaComponent implements OnInit {

  form: FormGroup;
  activo: boolean = true;

  constructor(
    private router: Router,
    private userDocumentoTiposService: UserDocumentoTiposService,
    private guiMsjService: GuiMsjService,
  ) {
    this.createForm();
  }

  ngOnInit(): void { }

  get nombreNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  get descripcionNoValida() {
    return this.form.get('descripcion').invalid && this.form.get('descripcion').touched;
  }

  createForm() {
    this.form = new FormGroup({
      nombre: new FormControl('', Validators.required),
      descripcion: new FormControl('', Validators.required),
      activo: new FormControl(false),
    });
  }

  async setDataForma(): Promise<UserDocumentoTipo> {
    const data: UserDocumentoTipo = {
      nombre: this.form.value.nombre,
      descripcion: this.form.value.descripcion,
      activo: this.form.value.activo,
    };
    return data;
  }

  async post() {
    try {
      await this.guiMsjService.msjFormSubmit('Guardando');
      await this.userDocumentoTiposService.create(await this.setDataForma());
      this.router.navigate(['/documentos-usuarios-listado']);
    } catch (error) {
      console.error('Error al crear el documento:', error);
    }
  }


}
