<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/dashboard">Inicio</a></li>
        <li><a routerLink="/alumno-boletines-listado">Alumno Boletines Listado</a></li>
        <li><a routerLink="/boletines-listado">Listado de Boletines</a></li>
        <li>Listado de Boletines cargados</li>
      </ul>
      <h2>Listado de Boletines Cargados</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-area pb-70">
  <div class="container" style="max-width: 100%">
    <div class="profile-courses-quizzes">
      <a routerLink="/boletin-nombre-alta" class="btn btn-success">
        <i class="fas fa-plus"></i> Configurar Nuevo Boletin
      </a>      
      <hr>
      <mat-form-field>
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Buscar..." autocomplete="off" #input>
      </mat-form-field>

      <div class="mat-elevation-z8">

        <br>
        <div *ngIf="loading; else dataTable" class="spinner-container">
          <mat-spinner></mat-spinner>
        </div>

        <ng-template #dataTable>
          <table mat-table [dataSource]="dataSource" matSort class="custom-table">

            <!-- Nombre del Boletin -->
            <ng-container matColumnDef="boletinNombre">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Boletin </th>
              <td mat-cell *matCellDef="let element"> {{element.boletin.nombre || 'SIN DATOS'}} </td>
            </ng-container>

            <!-- Nombre del Usuario -->
            <ng-container matColumnDef="usuarioNombre">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Usuario </th>
              <td mat-cell *matCellDef="let element"> {{element.alumno.usuario.nombrePersona}}
                {{element.alumno.usuario.apellidoPersona}} </td>
            </ng-container>

            <!-- Identificación -->
            <ng-container matColumnDef="identificacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Identificación </th>
              <td mat-cell *matCellDef="let element"> {{element.alumno.usuario.identificacion}} </td>
            </ng-container>

            <!-- Fecha de Creación -->
            <ng-container matColumnDef="created">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Creación </th>
              <td mat-cell *matCellDef="let element"> {{element.created | date:'dd/MM/yyyy'}} </td>
            </ng-container>

            <!-- Acciones -->
            <ng-container matColumnDef="_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let element">
                <button style="margin-right: 5px;" (click)="agregarNuevoBoletin(element.alumno._id)" type="button"
                  title="Agregar nuevo Boletín" class="btn btn-success"><i class="fas fa-plus"></i> Agregar Boletin</button>
                <button style="margin-right: 5px;" (click)="openDialogVerDetalleBoletin(element._id)" type="button"
                  title="Ver detalle boletín" class="btn btn-primary"><i class="fas fa-eye"></i> Ver</button>
                <button style="margin-right: 5px;" (click)="updateBoletin(element._id)" title="Modificar boletín"
                  type="button" class="btn btn-warning"><i class="fas fa-edit"></i> Editar</button>
                <button (click)="deleteBoletin(element._id)" title="Eliminar boletín" type="button"
                  class="btn btn-danger"><i class="fas fa-trash"></i> Borrar</button>
              </td>
            </ng-container>


            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="5">No hay registros para mostrar "{{input.value}}"</td>
            </tr>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </ng-template>

        <mat-paginator [pageSizeOptions]="[50, 100, 150, 200, 500, 800]"
          aria-label="Select page of users"></mat-paginator>
      </div>
    </div>
  </div>
</div>