import { GuiMsjService } from './../../../../services/shared/gui-msj.service';
import { CursosService } from './../../../../services/cursos/cursos.service';
import { Curso } from './../../../../interfaces/interface-bakend/cursos/curso.interface';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CardData } from '../../../../interfaces/interface-frontend/shared/cardData.interface';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { AuthService } from '../../../../services/auth/auth.service';
import { Actividad } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Subscription } from 'rxjs/internal/Subscription';
import { PaypalService } from '../../../../services/shared/paypal.service';
import { APP_CONFIG_CURSOS, IAppCursos } from '../../../../app.config/app.config-cursos';
import { State } from '../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes';
import { CursoAlumnoCompra } from '../../../../interfaces/interface-bakend/cursos/cursoAlumnoCompra';
import { Payments } from 'src/app/interfaces/interface-bakend/payments/payments.interfaces';
import { Translators } from '../../../../services/shared/translators.service';

@Component({
  selector: 'app-curso-compra',
  templateUrl: './curso-compra.component.html',
  styleUrls: ['./curso-compra.component.scss']
})

export class CursoCompraComponent implements OnInit, OnDestroy {

    usuario: User;
    curso: Curso;
    flagProcesandoPago = false;

    /* Paypal */
    valor: string;
    description: string;
    // tslint:disable-next-line:variable-name
    currency_code = 'USD';
    detailPago: any;
    estadoPago: Subscription;
    /* FIN Paypal */

    /* Card */
    cardDataUser: CardData;
    cardDataFlag: boolean;
    loaded = false;
    /* Fin Card */

    actividad: Actividad;
    pais: PaisDisponible;
    costoPagar: number;
    solicitud: any;
    state: State ;
    idioma: Subscription;


    constructor(
      @Inject(APP_CONFIG_CURSOS) private iAppCursos: IAppCursos,
      private router: Router ,
      private authService: AuthService,
      private cursosService: CursosService,
      private guiMsjService: GuiMsjService,
      private paypalService: PaypalService,
      private translatorService: Translators,
  ) {
      this.statusChangesEstadoPagoPaypal();
      this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        this.idioma = resp;
      });
  }

    ngOnInit(): void {
        this.getEstadoVariables();
    }

    async getEstadoVariables(){
        if (this.getCurso() === undefined) {
            this.router.navigateByUrl('/cursos-disponibles-compra');
            return;
        } else {
            await this.getCurso();
            await this.getUsuarioLogon();
            this.loaded = true;
        }
    }

    getCurso() {
        return this.curso = this.cursosService.getCursoSeleccionado();
    }

    async getUsuarioLogon() {
        this.usuario = await this.authService.getUserLogon();
    }

    async procesarPago(data: any){
        this.flagProcesandoPago = true;

        const cursoPagar: CursoAlumnoCompra = {
            curso: await this.cursosService.getCursoSeleccionado().id,
            payment: await this.loadPayment('PAYPAL', data),
        };

        this.cursosService.postComprarCurso(cursoPagar).then( resp => {
            this.flagProcesandoPago = false;
            this.guiMsjService.msjFormSubmit('pagoCursoOK');
            this.router.navigateByUrl('/cursos-perfil-alumno');
        });
    }

    async loadPayment(tipo: string, data): Promise<Payments> {
        return  {
            tipo,
            payment: {
              result:  data,
              usuario: await (await this.authService.getUserLogon())._id,
              observaciones: this.cursosService.getCursoSeleccionado().nombre,
              importe: this.cursosService.getCursoSeleccionado().precio,
            },
        };
    }

    statusChangesEstadoPagoPaypal() {
        this.estadoPago = this.paypalService.getEstadoPago().subscribe(async (estadoPago: any) => {
            if (estadoPago.value) {
                await this.procesarPago(await this.paypalService.getDetail());
                this.paypalService.setEstadoPago(false);
                return;
            } else {
                return;
            }
        });
    }

    ngOnDestroy(): void {
	    this.estadoPago.unsubscribe();
    }


}
