import { Component, Input, OnInit } from '@angular/core';
import { Translators } from '../../../../services/shared/translators.service';
import { Actividad } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { Subscription } from 'rxjs';
import { PaisesService } from '../../../../services/shared/paises.service';

@Component({
  selector: 'app-ofertas-avatar',
  templateUrl: './ofertas-avatar.component.html',
  styleUrls: ['./ofertas-avatar.component.scss']
})

export class OfertasAvatarComponent implements OnInit {

  @Input() actividad: Actividad;
  idioma: Subscription;

  constructor(
    private actividadesService: ActividadesService,
    private translatorService: Translators,
    private paisesService: PaisesService,
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });
   }

  ngOnInit(): void {
  }

  navCtrlDetalleOfertaLaboral(actividad: Actividad) {
    this.actividadesService.setActividadSeleccionada(actividad);
    this.paisesService.setPaisSeleccionado(actividad.paisDisponible);
  }

}
